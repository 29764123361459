import React, { useCallback, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

import { MakoMultiTabs } from "@/components/MakoMultiTabs";

import { FormCancelamento } from "./formCancelamento";
import { FormDadosBasicos } from "./formDadosBasicos";
import { FormMovimentado } from "./formMovimentado";
import { FormItens } from "./formItens";

import useRequisicao from "@/hooks/useRequisicao";

export const TabRequisicao = () => {
    const [submit, setSubmit] = useState(false);

    const { isEditing, PERSISTIR_TYPES_REQUISICAO, persistirRequisicao } = useRequisicao();

    const { state } = useLocation();

    const history = useHistory();

    const voltarParaListagem = () => {
        history.push("/materiais/requisicao/");
    };

    const handleAbleButton = useCallback((value) => {
        setSubmit(value);
    }, []);

    const titleTemplate = (edit = false, nome) => {
        if (!edit) return "Cadastro de requisição";
        return `Requisição: ${nome}`;
    };

    const handleTabs = useCallback(() => {
        if (state.type === PERSISTIR_TYPES_REQUISICAO.NEW) {
            return [
                {
                    header: "Dados Básicos",
                    icon: "pi pi-fw pi-bookmark",
                    component: <FormDadosBasicos ableButtonStep={handleAbleButton} />,
                },
                {
                    header: "Items",
                    icon: "pi pi-fw pi-list",
                    component: <FormItens ableButtonStep={handleAbleButton} />,
                },
            ];
        }

        if (
            state.type === PERSISTIR_TYPES_REQUISICAO.UPDATE &&
            (state.requisicao.status === "A" || state.requisicao.status === "P")
        ) {
            return [
                {
                    header: "Dados Básicos",
                    icon: "pi pi-fw pi-bookmark",
                    component: <FormDadosBasicos ableButtonStep={handleAbleButton} />,
                },
                {
                    header: "Items",
                    icon: "pi pi-fw pi-list",
                    component: <FormItens ableButtonStep={handleAbleButton} />,
                },
                {
                    header: "Movimentado",
                    icon: "pi pi-fw pi-angle-double-down",
                    component: <FormMovimentado />,
                },
            ];
        }

        if (state.type === PERSISTIR_TYPES_REQUISICAO.UPDATE && state.requisicao.status === "C") {
            return [
                {
                    header: "Cancelamento",
                    icon: "pi pi-fw pi-times-circle",
                    component: <FormCancelamento />,
                },
            ];
        }

        return [
            {
                header: "Dados Básicos",
                icon: "pi pi-fw pi-bookmark",
                component: <FormDadosBasicos ableButtonStep={handleAbleButton} />,
            },
            {
                header: "Items",
                icon: "pi pi-fw pi-list",
                component: <FormItens ableButtonStep={handleAbleButton} />,
            },
            {
                header: "Movimentado",
                icon: "pi pi-fw pi-angle-double-down",
                component: <FormMovimentado />,
            },
            {
                header: "Cancelamento",
                icon: "pi pi-fw pi-times-circle",
                component: <FormCancelamento />,
            },
        ];
    }, [handleAbleButton, state, PERSISTIR_TYPES_REQUISICAO]);

    const persistirDadosRequisicao = useCallback(async () => {
        switch (state.type) {
            case PERSISTIR_TYPES_REQUISICAO.NEW:
                if (await persistirRequisicao(PERSISTIR_TYPES_REQUISICAO.NEW)) {
                    history.push("/materiais/requisicao/");
                }
                break;
            case PERSISTIR_TYPES_REQUISICAO.UPDATE:
                if (await persistirRequisicao(PERSISTIR_TYPES_REQUISICAO.UPDATE)) {
                    history.push("/materiais/requisicao/");
                }
                break;
            default:
                break;
        }
    }, [state, PERSISTIR_TYPES_REQUISICAO, persistirRequisicao, history]);

    return (
        <div className="p-grid">
            <div className="p-col-12">
                <div>
                    <MakoMultiTabs
                        tabs={handleTabs()}
                        title={titleTemplate()}
                        propsButtonProx={{ disabled: isEditing ? false : !submit }}
                        propsButtonFinalizar={{
                            type: "button",
                            disabled: isEditing ? false : !submit,
                            onClick: persistirDadosRequisicao,
                        }}
                        propsButtonCancelar={{ onClick: voltarParaListagem }}
                    />
                </div>
            </div>
        </div>
    );
};
