export const MAKO_ICONS = {
    VISUALIZAR: "pi pi-eye",
    ARQUIVO: "pi pi-print",
    ARQUIVO_PDF: "pi pi-file-pdf",
    EMAIL: "pi pi-envelope",
    LIMPAR_FORM: "pi pi-trash",
    LIMPAR_INPUT: "pi pi-times",
    EDITAR: "pi pi-pencil",
    DELETE: "pi pi-trash",
    NOVO: "pi pi-plus",
    FILTRAR: "pi pi-filter",
    LIMPAR_FILTROS: "pi pi-filter-slash",
    PESQUISAR: "pi pi-search",
    CONFIRMAR: "pi pi-check",
    CANCEL: "pi pi-times",
    DOWNLOAD: "pi pi-download",
    OPCOES: "pi pi-cog",
    LOADING: "pi pi-spin pi-spinner",
    ATIVO: "pi pi-chevron-circle-down",
    INATIVO: "pi pi-times-circle",
    BLOQUEADO: "pi pi-lock",
    ABERTO: "pi pi-unlock",
    GRAVAR: "pi pi-save",
    FRACIONAR: "fas fa-chart-pie",
    ALERTA: "pi-exclamation-triangle",
    DESFAZER: "pi pi-undo",
    REATUALIZAR: "pi pi-refresh",
    VOLTAR: "pi pi-angle-double-left",
};

export const COLORS_VALIDADE = {
    CANCELADA: "#d32f2f",
    PENDENTE: "#2196f4",
    VENCIDA: "#de881f",
    CONCLUIDA: "#069e2d",
};

export const COLORS_STATUS_CAIXA = {
    BLOQUEADO: "#D32F2F",
    ABERTO: "#689F38",
};

export const COLORS_CREDIARIO = {
    APROVADO: "#069e2d",
    REPROVADO: "#d32f2f",
    VALIDADO: "#2196f4",
    PENDENTE: "#000000",
};
