import React, { useCallback, useEffect, useState, useRef } from "react";
import classNames from "classnames";
import { useHistory } from "react-router-dom";
import { InputText } from "primereact/inputtext";
import { MultiSelect } from "primereact/multiselect";
import { Checkbox } from "primereact/checkbox";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { useFormik } from "formik";
import * as Yup from "yup";

import { MakoInputMoeda } from "@/components/MakoInputMoeda";
import { axiosGet, axiosPost, axiosPut } from "@/services/http";
import useLoading from "@/hooks/useLoading";

export const RegraArredondamentoValorForm = ({ data, aposSalvar }) => {
    const [empresas, setEmpresas] = useState([]);
    const [loadingEmpresas, setLoadingEmpresas] = useState(false);
    const toastRef = useRef(null);
    const history = useHistory();
    const { showLoading, hideLoading } = useLoading();

    const { setValues, ...formik } = useFormik({
        initialValues: {
            descricao: "",
            minimo: 0,
            maximo: 0,
            empresas: null,
            acima: false,
        },
        onSubmit: handleSubmit,
    });

    const listarEmpresas = useCallback(async () => {
        setLoadingEmpresas(true);
        const resposta = await axiosGet("/pessoas/grupos-perfis/");
        setLoadingEmpresas(false);

        if (resposta.status === 200) {
            if (resposta.data.results.length > 0)
                setEmpresas(await resposta.data.results.map((vinculo) => vinculo.perfil));
        }
    }, []);

    useEffect(() => {
        if (data) {
            setValues(data);
        }

        listarEmpresas();
    }, [data, setValues, listarEmpresas]);

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                descricao: Yup.string().required("O campo 'descrição' é obrigatório."),
                minimo: Yup.number().min(0, "Esse campo não pode ser menor que 0 (ZERO)."),
                maximo: Yup.number().min(values.minimo, "Esse campo não pode ser menor que o campo 'valor mínimo'."),
            });

            await formSchema.validate(values, { abortEarly: false });

            if (!values.id) {
                showLoading();
                const resposta = await axiosPost("/vendas/regra-arredondamento-valor/", values);
                hideLoading();

                if (resposta.status === 201) {
                    toastRef.current.show({
                        severity: "success",
                        summary: "Sucesso",
                        detail: "Regra de arredondamento de preço cadastrada com sucesso!",
                        life: 1500,
                    });

                    if (aposSalvar) {
                        aposSalvar(resposta.data);
                    }
                } else {
                    toastRef.current.show({
                        severity: "error",
                        summary: "Erro :(",
                        detail: "Desculpe, não conseguimos processar a sua requisição.",
                        life: 3000,
                    });
                }
            } else {
                showLoading();
                const resposta = await axiosPut(`/vendas/regra-arredondamento-valor/${values.id}/`, values);
                hideLoading();

                if (resposta.status === 200) {
                    toastRef.current.show({
                        severity: "success",
                        summary: "Sucesso",
                        detail: "Regra de arredondamento de preço alterada com sucesso!",
                        life: 1500,
                    });

                    if (aposSalvar) {
                        aposSalvar(resposta.data);
                    }
                } else {
                    toastRef.current.show({
                        severity: "error",
                        summary: "Erro :(",
                        detail: "Desculpe, não conseguimos processar a sua requisição.",
                        life: 3000,
                    });
                }
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};

                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });

                formik.setErrors(errorMessages);
            }
        }
    }

    return (
        <>
            <Toast ref={toastRef} />
            <form onSubmit={formik.handleSubmit}>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-6">
                        <label htmlFor="descricao">Descrição *</label>
                        <InputText
                            id="descricao"
                            name="descricao"
                            value={formik.values.descricao}
                            onChange={formik.handleChange}
                            autoComplete="off"
                            className={classNames({ "p-invalid": formik.errors.descricao })}
                        />
                        {formik.errors.descricao && <small className="p-error">{formik.errors.descricao}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-6">
                        <label htmlFor="empresas">Empresas</label>
                        <MultiSelect
                            id="empresas"
                            name="empresas"
                            options={empresas}
                            optionValue="id"
                            optionLabel="nome"
                            selectedItemsLabel="{0} empresas selecionadas"
                            maxSelectedLabels={2}
                            disabled={loadingEmpresas}
                            placeholder={!loadingEmpresas ? "Selecione" : "Carregando..."}
                            value={formik.values.empresas}
                            onChange={formik.handleChange}
                        />
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="vlr-minimo">Valor mínimo *</label>
                        <MakoInputMoeda
                            id="vlr-minimo"
                            name="minimo"
                            valueMoeda={formik.values.minimo}
                            onChangeMoeda={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.minimo })}
                        />
                        {formik.errors.minimo && <small className="p-error">{formik.errors.minimo}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="vlr-maximo">Valor máximo *</label>
                        <MakoInputMoeda
                            id="vlr-maximo"
                            name="maximo"
                            valueMoeda={formik.values.maximo}
                            onChangeMoeda={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.maximo })}
                        />
                        {formik.errors.maximo && <small className="p-error">{formik.errors.maximo}</small>}
                    </div>
                    <div className="p-field-checkbox p-mt-4 p-col-12 p-md-2">
                        <Checkbox
                            id="acima"
                            name="acima"
                            checked={formik.values.acima}
                            onChange={formik.handleChange}
                        />
                        <label htmlFor="acima">Arredonda para cima?</label>
                    </div>
                </div>
                <p>
                    <b>* Campos obrigatórios.</b>
                </p>
                <div className="p-grid p-col-12 p-md-6">
                    <Button icon="pi pi-save" label="Gravar" type="submit" className="p-button-info p-mr-2" />
                    <Button
                        icon="pi pi-angle-double-left"
                        label="Voltar"
                        type="button"
                        className="p-button-danger p-mr-2"
                        onClick={() => history.push("/vendas/cadastros/arredondamento-valor")}
                    />
                </div>
            </form>
        </>
    );
};
