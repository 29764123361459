import React, { forwardRef, useImperativeHandle, useState, useRef, useCallback, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { Messages } from "primereact/messages";
import { Dropdown } from "@/components/Dropdown";
import useVenda from "@/hooks/useVenda";
import { axiosGet } from "@/services/http";
import MakoListagem from "@/components/MakoListagem";
import { formatarCasasDecimais, parseNumberToMoneyHTML } from "@/assets/util/util";
import useToast from "@/hooks/useToast";

const ModalOrcamentoRapido = (props, ref) => {
    const [visible, setVisible] = useState(false);
    const [carregando, setCarregando] = useState(false);
    const [orcamento, setOrcamento] = useState(null);
    const [itensOrcamento, setItensOrcamento] = useState([]);
    const [itensSelecionado, setItensSelecionado] = useState(null);
    const [listaOrcamentos, setListaOrcamentos] = useState([]);
    const msgRef = useRef(null);
    const { dadosBasicos, handleItemVenda } = useVenda();
    const { showSuccess, showError } = useToast();

    async function handleSubmit() {
        try {
            await handleItemVenda(
                { ...itensSelecionado, id: null, descricao: itensSelecionado.sku.descricao_reduzida },
                "novo",
                true
            );

            showSuccess({
                summary: "Sucesso",
                detail: "Item inserido com sucesso!",
                life: 1500,
            });
            setItensSelecionado(null);
            // fecharModal();
        } catch (error) {
            showError({
                summary: "Erro",
                detail: "Desculpe, não foi possível inserir os itens do orçamento",
                life: 3000,
            });
        }
    }

    const abrirModal = () => {
        setVisible(true);
    };

    const fecharModal = () => {
        setItensSelecionado(null);
        setOrcamento([]);
        setItensOrcamento([]);
        setVisible(false);
    };

    const footerDialog = () => {
        return (
            <div>
                <Button
                    label="Cancelar"
                    icon="pi pi-times"
                    onClick={fecharModal}
                    className="p-button-text p-button-danger"
                />
                <Button
                    label="Inserir"
                    className="p-button-success"
                    type="submit"
                    icon="pi pi-plus"
                    disabled={!itensSelecionado}
                    onClick={() => handleSubmit()}
                    autoFocus
                />
            </div>
        );
    };

    useImperativeHandle(ref, () => {
        return {
            abrirModal,
        };
    });

    const listarTiposCodigos = useCallback(async () => {
        if (dadosBasicos?.cliente?.id) {
            setCarregando(true);
            const resposta = await axiosGet(
                `/vendas/orcamento-rapido?cliente=${dadosBasicos.cliente.id}&baixado=false`
            );
            setCarregando(false);

            if (resposta.status === 200) {
                let lista = [];
                resposta.data.results.forEach((orcamento) => {
                    lista.push({
                        ...orcamento,
                        label: `Número: ${orcamento.id} | Data: ${orcamento.data} | Valor: ${orcamento.valor_total}`,
                    });
                });
                setListaOrcamentos(lista);
            }
        }
    }, [dadosBasicos?.cliente?.id]);

    useEffect(() => {
        listarTiposCodigos();
    }, [listarTiposCodigos]);

    const calcularValorTotalItemTabela = (rowData) => {
        const { quantidade, cubagem, valor_unitario, valor_desconto_unitario } = rowData;

        const valorItem = valor_unitario - valor_desconto_unitario;
        const valorTotal = quantidade * cubagem * valorItem;

        return parseNumberToMoneyHTML(valorTotal);
    };

    const selecionaOrcamento = (e) => {
        setOrcamento(e);
        setItensOrcamento(e.itemorcamentorapido_set);
        setItensSelecionado([]);
    };

    const colunas = [
        { selectionMode: "single", style: { minWidth: "50px" } },
        { field: "sku.codigo", header: "Código", style: { minWidth: "100px" } },
        { field: "sku.descricao_reduzida", header: "Descrição", style: { minWidth: "300px" } },
        { field: "quantidade", header: "Qtd.", style: { minWidth: "120px" } },
        {
            field: "cubagem",
            header: "Cubagem",
            style: { minWidth: "100px" },
            action: (e) => formatarCasasDecimais(e.cubagem, 5),
        },
        {
            field: "tabela_preco.nome",
            header: "Tabela de Preço",
            style: { minWidth: "200px" },
        },
        {
            field: "valor_unitario",
            header: "Valor unitário (R$)",
            style: { minWidth: "150px" },
            action: (e) => parseNumberToMoneyHTML(e.valor_unitario),
        },
        {
            field: "valor_desconto_unitario",
            header: "Valor descontos (R$)",
            style: { minWidth: "150px" },
            action: (e) => parseNumberToMoneyHTML(e.valor_desconto_unitario * e.quantidade),
        },
        {
            field: "valor_total",
            header: "Valor total (R$)",
            style: { minWidth: "150px" },
            action: (e) => calcularValorTotalItemTabela(e),
            frozen: true,
            alignFrozen: "right",
        },
    ];

    return (
        <Dialog
            visible={visible}
            header="Itens de orçamento rápido"
            footer={footerDialog}
            style={{ width: "45vw" }}
            onHide={fecharModal}
            blockScroll
        >
            <Messages ref={msgRef} />
            <form>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-12">
                        <label htmlFor="orcamento">Selecione um orçamento rápido:</label>
                        <Dropdown
                            id="orcamento"
                            name="orcamento"
                            options={listaOrcamentos}
                            optionLabel="label"
                            emptyMessage="O cliente não possui orçamentos"
                            disabled={carregando}
                            value={orcamento}
                            onChange={(e) => selecionaOrcamento(e.target.value)}
                        />
                    </div>
                </div>
                {orcamento?.id ? (
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-12 p-md-12">
                            <label htmlFor="orcamento">Selecione os itens que serão inseridos:</label>

                            <MakoListagem
                                colunas={colunas}
                                dadosLocal={itensOrcamento}
                                responsiva
                                configTabela={{
                                    paginator: false,
                                    scrollable: true,
                                    scrollHeight: "300px",
                                    frozenWidth: "0.5vw",
                                    selectionMode: "single",
                                    selection: itensSelecionado,
                                    onSelectionChange: (e) => setItensSelecionado(e.value),
                                }}
                            />
                        </div>
                    </div>
                ) : null}
            </form>
        </Dialog>
    );
};

export const ModalOrcRapido = forwardRef(ModalOrcamentoRapido);
