import React, { createContext, useCallback, useState } from "react";

import { gerarId } from "@/assets/util/util";
import useLoading from "@/hooks/useLoading";
import useToast from "@/hooks/useToast";
import useHttp from "@/hooks/useHttp";

const CrediarioContext = createContext({});

export const CrediarioProvider = ({ children }) => {
    const [submit, setSubmit] = useState(false);
    const [crediario, setCrediario] = useState({
        dadosprofissionais_dyn: [],
    });
    const [previsoes, setPrevisoes] = useState([]);
    const { showLoading, hideLoading } = useLoading();
    const { httpGet, httpPost, httpPut } = useHttp();
    const { showError } = useToast();

    const gerenciarOperacoesCrud = (lista, obj, op) => {
        switch (op) {
            case "novo":
                lista.push({ ...obj, id: gerarId() });

                break;

            case "editar":
                if (typeof obj.id === "number") {
                    if (lista.length > 0) {
                        const index = lista?.findIndex((e) => e.id === obj.id);
                        lista[index] = obj;
                    }
                } else if (typeof obj.id === "string") {
                    if (lista.length > 0) {
                        const index = lista?.findIndex((e) => e.id === obj.id);
                        obj.op = "novo";
                        lista[index] = obj;
                    }
                }

                break;

            case "deletar":
                if (typeof obj.id === "number") {
                    if (lista.length > 0) {
                        const index = lista?.findIndex((e) => e.id === obj.id);
                        obj.op = "deletar";
                        lista[index] = obj;
                    }
                } else if (typeof obj.id === "string") {
                    if (lista.length > 0) {
                        const index = lista?.findIndex((e) => e.id === obj.id);
                        lista.splice(index, 1);
                    }
                }

                break;

            default:
                break;
        }

        return lista;
    };

    const handleDadosCrediario = useCallback(
        (dados) => {
            let diff = {};
            Object.entries(dados).forEach(([k, v]) => {
                if (v !== crediario[k]) diff[k] = v;
            });
            if (Object.keys(diff).length > 0)
                setCrediario({
                    ...crediario,
                    ...dados,
                });
            setSubmit(true);
        },
        [crediario, setCrediario]
    );

    const handleDadosComplementares = useCallback(
        (op, dados) => {
            let dado = crediario?.referenciacrediarioperfil_set ? crediario.referenciacrediarioperfil_set : [];

            dado = gerenciarOperacoesCrud(dado, dados, op);

            setCrediario({
                ...crediario,
                referenciacrediarioperfil_set: dado,
            });
        },
        [crediario]
    );

    const handleRelacao = useCallback(
        (op, relacoes) => {
            let relacao = crediario?.relacaoperfil_set ? crediario.relacaoperfil_set : [];

            relacao = gerenciarOperacoesCrud(relacao, relacoes, op);

            setCrediario({
                ...crediario,
                relacaoperfil_set: relacao,
            });
        },
        [crediario]
    );

    const handleCargo = useCallback(
        (op, cargos) => {
            let cargo = crediario?.dadosprofissionais_set ? crediario.dadosprofissionais_set : [];

            cargo = gerenciarOperacoesCrud(cargo, cargos, op);

            setCrediario({
                ...crediario,
                dadosprofissionais_set: cargo,
            });
        },
        [crediario]
    );

    const handleAnaliseCrediario = useCallback(
        (op, analise) => {
            let analises = crediario?.analisecrediariovenda_set ? crediario.analisecrediariovenda_set : [];

            analises = gerenciarOperacoesCrud(analises, analise, op);

            setCrediario({
                ...crediario,
                status: analise.status,
                analisecrediariovenda_set: analises,
            });
        },
        [crediario]
    );

    const formatarObjCrud = (itens, op = "rel") => {
        let crud = {};

        itens.forEach((item) => {
            if (item.op === "novo") {
                if (crud.create) {
                    crud.create.push(item);
                } else {
                    crud = {
                        ...crud,
                        create: [item],
                    };
                }
            } else if (item.op === "deletar") {
                if (crud.remove) {
                    crud.remove.push(item.id);
                } else {
                    crud = {
                        ...crud,
                        remove: [item.id],
                    };
                }
            } else if (item.op === "editar" || op === "rel") {
                if (crud.update) {
                    crud = {
                        ...crud,
                        update: {
                            ...crud.update,
                            [item.id]: item,
                        },
                    };
                } else {
                    crud = {
                        ...crud,
                        update: {
                            [item.id]: item,
                        },
                    };
                }
            }
        });

        return crud;
    };

    const handlePreencherCrediario = useCallback(
        async (idCrediario) => {
            const handlers = {
                200: async ({ data }) => {
                    const { perfil_titular, orcamento, dadoscrediario_set, ...json } = data;
                    let protocolos = [],
                        dadosProfissionais = [];

                    dadoscrediario_set.forEach((dado) => {
                        if (dado.criterio.padrao_criterio.aba === "Protocolo")
                            protocolos.push({ ...dado, criterio: dado.criterio.id, op: "editar" });
                        else if (dado.criterio.padrao_criterio.aba === "DadosProfissionais")
                            dadosProfissionais.push({
                                ...dado,
                                criterio: dado.criterio.id,
                                nome: dado.criterio.padrao_criterio.chave,
                                op: "editar",
                            });
                    });

                    setCrediario({
                        ...json,
                        orcamento: orcamento,
                        perfil_titular: perfil_titular,
                        nome: perfil_titular.nome,
                        identificacao: perfil_titular.identificacao,
                        rg: perfil_titular.perfil_pf?.rg,
                        uf_emissor: perfil_titular.perfil_pf?.rg_estado_emissor,
                        data_nascimento: perfil_titular?.perfil_pf?.data_nascimento || null,
                        inscricao_estadual: perfil_titular?.perfil_pj?.inscricao_estadual || null,
                        estado: perfil_titular?.perfil_pj?.estado || null,
                        data_constituicao: perfil_titular?.perfil_pj?.data_constituicao || null,
                        orgao_expeditor: perfil_titular.rg_orgao_emissor,
                        numero_orcamento: orcamento.id,
                        preco_orcamento: orcamento.valor_total_av,
                        data_orcamento: orcamento.data,
                        hora_orcamento: orcamento.hora,
                        protocolocrediario_dyn: protocolos,
                        dadosprofissionais_dyn: dadosProfissionais,
                    });
                    setSubmit(true);
                },
                400: () => showError(),
            };
            showLoading();
            await httpGet({ url: `/crediario/protocolo-crediario/${idCrediario}/` }, handlers);
            hideLoading();
        },
        [showLoading, hideLoading]
    );

    const handleCrediario = async () => {
        const {
            relacaoperfil_set = [],
            referenciacrediarioperfil_set = [],
            documentosrenda_set = [],
            protocolocrediario_dyn = [],
            dadosprofissionais_dyn = [],
            analisecrediariovenda_set = [],
            ocupacao,
            perfil_titular,
            orcamento,
            regra,
            status,
            ...protocolo
        } = crediario;

        delete protocolo.status;

        let postCrediario = {
            ...protocolo,
            orcamento: orcamento.id,
            ocupacao: ocupacao.id,
            perfil_titular: perfil_titular.id,
            regra: regra.id,
            status: status !== "A" && status !== "R" ? "I" : status,
        };

        if (relacaoperfil_set.length > 0) {
            let relacaoFormatada = [];
            await relacaoperfil_set.forEach(async (relacao) => {
                delete relacao.rendarelacaoperfil_set;
                relacaoFormatada.push({
                    ...relacao,
                    nome_relacao: relacao.nome_relacao?.id,
                    perfil_titular: relacao.perfil_titular?.id || relacao.perfil_titular,
                    perfil_vinculado: relacao.perfil_vinculado?.id,
                });
            });

            const relacao = formatarObjCrud(relacaoFormatada, "rel");

            postCrediario = {
                ...postCrediario,
                relacaoperfil_set: relacao,
            };
        }

        if (referenciacrediarioperfil_set.length > 0) {
            postCrediario = {
                ...postCrediario,
                referenciacrediarioperfil_set: formatarObjCrud(
                    referenciacrediarioperfil_set.map(({ usuario_verificacao, ...ref }) => {
                        return {
                            ...ref,
                            usuario_verificacao: usuario_verificacao?.id || null,
                        };
                    })
                ),
            };
        }

        if (documentosrenda_set.length > 0) {
            const documentos = formatarObjCrud(documentosrenda_set);

            postCrediario = {
                ...postCrediario,
                documentosrenda_set: documentos,
            };
        }

        if (analisecrediariovenda_set.length > 0) {
            let analises = [];
            await analisecrediariovenda_set.forEach(async (analise) => {
                analises.push({ ...analise, usuario_aprovacao: analise.usuario_aprovacao?.id });
            });

            const dados = formatarObjCrud(analises);

            postCrediario = {
                ...postCrediario,
                analisecrediariovenda_set: dados,
            };
        }

        if (protocolocrediario_dyn.length > 0) {
            let listaDados = [];
            await protocolocrediario_dyn.forEach(async (dado) => {
                listaDados.push({
                    ...dado,
                    regra: regra.id,
                    op: !dado.id ? "novo" : "editar",
                });
            });

            const dados = formatarObjCrud(listaDados?.filter((e) => e.valor));

            postCrediario = {
                ...postCrediario,
                dadoscrediario_set: { ...postCrediario.dadoscrediario_set, ...dados },
            };
        }

        if (dadosprofissionais_dyn.length > 0) {
            let listaDados = [];
            dadosprofissionais_dyn.forEach(async (dado) => {
                listaDados.push({
                    ...dado,
                    regra: regra.id,
                    op: !dado.id ? "novo" : "editar",
                });
            });

            const dados = formatarObjCrud(listaDados);

            postCrediario = {
                ...postCrediario,
                dadoscrediario_set: { ...postCrediario.dadoscrediario_set, ...dados },
            };
        }

        let statusCode = 400,
            json = {};
        if (!postCrediario.id) {
            const handlers = {
                201: async ({ data }) => {
                    json = data;
                    statusCode = 201;
                },
            };

            await httpPost({ url: "/crediario/protocolo-crediario/", body: postCrediario }, handlers);
        } else {
            const handlers = {
                200: async ({ data }) => {
                    json = data;
                    statusCode = 200;
                },
            };

            await httpPut(
                { url: `/crediario/protocolo-crediario/${postCrediario.id}/`, body: postCrediario },
                handlers
            );
        }
        return { statusCode, json };
    };

    return (
        <CrediarioContext.Provider
            value={{
                submit,
                crediario,
                previsoes,
                setCrediario,
                setPrevisoes,
                handleCargo,
                setSubmit,
                handleDadosCrediario,
                handleDadosComplementares,
                handleAnaliseCrediario,
                handleCrediario,
                handleRelacao,
                handlePreencherCrediario,
            }}
        >
            {children}
        </CrediarioContext.Provider>
    );
};

export default CrediarioContext;
