import React, { useEffect, useCallback, useState } from "react";
import { Button } from "primereact/button";
import * as Yup from "yup";

import { CustomFieldCalendar } from "./CustomFieldCalendar";
import { CustomFieldDropdown } from "./CustomFieldDropdown";
import { CustomFieldInputNumber } from "./CustomFieldInputNumber";
import { CustomFieldInputText } from "./CustomFieldInputText";
import { CustomFieldInputTextArea } from "./CustomFieldInputTextArea";
import { CustomFieldTime } from "./CustomFieldTime";
import { axiosGet } from "@/services/http";

export const FormCustomFields = ({ contentType, instance, localValues, onSubmit }) => {
    const [customFields, setCustomFields] = useState([]);
    const [validationsSchema, setValidationsSchema] = useState({});
    const [values, setValues] = useState({});
    const [errors, setErrors] = useState({});

    async function handleSubmit() {
        try {
            setErrors({});

            await validationsSchema.validate(values, { abortEarly: false });

            if (onSubmit) {
                let body = [];
                customFields.forEach((field) => {
                    let value = null;
                    for (const [k, v] of Object.entries(values)) {
                        if (k === `${field.id}`) {
                            value = v;
                        }
                    }
                    body.push({ ...field, value });
                });

                onSubmit(body);
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};

                error.inner.forEach((err) => {
                    const [path] = err.path.split(".");
                    errorMessages[path] = err.message;
                });

                setErrors(errorMessages);
            }
        }
    }

    const criarAgrupamentoCom4Colunas = (componentes) => {
        return <div className="p-fluid p-formgrid p-grid">{componentes}</div>;
    };

    const inserirComponenteNoGrid = (componente) => {
        return <div className="p-field p-col-12 p-md-3">{componente}</div>;
    };

    const criarCampoPersonalizado = (field) => {
        const { value, ...rest } = field;

        const props = {
            key: rest.id,
            instance: instance,
            fieldOptions: rest,
            valueOptions: values[rest.id],
            onChange: (e) => setValues({ ...values, [rest.id]: e }),
            error: errors[rest.id],
        };

        let componente = null;
        if (rest.field_type === "t") {
            componente = <CustomFieldInputText {...props} />;
        }

        if (rest.field_type === "a") {
            componente = <CustomFieldInputTextArea {...props} />;
        }

        if (rest.field_type === "i" || rest.field_type === "f") {
            componente = <CustomFieldInputNumber {...props} />;
        }

        if (rest.field_type === "m" || rest.field_type === "b") {
            componente = <CustomFieldDropdown {...props} />;
        }

        if (rest.field_type === "d") {
            componente = <CustomFieldCalendar {...props} />;
        }

        if (rest.field_type === "h") {
            componente = <CustomFieldTime {...props} />;
        }

        return inserirComponenteNoGrid(componente);
    };

    const montarCamposPersonalizados = (customFields) => {
        const componentes = customFields.map((field) => {
            return criarCampoPersonalizado(field);
        });

        // não me orgulho do código abaixo :(
        let grids = [];
        for (let x = 0; x < componentes.length; x += 4) {
            grids.push(criarAgrupamentoCom4Colunas(componentes.slice(x, x + 4)));
        }

        return grids;
    };

    const initForm = (customFields) => {
        let _formData = {};
        let _schemaData = {};

        customFields.forEach((field) => {
            const { value, ...rest } = field;

            if (value) {
                _formData[rest.id] = value;
            }

            if (rest.is_required) {
                if (rest.field_type === "t" || rest.field_type === "m") {
                    _schemaData[rest.id] = Yup.object().shape({
                        value: Yup.string().required(`O campo '${rest.label.toLowerCase()}' é obrigatório.`),
                    });
                }
            }
        });

        setValues(_formData);
        setValidationsSchema(Yup.object().shape(_schemaData));
    };

    const buscarCamposPersonalizados = useCallback(async () => {
        if (!localValues) {
            const params = {
                content_type: contentType,
                instance,
            };
            const { status, data } = await axiosGet("/custom-fields/full/", { params });

            if (status === 200) {
                setCustomFields(data.results);
                initForm(data.results);
            }
        } else {
            setCustomFields(localValues);
            initForm(localValues);
        }
    }, [contentType, instance, localValues]);

    useEffect(() => {
        buscarCamposPersonalizados();
    }, [buscarCamposPersonalizados]);

    return (
        <>
            {customFields.length > 0 ? (
                <>
                    {montarCamposPersonalizados(customFields)}
                    <p>
                        <b>* Campos obrigatórios</b>
                    </p>
                    <div className="p-grid">
                        <div className="p-col-12">
                            <Button type="button" icon="pi pi-save" label="Gravar" onClick={handleSubmit} />
                        </div>
                    </div>
                </>
            ) : (
                <h6>Nenhum campo personalizado configurado.</h6>
            )}
        </>
    );
};
