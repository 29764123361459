import React, { useRef, useMemo, useState } from "react";
import { Button } from "primereact/button";
import { InputNumber } from "primereact/inputnumber";
import MakoListagem from "@/components/MakoListagem";
import { MakoCalendar } from "@/components/MakoCalendar";
import { useFormik } from "formik";
import classNames from "classnames";
import * as Yup from "yup";
import { parseMoeda } from "@/assets/util/util";
import { parseNumber } from "@/assets/helpers/number";
import useNotaServico from "@/hooks/useNotaServico";
import { Dialog } from "primereact/dialog";
import { MakoInputMoeda } from "@/components/MakoInputMoeda";
import { TIPO_VENCIMENTO_PARCELA_CHOICE } from "@/assets/constants/constants";
import { Dropdown } from "primereact/dropdown";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import permissoes from "@/assets/constants/permissoes";
import { dataToStr } from "@/assets/util/datas";
import { ConfirmDialog } from "primereact/confirmdialog";
import useToast from "@/hooks/useToast";

export const ParcelasServicoForm = () => {
    const { handleParcelas, parcelas } = useNotaServico();
    const [adicionaParcela, setAdicionaParcela] = useState(false);
    const [deleteDialog, setDeleteDialog] = useState(false);
    const [parcela, setParcela] = useState(null);
    const { showSuccess, showError } = useToast();
    const listagemRef = useRef(null);

    const { setFieldValue, setValues, resetForm, ...formik } = useFormik({
        initialValues: {
            status: "novo",
            tipo_vencimento: 1,
            valor: 0,
            valor_desconto: 0,
            valor_liquido: 0,
            numero_fatura: 1,
            data_vencimento: new Date(),
        },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                valor: Yup.number()
                    .required("O campo 'valor' é obrigatório.")
                    .min(0)
                    .typeError("Informe um tipo de parcela."),
                valor_desconto: Yup.number()
                    .required("O campo 'valor do desconto' é obrigatório.")
                    .min(0)
                    .max(values.valor)
                    .typeError("Informe um tipo de parcela."),
                tipo_vencimento: Yup.number()
                    .required("O campo 'tipo de parcela' é obrigatório.")
                    .typeError("Informe um tipo de parcela."),
                numero_fatura: Yup.number()
                    .required("O campo 'quantidade' é obrigatório.")
                    .min(1, "O valor mínimo para esse campo é 1."),
                data_vencimento: Yup.date()
                    .required("O campo 'data 1ª parcela' é obrigatório.")
                    .typeError("Informe uma data válida."),
            });
            await formSchema.validate(values, {
                abortEarly: false,
            });
            await handleParcelas(values.status, {
                ...values,
                data_vencimento: dataToStr(values.data_vencimento, "yyyy-MM-dd"),
            });
            resetForm();
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            } else
                showError({
                    summary: "Erro",
                    detail: "Falha ao lançar parcelas, por favor tente novamente.",
                    life: 3000,
                });
        }
    }

    const removerParcela = async () => {
        try {
            await handleParcelas("deletar", parcela);
            showSuccess({
                summary: "Sucesso",
                detail: "Parcela removida com sucesso!",
                life: 3000,
            });
        } catch (error) {
            showError({
                summary: "Erro",
                detail: "Falha ao remover parcela!",
                life: 3000,
            });
        }
    };

    const confirmarDelete = (servico) => {
        setParcela({ ...servico, status: "deletar" });
        setDeleteDialog(true);
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <MakoControleAcesso
                    permissao={[permissoes.FISCAL_NOTASERVICO_EDITAR]}
                    componente={Button}
                    icon="pi pi-trash"
                    className="p-button-rounded p-button-danger p-mr-2 p-mb-1"
                    onClick={() => confirmarDelete(rowData)}
                    tooltipOptions={{ position: "left" }}
                />
            </div>
        );
    };

    const colunas = [
        { field: "numero_fatura", header: "Número", style: { width: "12%" } },
        { field: "tipo_vencimento", header: "Tipo" },
        {
            field: "data_vencimento",
            header: "Data vencimento",
            dateFormat: "dd/MM/yyyy",
        },
        {
            field: "valor",
            header: "Valor",
            money: true,
        },
        {
            field: "actions",
            header: "Ações",
            align: "center",
            style: { width: "8%" },
            action: actionBodyTemplate,
        },
    ];

    const cabecalhoTabela = (
        <>
            <Button
                label="Adicionar parcela"
                icon="pi pi-plus"
                className="p-button-success p-button-outlined p-mr-2"
                onClick={(e) => setAdicionaParcela(true)}
            />
        </>
    );

    const totalParcelas = useMemo(() => {
        return parcelas.reduce((total, pag) => total + parseNumber(pag.valor), 0);
    }, [parcelas]);

    const esconderParcela = () => {
        resetForm();
        setAdicionaParcela(false);
    };

    return (
        <div>
            <MakoListagem ref={listagemRef} painelEsquerdo={cabecalhoTabela} colunas={colunas} dadosLocal={parcelas} />
            <div className="p-mt-3">
                <div className={`p-text-right ${!parcelas?.length > 0 && "p-error"}`}>
                    <h6>{`Total das parcelas: ${parseMoeda(totalParcelas, true)}`}</h6>
                </div>
                <div className="p-text-right">
                    <h6>{`Total esperado: ${parseMoeda(parcelas[0]?.valor_total, true)}`}</h6>
                </div>
            </div>
            <Dialog
                header="Adicionar serviço"
                visible={adicionaParcela}
                breakpoints={{ "960px": "80vw" }}
                style={{ width: "75vw" }}
                onHide={() => esconderParcela()}
            >
                <form onSubmit={formik.handleSubmit}>
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-12 p-md-3">
                            <label htmlFor="numero_fatura">Número *</label>
                            <InputNumber id="numero_fatura" value={formik.values.numero_fatura} disabled />
                        </div>
                        <div className="p-field p-col-12 p-md-3">
                            <label htmlFor="valor">Valor *</label>
                            <MakoInputMoeda
                                id="valor"
                                name="valor"
                                valueMoeda={formik.values.valor}
                                onChangeMoeda={formik.handleChange}
                                className={classNames({ "p-invalid": formik.errors.valor })}
                            />
                            {formik.errors.valor && <small className="p-error">{formik.errors.valor}</small>}
                        </div>
                        <div className="p-field p-col-12 p-md-3">
                            <label htmlFor="valor_desconto">Valor desconto *</label>
                            <MakoInputMoeda
                                id="valor_desconto"
                                name="valor_desconto"
                                min={0}
                                valueMoeda={formik.values.valor_desconto}
                                onChangeMoeda={formik.handleChange}
                                className={classNames({ "p-invalid": formik.errors.valor_desconto })}
                            />
                            {formik.errors.valor_desconto && (
                                <small className="p-error">{formik.errors.valor_desconto}</small>
                            )}
                        </div>
                        <div className="p-field p-col-12 p-md-3">
                            <label htmlFor="valor_liquido">Valor líquido *</label>
                            <MakoInputMoeda
                                id="valor_liquido"
                                name="valor_liquido"
                                valueMoeda={formik.values.valor_liquido}
                                disabled
                            />
                        </div>
                    </div>
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-12 p-md-5">
                            <label htmlFor="tipo_vencimento">Tipo de vencimento *</label>
                            <Dropdown
                                id="tipo_vencimento"
                                name="tipo_vencimento"
                                options={TIPO_VENCIMENTO_PARCELA_CHOICE}
                                value={formik.values.tipo_vencimento}
                                optionLabel="label"
                                optionValue="value"
                                onChange={formik.handleChange}
                                className={classNames({ "p-invalid": formik.errors.tipo_vencimento })}
                            />
                            {formik.errors.tipo_vencimento && (
                                <small className="p-error">{formik.errors.tipo_vencimento}</small>
                            )}
                        </div>
                        <div className="p-field p-col-12 p-md-3">
                            <label htmlFor="data_vencimento">Data do vecimento *</label>
                            <MakoCalendar
                                id="data_vencimento"
                                name="data_vencimento"
                                valueCalendar={formik.values.data_vencimento}
                                onChange={formik.handleChange}
                                className={classNames({ "p-invalid": formik.errors.data_vencimento })}
                            />
                            {formik.errors.data_vencimento && (
                                <small className="p-error">{formik.errors.data_vencimento}</small>
                            )}
                        </div>
                    </div>
                    <Button
                        icon={formik.values.status === "editar" ? "pi pi-save" : "pi pi-plus"}
                        type="submit"
                        label={formik.values.status === "editar" ? "Salvar" : "Inserir"}
                        className="p-button-success p-mr-2 p-mt-2"
                    />
                </form>
            </Dialog>
            <ConfirmDialog
                visible={deleteDialog}
                onHide={() => setDeleteDialog(false)}
                header="Confirmação"
                message={
                    parcela && (
                        <span>
                            {"Deseja realmente remover a parcela número "}
                            <b>{parcela.numero_fatura}</b>?
                        </span>
                    )
                }
                icon="pi pi-info-circle p-mr-3"
                accept={removerParcela}
                acceptLabel="Sim"
                acceptClassName="p-button-danger"
                reject={() => setDeleteDialog(false)}
                rejectLabel="Não"
            />
        </div>
    );
};
