import React, { useCallback, useEffect, useState } from "react";

import { useFormik } from "formik";
import classNames from "classnames";
import * as Yup from "yup";

import { InputTextarea } from "primereact/inputtextarea";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";


import { MakoInputMoeda } from "@/components/MakoInputMoeda";
import { MakoCalendar } from "@/components/MakoCalendar";

import useLoading from "@/hooks/useLoading";
import useToast from "@/hooks/useToast";

import { axiosGet, axiosPatch, axiosPost } from "@/services/http";

import { NATUREZA_PLANO_CONTAS } from "@/assets/constants/constants";
import { formatarCasasDecimais } from "@/assets/util/util";
import { dataToStr } from "@/assets/util/datas";

export const UtilizarForm = ({ voucher, onFinish, fecharModal, caixa }) => {
    const [listaPlanos, setListaPlanos] = useState([]);
    const [listaContas, setListaContas] = useState([]);
    const [listaCaixas, setListaCaixas] = useState([]);

    const { showLoading, hideLoading } = useLoading();
    const { showSuccess, showError } = useToast();

    const { setFieldValue, ...formik } = useFormik({
        initialValues: {
            tipo_movimento: "C",
            data_movimentacao: new Date(),
            valor: voucher?.valor,
            conta_financeira: caixa?.caixa.conta_financeira?.id,
            caixa_movimento: null,
            plano_contas: null,
            compensado: false,
            cancelado: false,
            historico: "",
        },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                tipo_movimento: Yup
                    .string()
                    .required("O campo 'tipo' é obrigatório.")
                    .typeError("Informe um 'tipo de movimentação' válido"),
                data_movimentacao: Yup
                    .date()
                    .required("O campo 'data da movimentação' é obrigatório.")
                    .typeError("Informe uma 'data' válida"),
                valor: Yup
                    .number()
                    .required("O campo 'valor' é obrigatório.")
                    .typeError("Informe um 'valor' válido"),
                conta_financeira: Yup
                    .number()
                    .required("O campo 'conta financeira' é obrigatório.")
                    .typeError("Informe uma 'conta financeira' válida"),
                caixa_movimento: Yup
                    .number()
                    .required("O campo 'caixa movimento' é obrigatório.")
                    .typeError("Informe um 'caixa de movimentação' válido"),
                historico: Yup.string()
                    .required("O campo 'histórico' é obrigatório.")
                    .max(60, "Numero máximo de caracteres: 60")
                    .typeError("O campo 'histórico' é obrigatório"),
                plano_contas: Yup.number()
                    .required("O campo 'plano de contas' é obrigatório.")
                    .typeError("Informe um 'plano de contas' válido"),
            });

            await formSchema.validate(values, {
                abortEarly: false,
            });

            values.data_movimentacao = dataToStr(values.data_movimentacao, "yyyy-MM-dd");
            values.valor = formatarCasasDecimais(values.valor);

            showLoading();
            let resposta = await axiosPost(`/financeiro/movimentacao-contas-corrente/`, values);
            hideLoading();

            if (resposta.status === 201) {
                showLoading();
                let resposta = await axiosPatch(`/vendas/voucher/${voucher.id}/`, { situacao: "U" });
                hideLoading();

                if (resposta.status === 200 || resposta.status === 204) {
                    showSuccess({
                        summary: "Sucesso",
                        detail: "Voucher utilizado com sucesso!",
                        life: 3000,
                    });
        
                    if (typeof onFinish === "function") onFinish();
                } else {
                    throw new Error();
                }
            } else {
                throw new Error();
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            } else {
                showError({
                    summary: "Erro",
                    detail: "Desculpe, não conseguimos processar a sua requisição.",
                    life: 3000,
                });
            }
        }
    }

    const listarPlanosFinanceiros = useCallback(async () => {
        showLoading();
        const resposta = await axiosGet("/financeiro/planos-contas-financeiras?limit=10000");
        hideLoading();

        if (resposta.status === 200 || resposta.status === 201) {
            setListaPlanos(resposta.data.results);
        }
    }, [showLoading, hideLoading]);

    const listarContasFinanceiras = useCallback(async () => {
        // showLoading();
        // const resposta = await axiosGet("/financeiro/contas-financeiras?limit=1000");
        // hideLoading();

        // if (resposta.status === 200 || resposta.status === 201) {
            setListaContas([caixa.caixa.conta_financeira]);
        // }
    }, [caixa]);

    const listarCaixasMovimento = useCallback(async () => {
        showLoading();
        const resposta = await axiosGet(`/financeiro/caixas-movimento/?query={id,caixa}&caixa=${caixa.caixa.id}&operador=${caixa.operador.id}&datahora_fechamento__isnull=false`);
        hideLoading();

        if (resposta.status === 200 || resposta.status === 201) {
            setListaCaixas(resposta.data.results);
        }
    }, [caixa, showLoading, hideLoading])

    useEffect(() => {
        listarPlanosFinanceiros();
        listarContasFinanceiras();
        listarCaixasMovimento();
    }, [listarPlanosFinanceiros, listarContasFinanceiras, listarCaixasMovimento]);

    useEffect(() => {
        formik.resetForm();
    }, [voucher])

    return (
        <div className="p-grid">
            <div className="p-col-12">
                <form onSubmit={formik.handleSubmit}>
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-12 p-md-3">
                            <label htmlFor="tipo_movimento">Tipo *</label>
                            <Dropdown
                                id="tipo_movimento"
                                name="tipo_movimento"
                                placeholder="Selecione uma conta financeira"
                                options={NATUREZA_PLANO_CONTAS}
                                filter
                                filterBy="label"
                                optionValue="id"
                                optionLabel="label"
                                value={formik.values.tipo_movimento}
                                onChange={formik.handleChange}
                                className={classNames({ "p-invalid": formik.errors.tipo_movimento })}
                            />
                            {formik.errors.tipo_movimento && (
                                <small className="p-error">{formik.errors.tipo_movimento}</small>
                            )}
                        </div>
                        <div className="p-field p-col-12 p-md-6">
                            <label htmlFor="conta_financeira">Conta Financeira *</label>
                            <Dropdown
                                id="conta_financeira"
                                name="conta_financeira"
                                placeholder="Selecione uma conta financeira"
                                options={listaContas}
                                filter
                                disabled
                                filterBy="descricao"
                                optionValue="id"
                                optionLabel="descricao"
                                value={formik.values.conta_financeira}
                                onChange={formik.handleChange}
                                className={classNames({ "p-invalid": formik.errors.conta_financeira })}
                            />
                            {formik.errors.conta_financeira && (
                                <small className="p-error">{formik.errors.conta_financeira}</small>
                            )}
                        </div>
                        <div className="p-field p-col-12 p-md-3">
                            <label htmlFor="data_movimentacao">Data movimentação *</label>
                            <MakoCalendar
                                id="data_movimentacao"
                                name="data_movimentacao"
                                valueCalendar={formik.values.data_movimentacao}
                                onChange={formik.handleChange}
                                className={classNames({
                                    "p-invalid": formik.errors.data_movimentacao,
                                })}
                            />
                            {formik.errors.data_movimentacao && (
                                <small className="p-error">{formik.errors.data_movimentacao}</small>
                            )}
                        </div>
                    </div>
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-12 p-md-3">
                            <label htmlFor="valor">Valor *</label>
                            <MakoInputMoeda
                                id="valor"
                                name="valor"
                                min={0}
                                valueMoeda={formik.values.valor}
                                onChangeMoeda={formik.handleChange}
                                className={classNames({ "p-invalid": formik.errors.valor })}
                                disabled
                            />
                            {formik.errors.valor && <small className="p-error">{formik.errors.valor}</small>}
                        </div>
                        <div className="p-field p-col-12 p-md-6">
                            <label htmlFor="plano_contas">Plano de contas *</label>
                            <Dropdown
                                id="plano_contas"
                                name="plano_contas"
                                placeholder="Selecione uma conta financeira"
                                options={listaPlanos}
                                filter
                                filterBy="descricao"
                                optionValue="id"
                                optionLabel="descricao"
                                value={formik.values.plano_contas}
                                onChange={formik.handleChange}
                                className={classNames({ "p-invalid": formik.errors.plano_contas })}
                            />
                            {formik.errors.plano_contas && (
                                <small className="p-error">{formik.errors.plano_contas}</small>
                            )}
                        </div>
                        <div className="p-field p-col-12 p-md-3">
                            <label htmlFor="caixa_movimento">Caixa de movimentação *</label>
                            <Dropdown
                                id="caixa_movimento"
                                name="caixa_movimento"
                                placeholder="Selecione um caixa movimento"
                                options={listaCaixas}
                                filter
                                filterBy="descricao"
                                optionValue="id"
                                optionLabel="caixa.nome"
                                value={formik.values.caixa_movimento}
                                onChange={formik.handleChange}
                                className={classNames({ "p-invalid": formik.errors.caixa_movimento })}
                            />
                            {formik.errors.caixa_movimento && (
                                <small className="p-error">{formik.errors.caixa_movimento}</small>
                            )}
                        </div>
                    </div>
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-12 p-md-12">
                            <label htmlFor="caixa_movimento">Histórico *</label>
                            <InputTextarea
                                id="historico"
                                name="historico"
                                rows={8}
                                value={formik.values.historico}
                                onChange={formik.handleChange}
                                className={classNames({ "p-invalid": formik.errors.historico })}
                            />
                            {formik.errors.historico && <small className="p-error">{formik.errors.historico}</small>}
                        </div>
                    </div>
                    <div className="p-grid p-justify-end p-mt-1 p-mr-1">
                        <Button
                            className="p-button-danger p-button-text"
                            icon="pi pi-times"
                            type="reset"
                            label="Fechar"
                            onClick={() => fecharModal()}
                        />
                        <Button className="p-button-success" icon="pi pi-save" type="submit" label="Gravar" />
                    </div>
                </form>
            </div>
        </div>
    );
};


