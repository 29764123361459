import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useState } from "react";
import axios from "axios";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";

import MakoListagem from "@/components/MakoListagem";
import { axiosGet, axiosPost } from "@/services/http";
import useEmpresa from "@/hooks/useEmpresa";
import useToast from "@/hooks/useToast";

const ConsultarNotasEmitidasContraCnpj = ({ entradaId, cnpjOrigem, fazerUpload, onUpload }, ref) => {
    const [visible, setVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const [loadingUpload, setLoadingUpload] = useState(false);
    const [arquivos, setArquivos] = useState([]);
    const { empresaSelecionadaId } = useEmpresa();
    const { showSuccess, showError, showWarning } = useToast();

    const abrirModal = () => {
        setVisible(true);
    };

    useImperativeHandle(ref, () => ({ abrirModal }));

    async function enviarXml(nomeArquivo, conteudo) {
        const blobFile = new Blob([conteudo], { type: "text/plain" });
        const arquivoXML = new File([blobFile], nomeArquivo, { type: "text/plain" });
        const formData = new FormData();
        formData.append("file", arquivoXML);
        const headers = {
            "Content-Type": "multipart/form-data",
        };
        setLoadingUpload(true);
        const { status, data } = await axiosPost(`/compras/upload-xml-entrada/${entradaId}/`, formData, { headers });
        setLoadingUpload(false);
        if (status === 200) {
            showSuccess({
                summary: "Sucesso",
                detail: "XML enviado com sucesso!",
                life: 1500,
            });
            setTimeout(() => {
                if (typeof onUpload === "function") onUpload();
                setVisible(false);
            }, 2000);
        } else if (status === 400) {
            showWarning({
                summary: "XML não importado",
                detail: data.msg,
                life: 5000,
            });
        } else {
            showError({
                summary: "Erro :(",
                detail: "Desculpe, não foi possível importar o XML.",
                life: 3000,
            });
        }
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button
                    icon="pi pi-upload"
                    disabled={!fazerUpload}
                    loading={loadingUpload}
                    onClick={() => enviarXml(rowData.nome_arquivo, rowData.conteudo)}
                    className="p-button-rounded p-button-help p-mr-2 p-mb-1"
                />
            </div>
        );
    };

    const colunas = [
        { field: "numero_nf", header: "Número da NF-e" },
        { field: "emitente", header: "Emitente" },
        { field: "nome_arquivo", header: "XML" },
        { field: "data_emissao", header: "Data da emissão", dateFormat: "dd/MM/yyyy HH:mm:ss" },
        { field: "action", header: "Ações", style: { width: "10%" }, action: (e) => actionBodyTemplate(e) },
    ];

    const listarArquivos = useCallback(
        async (cancelToken) => {
            let url = `/compras/consultar-notas-cnpj/${empresaSelecionadaId}/`;
            if (cnpjOrigem) url = `${url}?origem=${cnpjOrigem}`;
            setLoading(true);
            const { status, data } = await axiosGet(url, { cancelToken });
            setLoading(false);
            if (status === 200) {
                setArquivos(data.xmls);
                if (data.erro_arquivos?.length > 0)
                    showWarning({
                        summary: "Arquivos não processados",
                        detail: `Existem arquivos que não puderam ser processados ${data.erro_arquivos}.`,
                        life: 3000,
                    });
            } else
                showError({
                    summary: "Erro",
                    detail: "Não foi possível realizar a consulta de notas emitidas contra o CNPJ. Por favor, verifique as configurações da API de serviço de tente novamente.",
                    life: 4000,
                });
        },
        [empresaSelecionadaId, cnpjOrigem, showError, showWarning]
    );

    useEffect(() => {
        const { token, ...cancelToken } = axios.CancelToken.source();
        if (visible) listarArquivos(token);
        return () => {
            cancelToken.cancel();
        };
    }, [listarArquivos, visible]);

    return (
        <Dialog
            header="Consulta de NFe Online"
            visible={visible}
            onHide={() => setVisible(false)}
            style={{ width: "70vw" }}
        >
            <MakoListagem
                colunas={colunas}
                dadosLocal={arquivos}
                configTabela={{
                    paginator: true,
                    loading,
                }}
            />
        </Dialog>
    );
};

export const ModalConsultaNotasCnpj = forwardRef(ConsultarNotasEmitidasContraCnpj);
