import React, { useState, useRef } from "react";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { useFormik } from "formik";
import { InputNumber } from "primereact/inputnumber";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import { Checkbox } from "primereact/checkbox";
import permissoes from "@/assets/constants/permissoes";
import MakoListagem from "@/components/MakoListagem";
import useLoading from "@/hooks/useLoading";
import classNames from "classnames";
import * as Yup from "yup";
import { Divider } from "primereact/divider";
import { MakoAutoComplete } from "@/components/MakoAutoComplete";
import { InputText } from "primereact/inputtext";
import { BotaoDelete } from "@/components/BotaoDelete";
import useHttp from "@/hooks/useHttp";
import useToast from "@/hooks/useToast";
import useEmpresa from "@/hooks/useEmpresa";

export const ServicosForm = ({ opFiscal }) => {
    const [visivel, setVisivel] = useState(false);
    const { showLoading, hideLoading } = useLoading();
    const { httpPost, httpPut } = useHttp();
    const { showSuccess, showError } = useToast();
    const { empresaSelecionadaId } = useEmpresa();
    const listagemRef = useRef(null);

    const { setFieldValue, setValues, resetForm, ...formik } = useFormik({
        enableReinitialize: true,
        initialValues: {
            servico_municipio: null,
            aliquota_pis: 0,
            retem_pis: false,
            aliquota_cofins: 0,
            retem_cofins: false,
            aliquota_ir: 0,
            retem_ir: false,
            aliquota_csll: 0,
            retem_csll: false,
            aliquota_inss: 0,
            retem_inss: false,
            aliquota_cpp: 0,
            retem_cpp: false,
            aliquota_outras_retencoes: 0,
            outras_retencoes: false,
            aliquota_total_tributos: 0,
            empresa: empresaSelecionadaId,
        },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                servico_municipio: Yup.object()
                    .required("O campo é obrigatório.")
                    .typeError("Informe um serviço válido."),
                aliquota_cpp: Yup.number().required("O campo é obrigatório.").typeError("Informe um valor válido"),
                aliquota_inss: Yup.number().required("O campo é obrigatório.").typeError("Informe um valor válido"),
                aliquota_pis: Yup.number().required("O campo é obrigatório.").typeError("Informe um valor válido"),
                aliquota_cofins: Yup.number().required("O campo é obrigatório.").typeError("Informe um valor válido"),
                aliquota_csll: Yup.number().required("O campo é obrigatório.").typeError("Informe um valor válido"),
                aliquota_outras_retencoes: Yup.number()
                    .required("O campo é obrigatório.")
                    .typeError("Informe um valor válido"),
                aliquota_total_tributos: Yup.number()
                    .required("O campo é obrigatório.")
                    .typeError("Informe um valor válido"),
            });

            await formSchema.validate(values, {
                abortEarly: false,
            });

            if (!values.id) {
                const handlers = {
                    201: () => {
                        showSuccess({
                            summary: "Sucesso",
                            detail: "Serviço da operação inserido com sucesso!",
                            life: 1500,
                        });
                        listagemRef.current?.buscarDados();
                        resetForm();
                        setVisivel(false);
                    },
                };

                showLoading();
                await httpPost(
                    {
                        url: "/servicos/itens-operacao-fiscal-servico/",
                        body: {
                            ...values,
                            servico_municipio: values.servico_municipio.id,
                            operacao_servico: opFiscal?.id,
                            empresa: empresaSelecionadaId,
                        },
                    },
                    handlers
                );
                hideLoading();
            } else {
                const handlers = {
                    200: () => {
                        showSuccess({
                            summary: "Sucesso",
                            detail: "Serviço da operação alterado com sucesso!",
                            life: 1500,
                        });
                        listagemRef.current?.buscarDados();
                        resetForm();
                        setVisivel(false);
                    },
                };

                showLoading();
                await httpPut(
                    {
                        url: `/servicos/itens-operacao-fiscal-servico/${values.id}/`,
                        body: {
                            ...values,
                            servico_municipio: values.servico_municipio.id,
                        },
                    },
                    handlers
                );
                hideLoading();
            }
        } catch (error) {
            hideLoading();
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            } else showError();
        }
    }

    const esconderDialog = async () => {
        resetForm();
        setVisivel(false);
    };

    const exibirEditar = (servico) => {
        setValues(servico);
        setVisivel(true);
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <MakoControleAcesso
                    permissao={[permissoes.PRODUTO_PRODUTO_PRODUTOS_EDITAR]}
                    componente={Button}
                    icon="pi pi-pencil"
                    className="p-button-rounded p-button-warning p-mr-2 p-mb-1"
                    tooltip="Alterar cadastro"
                    onClick={() => exibirEditar(rowData)}
                    tooltipOptions={{ position: "left" }}
                />
                <MakoControleAcesso
                    permissao={[permissoes.FISCAL_CADASTRO_OPERACAOFISCALSERVICO_EDITAR]}
                    componente={BotaoDelete}
                    url="/servicos/itens-operacao-fiscal-servico/"
                    objetoId={rowData.id}
                    exigeConfirmacao
                    msgConfirmacao={<span>Deseja realmente excluir o serviço da operação?</span>}
                    classNames="p-mb-1"
                    tooltip="Deletar serviço"
                    tooltipOptions={{ position: "left" }}
                    msgToastErroExclusao="O serviço não pode ser removido da operação."
                    onDelete={() => listagemRef.current?.buscarDados()}
                />
            </div>
        );
    };

    const cabecalhoTabela = (
        <>
            <Button
                label="Adicionar"
                icon="pi pi-plus"
                className="p-button-success p-button-outlined p-mr-2"
                onClick={(e) => setVisivel(true)}
            />
        </>
    );

    const colunas = [
        { field: "servico_municipio.codigo", header: "Código", style: { width: "10%" } },
        { field: "servico_municipio.descricao", header: "Descrição" },
        {
            field: "servico_municipio.municipio",
            header: "Município",
            action: (e) => formataMunicipio(e.servico_municipio.municipio),
        },
        {
            field: "action",
            header: "Ações",
            action: (e) => actionBodyTemplate(e),
            style: { width: "10%" },
        },
    ];

    const formataMunicipio = (cidade) => {
        return `${cidade.nome} - ${cidade.estado.uf}`;
    };

    const autoCompleteTemplate = (servico) => {
        return `${servico.codigo} - ${servico.descricao} (${servico.municipio.nome})`;
    };

    return (
        <>
            <MakoListagem
                ref={listagemRef}
                titulo="Serviços da Operação"
                colunas={colunas}
                painelEsquerdo={cabecalhoTabela}
                urlPesquisa={`/servicos/itens-operacao-fiscal-servico?operacao_servico=${opFiscal?.id}`}
                configTabela={{
                    paginator: true,
                    lazy: true,
                }}
            />
            <Dialog
                header={"Serviço da Operação fiscal"}
                visible={visivel}
                breakpoints={{ "960px": "75vw" }}
                style={{ width: "70vw", display: "block" }}
                onHide={() => esconderDialog()}
            >
                <form onSubmit={formik.handleSubmit}>
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-12 p-md-12">
                            <label htmlFor="servico_municipio">Serviço</label>
                            <MakoAutoComplete
                                id="servico_municipio"
                                name="servico_municipio"
                                value={formik.values.servico_municipio}
                                onChange={({ value }) => {
                                    setFieldValue("servico_municipio", value);
                                    setFieldValue("descricao", value.descricao);
                                }}
                                itemTemplate={autoCompleteTemplate}
                                field="descricao"
                                urlSearch={`/servicos/servicos-municipio?search=`}
                                placeholder="Busque por serviço ou seu respectivo município..."
                                className={classNames({ "p-invalid": formik.errors.servico_municipio })}
                            />
                            {formik.errors.servico_municipio && (
                                <small className="p-error">{formik.errors.servico_municipio}</small>
                            )}
                        </div>
                    </div>
                    {formik.values.servico_municipio?.id ? (
                        <div>
                            <Divider align="center">
                                <b>Dados do Serviço</b>
                            </Divider>
                            <div className="p-fluid p-formgrid p-grid">
                                <div className="p-field p-col-12 p-md-3">
                                    <label htmlFor="codigo">Código</label>
                                    <InputText
                                        id="codigo"
                                        name="codigo"
                                        value={formik.values.servico_municipio.codigo}
                                        disabled
                                    />
                                </div>
                                <div className="p-field p-col-12 p-md-9">
                                    <label htmlFor="descricao">Descrição</label>
                                    <InputText
                                        id="descricao"
                                        name="descricao"
                                        value={formik.values.servico_municipio.descricao}
                                        disabled
                                    />
                                </div>
                            </div>
                            <div className="p-fluid p-formgrid p-grid">
                                <div className="p-field p-col-12 p-md-5">
                                    <label htmlFor="municipio">Município</label>
                                    <InputText
                                        id="municipio"
                                        name="municipio"
                                        value={`${formik.values.servico_municipio.municipio.nome} - ${formik.values.servico_municipio.municipio.estado.uf}`}
                                        disabled
                                    />
                                </div>
                                <div className="p-field p-col-12 p-md-3">
                                    <label htmlFor="aliquota">Alíquota</label>
                                    <InputNumber
                                        id="aliquota"
                                        name="aliquota"
                                        prefix="%"
                                        mode="decimal"
                                        minFractionDigits={2}
                                        maxFractionDigits={2}
                                        value={formik.values.servico_municipio.aliquota}
                                        disabled
                                    />
                                </div>
                                <div className="p-field p-col-12 p-md-4">
                                    <label htmlFor="cnae">Cnae</label>
                                    <InputText
                                        id="cnae"
                                        name="cnae"
                                        value={formik.values.servico_municipio.cnae || "-"}
                                        disabled
                                    />
                                </div>
                            </div>
                        </div>
                    ) : null}
                    <Divider align="center">
                        <b>Alíquotas</b>
                    </Divider>
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-12 p-md-3">
                            <label htmlFor="aliquota_pis">Alíq. PIS</label>
                            <InputNumber
                                id="aliquota_pis"
                                name="aliquota_pis"
                                prefix="%"
                                min={0}
                                mode="decimal"
                                minFractionDigits={2}
                                maxFractionDigits={2}
                                value={formik.values.aliquota_pis}
                                onValueChange={formik.handleChange}
                                className={classNames({ "p-invalid": formik.errors.aliquota_pis })}
                            />
                            {formik.errors.aliquota_pis && (
                                <small className="p-error">{formik.errors.aliquota_pis}</small>
                            )}
                        </div>
                        <div className="p-field p-col-12 p-md-3">
                            <label htmlFor="aliquota_cofins">Alíq. Cofins</label>
                            <InputNumber
                                id="aliquota_cofins"
                                name="aliquota_cofins"
                                prefix="%"
                                min={0}
                                mode="decimal"
                                minFractionDigits={2}
                                maxFractionDigits={2}
                                value={formik.values.aliquota_cofins}
                                onValueChange={formik.handleChange}
                                className={classNames({ "p-invalid": formik.errors.aliquota_cofins })}
                            />
                            {formik.errors.aliquota_cofins && (
                                <small className="p-error">{formik.errors.aliquota_cofins}</small>
                            )}
                        </div>
                        <div className="p-field p-col-12 p-md-3">
                            <label htmlFor="aliquota_ir">Alíq. IR</label>
                            <InputNumber
                                id="aliquota_ir"
                                name="aliquota_ir"
                                prefix="%"
                                min={0}
                                mode="decimal"
                                minFractionDigits={2}
                                maxFractionDigits={2}
                                value={formik.values.aliquota_ir}
                                onValueChange={formik.handleChange}
                                className={classNames({ "p-invalid": formik.errors.aliquota_ir })}
                            />
                            {formik.errors.aliquota_ir && (
                                <small className="p-error">{formik.errors.aliquota_ir}</small>
                            )}
                        </div>
                        <div className="p-field p-col-12 p-md-3">
                            <label htmlFor="aliquota_csll">Alíq. Csll</label>
                            <InputNumber
                                id="aliquota_csll"
                                name="aliquota_csll"
                                prefix="%"
                                min={0}
                                mode="decimal"
                                minFractionDigits={2}
                                maxFractionDigits={2}
                                value={formik.values.aliquota_csll}
                                onValueChange={formik.handleChange}
                                className={classNames({ "p-invalid": formik.errors.aliquota_csll })}
                            />
                            {formik.errors.aliquota_csll && (
                                <small className="p-error">{formik.errors.aliquota_csll}</small>
                            )}
                        </div>
                    </div>
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-12 p-md-3">
                            <label htmlFor="aliquota_inss">Alíq. Inss</label>
                            <InputNumber
                                id="aliquota_inss"
                                name="aliquota_inss"
                                prefix="%"
                                min={0}
                                mode="decimal"
                                minFractionDigits={2}
                                maxFractionDigits={2}
                                value={formik.values.aliquota_inss}
                                onValueChange={formik.handleChange}
                                className={classNames({ "p-invalid": formik.errors.aliquota_inss })}
                            />
                            {formik.errors.aliquota_inss && (
                                <small className="p-error">{formik.errors.aliquota_inss}</small>
                            )}
                        </div>
                        <div className="p-field p-col-12 p-md-3">
                            <label htmlFor="aliquota_cpp">Alíq. CPP</label>
                            <InputNumber
                                id="aliquota_cpp"
                                name="aliquota_cpp"
                                prefix="%"
                                min={0}
                                mode="decimal"
                                minFractionDigits={2}
                                maxFractionDigits={2}
                                value={formik.values.aliquota_cpp}
                                onValueChange={formik.handleChange}
                                className={classNames({ "p-invalid": formik.errors.aliquota_cpp })}
                            />
                            {formik.errors.aliquota_cpp && (
                                <small className="p-error">{formik.errors.aliquota_cpp}</small>
                            )}
                        </div>
                        <div className="p-field p-col-12 p-md-3">
                            <label htmlFor="aliquota_outras_retencoes">Alíq. outras retenções</label>
                            <InputNumber
                                id="aliquota_outras_retencoes"
                                name="aliquota_outras_retencoes"
                                prefix="%"
                                min={0}
                                mode="decimal"
                                minFractionDigits={2}
                                maxFractionDigits={2}
                                value={formik.values.aliquota_outras_retencoes}
                                onValueChange={formik.handleChange}
                                className={classNames({ "p-invalid": formik.errors.aliquota_outras_retencoes })}
                            />
                            {formik.errors.aliquota_outras_retencoes && (
                                <small className="p-error">{formik.errors.aliquota_outras_retencoes}</small>
                            )}
                        </div>
                        <div className="p-field p-col-12 p-md-3">
                            <label htmlFor="aliquota_total_tributos">Alíq. total tributos</label>
                            <InputNumber
                                id="aliquota_total_tributos"
                                name="aliquota_total_tributos"
                                prefix="%"
                                min={0}
                                mode="decimal"
                                minFractionDigits={2}
                                maxFractionDigits={2}
                                value={formik.values.aliquota_total_tributos}
                                onValueChange={formik.handleChange}
                                className={classNames({ "p-invalid": formik.errors.aliquota_total_tributos })}
                            />
                            {formik.errors.aliquota_total_tributos && (
                                <small className="p-error">{formik.errors.aliquota_total_tributos}</small>
                            )}
                        </div>
                    </div>
                    <Divider align="center">
                        <b>Retenções</b>
                    </Divider>
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field-checkbox p-col-12 p-md-3">
                            <br></br>
                            <Checkbox
                                inputId="retem_pis"
                                id="retem_pis"
                                name="retem_pis"
                                onChange={formik.handleChange}
                                checked={formik.values.retem_pis}
                                value={formik.values.retem_pis}
                            />
                            <label htmlFor="retem_pis">Retém PIS</label>
                        </div>
                        <div className="p-field-checkbox p-col-12 p-md-3">
                            <br></br>
                            <Checkbox
                                inputId="retem_cofins"
                                id="retem_cofins"
                                name="retem_cofins"
                                onChange={formik.handleChange}
                                checked={formik.values.retem_cofins}
                                value={formik.values.retem_cofins}
                            />
                            <label htmlFor="retem_cofins">Retém Cofins</label>
                        </div>
                        <div className="p-field-checkbox p-col-12 p-md-3">
                            <br></br>
                            <Checkbox
                                inputId="retem_ir"
                                id="retem_ir"
                                name="retem_ir"
                                onChange={formik.handleChange}
                                checked={formik.values.retem_ir}
                                value={formik.values.retem_ir}
                            />
                            <label htmlFor="retem_ir">Retém IR</label>
                        </div>
                        <div className="p-field-checkbox p-col-12 p-md-3">
                            <br></br>
                            <Checkbox
                                inputId="retem_csll"
                                id="retem_csll"
                                name="retem_csll"
                                onChange={formik.handleChange}
                                checked={formik.values.retem_csll}
                                value={formik.values.retem_csll}
                            />
                            <label htmlFor="retem_csll">Retém Csll</label>
                        </div>
                        <div className="p-field-checkbox p-col-12 p-md-3">
                            <br></br>
                            <Checkbox
                                inputId="retem_inss"
                                id="retem_inss"
                                name="retem_inss"
                                onChange={formik.handleChange}
                                checked={formik.values.retem_inss}
                                value={formik.values.retem_inss}
                            />
                            <label htmlFor="retem_inss">Retém Inss</label>
                        </div>
                        <div className="p-field-checkbox p-col-12 p-md-3">
                            <br></br>
                            <Checkbox
                                inputId="retem_cpp"
                                id="retem_cpp"
                                name="retem_cpp"
                                onChange={formik.handleChange}
                                checked={formik.values.retem_cpp}
                                value={formik.values.retem_cpp}
                            />
                            <label htmlFor="retem_cpp">Retém CPP</label>
                        </div>
                        <div className="p-field-checkbox p-col-12 p-md-3">
                            <br></br>
                            <Checkbox
                                inputId="outras_retencoes"
                                id="outras_retencoes"
                                name="outras_retencoes"
                                onChange={formik.handleChange}
                                checked={formik.values.outras_retencoes}
                                value={formik.values.outras_retencoes}
                            />
                            <label htmlFor="outras_retencoes">Outras retenções</label>
                        </div>
                    </div>
                    <div className="p-grid p-col-12 p-md-12 p-mt-1">
                        <Button
                            icon={formik.values.id ? "pi pi-save" : "pi pi-plus"}
                            type="submit"
                            label={formik.values.id ? "Salvar" : "Inserir"}
                            className="p-button-success p-mr-2"
                        />
                        <Button
                            icon="pi pi-times"
                            type="reset"
                            label="Fechar"
                            className="p-button-danger"
                            onClick={() => esconderDialog()}
                        />
                    </div>
                </form>
            </Dialog>
        </>
    );
};
