import React, { useState } from "react";
import { AutoComplete } from "primereact/autocomplete";

import { axiosGet } from "@/services/http";

export const MakoAutoComplete = ({
    urlSearch,
    setTotalRegistros,
    minCaracteresBusca = 1,
    aposPesquisar,
    disabled,
    ...props
}) => {
    const [filtered, setFiltered] = useState([]);
    const [loading, setLoading] = useState(false);

    async function pesquisar(event) {
        let _filtered = [];
        if (urlSearch && event.query.length >= minCaracteresBusca) {
            setLoading(true);
            const resp = await axiosGet(urlSearch + event.query);
            setLoading(false);

            if (resp.status === 200) {
                const { count, results } = resp.data;

                if (setTotalRegistros) {
                    setTotalRegistros(count);
                }

                if (aposPesquisar) {
                    _filtered = aposPesquisar(results);
                } else {
                    if (results.length > 0) {
                        _filtered = results;
                    }
                }
            }
        }
        setFiltered(_filtered);
    }

    return (
        <AutoComplete
            suggestions={filtered}
            completeMethod={pesquisar}
            disabled={loading || disabled}
            delay={1500}
            {...props}
        />
    );
};
