import React, { useEffect, useMemo } from "react";

import * as Yup from "yup";
import { useFormik } from "formik";

import { classNames } from "primereact/utils";
import { InputText } from "primereact/inputtext";

import { MakoButton as Button } from "@/components/MakoButton";
import { MakoInputPorcentagem } from "@/components/MakoInputs";
import { MakoActionsButtons } from "@/components/MakoActionsButtons";
import { DropdownParcelas } from "./dropdown-parcelas";
import { MakoInputMoeda } from "@/components/MakoInputMoeda";
import { MakoCalendar } from "@/components/MakoCalendar";
import { Dropdown } from "@/components/Dropdown";
import { Label } from "@/components/Label";

import useTaxaCartaoContaFinanceira from "../../hooks/useTaxaCartaoContaFinanceira";
import useCaixaMovimento from "@/hooks/useCaixaMovimento";
import useLoadingLocal from "@/hooks/useLoadingLocal";
import useHttp from "@/hooks/useHttp";

import { TIPO_TRANSACOES_PENDENCIAS } from "@/assets/constants/constants";
import { dataToStr } from "@/assets/util/datas";

const BASE_URL = "/financeiro/caixas/{caixa}/pendencias/{pendencia}/processar";

const INITIAL_VALUES = {
    conta_financeira: null,
    tipo: 0,
    valor_original: 0,
    quantidade_parcelas: 1,
    administradora: null,
    bandeira: null,
    numero_autorizacao: "",
    data_autorizacao: new Date(),
    maquineta: "",
    nsu: "",
    percentual_taxa: 0,
    valor_taxa: 0,
    valor_liquido: 0,
    valor_calculado: 0,
    juros: 0,
    multa: 0,
    descontos: 0,
};

export const ReceberCartaoCredito = ({ pendencia, successCallback = () => {} }) => {
    const [loading, showLoading, hideLoading] = useLoadingLocal();
    const { caixaMov } = useCaixaMovimento();
    const { httpPost } = useHttp();

    const { setValues, setFieldValue, ...formik } = useFormik({
        initialValues: {
            ...INITIAL_VALUES,
            valor_calculado: pendencia?.valor_receber,
            valor_original: pendencia?.valor_receber,
            forma_recebimento: pendencia?.forma_recebimento,
            id: pendencia?.id,
            tipo_chave: pendencia?.tipo_chave,
        },
        onSubmit: handleSubmit,
    });

    const taxaCartao = useTaxaCartaoContaFinanceira({
        conta_id: formik.values.conta_financeira,
        bandeira_id: formik.values.bandeira,
        numero_parcela: formik.values.numero_parcela,
    });

    async function handleSubmit(values, formikHelpers) {
        try {
            const formSchema = Yup.object().shape({
                conta_financeira: Yup.number().nullable().required("O campo 'conta financeira' é obrigatório"),
                tipo: Yup.number().oneOf(
                    TIPO_TRANSACOES_PENDENCIAS.map((v) => v.value),
                    "Informe um 'tipo' válido"
                ),
                valor_original: Yup.number().min(0.01, "Informe um 'valor transação' maior que 0"),
                quantidade_parcelas: Yup.number().oneOf(
                    Array.from({ length: 18 }, (_, i) => i + 1),
                    "Informe uma 'quantidade de parcelas' válida"
                ),
                administradora: Yup.number().nullable().required("O campo 'administradora' é obrigatório"),
                bandeira: Yup.number().nullable().required("O campo 'bandeira' é obrigatório"),
                numero_autorizacao: Yup.string()
                    .max(15, "Informe ao máximo 15 caractéres")
                    .nullable()
                    .typeError("Informe um 'número autorização' válido"),
                data_autorizacao: Yup.date().nullable().typeError("Informe uma 'data autorização' válida"),
                maquineta: Yup.string()
                    .max(15, "Informe ao máximo 15 caractéres")
                    .nullable()
                    .typeError("Informe um 'terminal/maquineta' válido"),
                nsu: Yup.string()
                    .max(15, "Informe ao máximo 15 caractéres")
                    .nullable()
                    .typeError("Informe um 'nsu' válido"),
                percentual_taxa: Yup.string().typeError("Informe um 'percentual de taxa' válido"),
                valor_taxa: Yup.number().typeError("Informe um 'valor de taxa' válido"),
                valor_liquido: Yup.number().typeError("Informe um valor liquido válido"),
            });

            await formSchema.validate(values, { abortEarly: false });

            const { id, tipo, ...body } = values;

            let success = false;
            let dataCallback = null;

            const handlers = {
                200: ({ data }) => {
                    success = true;
                    dataCallback = data;
                    formikHelpers.resetForm({ values: INITIAL_VALUES });
                },
            };

            const url = BASE_URL.replace("{caixa}", caixaMov?.id).replace("{pendencia}", id);
            showLoading();
            await httpPost(
                { url, body: { ...body, data_autorizacao: dataToStr(body.data_autorizacao, "yyyy-MM-dd HH:mm") } },
                handlers
            );
            hideLoading();
            if (success) successCallback(dataCallback);
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formikHelpers.setErrors(errorMessages);
            }
        }
    }

    const disabledInputsTransacao = useMemo(() => {
        return [1, 2].includes(formik.values.tipo);
    }, [formik.values.tipo]);

    useEffect(() => {
        setFieldValue("percentual_taxa", taxaCartao.porcentagem);
        setFieldValue("valor_taxa", taxaCartao.valor);
        setFieldValue("valor_liquido", formik.values.valor_original - taxaCartao.valor);
    }, [setFieldValue, taxaCartao, formik.values.valor_original]);

    return (
        <form onSubmit={formik.handleSubmit}>
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12 p-md-4">
                    <Label htmlFor="conta_financeira" label="Conta financeira" obrigatorio />
                    <Dropdown
                        id="conta_financeira"
                        name="conta_financeira"
                        url="/financeiro/contas-financeiras?limit=200"
                        optionValue="id"
                        optionLabel="descricao"
                        showClear={false}
                        value={formik.values.conta_financeira}
                        onChange={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.conta_financeira })}
                    />
                    {formik.errors.conta_financeira && (
                        <small className="p-error">{formik.errors.conta_financeira}</small>
                    )}
                </div>
                <div className="p-field p-col-12 p-md-4">
                    <Label htmlFor="tipo" label="Tipo" />
                    <Dropdown
                        id="tipo"
                        name="tipo"
                        options={TIPO_TRANSACOES_PENDENCIAS}
                        optionValue="value"
                        optionLabel="label"
                        showClear={false}
                        value={formik.values.tipo}
                        onChange={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.tipo })}
                    />
                    {formik.errors.tipo && <small className="p-error">{formik.errors.tipo}</small>}
                </div>
                <div className="p-field p-col-12 p-md-4">
                    <Label htmlFor="valor_original" label="Valor da transação" obrigatorio />
                    <MakoInputMoeda
                        id="valor_original"
                        name="valor_original"
                        valueMoeda={formik.values.valor_original}
                        onChangeMoeda={formik.handleChange}
                        disabled
                    />
                </div>
            </div>
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12 p-md-4">
                    <Label htmlFor="quantidade_parcelas" label="Numero parcelas" />
                    <DropdownParcelas
                        id="quantidade_parcelas"
                        name="quantidade_parcelas"
                        showClear={false}
                        value={formik.values.quantidade_parcelas}
                        onChange={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.quantidade_parcelas })}
                    />
                    {formik.errors.quantidade_parcelas && (
                        <small className="p-error">{formik.errors.quantidade_parcelas}</small>
                    )}
                </div>
                <div className="p-field p-col-12 p-md-4">
                    <Label htmlFor="administradora" label="Administradora" obrigatorio />
                    <Dropdown
                        id="administradora"
                        name="administradora"
                        url="/financeiro/administradora-cartao/?limit=200&query={id,nome}&ativo=true&operacao=C"
                        optionValue="id"
                        optionLabel="nome"
                        value={formik.values.administradora}
                        onChange={formik.handleChange}
                        disabled={disabledInputsTransacao}
                        className={classNames({ "p-invalid": formik.errors.administradora })}
                    />
                    {formik.errors.administradora && <small className="p-error">{formik.errors.administradora}</small>}
                </div>
                <div className="p-field p-col-12 p-md-4">
                    <Label htmlFor="bandeira" label="Bandeira" obrigatorio />
                    <Dropdown
                        id="bandeira"
                        name="bandeira"
                        url="/financeiro/bandeiras-cartao/?ativo=True&limit=200"
                        optionValue="id"
                        optionLabel="nome"
                        value={formik.values.bandeira}
                        disabled={disabledInputsTransacao}
                        onChange={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.bandeira })}
                    />
                    {formik.errors.bandeira && <small className="p-error">{formik.errors.bandeira}</small>}
                </div>
            </div>
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12 p-md-4">
                    <Label htmlFor="autorizacao" label="N° autorização" />
                    <InputText
                        id="autorizacao"
                        name="autorizacao"
                        value={formik.values.autorizacao}
                        onInput={formik.handleChange}
                        maxLength={15}
                        disabled={disabledInputsTransacao}
                        className={classNames({ "p-invalid": formik.errors.autorizacao })}
                    />
                    {formik.errors.autorizacao && <small className="p-error">{formik.errors.autorizacao}</small>}
                </div>
                <div className="p-field p-col-12 p-md-4">
                    <Label htmlFor="data_autorizacao" label="Data autorização" />
                    <MakoCalendar
                        id="data_autorizacao"
                        name="data_autorizacao"
                        valueCalendar={formik.values.data_autorizacao}
                        disabled={disabledInputsTransacao}
                        onChange={formik.handleChange}
                    />
                </div>
                <div className="p-field p-col-12 p-md-4">
                    <Label htmlFor="maquineta" label="Terminal/Maquineta" />
                    <InputText
                        id="maquineta"
                        name="maquineta"
                        value={formik.values.maquineta}
                        onInput={formik.handleChange}
                        maxLength={15}
                        disabled={disabledInputsTransacao}
                        className={classNames({ "p-invalid": formik.errors.maquineta })}
                    />
                    {formik.errors.maquineta && <small className="p-error">{formik.errors.maquineta}</small>}
                </div>
            </div>
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12 p-md-4">
                    <Label htmlFor="nsu" label="NSU" />
                    <InputText
                        id="nsu"
                        name="nsu"
                        value={formik.values.nsu}
                        onInput={formik.handleChange}
                        maxLength={15}
                        disabled={disabledInputsTransacao}
                        className={classNames({ "p-invalid": formik.errors.nsu })}
                    />
                    {formik.errors.nsu && <small className="p-error">{formik.errors.nsu}</small>}
                </div>
                <div className="p-field p-col-12 p-md-4">
                    <Label htmlFor="percentual_taxa" label="Taxa (%)" />
                    <MakoInputPorcentagem
                        id="percentual_taxa"
                        name="percentual_taxa"
                        minFractionDigits={2}
                        maxFractionDigits={4}
                        value={formik.values.percentual_taxa}
                        handleChange={formik.handleChange}
                        disabled
                    />
                </div>
                <div className="p-field p-col-12 p-md-4">
                    <Label htmlFor="valor_taxa" label="Valor taxa" />
                    <MakoInputMoeda
                        id="valor_taxa"
                        name="valor_taxa"
                        valueMoeda={formik.values.valor_taxa}
                        onChangeMoeda={formik.handleChange}
                        disabled
                    />
                </div>
            </div>
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12 p-md-4">
                    <Label htmlFor="valor_liquido" label="Valor líquido" />
                    <MakoInputMoeda
                        id="valor_liquido"
                        name="valor_liquido"
                        valueMoeda={formik.values.valor_liquido}
                        onChangeMoeda={formik.handleChange}
                        disabled
                    />
                </div>
            </div>
            <MakoActionsButtons className="p-jc-end">
                <Button loading={loading} label="Confirmar" type="submit" className="p-button p-button-success" />
            </MakoActionsButtons>
        </form>
    );
};
