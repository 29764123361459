import React, { useRef } from "react";
import { Button } from "primereact/button";
import { useHistory } from "react-router-dom";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";

import MakoListagem from "@/components/MakoListagem";
import { PageBase } from "@/components/PageBase";
import { BotaoDelete } from "@/components/BotaoDelete";
import permissoes from "@/assets/constants/permissoes";

const url = "/financeiro/planos-recebimentos/";

export const FinanceiroPlanoRecebimentoPage = () => {
    const listagemRef = useRef(null);
    const history = useHistory();

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <MakoControleAcesso
                    permissao={[permissoes.FINANCEIRO_FINANCEIRO_PLANORECEBIMENTO_EDITAR]}
                    componente={Button}
                    icon="pi pi-pencil"
                    className="p-button-rounded p-button-warning p-mr-2 p-mb-1"
                    tooltip="Alterar cadastro de plano de recebimentos"
                    tooltipOptions={{ position: "left" }}
                    onClick={() =>
                        history.push({
                            pathname: "/financeiro/plano-recebimentos/form",
                            state: { planRecebimento: rowData },
                        })
                    }
                />
                <MakoControleAcesso
                    permissao={[permissoes.FINANCEIRO_FINANCEIRO_PLANORECEBIMENTO_EXCLUIR]}
                    componente={BotaoDelete}
                    url={url}
                    objetoId={rowData.id}
                    classNames="p-mr-2 p-mb-1"
                    exigeConfirmacao
                    msgConfirmacao={
                        <span>
                            Deseja realmente excluir o plano de recebimento <b>{rowData.descricao}</b>?
                        </span>
                    }
                    msgToastErroExclusao="O plano de recebimento não pode ser excluído."
                    tooltip="Deletar plano de recebimento"
                    tooltipOptions={{ position: "left" }}
                    onDelete={() => listagemRef.current?.buscarDados()}
                />
            </div>
        );
    };

    const cabecalhoTabela = (
        <>
            <MakoControleAcesso
                permissao={[permissoes.FINANCEIRO_FINANCEIRO_PLANORECEBIMENTO_INCLUIR]}
                componente={Button}
                label="Novo"
                icon="pi pi-plus"
                className="p-button-success p-mr-2"
                onClick={() => history.push("/financeiro/plano-recebimentos/form")}
            />
        </>
    );

    const colunas = [
        { field: "id", header: "Código", style: { width: "10rem" }, filter: true },
        { field: "descricao", header: "Descrição", style: { minWidth: "25rem" }, filter: true },
        { field: "minimo_parcelas", header: "Min. parcelas", style: { width: "10rem" } },
        { field: "maximo_parcelas", header: "Máx. parcelas", style: { width: "10rem" } },
        { field: "vigencia_inicial", header: "Vigência inicial", style: { width: "12rem" }, dateFormat: "dd/MM/yyyy" },
        { field: "vigencia_final", header: "Vigência final", style: { width: "12rem" }, dateFormat: "dd/MM/yyyy" },
        { field: "taxa_minima", header: "Taxa mínima", style: { width: "12rem" } },
        { field: "taxa_alvo", header: "Taxa alvo", style: { width: "130px" } },
        {
            field: "action",
            header: "Ações",
            frozen: true,
            alignFrozen: "right",
            style: { width: "12rem" },
            action: (e) => actionBodyTemplate(e),
        },
    ];

    return (
        <PageBase>
            <MakoListagem
                ref={listagemRef}
                titulo="Planos de Recebimentos"
                colunas={colunas}
                painelEsquerdo={cabecalhoTabela}
                urlPesquisa={url}
                filtros={{
                    id: { value: "", matchMode: "equals" },
                    descricao: { value: "", matchMode: "equals" },
                }}
                configTabela={{
                    paginator: true,
                    lazy: true,
                    scrollable: true,
                    frozenWidth: "0.5vw",
                    flexGrow: 1,
                    flexBasis: "12rem",
                }}
            />
        </PageBase>
    );
};
