import React, { createContext, useCallback, useState } from "react";
import { gerarId } from "@/assets/util/util";
import { axiosGet, axiosPost, axiosPut } from "@/services/http";
import useLoading from "@/hooks/useLoading";

const NotaServicoContext = createContext({});

export const NotaServicoProvider = ({ children }) => {
    const [submit, setSubmit] = useState(false);
    const [notaServico, setNotaServico] = useState({});
    const [servicos, setServicos] = useState([]);
    const [parcelas, setParcelas] = useState([]);
    const [activeTab, setActiveTab] = useState(0);
    const { showLoading, hideLoading } = useLoading();

    const gerenciarOperacoesCrud = (lista, obj, op) => {
        switch (op) {
            case "novo":
                lista.push({ ...obj, id: gerarId() });
                break;
            case "editar":
                if (typeof obj.id === "number") {
                    if (lista?.length > 0) {
                        const index = lista?.findIndex((e) => e.id === obj.id);
                        lista[index] = obj;
                    }
                } else if (typeof obj.id === "string") {
                    if (lista?.length > 0) {
                        const index = lista?.findIndex((e) => e.id === obj.id);
                        obj.status = "novo";
                        lista[index] = obj;
                    }
                }
                break;
            case "deletar":
                if (typeof obj.id === "number") {
                    if (lista?.length > 0) {
                        const index = lista?.findIndex((e) => e.id === obj.id);
                        if (obj.status === "deletar") {
                            obj.status = "editar";
                            lista[index] = obj;
                        } else {
                            obj.status = "deletar";
                            lista[index] = obj;
                        }
                    }
                } else if (typeof obj.id === "string") {
                    if (lista?.length > 0) {
                        const index = lista?.findIndex((e) => e.id === obj.id);
                        lista.splice(index, 1);
                    }
                }
                break;
            default:
                break;
        }
        return lista;
    };

    const handleDadosGerais = useCallback(
        (dados) => {
            setNotaServico({
                ...notaServico,
                ...dados,
            });
            setSubmit(true);
        },
        [notaServico, setNotaServico]
    );

    const handleServicos = useCallback(
        (op, servico) => {
            let servicos = notaServico.serviconotafiscalservico_set ? notaServico.serviconotafiscalservico_set : [];
            servicos = gerenciarOperacoesCrud(servicos, servico, op);
            setServicos(servicos.filter((aux) => aux.status !== "deletar"));
            setNotaServico({
                ...notaServico,
                serviconotafiscalservico_set: servicos,
            });
        },
        [notaServico]
    );

    const handleParcelas = useCallback(
        (op, parcela) => {
            let parcelas = notaServico.parcelasnotafiscalservico_set ? notaServico.parcelasnotafiscalservico_set : [];
            parcelas = gerenciarOperacoesCrud(parcelas, parcela, op);
            setParcelas(parcelas.filter((aux) => aux.status !== "deletar"));
            setNotaServico({
                ...notaServico,
                parcelasnotafiscalservico_set: parcelas,
            });
        },
        [notaServico]
    );

    const formatarObjCrud = (servicos, sku) => {
        let crud = {};
        servicos.forEach((item) => {
            if (item.status === "novo") {
                if (crud.create) {
                    crud.create.push(item);
                } else {
                    crud = {
                        ...crud,
                        create: [item],
                    };
                }
            } else if (item.status === "editar") {
                if (crud.update) {
                    crud = {
                        ...crud,
                        update: {
                            ...crud.update,
                            [item.id]: item,
                        },
                    };
                } else {
                    crud = {
                        ...crud,
                        update: {
                            [item.id]: item,
                        },
                    };
                }
            } else if (item.status === "deletar") {
                if (crud.remove) {
                    crud.remove.push(item.id);
                } else {
                    crud = {
                        ...crud,
                        remove: [item.id],
                    };
                }
            }
        });

        return crud;
    };

    const handlePreencherServico = useCallback(
        async (idServico) => {
            showLoading();
            const json = await axiosGet(`/servicos/notas-fiscais-servicos/${idServico}/`);
            hideLoading();
            if (json.status === 200) {
                const { serviconotafiscalservico_set, parcelasnotafiscalservico_set, natureza_operacao, ...rest } =
                    json.data;
                setNotaServico({
                    ...rest,
                    natureza_operacao: {
                        ...natureza_operacao,
                        label: `${natureza_operacao.codigo} - ${natureza_operacao.descricao}`,
                    },
                    serviconotafiscalservico_set,
                    parcelasnotafiscalservico_set,
                });
                setServicos(serviconotafiscalservico_set);
                setParcelas(parcelasnotafiscalservico_set);
                setSubmit(true);
            }
        },
        [showLoading, hideLoading]
    );

    const handleServico = async () => {
        const {
            serviconotafiscalservico_set,
            parcelasnotafiscalservico_set,
            municipio_prestador,
            municipio_tomador,
            natureza_operacao,
            endereco_prestador,
            endereco_tomador,
            tomador,
            ...rest
        } = notaServico;

        let postServico = {
            ...rest,
            natureza_operacao: natureza_operacao.id,
            municipio_prestador: municipio_prestador.id,
            municipio_tomador: municipio_tomador.id,
            tomador: tomador.id,
            endereco_prestador: endereco_prestador.id,
            endereco_tomador: endereco_tomador.id,
        };

        if (serviconotafiscalservico_set?.length > 0) {
            let lista = [];
            serviconotafiscalservico_set.forEach((servico) => {
                lista.push({
                    ...servico,
                    servico: servico.servico.id,
                    municipio_prestacao: servico.municipio_prestacao.id,
                    endereco_prestacao: servico.endereco_prestacao.id,
                });
            });
            const servicos = formatarObjCrud(lista);
            postServico = {
                ...postServico,
                serviconotafiscalservico_set: servicos,
            };
        }

        if (parcelasnotafiscalservico_set?.length > 0) {
            const parcelas = formatarObjCrud(parcelasnotafiscalservico_set);
            postServico = {
                ...postServico,
                parcelasnotafiscalservico_set: parcelas,
            };
        }

        if (!postServico.id) {
            const { status, data } = await axiosPost("/servicos/notas-fiscais-servicos/", postServico);
            return { status, data };
        } else {
            const { status, data } = await axiosPut(`/servicos/notas-fiscais-servicos/${postServico.id}/`, postServico);
            return { status, data };
        }
    };

    return (
        <NotaServicoContext.Provider
            value={{
                submit,
                notaServico,
                activeTab,
                servicos,
                parcelas,
                setActiveTab,
                setSubmit,
                handleDadosGerais,
                handleServicos,
                handleServico,
                handleParcelas,
                handlePreencherServico,
            }}
        >
            {children}
        </NotaServicoContext.Provider>
    );
};

export default NotaServicoContext;
