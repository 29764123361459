import React, { useRef, useState } from "react";

import { Button } from "primereact/button";

import { MakoBuscaSkuPersonalizada } from "@/components/MakoBuscaSkuPersonalizada";
import { MakoCalendar } from "@/components/MakoCalendar";
import { Listagem } from "./listagem";

import { dataToStr } from "@/assets/util/datas";
import { Label } from "@/components/Label";

import { addDays } from "date-fns";

import useClearRefs from "@/hooks/useClearRefs";
import { MAKO_ICONS } from "@/assets/constants/constants_styles";

const BASE_DATA = addDays(new Date(), 8);

export const Consultar = () => {
    const [exibirConsulta, setExibirConsulta] = useState(false);
    const [item, setItem] = useState(null);
    const [data, setData] = useState(BASE_DATA);

    const listagemRef = useRef(null);

    useClearRefs(listagemRef);

    const limparConsulta = () => {
        listagemRef.current?.limpar();
        setExibirConsulta(false);
        setItem(null);
    };

    const exibirResultado = () => {
        setExibirConsulta(true);
    };

    return (
        <>
            <div className="p-col-12">
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-10">
                        <MakoBuscaSkuPersonalizada
                            id="sku"
                            name="sku"
                            label="Selecione um produto"
                            skuValue={item}
                            skuChange={(e) => setItem(e)}
                            exibirVisualizacaoSku
                            disabledBusca={exibirConsulta}
                            obrigatorio
                        />
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <Label htmlFor="saldo" label="Data" />
                        <MakoCalendar
                            id="saldo"
                            name="saldo"
                            disabled={exibirConsulta}
                            valueCalendar={data}
                            onChange={(e) => setData(e.target.value)}
                        />
                    </div>
                </div>
                <div className="p-grid p-col-12 p-md-6">
                    <Button
                        label="Consultar"
                        type="button"
                        icon={MAKO_ICONS.PESQUISAR}
                        className="p-button-info"
                        onClick={exibirResultado}
                        disabled={!(item instanceof Object)}
                    />
                    <Button
                        label="Limpar"
                        type="reset"
                        icon={MAKO_ICONS.LIMPAR_FORM}
                        className="p-button-warning p-ml-2"
                        onClick={limparConsulta}
                        disabled={!item}
                    />
                </div>
                <Listagem
                    listagemRef={listagemRef}
                    filtros={{
                        sku: item?.id,
                        data: dataToStr(data, "yyyy-MM-dd"),
                    }}
                    fazerBusca={exibirConsulta}
                />
            </div>
        </>
    );
};
