import React, { useImperativeHandle, useState, forwardRef, useCallback } from "react";

import { classNames } from "primereact/utils";
import { useFormik } from "formik";
import * as Yup from "yup";

import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";

import { MakoButton as Button } from "@/components/MakoButton";
import { MakoActionsButtons } from "@/components/MakoActionsButtons";
import { MakoInputMoeda } from "@/components/MakoInputMoeda";
import { MakoCalendar } from "@/components/MakoCalendar";
import { Dropdown } from "@/components/Dropdown";
import { Label } from "@/components/Label";

import { dataToStr, parseData } from "@/assets/util/datas";

import useDevolucaoFornecedor from "@/hooks/useDevolucaoFornecedor";
import useToast from "@/hooks/useToast";

const INITIAL_VALUES = {
    valor_credito: 0,
    valor_abatido: 0,
    numero_parcela: 1,
    forma_recebimento: null,
    data_vencimento: null,
    competencia: null,
    plano_orcamentario: null,
    historico_padrao: null,
    template_rateio: null,
    pagamento_abatido: null,
    devolucao_fornecedor: null,
    credor: null,
    frete: false,
};

const Modal = ({ successCallback = () => {} }, ref) => {
    const [visible, setVisible] = useState(false);

    const { showError, showSuccess } = useToast();
    const { dadosBasicos, handleParcelas } = useDevolucaoFornecedor();

    const { setFieldValue, setValues, resetForm, ...formik } = useFormik({
        initialValues: INITIAL_VALUES,
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                valor_abatido: Yup.number()
                    .required("O campo 'valor para abater' é obrigatório.")
                    .typeError("O campo 'valor para abater' é obrigatório."),
                vencimento: Yup.date()
                    .required("O campo 'data de vencimento' é obrigatório.")
                    .typeError("O campo 'data de vencimento' é obrigatório."),
            });

            await formSchema.validate(values, {
                abortEarly: false,
            });

            // eslint-disable-next-line no-unused-vars
            const { vencimento, valor, pagar, ...rest } = values;

            const { status } = await handleParcelas(
                {
                    ...rest,
                    data_vencimento: dataToStr(vencimento, "yyyy-MM-dd"),
                    credor: dadosBasicos?.fornecedor?.id,
                },
                formik.values.valor_abatido === 0
            );

            if (![200, 201, 204].includes(status)) showError();
            else {
                showSuccess({
                    summary: "Sucesso!",
                    detail: "O item foi cadastrado com sucesso!",
                    life: 3000,
                });
                resetForm({ values: INITIAL_VALUES });
                successCallback();
                fecharModal();
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            } else showError();
        }
    }

    const abrirModal = useCallback(
        (pagamento) => {
            const {
                id,
                vencimento,
                numero_parcela,
                forma_pagamento,
                valor: _valor,
                valor_pago,
                competencia,
                historico_padrao,
                plano_orcamentario,
                template_rateio,
            } = pagamento;
            const valor = _valor - valor_pago;
            setVisible(true);
            resetForm({
                values: {
                    ...INITIAL_VALUES,
                    valor,
                    numero_parcela,
                    competencia,
                    plano_orcamentario,
                    devolucao_fornecedor: dadosBasicos?.id,
                    forma_recebimento: forma_pagamento?.id,
                    vencimento: parseData(vencimento),
                    data_vencimento: parseData(vencimento),
                    valor_abatido: _valor,
                    pagar: 0,
                    historico_padrao: historico_padrao?.id,
                    template_rateio: template_rateio?.id,
                    pagamento_abatido: id,
                },
            });
        },
        [resetForm, dadosBasicos]
    );

    const abrirModalEdicao = useCallback(
        (parcela, valor_pagamento) => {
            setVisible(true);
            resetForm({
                values: {
                    ...INITIAL_VALUES,
                    ...parcela,
                    forma_recebimento: parcela.forma_recebimento?.id,
                    vencimento: parseData(parcela.data_vencimento),
                    data_vencimento: parseData(parcela.data_vencimento),
                    valor: valor_pagamento,
                    pagar: valor_pagamento - parcela.valor_abatido,
                    historico_padrao: parcela.historico_padrao?.id,
                    template_rateio: parcela.template_rateio?.id,
                    valor_abatido: parseFloat(parcela.valor_abatido),
                },
            });
        },
        [resetForm]
    );

    const fecharModal = () => {
        setVisible(false);
    };

    const onChangeValorAbater = useCallback(
        (e) => {
            const { value } = e;
            const { valor } = formik.values;
            const _value = value || 0;
            setFieldValue("pagar", valor - _value);
            formik.handleChange(e);
        },
        [formik, setFieldValue]
    );

    useImperativeHandle(ref, () => ({ abrirModal, abrirModalEdicao }), [abrirModal, abrirModalEdicao]);

    return (
        <Dialog
            visible={visible}
            header="Editando parcela da devolução ao fornecedor"
            style={{ width: "55vw" }}
            breakpoints={{ "960px": "75vw" }}
            onHide={fecharModal}
        >
            <form onSubmit={formik.handleSubmit}>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-2">
                        <Label htmlFor="numero_parcela" label="Parcela" />
                        <InputText
                            id="numero_parcela"
                            name="numero_parcela"
                            value={formik.values.numero_parcela}
                            disabled
                        />
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <Label htmlFor="data_vencimento" label="Vencimento original" />
                        <MakoCalendar
                            id="data_vencimento"
                            name="data_vencimento"
                            value={formik.values.data_vencimento}
                            disabled
                        />
                    </div>
                    <div className="p-field p-col-12 p-md-4">
                        <Label htmlFor="forma_recebimento" label="Forma de pagamento" />
                        <Dropdown
                            id="forma_recebimento"
                            name="forma_recebimento"
                            url="/financeiro/formas-recebimentos/?query={id,descricao}&ativo=true&limit=50"
                            optionValue="id"
                            optionLabel="descricao"
                            value={formik.values.forma_recebimento}
                            disabled
                        />
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <Label htmlFor="vencimento" label="Vencimento" obrigatorio />
                        <MakoCalendar
                            id="vencimento"
                            name="vencimento"
                            value={formik.values.vencimento}
                            minDate={parseData(dadosBasicos?.data_devolucao)}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.vencimento })}
                        />
                        {formik.errors.vencimento && <small className="p-error">{formik.errors.vencimento}</small>}
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-3">
                        <Label htmlFor="valor" label="Valor da parcela" />
                        <MakoInputMoeda id="valor" name="valor" value={formik.values.valor} disabled />
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <Label htmlFor="valor_abatido" label="Valor p/ abater" />
                        <MakoInputMoeda
                            id="valor_abatido"
                            name="valor_abatido"
                            value={formik.values.valor_abatido}
                            min={0}
                            max={dadosBasicos?.valor_total}
                            onChangeMoeda={onChangeValorAbater}
                            className={classNames({ "p-invalid": formik.errors.valor_abatido })}
                        />
                        {formik.errors.valor_abatido && (
                            <small className="p-error">{formik.errors.valor_abatido}</small>
                        )}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <Label htmlFor="pagar" label="Valor p/ pagar" />
                        <MakoInputMoeda id="pagar" name="pagar" value={formik.values.pagar} disabled />
                    </div>
                </div>
                <MakoActionsButtons className="p-justify-end p-mt-2">
                    <Button type="submit" label="Confirmar" />
                </MakoActionsButtons>
            </form>
        </Dialog>
    );
};

export const ModalEditarParcela = forwardRef(Modal);
