import React, { forwardRef, useCallback, useImperativeHandle, useState } from "react";
import classNames from "classnames";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { useFormik } from "formik";
import { Label } from "@/components/Label";
import { Dropdown } from "@/components/Dropdown";
import { CamposObrigatorios } from "@/components/CamposObrigatorios";
import { axiosPost } from "@/services/http";
import useToast from "@/hooks/useToast";
import * as Yup from "yup";
import useLoading from "@/hooks/useLoading";
import useEmpresa from "@/hooks/useEmpresa";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const Modal = ({ aposSalvar }, ref) => {
    const [visible, setVisible] = useState(false);
    const { showLoading, hideLoading } = useLoading();
    const { showSuccess, showError } = useToast();
    const { empresaSelecionadaId } = useEmpresa();
    const history = useHistory();

    const { setValues, setFieldValue, resetForm, ...formik } = useFormik({
        initialValues: {
            operacao_fiscal: null,
            cfop: null,
            ocorrencia: null,
            sku: null,
        },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                operacao_fiscal: Yup.number().required("O campo é obrigatório."),
                cfop: Yup.number().required("O campo é obrigatório."),
            });

            const dadosValidos = await formSchema.validate(values, { abortEarly: false });

            showLoading();
            const { status, data } = await axiosPost(`/fiscal/inserir-nota-assistencia/${empresaSelecionadaId}/`, {
                ...dadosValidos,
            });
            hideLoading();

            if (status === 200) {
                showSuccess({
                    summary: "Sucesso",
                    detail: "Nota de assistência técnica gerada com sucesso.",
                    life: 1500,
                });
                if (typeof aposSalvar === "function") aposSalvar();
                history.push({
                    pathname: "/fiscal/nota-saida/emissao-nota",
                    state: { notaFiscal: data.nota_gerada },
                });
            } else if (status === 409) {
                showError({
                    summary: "Erro",
                    detail: data.msg,
                    life: 3000,
                });
            } else {
                showError({
                    summary: "Erro",
                    detail: "Desculpe, não foi possível processar a requisição.",
                    life: 3000,
                });
            }
        } catch (error) {
            hideLoading();
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            } else
                showError({
                    summary: "Erro",
                    detail: "Desculpe, não conseguimos processar a sua requisição.",
                    life: 3000,
                });
        }
    }

    const abrirModal = (ocorrencia) => {
        setVisible(true);
        setFieldValue("ocorrencia", ocorrencia);
    };

    const fecharModal = () => {
        setVisible(false);
        resetForm();
    };

    useImperativeHandle(ref, () => ({ abrirModal }));

    const aposBuscar = useCallback(async (results) => {
        if (results.length === 1) setFieldValue("cfop", results[0].cfop.id);
        return results.map(({ cfop }) => {
            return {
                id: cfop.id,
                label: `${cfop.codigo} - ${cfop.descricao}`,
            };
        });
    }, []);

    return (
        <Dialog
            header="Inserir Nota Fiscal de Assistência Técnica"
            visible={visible}
            onHide={fecharModal}
            breakpoints={{ "960px": "75vw" }}
            style={{ width: "45vw", display: "block" }}
        >
            <form onSubmit={formik.handleSubmit}>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-12">
                        <Label htmlFor="operacao_fiscal" label="Operação Fiscal" obrigatorio />
                        <Dropdown
                            id="operacao_fiscal"
                            name="operacao_fiscal"
                            url="/fiscal/operacoes-fiscais?assistencia_tecnica=true"
                            optionValue="id"
                            optionLabel="descricao"
                            value={formik.values.operacao_fiscal}
                            showClear={false}
                            onChange={(e) => {
                                setFieldValue("cfop", null);
                                formik.handleChange(e);
                            }}
                            className={classNames({ "p-invalid": formik.errors.operacao_fiscal })}
                        />
                        {formik.errors.operacao_fiscal && (
                            <small className="p-error">{formik.errors.operacao_fiscal}</small>
                        )}
                    </div>
                    <div className="p-field p-col-12 p-md-12">
                        <Label htmlFor="cfop" label="CFOP" obrigatorio />
                        <Dropdown
                            id="cfop"
                            name="cfop"
                            url={`/fiscal/cfop-operacoes-fiscais?operacao_fiscal=${formik.values.operacao_fiscal}`}
                            optionValue="id"
                            optionLabel="label"
                            value={formik.values.cfop}
                            onChange={formik.handleChange}
                            buscar={formik.values.operacao_fiscal}
                            disabled={!!!formik.values.operacao_fiscal}
                            aposBuscar={aposBuscar}
                            className={classNames({ "p-invalid": formik.errors.cfop })}
                        />
                        {formik.errors.cfop && <small className="p-error">{formik.errors.cfop}</small>}
                    </div>
                </div>
                <CamposObrigatorios />
                <div className="p-grid p-col-12 p-md-6">
                    <Button type="submit" label="Inserir" icon="pi pi-plus" className="p-button-info p-mr-2" />
                    <Button
                        type="reset"
                        label="Cancelar"
                        icon="pi pi-times"
                        onClick={() => fecharModal()}
                        className="p-button-danger p-mr-2"
                    />
                </div>
            </form>
        </Dialog>
    );
};

export const ModalInsereNotaAssistencia = forwardRef(Modal);
