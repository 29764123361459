import React, { useState, useRef } from "react";
import { Button } from "primereact/button";
import { Redirect } from "react-router-dom";
import { ConfirmDialog } from "primereact/confirmdialog";
import { axiosDelete } from "@/services/http";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";

import permissoes from "@/assets/constants/permissoes";
import MakoListagem from "@/components/MakoListagem";
import useLoading from "@/hooks/useLoading";
import { Tag } from "primereact/tag";
import { parseNumberToMoneyHTML } from "@/assets/util/util";
import useToast from "@/hooks/useToast";
import {
    BooleanFiltroTemplate,
    CodigoFiltroTemplate,
    TextoFiltroTemplate,
    TimeFiltroTemplate,
} from "@/components/MakoFiltrosCabecalho";

export const RotaEntregaPage = () => {
    const [redirecionar, setRedirecionar] = useState(null);
    const [deleteDialog, setDeleteDialog] = useState(false);

    const [rotas, setRotas] = useState(null);
    const { showLoading, hideLoading } = useLoading();
    const { showSuccess, showError } = useToast();
    const listagemRef = useRef(null);

    const confirmarDelete = (rotas) => {
        setRotas(rotas);
        setDeleteDialog(true);
    };

    const esconderDeleteDialog = () => {
        setDeleteDialog(false);
    };

    const deleteRota = async () => {
        showLoading();
        const resposta = await axiosDelete(`/vendas/rotas-entrega/${rotas.id}`);
        hideLoading();

        if (resposta.status === 200 || resposta.status === 204) {
            showSuccess({
                summary: "Sucesso",
                detail: "Rota deletada com sucesso!",
                life: 3000,
            });

            listagemRef.current?.buscarDados();
        } else {
            showError({
                summary: "Erro",
                detail: "Desculpe, não conseguimos processar a sua requisição.",
                life: 3000,
            });
            hideLoading();
        }
        setDeleteDialog(false);
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <MakoControleAcesso
                    permissao={[permissoes.VENDAS_CADASTRO_ROTAENTREGA_EDITAR]}
                    componente={Button}
                    icon="pi pi-pencil"
                    className="p-button-rounded p-button-warning p-mr-2 p-mb-1"
                    tooltip="Alterar cadastro de rotas"
                    tooltipOptions={{ position: "left" }}
                    onClick={() =>
                        setRedirecionar({
                            pathname: "/vendas/cadastros/rota-entrega/form",
                            state: { rotas: rowData },
                        })
                    }
                />
                <MakoControleAcesso
                    permissao={[permissoes.VENDAS_CADASTRO_ROTAENTREGA_EXCLUIR]}
                    componente={Button}
                    icon="pi pi-trash"
                    className="p-button-rounded p-button-danger p-mr-2 p-mb-1"
                    onClick={() => confirmarDelete(rowData)}
                    tooltip="Deletar rota"
                    tooltipOptions={{ position: "left" }}
                />
            </div>
        );
    };

    const cabecalhoTabela = (
        <>
            <MakoControleAcesso
                permissao={[permissoes.VENDAS_CADASTRO_ROTAENTREGA_INCLUIR]}
                componente={Button}
                label="Novo"
                icon="pi pi-plus"
                className="p-button-success p-mr-2"
                onClick={() => setRedirecionar("/vendas/cadastros/rota-entrega/form")}
            />
        </>
    );

    const retornaSimOuNao = (dia) => {
        if (dia) return <Tag severity="success" value="Sim" style={{ width: "40px" }} />;
        return <Tag severity="danger" value="Não" style={{ width: "40px" }} />;
    };

    const TimesFiltroTemplate = (options) => {
        return <TimeFiltroTemplate inputProps={{ showSeconds: true, placeholder: "HH:mm:ss" }} {...options} />;
    };

    const colunas = [
        {
            field: "id",
            header: "Código",
            style: { minWidth: "50px" },
            filter: true,
            filterElement: CodigoFiltroTemplate,
        },
        {
            field: "descricao",
            header: "Descrição",
            style: { minWidth: "220px" },
            filter: true,
            filterElement: TextoFiltroTemplate,
        },
        {
            field: "hora_inicial",
            header: "Hora inicial",
            style: { minWidth: "120px" },
            filter: true,
            filterElement: TimesFiltroTemplate,
            action: (e) => `${formatStringTime(e.hora_inicial)}`,
        },
        {
            field: "hora_final",
            header: "Hora final",
            style: { minWidth: "120px" },
            filter: true,
            filterElement: TimesFiltroTemplate,
            action: (e) => `${formatStringTime(e.hora_final)}`,
        },
        {
            field: "valor_frete",
            header: "Valor frete",
            style: { minWidth: "120px" },
            action: (e) => parseNumberToMoneyHTML(e.valor_frete),
        },
        {
            field: "segunda",
            header: "Seg.",
            style: { minWidth: "50px" },
            filter: true,
            filterElement: BooleanFiltroTemplate,
            action: (e) => retornaSimOuNao(e.segunda),
        },
        {
            field: "terca",
            header: "Ter.",
            style: { minWidth: "50px" },
            filter: true,
            filterElement: BooleanFiltroTemplate,
            action: (e) => retornaSimOuNao(e.terca),
        },
        {
            field: "quarta",
            header: "Qua.",
            style: { minWidth: "50px" },
            filter: true,
            filterElement: BooleanFiltroTemplate,
            action: (e) => retornaSimOuNao(e.quarta),
        },
        {
            field: "quinta",
            header: "Qui.",
            style: { minWidth: "50px" },
            filter: true,
            filterElement: BooleanFiltroTemplate,
            action: (e) => retornaSimOuNao(e.quinta),
        },
        {
            field: "sexta",
            header: "Sex.",
            style: { minWidth: "50px" },
            filter: true,
            filterElement: BooleanFiltroTemplate,
            action: (e) => retornaSimOuNao(e.sexta),
        },
        {
            field: "sabado",
            header: "Sáb.",
            style: { minWidth: "50px" },
            filter: true,
            filterElement: BooleanFiltroTemplate,
            action: (e) => retornaSimOuNao(e.sabado),
        },
        {
            field: "domingo",
            header: "Dom.",
            style: { minWidth: "50px" },
            filter: true,
            filterElement: BooleanFiltroTemplate,
            action: (e) => retornaSimOuNao(e.domingo),
        },
        {
            field: "action",
            header: "Ações",
            action: (e) => actionBodyTemplate(e),
            style: { minWidth: "120px" },
            frozen: true,
            alignFrozen: "right",
        },
    ];

    const formatStringTime = (time) => {
        const splitedTime = time.split(":");
        return splitedTime[0] + ":" + splitedTime[1];
    };

    return redirecionar ? (
        <Redirect to={redirecionar} />
    ) : (
        <div className="p-grid">
            <div className="p-col-12">
                <div className="card">
                    <MakoListagem
                        ref={listagemRef}
                        titulo="Rotas de Entrega"
                        colunas={colunas}
                        painelEsquerdo={cabecalhoTabela}
                        urlPesquisa={"/vendas/rotas-entrega/"}
                        configTabela={{
                            paginator: true,
                            lazy: true,
                            scrollable: true,
                            scrollHeight: "400px",
                            frozenWidth: "0.5vw",
                            flexGrow: 1,
                            flexBasis: "12rem",
                        }}
                        filter={{
                            id: { value: null, matchMode: "equals" },
                            descricao: {
                                operator: "and",
                                constraints: [{ value: "", matchMode: "contains" }],
                            },
                            hora_inicial: { value: null, matchMode: "equals" },
                            hora_final: { value: null, matchMode: "equals" },
                            valor_frete: { value: null, matchMode: "equals" },
                            segunda: { value: null, matchMode: "equals" },
                            terca: { value: null, matchMode: "equals" },
                            quarta: { value: null, matchMode: "equals" },
                            quinta: { value: null, matchMode: "equals" },
                            sexta: { value: null, matchMode: "equals" },
                            sabado: { value: null, matchMode: "equals" },
                            domingo: { value: null, matchMode: "equals" },
                        }}
                    />
                    <ConfirmDialog
                        visible={deleteDialog}
                        onHide={esconderDeleteDialog}
                        header="Confirmação"
                        message={
                            rotas && (
                                <span>
                                    {"Deseja realmente excluir a rota "}
                                    <b>{rotas.descricao}</b>?
                                </span>
                            )
                        }
                        icon="pi pi-info-circle p-mr-3"
                        accept={deleteRota}
                        acceptLabel="Sim"
                        acceptClassName="p-button-danger"
                        reject={esconderDeleteDialog}
                        rejectLabel="Não"
                    />
                </div>
            </div>
        </div>
    );
};
