import { useCallback } from "react";

import { axiosGet, axiosPost } from "@/services/http";
import useLoading from "./useLoading";
import useToast from "./useToast";
import useEmpresa from "./useEmpresa";

const useImpressaoTermica = () => {
    const { showSuccess, showWarning, showError } = useToast();
    const { showLoading, hideLoading } = useLoading();
    const { empresaSelecionadaId } = useEmpresa();

    const solicitarImpressao = useCallback(
        async ({ chave, impressora, filtros }) => {
            const body = { chave, impressora, filtros, empresa: empresaSelecionadaId };
            showLoading();
            const { status, data } = await axiosPost("/relatorios/impressao-termica/imprimir/", body);
            hideLoading();
            if (status === 200 || status === 204) {
                showSuccess({
                    summary: "Sucesso!",
                    detail: data?.msg || "Impressão realizada com sucesso.",
                    life: 1500,
                });
            } else if (status !== 500) {
                showWarning({
                    summary: "Falhou...",
                    detail: data?.msg || "Desculpe, não foi possível gerar a impressão.",
                    life: 3000,
                });
            } else {
                showError({
                    summary: "Erro :(",
                    detail: data?.msg || "Desculpe, não foi possível gerar a impressão.",
                    life: 3000,
                });
            }
        },
        [showLoading, hideLoading, showSuccess, showWarning, showError, empresaSelecionadaId]
    );

    const reimprimir = useCallback(
        async (id) => {
            showLoading();
            const { status, data } = await axiosGet(`/relatorios/impressao-termica/imprimir/${id}/`);
            hideLoading();
            if (status === 200 || status === 204) {
                showSuccess({
                    summary: "Sucesso!",
                    detail: data?.msg || "Reimpressão realizada com sucesso.",
                    life: 1500,
                });
            } else if (status !== 500) {
                showWarning({
                    summary: "Falhou...",
                    detail: data.msg,
                    life: 3000,
                });
            } else {
                showError({
                    summary: "Erro :(",
                    detail: data?.msg || "Desculpe, não foi possível reimprimir.",
                    life: 3000,
                });
            }
        },
        [showLoading, hideLoading, showSuccess, showWarning, showError]
    );

    return { solicitarImpressao, reimprimir };
};

export default useImpressaoTermica;
