import React, { useCallback, useEffect, useRef, useState } from "react";
import { addDays, differenceInDays } from "date-fns";
import { Button } from "primereact/button";

import { ModalEditarParcela } from "./ModalEditarParcela";
import { MakoInputMoeda } from "@/components/MakoInputMoeda";
import MakoListagem from "@/components/MakoListagem";
import { LancamentoParcelas } from "@/components/LancamentoParcelas";
import { axiosGet, axiosPost, axiosPatch } from "@/services/http";
import { TIPO_FORMAS_PAGAMENTO_RECEBIMENTO_CHOICE } from "@/assets/constants/financeiro";
import { dataToStr, parseData } from "@/assets/util/datas";
import { parseMoeda } from "@/assets/util/util";
import { parseNumber } from "@/assets/helpers/number";
import useOrdemCompra from "@/hooks/useOrdemCompra";
import useToast from "@/hooks/useToast";

export const ParcelasOrdemCompraForm = () => {
    const [parcelas, setParcelas] = useState([]);
    const [loading, setLoading] = useState(false);
    const listagemRef = useRef(null);
    const modalParcelaRef = useRef(null);
    const { dadosBasicos, permiteAlterar, handleRemoverParcelas, atualizarValoresOC } = useOrdemCompra();
    const { showWarning, showError } = useToast();

    async function handleSubmit(values) {
        const parcelasInvalidas = parcelas.filter((p) => p.forma_pagamento === null || p.forma_pagamento === undefined);
        if (parcelasInvalidas.length > 0) {
            showWarning({
                summary: "Aviso!",
                detail: "Você precisa configurar as formas de pagamento das parcelas.",
                life: 3000,
            });
            return;
        }
        const totalParcelas = parcelas.reduce((total, parcela) => total + parseNumber(parcela.valor_parcela), 0);
        if (totalParcelas - parseNumber(dadosBasicos.valor_total_parcelas) > 0.01) {
            showWarning({
                summary: "Aviso!",
                detail: "O somatório das parcelas é diferente do valor total de parcelas da ordem de compra.",
                life: 3000,
            });
            return;
        }
        const parcelasFormatadas = parcelas.map((parcela) => ({
            ...parcela,
            ordem_compra: dadosBasicos.id,
        }));
        const infoFinanceira = {
            vencimento_inicial: dataToStr(
                addDays(parseData(dadosBasicos.data_pedido), parcelasFormatadas[0].dias_apos_pedido),
                "yyyy-MM-dd"
            ),
            quantidade_parcelas: parcelas.length,
            forma_pagamento: values.forma_pagamento,
            intervalo_parcelas: parcelasFormatadas.map((parcela) => parcela.dias_apos_pedido).join(","),
        };
        const { status } = await axiosPatch(`/compras/ordens-compra/${dadosBasicos?.id}/`, infoFinanceira);
        if (status === 200) {
            await handleRemoverParcelas();
            const { status: statusParcelas } = await axiosPost("/compras/parcelas-ordens-compra/", parcelasFormatadas);
            if (statusParcelas === 201) {
                await atualizarValoresOC();
            } else {
                showError({
                    summary: "Erro :(",
                    detail: "Desculpe, não foi possível gerar as parcelas da ordem de compra.",
                    life: 3000,
                });
            }
        } else {
            showError({
                summary: "Erro :(",
                detail: "Desculpe, não foi possível gerar as parcelas da ordem de compra.",
                life: 3000,
            });
        }
    }

    const numeroParcelaBodyTemplate = (rowData) => {
        if (rowData.numero_parcela === 0) return <span>ENTRADA</span>;
        return <span>{rowData.numero_parcela}</span>;
    };

    const parseDiasAposVencimentoEmData = (dias) => {
        const { data_pedido } = dadosBasicos;
        const data = typeof data_pedido === "string" ? parseData(data_pedido) : data_pedido;
        return addDays(data, parseInt(dias));
    };

    const vencimentoEstimadoBodyTemplate = (rowData) => {
        const vencimentoEstimado = parseDiasAposVencimentoEmData(rowData.dias_apos_pedido);
        return dataToStr(vencimentoEstimado, "dd/MM/yyyy");
    };

    const formaPgtoBodyTemplate = (rowData) => {
        const formaPgto = TIPO_FORMAS_PAGAMENTO_RECEBIMENTO_CHOICE.find((e) => e.id === rowData.forma_pagamento);
        if (!formaPgto) return <span>N/A</span>;
        return <span>{formaPgto.label}</span>;
    };

    const actionBodyTemplate = (rowData, { rowIndex }) => {
        return (
            <div className="actions">
                <Button
                    icon="pi pi-pencil"
                    className="p-button-rounded p-button-warning p-mr-2"
                    disabled={!permiteAlterar}
                    onClick={() =>
                        modalParcelaRef.current?.abrirModal({
                            ...rowData,
                            data_pedido: dadosBasicos.data_pedido,
                            rowIndex,
                        })
                    }
                />
            </div>
        );
    };

    const rowClass = (rowData) => {
        return {
            "table-recebimentos-overdue": rowData._editada,
        };
    };

    const colunas = [
        { field: "numero_parcela", header: "Nº da parcela", action: (e) => numeroParcelaBodyTemplate(e) },
        { field: "valor_parcela", header: "Valor", money: true },
        { field: "dias_apos_pedido", header: "Dias após o pedido" },
        {
            field: "vencimento_estimado",
            header: "Vencimento estimado",
            action: (e) => vencimentoEstimadoBodyTemplate(e),
        },
        { fiedl: "forma_pagamento", header: "Forma de pagamento", action: (e) => formaPgtoBodyTemplate(e) },
        {
            field: "actions",
            header: "Ações",
            style: { width: "8%" },
            action: actionBodyTemplate,
        },
    ];

    const removerTodasParcelas = async () => {
        const sucesso = await handleRemoverParcelas();
        if (sucesso) listagemRef.current?.buscarDados();
    };

    const popularParcelas = (parcelas) => {
        const diffDataPedidoVencInicial = differenceInDays(parcelas[0].vencimento, parseData(dadosBasicos.data_pedido));
        setParcelas(
            parcelas.map((parcela) => ({
                numero_parcela: parcela.numParcela,
                valor_parcela: parcela.valorParcela,
                dias_apos_pedido: parcela.intervaloParcela + diffDataPedidoVencInicial,
            }))
        );
    };

    const editarParcelas = (e) => {
        let _parcelas = [...parcelas];
        const { rowIndex, alterar_todas, ...parcelaEditada } = e;
        if (rowIndex === 0 && alterar_todas) {
            _parcelas = _parcelas.map((parcela) => {
                return {
                    ...parcela,
                    forma_pagamento: parcelaEditada.forma_pagamento,
                    _editada: true,
                };
            });
        } else {
            _parcelas[rowIndex] = parcelaEditada;
        }
        setParcelas(_parcelas);
    };

    const carregarParcelas = useCallback(async () => {
        setLoading(true);
        const { status, data } = await axiosGet(
            `/compras/parcelas-ordens-compra/?ordem_compra=${dadosBasicos?.id}&limit=120`
        );
        setLoading(false);
        if (status === 200) {
            setParcelas(data.results);
        } else {
            showError({
                summary: "Erro :(",
                detail: "Não foi possível carregar as parcelas da ordem de compra.",
                life: 3000,
            });
        }
    }, [dadosBasicos, showError]);

    useEffect(() => {
        carregarParcelas();
    }, [carregarParcelas]);

    return (
        <>
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12 p-md-2">
                    <label htmlFor="valor-antecipação">Valor da antecipação</label>
                    <MakoInputMoeda
                        id="valor-antecipação"
                        name="valor_antecipado"
                        disabled
                        valueMoeda={dadosBasicos.valor_antecipado || 0}
                    />
                </div>
                <div className="p-field p-col-12 p-md-2">
                    <label htmlFor="valor-total-parcelas">Valor total das parcelas</label>
                    <MakoInputMoeda
                        id="valor-total-parcelas"
                        name="valor_total_parcelas"
                        disabled
                        valueMoeda={dadosBasicos.valor_total_parcelas || 0}
                    />
                </div>
                <div className="p-field p-col-12 p-md-2">
                    <label htmlFor="valor-total-oc">Valor total da OC</label>
                    <MakoInputMoeda
                        id="valor-total-oc"
                        name="valor_total_oc"
                        disabled
                        valueMoeda={dadosBasicos.valor_total_ordem_compra || 0}
                    />
                </div>
            </div>
            <LancamentoParcelas
                valorBruto={dadosBasicos.valor_total_parcelas || 0}
                vencimento={dadosBasicos.data_pedido}
                minVencimento={dadosBasicos.data_pedido}
                onSubmit={popularParcelas}
            />
            <MakoListagem
                dadosLocal={parcelas}
                colunas={colunas}
                configTabela={{ paginator: true, loading, rowClassName: rowClass }}
            />
            {parcelas.filter((p) => p._editada).length > 0 && (
                <p className="p-error p-mt-2">É necessário realizar uma nova gravação das parcelas.</p>
            )}
            <div className="p-mt-3">
                <div className="p-text-left">
                    <Button
                        icon="pi pi-plus"
                        label="Gravar parcelas"
                        disabled={!permiteAlterar}
                        onClick={handleSubmit}
                        className="p-button-success p-mr-2"
                    />
                    <Button
                        icon="pi pi-trash"
                        label="Excluir parcelas"
                        disabled={!permiteAlterar}
                        onClick={removerTodasParcelas}
                        className="p-button-danger"
                    />
                </div>
                <div className="p-text-right">
                    <h5>{`Total das parcelas: ${parseMoeda(
                        parcelas.reduce((total, parcela) => total + parseNumber(parcela.valor_parcela), 0),
                        true
                    )}`}</h5>
                </div>
            </div>
            <ModalEditarParcela
                ref={modalParcelaRef}
                parcelas={parcelas}
                dataPedido={dadosBasicos.data_pedido}
                onSuccess={editarParcelas}
            />
        </>
    );
};
