import React, { useRef } from "react";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import permissoes from "@/assets/constants/permissoes";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import MakoListagem from "@/components/MakoListagem";
import { PageBase } from "@/components/PageBase";
import { useHistory } from "react-router-dom";
import { CodigoFiltroTemplate, TextoFiltroTemplate } from "@/components/MakoFiltrosCabecalho";
import { BotaoDelete } from "@/components/BotaoDelete";

export const EstoqueCargaPage = () => {
    const history = useHistory();
    const toastRef = useRef(null);
    const listagemRef = useRef(null);

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <MakoControleAcesso
                    permissao={[permissoes.ESTOQUE_CADASTRO_CARGA_EDITAR]}
                    componente={Button}
                    icon="pi pi-pencil"
                    className="p-button-rounded p-button-warning p-mr-2 p-mb-1"
                    tooltip="Alterar cadastro de carga"
                    tooltipOptions={{ position: "left" }}
                    onClick={() =>
                        history.push({
                            pathname: "/estoque/cadastros/carga/form",
                            state: { carga: rowData },
                        })
                    }
                />
                <MakoControleAcesso
                    permissao={[permissoes.ESTOQUE_CADASTRO_CARGA_EXCLUIR]}
                    componente={BotaoDelete}
                    url={"/transferencias/carga/"}
                    objetoId={rowData.id}
                    classNames="p-mb-1"
                    msgToastErroExclusao="A carga não pode ser excluída."
                    exigeConfirmacao
                    msgConfirmacao={
                        <span>
                            Deseja mesmo excluir a carga <b>{rowData.id}</b>?
                        </span>
                    }
                    tooltip="Deletar carga"
                    tooltipOptions={{ position: "left" }}
                    onDelete={() => listagemRef.current?.buscarDados()}
                />
            </div>
        );
    };

    const cabecalhoTabela = (
        <>
            <MakoControleAcesso
                permissao={[permissoes.ESTOQUE_CADASTRO_CARGA_INCLUIR]}
                componente={Button}
                label="Novo"
                icon="pi pi-plus"
                className="p-button-success p-mr-2"
                onClick={() => history.push("/estoque/cadastros/carga/form")}
            />
        </>
    );

    const colunas = [
        {
            field: "id",
            header: "Carga",
            style: { minWidth: "10%" },
            filter: true,
            filterElement: CodigoFiltroTemplate,
        },
        {
            field: "rota_transf.descricao",
            header: "Rota de Transferência",
            style: { minWidth: "30%" },
            filter: true,
            filterElement: TextoFiltroTemplate,
            filterField: "rota_transf__descricao",
        },
        {
            field: "motorista.nome",
            header: "Motorista",
            style: { minWidth: "30%" },
            filter: true,
            filterElement: TextoFiltroTemplate,
            filterField: "motorista__nome",
        },
        {
            field: "veiculo.placa",
            header: "Placa do Veículo",
            style: { minWidth: "10%" },
            filter: true,
            filterElement: TextoFiltroTemplate,
            filterField: "veiculo__placa",
        },

        {
            field: "action",
            header: "Ações",
            action: (e) => actionBodyTemplate(e),
            style: { minWidth: "15%" },
        },
    ];

    return (
        <PageBase>
            <Toast ref={toastRef} />
            <MakoListagem
                ref={listagemRef}
                titulo="Cargas"
                colunas={colunas}
                painelEsquerdo={cabecalhoTabela}
                urlPesquisa={"/transferencias/carga/"}
                responsiva
                configTabela={{
                    paginator: true,
                    lazy: true,
                }}
                filtros={{
                    id: { value: "", matchMode: "equals" },
                    rota_transf__descricao: {
                        operator: "and",
                        constraints: [{ value: null, matchMode: ["unaccent_icontains"] }],
                    },
                    motorista__nome: {
                        operator: "and",
                        constraints: [{ value: null, matchMode: "unaccent_icontains" }],
                    },
                    veiculo__placa: {
                        operator: "and",
                        constraints: [{ value: null, matchMode: "icontains" }],
                    },
                }}
            />
        </PageBase>
    );
};
