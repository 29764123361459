import React, { useCallback, useEffect, useRef, useState } from "react";

import * as Yup from "yup";

import { MakoDropdownEmpresas } from "@/components/MakoDropdownEmpresas";
import { MakoCalendar } from "@/components/MakoCalendar";

import { COMPONENTES_ESPECIAIS, MakoFormGerador } from "@/components/MakoFormGerador";
import { MakoBaseRelatorio as R } from "@/components/MakoBaseRelatorio";

import useRelatorio from "@/hooks/useRelatorio";

import { TIPOS_FILTROS_TEMPLATE, gerarFiltroTemplate, montarFiltroTemplate } from "@/assets/util/relatorios";
import { RELATORIOS_EMITIR_FINANCEIRO_CONTASRECEBIDAS} from "@/assets/constants/relatorios";
import { dataToStr } from "@/assets/util/datas";

import useClearRefs from "@/hooks/useClearRefs";
import { TIPO_FORMAS_PAGAMENTO_RECEBIMENTO_CHOICE } from "@/assets/constants/financeiro";
import MakoListagem from "@/components/MakoListagem";
import { formatarCasasDecimais, gerarFileName } from "@/assets/util/util";

import { Dropdown } from "@/components/Dropdown";
import { axiosGet } from "@/services/http";
import useFormatCNPJCPF from "@/hooks/useFomatCNPJCPF";
import { MakoInputCliente } from "@/components/MakoInputs";

const BASE_URL = "/relatorios/contas-recebidas/";

const FILTROS_VIEWSET = {
    empresa: "empresa__id",
    periodo_vencimento_start: "vencimento__gte",
    periodo_vencimento_end: "vencimento__lte",
    fornec: "devedor__id",
    faixa_valor_start: "valor__gte",
    faixa_valor_end: "valor__lte",
    forma_recebimento: "forma_recebimento",
    parcela: "numero_parcela",
    ordenacao:  "order",
    codigo: "conta_financeira__codigo",
    pessoa: "pessoa",
    periodo_recebimento_start: "recebimento__gte",
    periodo_recebimento_end: "recebimento__lte",
    periodo_emissao_start: "emissao__gte",
    periodo_emissao_end: "emissao__lte",
};

const FILE_NAME = gerarFileName("Contas recebidas");

const OPTIONS_ORDENACAO = [
    {label: "Cliente", value: "cliente"},
    {label: "Documento", value: "documento"},
]

export const RelatorioContasRecebidas = () => {
    
    const [url, setUrl] = useState(null);
    const formRef = useRef();
    const empresaSelecionada = useRef();
    const listagemRef = useRef();
    const [buscarListagem, setBuscarListagem] = useState(false);
    const [formatarDocumento] = useFormatCNPJCPF();

    useClearRefs(empresaSelecionada);

    const { solicitarRelatorio } = useRelatorio();

    const { ExportCSV } = R.Buttons;
    const botaoCsv = useRef();
    const filtrosTemplate = [
        {
            key: "empresa",
            label: "Empresa",
            type: TIPOS_FILTROS_TEMPLATE.PESSOA,
        },
        {
            key: ["periodo_emissao_start", "periodo_emissao_end"],
            label: "Período emissão",
            type: TIPOS_FILTROS_TEMPLATE.DATEPERIOD,
        },
        {
            key: ["periodo_vencimento_start", "periodo_vencimento_end"],
            label: "Período de vencimento",
            type: TIPOS_FILTROS_TEMPLATE.DATEPERIOD,
        },
        {
            key: ["periodo_recebimento_start", "periodo_recebimento_end"],
            label: "Período de recebimento",
            type: TIPOS_FILTROS_TEMPLATE.DATEPERIOD,
        },
        {
            key: "fornec",
            label: "Fornecedor",
            type: TIPOS_FILTROS_TEMPLATE.PESSOA,
        },
        {
            key: "forma_recebimento",
            label: "Forma Recebimento",
            optionKey: "id",
            optionLabel: "label",
            options: TIPO_FORMAS_PAGAMENTO_RECEBIMENTO_CHOICE,
            type: TIPOS_FILTROS_TEMPLATE.CHOICE,
        },
        {
            key: "codigo",
            label: "Código",
        },
        {
            key: "parcela",
            label: "Parcela",
        },
        {
            key: ["faixa_valor_start", "faixa_valor_end"],
            label: "Faixa valor",
            type: TIPOS_FILTROS_TEMPLATE.MONEYPERIODO,
        },
        {
            key: "orderBy",
            label: "Ordenado por",
            optionKey: "value",
            optionLabel: "label",
            options: OPTIONS_ORDENACAO,
            type: TIPOS_FILTROS_TEMPLATE.CHOICE,
        },
    ];

    async function handleSubmit({ background, emails, corpo_email, versao = "1", ...values } = null) {
        try {
            const formSchema = Yup.object().shape({
                periodo_vencimento_start: Yup.date()
                    .nullable()
                    .typeError("Informe um 'periodo de vencimento inicial' válido"),
                periodo_vencimento_end: Yup.date()
                    .when("periodo_vencimento_start", {
                        is: (val) => !!val,
                        then: Yup.date()
                            .min(
                                values.periodo_vencimento_start || new Date(),
                                "A 'data de vencimento final' não pode ser anterior a inicial"
                            )

                            .typeError("Informe um 'periodo de vencimento final' válido"),
                        otherwise: Yup.date().nullable(),
                    }),
                periodo_recebimento_start: Yup.date()
                    .nullable()
                    .typeError("Informe um 'periodo de recebimento inicial' válido"),
                periodo_recebimento_end: Yup.date()
                .when("periodo_recebimento_start", {
                    is: (val) => !!val,
                    then: Yup.date()
                        .min(
                            values.periodo_recebimento_start || new Date(),
                            "A 'data de recebimento final' não pode ser anterior a inicial"
                        )

                        .typeError("Informe um 'periodo de recebimento final' válido")
                        .required("Informe um 'periodo de recebimento final' válido"),
                    otherwise: Yup.date().nullable(),
                }),
                periodo_emissao_start: Yup.date()
                .nullable()
                .typeError("Informe um 'periodo de recebimento inicial' válido"),
                periodo_emissao_end: Yup.date()
                .when("periodo_emissao_start", {
                    is: (val) => !!val,
                    then: Yup.date()
                        .min(
                            values.periodo_emissao_start || new Date(),
                            "A 'data de emissão final' não pode ser anterior a inicial"
                        )

                        .typeError("Informe um 'periodo de emissão final' válido")
                        .required("Informe um 'periodo de emissão final' válido"),
                    otherwise: Yup.date().nullable(),
                }),
                empresa: Yup.number()
                    .required("O campo 'empresa' é obrigatório")
                    .typeError("Informe uma 'empresa' válida"),
                fornec: Yup.object()
                    .nullable()
                    .shape({
                        id: Yup.number(),
                    })
                    .typeError("Informe um 'fornecedor' válida."),
                faixa_valor_start: Yup.number().nullable().typeError("Informe uma 'faixa de valor inicial' válida"),
                faixa_valor_end: Yup.number()
                    .when("faixa_valor_start", {
                        is: (val) => val != null,
                        then: Yup.number()
                            .test(
                                "valor validation",
                                "A 'faixa de valor final' não pode ser menor que a inicial",
                                (val) => {
                                    if (val == null) return true;

                                    return typeof val === "number" && val >= values.faixa_valor_start;
                                }
                            )
                            .typeError("Informe um 'faixa de valor final' válido"),
                    })
                    .nullable()
                    .typeError("Informe uma 'faixa de valor final' válida"),
                forma_pgto: Yup.string().nullable().typeError("Informe uma 'forma de pagamento' válida"),
                pessoa: Yup.object().shape({
                    id: Yup.number()
                }),
                parcela: Yup.string()
                    .nullable()
                    .typeError("Informe uma 'quantidade de parcela' válida"),
                codigo: Yup.string()
                    .nullable()
                    .test("codigo validation", "O 'codigo' deve ser maior que 0", (val) => {
                        if (val?.length >= 1 && parseInt(val) > 0) return true;
                        return !val;
                    })
                    .typeError("Informe um 'código' válido"),
                orderBy: Yup.string().nullable().typeError("Seleciona uma 'ordenação' válida."),
            });

            let dadosValidados = await formSchema.validate(values, {
                abortEarly: false,
            });
            
            let filtrosTemplateAplicados = montarFiltroTemplate(filtrosTemplate, {
                ...dadosValidados,
                empresa: dadosValidados?.empresa ? empresaSelecionada.current : null,
                fornec: dadosValidados?.fornec?.id ? dadosValidados?.fornec : null,
            });
            
            filtrosTemplateAplicados = gerarFiltroTemplate(filtrosTemplateAplicados);

            if (dadosValidados.periodo_emissao_start !== null)
                dadosValidados.periodo_emissao_start = dataToStr(dadosValidados.periodo_emissao_start, "yyyy-MM-dd");
            if (dadosValidados.periodo_emissao_end !== null)
                dadosValidados.periodo_emissao_end = dataToStr(dadosValidados.periodo_emissao_end, "yyyy-MM-dd");
            if (dadosValidados.periodo_vencimento_start !== null)
                dadosValidados.periodo_vencimento_start = dataToStr(
                    dadosValidados.periodo_vencimento_start,
                    "yyyy-MM-dd"
                );
            if (dadosValidados.periodo_vencimento_end !== null)
                dadosValidados.periodo_vencimento_end = dataToStr(dadosValidados.periodo_vencimento_end, "yyyy-MM-dd");

            if (dadosValidados.periodo_recebimento_start !== null)
            dadosValidados.periodo_recebimento_start = dataToStr(
                dadosValidados.periodo_recebimento_start,
                "yyyy-MM-dd"
            );
            if (dadosValidados.periodo_recebimento_end !== null)
                dadosValidados.periodo_recebimento_end = dataToStr(dadosValidados.periodo_recebimento_end, "yyyy-MM-dd");

            if (dadosValidados.periodo_emissao_start !== null)
            dadosValidados.periodo_emissao_start = dataToStr(
                dadosValidados.periodo_emissao_start,
                "yyyy-MM-dd"
            );
            if (dadosValidados.periodo_emissao_end !== null)
                dadosValidados.periodo_emissao_end = dataToStr(dadosValidados.periodo_emissao_end, "yyyy-MM-dd");

            if (dadosValidados.pessoa !== null){
                dadosValidados.pessoa = dadosValidados.pessoa.id
            }

            let filtros = {};
            if (dadosValidados) {
                Object.keys(dadosValidados).forEach((key) => {
                    if (dadosValidados[key]) filtros[key] = dadosValidados[key];
                });
            }
            
            if(buscarListagem === false){
                let filtros = {};
                if (dadosValidados) {
                    Object.keys(dadosValidados).forEach((key) => {
                        if (dadosValidados[key] !== undefined && dadosValidados[key] !== null) filtros[key] = dadosValidados[key];
                    });
                    solicitarRelatorio({
                        chave: RELATORIOS_EMITIR_FINANCEIRO_CONTASRECEBIDAS,
                        emails,
                        filtros,
                        versao,
                        filtros_template: filtrosTemplateAplicados,
                        corpo_email,
                        enviar_fila: background,
                    });
                }
            }
            else{
                let filtros = [];
                for (const [k, v] of Object.entries(dadosValidados)) {
                    if (v !== null && v !== "") {
                        if ((k === 'faixa_valor_start' && v === 0)) continue
                        if ((k === 'faixa_valor_end' && v === 0)) continue
                        filtros.push(`${FILTROS_VIEWSET[k]}=${v}`);
                    }
                }
                const _url = `${BASE_URL}?${filtros.join("&")}`;
                console.log(_url)
                setUrl(_url);
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                setErrors(errorMessages);
            }
        }
    }

    const colunas = [
        { field: "empresa", header: "Empresa"},
        { field: "cliente", header: "Cliente", action: (e) => {
            if(e.formatar_monetario){
                return `${e.cliente} - ${formatarDocumento(e.cpf_cnpj)}`
            }
            else{
                return ""
            }
        }},
        { field: 'documento', header: "Documento", action: (e) => {
            if(e.formatar_monetario){
                return e.documento
            }
            else return ""
        }},
        { field: "vencimento", header: "Vencimento", action: (e) => e.vencimento},
        { field: "valor_original", header: "Val. original",align: "right",action: (e) => e.valor_original !== 0 ? `${formatarCasasDecimais(e.valor_original,2, {minimumFractionDigits: 2})}` : ""},
        { field: "acrescimos", header: "Acréscimos", action: (e) => {
            if(e.formatar_monetario){
                return `${formatarCasasDecimais(e.acrescimos,2, {minimumFractionDigits: 2})}`
            }
            else{
                return ""
            }
        },
            align: "right"
        },
        { field: "data_recebimento", header: "Recebimento",align: "center",action: (e) => e.data_recebimento},
        { field: "valor_recebido", header: "Recebido",align: "right",action: (e) => `${formatarCasasDecimais(e.valor_recebido,2, {minimumFractionDigits: 2})}`},
        { field: "forma_recebimento", header: "Forma"},
    ]


    const limparFormulario = () => {
        formRef.current?.resetForm();
    };

    const limparVisualizacao = () => {
        setBuscarListagem(false)
        setUrl(null);
    };

    const submit = (e, limpar = true) => {
        if (limpar) limparVisualizacao();
        formRef.current?.handleSubmit(e);
    };

    const setErrors = (values) => {
        formRef.current?.setErrors(values);
    };

    const setFieldValue = (field, value, shouldValidate) => {
        formRef.current?.setFieldValue(field, value, shouldValidate);
    };

    const onChangeEmpresa = (e) => {
        empresaSelecionada.current = e?.empresa;
    };

    const handleVisualizar = (e) => {
        setBuscarListagem(true);
        submit(e, false);
    }

    const aplicarFiltrosCsv = (values) => {
        return botaoCsv?.current?.filtrosCSV(values);
    };

    const gerarFiltrosDadosValidados = (dados) => {
        let filtros = {};
        if (dados) {
            Object.keys(dados).forEach((key) => {
                if (dados[key] !== null && dados[key] !== "") filtros[key] = dados[key];
            });
        }
        return filtros;
    };

    const filtersOnClick = async () => {
        const { values = {} } = formRef.current?.getFormikInstance();
        let { background, emails, corpo_email, ...dadosValidados } = values;
        if (dadosValidados.periodo_emissao_start !== null)
            dadosValidados.periodo_emissao_start = dataToStr(dadosValidados.periodo_emissao_start, "yyyy-MM-dd");
        if (dadosValidados.periodo_emissao_end !== null)
            dadosValidados.periodo_emissao_end = dataToStr(dadosValidados.periodo_emissao_end, "yyyy-MM-dd");


        if (dadosValidados.periodo_vencimento_start !== null)
        dadosValidados.periodo_vencimento_start = dataToStr(
            dadosValidados.periodo_vencimento_start,
            "yyyy-MM-dd"
        );
        if (dadosValidados.periodo_vencimento_end !== null)
            dadosValidados.periodo_vencimento_end = dataToStr(dadosValidados.periodo_vencimento_end, "yyyy-MM-dd");

        if (dadosValidados.periodo_recebimento_start !== null)
        dadosValidados.periodo_recebimento_start = dataToStr(
            dadosValidados.periodo_recebimento_start,
            "yyyy-MM-dd"
        );
        if (dadosValidados.periodo_recebimento_end !== null)
            dadosValidados.periodo_recebimento_end = dataToStr(dadosValidados.periodo_recebimento_end, "yyyy-MM-dd");
            
        if (dadosValidados.periodo_emissao_start !== null)
        dadosValidados.periodo_emissao_start = dataToStr(
            dadosValidados.periodo_emissao_start,
            "yyyy-MM-dd"
        );
        if (dadosValidados.periodo_emissao_end !== null)
            dadosValidados.periodo_emissao_end = dataToStr(dadosValidados.periodo_emissao_end, "yyyy-MM-dd");

        const filtros = gerarFiltrosDadosValidados(dadosValidados);
        return aplicarFiltrosCsv(filtros);
    };

    const BotaoExportar = (props) => {
        return (
            <ExportCSV
                {...props}
                ref={botaoCsv}
                chave_relatorio={RELATORIOS_EMITIR_FINANCEIRO_CONTASRECEBIDAS}
                fileName={FILE_NAME}
                filtersOnClick={filtersOnClick}
            />
        );
    };

    const carregaUsuarioAtual = useCallback(async () => {
        //showLoading();
        const resposta = await axiosGet("/auth/users/me/");
        //hideLoading();
        console.log(resposta)
        if (resposta.status === 200) setFieldValue("usuario", resposta.data.username);
    }, []);

    useEffect(() => {
        carregaUsuarioAtual();
    }, [carregaUsuarioAtual]);

    return (
        <R.Wrapper titulo={"contas recebidas"}>
            <MakoFormGerador
                ref={formRef}
                formikProps={{
                    initialValues: {
                        periodo_emissao_start: null,
                        periodo_emissao_end: null,
                        periodo_vencimento_start: null,
                        periodo_vencimento_end: null,
                        empresa: null,
                        fornec: null,
                        faixa_valor_start: 0,
                        faixa_valor_end: 0,
                        forma_recebimento: null,
                        parcela: null,
                        codigo: "",
                        orderBy: "",
                    },
                    onSubmit: handleSubmit,
                }}
                camposFormularios={[
                    {
                        label: "Empresa *",
                        inputId: "empresa",
                        inputName: "empresa",
                        component: MakoDropdownEmpresas,
                        componentEspecial: COMPONENTES_ESPECIAIS.EMPRESA,
                        componentProps: {
                            placeholder: "Selecione uma empresa",
                            getOnChange: onChangeEmpresa,
                        },
                        fieldSize: 4,
                    },
                    {
                        label: "Periodo de vencimento",
                        inputId: "periodo_vencimento_start",
                        inputName: "periodo_vencimento_start",
                        component: MakoCalendar,
                        componentProps: {
                            valueKey: "valueCalendar",
                        },
                        fieldSize: 2,
                    },
                    {
                        inputId: "periodo_vencimento_end",
                        inputName: "periodo_vencimento_end",
                        component: MakoCalendar,
                        componentProps: {
                            valueKey: "valueCalendar",
                        },
                        divClassName: "p-mt-4",
                        divStyle: {
                            paddingTop: "5px",
                        },
                        fieldSize: 2,
                    },
                    {
                        label: "Periodo de recebimento",
                        inputId: "periodo_recebimento_start",
                        inputName: "periodo_recebimento_start",
                        component: MakoCalendar,
                        componentProps: {
                            valueKey: "valueCalendar",
                        },
                        fieldSize: 2,
                    },
                    {
                        inputId: "periodo_recebimento_end",
                        inputName: "periodo_recebimento_end",
                        component: MakoCalendar,
                        componentProps: {
                            valueKey: "valueCalendar",
                        },
                        divClassName: "p-mt-4",
                        divStyle: {
                            paddingTop: "5px",
                        },
                        fieldSize: 2,
                    },
                    {
                        label: "Periodo de emissão",
                        inputId: "periodo_emissao_start",
                        inputName: "periodo_emissao_start",
                        component: MakoCalendar,
                        componentProps: {
                            valueKey: "valueCalendar",
                        },
                        fieldSize: 2,
                    },
                    {
                        inputId: "periodo_emissao_end",
                        inputName: "periodo_emissao_end",
                        component: MakoCalendar,
                        componentProps: {
                            valueKey: "valueCalendar",
                        },
                        divClassName: "p-mt-4",
                        divStyle: {
                            paddingTop: "5px",
                        },
                        fieldSize: 2,
                    },
                    {
                        label: "Forma de recebimento:",
                        inputId: "forma_recebimento",
                        inputName: "forma_recebimento",
                        component: Dropdown,
                        componentProps: {
                            placeholder: "Selecione uma forma de recebimento",
                            filter: true,
                            filterBy: "descricao",
                            optionValue: "codigo",
                            optionLabel: "descricao",
                            url: "/financeiro/formas-recebimentos/"
                        },
                        fieldSize: 3,
                    },
                    {
                        label: "Pessoa",
                        inputId: "pessoa",
                        inputName: "pessoa",
                        component: MakoInputCliente,
                        fieldSize: 3,
                    },
                    {
                        label: "Ordenação",
                        inputId: "ordenacao",
                        inputName: "ordenacao",
                        component: Dropdown,
                        componentProps: {
                            placeholder: "Selecione sua forma de ordenação",
                            optionValue: "value",
                            optionLabel: "label",
                            options: OPTIONS_ORDENACAO
                        }
                    }
                ]}
            >
                <R.Buttons.Wrapper>
                    <R.Buttons.Visualizar onClick={handleVisualizar} />
                    <R.Buttons.GerarPdf
                        chave={RELATORIOS_EMITIR_FINANCEIRO_CONTASRECEBIDAS}
                        setFieldValue={setFieldValue}
                        handleSubmit={submit}
                    />
                    <R.Buttons.EnviarEmail handleSubmit={submit} setFieldValue={setFieldValue} />
                    <R.Buttons.Limpar onClick={limparFormulario} />
                </R.Buttons.Wrapper>
            </MakoFormGerador>
            <MakoListagem
                ref={listagemRef}
                colunas={colunas}
                urlPesquisa={url}
                msgTabelaVazia={typeof url !== "string" && "Busca não efetuada"}
                botaoExportar={BotaoExportar}
                configTabela={{
                    paginator: true,
                    lazy: true,
                }}>
                
            </MakoListagem>
        </R.Wrapper>
    );
};