import React from "react";

import { MakoMultiTabs } from "@/components/MakoMultiTabs";

import { Parcelas } from "./parcelas";
import { Recebimentos } from "./recebimentos";

export const Tabs = () => {
    const tabs = [
        {
            header: "Parcelas",
            icon: "pi pi-chart-pie p-mr-2",
            component: <Parcelas />,
        },
        {
            header: "Recebimento",
            icon: "pi pi-dollar p-mr-2",
            component: <Recebimentos />,
        },
    ];
    return (
        <MakoMultiTabs
            tabs={tabs}
            title={null}
            ocultarButtonFinalizar
            ocultarButtonCancelar
            ocultarButtonAnt
            ocultarButtonProx
        />
    );
};
