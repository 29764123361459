import React from "react";

import { Sessoes } from "./sessoes";

export const ReceberCrediario = ({ pendencia }) => {
    return (
        <div>
            <Sessoes.Info />
            <Sessoes.Tabs />
        </div>
    );
};
