import React, { useCallback, useEffect, useState } from "react";
import classNames from "classnames";
import { useHistory } from "react-router-dom";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { useFormik } from "formik";
import * as Yup from "yup";

import { PageBase } from "@/components/PageBase";
import { CamposObrigatorios } from "@/components/CamposObrigatorios";
import { axiosGet, axiosPatch, axiosPost } from "@/services/http";
import useLoading from "@/hooks/useLoading";
import useToast from "@/hooks/useToast";
import { Checkbox } from "primereact/checkbox";
import { TIPO_MODELO_SPED } from "@/assets/constants/constants";
import { Dropdown } from "@/components/Dropdown";
import { Label } from "@/components/Label";
import { MultiSelect } from "primereact/multiselect";

export const ModeloSpedForm = (props) => {
    const { showLoading, hideLoading } = useLoading();
    const history = useHistory();
    const { showSuccess, showError } = useToast();
    const [templates, setTemplates] = useState([]);

    const { resetForm, ...formik } = useFormik({
        initialValues: props.location.state?.modelo || {
            id: null,
            nome: "",
            modelo: null,
            templates: [],
            ativo: true,
        },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                nome: Yup.string().required("O campo 'nome' é obrigatório."),
                modelo: Yup.string().required("O campo 'modelo' é obrigatório."),
                templates: Yup.array().required("O campo 'registros' é obrigatório."),
            });

            await formSchema.validate(values, {
                abortEarly: false,
            });

            if (!values.id) {
                showLoading();
                const resposta = await axiosPost("/fiscal/sped-tipo/", values);
                hideLoading();

                if (resposta.status === 201) {
                    showSuccess({
                        summary: "Sucesso",
                        detail: "Modelo cadastrado com sucesso!",
                        life: 1500,
                    });
                    setTimeout(() => {
                        voltarParaListagem();
                    }, 2000);
                } else {
                    showError({
                        summary: "Erro",
                        detail: "Desculpe, não conseguimos processar a sua requisição.",
                        life: 3000,
                    });
                }
            } else {
                showLoading();
                const resposta = await axiosPatch(`/fiscal/sped-tipo/${values.id}/`, values);
                hideLoading();

                if (resposta.status === 200) {
                    showSuccess({
                        summary: "Sucesso",
                        detail: "Modelo alterado com sucesso!",
                        life: 1500,
                    });
                    setTimeout(() => {
                        voltarParaListagem();
                    }, 2000);
                } else {
                    showError({
                        summary: "Erro",
                        detail: "Desculpe, não conseguimos processar a sua requisição.",
                        life: 3000,
                    });
                }
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            }
        }
    }

    const listarTemplates = useCallback(async () => {
        const { status, data } = await axiosGet("/fiscal/sped-registro-template/");
        if (status === 200) setTemplates(data.results);
    }, []);

    useEffect(() => {
        listarTemplates();
    }, [listarTemplates]);

    const voltarParaListagem = () => {
        resetForm();
        history.push("/fiscal/modelo-sped");
    };

    const itemTemplate = ({ registro, descricao }) => {
        return `${registro} - ${descricao}`;
    };

    return (
        <PageBase>
            <h3>{!formik.values.id ? "Novo Modelo de Sped" : "Manutenção de Modelo de Sped"}</h3>
            <form onSubmit={formik.handleSubmit}>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-6">
                        <Label htmlFor="templates" label="Nome" obrigatorio />
                        <InputText
                            id="nome"
                            name="nome"
                            autoComplete="off"
                            value={formik.values.nome}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.nome })}
                        />
                        {formik.errors.nome && <small className="p-error">{formik.errors.nome}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <Label htmlFor="templates" label="Modelo" obrigatorio />
                        <Dropdown
                            id="modelo"
                            name="modelo"
                            options={TIPO_MODELO_SPED}
                            showClear={false}
                            value={formik.values.modelo}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.modelo })}
                        />
                        {formik.errors.modelo && <small className="p-error">{formik.errors.modelo}</small>}
                    </div>
                    <div className="p-field-checkbox p-col-12 p-md-3 p-mt-5">
                        <Checkbox
                            id="ativo"
                            name="ativo"
                            checked={formik.values.ativo}
                            onChange={formik.handleChange}
                        />
                        <Label htmlFor="ativo" label="Ativo?" />
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-12">
                        <Label htmlFor="templates" label="Registros" obrigatorio />
                        <MultiSelect
                            id="templates"
                            name="templates"
                            options={templates}
                            optionValue="id"
                            optionLabel="registro"
                            placeholder="Selecione os registros"
                            filterBy="bloco,registro"
                            filter
                            itemTemplate={itemTemplate}
                            value={formik.values.templates}
                            onChange={formik.handleChange}
                            className="multiselect-custom"
                        />
                        {formik.errors.templates && <small className="p-error">{formik.errors.templates}</small>}
                    </div>
                </div>
                <CamposObrigatorios />
                <div className="p-grid p-col-12 p-md-6">
                    <Button type="submit" label="Gravar" icon="pi pi-save" className="p-button-info p-mr-2" />
                    <Button
                        type="reset"
                        label="Voltar"
                        icon="pi pi-angle-double-left"
                        className="p-button-danger"
                        onClick={voltarParaListagem}
                    />
                </div>
            </form>
        </PageBase>
    );
};
