import React, { useCallback, useRef } from "react";

import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import { MakoButton } from "@/components/MakoButton";

import { ModaisPendencias } from "../../../modals";

import permissoes from "@/assets/constants/permissoes";

import useCaixaMovimento from "@/hooks/useCaixaMovimento";

const { ReceberCrediario: ModalReceberCrediario } = ModaisPendencias;
const { FINANCEIRO_FINANCEIRO_CAIXAMOV_PENDENCIAS_PROCESSAR } = permissoes;

export const ReceberCrediario = ({ pendencia, onCloseModal = () => {} }) => {
    const { caixaMov } = useCaixaMovimento();
    const modalRef = useRef(null);

    const onClick = useCallback(async () => {
        modalRef.current?.show({});
    }, []);

    return (
        <>
            <MakoControleAcesso
                componente={MakoButton}
                permissao={[FINANCEIRO_FINANCEIRO_CAIXAMOV_PENDENCIAS_PROCESSAR]}
                label="Receber crediário"
                className="p-button p-button-secondary p-py-1 p-text-bold"
                type="button"
                disabled={caixaMov?.bloqueado}
                onClick={onClick}
            />
            <ModalReceberCrediario ref={modalRef} onHide={onCloseModal} />
        </>
    );
};
