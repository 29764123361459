import React, { useState, useRef, useEffect, useCallback } from "react";
import { Button } from "primereact/button";
import { Redirect } from "react-router-dom";
import { Toast } from "primereact/toast";
import { Dropdown } from "primereact/dropdown";
import { Dialog } from "primereact/dialog";
import { axiosGet, axiosPost } from "@/services/http";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import { MakoListagemHierarquica } from "@/components/MakoListagemHierarquica";
import { BotaoDelete } from "@/components/BotaoDelete";

import permissoes from "@/assets/constants/permissoes";
import useLoading from "@/hooks/useLoading";

const url = "/financeiro/planos-contas-financeiras/";
const urlVerPlan = "/financeiro/versoes-planos-contas-financeiras/";
const urlImportar = "/financeiro/importar-versao-plano-conta-financeira/";

export const FinanceiroPlanoContasPage = (props) => {
    const [redirecionar, setRedirecionar] = useState(null);
    const [vincularEmpresa, setVincularEmpresa] = useState(false);
    const [planoSelecionado, setPlanoSelecionado] = useState(null);
    const [dados, setDados] = useState([]);
    const [value, setValue] = useState("0");
    const [origem, setOrigem] = useState("");
    const [botaoAtivo, setBotaoAtivo] = useState(false);
    const [versoes, setVersoes] = useState([]);
    const { showLoading, hideLoading } = useLoading();
    const toastRef = useRef(null);

    const handleDados = useCallback(async () => {
        showLoading();
        const resposta = await axiosGet(`/financeiro/planos-contas-financeiras-estruturas/?id_versao=${value}`);
        hideLoading();

        if (resposta.status === 200) {
            if (resposta.data.length > 0) {
                setDados(resposta.data);
            } else {
                setDados([]);
            }
        } else {
            toastRef.current.show({
                severity: "error",
                summary: "Mako",
                detail: "Não conseguimos processar a sua requisição.",
                life: 3000,
            });
        }
    }, [showLoading, hideLoading, value]);

    async function importarDados() {
        const body = {
            id_origem: origem,
            id_destino: value,
        };

        showLoading();
        const resposta = await axiosPost(urlImportar, body);
        hideLoading();

        if (resposta.status === 200) {
            toastRef.current.show({
                severity: "success",
                summary: "Mako",
                detail: "Sucesso! \nImportação realizada com êxito.",
                life: 3000,
            });
        } else {
            toastRef.current.show({
                severity: "error",
                summary: "Mako",
                detail: "Desculpe \nNão conseguimos listar as versões do plano de contas.",
                life: 3000,
            });
        }
    }

    const getVersaoPlanoContas = useCallback(async () => {
        showLoading();
        const resposta = await axiosGet(urlVerPlan);
        hideLoading();

        if (resposta.status === 200) {
            setVersoes(resposta.data.results);
        } else {
            toastRef.current.show({
                severity: "error",
                summary: "Mako",
                detail: "Desculpe \nNão conseguimos listar as versões do plano de contas.",
                life: 3000,
            });
        }
    }, [showLoading, hideLoading]);

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <MakoControleAcesso
                    permissao={[permissoes.FINANCEIRO_FINANCEIRO_PLANOCONTAS_EDITAR]}
                    componente={Button}
                    icon="pi pi-pencil"
                    className="p-button-rounded p-button-warning p-mr-2 p-mb-1"
                    tooltip="Alterar cadastro de contas"
                    tooltipOptions={{ position: "left" }}
                    onClick={() =>
                        setRedirecionar({
                            pathname: "/financeiro/plano-contas/form",
                            state: { planContas: rowData, versaoId: value },
                        })
                    }
                />
                <MakoControleAcesso
                    permissao={[permissoes.FINANCEIRO_FINANCEIRO_PLANOCONTAS_EXCLUIR]}
                    componente={BotaoDelete}
                    url={url}
                    objetoId={rowData.data.id}
                    exigeConfirmacao
                    msgConfirmacao={
                        <span>
                            Deseja realmente excluir o plano de contas <b>{rowData.data.descricao}</b>?
                        </span>
                    }
                    classNames="p-mb-1"
                    tooltip="Deletar plano de contas"
                    tooltipOptions={{ position: "left" }}
                    msgToastErroExclusao="O plano de contas não pode ser excluído."
                    onDelete={() => handleDados()}
                />
            </div>
        );
    };

    const valueId = (id) => {
        handleDados(id.value);
        setValue(id.value);
        setBotaoAtivo(true);
    };

    const setOrigemId = (id) => {
        setOrigem(id.value);
    };

    useEffect(() => {
        if (props.location.state?.versaoId) {
            setValue(props.location.state.versaoId);
            setBotaoAtivo(true);
        }
        handleDados();
        getVersaoPlanoContas();
    }, [props.location.state?.versaoId, getVersaoPlanoContas, handleDados]);

    const cabecalhoTabela = (
        <>
            <Dropdown
                id="versoes"
                name="versoes"
                style={{ width: "380px" }}
                value={value}
                placeholder="Selecione uma versão"
                optionLabel="descricao"
                className="p-mr-2"
                optionValue="id"
                options={versoes}
                onChange={valueId}
            />
            <MakoControleAcesso
                permissao={[permissoes.FINANCEIRO_FINANCEIRO_PLANOCONTAS_INCLUIR]}
                componente={Button}
                label="Novo"
                icon="pi pi-plus"
                disabled={botaoAtivo ? false : true}
                className="p-button-success p-mr-2"
                onClick={() =>
                    setRedirecionar({
                        pathname: "/financeiro/plano-contas/form",
                        state: {
                            versaoId: value,
                            contaPaiId: planoSelecionado,
                        },
                    })
                }
            />
            <Button
                label="Importar"
                icon="pi pi-download"
                disabled={botaoAtivo ? false : true}
                className="p-button-help p-mr-2"
                onClick={() => exibirImportarContas()}
            />
        </>
    );

    const esconderImportarContas = () => {
        setVincularEmpresa(false);
    };

    const exibirImportarContas = () => {
        setVincularEmpresa(true);
    };

    const verificaTitulo = (titulo, info) => {
        if (titulo) return <b>{info}</b>;
        return info;
    };

    const colunas = [
        {
            field: "codigo",
            header: "Código",
            expander: true,
            style: { width: "150px" },
            action: (e) => verificaTitulo(e.data.conta_titulo, e.data.codigo),
        },
        {
            field: "descricao",
            header: "Descrição",
            style: { width: "200px" },
            action: (e) => verificaTitulo(e.data.conta_titulo, e.data.descricao),
        },
        { field: "natureza", header: "Natureza", style: { width: "70px" } },
        { field: "estrutura", header: "Estrutura", style: { width: "70px" } },
        { field: "nivel", header: "Nível", style: { width: "70px" } },
        {
            field: "action",
            header: "Ações",
            action: (e) => actionBodyTemplate(e),
            style: { width: "120px" },
        },
    ];

    const rowClass = (node) => {
        return {
            "p-text-bold": node.data.conta_titulo === true,
        };
    };

    return redirecionar ? (
        <Redirect to={redirecionar} />
    ) : (
        <div className="p-grid">
            <Toast ref={toastRef} />
            <div className="p-col-12">
                <div className="card">
                    {dados ? (
                        <MakoListagemHierarquica
                            dados={dados}
                            colunas={colunas}
                            cabecalho={cabecalhoTabela}
                            titulo="Plano de Contas"
                            tabelaConfig={{
                                selectionMode: "single",
                                selectionKeys: planoSelecionado,
                                selection: planoSelecionado,
                                onSelectionChange: (e) => setPlanoSelecionado(e.value),
                                scrollable: true,
                                rowClassName: rowClass,
                            }}
                        />
                    ) : (
                        `Nenhum registro selecionado.`
                    )}
                </div>
            </div>
            <Dialog
                header="Importar contas"
                visible={vincularEmpresa}
                breakpoints={{ "960px": "75vw" }}
                style={{ width: "30vw", display: "block" }}
                onHide={() => esconderImportarContas()}
            >
                <div>
                    <div className="p-grid p-fluid p-formgrid" style={{ paddingLeft: "5%" }}>
                        <div className="p-field p-col-12 p-md-4" style={{ width: "100%" }}>
                            <label htmlFor="versoes">Versão alvo: </label>
                            <Dropdown
                                id="versoes"
                                name="versoes"
                                style={{ width: "410px", paddingLeft: "2%" }}
                                value={value}
                                placeholder="Selecione uma versão"
                                optionLabel="descricao"
                                optionValue="id"
                                options={versoes}
                                disabled
                            />
                        </div>
                    </div>
                    <div className="p-grid p-fluid p-formgrid p-justify-end" style={{ paddingLeft: "5%" }}>
                        <div className="p-field p-col-12 p-md-4" style={{ width: "100%" }}>
                            <label htmlFor="importar">Importar dados de:</label>
                            <Dropdown
                                id="importar"
                                name="importar"
                                style={{ width: "410px", paddingLeft: "2%" }}
                                value={origem}
                                placeholder="Selecione uma versão"
                                optionLabel="descricao"
                                optionValue="id"
                                className="p-mr-2"
                                onChange={setOrigemId}
                                options={versoes}
                            />
                        </div>
                    </div>
                    <div className="p-grid p-fluid p-formgrid p-justify-end" style={{ paddingLeft: "5%" }}>
                        <div className="p-col-6 p-md-4 p-justify-center">
                            <Button
                                label="Importar"
                                icon="pi pi-check"
                                className="p-button"
                                onClick={() => importarDados()}
                            />
                        </div>
                    </div>
                </div>
            </Dialog>
        </div>
    );
};
