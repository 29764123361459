import React, { useMemo } from "react";

import { Tag } from "primereact/tag";

import MakoListagem from "@/components/MakoListagem";

import { gerarFiltrosUrl } from "@/assets/util/util";

import useEmpresa from "@/hooks/useEmpresa";

const BASE_URL = "/produtos/saldos-estoque";
const BASE_FILTROS = { sku: null, data: null };
const KEY_FILTRO_EMPRESA = "estoque_empresa__empresa";

const verificaEstoque = ({ quantidade }) => {
    if (quantidade > 0) return <Tag severity="success" value="Em estoque" style={{ width: "100px" }} />;
    return <Tag severity="danger" value="Sem estoque" style={{ width: "100px" }} />;
};

const COLUNAS = [
    { field: "estoque.nome", header: "Estoque" },
    { field: "descricao", header: "Formula" },
    { field: "quantidade", header: "Quantidade", decimal: true, align: "right", style: { width: "10%" } },
    { action: verificaEstoque, align: "center", style: { width: "8%" } },
];

export const Listagem = ({ filtros = BASE_FILTROS, fazerBusca = true, listagemRef }) => {
    const { empresaSelecionadaId: empresa } = useEmpresa();

    const url = useMemo(() => {
        const query = { ...BASE_FILTROS, ...filtros, [KEY_FILTRO_EMPRESA]: empresa };
        return `${BASE_URL}?${gerarFiltrosUrl(query)}`;
    }, [filtros, empresa]);

    const buscar = useMemo(() => {
        return !!empresa && !!filtros?.sku;
    }, [empresa, filtros?.sku]);

    return (
        <div className="p-mt-2">
            {!!!empresa && <div className="p-error p-pb-2">Selecione uma empresa para buscar!</div>}
            <MakoListagem
                ref={listagemRef}
                colunas={COLUNAS}
                urlPesquisa={url}
                fazerBusca={buscar && fazerBusca}
                botaoExportar
                configTabela={{
                    paginator: true,
                    lazy: true,
                }}
            />
        </div>
    );
};
