import React, { useEffect, useRef, useState, useCallback } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { useFormik } from "formik";
import { axiosPost, axiosGet, axiosPatch, axiosDelete } from "@/services/http";
import { useHistory, useLocation } from "react-router-dom";
import { InputNumber } from "primereact/inputnumber";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import { MultiSelect } from "primereact/multiselect";
import { TIPO_CENTRO_ESTOCAGEM_CHOICE } from "@/assets/constants/constants";
import { TabPanel, TabView } from "primereact/tabview";
import { VincularEmpresaForm } from "./formVincularEmpresa";
import { PageBase } from "@/components/PageBase";
import { Dropdown } from "@/components/Dropdown";
import permissoes from "@/assets/constants/permissoes";
import MakoListagem from "@/components/MakoListagem";
import useLoading from "@/hooks/useLoading";
import useAuth from "@/hooks/useAuth";
import classNames from "classnames";
import * as Yup from "yup";
import { ItemTabelaModalForm } from "./modals/itemTabelaModalForm";
import { BotaoDelete } from "@/components/BotaoDelete";
import { EditarItemModalForm } from "./modals/editarItemModalForm";
import { OverlayPanel } from "primereact/overlaypanel";
import { Panel } from "primereact/panel";
import { dataToStr } from "@/assets/util/datas";
import { MakoInputMoeda } from "@/components/MakoInputMoeda";
import { ConfirmDialog } from "primereact";
import { MAKO_ICONS } from "@/assets/constants/constants_styles";
import { formatarCasasDecimais, parseMoeda } from "@/assets/util/util";
import { TextoFiltroTemplate } from "@/components/MakoFiltrosCabecalho";
import { calcularMarkup } from "@/assets/util/calcFinanceiros";
import { MakoButton } from "@/components/MakoButton";

const urlvoltar = "/vendas/cadastros/tabela-precos";

export const ProdutoTabelaPrecosForm = (props) => {
    const [exibeProdutos, setExibeProdutos] = useState(false);
    const [editarProduto, setEditarProduto] = useState(false);
    const [excluirItens, setExcluirItens] = useState(false);
    const [produto, setProduto] = useState(false);
    const [listaPlanos, setListaPlanos] = useState([]);
    const [preco, setPreco] = useState(null);
    const { showLoading, hideLoading } = useLoading();
    const { verifyPermission } = useAuth();
    const listagemRef = useRef(null);
    const history = useHistory();
    const toastRef = useRef(null);
    const inspecionar = useRef(null);
    const { state } = useLocation();

    const { setValues, setFieldValue, ...formik } = useFormik({
        initialValues: {
            id: null,
            nome: "",
            qtd_minima_tag: 1,
            tag_cliente: null,
            tipo_centro_estocagem: null,
            vigencia: null,
            plano_recebimento: [],
            percentual_acrescimo: 0,
        },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                nome: Yup.string().required("O campo 'nome' é obrigatório."),
                qtd_minima_tag: Yup.number().required("O campo 'quantidade mínima' é obrigatório."),
                plano_recebimento: Yup.array().required("O campo 'plano de recebimento' é obrigatório."),
                vigencia: Yup.number().required("O campo 'vigẽncia' é obrigatório."),
            });

            await formSchema.validate(values, {
                abortEarly: false,
            });

            if (!values.id) {
                showLoading();
                const { status, data } = await axiosPost("/produtos/tabelas-preco/", values);
                hideLoading();

                if (status === 201 || status === 200) {
                    toastRef.current.show({
                        severity: "success",
                        summary: "Sucesso",
                        detail: "Tabela de preços cadastrada com sucesso!",
                        life: 1500,
                    });

                    if (formik.values.id) history.push("/vendas/cadastros/tabela-precos");
                    else {
                        formik.resetForm();
                        history.push("/vendas/cadastros/tabela-precos/form", {
                            tabelaPreco: { ...data, vigencia: data.vigencia?.id },
                        });
                    }
                } else {
                    toastRef.current.show({
                        severity: "error",
                        summary: "Erro",
                        detail: "Desculpe, não conseguimos processar a sua requisição.",
                        life: 1500,
                    });
                }
            } else {
                delete values.empresastabelapreco_set;
                delete values.precostabelapreco_set;

                showLoading();
                const { status } = await axiosPatch(`/produtos/tabelas-preco/${values.id}/`, values);
                hideLoading();

                if (status === 200) {
                    toastRef.current.show({
                        severity: "success",
                        summary: "Sucesso",
                        detail: "Cadastro da tabela alterado com sucesso!",
                        life: 1500,
                    });
                } else {
                    toastRef.current.show({
                        severity: "error",
                        summary: "Erro",
                        detail: "Desculpe, não conseguimos processar a sua requisição.",
                        life: 1500,
                    });
                }
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
                hideLoading();
            } else {
                toastRef.current.show({
                    severity: "error",
                    summary: "Erro",
                    detail: "Desculpe, não conseguimos processar a sua requisição.",
                    life: 1500,
                });
                hideLoading();
            }
        }
    }

    async function handleRemove() {
        try {
            showLoading();
            const { status, data } = await axiosDelete(`/produtos/limpar-tabela-preco/${formik.values.id}/`);
            hideLoading();

            if (status.status === 200) {
                toastRef.current.show({
                    severity: "success",
                    summary: "Sucesso",
                    detail: "Itens da tabela removidos com sucesso!",
                    life: 1500,
                });
                listagemRef.current?.buscarDados();
            } else {
                toastRef.current.show({
                    severity: "error",
                    summary: "Erro",
                    detail: data.msg || "Desculpe, não conseguimos processar a sua requisição.",
                    life: 1500,
                });
            }
        } catch (error) {
            toastRef.current.show({
                severity: "error",
                summary: "Erro",
                detail: "Desculpe, não conseguimos processar a sua requisição.",
                life: 1500,
            });
            hideLoading();
        }
    }

    const listarPlanoRecebimentos = useCallback(async () => {
        showLoading();
        const resposta = await axiosGet(
            "/financeiro/planos-recebimentos?query={id,descricao,minimo_parcelas,maximo_parcelas,taxa_alvo,vigencia_inicial,vigencia_final}&limit=300"
        );
        hideLoading();
        if (resposta.status === 200) setListaPlanos(resposta.data.results);
    }, [showLoading, hideLoading]);

    const cancelar = () => {
        formik.resetForm();
        history.push(urlvoltar);
    };

    const cabecalhoTabela = (
        <>
            <MakoControleAcesso
                permissao={[permissoes.VENDAS_CADASTRO_TABELAPRECO_EDITAR]}
                componente={Button}
                label="Adicionar produto(s)"
                icon="pi pi-plus"
                type="button"
                className="p-button-info p-mr-2"
                disabled={state?.apenasVisualizar}
                onClick={() => setExibeProdutos(true)}
            />
            <MakoControleAcesso
                permissao={[permissoes.VENDAS_CADASTRO_TABELAPRECO_EDITAR]}
                componente={Button}
                label="Limpar tabela"
                icon="pi pi-trash"
                type="button"
                className="p-button-warning p-mr-2"
                disabled={state?.apenasVisualizar}
                onClick={() => setExcluirItens(true)}
            />
            <MakoButton
                label="Voltar"
                type="button"
                icon="pi pi-angle-double-left"
                className="p-button-danger p-mr-2"
                to={urlvoltar}
            />
        </>
    );

    const inspecionaProduto = async (produto, evento) => {
        const { precosku_set = [] } = produto.sku;
        const custo_base = precosku_set.length > 0 ? precosku_set[0].custo_base : 0;
        const formacao_origem = precosku_set.length > 0 ? precosku_set[0].formacao_origem : null;
        const ultimo_calculo = precosku_set.length > 0 ? precosku_set[0].ultimo_calculo : null;
        setPreco({
            custo: parseMoeda(custo_base),
            preco: parseMoeda(produto.preco_calculado),
            markup: formatarCasasDecimais(calcularMarkup(produto.preco_calculado, custo_base), 4),
            formacao: formacao_origem?.descricao || null,
            data_calculo: dataToStr(ultimo_calculo, "dd/MM/yyyy HH:mm:ss"),
        });
        inspecionar.current.toggle(evento);
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <MakoControleAcesso
                    permissao={[permissoes.VENDAS_CADASTRO_TABELAPRECO_EDITAR]}
                    componente={Button}
                    icon={MAKO_ICONS.EDITAR}
                    className="p-button-rounded p-button-warning p-mr-2"
                    tooltip="Alterar cadastro de tabela de preços"
                    tooltipOptions={{ position: "left" }}
                    disabled={state?.apenasVisualizar}
                    onClick={() => {
                        setEditarProduto(true);
                        setProduto(rowData);
                    }}
                />
                <MakoControleAcesso
                    permissao={[permissoes.VENDAS_CADASTRO_TABELAPRECO_EDITAR]}
                    componente={BotaoDelete}
                    url="/produtos/tabela-preco-itens/"
                    objetoId={rowData.id}
                    exigeConfirmacao
                    msgConfirmacao={
                        <span>
                            Deseja realmente remover o item <b>{rowData.sku.descricao_reduzida}</b>?
                        </span>
                    }
                    classNames="p-mr-2"
                    tooltip="Remover item"
                    tooltipOptions={{ position: "left" }}
                    msgToastErroExclusao="O item não pode ser removido."
                    disabled={state?.apenasVisualizar}
                    onDelete={() => listagemRef.current?.buscarDados()}
                />
                <MakoControleAcesso
                    permissao={[permissoes.VENDAS_CADASTRO_TABELAPRECO_CONSULTAR]}
                    componente={Button}
                    icon={MAKO_ICONS.PESQUISAR}
                    type="button"
                    className="p-button-rounded p-button-help p-mr-2"
                    tooltip="Inspecionar"
                    tooltipOptions={{ position: "left" }}
                    onClick={(e) => inspecionaProduto(rowData, e)}
                    disabled={state?.apenasVisualizar}
                />
            </div>
        );
    };

    const moedaFiltroTemplate = (options) => {
        return (
            <MakoInputMoeda
                showClear
                valueMoeda={options.value}
                onChangeMoeda={(e) => options.filterCallback(e.value)}
            />
        );
    };

    const textoFiltroTemplate = (options) => {
        return (
            <InputNumber
                min={0}
                minFractionDigits={0}
                useGrouping={false}
                value={options.value}
                onValueChange={(e) => options.filterCallback(e.value)}
            />
        );
    };

    const planoRecebimentoFiltroTemplate = (options) => {
        return (
            <Dropdown
                placeholder="Selecione"
                showClear
                optionLabel="descricao"
                optionValue="id"
                url={"/financeiro/planos-recebimentos?query={id,descricao}&limit=300"}
                value={options.value}
                onChange={(e) => options.filterCallback(e.value)}
            />
        );
    };

    const aposPesquisarProdutos = (data) => {
        return data.map((item) => {
            return {
                ...item,
                sku__codigo: item.sku.codigo,
            };
        });
    };

    const colunas = [
        {
            field: "sku__codigo",
            header: "Código",
            filter: true,
            filterElement: textoFiltroTemplate,
            style: { minWidth: "5%" },
        },
        {
            field: "sku.descricao_reduzida",
            header: "Produto",
            style: { minWidth: "30%" },
            filter: true,
            filterElement: TextoFiltroTemplate,
            filterField: "sku__descricao_reduzida",
        },
        {
            field: "plano_recebimento",
            header: "Plano recebimento",
            filter: true,
            filterElement: planoRecebimentoFiltroTemplate,
            action: ({ plano_recebimento }) => plano_recebimento?.descricao || "-",
        },
        {
            field: "sku",
            money: true,
            header: "Custo",
            align: "right",
            action: ({ sku }) => {
                const { precosku_set = [] } = sku;
                if (!precosku_set.length > 0) return parseMoeda(0, false);
                return parseMoeda(precosku_set[0].custo_base, false);
            },
        },
        {
            field: "sku",
            header: "Markup",
            align: "right",
            action: ({ sku, preco_calculado }) => {
                const { precosku_set = [] } = sku;
                if (!precosku_set.length > 0) return parseMoeda(0, false);
                if (precosku_set[0].custo_base === 0) return 1;
                return formatarCasasDecimais(calcularMarkup(preco_calculado, precosku_set[0].custo_base), 4, true);
            },
            money: true,
        },
        {
            field: "preco_calculado",
            filter: true,
            money: true,
            filterElement: moedaFiltroTemplate,
            header: "Preço",
        },
        {
            field: "preco_arredondado",
            header: "Arredondado",
            filter: true,
            money: true,
            filterElement: moedaFiltroTemplate,
        },
        {
            field: "action",
            header: "Ações",
            rowEditor: true,
            style: { minWidth: "12%" },
            action: (e) => actionBodyTemplate(e),
        },
    ];

    useEffect(() => {
        if (props.location?.state?.tabelaPreco) setValues(props.location.state.tabelaPreco);
    }, [props.location?.state?.tabelaPreco, setValues]);

    useEffect(() => {
        listarPlanoRecebimentos();
    }, [listarPlanoRecebimentos]);

    const rowClass = (rowData) => {
        const { precosku_set = [] } = rowData.sku;
        let markup = 0;
        if (!precosku_set.length > 0)
            return {
                "table-recebimentos-overdue": true,
            };
        if (precosku_set[0].custo_base === 0)
            return {
                "table-recebimentos-overdue": true,
            };
        markup = formatarCasasDecimais(calcularMarkup(rowData.preco_calculado, precosku_set[0].custo_base), 4);
        return {
            "table-recebimentos-overdue": markup <= 1,
        };
    };

    return (
        <PageBase>
            <div className="p-col-12">
                <Toast ref={toastRef} />
                <h5>{!formik.values.id ? "Nova Tabela de Preços" : "Manutenção de Tabela de Preços"}</h5>
                <TabView>
                    <TabPanel header="Tabela" leftIcon="pi pi-dollar">
                        <form onSubmit={formik.handleSubmit}>
                            <div className="p-fluid p-formgrid p-grid">
                                <div className="p-field p-col-12 p-md-7">
                                    <label htmlFor="nome">Nome tabela *</label>
                                    <InputText
                                        id="nome"
                                        name="nome"
                                        value={formik.values.nome}
                                        onChange={formik.handleChange}
                                        className={classNames({
                                            "p-invalid": formik.errors.nome,
                                        })}
                                        autoComplete="off"
                                        autoFocus
                                        disabled={state?.apenasVisualizar}
                                    />
                                    {formik.errors.nome && <small className="p-error">{formik.errors.nome}</small>}
                                </div>
                                <div className="p-field p-col-12 p-md-2">
                                    <label htmlFor="qtd_minima_tag">Quantidade mínima *</label>
                                    <InputNumber
                                        id="qtd_minima_tag"
                                        name="qtd_minima_tag"
                                        min={0}
                                        showButtons
                                        onValueChange={formik.handleChange}
                                        value={formik.values.qtd_minima_tag}
                                        className={classNames({
                                            "p-invalid": formik.errors.qtd_minima_tag,
                                        })}
                                        autoComplete="off"
                                        disabled={state?.apenasVisualizar}
                                    />
                                    {formik.errors.qtd_minima_tag && (
                                        <small className="p-error">{formik.errors.qtd_minima_tag}</small>
                                    )}
                                </div>
                                <div className="p-field p-col-12 p-md-3">
                                    <label htmlFor="tag_cliente">Tag cliente</label>
                                    <Dropdown
                                        id="tag_cliente"
                                        name="tag_cliente"
                                        placeholder="Selecione uma tag"
                                        url={"/pessoas/tags-perfis?query={id, descricao}&limit=300"}
                                        optionValue="id"
                                        optionLabel="descricao"
                                        emptyMessage="Nenhuma tag cadastrada."
                                        showClear
                                        value={formik.values.tag_cliente}
                                        onChange={formik.handleChange}
                                        disabled={state?.apenasVisualizar}
                                    />
                                    {formik.errors.tag_cliente && (
                                        <small className="p-error">{formik.errors.tag_cliente}</small>
                                    )}
                                </div>
                            </div>
                            <div className="p-fluid p-formgrid p-grid">
                                <div className="p-field p-col-12 p-md-3">
                                    <label htmlFor="vigencia">Vigência *</label>
                                    <Dropdown
                                        id="vigencia"
                                        name="vigencia"
                                        placeholder="Selecione uma vigência"
                                        url={"/produtos/precos-vigencia?query={id,descricao}&limit=300"}
                                        optionValue="id"
                                        optionLabel="descricao"
                                        filter
                                        filterBy="descricao"
                                        emptyMessage="Nenhuma vigência cadastrada."
                                        value={formik.values.vigencia}
                                        onChange={formik.handleChange}
                                        disabled={state?.apenasVisualizar}
                                        showClear={false}
                                    />
                                    {formik.errors.vigencia && (
                                        <small className="p-error">{formik.errors.vigencia}</small>
                                    )}
                                </div>
                                <div className="p-field p-col-12 p-md-3">
                                    <label htmlFor="tipo_centro_estocagem">Tipo estocagem</label>
                                    <Dropdown
                                        id="tipo_centro_estocagem"
                                        name="tipo_centro_estocagem"
                                        options={TIPO_CENTRO_ESTOCAGEM_CHOICE}
                                        optionValue="value"
                                        optionLabel="label"
                                        filter
                                        filterBy="label"
                                        showClear
                                        placeholder="Selecione um tipo"
                                        value={formik.values.tipo_centro_estocagem}
                                        onChange={formik.handleChange}
                                        disabled={state?.apenasVisualizar}
                                    />
                                    {formik.errors.tipo_centro_estocagem && (
                                        <small className="p-error">{formik.errors.tipo_centro_estocagem}</small>
                                    )}
                                </div>
                                <div className="p-field p-col-12 p-md-6">
                                    <label htmlFor="plano_recebimento">Plano de Recebimento *</label>
                                    <MultiSelect
                                        id="plano_recebimento"
                                        name="plano_recebimento"
                                        value={formik.values.plano_recebimento}
                                        options={listaPlanos}
                                        onChange={(e) => setFieldValue("plano_recebimento", e.value)}
                                        optionLabel="descricao"
                                        optionValue="id"
                                        placeholder="Selecione um plano de recebimento"
                                        filterBy="descricao"
                                        filter
                                        className="multiselect-custom"
                                        disabled={state?.apenasVisualizar}
                                    />
                                </div>
                            </div>
                            <b>* Campos obrigatórios.</b>
                            <div className="p-fluid p-formgrid p-grid p-mt-2">
                                <div className="p-col-12 p-md-2 p-ml-1">
                                    <Button
                                        label={formik.values.id ? "Gravar" : "Criar tabela"}
                                        icon={formik.values.id ? "pi pi-save" : "pi pi-plus"}
                                        type="submit"
                                        className="p-button-success p-mr-2 p-mb-1"
                                        disabled={state?.apenasVisualizar}
                                    />
                                </div>
                                <div className="p-col-12 p-md-3">
                                    <Button
                                        label="Voltar"
                                        type="reset"
                                        icon="pi pi-angle-double-left"
                                        className="p-button-danger p-mr-2 p-mb-6"
                                        onClick={cancelar}
                                    />
                                </div>
                            </div>
                        </form>
                    </TabPanel>
                    {formik.values.id ? (
                        <TabPanel header="Itens" leftIcon="pi pi-shopping-cart">
                            <MakoListagem
                                ref={listagemRef}
                                colunas={colunas}
                                urlPesquisa={`/produtos/tabela-preco-itens?tabela=${formik.values.id}`}
                                painelEsquerdo={cabecalhoTabela}
                                aposPesquisar={aposPesquisarProdutos}
                                filtros={{
                                    sku__codigo: {
                                        operator: "and",
                                        constraints: [{ value: "", matchMode: "contains" }],
                                    },
                                    sku__descricao_reduzida: { value: "", matchMode: "unaccent_icontains" },
                                    plano_recebimento: { value: null, matchMode: "equals" },
                                    preco_calculado: { value: null, matchMode: "equals" },
                                    preco_arredondado: { value: null, matchMode: "equals" },
                                }}
                                configTabela={{
                                    paginator: true,
                                    lazy: true,
                                    scrollable: true,
                                    editMode: "row",
                                    dataKey: "id",
                                    rowClassName: rowClass,
                                }}
                            />
                        </TabPanel>
                    ) : null}
                    {verifyPermission([permissoes.VENDAS_CADASTRO_TABELAPRECO_INCLUIR]) && formik.values.id ? (
                        <TabPanel header="Vincular Empresas" leftIcon="pi pi-users">
                            <VincularEmpresaForm tabela={formik.values.id} />
                        </TabPanel>
                    ) : null}
                </TabView>
                <OverlayPanel className="p-col-8 p-md-3" ref={inspecionar}>
                    <Panel header="Dados do produto" collapsed>
                        <label>
                            <i className="pi pi-database p-mr-1 p-mb-2" style={{ fontSize: "1rem" }} />
                            <b>Formação: </b>
                            {`${preco?.formacao || "-"}`}
                        </label>
                        <br />
                        <label>
                            <i className="pi pi-money-bill p-mr-1 p-mb-2" style={{ fontSize: "1rem" }} />
                            <b>Custo base: </b>
                            {`${preco?.custo || "R$  -"}`}
                        </label>
                        <br />
                        <label>
                            <i className="pi pi-dollar p-mr-1 p-mb-2" style={{ fontSize: "1rem" }} />
                            <b>Preço base: </b>
                            {`${preco?.preco || "R$ -"}`}
                        </label>
                        <br />
                        <label>
                            <i className="pi pi-calendar p-mr-1 p-mb-2" style={{ fontSize: "1rem" }} />
                            <b>Data cálculo: </b>
                            {`${preco?.data_calculo || "-"}`}
                        </label>
                        <br />
                        <label>
                            <i className="pi pi-percentage p-mr-1 p-mb-2" style={{ fontSize: "1rem" }} />
                            <b>Markup: </b>
                            {`${preco?.markup || "-"}`}
                        </label>
                    </Panel>
                </OverlayPanel>
                <ItemTabelaModalForm
                    tabela={formik.values.id}
                    vigencia={formik.values.vigencia}
                    setExibirDialog={setExibeProdutos}
                    exibirDialog={exibeProdutos}
                    toastRef={toastRef}
                    listagemRef={listagemRef}
                    acrescimo={formik.values.percentual_acrescimo}
                />
                <EditarItemModalForm
                    toastRef={toastRef}
                    setEditarProduto={setEditarProduto}
                    editarProduto={editarProduto}
                    produto={produto}
                    listagemRef={listagemRef}
                />
                <ConfirmDialog
                    visible={excluirItens}
                    onHide={() => setExcluirItens(false)}
                    header="Confirmação"
                    message={
                        <span>
                            Deseja realmente excluir <b>TODOS</b> os itens da tabela de preços?
                        </span>
                    }
                    icon="pi pi-info-circle p-mr-3"
                    accept={() => handleRemove()}
                    acceptLabel="Sim"
                    acceptClassName="p-button-danger"
                    reject={() => setExcluirItens(false)}
                    rejectLabel="Não"
                />
            </div>
        </PageBase>
    );
};
