import React, { createContext, useCallback, useState } from "react";
import { axiosGet, axiosPatch, axiosPost } from "@/services/http";
import useLoading from "@/hooks/useLoading";
import { dataToStr } from "@/assets/util/datas";

const TransferenciaContext = createContext({});

export const TransferenciaProvider = ({ children }) => {
    const [transferencia, setTransferencia] = useState(null);
    const { showLoading, hideLoading } = useLoading();

    const handleDadosBasicos = useCallback(
        async (values, tipo, usuario) => {
            if (!values.id) {
                const { status, data } = await axiosPost("/transferencias/transferencia-mercadoria/", {
                    ...values,
                    tipo_transferencia: tipo?.id,
                    usuario_gerou: usuario.id,
                    data_emissao: dataToStr(values.data_emissao, "yyyy-MM-dd"),
                    hora_emissao: dataToStr(values.hora_emissao, "HH:mm:ss") || null,
                });
                if (status === 201)
                    setTransferencia({ ...data, ce_destino: data.ce_destino.id, ce_origem: data.ce_origem.id });
                return { status, data };
            } else {
                const { ce_destino, ce_origem, data_emissao, hora_emissao, carga, orientacoes } = values;
                const body = {
                    ce_destino,
                    ce_origem,
                    data_emissao,
                    hora_emissao,
                    carga,
                    orientacoes,
                };
                let diffProd = {};
                Object.entries(body).forEach(([k, v]) => {
                    if (v !== transferencia[k]) {
                        if (k === "data_emissao") diffProd[k] = dataToStr(v, "yyyy-MM-dd");
                        else if (k === "hora_emissao") diffProd[k] = dataToStr(v, "HH:mm:ss");
                        else diffProd[k] = v;
                    }
                });
                if (Object.keys(diffProd).length > 0) {
                    const { status, data } = await axiosPatch(
                        `/transferencias/transferencia-mercadoria/${values.id}/`,
                        diffProd
                    );
                    if (status === 200)
                        setTransferencia({ ...data, ce_destino: data.ce_destino.id, ce_origem: data.ce_origem.id });
                    return { status, data };
                }
                return { status: 200 };
            }
        },
        [transferencia, setTransferencia]
    );

    const handleItens = useCallback(
        async (values) => {
            if (!values.id) {
                const { status, data } = await axiosPost("/transferencias/itens-transferencia-mercadoria/", {
                    ...values,
                    sku: values.sku.id,
                    transferencia: transferencia.id,
                });
                return { status, data };
            } else {
                const { status, data } = await axiosPatch(
                    `/transferencias/itens-transferencia-mercadoria/${values.id}/`,
                    {
                        ...values,
                        sku: values.sku.id,
                    }
                );
                return { status, data };
            }
        },
        [transferencia?.id]
    );

    const handlePreencherTransferencia = useCallback(
        async (idTransf) => {
            showLoading();
            const { status, data } = await axiosGet(`/transferencias/transferencia-mercadoria/${idTransf}/`);
            hideLoading();

            if (status === 200) {
                const { itemtransferenciamercadoria_set, ce_destino, ce_origem, ...rest } = data;
                setTransferencia({
                    ...rest,
                    ce_destino: ce_destino.id,
                    ce_origem: ce_origem.id,
                });
            }
        },
        [showLoading, hideLoading]
    );

    return (
        <TransferenciaContext.Provider
            value={{
                transferencia,
                handleDadosBasicos,
                handleItens,
                handlePreencherTransferencia,
            }}
        >
            {children}
        </TransferenciaContext.Provider>
    );
};

export default TransferenciaContext;
