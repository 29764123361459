import React from "react";

import { Button } from "primereact/button";

import { MakoInputMoeda } from "@/components/MakoInputMoeda";
import { Dropdown } from "@/components/Dropdown";
import { Label } from "@/components/Label";

import { MAKO_ICONS } from "@/assets/constants/constants_styles";

export const Form = () => {
    return (
        <form>
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12 p-md-12">
                    <Label htmlFor="rateio" label="Regra de rateio" />
                    <Dropdown
                        url="/financeiro/templates-rateios/"
                        optionValue="id"
                        optionLabel="descricao"
                        showClear={false}
                        disabled
                    />
                </div>
            </div>
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12 p-md-5">
                    <Label htmlFor="conta_financeira" label="Conta financeira" obrigatorio />
                    <Dropdown
                        id="conta_financeira"
                        name="conta_financeira"
                        url={`/financeiro/contas-financeiras/?ativa=True`}
                        placeholder="Selecione"
                        optionValue="id"
                        optionLabel="label"
                        disabled
                        // className={classNames({ "p-invalid": ?? })}
                    />
                    {/* {formik.errors.forma_recebimento && (
                        <small className="p-error">{formik.errors.forma_recebimento}</small>
                    )} */}
                </div>
                <div className="p-field p-col-12 p-md-5">
                    <Label htmlFor="forma_recebimento" label="Forma de recebimento" obrigatorio />
                    <Dropdown
                        id="forma_recebimento"
                        name="forma_recebimento"
                        url="/financeiro/formas-recebimentos/?ativo=true&viacaixa=true&tipo__in=0,2,3"
                        optionValue="id"
                        optionLabel="descricao"
                        showClear={false}
                        disabled
                        // className={classNames({ "p-invalid": ?? })}
                    />
                    {/* {formik.errors.forma_recebimento && (
                        <small className="p-error">{formik.errors.forma_recebimento}</small>
                    )} */}
                </div>
                <div className="p-field p-col-12 p-md-2">
                    <Label htmlFor="valor" label="Valor" />
                    <div className="p-inputgroup">
                        <MakoInputMoeda
                            inputId="descovalorntos"
                            name="valor"
                            suffix=" %"
                            minFractionDigits={2}
                            disabled
                        />
                        <Button icon={MAKO_ICONS.NOVO} type="button" className="p-button-success" />
                    </div>
                </div>
            </div>
        </form>
    );
};
