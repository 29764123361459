import React, { useRef } from "react";

import { Button } from "primereact/button";

import { Parcelas as ListagemParcelas } from "../../listagens";
import { ModalEditar } from "../../modals";

import { MAKO_ICONS } from "@/assets/constants/constants_styles";

export const Parcelas = ({ handleEditar = () => {}, handleRemover = () => {}, parcelas = [], repeticao }) => {
    const modalRef = useRef(null);

    const actionsTemplate = (rowData, colProps) => {
        return (
            <div className="actions">
                <Button
                    icon={MAKO_ICONS.EDITAR}
                    disabled={rowData.quitado}
                    className="p-button-rounded p-button-warning p-mr-2"
                    onClick={() =>
                        modalRef.current?.show({
                            id: rowData.id,
                            index: colProps.rowIndex,
                            vencimento: rowData.vencimento,
                            valor: rowData.valor,
                            historico_original: rowData.historico_original,
                            paga_comissao_recebimento: rowData.paga_comissao_recebimento,
                            previsao: rowData.previsao,
                        })
                    }
                />
                <Button
                    icon={MAKO_ICONS.DELETE}
                    disabled={rowData.quitado}
                    className="p-button-rounded p-button-danger"
                    onClick={() => handleRemover(colProps.rowIndex, repeticao)}
                />
            </div>
        );
    };

    return (
        <>
            <ModalEditar ref={modalRef} parcelas={parcelas} handleEditar={handleEditar} />
            <ListagemParcelas parcelas={parcelas} actions={actionsTemplate} />
        </>
    );
};
