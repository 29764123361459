import React, { useState, useImperativeHandle, forwardRef } from "react";
import { Dialog } from "primereact/dialog";
import { Password } from "primereact/password";
import { Button } from "primereact/button";

import useAuth from "@/hooks/useAuth";
import useToast from "@/hooks/useToast";
import { axiosPost } from "@/services/http";

const ConfirmarSenha = ({ onConfirm }, ref) => {
    const [visible, setVisible] = useState(false);
    const [senha, setSenha] = useState("");
    const [loading, setLoading] = useState(false);
    const { user } = useAuth();
    const { showWarning, showError } = useToast();

    const confirmarSenha = async () => {
        const userData = {
            username: user.usuario.username,
            password: senha,
        };

        setLoading(true);
        const { status } = await axiosPost("/auth/token/login", userData);
        setLoading(false);

        if (status === 200) {
            if (onConfirm) {
                onConfirm();
            }

            fecharModal();
        } else if (status === 400) {
            showWarning({
                summary: "Senha inválida!",
                detail: "A senha fornecida não está correta.",
                life: 3000,
            });
        } else {
            showError({
                summary: "Erro :(",
                detail: "Desculpe, a requisição não pode ser concluída.",
                life: 3000,
            });
        }
    };

    const abrirModal = () => {
        setVisible(true);
    };

    const fecharModal = () => {
        setVisible(false);
    };

    useImperativeHandle(ref, () => ({ abrirModal }));

    const footer = (
        <div>
            <Button label="Confirmar senha" icon="pi pi-check" onClick={confirmarSenha} autoFocus loading={loading} />
        </div>
    );

    return (
        <Dialog
            header="Confirmar senha"
            visible={visible}
            onHide={fecharModal}
            position="top"
            footer={footer}
            style={{ width: "20vw" }}
        >
            <div className="p-fluid">
                <Password
                    value={senha}
                    placeholder="Digite sua senha"
                    onChange={(e) => setSenha(e.target.value)}
                    feedback={false}
                />
            </div>
        </Dialog>
    );
};

export const MakoDialogConfirmarSenha = forwardRef(ConfirmarSenha);
