import { useCallback } from "react";
import useLocalStorage from "use-local-storage";

import useToast from "./useToast";
import storageKeys from "@/assets/constants/storage";
import { axiosGet, axiosPut } from "@/services/http";

const useNotificacao = () => {
    const { showInfo } = useToast();
    const [storageNotifications, setStorageNotifications] = useLocalStorage(storageKeys.NOTIFICACOES_KEY, 0);

    const ouvirNotificacoes = useCallback(
        async (cancelToken) => {
            const { status, data } = await axiosGet("/pessoas/minhas-notificacoes/", { cancelToken });
            if (status === 200 && data.results.length > 0) {
                const qtd = data.results.length;
                setStorageNotifications((old) => old + qtd);
                const sulfixoMsg = qtd > 1 ? `${qtd} novas notificações.` : "1 nova notificação.";
                showInfo({
                    summary: "Info.",
                    detail: `Você tem ${sulfixoMsg}`,
                    life: 2000,
                });
            }
        },
        [showInfo, setStorageNotifications]
    );

    const marcarTodasMsgComoLidas = useCallback(async () => {
        const { status } = await axiosPut("/pessoas/ler-todas-notificacoes/");
        if (status === 204) setStorageNotifications(0);
    }, [setStorageNotifications]);

    const marcarMsgComoLida = useCallback(() => {
        if (storageNotifications > 0) setStorageNotifications((old) => Math.max(0, old - 1));
    }, [storageNotifications, setStorageNotifications]);

    return { marcarTodasMsgComoLidas, marcarMsgComoLida, ouvirNotificacoes, notifications: storageNotifications };
};

export default useNotificacao;
