import React, { useCallback, useEffect, useRef, useState } from "react";
import { Toast } from "primereact/toast";
import { MakoInputCliente } from "@/components/MakoInputs/MakoInputCliente";
import { useFormik } from "formik";
import { MakoCalendar } from "@/components/MakoCalendar";
import { MakoTime } from "@/components/MakoTime";
import classNames from "classnames";
import { InputTextarea } from "primereact/inputtextarea";
import { Dropdown } from "@/components/Dropdown";
import { axiosGet, axiosPost, axiosPut } from "@/services/http";
import useLoading from "@/hooks/useLoading";
import { MakoInputMoeda } from "@/components/MakoInputMoeda";
import { ItensOrcamentoRapidoForm } from "./list";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import { Button } from "primereact/button";
import permissoes from "@/assets/constants/permissoes";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import { dataToStr, horaToStr } from "@/assets/util/datas";
import { InputText } from "primereact/inputtext";

export const OrcamentoRapidoForm = (props) => {
    const [listaPlanos, setListaPlanos] = useState([]);
    const [dadosBasicos, setDadosBasicos] = useState(null);
    const [itensOrcamento, setItensOrcamento] = useState([]);
    const { showLoading, hideLoading } = useLoading();
    const history = useHistory();

    const toastRef = useRef();
    const { setValues, setFieldValue, resetForm, ...formik } = useFormik({
        initialValues: props.location?.state
            ? { ...props.location.state.orcamento, valor_total: parseFloat(props.location.state.orcamento.valor_total) }
            : {
                  cliente: null,
                  data: new Date(),
                  hora: null,
                  plano_recebimento: null,
                  valor_total: 0,
                  observacoes: "",
                  identificacao: "",
              },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                cliente: Yup.object().required("O campo é obrigatório.").typeError("Informe um 'cliente' válido."),
                data: Yup.date().required("O campo é obrigatório."),
                plano_recebimento: Yup.number().required("O campo é obrigatório.").typeError("Informe um 'plano de recebimento' válido."),
                identificacao: Yup.string().max(40).required("O campo é obrigatório."),
            });

            await formSchema.validate(values, {
                abortEarly: false,
            });

            const __STATUS = values.id || values.localId ? "editar" : "novo";

            setDadosBasicos(values);
            toastRef.current.show({
                severity: "success",
                summary: "Sucesso",
                detail: "Dados básicos do orçamento rápido gravados com sucesso!",
                life: 3000,
            });
            if (__STATUS === "novo")
                toastRef.current.show({
                    severity: "info",
                    summary: "Atenção",
                    detail: "Agora você já pode vincular os itens ao orçamento.",
                    life: 6000,
                });
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};

                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });

                formik.setErrors(errorMessages);
            }
        }
    }

    const gerenciaCrud = () => {
        let objCrud = {};
        objCrud["create"] = [];
        if (!dadosBasicos.id) {
            itensOrcamento.forEach((item) => {
                if (item.status && item.status === "novo")
                    objCrud.create.push({
                        ...item,
                        tabela_preco: item.tabela_preco?.id,
                        sku: item.sku.id,
                        sku_movimenta_estoque: item.sku_movimenta_estoque.id,
                        unidade_venda: item.unidade_venda.id,
                    });
            });
            return objCrud;
        } else {
            objCrud["remove"] = [];

            itensOrcamento.forEach((item) => {
                if (item.status && item.status === "novo") {
                    objCrud.create.push({
                        ...item,
                        sku: item.sku.id,
                        sku_movimenta_estoque: item.sku_movimenta_estoque.id,
                        unidade_venda: item.unidade_venda.id,
                        tabela_preco: item.tabela_preco?.id,
                    });
                } else if (item.status && item.status === "deletar") objCrud.remove.push(item.id);
            });
            return objCrud;
        }
    };

    async function handlePersistir() {
        try {
            let valorTotal = 0;
            itensOrcamento.forEach((item) => {
                if (!item.status) valorTotal += parseFloat(item.valor_total_ap);
                else if (item.status !== "deletar") valorTotal += parseFloat(item.valor_total);
            });

            if (!dadosBasicos.id) {
                const crud = gerenciaCrud();

                showLoading();
                const resposta = await axiosPost(`/vendas/orcamento-rapido/`, {
                    ...dadosBasicos,
                    itemorcamentorapido_set: crud,
                    cliente: dadosBasicos.cliente?.id,
                    data: dataToStr(dadosBasicos.data, "yyyy-MM-dd"),
                    hora: horaToStr(dadosBasicos.hora, "HH:mm:ss"),
                    valor_total: valorTotal.toFixed(4),
                });
                hideLoading();

                if (resposta.status === 201) {
                    toastRef.current.show({
                        severity: "success",
                        summary: "Sucesso",
                        detail: "Orçamento rápido cadastrado com sucesso!",
                        life: 1500,
                    });

                    setTimeout(() => {
                        resetForm();
                        history.push("/vendas/venda/orcamento-rapido");
                    }, 2500);
                } else {
                    toastRef.current.show({
                        severity: "error",
                        summary: "Erro",
                        detail: "Desculpe, não conseguimos processar a sua requisição.",
                        life: 1500,
                    });
                }
            } else {
                const crud = gerenciaCrud();

                showLoading();
                const resposta = await axiosPut(`/vendas/orcamento-rapido/${dadosBasicos.id}/`, {
                    ...dadosBasicos,
                    itemorcamentorapido_set: crud,
                    cliente: dadosBasicos.cliente?.id,
                    data: dataToStr(dadosBasicos.data, "yyyy-MM-dd"),
                    hora: horaToStr(dadosBasicos.hora, "HH:mm:ss"),
                    valor_total: valorTotal.toFixed(4),
                });
                hideLoading();

                if (resposta.status === 200) {
                    toastRef.current.show({
                        severity: "success",
                        summary: "Sucesso",
                        detail: "Orçamento rápido alterado com sucesso!",
                        life: 1500,
                    });

                    setTimeout(() => {
                        resetForm();
                        history.push("/vendas/venda/orcamento-rapido");
                    }, 2000);
                } else {
                    toastRef.current.show({
                        severity: "error",
                        summary: "Erro",
                        detail: "Desculpe, não conseguimos processar a sua requisição.",
                        life: 1500,
                    });
                }
            }
        } catch (error) {
            toastRef.current.show({
                severity: "error",
                summary: "Erro",
                detail: "Desculpe, não conseguimos processar a sua requisição.",
                life: 1500,
            });
        }
    }

    const listarPlanoRecebimentos = useCallback(async () => {
        showLoading();
        const resposta = await axiosGet("/financeiro/planos-recebimentos?query={id,descricao}&limit=300");
        hideLoading();

        if (resposta.status === 200 || resposta.status === 201) setListaPlanos(resposta.data.results);
    }, [showLoading, hideLoading]);

    useEffect(() => {
        listarPlanoRecebimentos();
    }, [listarPlanoRecebimentos]);

    useEffect(() => {
        let total = 0;
        itensOrcamento.forEach((item) => {
            if (item.status && item.status !== "deletar") total += parseFloat(item.valor_total);
            else if (!item.status) total += parseFloat(item.valor_total_av);
        });
        setFieldValue("valor_total", total);
    }, [setItensOrcamento, itensOrcamento, setFieldValue]);

    const carregaInformacoes = useCallback(async () => {
        const { itemorcamentorapido_set, ...orcamento } = props.location?.state?.orcamento;
        setDadosBasicos(orcamento);
        setItensOrcamento(itemorcamentorapido_set);
    }, [props.location?.state?.orcamento]);

    const onChangeCliente = (e) => {
        setFieldValue("cliente", e);
        setFieldValue("identificacao", e.nome);
    };

    useEffect(() => {
        if (props.location?.state?.orcamento?.id) carregaInformacoes();
    }, [props.location?.state?.orcamento, carregaInformacoes]);

    return (
        <div className="p-grid">
            <Toast ref={toastRef} />
            <div className="p-col-12">
                <div className="card">
                    <h4>{!formik.values.id ? "Novo Orçamento Rápido" : "Manutenção de Orçamento Rápido"}</h4>
                    <form onSubmit={formik.handleSubmit}>
                        <div className="p-fluid p-formgrid p-grid">
                            <div className="p-field p-col-12 p-md-2">
                                <label htmlFor="id">Código</label>
                                <InputText
                                    id="id"
                                    name="id"
                                    value={formik.values.id}
                                    onChange={formik.handleChange}
                                    disabled
                                />
                            </div>
                            <div className="p-field p-col-12 p-md-6">
                                <label htmlFor="cliente">Cliente *</label>
                                <MakoInputCliente
                                    id="cliente"
                                    name="cliente"
                                    disabled={!!formik.values.id}
                                    somenteAtivo
                                    value={formik.values.cliente}
                                    onChange={(e) => onChangeCliente(e.value)}
                                    className={classNames({ "p-invalid": formik.errors.cliente })}
                                />
                                {formik.errors.cliente && <small className="p-error">{formik.errors.cliente}</small>}
                            </div>
                            <div className="p-field p-col-12 p-md-2">
                                <label htmlFor="data-venda">Data *</label>
                                <MakoCalendar
                                    id="data-venda"
                                    name="data"
                                    valueCalendar={formik.values.data}
                                    minDate={new Date()}
                                    onChange={formik.handleChange}
                                    className={classNames({ "p-invalid": formik.errors.data })}
                                />
                                {formik.errors.data && <small className="p-error">{formik.errors.data}</small>}
                            </div>
                            <div className="p-field p-col-12 p-md-2">
                                <label htmlFor="hora-venda">Hora</label>
                                <MakoTime
                                    id="hora-venda"
                                    name="hora"
                                    valueTime={formik.values.hora}
                                    onChange={formik.handleChange}
                                    className={classNames({ "p-invalid": formik.errors.hora })}
                                />
                            </div>
                        </div>
                        <div className="p-fluid p-formgrid p-grid ">
                            <div className="p-field p-col-12 p-md-4">
                                <label htmlFor="identificacao">Identificação *</label>
                                <InputText
                                    id="identificacao"
                                    name="identificacao"
                                    value={formik.values.identificacao}
                                    onChange={formik.handleChange}
                                    className={classNames({ "p-invalid": formik.errors.identificacao })}
                                />
                                {formik.errors.identificacao && (
                                    <small className="p-error">{formik.errors.identificacao}</small>
                                )}
                            </div>

                            <div className="p-field p-col-12 p-md-5">
                                <label htmlFor="plano_recebimento">Plano de recebimentos *</label>
                                <Dropdown
                                    id="plano_recebimento"
                                    name="plano_recebimento"
                                    options={listaPlanos}
                                    optionValue="id"
                                    optionLabel="descricao"
                                    filter
                                    filterBy="descricao"
                                    placeholder="Selecione um plano..."
                                    value={formik.values.plano_recebimento}
                                    onChange={formik.handleChange}
                                    className={classNames({ "p-invalid": formik.errors.plano_recebimento })}
                                />
                                {formik.errors.plano_recebimento && (
                                    <small className="p-error">{formik.errors.plano_recebimento}</small>
                                )}
                            </div>
                            <div className="p-field p-col-12 p-md-3">
                                <label htmlFor="valor_total">Valor total</label>
                                <MakoInputMoeda
                                    id="valor_total"
                                    name="valor_total"
                                    disabled
                                    valueMoeda={formik.values.valor_total}
                                    className={classNames({ "p-invalid": formik.errors.valor_total })}
                                />
                            </div>
                        </div>
                        <div className="p-fluid p-formgrid p-grid ">
                            <div className="p-field p-col-12 p-md-12">
                                <label htmlFor="observacoes">Observações</label>
                                <InputTextarea
                                    id="observacoes"
                                    name="observacoes"
                                    value={formik.values.observacoes}
                                    onChange={formik.handleChange}
                                    className={classNames({ "p-invalid": formik.errors.observacoes })}
                                    rows={4}
                                    autoComplete="off"
                                    autoResize
                                />
                                {formik.errors.observacoes && (
                                    <small className="p-error">{formik.errors.observacoes}</small>
                                )}
                            </div>
                        </div>
                        <div className="p-grid p-col-12 p-md-6">
                            <MakoControleAcesso
                                permissao={[permissoes.VENDAS_VENDA_ORCAMENTORAPIDO_INCLUIR]}
                                componente={Button}
                                label="Gravar"
                                icon="pi pi-save"
                                type="submit"
                                className="p-button-info p-mr-2"
                            />
                            <MakoControleAcesso
                                permissao={[permissoes.VENDAS_VENDA_ORCAMENTORAPIDO_INCLUIR]}
                                componente={Button}
                                label="Finalizar"
                                icon="pi pi-check"
                                type="button"
                                disabled={!dadosBasicos}
                                onClick={() => handlePersistir()}
                                className="p-button-success p-mr-2"
                            />
                            <Button
                                label="Cancelar"
                                type="reset"
                                icon="pi pi-angle-double-left"
                                className="p-button-danger p-mr-2"
                                onClick={() => history.push("/vendas/venda/orcamento-rapido")}
                            />
                        </div>
                    </form>
                </div>
                {dadosBasicos ? (
                    <ItensOrcamentoRapidoForm
                        setItensOrcamento={setItensOrcamento}
                        itensOrcamento={itensOrcamento}
                        toastRef={toastRef}
                    />
                ) : null}
            </div>
        </div>
    );
};
