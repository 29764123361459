import React from "react";

import { MakoButton } from "@/components/MakoButton";

export const Calcular = () => {
    return (
        <MakoButton
            icon="pi pi-calculator"
            tooltip="Calcular"
            type="button"
            className="p-button-rounded p-button-warning"
        />
    );
};
