import React from "react";

import { MakoButton } from "@/components/MakoButton";
import useCaixaMovimento from "@/hooks/useCaixaMovimento";

export const ReceberCrediario = () => {
    const { caixaMov } = useCaixaMovimento();
    return (
        <MakoButton
            label="Receber crediário"
            className="p-button p-button-success p-py-1 p-text-bold"
            type="button"
            disabled={caixaMov?.bloqueado}
        />
    );
};
