import React, { forwardRef, useImperativeHandle, useRef, useState } from "react";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import useLoading from "@/hooks/useLoading";
import axios from "axios";
import { InputTextarea } from "primereact/inputtextarea";
import { dataToStr, horaToStr } from "@/assets/util/datas";
import useEmpresa from "@/hooks/useEmpresa";
import { Dialog } from "primereact/dialog";

const Modal = ({ apiServico, nota }, ref) => {
    const [correcao, setCorrecao] = useState("");
    const { empresaSelecionada } = useEmpresa();
    const { showLoading, hideLoading } = useLoading();
    const toastRef = useRef(null);
    const [visible, setVisible] = useState(false);

    async function handleSubmit() {
        try {
            const body = [
                {
                    ModeloDocumento: nota.modelo === "65" ? "NFCe" : "NFe",
                    Versao: "4.00",
                    Evento: {
                        NtfCnpjEmissor: empresaSelecionada.identificacao,
                        NtfNumero: `${nota.numero}`,
                        NtfSerie: `${nota.serie}`,
                        tpAmb: process.env.REACT_APP_AMB_INVOICY,
                        EveInf: {
                            EveDh: `${dataToStr(new Date(), "yyyy-MM-dd")}T${horaToStr(new Date(), "HH:mm:ss")}-03:00`,
                            EveFusoHorario: "-03:00",
                            EveTp: "110110",
                            EvenSeq: "1",
                            Evedet: {
                                EveDesc: "CC-e",
                                EvenProt: `${nota.protocolo}`,
                                EvexJust: correcao,
                            },
                        },
                    },
                },
            ];

            showLoading();
            const resposta = await axios.post(
                `${process.env.REACT_APP_INTEGRACAO_FISCAL}/documents/${apiServico.chave_acesso}/${apiServico.cnpj_integrado}/nfe/eventos`,
                body
            );
            hideLoading();

            if (resposta.data?.length > 0)
                if (resposta.data[0].Codigo === 100 || resposta.data[0].Codigo === 101) {
                    toastRef.current.show({
                        severity: resposta.data[0].Documentos[0].Situacao.SitCodigo === 220 ? "warn" : "success",
                        detail: `${resposta.data[0].Documentos[0].Situacao.SitDescricao}!
                         \n
                         Código: ${resposta.data[0].Documentos[0].Situacao.SitCodigo}`,
                        life: 5000,
                    });

                    fecharModal(false);
                } else {
                    toastRef.current.show({
                        severity: "warn",
                        summary: "Falha",
                        detail: `${resposta.data[0].Descricao} \n
                    Código: ${resposta.data[0].Codigo}`,
                        life: 5000,
                    });
                }
            else if (resposta.data)
                toastRef.current.show({
                    severity: "warn",
                    summary: "Falha",
                    detail: `${resposta.data.Descricao} \n
                    Código: ${resposta.data.Codigo}`,
                    life: 5000,
                });
        } catch (error) {
            toastRef.current.show({
                severity: "error",
                summary: "Erro",
                detail: `Falha ao comunicar com o agente fiscal.`,
                life: 3000,
            });
        }
    }

    function validateForm() {
        return correcao.length >= 15;
    }

    const fecharModal = () => {
        setCorrecao("");
        setVisible(false);
    };

    useImperativeHandle(ref, () => ({ setVisible }));

    return (
        <>
            <Toast ref={toastRef} />
            <Dialog
                header="Carta de Correção (CC-e)"
                visible={visible}
                breakpoints={{ "960px": "80vw" }}
                style={{ width: "35vw" }}
                onHide={() => fecharModal()}
            >
                <form>
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-12 p-md-12">
                            <InputTextarea
                                id="correcao"
                                placeholder="Digite a correção (min. 15 caracteres)"
                                value={correcao}
                                rows={6}
                                onChange={(e) => setCorrecao(e.target.value)}
                                autoComplete="off"
                            />
                        </div>
                    </div>
                    <div className="p-grid p-col-12 p-md-12">
                        <Button
                            label="Enviar"
                            type="button"
                            className="p-button-info"
                            disabled={!validateForm()}
                            onClick={handleSubmit}
                        />
                    </div>
                </form>
            </Dialog>
        </>
    );
};

export const CartaCorrecaoFormModal = forwardRef(Modal);
