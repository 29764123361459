import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useState } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { useFormik } from "formik";

import { BaseModalAjusteComissao } from "./BaseModal";
import { MakoInputMoeda } from "@/components/MakoInputMoeda";
import { MakoInputPercent } from "@/components/MakoInputs";
import { parseNumber } from "@/assets/helpers/number";
import useHttp from "@/hooks/useHttp";
import useToast from "@/hooks/useToast";
import useLoadingLocal from "@/hooks/useLoadingLocal";

const Modal = ({ onFechar }, ref) => {
    const [visible, setVisible] = useState(false);
    const [baseComissao, setBaseComissao] = useState(null);
    const { httpPatch } = useHttp();
    const [loading, showLoading, hideLoading] = useLoadingLocal();
    const { showSuccess } = useToast();

    const { setFieldValue, ...formik } = useFormik({
        initialValues: {
            base_encargo: 0,
            percent_encargo: 0,
            comissao_encargo: 0,
            base_mercadoria: 0,
            percent_mercadoria: 0,
            comissao_mercadoria: 0,
            base_servico: 0,
            percent_servico: 0,
            comissao_servico: 0,
            total_comissao: 0,
        },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        const url = `/vendas/bases-comissoes-vendas/${baseComissao.id}/`;
        const handlers = {
            200: () => {
                showSuccess({
                    summary: "Sucesso",
                    detail: "Comissão alterada com sucesso!",
                    life: 1500,
                });
                fecharModal();
            },
        };
        showLoading();
        await httpPatch({ url, body: values }, handlers);
        hideLoading();
    }

    const abrirModal = (baseComissao) => {
        setVisible(true);
        formik.setValues({
            base_encargo: parseNumber(baseComissao.base_encargo),
            percent_encargo: parseNumber(baseComissao.percent_encargo),
            comissao_encargo: parseNumber(baseComissao.comissao_encargo),
            base_mercadoria: parseNumber(baseComissao.base_mercadoria),
            percent_mercadoria: parseNumber(baseComissao.percent_mercadoria),
            comissao_mercadoria: parseNumber(baseComissao.comissao_mercadoria),
            base_servico: parseNumber(baseComissao.base_servico),
            percent_servico: parseNumber(baseComissao.percent_servico),
            comissao_servico: parseNumber(baseComissao.comissao_servico),
            total_comissao: parseNumber(baseComissao.total_comissao),
        });
        setBaseComissao(baseComissao);
    };

    const fecharModal = () => {
        setVisible(false);
        setBaseComissao(null);
        formik.resetForm();
        if (typeof onFechar === "function") onFechar();
    };

    useImperativeHandle(ref, () => ({ abrirModal }));

    const footer = (
        <div>
            <Button
                type="button"
                icon="pi pi-times"
                label="Cancelar"
                loading={loading}
                severity="danger"
                onClick={fecharModal}
            />
            <Button
                type="button"
                icon="pi pi-save"
                label="Salvar"
                loading={loading}
                onClick={() => formik.handleSubmit()}
            />
        </div>
    );

    const calcularComissao = useCallback(() => {
        const totalEncargos = formik.values.comissao_encargo;
        const totalMercadorias = formik.values.comissao_mercadoria;
        const totalServicos = formik.values.comissao_servico;
        const totalComissao = totalEncargos + totalMercadorias + totalServicos;
        setFieldValue("total_comissao", totalComissao);
    }, [
        formik.values.comissao_encargo,
        formik.values.comissao_mercadoria,
        formik.values.comissao_servico,
        setFieldValue,
    ]);

    useEffect(() => {
        calcularComissao();
    }, [calcularComissao]);

    if (!baseComissao) return <></>;

    const onChangeComissao = (event) => {
        formik.handleChange(event);
        const { name } = event.target;
        const [field, escopo] = name.split("_");
        if (field === "base") {
            const base = parseNumber(event.value);
            const percent = formik.values[`percent_${escopo}`];
            const comissao = (base * percent) / 100;
            setFieldValue(`comissao_${escopo}`, comissao);
        }
        if (field === "percent") {
            const base = formik.values[`base_${escopo}`];
            const percent = parseNumber(event.value);
            const comissao = (base * percent) / 100;
            setFieldValue(`comissao_${escopo}`, comissao);
        }
        if (field === "comissao") {
            const comissao = parseNumber(event.value);
            const base = formik.values[`base_${escopo}`];
            const percent = (comissao / base) * 100;
            setFieldValue(`percent_${escopo}`, percent);
        }
    };

    return (
        <Dialog header="Alterando comissão" visible={visible} onHide={fecharModal} footer={footer}>
            <BaseModalAjusteComissao baseComissao={baseComissao} />
            <form onSubmit={formik.handleSubmit}>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-9">
                        <label htmlFor="comissionado">Comissionado(a)</label>
                        <InputText id="comissionado" disabled value={baseComissao.comissionado?.nome || ""} />
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="base-encargos">Base de encargos</label>
                        <MakoInputMoeda
                            id="base-encargos"
                            name="base_encargo"
                            valueMoeda={formik.values.base_encargo}
                            onChangeMoeda={onChangeComissao}
                        />
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="percent-encargos">Percentual de encargos</label>
                        <MakoInputPercent
                            id="percent-encargos"
                            name="percent_encargo"
                            value={formik.values.percent_encargo}
                            onValueChange={onChangeComissao}
                        />
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="vlr-comissao-encargos">Valor comissão encargos</label>
                        <MakoInputMoeda
                            id="vlr-comissao-encargos"
                            name="comissao_encargo"
                            valueMoeda={formik.values.comissao_encargo}
                            onChangeMoeda={onChangeComissao}
                        />
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="base-mercadorias">Base de mercadorias</label>
                        <MakoInputMoeda
                            id="base-mercadorias"
                            name="base_mercadoria"
                            valueMoeda={formik.values.base_mercadoria}
                            onChangeMoeda={onChangeComissao}
                        />
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="percent-mercadorias">Percentual de mercadorias</label>
                        <MakoInputPercent
                            id="percent-mercadorias"
                            name="percent_mercadoria"
                            value={formik.values.percent_mercadoria}
                            onValueChange={onChangeComissao}
                        />
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="vlr-comissao-mercadorias">Valor comissão mercadorias</label>
                        <MakoInputMoeda
                            id="vlr-comissao-mercadorias"
                            name="comissao_mercadoria"
                            valueMoeda={formik.values.comissao_mercadoria}
                            onChangeMoeda={onChangeComissao}
                        />
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="base-servicos">Base de serviços</label>
                        <MakoInputMoeda
                            id="base-servicos"
                            name="base_servico"
                            valueMoeda={formik.values.base_servico}
                            onChangeMoeda={onChangeComissao}
                        />
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="percent-servicos">Percentual de serviços</label>
                        <MakoInputPercent
                            id="percent-servicos"
                            name="percent_servico"
                            value={formik.values.percent_servico}
                            onValueChange={onChangeComissao}
                        />
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="vlr-comissao-servicos">Valor comissão serviços</label>
                        <MakoInputMoeda
                            id="vlr-comissao-servicos"
                            name="comissao_servico"
                            valueMoeda={formik.values.comissao_servico}
                            onChangeMoeda={onChangeComissao}
                        />
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="novo-valor">Novo valor</label>
                        <MakoInputMoeda
                            id="novo-valor"
                            name="total_comissao"
                            disabled
                            valueMoeda={formik.values.total_comissao}
                        />
                    </div>
                </div>
            </form>
        </Dialog>
    );
};

export const ModalAlterarComissao = forwardRef(Modal);
