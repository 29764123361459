import React, { useCallback, useEffect, useRef, useState } from "react";

import { addDays, subDays } from "date-fns/esm";
import { useFormik } from "formik";
import classNames from "classnames";
import * as Yup from "yup";

import { MakoDropdownCategoriasHierarquicas } from "@/components/MakoDropdownCategoriasHierarquicas";
import { MakoBuscaSkuPersonalizada } from "@/components/MakoBuscaSkuPersonalizada";
import { MakoSelecionarEmails } from "@/components/MakoSelecionarEmails";
import { MakoInputFornecedor } from "@/components/MakoInputs/MakoInputFornecedor";
import MakoListagem from "@/components/MakoListagem";

import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";

import useRelatorio from "@/hooks/useRelatorio";
import useLoading from "@/hooks/useLoading";
import useToast from "@/hooks/useToast";

import { RELATORIO_ESTOQUE_NECESSIDADECOMPRA } from "@/assets/constants/relatorios";
import { formatarCasasDecimais } from "@/assets/util/util";
import { dataToStr } from "@/assets/util/datas";
import { axiosPost } from "@/services/http";

export const RelatorioNecessidadeOC = () => {
    const [visible, setVisible] = useState(false);
    const [fornecedores, setFornecedores] = useState([]);
    const [dados, setDados] = useState(null);
    const [produtos, setProdutos] = useState([]);
    const [emails, setEmails] = useState([]);

    const { showLoading, hideLoading } = useLoading();
    const { solicitarRelatorio } = useRelatorio();
    const { showWarning, showError } = useToast();

    const toastRef = useRef();

    const actionBodyTemplate = (rowData, key) => {
        return (
            <>
                <Button
                    type="button"
                    icon="pi pi-trash p"
                    onClick={() => handleDelete(rowData.id, key)}
                    className="p-button-rounded p-button-danger"
                />
            </>
        );
    };

    const colunasFornecedores = [
        { field: "id", header: "Código", style: { width: "10%" } },
        { field: "nome", header: "Nome" },
        {
            field: "action",
            header: "Ações",
            style: { width: "10%" },
            action: (rowData) => actionBodyTemplate(rowData, "fornec"),
        },
    ];

    const colunasProdutos = [
        { field: "codigo", header: "Código", style: { width: "10%" } },
        { field: "descricao_reduzida", header: "Descrição" },
        {
            field: "action",
            header: "Ações",
            style: { width: "10%" },
            action: (rowData) => actionBodyTemplate(rowData, "sku"),
        },
    ];

    const handleDelete = useCallback(
        (id, key) => {
            switch (key) {
                case "fornec":
                    setFornecedores((prev) => prev.filter((item) => item.id !== id));
                    break;

                case "sku":
                    setProdutos((prev) => prev.filter((item) => item.id !== id));
                    break;

                default:
                    break;
            }
        },
        [setFornecedores, setProdutos]
    );

    const { setValues, setFieldValue, ...formik } = useFormik({
        initialValues: {
            categoria: null,
            fornec: null,
            sku: null,
            orderBy: "",
            relatorio: false,
        },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values = null) {
        values.sku = produtos;
        values.fornec = fornecedores;
        try {
            const formSchema = Yup.object().shape({
                fornec: Yup.array().of(
                    Yup.object().shape({
                        id: Yup.number(),
                    })
                ),
                sku: Yup.array().of(
                    Yup.object().shape({
                        id: Yup.number(),
                    })
                ),
                categoria: Yup.number().nullable().notRequired().typeError("Selecione uma categoria."),
                orderBy: Yup.string().nullable().notRequired().typeError("Seleciona uma 'ordenação' válida."),
            });

            await formSchema.validate(values, {
                abortEarly: false,
            });
            values.fornecedores = values.fornec.map((item) => item.id);
            values.skus = values.sku.map((item) => item.id);

            delete values.fornec;
            delete values.sku;

            if (values.relatorio) {
                delete values.relatorio;
                let filtros = {};
                if (values) {
                    Object.keys(values).forEach((key) => {
                        if (values[key]) filtros[key] = values[key];
                    });
                }

                solicitarRelatorio({ chave: RELATORIO_ESTOQUE_NECESSIDADECOMPRA, emails, filtros });
            } else {
                delete values.relatorio;
                delete values.orderBy;

                showLoading();
                const response = await axiosPost("/compras/consultar-necessidade-compra/", values);
                hideLoading();

                if (response.status === 200) {
                    setDados(response.data.result);
                } else {
                    showError({
                        summary: "Erro :(",
                        detail: "Não conseguimos processar a sua requisição.",
                        life: 3000,
                    });
                }
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};

                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });

                formik.setErrors(errorMessages);
            }
        }
    }

    const handleAdd = useCallback(
        async (value = null, key = "fornec") => {
            try {
                const formSchema = Yup.object().shape({
                    sku: Yup.number().nullable().notRequired().typeError("Selecione uma categoria."),
                    sku: Yup.object()
                        .notRequired()
                        .shape({
                            id: Yup.number(),
                        })
                        .typeError("Informe um 'fornecedor' válida."),
                });

                await formSchema.validate(value, {
                    abortEarly: false,
                });

                switch (key) {
                    case "fornec":
                        if (
                            fornecedores.length === 0 ||
                            fornecedores.find((item) => item.id === value.id) == "undefined"
                        ) {
                            setFornecedores((prev) => [...prev, value]);
                        } else {
                            showWarning({
                                summary: "Alerta :(",
                                detail: "Você ja selecionou este fornecedor.",
                                life: 3000,
                            });
                        }
                        break;

                    case "sku":
                        if (produtos.length === 0 || produtos.find((item) => item.id === value.id) === "undefined") {
                            setProdutos((prev) => [...prev, value]);
                        } else {
                            showWarning({
                                summary: "Alerta :(",
                                detail: "Você ja selecionou este produto.",
                                life: 3000,
                            });
                        }
                        break;

                    default:
                        break;
                }
            } catch (error) {
                if (error instanceof Yup.ValidationError) {
                    let errorMessages = {};

                    error.inner.forEach((err) => {
                        errorMessages[err.path] = err.message;
                    });

                    formik.setErrors(errorMessages);
                }
            }
        },
        [produtos, fornecedores, setProdutos, setFornecedores, showWarning]
    );

    const handleChangeCategoria = (e) => {
        setProdutos([]);
        formik.handleChange(e);
    };

    const handleConfirm = (emails) => {
        fecharModal();
        if (emails && emails.length > 0) {
            handlePreSubmit(emails);
        }
    };

    const handlePreSubmit = (emails = []) => {
        setFieldValue("relatorio", true);
        setEmails(emails);
        formik.handleSubmit();
    };

    const handlePreSubmitNaoRelatorio = () => {
        setFieldValue("relatorio", false);
        formik.handleSubmit();
    };

    const limparFiltro = () => {
        formik.resetForm();
    };

    const fecharModal = () => {
        setVisible(() => false);
    };

    const colunas = [
        {
            field: "sku.codigo",
            header: "Código",
            style: { width: "8%" },
        },
        {
            field: "sku.descricao_reduzida",
            header: "Descrição",
        },
        {
            field: "saldo_atual",
            header: "Saldo Fisico",
            action: ({ saldo_atual }) => formatarCasasDecimais(saldo_atual, 2),
        },
        {
            field: "necessidade",
            header: "Necessidade",
        },
        {
            field: "dias_ultima_compra",
            header: "Ult. Compra",
            action: ({ dias_ultima_compra }) => dataToStr(subDays(new Date(), dias_ultima_compra), "dd/MM/yyyy"),
        },
        {
            field: "dias_proxima_compra",
            header: "Data/Compra",
            action: ({ dias_proxima_compra }) => dataToStr(addDays(new Date(), dias_proxima_compra), "dd/MM/yyyy"),
        },
    ];

    return (
        <div className="p-grid">
            <Toast ref={toastRef} />
            <div className="p-col-12">
                <form onSubmit={formik.handleSubmit}>
                    <div className="card">
                        <h5>Relatório de necessidade de compra</h5>
                        <div className="p-fluid p-formgrid p-grid">
                            <div className="p-field p-col-10">
                                <label htmlFor="fornec">Fornecedor:</label>
                                <MakoInputFornecedor
                                    id="fornec"
                                    name="fornec"
                                    value={formik.values.fornec}
                                    onChange={formik.handleChange}
                                    className={classNames({ "p-invalid": formik.errors.fornec })}
                                />
                                {formik.errors.fornec && <small className="p-error">{formik.errors.fornec}</small>}
                            </div>
                            <div className="p-field p-col-2 p-mt-4">
                                <Button
                                    label="Adicionar"
                                    icon="pi pi-plus"
                                    type="button"
                                    onClick={() => handleAdd(formik.values.fornec)}
                                    className="p-button-success p-mt-1"
                                />
                            </div>
                        </div>
                        <div className="p-mb-4">
                            <MakoListagem dadosLocal={fornecedores} colunas={colunasFornecedores} />
                        </div>
                        <div className="p-fluid p-formgrid p-grid">
                            <div className="p-field p-col-6">
                                <label htmlFor="categoria">Categoria *</label>
                                <MakoDropdownCategoriasHierarquicas
                                    id="categoria"
                                    name="categoria"
                                    categoriaTituloSelecionavel
                                    value={formik.values.categoria}
                                    onChange={(e) => handleChangeCategoria(e)}
                                    className={classNames({ "p-invalid": formik.errors.categoria })}
                                />
                                {formik.errors.categoria && (
                                    <small className="p-error">{formik.errors.categoria}</small>
                                )}
                            </div>
                        </div>
                        <div className="p-fluid p-formgrid p-grid">
                            <div className="p-field p-col-10">
                                <label htmlFor="sku">Produto *</label>
                                <MakoBuscaSkuPersonalizada
                                    id="sku"
                                    name="sku"
                                    disabledBusca={!!!formik.values.categoria}
                                    categoriaId={formik.values.categoria}
                                    skuValue={formik.values.sku}
                                    skuChange={(e) => setFieldValue("sku", e)}
                                    skuError={formik.errors.sku}
                                />
                            </div>
                            <div className="p-field p-col-2 p-mt-6">
                                <Button
                                    label="Adicionar"
                                    icon="pi pi-plus"
                                    type="button"
                                    onClick={() => handleAdd(formik.values.sku, "sku")}
                                    className="p-button-success p-mt-2"
                                />
                            </div>
                        </div>
                        <div className="p-mb-4">
                            <MakoListagem dadosLocal={produtos} colunas={colunasProdutos} />
                        </div>
                        <div className="p-grid p-col-12 p-md-6 p-mt-2">
                            <Button
                                label="Pesquisar"
                                icon="pi pi-search"
                                type="button"
                                onClick={() => handlePreSubmitNaoRelatorio()}
                                disabled={fornecedores.length === 0 || produtos.length === 0}
                                className="p-button-success p-mr-2"
                            />
                            <Button
                                label="Gerar PDF"
                                icon="pi pi-file-pdf"
                                type="button"
                                onClick={() => handlePreSubmit()}
                                className="p-button-info p-mr-2"
                            />
                            <Button
                                type="button"
                                onClick={() => setVisible(true)}
                                label="Enviar por email"
                                icon="pi pi-envelope"
                                className="p-button-info p-mr-2"
                            />
                            <Button
                                type="reset"
                                icon="pi pi-trash"
                                label="Limpar"
                                onClick={() => limparFiltro()}
                                className="p-button-warning p-mr-2"
                            />
                        </div>
                        <div>
                            <MakoListagem titulo="Resultado da busca" dadosLocal={dados} colunas={colunas} />
                        </div>
                    </div>
                </form>
            </div>
            <Dialog visible={visible} onHide={fecharModal} style={{ width: "60vw" }} header="Informar emails">
                <MakoSelecionarEmails onCancel={fecharModal} onConfirm={handleConfirm} />
            </Dialog>
        </div>
    );
};
