import React, { forwardRef, useImperativeHandle, useRef, useState } from "react";
import { Button } from "primereact/button";
import { EMPRESA_NFE_SERIE } from "@/assets/constants/parametros";
import useParam from "@/hooks/useParam";

import axios from "axios";
import useToast from "@/hooks/useToast";
import { InputNumber } from "primereact/inputnumber";
import { Dropdown } from "@/components/Dropdown";
import { TIPO_MODELO_NOTA_FISCAL_CHOICE } from "@/assets/constants/constants";
import { axiosPost } from "@/services/http";
import { Dialog } from "primereact/dialog";

const Modal = ({ apiServico }, ref) => {
    const [inicio, setInicio] = useState(0);
    const [fim, setFim] = useState(0);
    const [modelo, setModelo] = useState("nfe");
    const { getParam } = useParam();
    const { showWarning, showError, showSuccess } = useToast();
    const serie_nf = getParam(EMPRESA_NFE_SERIE);
    const [serie, setSerie] = useState(serie_nf?.valor ? serie_nf.valor : 1);
    const [visible, setVisible] = useState(false);

    async function consultarNotas() {
        try {
            const { status, data } = await axios.post(
                `${process.env.REACT_APP_INTEGRACAO_FISCAL}/documents/${apiServico.chave_acesso}/${apiServico.cnpj_integrado}/${modelo}/consultar`,
                {
                    inicio: inicio,
                    fim: fim,
                    serie: serie,
                }
            );

            if (status === 200)
                if (data[0].Codigo === 100 && data[0].Descricao) {
                    const resposta = await axiosPost(`/fiscal/atualizar-notas/`, {
                        documentos: data[0].Documentos,
                        modelo: modelo,
                    });

                    if (resposta.status === 200) {
                        showSuccess({
                            summary: "Sucesso! Código: 100",
                            detail: `Documentos processados com sucesso!`,
                            life: 9000,
                        });
                        fecharModal();
                    }
                } else {
                    showWarning({
                        summary: `Código: ${data[0].Codigo}`,
                        detail: data[0].Descricao,
                        life: 6000,
                    });
                }
            else
                showError({
                    summary: "Erro",
                    detail: `Desculpe, não conseguimos processar sua requisição.`,
                    life: 3000,
                });
        } catch (error) {
            showError({
                summary: "Erro",
                detail: `Falha ao comunicar com o agente fiscal.`,
                life: 3000,
            });
        }
    }

    useImperativeHandle(ref, () => ({ setVisible }));

    const fecharModal = () => {
        setVisible(false);
    };

    return (
        <>
            <Dialog
                header="Consultar Contingências"
                visible={visible}
                breakpoints={{ "960px": "80vw" }}
                style={{ width: "45vw" }}
                onHide={() => fecharModal()}
            >
                <form>
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-12 p-md-12">
                            <label htmlFor="modelo">Modelo *</label>
                            <Dropdown
                                id="modelo"
                                name="modelo"
                                options={TIPO_MODELO_NOTA_FISCAL_CHOICE}
                                optionValue="value"
                                optionLabel="label"
                                placeholder="Selecione um modelo"
                                value={modelo}
                                showClear={false}
                                onChange={(e) => setModelo(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-12 p-md-4">
                            <label htmlFor="inicio">Início *</label>
                            <InputNumber
                                id="inicio"
                                value={inicio}
                                min={0}
                                onValueChange={(e) => setInicio(e.target.value)}
                                autoComplete="off"
                            />
                        </div>
                        <div className="p-field p-col-12 p-md-4">
                            <label htmlFor="fim">Fim *</label>
                            <InputNumber
                                id="fim"
                                value={fim}
                                min={0}
                                onValueChange={(e) => setFim(e.target.value)}
                                autoComplete="off"
                            />
                        </div>
                        <div className="p-field p-col-12 p-md-4">
                            <label htmlFor="serie">Série *</label>
                            <InputNumber
                                id="serie"
                                value={serie}
                                min={1}
                                onValueChange={(e) => setSerie(e.target.value)}
                                autoComplete="off"
                            />
                        </div>
                    </div>
                    <div className="p-grid p-justify-center p-mt-2">
                        <Button
                            label="Consultar"
                            type="button"
                            className="p-button-info"
                            style={{ width: "200px" }}
                            onClick={() => consultarNotas()}
                        />
                    </div>
                </form>
            </Dialog>
        </>
    );
};

export const ContingenciaFormModal = forwardRef(Modal);
