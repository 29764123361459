import { useCallback } from "react";

import { axiosGet, axiosPost } from "@/services/http";
import useLoading from "./useLoading";
import useToast from "./useToast";
import useEmpresa from "./useEmpresa";
import { dataToStr } from "@/assets/util/datas";

const useRelatorio = () => {
    const { showSuccess, showWarning, showError } = useToast();
    const { showLoading, hideLoading } = useLoading();
    const { empresaSelecionadaId } = useEmpresa();

    const abrirRelatorio = useCallback(
        (data, contentType = "", custom_name = dataToStr(new Date(), "dd-MM-yyyyHH:mm:ss")) => {
            const file = new Blob([data], { type: contentType });
            const fileURL = URL.createObjectURL(file);
            if (!contentType.includes("csv")) {
                window.open(fileURL);
            } else {
                const dllink = document.createElement("a");
                dllink.setAttribute("download", `${custom_name}.csv`);
                dllink.href = fileURL;
                dllink.click();
            }
        },
        []
    );

    const baixarRelatorio = useCallback((data, contentType) => {
        const file = new Blob([data], { type: contentType });
        const fileURL = URL.createObjectURL(file);
        const link = document.createElement("a");
        link.href = fileURL;
        link.download = "Relatório MAKO";

        link.dispatchEvent(
            new MouseEvent("click", {
                bubbles: true,
                cancelable: true,
                view: window,
            })
        );

        setTimeout(() => {
            window.URL.revokeObjectURL(data);
            link.remove();
        }, 100);
    }, []);

    const solicitarRelatorio = useCallback(
        async ({
            chave,
            emails = [],
            filtros,
            versao = "1",
            filtros_template = [],
            corpo_email = "",
            enviar_fila = null,
            custom_name = null,
        }) => {
            const body = {
                chave,
                versao,
                emails,
                filtros,
                filtros_template,
                corpo_email,
                enviar_fila,
                empresa: empresaSelecionadaId,
            };

            showLoading();
            const { status, data, headers } = await axiosPost("/relatorios/gerar/", body, {
                responseType: "blob",
            });
            hideLoading();

            if (status === 200) {
                const contentType = headers["content-type"] || "application/json";

                if (contentType !== "application/json") {
                    abrirRelatorio(data, contentType, custom_name);
                } else {
                    showSuccess({
                        summary: "Sucesso!",
                        detail: JSON.parse(await data.text()).msg,
                        life: 1500,
                    });
                }
            } else if (status === 500) {
                showError({
                    summary: "Erro",
                    detail: "Desculpe, não conseguimos processar sua requisição.",
                    life: 3000,
                });
            } else {
                showWarning({
                    summary: "Falhou...",
                    detail: JSON.parse(await data.text()).msg,
                    life: 3000,
                });
            }
        },
        [showLoading, hideLoading, showSuccess, showWarning, showError, abrirRelatorio, empresaSelecionadaId]
    );

    const carregarRelatorio = useCallback(
        async (id, baixar = false) => {
            showLoading();
            const { status, data, headers } = await axiosGet(`/relatorios/relatorios-emitidos-full/${id}/`, {
                responseType: "blob",
            });
            hideLoading();

            if (status === 200) {
                if (!baixar) abrirRelatorio(data, headers["content-type"]);
                else baixarRelatorio(data, headers["content-type"]);
            } else {
                showWarning({
                    summary: "Falhou...",
                    detail: JSON.parse(await data.text()).msg,
                    life: 3000,
                });
            }
        },
        [showLoading, hideLoading, showWarning, abrirRelatorio, baixarRelatorio]
    );

    return { solicitarRelatorio, carregarRelatorio };
};

export default useRelatorio;
