import React, { forwardRef, memo, useCallback, useEffect, useState } from "react";
import { Dropdown } from "primereact/dropdown";
import { InputNumber } from "primereact/inputnumber";
import { Button } from "primereact/button";
import { useFormik } from "formik";
import {
    TIPO_FINALIDADE_NF_CHOICE,
    TIPO_FORMA_EMISSAO_CHOICE,
    TIPO_INDICADOR_FINAL_CHOICE,
    TIPO_INDICADOR_FORMA_PAGAMENTO_NF_CHOICE,
    TIPO_INDICADOR_PRESENCA_NF_CHOICE,
    TIPO_LOCAL_DESTINO_NOTA_FISCAL,
    TIPO_MODELO_NOTA_FISCAL,
    TIPO_OPERACAO_NF_CHOICE,
} from "@/assets/constants/constants";
import { MakoAutoComplete } from "@/components/MakoAutoComplete";
import { axiosGet } from "@/services/http";
import classNames from "classnames";
import * as Yup from "yup";
import useNotaFiscal from "@/hooks/useNotaFiscal";
import { EMPRESA_NFCE_SERIE, EMPRESA_NFE_SERIE } from "@/assets/constants/parametros";
import useParam from "@/hooks/useParam";
import { Dialog } from "primereact/dialog";
import useEmpresa from "@/hooks/useEmpresa";
import { FormikAutoSave } from "@/components/FormikAutoSave";
import { Divider } from "primereact/divider";
import { PageBase } from "@/components/PageBase";
import { MakoCalendarTime } from "@/components/MakoCalendarTime";
import useToast from "@/hooks/useToast";
import { SelectButton } from "primereact/selectbutton";
import useAuth from "@/hooks/useAuth";
import permissoes from "@/assets/constants/permissoes";

export const EmissaoForm = () => {
    const { empresaSelecionada, empresaSelecionadaId } = useEmpresa();
    const [estados, setEstados] = useState([]);
    const [exibirNotas, setExibirNotas] = useState(false);
    const [notaRef, setNotaRef] = useState(null);
    const [numero, setNumero] = useState(null);
    const { dadosBasicos, handleDadosBasicos } = useNotaFiscal();
    const { getParam } = useParam();
    const { showWarning, showError, showInfo } = useToast();
    const { verifyPermission } = useAuth();

    const { setValues, setFieldValue, ...formik } = useFormik({
        initialValues: {
            status: "P",
            emitente: null,
            codigo_nf: 300,
            codigo_uf:
                empresaSelecionada?.enderecoperfil_set?.length > 0 && empresaSelecionada?.enderecoperfil_set[0].cidade
                    ? empresaSelecionada?.enderecoperfil_set[0].cidade.estado.codigo_uf
                    : null,
            natureza_operacao: "",
            indicador_pagamento: 1,
            id_dest: 1,
            digito_verificador: null,
            modelo: "55",
            regime_especial: 5,
            dastinatario: null,
            serie: 1,
            numero: null,
            chave_nf: null,
            nota_referencia: [],
            fuso_horario: "-03:00",
            tipo_operacao: 1,
            indicador_final: 1,
            datahora_saida: new Date(),
            municipio_ocorrencia:
                empresaSelecionada?.enderecoperfil_set?.length > 0 && empresaSelecionada?.enderecoperfil_set[0].cidade
                    ? empresaSelecionada?.enderecoperfil_set[0].cidade
                    : null,
            indicador_presenca: 1,
            formato_impressao: 2,
            forma_emissao: 1,
            ambiente: process.env.REACT_APP_AMB_INVOICY, //1- PRODUCAO 2- HOMOLOGACAO
            finalidade: 1,
            processo_emissao: 0,
            versao_processo: "MigrateInvoiCy",
            requer_identificacao: true,
        },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            values = { ...values, numero: values.numero || numero };
            const formSchema = Yup.object().shape({
                cfop: Yup.object()
                    .required("O campo é obrigatório!")
                    .typeError("Informe uma 'natureza da operação' válida."),
                municipio_ocorrencia: Yup.object().required("O campo é obrigatório!"),
                numero: Yup.number().required("O campo é obrigatório!"),
                serie: Yup.number().required("O campo é obrigatório!"),
            });

            await formSchema.validate(values, {
                abortEarly: false,
            });

            const { status } = await handleDadosBasicos(values);

            if (status !== 200 && status !== 201) {
                showError({
                    summary: "Erro",
                    detail: "Desculpe, não foi possível salvar as informações da nota fiscal.",
                    life: 1500,
                });
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            } else {
                showError({
                    summary: "Erro",
                    detail: "Desculpe, não conseguimos processar a sua requisição.",
                    life: 1500,
                });
            }
        }
    }

    const listarEstados = useCallback(async () => {
        const resposta = await axiosGet("/pessoas/estados?limit=300");
        if (resposta.status === 200) setEstados(resposta.data.results);
    }, []);

    const autoCompleteCidadeTemplate = (valor) => {
        valor.label = `${valor.codigo} - ${valor.nome}`;
        return `${valor.codigo} - ${valor.nome}`;
    };

    const autoCompleteDestTemplate = (valor) => {
        return `${valor.nome} - ${valor.identificacao}`;
    };

    const insereNotaRef = () => {
        let lista = [];
        if (formik.values.nota_referencia?.length > 0) lista = formik.values.nota_referencia;
        lista.push(notaRef.chave_nf);
        setFieldValue("nota_referencia", lista);
        setNotaRef(null);
    };

    useEffect(() => {
        if (!dadosBasicos?.id) {
            if (!(formik.values.serie && formik.values.modelo)) {
                const serie = getParam(formik.values.modelo === "55" ? EMPRESA_NFE_SERIE : EMPRESA_NFCE_SERIE);
                if (serie?.valor) setFieldValue("serie", serie.valor);
                else {
                    showWarning({
                        summary: "Parâmetro não configurado.",
                        detail: "O parâmetro referente a série utilizada pela empresa não foi configurado! Por favor, verifique as configurações do aplicativo.",
                        life: 3500,
                    });
                    setFieldValue("serie", null);
                }
            }
        }
    }, [getParam, dadosBasicos?.id, formik.values?.modelo, setFieldValue, formik.values?.serie, showWarning]);

    const carregaNumeroNf = useCallback(async () => {
        if (!formik.values?.id) {
            const resposta = await axiosGet(
                `/fiscal/numeros-notas-fiscais?ordering=-numero&limit=1&query={numero}&modelo=${formik.values.modelo}&empresa=${empresaSelecionadaId}&serie=${formik.values.serie}`
            );
            if (resposta.status === 200)
                if (resposta.data.results.length > 0) setNumero(resposta.data.results[0].numero + 1);
                else setNumero(1);
        }
    }, [formik.values?.modelo, formik.values.serie, formik.values?.id, empresaSelecionadaId]);

    useEffect(() => {
        carregaNumeroNf();
    }, [carregaNumeroNf]);

    useEffect(() => {
        listarEstados();
    }, [listarEstados]);

    useEffect(() => {
        if (dadosBasicos?.id) setValues(dadosBasicos);
    }, [setValues, dadosBasicos]);

    const selecionaCfop = (value) => {
        setFieldValue("cfop", value);
        if (value instanceof Object && formik.values.id) {
            handleSubmit({ ...formik.values, cfop: value });
        }
    };

    const selecionaDestinatario = (dest) => {
        if (dest instanceof Object) {
            if (dest?.enderecoperfil_set?.length === 0)
                showWarning({
                    summary: "Destinatário sem endereço",
                    detail: "O destinatário não possui endereço cadastrado, preencha todas as informações necessárias no cadastro de pessoas e tente novamente.",
                    life: 3000,
                });
            else setFieldValue("destinatario", dest);
        }
    };

    const alteraModeloNf = (value) => {
        setFieldValue("modelo", value);
        showInfo({
            summary: "Modelo alterado!",
            detail: "Não esqueça de se certificar que o destinatário é compatível com o modelo selecionado.",
            life: 3000,
        });
    };

    return (
        <PageBase>
            <form onSubmit={formik.handleSubmit}>
                <SelectButton
                    id="modelo"
                    name="modelo"
                    value={formik.values.modelo}
                    onChange={({ value }) => alteraModeloNf(value)}
                    optionValue="value"
                    optionLabel="label"
                    tooltip="Refere-se ao modelo atual da nota fiscal"
                    tooltipOptions={{ position: "left" }}
                    options={TIPO_MODELO_NOTA_FISCAL}
                    disabled={!verifyPermission(permissoes.FISCAL_NOTASAIDA_MODELO_ALTERAR)}
                />
                <Divider align="center">
                    <b>Destinatário</b>
                </Divider>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-12">
                        <MakoAutoComplete
                            id="destinatario"
                            name="destinatario"
                            placeholder="Busque pelo nome ou cpf/cnpj ... (min 4 caractéres)"
                            minCaracteresBusca={4}
                            delay={1000}
                            value={formik.values.destinatario}
                            onChange={(e) => selecionaDestinatario(e.target.value)}
                            itemTemplate={autoCompleteDestTemplate}
                            field="nome"
                            urlSearch={"/pessoas/perfis?search="}
                        />
                        {formik.errors.destinatario && <small className="p-error">{formik.errors.destinatario}</small>}
                    </div>
                </div>
                <Divider align="center">
                    <b>Informações Básicas</b>
                </Divider>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-8">
                        <label htmlFor="cfop">Natureza da Operação *</label>
                        <MakoAutoComplete
                            id="cfop"
                            name="cfop"
                            placeholder="Busque pelo código ou descrição ... (min 2 caracteres)"
                            minCaracteresBusca={2}
                            value={formik.values.cfop}
                            onChange={({ value }) => selecionaCfop(value)}
                            field="descricao_completa"
                            urlSearch={"/fiscal/cfop?limit=60&search="}
                            className={classNames({ "p-invalid": formik.errors.cfop })}
                        />
                        {formik.errors.cfop && <small className="p-error">{formik.errors.cfop}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="numero">Número *</label>
                        <InputNumber
                            id="numero"
                            name="numero"
                            value={formik.values.numero || numero}
                            onValueChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.numero })}
                        />
                        {formik.errors.numero && <small className="p-error">{formik.errors.numero}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="serie">Série *</label>
                        <InputNumber
                            id="serie"
                            name="serie"
                            value={formik.values.serie}
                            onValueChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.serie })}
                        />
                        {formik.errors.serie && <small className="p-error">{formik.errors.serie}</small>}
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-12">
                        <label htmlFor="nota_ref">Nota(s) referência</label>
                        <div className="p-inputgroup">
                            <MakoAutoComplete
                                id="nota_ref"
                                name="nota_ref"
                                placeholder="Busque pela chave... (min 4 caracteres)"
                                minCaracteresBusca={4}
                                value={notaRef}
                                onChange={(e) => setNotaRef(e.value)}
                                field="chave_nf"
                                urlSearch={
                                    "/fiscal/notas-fiscais?limit=30&chave_nf__icontains=False&chave_nf__icontains="
                                }
                            />
                            <Button
                                type="button"
                                icon="pi pi-plus"
                                className="p-button-success"
                                disabled={!notaRef || typeof notaRef !== "object"}
                                onClick={() => insereNotaRef()}
                            />
                            <Button
                                type="button"
                                icon="pi pi-trash"
                                className="p-button-warning p-ml-1"
                                disabled={!(formik.values.nota_referencia?.length > 0)}
                                tooltip="Limpar notas"
                                tooltipOptions={{ position: "left" }}
                                onClick={() => setFieldValue("nota_referencia", [])}
                            />
                            <Button
                                type="button"
                                icon="pi pi-book"
                                className="p-button-info p-ml-1"
                                style={{ width: "50px" }}
                                badge={`${formik.values.nota_referencia?.length || 0}`}
                                tooltip={
                                    formik.values.nota_referencia?.length > 0
                                        ? "Exibir notas referenciadas"
                                        : "Não existem notas referenciadas"
                                }
                                tooltipOptions={{ position: "left" }}
                                onClick={() => setExibirNotas(true)}
                            />
                        </div>
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="indicador_pagamento">Indicador pagamento *</label>
                        <Dropdown
                            id="indicador_pagamento"
                            name="indicador_pagamento"
                            placeholder="Selecione um indicador"
                            options={TIPO_INDICADOR_FORMA_PAGAMENTO_NF_CHOICE}
                            optionValue="id"
                            optionLabel="label"
                            value={formik.values.indicador_pagamento}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.indicador_pagamento })}
                        />
                        {formik.errors.indicador_pagamento && (
                            <small className="p-error">{formik.errors.indicador_pagamento}</small>
                        )}
                    </div>
                    <div className="p-field p-col-12 p-md-4">
                        <label htmlFor="municipio_ocorrencia">Município de ocorrência *</label>
                        <MakoAutoComplete
                            id="municipio_ocorrencia"
                            name="municipio_ocorrencia"
                            placeholder="Busque pelo nome ou código do município ... (min 4 caracteres)"
                            minCaracteresBusca={4}
                            value={formik.values.municipio_ocorrencia}
                            onChange={formik.handleChange}
                            itemTemplate={autoCompleteCidadeTemplate}
                            field="nome"
                            urlSearch={"/pessoas/cidades?query={id, codigo, nome}&search="}
                            className={classNames({ "p-invalid": formik.errors.municipio_ocorrencia })}
                        />
                        {formik.errors.municipio_ocorrencia && (
                            <small className="p-error">{formik.errors.municipio_ocorrencia}</small>
                        )}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="codigo_uf">UF *</label>
                        <Dropdown
                            id="codigo_uf"
                            name="codigo_uf"
                            placeholder="Selecione um estado"
                            options={estados}
                            filter
                            filterBy="nome"
                            optionValue="codigo_uf"
                            optionLabel="nome"
                            value={formik.values.codigo_uf}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.codigo_uf })}
                        />
                        {formik.errors.codigo_uf && <small className="p-error">{formik.errors.codigo_uf}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="tipo_operacao">Tipo Operação *</label>
                        <Dropdown
                            id="tipo_operacao"
                            name="tipo_operacao"
                            placeholder="Selecione a operação"
                            options={TIPO_OPERACAO_NF_CHOICE}
                            optionValue="id"
                            optionLabel="label"
                            value={formik.values.tipo_operacao}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.tipo_operacao })}
                        />
                        {formik.errors.tipo_operacao && (
                            <small className="p-error">{formik.errors.tipo_operacao}</small>
                        )}
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="indicador_final">Consumidor final *</label>
                        <Dropdown
                            id="indicador_final"
                            name="indicador_final"
                            placeholder="Selecione um indicador"
                            options={TIPO_INDICADOR_FINAL_CHOICE}
                            optionValue="id"
                            optionLabel="label"
                            value={formik.values.indicador_final}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.indicador_final })}
                        />
                        {formik.errors.indicador_final && (
                            <small className="p-error">{formik.errors.indicador_final}</small>
                        )}
                    </div>
                    <div className="p-field p-col-12 p-md-4">
                        <label htmlFor="finalidade">Finalidade *</label>
                        <Dropdown
                            id="finalidade"
                            name="finalidade"
                            placeholder="Selecione uma finalidade"
                            options={TIPO_FINALIDADE_NF_CHOICE}
                            optionValue="id"
                            optionLabel="label"
                            value={formik.values.finalidade}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.finalidade })}
                        />
                        {formik.errors.finalidade && <small className="p-error">{formik.errors.finalidade}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-5">
                        <label htmlFor="indicador_presenca">Indicador presença *</label>
                        <Dropdown
                            id="indicador_presenca"
                            name="indicador_presenca"
                            placeholder="Selecione um indicador"
                            options={TIPO_INDICADOR_PRESENCA_NF_CHOICE}
                            optionValue="id"
                            optionLabel="label"
                            value={formik.values.indicador_presenca}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.indicador_presenca })}
                        />
                        {formik.errors.indicador_presenca && (
                            <small className="p-error">{formik.errors.indicador_presenca}</small>
                        )}
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-6">
                        <label htmlFor="forma_emissao">Forma emissão *</label>
                        <Dropdown
                            id="forma_emissao"
                            name="forma_emissao"
                            placeholder="Selecione a forma de emissão"
                            options={TIPO_FORMA_EMISSAO_CHOICE}
                            optionValue="id"
                            optionLabel="label"
                            value={formik.values.forma_emissao}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.forma_emissao })}
                        />
                        {formik.errors.forma_emissao && (
                            <small className="p-error">{formik.errors.forma_emissao}</small>
                        )}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="id_dest">Indicador destino *</label>
                        <Dropdown
                            id="id_dest"
                            name="id_dest"
                            placeholder="Selecione um indicador"
                            options={TIPO_LOCAL_DESTINO_NOTA_FISCAL}
                            optionValue="value"
                            optionLabel="label"
                            value={formik.values.id_dest}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.id_dest })}
                        />
                        {formik.errors.id_dest && <small className="p-error">{formik.errors.id_dest}</small>}
                    </div>
                </div>
                <p>
                    <b>* Campos obrigatórios.</b>
                </p>
                <br></br>
                {!dadosBasicos?.id ? (
                    <div className="p-grid">
                        <div className="p-col-12 p-md-6">
                            <Button
                                type="submit"
                                icon="pi pi-save"
                                label="Incluir dados básicos"
                                className="p-button-info"
                            />
                        </div>
                    </div>
                ) : null}
                <FormikAutoSave intervalo={500} autosave={typeof dadosBasicos?.id === "number"} formik={formik} />
            </form>
            <Dialog
                header="Nota(s) Referênciadas"
                visible={exibirNotas}
                breakpoints={{ "960px": "80vw" }}
                style={{ width: "30vw" }}
                onHide={() => setExibirNotas(false)}
            >
                {formik.values.nota_referencia?.length > 0
                    ? formik.values.nota_referencia.map((nota) => {
                          const chave = nota instanceof Object ? nota.chave_nf : nota;
                          return (
                              <div key={chave}>
                                  <card className="p-mb-1">
                                      <b>{chave}</b>
                                  </card>
                              </div>
                          );
                      })
                    : null}
            </Dialog>
        </PageBase>
    );
};

export default memo(forwardRef(EmissaoForm));
