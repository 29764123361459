import React, { useCallback, useRef } from "react";
import { Redirect, Link } from "react-router-dom";
import classNames from "classnames";

import AppBreadcrumb from "../AppBreadcrumb";
import MakoAjuda from "../MakoAjuda";
import { AppNotificacoes } from "../AppNotificacoes";
import { ModalAlterarSenhaUsuario } from "@/pages/Gestao/Usuarios/GerenciarUsuarios/modal/AlterarSenhaUsuario";
import useAuth from "@/hooks/useAuth";
import useParam from "@/hooks/useParam";
import { MakoUsuarioEmpresas } from "../MakoUsuarioEmpresas";
import useCaixaMovimento from "@/hooks/useCaixaMovimento";
import useMakoSidebarConsultarSaldos from "@/hooks/useMakoSidebarConsultarSaldos";
import { MAKO_ICONS } from "@/assets/constants/constants_styles";
import { MakoHistoricoLogs } from "../MakoLogs";

const BASEURL_GERENCIAR_CAIXA = "/financeiro/financeiro/gerenciar-caixa";

const AppTopbar = (props) => {
    const modalAltSenhaRef = useRef(null);
    const logRef = useRef(null);
    const { signed, signOut, user, reloadPermissions } = useAuth();
    const { loadParams } = useParam();
    const { caixaMov: caixa } = useCaixaMovimento();
    const { show } = useMakoSidebarConsultarSaldos();

    const notificationsItemClassName = classNames("notifications-item", {
        "active-menuitem": props.topbarNotificationMenuActive,
    });

    const profileItemClassName = classNames("profile-item", {
        "active-menuitem fadeInDown": props.topbarUserMenuActive,
    });

    const caixaItemClassName = classNames("caixa-item", {
        "active-menuitem fadeInDown": props.topbarCaixaMenuActive,
    });

    const handleSignOut = useCallback(() => {
        signOut();
    }, [signOut]);

    return !signed ? (
        <Redirect to="/login" />
    ) : (
        <>
            <ModalAlterarSenhaUsuario ref={modalAltSenhaRef} />
            <MakoHistoricoLogs ref={logRef} />
            <div className="layout-topbar">
                <div className="topbar-left">
                    <button type="button" className="menu-button p-link" onClick={props.onMenuButtonClick}>
                        <i className="pi pi-chevron-left" />
                    </button>
                    <span className="topbar-separator" />
                    <div
                        className="layout-breadcrumb viewname"
                        style={{ textTransform: "uppercase", fontSize: "13px" }}
                    >
                        <AppBreadcrumb routers={props.routers} />
                    </div>
                    <img
                        id="logo-mobile"
                        className="mobile-logo"
                        src="assets/layout/images/logo-dark.svg"
                        alt="diamond-layout"
                    />
                </div>

                <div className="topbar-right">
                    <ul className="topbar-menu">
                        <li className={profileItemClassName}>
                            <i className="pi pi-building p-mr-2" />
                            <MakoUsuarioEmpresas />
                        </li>
                        {caixa && (
                            <li className={caixaItemClassName}>
                                <button type="button" className="p-link" onClick={props.onTopbarCaixa}>
                                    <i className="fas fa-cash-register" />
                                </button>
                                <ul className="profile-menu fade-in-up">
                                    <li>
                                        <Link className="p-link" to={BASEURL_GERENCIAR_CAIXA} replace>
                                            <i className="fas fa-cash-register" />
                                            <span>{caixa?.caixa?.conta_financeira?.codigo}</span>
                                        </Link>
                                    </li>
                                    <li>
                                        <button type="button" className="p-link">
                                            {caixa?.bloqueado ? (
                                                <i className="pi pi-lock" style={{ color: "#D32F2F" }} />
                                            ) : (
                                                <i className="pi pi-unlock" style={{ color: "#689F38" }} />
                                            )}
                                            <span>{`Situação: ${
                                                caixa?.bloqueado ? "Bloqueado" : "Desbloqueado"
                                            }`}</span>
                                        </button>
                                    </li>
                                </ul>
                            </li>
                        )}
                        <li className={notificationsItemClassName}>
                            <AppNotificacoes onClick={props.onTopbarNotification} />
                        </li>
                        <li>
                            <MakoAjuda />
                        </li>
                        <li className={profileItemClassName}>
                            <button type="button" className="p-link" onClick={props.onTopbarUserMenu}>
                                <i className="pi pi-user" />
                                <span className="profile-name"> {" " + user.usuario.username}</span>
                            </button>
                            <ul className="profile-menu fade-in-up">
                                <li>
                                    <button
                                        type="button"
                                        className="p-link"
                                        onClick={() => {
                                            loadParams();
                                        }}
                                    >
                                        <i className="pi pi-cog" />
                                        <span>Recarregar parâmetros</span>
                                    </button>
                                </li>
                                <li>
                                    <button
                                        type="button"
                                        className="p-link"
                                        onClick={() => {
                                            reloadPermissions();
                                            window.location.reload();
                                        }}
                                    >
                                        <i className="pi pi-check-square" />
                                        <span>Recarregar permissões</span>
                                    </button>
                                </li>
                                <li>
                                    <button type="button" className="p-link" onClick={show}>
                                        <i className={MAKO_ICONS.PESQUISAR} />
                                        <span>Consultar saldo</span>
                                    </button>
                                </li>
                                <li>
                                    <button
                                        type="button"
                                        className="p-link"
                                        onClick={() => logRef.current?.abrirModal(user)}
                                    >
                                        <i className={MAKO_ICONS.HISTORICO} />
                                        <span>Histórico de logs</span>
                                    </button>
                                </li>
                                <li>
                                    <button
                                        type="button"
                                        className="p-link"
                                        onClick={() => modalAltSenhaRef.current?.abrirModal(user)}
                                    >
                                        <i className="pi pi-lock" />
                                        <span>Alterar Senha</span>
                                    </button>
                                </li>
                                <li>
                                    <button type="button" className="p-link" onClick={handleSignOut}>
                                        <i className="pi pi-power-off" />
                                        <span>Sair do Sistema</span>
                                    </button>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>
        </>
    );
};

export default AppTopbar;
