import React, { useState, useRef, useCallback } from "react";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import permissoes from "@/assets/constants/permissoes";
import MakoListagem from "@/components/MakoListagem";
import { ClonarProtocoloModalForm } from "./modals/clonarProtocoloModalForm";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { FiltroAvancadoCrediario } from "./modals/filtroAvancadoModal";
import { useLocalFiltro } from "@/hooks/useLocalFiltro";
import { key_filtros } from "@/assets/constants/filtros";
import {
    CodigoFiltroTemplate,
    DateFiltroTemplate,
    MoedaFiltroTemplate,
    TextoFiltroTemplate,
} from "@/components/MakoFiltrosCabecalho";
import useAuth from "@/hooks/useAuth";
import { Menu } from "primereact/menu";
import { axiosPatch } from "@/services/http";
import useToast from "@/hooks/useToast";
import { COLORS_CREDIARIO, MAKO_ICONS } from "@/assets/constants/constants_styles";
import useRelatorio from "@/hooks/useRelatorio";
import useFetchRelatorio from "@/hooks/useFetchRelatorio";
import { RELATORIO_VENDAS_FICHACREDIARIO } from "@/assets/constants/relatorios";
import useEmpresa from "@/hooks/useEmpresa";
import { PageBase } from "@/components/PageBase";
import { MakoAjudaOverlay } from "@/components/MakoAjudaOverlay";
import { Tag } from "primereact/tag";

const BASE_URL = "/crediario/protocolo-crediario/";
export const CrediarioPage = () => {
    const [filtros, setFiltro, removerFiltro, filtroString] = useLocalFiltro(key_filtros.VENDAS_VENDA_CREDIARIO);
    const [url, setUrl] = useState(() => {
        if (filtros) return `${BASE_URL}?${filtroString}`;
        return BASE_URL;
    });
    const [exibirClonar, setExibirClonar] = useState(false);
    const [crediario, setCrediario] = useState(null);
    const [totalizadorFiltros, setTotalizadorFiltros] = useState(0);
    const listagemRef = useRef(null);
    const filtroRef = useRef(null);
    const menuRef = useRef(null);
    const history = useHistory();
    const { verifyPermission } = useAuth();
    const { showError, showSuccess, showWarning, showInfo } = useToast();
    const { solicitarRelatorio } = useRelatorio();
    const { relatorio } = useFetchRelatorio({ chave: RELATORIO_VENDAS_FICHACREDIARIO });
    const { empresaSelecionadaId } = useEmpresa();

    const gerarPdf = (crediario_id) => {
        solicitarRelatorio({
            versao: 3,
            chave: relatorio.chave,
            filtros: { crediario_id: crediario_id, empresaSelecionada: empresaSelecionadaId },
            enviar_fila: false,
        });
    };

    const validarCrediario = useCallback(async () => {
        const { status, data } = await axiosPatch(`/crediario/protocolo-crediario/${crediario.id}/`, { status: "P" });

        if (status === 200) {
            listagemRef.current?.buscarDados();
            if (data.status === "P") {
                showSuccess({
                    summary: "Sucesso!",
                    detail: "O crediário foi validado com sucesso! Agora o mesmo está pendente para aprovação.",
                    life: 3000,
                });
            } else if (data?.status === "I") {
                showWarning({
                    summary: "Crediário incompleto",
                    detail: `O crediário não atende aos critérios necessários, por favor verifique as informações e tente novamente. ${
                        data?.historico_validacao ? `(${data.historico_validacao})` : ""
                    }`,
                    life: 9000,
                });
            } else if (data?.status === "R") {
                listagemRef.current?.buscarDados();
                showInfo({
                    summary: "Crediário Rejeitado",
                    detail: `O crediário foi validado porém as informaçẽos estão inconsistêntes, uma rejeição foi registrada. ${
                        data?.historico_validacao ? `(${data.historico_validacao})` : ""
                    }`,
                    life: 3000,
                });
            } else {
                showError({
                    summary: "Erro",
                    detail: `Desculpe, não foi possível validar o crediário. ${data?.erros ? `(${data.erros})` : ""}`,
                    life: 3000,
                });
            }
        }
    }, [showError, showSuccess, showWarning, showInfo, crediario?.id]);

    const botoes = [
        {
            label: "Opções",
            items: [
                {
                    label: "Clonar protocolo",
                    icon: "pi pi-clone",
                    disabled: !verifyPermission([permissoes.VENDAS_VENDA_CREDIARIO_INCLUIR]),
                    command: () => setExibirClonar(true),
                },
                {
                    label: "Validar",
                    icon: "pi pi-check-square",
                    disabled: !verifyPermission([permissoes.VENDAS_VENDA_CREDIARIO_VALIDARCREDIARIO]),
                    command: () => validarCrediario(),
                },
            ],
        },
    ];

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <MakoControleAcesso
                    permissao={[permissoes.VENDAS_VENDA_CREDIARIO_EDITAR]}
                    componente={Button}
                    icon="pi pi-pencil"
                    className="p-button-rounded p-button-warning p-mr-2 p-mb-1"
                    tooltip="Alterar cadastro de crediario"
                    tooltipOptions={{ position: "left" }}
                    onClick={() =>
                        history.push({
                            pathname: "/vendas/venda/crediario/form",
                            crediario: rowData,
                        })
                    }
                />
                <MakoControleAcesso
                    permissao={[permissoes.VENDAS_VENDA_CREDIARIO_EDITAR]}
                    componente={Button}
                    icon="pi pi-cog"
                    className="p-button-rounded p-button-info p-mr-2 p-mb-1"
                    tooltip="Opções"
                    tooltipOptions={{ position: "left" }}
                    disabled={rowData.status === "R"}
                    onClick={(e) => {
                        setCrediario(rowData);
                        menuRef.current?.toggle(e);
                    }}
                />
                <Button
                    onClick={() => {
                        gerarPdf(rowData.id);
                    }}
                    icon={MAKO_ICONS.ARQUIVO_PDF}
                    label=""
                    className="p-button-rounded p-button-help p-mr-2 p-mb-1"
                />
            </div>
        );
    };

    const removerFiltros = () => {
        removerFiltro();
        setTotalizadorFiltros(0);
        setUrl(BASE_URL);
    };

    const cabecalhoTabela = (
        <>
            <MakoControleAcesso
                permissao={[permissoes.VENDAS_VENDA_CREDIARIO_INCLUIR]}
                componente={Button}
                label="Novo"
                icon="pi pi-plus"
                className="p-button-success p-mr-2"
                onClick={() => history.push("/vendas/venda/crediario/form")}
            />
            <Button
                label="Filtro Avançado"
                icon="pi pi-filter"
                className="p-button-help p-mr-2"
                badge={totalizadorFiltros > 0 ? totalizadorFiltros : null}
                onClick={() => filtroRef.current?.abrirModal()}
            />
            <Button
                label="Limpar Filtros"
                icon="pi pi-trash"
                className="p-button-warning p-mr-2"
                onClick={() => removerFiltros()}
            />
        </>
    );

    const esconderDialogClonar = () => {
        setCrediario(null);
        setExibirClonar(false);
    };

    const onConfirmFiltro = (novaUrl, contador) => {
        setUrl(novaUrl);
        setTotalizadorFiltros(contador);
    };

    const rowClass = ({ status }) => {
        return {
            "table-recebimentos-overdue": status === "R",
            "table-recebimentos-effective": status === "A",
            "table-recebimentos-pending": status === "P",
        };
    };

    const colunas = [
        {
            field: "id",
            header: "Protocolo",
            style: { width: "12%" },
            filter: true,
            filterElement: CodigoFiltroTemplate,
        },
        {
            field: "perfil_titular.identificacao",
            header: "CPF/CNPJ",
            style: { width: "15%" },
            filter: true,
            filterElement: TextoFiltroTemplate,
            filterField: "perfil_titular__identificacao",
        },
        {
            field: "perfil_titular.nome",
            header: "Nome do cliente",
            style: { minWidth: "180px" },

            filter: true,
            filterElement: TextoFiltroTemplate,
            filterField: "perfil_titular__nome",
        },
        {
            field: "orcamento.id",
            header: "Orçamento",
            filter: true,
            filterElement: CodigoFiltroTemplate,
            filterField: "orcamento__id",
        },
        {
            field: "orcamento.data",
            header: "Data",
            style: { width: "10%" },
            dateFormat: "dd/MM/yyyy",
            filter: true,
            filterElement: DateFiltroTemplate,
            filterField: "orcamento__data",
        },
        {
            field: "orcamento.valor_total_ap",
            header: "Valor",
            money: true,
            filter: true,
            filterElement: MoedaFiltroTemplate,
            filterField: "orcamento__valor_total_ap",
        },
        {
            field: "action",
            header: "Ações",
            action: (e) => actionBodyTemplate(e),
            style: { width: "15%" },
        },
    ];

    const legenda = (
        <>
            <MakoAjudaOverlay
                buttonProps={{
                    style: {
                        color: "red",
                        fontSize: "1.5rem",
                        display: "inline-flex",
                        alignItem: "center",
                        textAlign: "center",
                    },
                    className: "p-link p-px-2 p-mr-2",
                }}
                icon="pi pi-palette"
                showCloseIcon={false}
            >
                <div>
                    <h6>
                        <b>Legenda de cores:</b>
                    </h6>
                    {React.Children.toArray(
                        Object.keys(COLORS_CREDIARIO)
                            .map((k) => (
                                <Tag
                                    value={k !== "CONCLUIDA" ? k : "RECEBIDO"}
                                    className="p-mr-2"
                                    style={{
                                        backgroundColor: "white",
                                        color: COLORS_CREDIARIO[k],
                                        width: "min-content",
                                        borderColor: COLORS_CREDIARIO[k],
                                        borderWidth: "1px",
                                        borderStyle: "solid",
                                    }}
                                />
                            ))
                            .reverse()
                    )}
                </div>
            </MakoAjudaOverlay>
        </>
    );

    return (
        <PageBase>
            <Menu model={botoes} popup ref={menuRef} />
            <MakoListagem
                ref={listagemRef}
                titulo="Crediário"
                colunas={colunas}
                painelEsquerdo={cabecalhoTabela}
                painelDireito={legenda}
                urlPesquisa={url}
                filtros={{
                    id: { value: "", matchMode: "equals" },
                    perfil_titular__identificacao: { value: "", matchMode: "icontains" },
                    perfil_titular__nome: { value: "", matchMode: "icontains" },
                    orcamento__id: { value: "", matchMode: "equals" },
                    orcamento__data: { value: "", matchMode: "equals" },
                    orcamento__valor_total_ap: { value: "", matchMode: "equals" },
                }}
                configTabela={{
                    rowClassName: rowClass,
                    paginator: true,
                    lazy: true,
                }}
            />

            <FiltroAvancadoCrediario
                ref={filtroRef}
                onConfirm={onConfirmFiltro}
                url={BASE_URL}
                filtros={filtros}
                setFiltro={setFiltro}
                removerFiltro={removerFiltros}
                setTotalizadorFiltros={setTotalizadorFiltros}
            />
            <Dialog
                header="Clonar Protocolo de Crediário"
                visible={exibirClonar}
                breakpoints={{ "960px": "75vw" }}
                style={{ width: "30vw", display: "block" }}
                onHide={() => esconderDialogClonar()}
            >
                <ClonarProtocoloModalForm
                    crediario={crediario}
                    listagem={listagemRef}
                    esconderDialog={esconderDialogClonar}
                />
            </Dialog>
        </PageBase>
    );
};
