import React, { useEffect } from "react";

import classNames from "classnames";

import { useFormik } from "formik";
import * as Yup from "yup";

import { InputTextarea } from "primereact/inputtextarea";
import { InputNumber } from "primereact/inputnumber";
import { Button } from "primereact/button";

import { MakoAutoComplete } from "@/components/MakoAutoComplete";
import { MakoInputMoeda } from "@/components/MakoInputMoeda";
import { MakoCalendar } from "@/components/MakoCalendar";
import { Dropdown } from "@/components/Dropdown";

import { TIPOS_CHOICE_SERVICOS_SERVICO } from "@/assets/constants/constants";
import { axiosPatch, axiosPost } from "@/services/http";
import { dataToStr } from "@/assets/util/datas";

import useContratoServico from "@/hooks/useContratoServico";
import useLoading from "@/hooks/useLoading";
import useToast from "@/hooks/useToast";

export const ModalApontamento = ({ onFinish, onClose, servico, apontamento }) => {
    const { showLoading, hideLoading } = useLoading();
    const { showError, showSuccess } = useToast();
    const { contrato } = useContratoServico();

    const { setValues, setFieldValue, ...formik } = useFormik({
        initialValues: {
            servico_contratado: servico ? { ...servico.servico, label: servico.label, id: servico.id } : null,
            tipo: servico ? servico.servico.tipo : null,
            data_inicial: null,
            data_final: null,
            total_horas: 0,
            custo: servico ? servico.servico.custo : null,
            valor: servico ? servico.servico.valor_venda : null,
            valor_nfs: servico ? servico.servico.valor_venda : null,
            descricao: "",
        },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                descricao: Yup.string()
                    .max(255, "Máximo de caracteres atingido: 100")
                    .required("O campo 'descrição' é obrigatório")
                    .typeError("Informe um 'descrição' válido"),
                data_inicial: Yup.date()
                    .required("O campo 'data inicial' é obrigatório")
                    .typeError("Informe uma 'data inicial' válida"),
                data_final: Yup.date().when("data_inicial", {
                    is: (val) => !!val,
                    then: Yup.date()
                        .min(values.data_inicial || new Date(), "O campo 'data final' não pode ser anterior a inicial")
                        .required("O campo 'data final' é obrigatório")
                        .typeError("Informe uma 'data final' válida"),
                }),
                custo: Yup.number().required("O campo 'custo' é obrigatório.").typeError("Informe um 'custo' válido"),
                valor: Yup.number().required("O campo 'valor' é obrigatório.").typeError("Informe um 'valor' válido"),
                valor_nfs: Yup.number()
                    .required("O campo 'valor nfs' é obrigatório.")
                    .typeError("Informe um 'valor nfs' válido"),
                servico_contratado: Yup.object()
                    .shape({
                        id: Yup.number().required().required("O campo 'custo' é obrigatório."),
                    })
                    .typeError("Informe um 'custo' válido"),
            });
            await formSchema.validate(values, { abortEarly: false });
            const body = {
                ...values,
                servico_contratado:
                    values.servico_contratado instanceof Object
                        ? values.servico_contratado.id
                        : values.servico_contratado,
                data_inicial: dataToStr(values.data_inicial, "yyyy-MM-dd"),
                data_final: dataToStr(values.data_final, "yyyy-MM-dd"),
            };
            if (!values.id) {
                showLoading();
                const { status, data } = await axiosPost("/servicos/apontamento-servico/", body);
                hideLoading();
                if (status === 201) {
                    showSuccess({
                        summary: "Sucesso",
                        detail: "Apontamento cadastrado com sucesso!",
                        life: 1500,
                    });
                    onFinish();
                } else {
                    showError({
                        summary: "Erro :(",
                        detail: "Desculpe, não foi possível processar a sua requisição.",
                        life: 3000,
                    });
                }
            } else {
                showLoading();
                const { status, data } = await axiosPatch(`/servicos/apontamento-servico/${values.id}/`, body);
                hideLoading();
                if (status === 200) {
                    showSuccess({
                        summary: "Sucesso",
                        detail: "Apontamento alterado com sucesso!",
                        life: 1500,
                    });
                    onFinish();
                } else {
                    showError({
                        summary: "Erro :(",
                        detail: "Desculpe, não foi possível processar a sua requisição.",
                        life: 3000,
                    });
                }
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};

                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });

                formik.setErrors(errorMessages);
            }
        }
    }

    const onChangeValorServico = (e) => {
        setFieldValue("valor_nfs", e.value);
        formik.handleChange(e);
    };

    useEffect(() => {
        if (apontamento) setValues({ ...apontamento });
    }, [apontamento, setValues]);

    return (
        <>
            <form onSubmit={formik.handleSubmit}>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-9">
                        <label htmlFor="servico_contratado">Identificação do serviço *</label>
                        <MakoAutoComplete
                            id="servico_contratado"
                            name="servico_contratado"
                            minCaracteresBusca={3}
                            field="label"
                            placeholder="Comece a digitar para buscar... (min 3 caracteres)"
                            urlSearch={`/servicos/servicos-contrato/?contrato=${contrato.id}`}
                            value={formik.values.servico_contratado}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.servico_contratado })}
                            disabled
                        />
                        {formik.errors.servico_contratado && (
                            <small className="p-error">{formik.errors.servico_contratado}</small>
                        )}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="tipo">Tipo</label>
                        <Dropdown
                            id="tipo"
                            name="tipo"
                            options={TIPOS_CHOICE_SERVICOS_SERVICO}
                            optionValue="value"
                            optionLabel="label"
                            disabled
                            value={formik.values.tipo}
                        />
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="data_inicial">Data Inicial *</label>
                        <MakoCalendar
                            id="data_inicial"
                            name="data_inicial"
                            valueCalendar={formik.values.data_inicial}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.data_inicial })}
                        />
                        {formik.errors.data_inicial && <small className="p-error">{formik.errors.data_inicial}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="data_final">Data final *</label>
                        <MakoCalendar
                            id="data_final"
                            name="data_final"
                            valueCalendar={formik.values.data_final}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.data_final })}
                        />
                        {formik.errors.data_final && <small className="p-error">{formik.errors.data_final}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="total_horas">Número de horas</label>
                        <InputNumber
                            id="total_horas"
                            name="total_horas"
                            value={formik.values.total_horas}
                            onValueChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.total_horas })}
                        />
                        {formik.errors.total_horas && <small className="p-error">{formik.errors.total_horas}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="custo">Custo do serviço *</label>
                        <MakoInputMoeda
                            id="custo"
                            name="custo"
                            onChangeMoeda={formik.handleChange}
                            valueMoeda={formik.values.custo}
                            className={classNames({ "p-invalid": formik.errors.custo })}
                        />
                        {formik.errors.custo && <small className="p-error">{formik.errors.custo}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="valor">Valor do serviço *</label>
                        <MakoInputMoeda
                            id="valor"
                            name="valor"
                            onChangeMoeda={onChangeValorServico}
                            valueMoeda={formik.values.valor}
                            className={classNames({ "p-invalid": formik.errors.valor })}
                        />
                        {formik.errors.valor && <small className="p-error">{formik.errors.valor}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="valor_nfs">Valor para NFSe *</label>
                        <MakoInputMoeda
                            id="valor_nfs"
                            name="valor_nfs"
                            onChangeMoeda={formik.handleChange}
                            valueMoeda={formik.values.valor_nfs}
                            className={classNames({ "p-invalid": formik.errors.valor_nfs })}
                        />
                        {formik.errors.valor_nfs && <small className="p-error">{formik.errors.valor_nfs}</small>}
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-12">
                        <label htmlFor="descricao">Detalhes do serviço *</label>
                        <InputTextarea
                            id="descricao"
                            name="descricao"
                            value={formik.values.descricao}
                            onChange={formik.handleChange}
                            rows={2}
                            autoResize
                            maxLength={255}
                            className={classNames({ "p-invalid": formik.errors.descricao })}
                        />
                        {formik.errors.descricao && <small className="p-error">{formik.errors.descricao}</small>}
                    </div>
                </div>
                <div className="p-grid p-col-12 p-md-6">
                    <Button icon="pi pi-save" label="Gravar" type="submit" className="p-button-info p-mr-2" />
                    <Button
                        icon="pi pi-trash"
                        label="Limpar"
                        type="button"
                        className="p-button-warning p-mr-2"
                        onClick={formik.resetForm}
                    />
                    <Button label="Cancelar" type="button" className="p-button-danger" onClick={onClose} />
                </div>
            </form>
        </>
    );
};
