import React, { forwardRef, useImperativeHandle, useState, useRef } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { Messages } from "primereact/messages";
import { axiosPost } from "@/services/http";
import { CodigoFiltroTemplate, TextoFiltroTemplate } from "@/components/MakoFiltrosCabecalho";
import MakoListagem from "@/components/MakoListagem";
import useToast from "@/hooks/useToast";
import useTransferencia from "@/hooks/useTransferencia";

const BASE_FILTROS_CABECALHO = {
    sku__codigo__unaccent__icontains: { value: null, matchMode: "unaccent__icontains" },
    sku__descricao_reduzida__unaccent__icontains: { value: null, matchMode: "unaccent__icontains" },
    venda: { value: null, matchMode: "exact" },
};

const Modal = ({ aposSalvar }, ref) => {
    const [visible, setVisible] = useState(false);
    const [itensSelecionados, setItensSelecionados] = useState(null);
    const msgRef = useRef(null);
    const listagemRef = useRef(null);
    const { showSuccess, showError, showWarning } = useToast();
    const { transferencia } = useTransferencia();

    async function handleSubmit() {
        try {
            const { status, data } = await axiosPost(
                `/transferencias/item-requisicao-transferencia/${transferencia.id}/`,
                { requisicoes: itensSelecionados }
            );

            if (status === 200) {
                showSuccess({
                    summary: "Sucesso!",
                    detail: `A requisição foi registrada na transferência com sucesso.`,
                    life: 1500,
                });
                if (data?.existentes?.length > 0)
                    showWarning({
                        summary: "Requisições já utilizadas",
                        detail: `Algumas das requisições inseridas já estão sendo utilizadas (${data.existentes.join(
                            ","
                        )}).`,
                        life: 4000,
                    });
            } else
                showError({
                    summary: "Erro",
                    detail: "Desculpe, não conseguimos processar a sua requisição.",
                    life: 1500,
                });

            fecharModal();
            if (typeof aposSalvar === "function") aposSalvar();
        } catch (error) {
            showError({
                summary: "Erro",
                detail: "Desculpe, não foi possível inserir os itens do orçamento",
                life: 3000,
            });
        }
    }

    const abrirModal = () => {
        setVisible(true);
    };

    const fecharModal = () => {
        setItensSelecionados([]);
        setVisible(false);
    };

    const footerDialog = () => {
        return (
            <div>
                <Button
                    label="Cancelar"
                    icon="pi pi-times"
                    onClick={fecharModal}
                    className="p-button-text p-button-danger"
                />
                <Button
                    label="Inserir"
                    className="p-button-success"
                    type="submit"
                    icon="pi pi-plus"
                    disabled={!itensSelecionados}
                    onClick={() => handleSubmit()}
                    autoFocus
                />
            </div>
        );
    };

    useImperativeHandle(ref, () => {
        return {
            abrirModal,
        };
    });

    const colunas = [
        { selectionMode: "multiple", style: { width: "5%" } },
        {
            field: "id",
            header: "Nº Req",
            style: { width: "6%" },
        },
        {
            field: "tipo_requisicao.descricao",
            header: "Tipo",
            style: { minWidth: "10%" },
        },
        {
            field: "sku.codigo",
            header: "Código",
            style: { width: "10%" },
            filter: true,
            filterField: "sku__codigo__unaccent__icontains",
            filterElement: CodigoFiltroTemplate,
        },
        {
            field: "sku.descricao_reduzida",
            header: "Descrição",
            filter: true,
            filterField: "sku__descricao_reduzida__unaccent__icontains",
            filterElement: TextoFiltroTemplate,
        },
        {
            field: "venda.id",
            header: "Nº venda",
            style: { width: "9%" },
            filter: true,
            filterField: "venda",
            filterElement: CodigoFiltroTemplate,
        },
        {
            field: "data_limite",
            header: "Data Limite",
            style: { width: "8%" },
            dateFormat: "dd/MM/yyyy",
            align: "center",
        },
        {
            field: "quantidade",
            header: "Qtd.",
            style: { width: "8%" },
            decimal: true,
            align: "center",
        },
    ];

    return (
        <Dialog
            visible={visible}
            header="Requisições de transferências"
            footer={footerDialog}
            style={{ width: "70vw" }}
            onHide={fecharModal}
        >
            <Messages ref={msgRef} />
            <form>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-12">
                        <label htmlFor="requisicoes">Selecione quais requisições serão registradas:</label>
                        <MakoListagem
                            ref={listagemRef}
                            colunas={colunas}
                            urlPesquisa={`/transferencias/requisicao-transferencia?ce_origem=${transferencia.ce_origem}&ce_destino=${transferencia.ce_destino}&status=P`}
                            configTabela={{
                                paginator: false,
                                lazy: true,
                                scrollable: true,
                                scrollHeight: "300px",
                                frozenWidth: "0.5vw",
                                selectionMode: "single",
                                selection: itensSelecionados,
                                onSelectionChange: (e) => setItensSelecionados(e.value),
                            }}
                            filtros={BASE_FILTROS_CABECALHO}
                        />
                    </div>
                </div>
            </form>
        </Dialog>
    );
};

export const RequisicaoTransferenciaModal = forwardRef(Modal);
