import React, { useEffect, useState } from "react";
import { TabView, TabPanel } from "primereact/tabview";
import { Button } from "primereact/button";
import { useHistory } from "react-router-dom";
import { TrocaProvider } from "@/contexts/trocaContext";
import { TrocaDadosBasicosForm } from "./formDadosBasicos";
import { ItensSaidaForm } from "./FormItensSaida";
import useTroca from "@/hooks/useTroca";
import useLoading from "@/hooks/useLoading";
import { DocumentosTrocaForm } from "./formDocFiscal";
import { PageBase } from "@/components/PageBase";
import useToast from "@/hooks/useToast";
import { ItensTrocaForm } from "./FormItensTroca";

const TabTrocas = (props) => {
    const [activeTab, setActiveTab] = useState(0);
    const history = useHistory();
    const { handleTroca, handlePreencherTroca, dadosBasicos } = useTroca();
    const { showLoading, hideLoading } = useLoading();
    const { showError, showSuccess } = useToast();

    async function carregaTroca(id) {
        try {
            showLoading();
            await handlePreencherTroca(id);
            hideLoading();
        } catch (error) {
            showError({
                summary: "Erro",
                detail: "Desculpe, não foi possível carregar as informações do crediário.",
                life: 2000,
            });
        }
    }

    useEffect(() => {
        if (props.props?.location.state?.troca?.id && !dadosBasicos) {
            carregaTroca(props.props?.location.state?.troca?.id);
        }
    }, []);

    async function persistirDados() {
        showLoading();
        const resposta = await handleTroca();
        hideLoading();

        if (resposta.status === 201 || resposta.status === 200) {
            showSuccess({
                summary: "Sucesso!",
                detail: "Troca salva com sucesso.",
                life: 3000,
            });
            setTimeout(() => {
                history.push("/vendas/venda/trocas");
            }, [500]);
        } else {
            showError({
                summary: "Erro",
                detail: "Desculpe, não conseguimos processar a sua requisição.",
                life: 2000,
            });
        }
    }

    const alterarTab = (aba) => {
        if (aba === -1) {
            if (activeTab > 0) {
                setActiveTab(activeTab - 1);
            }
        } else {
            if (activeTab < 6) {
                setActiveTab(activeTab + 1);
            }
        }
    };

    const cancelar = () => {
        history.push("/vendas/venda/trocas");
    };

    return (
        <PageBase>
            <h5>Cadastro de Trocas</h5>
            <TabView activeIndex={activeTab} onTabChange={(e) => setActiveTab(e.index)}>
                <TabPanel header="Dados Básicos" leftIcon="pi pi-save">
                    <TrocaDadosBasicosForm />
                </TabPanel>
                <TabPanel header="Itens Devolvidos" leftIcon="pi pi-arrow-left" disabled={!dadosBasicos}>
                    <ItensTrocaForm />
                </TabPanel>
                <TabPanel header="Itens Saída" leftIcon="pi pi-reply" disabled={!dadosBasicos}>
                    <ItensSaidaForm />
                </TabPanel>
                <TabPanel header="Documentos Fiscais" leftIcon="pi pi-book" disabled={!dadosBasicos}>
                    <DocumentosTrocaForm />
                </TabPanel>
            </TabView>
            <div className="p-grid p-fluid p-justify-start">
                {activeTab > 0 ? (
                    <div className="p-col-6 p-md-2">
                        <Button
                            label="Anterior"
                            icon="pi pi-angle-left"
                            className="p-button-info"
                            onClick={() => alterarTab(-1)}
                        />
                    </div>
                ) : null}
                {activeTab < 2 ? (
                    <div className="p-col-6 p-md-2">
                        <Button
                            label="Próximo"
                            icon="pi pi-angle-right"
                            iconPos="right"
                            className="p-button-info"
                            onClick={() => alterarTab(1)}
                        />
                    </div>
                ) : null}
                <div className="p-col-6 p-md-2">
                    <Button label="Voltar" className="p-button-danger" onClick={cancelar} />
                </div>
            </div>
        </PageBase>
    );
};

export const TabTrocaContext = (props) => {
    return (
        <TrocaProvider>
            <TabTrocas props={props} />
        </TrocaProvider>
    );
};
