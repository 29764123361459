import React, { forwardRef, useCallback, useImperativeHandle, useState } from "react";

import { Dialog } from "primereact/dialog";

import { Forms } from "../../../features/Pendencias/forms";

const { ReceberCrediario: FormReceberCrediario } = Forms;

const Componente = ({ onHide = () => {} }, ref) => {
    const [visible, setVisible] = useState(false);
    const [pendencia, setPendencia] = useState(null);

    const show = useCallback(({ pendencia }) => {
        setPendencia(pendencia);
        setVisible(true);
    }, []);

    const hide = useCallback(() => {
        setVisible(false);
        onHide();
    }, [onHide]);

    useImperativeHandle(ref, () => ({ show, hide }), [show, hide]);

    return (
        <Dialog
            header="Recebendo parcelas de crediário"
            visible={visible}
            onHide={hide}
            style={{ width: "70vw" }}
            breakpoints={{ "960px": "85vw" }}
        >
            <FormReceberCrediario pendencia={pendencia} />
        </Dialog>
    );
};

export const ReceberCrediario = forwardRef(Componente);
