import React, { useCallback, useState } from "react";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { SelectButton } from "primereact/selectbutton";
import { axiosGet, axiosPost, axiosPut } from "@/services/http";
import { PickList } from "primereact/picklist";
import { useFormik } from "formik";
import useLoading from "@/hooks/useLoading";
import { MakoDropdownCategoriasHierarquicas } from "@/components/MakoDropdownCategoriasHierarquicas";
import { MakoBuscaSkuPersonalizada } from "@/components/MakoBuscaSkuPersonalizada";
import { Dropdown } from "@/components/Dropdown";
import { SIM_NAO_BOOLEAN } from "@/assets/constants/constants";
import MakoListagem from "@/components/MakoListagem";
import { InputNumber } from "primereact/inputnumber";
import classNames from "classnames";
import * as Yup from "yup";

const OPTIONS_SIM_NAO_BOOLEAN = SIM_NAO_BOOLEAN.map((item) => ({
    id: item.id,
    label: item.label === "Sim" ? "Apenas produtos fora da tabela atual" : "Todos os produtos",
}));

export const ItemTabelaModalForm = ({
    tabela,
    acrescimo,
    toastRef,
    listagemRef,
    setExibirDialog,
    exibirDialog = false,
    vigencia,
}) => {
    const [produtos, setProdutos] = useState([]);
    const [opcaoExibicao, setOpcaoExibicao] = useState("");
    const [produtosSelecionados, setProdutosSelecionados] = useState([]);
    const [buscarNaoCadastrados, setBuscarNaoCadastrados] = useState(null);
    const [errorsProdutos, setErrosProdutos] = useState({ preco: [], repetidos: [] });
    const [visible, setVisible] = useState(false);

    const { showLoading, hideLoading } = useLoading();

    const opcoesPesquisa = ["Categoria", "Pesquisa avançada", "Todos"];

    const { setValues, setFieldValue, ...formik } = useFormik({
        initialValues: {
            categoria_selecionada: null,
            categoria: null,
            meses_encargos: 0,
            isento_encargos: false,
            sku: null,
        },
        onSubmit: handleSubmit,
    });

    async function arredondaValores() {
        let lista = [];
        produtosSelecionados.forEach((item) => {
            lista.push(
                item.precosku_set?.length > 0 ? parseFloat(item.precosku_set[0].preco_base) * (acrescimo / 100 + 1) : 0
            );
        });
        const resposta = await axiosPut("/vendas/arredondar-precos/", {
            precos: lista,
        });
        return resposta.status === 200 ? lista : [];
    }

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                meses_encargos: Yup.number().required("O campo é obrigatório.").typeError("O campo deve ser informado"),
                isento_encargos: Yup.boolean()
                    .required("O campo é obrigatório.")
                    .typeError("O campo deve ser informado"),
            });

            await formSchema.validate(values, {
                abortEarly: false,
            });

            const valorArredondado = await arredondaValores();

            if (valorArredondado.length > 0) {
                const itens = produtosSelecionados.map((item, index) => {
                    return {
                        sku: item.sku?.id || item.id,
                    };
                });

                showLoading();
                const resposta = await axiosPost(`/vendas/incluir-itens-tabela/${tabela}/`, {
                    vigencia_preco: vigencia,
                    lista_itens: itens,
                    meses_encargos: values.meses_encargos,
                    isento_encargos: values.isento_encargos,
                });
                hideLoading();
                if (resposta.status === 200) {
                    toastRef.current.show({
                        severity: "success",
                        summary: "Sucesso",
                        detail: "Alteração gravada com sucesso!",
                        life: 1500,
                    });
                    setProdutosSelecionados([]);
                    listagemRef.current?.buscarDados();
                    if (resposta.data.erros_existencia.length > 0 || resposta.data.erros_preco.length > 0) {
                        let { erros_existencia = [], erros_preco = [] } = resposta.data;
                        erros_existencia = [...new Set(erros_existencia)];
                        erros_preco = [...new Set(erros_preco)];
                        setErrosProdutos((prev) => {
                            prev.preco = [];
                            prev.repetidos = [];
                            erros_preco.forEach((e) =>
                                prev.preco.push(produtosSelecionados.find(({ id }) => id === e))
                            );
                            erros_existencia.forEach((e) =>
                                prev.repetidos.push(produtosSelecionados.find(({ id }) => id === e))
                            );
                            return prev;
                        });
                        setVisible(true);
                    } else {
                        setExibirDialog(false);
                    }
                } else {
                    toastRef.current.show({
                        severity: "error",
                        summary: "Erro",
                        detail: "Desculpe, não conseguimos processar a sua requisição.",
                        life: 1500,
                    });
                }
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
                hideLoading();
            } else {
                toastRef.current.show({
                    severity: "error",
                    summary: "Erro",
                    detail: "Desculpe, não conseguimos processar a sua requisição.",
                    life: 1500,
                });
                hideLoading();
            }
        }
    }

    const selecionarPesquisa = (opcao = "") => {
        setOpcaoExibicao(opcao);
        if (opcao === "Todos") {
            setFieldValue("sku", null);
            listarProdutos();
        } else if (opcao === "Categoria") setProdutos([]);
        else if (opcao === "Pesquisa avançada") setProdutos([]);
        else setProdutosSelecionados([]);
    };

    const listarProdutos = useCallback(
        async (buscarNaoCadastrados = false) => {
            showLoading();
            let resposta;
            if (buscarNaoCadastrados === false) {
                resposta = await axiosGet(
                    "/produtos/sku?query={id,descricao_reduzida,codigo}&limit=1000000&ordering=-codigo"
                );
            } else {
                resposta = await axiosGet(
                    "/produtos/sku?query={id,descricao_reduzida,codigo}&limit=1000000&ordering=-codigo&precostabelapreco__sku__isnull=true"
                );
            }
            hideLoading();
            if (resposta.status === 200) setProdutos(resposta.data.results);
        },
        [showLoading, hideLoading]
    );

    const esconderDialogProdutos = () => {
        setProdutosSelecionados([]);
        setProdutos([]);
        setOpcaoExibicao("");
        setFieldValue("sku", null);
        setExibirDialog(false);
    };

    const insereProduto = () => {
        setProdutosSelecionados([...produtosSelecionados, formik.values.sku]);
        setFieldValue("sku", null);
    };

    const selecionaCategoria = async (categoria) => {
        setFieldValue("categoria", categoria);
        if (categoria) {
            showLoading();
            const resposta = await axiosGet(`/produtos/categorias-skus?categoria__id=${categoria}&limit=10000`);
            hideLoading();
            if (resposta.status === 200) {
                let lista = resposta.data.results;
                lista.forEach((item) => {
                    item.descricao_reduzida = item.sku.descricao_reduzida;
                    item.codigo = item.sku.codigo;
                });
                setProdutos(lista);
            }
        } else setProdutos([]);
    };

    const onChangeCategoria = useCallback(
        (e) => {
            setFieldValue("categoria", e);
        },
        [setFieldValue]
    );

    return (
        <>
            <Dialog
                header={"Adicionar produtos a tabela"}
                visible={exibirDialog}
                onHide={() => esconderDialogProdutos()}
                breakpoints={{ "960px": "75vw" }}
                style={{ width: "65vw" }}
            >
                <form onSubmit={formik.handleSubmit}>
                    <div className="p-grid p-fluid p-justify-center">
                        <div className="p-col-12 p-md-12">
                            <SelectButton
                                value={opcaoExibicao}
                                options={opcoesPesquisa}
                                onChange={(e) => selecionarPesquisa(e.value)}
                            />
                        </div>
                    </div>
                    <div className="p-fluid p-formgrid p-grid">
                        {opcaoExibicao === "Pesquisa avançada" ? (
                            <div className="p-field p-col-12 p-md-12">
                                <MakoBuscaSkuPersonalizada
                                    skuValue={formik.values.sku}
                                    skuChange={(e) => setFieldValue("sku", e)}
                                    skuError={formik.errors.sku}
                                />
                            </div>
                        ) : null}

                        {opcaoExibicao === "Categoria" ? (
                            <div className="p-field p-col-12 p-md-12">
                                <label htmlFor="categoria">Selecione uma categoria *</label>
                                <MakoDropdownCategoriasHierarquicas
                                    id="categoria"
                                    name="categoria"
                                    value={formik.values.categoria_selecionada}
                                    getCategoriaCompleta={onChangeCategoria}
                                    showClear
                                    onChange={(e) => {
                                        setFieldValue("categoria_selecionada", e.value);
                                        selecionaCategoria(e.value);
                                    }}
                                />
                            </div>
                        ) : null}
                    </div>
                    {opcaoExibicao === "Pesquisa avançada" ? (
                        <div className="p-grid p-justify-end p-mb-3 p-mr-2">
                            <Button
                                type="button"
                                icon="pi pi-plus"
                                label="Incluir"
                                className="p-button-success"
                                disabled={!formik.values.sku?.id}
                                onClick={() => insereProduto()}
                            />
                        </div>
                    ) : null}

                    {opcaoExibicao === "Todos" ? (
                        <Dropdown
                            options={OPTIONS_SIM_NAO_BOOLEAN}
                            placeholder="Critério para buscar todos"
                            value={buscarNaoCadastrados}
                            onChange={(e) => {
                                setBuscarNaoCadastrados(e.target.value);
                                listarProdutos(e.target.value);
                            }}
                            optionLabel="label"
                            optionValue="id"
                            className="p-mb-3"
                            style={{ width: "100%" }}
                        />
                    ) : null}
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-12 p-md-12">
                            <PickList
                                source={produtos}
                                target={produtosSelecionados}
                                sourceHeader="Produtos"
                                targetHeader="Selecionados"
                                showSourceControls={false}
                                showTargetControls={false}
                                itemTemplate={(item) => (
                                    <div>
                                        {item.codigo} - {item.descricao_reduzida}
                                    </div>
                                )}
                                onChange={(e) => {
                                    setProdutos(e.source);
                                    setProdutosSelecionados(e.target);
                                }}
                                sourceStyle={{ height: "200px" }}
                                targetStyle={{ height: "200px" }}
                            ></PickList>
                        </div>
                    </div>
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-12 p-md-3">
                            <label htmlFor="meses_encargos">Meses de encargos incluídos</label>
                            <InputNumber
                                id="meses_encargos"
                                name="meses_encargos"
                                min={0}
                                minFractionDigits={0}
                                maxFractionDigits={0}
                                value={formik.values.meses_encargos}
                                onValueChange={formik.handleChange}
                                className={classNames({ "p-invalid": formik.errors.meses_encargos })}
                            />
                            {formik.errors.meses_encargos && (
                                <small className="p-error">{formik.errors.meses_encargos}</small>
                            )}
                        </div>
                        <div className="p-field p-col-12 p-md-3">
                            <label htmlFor="isento_encargos">Isento de encargos</label>
                            <SelectButton
                                id="isento_encargos"
                                name="isento_encargos"
                                options={SIM_NAO_BOOLEAN}
                                optionValue="id"
                                optionLabel="label"
                                value={formik.values.isento_encargos}
                                onChange={formik.handleChange}
                                className={classNames({ "p-invalid": formik.errors.isento_encargos })}
                            />
                            {formik.errors.isento_encargos && (
                                <small className="p-error">{formik.errors.isento_encargos}</small>
                            )}
                        </div>
                        <div className="p-field p-col-12 p-md-3">
                            <Button
                                type="submit"
                                label="Adicionar produtos"
                                className="p-mt-5"
                                disabled={produtosSelecionados.length > 0 ? false : true}
                            />
                        </div>
                    </div>
                </form>
            </Dialog>
            <Dialog
                header={"Erros ao inserir produtos"}
                visible={visible}
                onHide={() => setVisible(false)}
                breakpoints={{ "960px": "75vw" }}
                style={{ width: "60vw" }}
            >
                <>
                    {errorsProdutos.preco.length > 0 && (
                        <div className="p-my-1">
                            <MakoListagem
                                titulo={"Produtos com preço não encontrado"}
                                colunas={[
                                    { field: "codigo", header: "Codigo", style: { width: "8%" } },
                                    { field: "descricao_reduzida", header: "Descrição" },
                                ]}
                                dadosLocal={errorsProdutos.preco}
                            />
                        </div>
                    )}
                    {errorsProdutos.repetidos.length > 0 && (
                        <div className="p-my-1">
                            <MakoListagem
                                titulo={"Produtos já existentes na tabela"}
                                colunas={[
                                    { field: "codigo", header: "Codigo", style: { width: "8%" } },
                                    { field: "descricao_reduzida", header: "Descrição" },
                                ]}
                                dadosLocal={errorsProdutos.repetidos}
                            />
                        </div>
                    )}
                </>
            </Dialog>
        </>
    );
};
