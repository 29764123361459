import React, { useEffect, useRef } from "react";
import { Toast } from "primereact/toast";
import { useFormik } from "formik";
import { InputText } from "primereact/inputtext";
import { PageBase } from "@/components/PageBase";
import { MakoInputMoeda } from "@/components/MakoInputMoeda";
import { FormikAutoSave } from "@/components/FormikAutoSave";
import useNotaFiscal from "@/hooks/useNotaFiscal";
import classNames from "classnames";
import * as Yup from "yup";
import { DuplicataCobrancaForm } from "./formDuplicatas";
import { Divider } from "primereact/divider";
import { FormaPagamentoCobrancaForm } from "./formFormasPagamento";

export const CobrancaForm = () => {
    const { dadosBasicos, handleDadosBasicos } = useNotaFiscal();
    const toastRef = useRef(null);

    const { setFieldValue, setValues, ...formik } = useFormik({
        initialValues: {
            numero_fatura: 1,
            fatura_original: 0,
            desconto_cobranca: 0,
            fatura_liquida: 0,
        },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                desconto_cobranca: Yup.number().required("O campo é obrigatório!"),
            });

            await formSchema.validate(values, {
                abortEarly: false,
            });

            const fatura_liquida = parseFloat(values.fatura_original) - parseFloat(values.desconto_cobranca);
            const { status } = await handleDadosBasicos({
                ...values,
                fatura_liquida:
                    fatura_liquida !== parseFloat(values.fatura_liquida) ? fatura_liquida : values.fatura_liquida,
            });

            if (status !== 200)
                toastRef.current.show({
                    severity: "error",
                    summary: "Erro",
                    detail: "Desculpe, não foi possível salvar as informações da nota fiscal.",
                    life: 1500,
                });
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            }
        }
    }

    useEffect(() => {
        if (dadosBasicos?.id)
            setValues({
                id: dadosBasicos.id,
                numero_fatura: dadosBasicos.numero_fatura || 1,
                fatura_original: dadosBasicos.fatura_original,
                desconto_cobranca: dadosBasicos.desconto_cobranca,
                fatura_liquida: dadosBasicos.fatura_liquida,
            });
    }, [dadosBasicos, setValues]);

    return (
        <PageBase>
            <Toast ref={toastRef} />
            <form onSubmit={formik.handleSubmit}>
                <div className="p-fluid p-formgrid p-grid p-mb-2">
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="numero_fatura">Número fatura *</label>
                        <InputText
                            id="numero_fatura"
                            name="numero_fatura"
                            onChange={formik.handleChange}
                            value={formik.values.numero_fatura}
                            disabled
                            keyfilter={/^[0-9]/}
                            autoComplete="off"
                            className={classNames({ "p-invalid": formik.errors.numero_fatura })}
                        />
                        {formik.errors.numero_fatura && (
                            <small className="p-error">{formik.errors.numero_fatura}</small>
                        )}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="fatura_original">Valor original da fatura *</label>
                        <MakoInputMoeda
                            id="fatura_original"
                            name="fatura_original"
                            min={0}
                            disabled
                            value={formik.values.fatura_original}
                            onValueChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.fatura_original })}
                        />
                        {formik.errors.fatura_original && (
                            <small className="p-error">{formik.errors.fatura_original}</small>
                        )}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="desconto_cobranca">Desconto cobrança *</label>
                        <MakoInputMoeda
                            id="desconto_cobranca"
                            name="desconto_cobranca"
                            min={0}
                            value={formik.values.desconto_cobranca}
                            onValueChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.desconto_cobranca })}
                        />
                        {formik.errors.desconto_cobranca && (
                            <small className="p-error">{formik.errors.desconto_cobranca}</small>
                        )}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="fatura_liquida">Fatura líquida *</label>
                        <MakoInputMoeda
                            id="fatura_liquida"
                            name="fatura_liquida"
                            min={0}
                            value={formik.values.fatura_original - formik.values.desconto_cobranca}
                            disabled
                            onValueChange={formik.handleChange}
                        />
                    </div>
                </div>
            </form>
            <Divider align="center">
                <b>Dados do pagamento</b>
            </Divider>
            <br />
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12 p-md-6">
                    <DuplicataCobrancaForm />
                </div>
                <div className="p-field p-col-12 p-md-6">
                    <FormaPagamentoCobrancaForm />
                </div>
            </div>
            <FormikAutoSave intervalo={500} autosave={typeof dadosBasicos?.id === "number"} formik={formik} />
        </PageBase>
    );
};
