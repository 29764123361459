import React, { useRef } from "react";
import { useHistory } from "react-router-dom";
import { Button } from "primereact/button";

import { BotaoDelete } from "@/components/BotaoDelete";
import MakoListagem from "@/components/MakoListagem";
import useClearRefs from "@/hooks/useClearRefs";
import useEmpresa from "@/hooks/useEmpresa";
import {
    CFOPFiltroTemplate,
    CategoriaFiltroTemplate,
    DropdownFiltroTemplate,
    EstoqueFiltroTemplate,
    TextoFiltroTemplate,
} from "@/components/MakoFiltrosCabecalho";
import { TIPO_CHAVE_DESTINACAO_SKU_CHOICE } from "@/assets/constants/constants";

export const DestinacaoEntradaPage = () => {
    const { empresaSelecionadaId } = useEmpresa();

    const listagemRef = useRef(null);
    const history = useHistory();

    useClearRefs(listagemRef);

    const painelEsquerdoTabela = (
        <>
            <Button
                label="Novo"
                icon="pi pi-plus"
                className="p-button-success p-mr-2 p-mb-2"
                disabled={!empresaSelecionadaId}
                onClick={() => history.push("/compras/cadastros/destinacoes/form")}
            />
        </>
    );

    const confirmarDelete = () => {
        listagemRef.current?.buscarDados();
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button
                    icon="pi pi-pencil"
                    className="p-button-rounded p-button-warning p-mr-2"
                    onClick={() =>
                        history.push({
                            pathname: "/compras/cadastros/destinacoes/form",
                            state: rowData,
                        })
                    }
                />
                <BotaoDelete
                    url="/compras/destinacoes-entradas/"
                    objetoId={rowData.id}
                    exigeConfirmacao
                    msgConfirmacao={
                        <span>
                            Deseja mesmo excluir a destinação <b>{rowData.nome}</b>?
                        </span>
                    }
                    msgToastErroExclusao="A destinação não pode ser excluído."
                    onDelete={confirmarDelete}
                    tooltip="Excluir endereço"
                    tooltipOptions={{ position: "bottom" }}
                />
            </div>
        );
    };

    const ChaveFiltroTemplate = (options) => {
        return (
            <DropdownFiltroTemplate
                dropdownProps={{
                    optionValue: "value",
                    optionLabel: "label",
                    options: TIPO_CHAVE_DESTINACAO_SKU_CHOICE,
                }}
                options={options}
            />
        );
    };

    const EstoquesFiltroTemplate = (options) => {
        return <EstoqueFiltroTemplate options={options} />;
    };

    const colunas = [
        {
            field: "nome",
            header: "Nome",
            style: { minWidth: "250px" },
            filter: true,
            filterElement: TextoFiltroTemplate,
        },
        {
            field: "descricao",
            header: "Descrição",
            style: { minWidth: "350px" },
            filter: true,
            filterElement: TextoFiltroTemplate,
        },
        {
            field: "chave.descricao",
            header: "Chave",
            style: { minWidth: "150px" },
            filter: true,
            filterElement: ChaveFiltroTemplate,
            filterField: "chave",
        },
        {
            field: "centro_estocagem_destino.nome",
            header: "Centro de estocagem",
            style: { minWidth: "250px" },
            filter: true,
            filterElement: EstoquesFiltroTemplate,
            filterField: "centro_estocagem_destino",
        },
        {
            field: "categoria.nome",
            header: "Categoria",
            style: { minWidth: "100px" },
            filter: true,
            filterElement: CategoriaFiltroTemplate,
            filterField: "categoria",
        },
        {
            field: "cfop_interno.codigo",
            header: "CFOP interno",
            style: { minWidth: "150px" },
            filter: true,
            filterElement: CFOPFiltroTemplate,
            filterField: "cfop_interno",
        },
        {
            field: "cfop_interestadual.codigo",
            header: "CFOP interestadual",
            style: { minWidth: "180px" },
            filter: true,
            filterElement: CFOPFiltroTemplate,
            filterField: "cfop_interestadual",
        },
        {
            field: "actions",
            header: "Ações",
            frozen: true,
            alignFrozen: "right",
            style: { minWidth: "100px" },
            action: (e) => actionBodyTemplate(e),
        },
    ];

    return (
        <div className="p-grid">
            <div className="p-col-12">
                <div className="card">
                    <MakoListagem
                        ref={listagemRef}
                        titulo="Destinações de Entrada"
                        colunas={colunas}
                        urlPesquisa="/compras/destinacoes-entradas/"
                        painelEsquerdo={painelEsquerdoTabela}
                        naoBuscarSemEmpresa
                        filtarPorEmpresa
                        configTabela={{
                            lazy: true,
                            paginator: true,
                            scrollable: true,
                        }}
                        filtros={{
                            nome: {
                                operator: "and",
                                constraints: [{ value: "", matchMode: "unaccent_icontains" }],
                            },
                            descricao: {
                                operator: "and",
                                constraints: [{ value: "", matchMode: "unaccent_icontains" }],
                            },
                            chave: { value: null, matchMode: "equals" },
                            centro_estocagem_destino: { value: null, matchMode: "equals" },
                            categoria: { value: null, matchMode: "equals" },
                            cfop_interno: { value: null, matchMode: "equals" },
                            cfop_interestadual: { value: null, matchMode: "equals" },
                        }}
                    />
                </div>
            </div>
        </div>
    );
};
