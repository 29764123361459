import React, { useRef, useState } from "react";
import { Menu } from "primereact/menu";
import { Tooltip } from "primereact/tooltip";
import { Button } from "primereact/button";

import { ModalAlteraComissionado, ModalAlterarComissao } from "./modals";
import { PageBase } from "@/components/PageBase";
import MakoListagem from "@/components/MakoListagem";
import { Delete } from "@/components/Delete";
import permissoes from "@/assets/constants/permissoes";
import { parseNumber } from "@/assets/helpers/number";
import useAuth from "@/hooks/useAuth";

const URL_API = "/vendas/bases-comissoes-vendas/";

export const AjusteComissaoPage = () => {
    const [baseComissaoSelecionada, setBaseComissaoSelecionada] = useState(null);
    const listagemRef = useRef(null);
    const menuRef = useRef();
    const modalAlterarComissionadoRef = useRef(null);
    const modalAlterarComissaoRef = useRef(null);
    const deleteRef = useRef(null);
    const { verifyPermission } = useAuth();

    const itensMenu = [
        {
            label: "Alterar comissionado",
            disabled: !verifyPermission(permissoes.VENDAS_COMISSAO_AJUSTE_TROCARCOMISSIONADO),
            command: () => modalAlterarComissionadoRef.current?.abrirModal(baseComissaoSelecionada),
        },
        {
            label: "Alterar valor",
            disabled: !verifyPermission(permissoes.VENDAS_COMISSAO_AJUSTE_EDITARVALOR),
            command: () => modalAlterarComissaoRef.current?.abrirModal(baseComissaoSelecionada),
        },
        {
            label: "Excluir comissão",
            disabled: !verifyPermission(permissoes.VENDAS_COMISSAO_AJUSTE_EXCLUIR),
            command: () => deleteRef.current?.handleClick(),
        },
    ];

    const itemVendaBodyTemplate = (rowData) => {
        const { sku } = rowData.item_venda;
        return (
            <>
                <Tooltip target={`.custom-target${rowData.id}`} content={sku.descricao_derivada} position="top" />
                <span className={`custom-target${rowData.id}`}>{sku.codigo}</span>
            </>
        );
    };

    const comissaoPagaBodyTemplate = (rowData) => {
        const { apuracao_comissao } = rowData;
        if (apuracao_comissao?.status === "P") return <span>Sim</span>;
        return <span>Não</span>;
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Menu model={itensMenu} popup ref={menuRef} />
                <Button icon="pi pi-eye" rounded disabled severity="help" className="p-mr-2 p-mb-1" />
                <Button
                    icon="pi pi-cog"
                    rounded
                    className="p-mr-2 p-mb-1"
                    onClick={(e) => {
                        setBaseComissaoSelecionada(rowData);
                        menuRef.current?.toggle(e);
                    }}
                />
            </div>
        );
    };

    const colunas = [
        {
            field: "venda",
            header: "Venda",
            style: { width: "6%" },
            // filter: true,
            // filterElement: CodigoFiltroTemplate,
        },
        {
            field: "devolucao",
            header: "Devolução",
            style: { width: "6%" },
            // filter: true,
            // filterElement: CodigoFiltroTemplate,
            // filterField: "regra_apuracao__descricao",
            // style: { minWidth: "120px" },
        },
        {
            field: "data",
            header: "Data",
            dateFormat: "dd/MM/yyyy",
            // style: { minWidth: "70px" },
        },
        {
            field: "item_venda.sku.codigo",
            header: "Produto",
            style: { width: "10%" },
            action: itemVendaBodyTemplate,
            // style: { minWidth: "70px" },
        },
        {
            field: "comissionado.nome",
            header: "Comissionado (a)",
            // filter: true,
            // filterElement: DateFiltroTemplate,
            // style: { minWidth: "70px" },
            // dateFormat: "dd/MM/yyyy",
        },
        { field: "apuracao_comissao.regra_apuracao.descricao", header: "Regra" },
        {
            field: "base_comissao",
            header: "Base comissão",
            money: true,
            style: { width: "10%" },
            // filter: true,
            // filterElement: DateFiltroTemplate,
            // style: { minWidth: "70px" },
            // dateFormat: "dd/MM/yyyy",
        },
        {
            field: "total_comissao",
            header: "Valor",
            money: true,
            style: { width: "10%" },
            // filter: true,
            // filterElement: DateFiltroTemplate,
            // style: { minWidth: "70px" },
            // dateFormat: "dd/MM/yyyy",
        },
        {
            field: "esta_pago",
            header: "Pago",
            style: { width: "6%" },
            action: comissaoPagaBodyTemplate,
        },
        {
            field: "action",
            header: "Ações",
            style: { width: "10%" },
            action: (e) => actionBodyTemplate(e),
        },
    ];

    const cabecalhoTabela = (
        <>
            <Button icon="pi pi-filter" label="Filtrar" disabled />
        </>
    );

    const rowClass = (rowData) => {
        const { apuracao_comissao } = rowData;
        const paga = apuracao_comissao?.status === "P";
        return {
            "table-recebimentos-overdue": parseNumber(rowData.total_comissao) < 0,
            "table-recebimentos-pending": paga,
        };
    };

    return (
        <PageBase>
            <MakoListagem
                ref={listagemRef}
                urlPesquisa={URL_API}
                titulo="Ajustes de comissão"
                painelDireito={cabecalhoTabela}
                colunas={colunas}
                configTabela={{
                    lazy: true,
                    paginator: true,
                    rowClassName: rowClass,
                }}
            />
            <ModalAlteraComissionado
                ref={modalAlterarComissionadoRef}
                onFechar={() => listagemRef.current?.buscarDados()}
            />
            <ModalAlterarComissao ref={modalAlterarComissaoRef} onFechar={() => listagemRef.current?.buscarDados()} />
            <Delete
                ref={deleteRef}
                url={URL_API}
                objetoId={baseComissaoSelecionada?.id}
                exigeConfirmacao
                msgConfirmacao="Confirma a exclusão da comissão?"
                onDelete={() => listagemRef.current?.buscarDados()}
            />
        </PageBase>
    );
};
