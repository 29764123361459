import React, { useCallback, useState, useEffect, useMemo } from "react";

import { useFormik } from "formik";
import classNames from "classnames";
import * as Yup from "yup";

import { InputTextarea } from "primereact/inputtextarea";
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";
import { Button } from "primereact/button";

import { MakoBuscaSkuPersonalizada } from "@/components/MakoBuscaSkuPersonalizada";
import { MakoInputFornecedor } from "@/components/MakoInputs/MakoInputFornecedor";
import { MakoInputCliente } from "@/components/MakoInputs/MakoInputCliente";
import { MakoInputPerfil } from "@/components/MakoInputs/MakoInputPerfil";
import { MakoInputMoeda } from "@/components/MakoInputMoeda";
import { MakoCalendar } from "@/components/MakoCalendar";
import { Dropdown } from "@/components/Dropdown";

import { axiosGet, axiosPatch, axiosPost } from "@/services/http";

import {
    COMPRAS_REGISTRONECESSIDADECOMPRA_CADASTRO_PRECOVENDA,
    COMPRAS_REGISTRONECESSIDADECOMPRA_VINCULO_SKU,
} from "@/assets/constants/parametros";
import { dataToStr } from "@/assets/util/datas";

import useLoading from "@/hooks/useLoading";
import useToast from "@/hooks/useToast";
import useParam from "@/hooks/useParam";

export const RegistroNecessidadeCompraForm = ({ onSubmit, registro, onClose }) => {
    const [listaFormacoes, setListaFormacoes] = useState([]);

    const { hideLoading, showLoading } = useLoading();
    const { showSuccess, showError, showWarning } = useToast();
    const { getParam } = useParam();

    const { setValues, setFieldValue, ...formik } = useFormik({
        initialValues: {
            id: null,
            cliente: null,
            usuario: null,
            fornecedor: null,
            solicitante: "",
            sku: null,
            data: new Date(),
            hora: null,
            formacao_preco: null,
            custo: 0,
            preco_venda: 0,
            descritivo: "",
        },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                cliente: Yup.object()
                    .nullable()
                    .shape({
                        id: Yup.number(),
                    })
                    .typeError("Informe um 'cliente' válido"),
                usuario: Yup.object()
                    .shape({
                        id: Yup.number().required("O campo 'usuario' é obrigatório"),
                    })
                    .required("O campo 'usuario' é obrigatório")
                    .typeError("Informe um 'usuario' válido"),
                fornecedor: Yup.object()
                    .nullable()
                    .shape({
                        id: Yup.number(),
                    })
                    .typeError("Informe um 'fornecedor' válido"),
                solicitante: Yup.string()
                    .required("O campo 'solicitante' é obrigatório")
                    .test("Tamanho", "Quantidade máxima de caracteres atingida: 45", (value) => value?.length < 45)
                    .typeError("Informe um 'solicitante' válido"),
                sku: Yup.object()
                    .nullable()
                    .shape({
                        id: Yup.number(),
                    })
                    .typeError("Informe um 'produto' válido"),
                data: Yup.date().required("O campo 'data' é obrigatório").typeError("Informe uma 'data' válida"),
                hora: Yup.date().nullable().typeError("Seleciona uma 'hora' válida"),
                formacao_preco: Yup.object()
                    .nullable()
                    .shape({
                        id: Yup.number(),
                    })
                    .typeError("Informe uma 'formação de preço' válida"),
                custo: Yup.number().required("O campo 'custo' é obrigatório").typeError("Informe um 'custo' válido."),
                preco_venda: Yup.number()
                    .required("O campo 'preço venda' é obrigatório")
                    .typeError("Informe um 'preço de venda' válido."),
                descritivo: Yup.string()
                    .required("O campo 'descritivo' é obrigatório")
                    .test("Tamanho", "Quantidade máxima de caracteres atingida: 255", (value) => value?.length < 255)
                    .typeError("Informe um 'descritivo' válido"),
            });

            await formSchema.validate(values, {
                abortEarly: false,
            });

            values.data = dataToStr(values.data, "yyyy-MM-dd");
            values.usuario = values.usuario.id;

            if (values.hora instanceof Date) values.hora = dataToStr(values.hora, "HH:mm");
            if (values.cliente instanceof Object) values.cliente = values.cliente.id;
            if (values.fornecedor instanceof Object) values.fornecedor = values.fornecedor.id;
            if (values.sku instanceof Object) values.sku = values.sku.id;
            if (values.formacao_preco instanceof Object) values.formacao_preco = values.formacao_preco.id;

            if (registro instanceof Object && typeof values.id === "number") {
                showLoading();
                const response = await axiosPatch(`/compras/registro-necessidade-compra/${values.id}/`, values);
                hideLoading();

                if (response.status === 200) {
                    showSuccess({
                        summary: "Sucesso",
                        detail: "Necessidade alterada com sucesso!",
                        life: 3000,
                    });

                    if (typeof onSubmit === "function") onSubmit(true);
                } else {
                    throw new Error("Desculpe, não conseguimos processar sua requisição");
                }
            } else {
                showLoading();
                const response = await axiosPost("/compras/registro-necessidade-compra/", values);
                hideLoading();

                if (response.status === 201) {
                    showSuccess({
                        summary: "Sucesso",
                        detail: "Necessidade cadastrada com sucesso!",
                        life: 3000,
                    });

                    if (typeof onSubmit === "function") onSubmit();
                } else {
                    throw new Error("Desculpe, não conseguimos processar sua requisição");
                }
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};

                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });

                formik.setErrors(errorMessages);
            } else {
                const { message } = error;
                showError({
                    summary: "Erro, :(",
                    detail: message,
                    life: 3000,
                });
            }
        }
    }

    const listarFormacaoPreco = useCallback(async () => {
        showLoading();
        const resposta = await axiosGet("/produtos/formacoes-precos?limit=300");
        hideLoading();

        if (resposta.status === 200 || resposta.status === 201) {
            setListaFormacoes(resposta.data.results);
        }
    }, [showLoading, hideLoading, setListaFormacoes]);

    const onCancel = () => {
        formik.resetForm();
        onClose();
    };

    const parametroPreco = useMemo(() => {
        const parametro = getParam(COMPRAS_REGISTRONECESSIDADECOMPRA_CADASTRO_PRECOVENDA);
        if (parametro) {
            return parametro.valor;
        }
        return null;
    }, [getParam]);

    const parametroVinculoSku = useMemo(() => {
        const parametro = getParam(COMPRAS_REGISTRONECESSIDADECOMPRA_VINCULO_SKU);
        if (parametro) {
            return parametro.valor;
        }
        showWarning({
            summary: "Alerta !",
            detail: "Para poder vincular um produto no cadastro da necessidade, parameterize o parametro 'compras.registronecessidadecompra.vinculo.sku'!",
            life: 3000,
        });
        return null;
    }, [getParam, showWarning]);

    const handleChangeCusto = useCallback(
        (e) => {
            setFieldValue("preco_venda", e.value);
            formik.handleChange(e);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [formik.values.formacao_preco, setFieldValue, formik.handleChange]
    );

    useEffect(() => {
        listarFormacaoPreco();
    }, [listarFormacaoPreco]);

    useEffect(() => {
        if (registro instanceof Object && registro !== null) {
            setValues({
                ...registro,
            });
        }
    }, [registro, setValues]);

    return (
        <form onSubmit={formik.handleSubmit}>
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12 p-md-3">
                    <label htmlFor="cliente">Cliente:</label>
                    <MakoInputCliente
                        id="cliente"
                        name="cliente"
                        value={formik.values.cliente}
                        onChange={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.cliente })}
                    />
                    {formik.errors.cliente && <small className="p-error">{formik.errors.cliente}</small>}
                </div>
                <div className="p-field p-col-12 p-md-3">
                    <label htmlFor="solicitante">Solicitante: *</label>
                    <InputText
                        id="solicitante"
                        name="solicitante"
                        value={formik.values.solicitante}
                        onChange={formik.handleChange}
                        maxLength={45}
                        className={classNames({ "p-invalid": formik.errors.solicitante })}
                    />
                    {formik.errors.solicitante && <small className="p-error">{formik.errors.solicitante}</small>}
                </div>
                <div className="p-field p-col-12 p-md-3">
                    <label htmlFor="usuario">Usuario: *</label>
                    <MakoInputPerfil
                        id="usuario"
                        name="usuario"
                        value={formik.values.usuario}
                        onChange={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.usuario })}
                    />
                    {formik.errors.usuario && <small className="p-error">{formik.errors.usuario}</small>}
                </div>
                <div className="p-field p-col-12 p-md-3">
                    <label htmlFor="fornecedor">Fornecedor:</label>
                    <MakoInputFornecedor
                        id="fornecedor"
                        name="fornecedor"
                        value={formik.values.fornecedor}
                        onChange={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.fornecedor })}
                    />
                    {formik.errors.fornecedor && <small className="p-error">{formik.errors.fornecedor}</small>}
                </div>
            </div>
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12 p-md-12">
                    <label htmlFor="sku" className="p-mt-2">
                        Produto:
                    </label>
                    <MakoBuscaSkuPersonalizada
                        id="sku"
                        name="sku"
                        placeholder="Digite para pesquisar..."
                        skuValue={formik.values.sku}
                        skuChange={(e) => setFieldValue("sku", e)}
                        skuError={formik.errors.sku}
                        disabledBusca={!parametroVinculoSku}
                    />
                </div>
            </div>
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12 p-md-2">
                    <label htmlFor="data">Data *</label>
                    <MakoCalendar
                        id="data"
                        name="data"
                        valueCalendar={formik.values.data}
                        onChange={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.data })}
                    />
                    {formik.errors.data && <small className="p-error">{formik.errors.data}</small>}
                </div>
                <div className="p-field p-col-12 p-md-2">
                    <label htmlFor="hora">Hora: </label>
                    <Calendar
                        id="hora"
                        name="hora"
                        timeOnly
                        showTime
                        hourFormat="24"
                        value={formik.values.hora}
                        onChange={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.hora })}
                    />
                    {formik.errors.hora && <small className="p-error">{formik.errors.hora}</small>}
                </div>
                <div className="p-field p-col-12 p-md-4">
                    <label htmlFor="formacao_preco">Formação de preço:</label>
                    <Dropdown
                        id="formacao_preco"
                        name="formacao_preco"
                        options={listaFormacoes}
                        optionValue="id"
                        optionLabel="descricao"
                        value={formik.values.formacao_preco}
                        onChange={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.formacao_preco })}
                        disabled={!parametroPreco}
                    />
                    {formik.errors.formacao_preco && <small className="p-error">{formik.errors.formacao_preco}</small>}
                </div>
                {!!parametroPreco && (
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="custo">Custo: *</label>
                        <MakoInputMoeda
                            id="custo"
                            name="custo"
                            valueMoeda={formik.values.custo}
                            onChangeMoeda={handleChangeCusto}
                            className={classNames({ "p-invalid": formik.errors.custo })}
                        />
                        {formik.errors.custo && <small className="p-error">{formik.errors.custo}</small>}
                    </div>
                )}
                <div className="p-field p-col-12 p-md-2">
                    <label htmlFor="preco_venda">Preço venda: *</label>
                    <MakoInputMoeda
                        id="preco_venda"
                        name="preco_venda"
                        valueMoeda={formik.values.preco_venda}
                        onChangeMoeda={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.preco_venda })}
                        disabled={!parametroPreco}
                    />
                    {formik.errors.preco_venda && <small className="p-error">{formik.errors.preco_venda}</small>}
                </div>
            </div>
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12 p-md-12">
                    <label htmlFor="descritivo">Descritivo: *</label>
                    <InputTextarea
                        id="descritivo"
                        name="descritivo"
                        rows={3}
                        maxLength={255}
                        value={formik.values.descritivo}
                        onChange={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.descritivo })}
                    />
                    {formik.errors.descritivo && <small className="p-error">{formik.errors.descritivo}</small>}
                </div>
            </div>
            <p>
                <b>* Campos obrigatórios</b>
            </p>
            <div className="p-mt-4">
                <Button type="submit" label="Gravar" className="p-button-success p-mr-2" />
                <Button
                    type="reset"
                    icon="pi pi-trash"
                    label="Limpar"
                    onClick={formik.resetForm}
                    className="p-button-warning p-mr-2"
                />
                <Button type="reset" label="Cancelar" onClick={onCancel} className="p-button-danger" />
            </div>
        </form>
    );
};
