import React, { createContext, useState } from "react";

import { axiosPost, axiosPut } from "@/services/http";
import { dataToStr } from "@/assets/util/datas";
import useToast from "@/hooks/useToast";
import useLoading from "@/hooks/useLoading";

const BoletoContext = createContext({});

export const BoletoProvider = ({ children }) => {
    const [dadosBasicos, setDadosBasicos] = useState(null);
    const [dadosValores, setDadosValores] = useState(null);
    const [dadosAntecipacao, setDadosAntecipacao] = useState({
        valor_antecipado: 0,
        custo_antecipacao: 0,
        data_antecipacao: 0,
    });
    const [dadosPagamento, setDadosPagamento] = useState({
        data_pagamento: null,
        descontos_aplicados: 0,
        juros_aplicados: 0,
        multa_aplicada: 0,
        valor_pago: 0,
    });
    const [submitDadosBasicos, setSubmitDadosBasicos] = useState(false);
    const [submitDadosValores, setSubmitDadosValores] = useState(false);
    const { showSuccess, showError } = useToast();
    const { showLoading, hideLoading } = useLoading();

    const handleDadosBasicos = (values) => {
        setDadosBasicos(values);
        setSubmitDadosBasicos(true);
    };

    const handleValores = (values) => {
        setDadosValores(values);
        setSubmitDadosValores(true);
    };

    const handleAntecipacao = (values) => {
        setDadosAntecipacao(values);
    };

    const handlePagamento = (values) => {
        setDadosPagamento(values);
    };

    const handleBoleto = async () => {
        const { pagador, data_emissao, data_processamento, vencimento, ...restDadosBasicos } = dadosBasicos;
        const { data_antecipacao, ...restAntecipacao } = dadosAntecipacao;
        const { data_pagamento, ...restPagamento } = dadosPagamento;
        const boleto = {
            ...restDadosBasicos,
            pagador: pagador.id,
            data_emissao: dataToStr(data_emissao, "yyyy-MM-dd"),
            data_processamento: dataToStr(data_processamento, "yyyy-MM-dd"),
            vencimento: dataToStr(vencimento, "yyyy-MM-dd"),
            ...dadosValores,
            ...restAntecipacao,
            data_antecipacao: dataToStr(data_antecipacao, "yyyy-MM-dd"),
            ...restPagamento,
            data_pagamento: dataToStr(data_pagamento, "yyyy-MM-dd"),
        };
        if (!boleto.id) {
            showLoading();
            const { status } = await axiosPost("/financeiro/boletos-bancarios/", boleto);
            hideLoading();
            if (status === 201) {
                showSuccess({
                    summary: "Sucesso!",
                    detail: "Boleto lançado com sucesso.",
                    life: 1500,
                });
            } else {
                showError({
                    summary: "Erro :(",
                    detail: "Desculpe, não foi possível processar a requisição.",
                    life: 3000,
                });
            }
        } else {
            showLoading();
            const { status } = await axiosPut(`/financeiro/boletos-bancarios/${boleto.id}/`, boleto);
            hideLoading();
            if (status === 200) {
                showSuccess({
                    summary: "Sucesso!",
                    detail: "Boleto alterado com sucesso.",
                    life: 1500,
                });
            } else {
                showError({
                    summary: "Erro :(",
                    detail: "Desculpe, não foi possível processar a requisição.",
                    life: 3000,
                });
            }
        }
    };

    return (
        <BoletoContext.Provider
            value={{
                submitDadosBasicos,
                submitDadosValores,
                handleDadosBasicos,
                handleValores,
                handleAntecipacao,
                handlePagamento,
                handleBoleto,
            }}
        >
            {children}
        </BoletoContext.Provider>
    );
};

export default BoletoContext;
