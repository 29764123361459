import React, { createContext, useCallback, useMemo, useState } from "react";

import { axiosPost, axiosPatch, axiosGet, axiosDelete } from "@/services/http";
import { dataToStr } from "@/assets/util/datas";

const DevolucaoFornecedorContext = createContext({});

export const DevolucaoFornecedorProvider = ({ children }) => {
    const [dadosBasicos, setDadosBasicos] = useState({});

    const handleDevolucaoFornecedor = async (values) => {
        const devolucao = {
            ...values,
            data_devolucao: dataToStr(values.data_devolucao, "yyyy-MM-dd"),
        };

        if (!values.id) {
            const response = await axiosPost("/compras/devolucao-fornecedor/", {
                ...devolucao,
                fornecedor: devolucao.fornecedor?.id,
                motorista: devolucao.motorista?.id || null,
            });
            if (response.status === 201) setDadosBasicos(response.data);
            return response;
        } else {
            let diffDevolucao = {};
            Object.entries(devolucao).forEach(([k, v]) => {
                if (v !== dadosBasicos[k]) diffDevolucao[k] = v;
            });
            if (Object.keys(diffDevolucao).length) {
                const response = await axiosPatch(`/compras/devolucao-fornecedor/${values.id}/`, {
                    ...diffDevolucao,
                    transportador: diffDevolucao?.transportador?.id || diffDevolucao?.transportador,
                    motorista: diffDevolucao?.motorista?.id || null,
                });

                if (response.status === 200) {
                    const { transportador } = response.data;
                    const _transportador = transportador?.id ? transportador : values?.transportador;
                    setDadosBasicos({ ...response.data, tipo: response.data.tipo.id, transportador: _transportador });
                }
                return response;
            }
        }
        return { status: 204, data: values };
    };

    const handlePreencherTroca = useCallback(async (idDev) => {
        const resposta = await axiosGet(`/compras/devolucao-fornecedor/${idDev}/`);

        if (resposta.status === 200) {
            const { tipo, ...rest } = resposta.data;
            setDadosBasicos({ ...rest, tipo: tipo.id });
        }
    }, []);

    const handleItens = useCallback(
        async (values) => {
            if (!values.id) {
                const { status, data } = await axiosPost("/compras/item-devolucao-fornecedor/", {
                    ...values,
                    sku: values.sku.id,
                    descricao_item_fornecedor: values.sku.descricao_reduzida,
                    devolucao_fornecedor: dadosBasicos.id,
                    numero_volumes_por_item: 1,
                });
                if (status === 201) handlePreencherTroca(dadosBasicos.id);
                return { status, data };
            } else {
                const { status, data } = await axiosPatch(`/compras/item-devolucao-fornecedor/${values.id}/`, {
                    ...values,
                    sku: values.sku.id,
                });
                return { status, data };
            }
        },
        [dadosBasicos?.id, handlePreencherTroca]
    );

    const handleParcelas = useCallback(
        async (values, remover = false) => {
            if (!values.id) {
                const { status, data } = await axiosPost("/compras/parcela-devolucao-fornecedor/", {
                    ...values,
                    devolucao_fornecedor: dadosBasicos.id,
                    credor: values?.credor?.id || values?.credor,
                });
                return { status, data };
            } else {
                if (remover) {
                    const { status, data } = await axiosDelete(`/compras/parcela-devolucao-fornecedor/${values.id}`);
                    return { status, data };
                }
                const { status, data } = await axiosPatch(
                    `/compras/parcela-devolucao-fornecedor/${values.id}/`,
                    values
                );
                return { status, data };
            }
        },
        [dadosBasicos?.id]
    );

    const editavel = useMemo(() => {
        if (!dadosBasicos) return true;
        const { status } = dadosBasicos || {};
        return status !== "F";
    }, [dadosBasicos]);

    const possuiDevolucao = useMemo(() => {
        return Object.keys(dadosBasicos).length > 0;
    }, [dadosBasicos]);

    return (
        <DevolucaoFornecedorContext.Provider
            value={{
                editavel,
                dadosBasicos,
                possuiDevolucao,
                handleDevolucaoFornecedor,
                handlePreencherTroca,
                handleItens,
                handleParcelas,
            }}
        >
            {children}
        </DevolucaoFornecedorContext.Provider>
    );
};

export default DevolucaoFornecedorContext;
