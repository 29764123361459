import React, { useRef } from "react";
import { useHistory } from "react-router-dom";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { Button } from "primereact/button";

import permissoes from "@/assets/constants/permissoes";
import MakoListagem from "@/components/MakoListagem";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import useLoading from "@/hooks/useLoading";
import { axiosDelete } from "@/services/http";
import useClearRefs from "@/hooks/useClearRefs";
import useToast from "@/hooks/useToast";
import { DropdownFiltroTemplate, MoedaFiltroTemplate, TextoFiltroTemplate } from "@/components/MakoFiltrosCabecalho";

export const RegraArredondamentoValorPage = () => {
    const listagemRef = useRef(null);
    const history = useHistory();
    const { showLoading, hideLoading } = useLoading();
    const { showError } = useToast();
    useClearRefs(listagemRef);

    const painelEsquerdoTabela = (
        <>
            <Button
                label="Nova"
                icon="pi pi-plus"
                className="p-button-success p-mr-2 p-mb-2"
                onClick={() => history.push("/vendas/cadastros/arredondamento-valor/form")}
            />
        </>
    );

    const deletarRegraArredondamentoValor = async (value) => {
        showLoading();
        const resposta = await axiosDelete(`/vendas/regra-arredondamento-valor/${value.id}/`);
        hideLoading();

        if (resposta.status === 204) {
            listagemRef.current?.buscarDados();
        } else {
            showError({
                summary: "Erro :(",
                detail: "A regra de arredondamento não pode ser excluída.",
                life: 3000,
            });
        }
    };

    const confirmarDelete = (value) => {
        confirmDialog({
            header: "Confirmação de exclusão",
            message: (
                <span>
                    Deseja mesmo excluir a regra de arredondamento <b>{value.descricao}</b>?
                </span>
            ),
            icon: "pi pi-info-circle",
            acceptClassName: "p-button-danger",
            acceptLabel: "Deletar",
            accept: () => deletarRegraArredondamentoValor(value),
            rejectLabel: "Cancelar",
        });
    };

    const acimaBodyTemplate = (rowData) => {
        if (rowData.acima) {
            return <span>Para cima</span>;
        }

        return <span>Para baixo</span>;
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <MakoControleAcesso
                    permissao={[permissoes.VENDAS_CADASTRO_ARREDONDAMENTOVALOR_EDITAR]}
                    componente={Button}
                    icon="pi pi-pencil"
                    className="p-button-rounded p-button-warning p-mr-2"
                    onClick={() =>
                        history.push({
                            pathname: "/vendas/cadastros/arredondamento-valor/form",
                            state: rowData,
                        })
                    }
                />
                <MakoControleAcesso
                    permissao={[permissoes.VENDAS_CADASTRO_ARREDONDAMENTOVALOR_EXCLUIR]}
                    componente={Button}
                    icon="pi pi-trash"
                    className="p-button-rounded p-button-danger p-mr-2"
                    onClick={() => confirmarDelete(rowData)}
                />
            </div>
        );
    };

    const DirecaoFiltroTemplate = (options) => {
        return (
            <DropdownFiltroTemplate
                dropdownProps={{
                    optionValue: "value",
                    optionLabel: "label",
                    options: [
                        { value: true, label: "Para cima" },
                        { value: false, label: "Para baixo" },
                    ],
                }}
                options={options}
            />
        );
    };

    const colunas = [
        {
            field: "descricao",
            header: "Descrição",
            filter: true,
            filterElement: TextoFiltroTemplate,
        },
        {
            field: "minimo",
            header: "Valor mínimo",
            money: true,
            style: { width: "12%" },
            filter: true,
            filterElement: MoedaFiltroTemplate,
        },
        {
            field: "maximo",
            header: "Valor máximo",
            money: true,
            style: { width: "12%" },
            filter: true,
            filterElement: MoedaFiltroTemplate,
        },
        {
            field: "acima",
            header: "Direção do arredondamento",
            style: { width: "20%" },
            action: (e) => acimaBodyTemplate(e),
            filter: true,
            filterElement: DirecaoFiltroTemplate,
        },
        {
            field: "actions",
            header: "Ações",
            style: { width: "10%" },
            action: (e) => actionBodyTemplate(e),
        },
    ];
    return (
        <div className="p-grid">
            <ConfirmDialog />
            <div className="p-col-12">
                <div className="card">
                    <MakoListagem
                        ref={listagemRef}
                        titulo="Listagem de regras de arredondamento de preços"
                        colunas={colunas}
                        painelEsquerdo={painelEsquerdoTabela}
                        urlPesquisa="/vendas/regra-arredondamento-valor/"
                        configTabela={{
                            paginator: true,
                            lazy: true,
                        }}
                        filtros={{
                            descricao: {
                                operator: "and",
                                constraints: [{ value: "", matchMode: "unaccent_icontains" }],
                            },
                            minimo: { value: null, matchMode: "equals" },
                            maximo: { value: null, matchMode: "equals" },
                            acima: { value: null, matchMode: "equals" },
                        }}
                    />
                </div>
            </div>
        </div>
    );
};
