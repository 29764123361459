import React, { useCallback, useEffect, useState, memo } from "react";
import { Dropdown } from "primereact/dropdown";
import useFormatCNPJCPF from "@/hooks/useFomatCNPJCPF";
import useAuth from "@/hooks/useAuth";
import useToast from "@/hooks/useToast";
import useHttp from "@/hooks/useHttp";

const Componente = ({
    placeholder,
    disabled,
    onChange,
    redefinirEmpresaPadrao = false,
    somenteEmpresasVinculadas = false,
    nome_curto = true,
    ...props
}) => {
    const [empresas, setEmpresas] = useState([]);
    const [loading, setLoading] = useState(false);
    const [formatarDocumento] = useFormatCNPJCPF();
    const { user, reloadPermissions } = useAuth();
    const { showError } = useToast();
    const { httpGet, httpPatch } = useHttp();

    const alterarEmpresaPadrao = useCallback(
        async (empresaPadraoId) => {
            if (redefinirEmpresaPadrao && user?.usuario_empresapadrao && empresaPadraoId) {
                const { id } = user.usuario_empresapadrao;

                setLoading(true);
                await httpPatch({
                    url: `/pessoas/usuarios-empresa-padrao/${id}/`,
                    body: {
                        empresa_padrao: empresaPadraoId,
                    },
                });
                setLoading(false);
            }
        },
        [redefinirEmpresaPadrao, user, httpPatch]
    );

    const buscarEmpresasVinculadas = useCallback(async () => {
        const handlers = {
            200: async ({ data }) => {
                let empresas = [];
                await data.results.forEach(async ({ perfil }) => {
                    if (somenteEmpresasVinculadas) {
                        const handlers = {
                            200: ({ data }) => {
                                if (data.length > 0)
                                    empresas.push({
                                        ...perfil,
                                        label: nome_curto && perfil.nome_curto ? perfil.nome_curto : perfil.nome,
                                    });
                            },
                        };
                        await httpGet(
                            {
                                url: `/pessoas/permissoes-perfis-vigentes?empresa=${perfil.id}&perfil=${user?.id}`,
                            },
                            handlers
                        );
                    } else {
                        empresas.push({
                            ...perfil,
                            label: nome_curto && perfil.nome_curto ? perfil.nome_curto : perfil.nome,
                        });
                    }
                });
                setTimeout(() => {
                    setEmpresas(empresas);
                }, [600]);
            },
        };

        setLoading(true);
        await httpGet(
            {
                url: "/pessoas/grupos-perfis?limit=100",
            },
            handlers
        );
        setLoading(false);
    }, [nome_curto, somenteEmpresasVinculadas, user?.id, httpGet]);

    useEffect(() => {
        buscarEmpresasVinculadas();
    }, [buscarEmpresasVinculadas]);

    const empresaOpcaoTemplate = (option) => {
        return <span>{`${option.label} - ${formatarDocumento(option.identificacao)}`}</span>;
    };

    const empresaSelecionadaTemplate = (option, props) => {
        if (option) return empresaOpcaoTemplate(option);
        return <span>{props.placeholder}</span>;
    };

    const onChangeEmpresa = async (value) => {
        if (value) {
            const empresaFull = empresas.find((el) => el.id === value);
            onChange({ id: value, empresa: empresaFull });
            if (redefinirEmpresaPadrao) {
                await reloadPermissions(value);
                await alterarEmpresaPadrao(value);
            }
        } else onChange({ id: null, empresa: null });
    };

    return (
        <Dropdown
            options={empresas}
            optionValue="id"
            filter
            filterBy="nome,identificacao"
            showClear
            placeholder={loading ? "Carregando..." : placeholder || "Selecione"}
            disabled={loading || disabled}
            emptyMessage="Nenhum registro disponível"
            emptyFilterMessage="Nenhum registro encontrado"
            valueTemplate={empresaSelecionadaTemplate}
            itemTemplate={empresaOpcaoTemplate}
            onChange={(e) => onChangeEmpresa(e.value)}
            {...props}
        />
    );
};

export const MakoDropdownEmpresas = memo(Componente);
