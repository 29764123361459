import React, { useRef, useEffect, memo, forwardRef, useCallback } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { useFormik } from "formik";
import { Toast } from "primereact/toast";
import * as Yup from "yup";
import classNames from "classnames";
import { InputTextarea } from "primereact/inputtextarea";
import { MakoTime } from "@/components/MakoTime";
import { MakoCalendar } from "@/components/MakoCalendar";
import useToast from "@/hooks/useToast";
import { MakoAutoComplete } from "@/components/MakoAutoComplete";
import { MakoInputMoeda } from "@/components/MakoInputMoeda";
import useTransferencia from "@/hooks/useTransferencia";
import { Divider } from "primereact/divider";
import { FormikAutoSave } from "@/components/FormikAutoSave";
import useAuth from "@/hooks/useAuth";
import useFormatCNPJCPF from "@/hooks/useFomatCNPJCPF";
import { BlockUI } from "@/components/BlockUI";
import { Dropdown } from "@/components/Dropdown";
import { MakoDropdownCompetenciaEstoque } from "@/components/MakoInputs/MakoDropdownCompetenciaEstoque";
import useEmpresa from "@/hooks/useEmpresa";

export const DadosBasicosForm = ({ tipo }) => {
    const { showError } = useToast();
    const { user } = useAuth();
    const { empresaSelecionadaId } = useEmpresa();
    const toastRef = useRef(null);
    const { transferencia, handleDadosBasicos } = useTransferencia();
    const [formatarDocumento] = useFormatCNPJCPF();

    const { setFieldValue, setValues, ...formik } = useFormik({
        initialValues: {
            id: null,
            tipo_transferencia: null,
            ce_destino: null,
            ce_origem: null,
            competencia: null,
            carga: null,
            data_emissao: new Date(),
            hora_emissao: null,
            usuario_gerou: user,
            orientacoes: null,
            valor_total: 0,
            status: "P",
        },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                ce_destino: Yup.number().required("O campo é obrigatório."),
                ce_origem: Yup.number().required("O campo é obrigatório."),
                competencia: Yup.number().required("O campo é obrigatório."),
                data_emissao: Yup.date().required("O campo é obrigatório."),
                carga: Yup.object().nullable(),
            });

            await formSchema.validate(values, {
                abortEarly: false,
            });

            const { status } = await handleDadosBasicos(values, tipo, user);

            if (status !== 200 && status !== 201)
                showError({
                    summary: "Erro",
                    detail: "Desculpe, não conseguimos processar sua requisição.",
                    life: 3000,
                });
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            }
        }
    }

    const filtraCentroOrigem = useCallback(
        async (results) => {
            const { tipo_ce_origem, tipo_sku_origem } = tipo;
            const dados = await results?.filter(
                (ce) =>
                    ce.tipo_sku === tipo_sku_origem.id &&
                    ce.tipo === tipo_ce_origem.id &&
                    empresaSelecionadaId === ce.estoque_empresa.empresa.id
            );
            if (dados?.length === 1) setFieldValue("ce_origem", dados[0].id);
            return dados;
        },
        [setFieldValue]
    );

    const filtrarCentroDestino = useCallback(
        async (results) => {
            const {
                tipo_ce_destino,
                tipo_sku_destino,
                /*mesma_raiz_cnpj,
            mesmo_centro_estocagem,
            mesmo_cnpj_cpf,
            mesmo_estoque,*/
            } = tipo;

            let lista_destino = await results?.filter(
                (ce) =>
                    ce.tipo_sku === tipo_sku_destino.id &&
                    ce.tipo === tipo_ce_destino.id &&
                    ce.id !== formik.values.ce_origem
            );

            /*lista_destino.forEach((ce) => {
            const { empresa, ...estoque } = ce.estoque_empresa;
            if (mesma_raiz_cnpj !== null) {
                const raizOrigem = formik.values.ce_origem.estoque_empresa.empresa.identificacao.substring(
                    0,
                    formik.values.ce_origem.estoque_empresa.empresa.identificacao.length - 2
                );
                const raiz = empresa.identificacao.substring(0, empresa.identificacao.length - 2);
                if (mesma_raiz_cnpj) {
                    if (raiz !== raizOrigem) return true;
                } else if (raiz === raizOrigem) return true;
            }
            if (mesmo_centro_estocagem !== null)
                try {
                    if (mesmo_centro_estocagem) {
                        if (formik.values.ce_origem.centro_estocagem_principal !== ce.centro_estocagem_principal)
                            return true;
                    } else if (formik.values.ce_origem.centro_estocagem_principal === ce.centro_estocagem_principal)
                        return true;
                } catch {
                    return false;
                }

            if (mesmo_cnpj_cpf !== null)
                if (mesmo_cnpj_cpf) {
                    if (formik.values.ce_origem.estoque_empresa.empresa.identificacao !== empresa.identificacao)
                        return true;
                } else if (formik.values.ce_origem.estoque_empresa.empresa.identificacao === empresa.identificacao)
                    return true;

            if (mesmo_estoque !== null)
                if (mesmo_estoque) {
                    if (formik.values.ce_origem.estoque_empresa.id !== estoque.id) return true;
                } else if (formik.values.ce_origem.estoque_empresa.id === estoque.id) return true;
        });*/
            if (lista_destino?.length === 1) setFieldValue("ce_destino", lista_destino[0].id);
            return lista_destino;
        },
        [formik.values.ce_origem, tipo, setFieldValue]
    );

    const autoCompleteTemplate = (carga) => {
        carga.label = `Carga: ${carga.id} | Rota de Transferência: ${carga.rota_transf.descricao}`;
        return `Carga: ${carga.id} | Rota de Transferência: ${carga.rota_transf.descricao}`;
    };

    useEffect(() => {
        if (transferencia) setValues(transferencia);
    }, [transferencia, setValues]);

    const itemTemplate = (centro) => {
        if (centro)
            return `${centro.estoque_empresa.descricao} - ${centro.nome} (${
                centro.estoque_empresa.empresa.nome
            }:${formatarDocumento(centro.estoque_empresa.empresa.identificacao)})`;
        return "---";
    };

    const valueTemplate = (centro) => {
        if (centro)
            return `${centro.estoque_empresa.descricao} - ${centro.nome} (${
                centro.estoque_empresa.empresa.nome
            }:${formatarDocumento(centro.estoque_empresa.empresa.identificacao)})`;
    };

    return (
        <>
            <Toast ref={toastRef} />
            <BlockUI
                blocked={
                    transferencia?.status?.id === "E" ||
                    transferencia?.status?.id === "F" ||
                    transferencia?.status?.id === "C" ||
                    transferencia?.status?.id === "R"
                }
                template={
                    <b style={{ fontSize: "3rem" }}>
                        A transferência foi{" "}
                        {transferencia?.status?.id === "E"
                            ? "enviada"
                            : transferencia?.status?.id === "F"
                            ? "finalizada"
                            : "cancelada"}
                        .
                    </b>
                }
            >
                <form onSubmit={formik.handleSubmit}>
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-12 p-md-2">
                            <label htmlFor="id">Código</label>
                            <InputText id="id" name="id" value={formik.values.id} readOnly disabled />
                        </div>
                        <div className="p-field p-col-12 p-md-5">
                            <label htmlFor="ce_origem">Centro de Estocagem Origem</label>
                            <Dropdown
                                id="ce_origem"
                                name="ce_origem"
                                placeholder={"Selecione um centro de estocagem"}
                                url="/produtos/centros-estocagem?limit=1000"
                                aposBuscar={filtraCentroOrigem}
                                style={{ height: "58%" }}
                                showClear={false}
                                filter
                                filterBy="id,nome"
                                optionValue="id"
                                optionLabel="nome"
                                itemTemplate={itemTemplate}
                                valueTemplate={valueTemplate}
                                value={formik.values.ce_origem}
                                onChange={(e) => setFieldValue("ce_origem", e.target.value)}
                                className={classNames({ "p-invalid": formik.errors.ce_origem })}
                            />
                            {formik.errors.ce_origem && <small className="p-error">{formik.errors.ce_origem}</small>}
                        </div>
                        <div className="p-field p-col-12 p-md-5">
                            <label htmlFor="ce_destino">Centro de Estocagem Destino</label>
                            <Dropdown
                                id="ce_destino"
                                name="ce_destino"
                                placeholder={"Selecione um centro de estocagem"}
                                disabled={!formik.values.ce_origem}
                                url="/produtos/centros-estocagem?limit=1000"
                                showClear={false}
                                aposBuscar={filtrarCentroDestino}
                                filter
                                filterBy="id,nome"
                                optionValue="id"
                                optionLabel="nome"
                                style={{ height: "58%" }}
                                itemTemplate={itemTemplate}
                                valueTemplate={valueTemplate}
                                value={formik.values.ce_destino}
                                onChange={formik.handleChange}
                                className={classNames({ "p-invalid": formik.errors.ce_destino })}
                            />
                            {formik.errors.ce_destino && <small className="p-error">{formik.errors.ce_destino}</small>}
                        </div>
                    </div>
                    <Divider />
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-12 p-md-6">
                            <label htmlFor="carga">Carga</label>
                            <MakoAutoComplete
                                id="carga"
                                name="carga"
                                value={formik.values.carga}
                                onChange={formik.handleChange}
                                itemTemplate={autoCompleteTemplate}
                                field="label"
                                urlSearch={`/transferencias/carga?search=`}
                                placeholder="Comece a digitar para buscar..."
                                className={classNames({ "p-invalid": formik.errors.carga })}
                            />
                        </div>
                        <div className="p-field p-col-12 p-md-3">
                            <label htmlFor="veiculo">Veículo</label>
                            <InputText
                                id="veiculo"
                                name="veiculo"
                                value={formik.values.carga?.veiculo?.placa || "Não possui"}
                                readOnly
                                disabled
                            />
                        </div>
                        <div className="p-field p-col-12 p-md-3">
                            <label htmlFor="tipo">Tipo</label>
                            <InputText
                                id="tipo"
                                name="tipo"
                                value={formik.values.carga?.veiculo?.tipo?.descricao || "-"}
                                readOnly
                                disabled
                            />
                        </div>
                        <div className="p-field p-col-12 p-md-3">
                            <label htmlFor="carga_maxima">Carga máxima</label>
                            <InputText
                                id="carga_maxima"
                                name="carga_maxima"
                                value={formik.values.carga?.veiculo?.carga_maxima || "-"}
                                readOnly
                                disabled
                            />
                        </div>
                        <div className="p-field p-col-12 p-md-3">
                            <label htmlFor="categoria_exigida">Categoria</label>
                            <InputText
                                id="categoria_exigida"
                                name="categoria_exigida"
                                value={formik.values.carga?.veiculo?.categoria_exigida?.descricao || "-"}
                                readOnly
                                disabled
                            />
                        </div>
                        <div className="p-field p-col-12 p-md-6">
                            <label htmlFor="motorista">Motorista</label>
                            <InputText
                                id="motorista"
                                name="motorista"
                                value={formik.values.carga?.motorista?.perfil_pf?.nome_completo || "Não possui"}
                                readOnly
                                disabled
                            />
                        </div>
                    </div>
                    <Divider />
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-12 p-md-3">
                            <label htmlFor="data_emissao">Data da transferencia *</label>
                            <MakoCalendar
                                id="data_emissao"
                                name="data_emissao"
                                valueCalendar={formik.values.data_emissao}
                                minDate={new Date()}
                                onChange={formik.handleChange}
                                className={classNames({ "p-invalid": formik.errors.data_emissao })}
                            />
                            {formik.errors.data_emissao && (
                                <small className="p-error">{formik.errors.data_emissao}</small>
                            )}
                        </div>
                        <div className="p-field p-col-12 p-md-3">
                            <label htmlFor="hora_emissao">Hora</label>
                            <MakoTime
                                id="hora_emissao"
                                name="hora_emissao"
                                valueTime={formik.values.hora_emissao}
                                onChange={formik.handleChange}
                            />
                        </div>
                        <div className="p-field p-col-12 p-md-3">
                            <label htmlFor="usuario_gerou">Usuário que gerou</label>
                            <InputText
                                id="usuario_gerou"
                                name="usuario_gerou"
                                value={formik.values.usuario_gerou?.nome || user?.username}
                                readOnly
                                disabled
                            />
                        </div>
                        <div className="p-field p-col-12 p-md-3">
                            <label htmlFor="usuario_processou">Usuário que processou</label>
                            <InputText
                                id="usuario_processou"
                                name="usuario_processou"
                                value={formik.values.usuario_processou}
                                readOnly
                                disabled
                            />
                        </div>
                    </div>
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-12 p-md-3">
                            <label htmlFor="datahora_proc">Data processamento</label>
                            <MakoCalendar
                                id="datahora_proc"
                                name="datahora_proc"
                                valueCalendar={formik.values.datahora_proc}
                                minDate={new Date()}
                                readOnly
                                className={classNames({ "p-invalid": formik.errors.datahora_proc })}
                                disabled
                            />
                        </div>
                        <div className="p-field p-col-12 p-md-3">
                            <label htmlFor="valor_total">Valor total</label>
                            <MakoInputMoeda
                                id="valor_total"
                                name="valor_total"
                                valueMoeda={formik.values.valor_total}
                                readOnly
                                disabled
                            />
                        </div>
                        <div className="p-field p-col-12 p-md-6">
                            <label htmlFor="competencia">Competência de estoque *</label>
                            <MakoDropdownCompetenciaEstoque
                                id="competencia"
                                name="competencia"
                                value={formik.values.competencia}
                                onChange={formik.handleChange}
                                buscarPorEmpresa
                                className={classNames({ "p-invalid": formik.errors.competencia })}
                            />
                            {formik.errors.competencia && (
                                <small className="p-error">{formik.errors.competencia}</small>
                            )}
                        </div>
                    </div>
                    <div className="p-fluid p-formgrid p-grid ">
                        <div className="p-field p-col-12 p-md-12">
                            <label htmlFor="orientacoes">Observações</label>
                            <InputTextarea
                                id="orientacoes"
                                name="orientacoes"
                                value={formik.values.orientacoes}
                                onChange={formik.handleChange}
                                className={classNames({ "p-invalid": formik.errors.orientacoes })}
                                rows={4}
                                autoComplete="off"
                                autoResize
                            />
                            {formik.errors.orientacoes && (
                                <small className="p-error">{formik.errors.orientacoes}</small>
                            )}
                        </div>
                    </div>
                    <p>* Campos obrigatórios</p>
                    {!transferencia?.id ? (
                        <div className="p-grid">
                            <div className="p-col-12 p-md-6">
                                <Button
                                    type="submit"
                                    icon="pi pi-save"
                                    label="Incluir dados básicos"
                                    className="p-mr-2 p-mb-2"
                                />
                            </div>
                        </div>
                    ) : null}
                    <FormikAutoSave intervalo={500} autosave={typeof transferencia?.id === "number"} formik={formik} />
                </form>
            </BlockUI>
        </>
    );
};

export default memo(forwardRef(DadosBasicosForm));
