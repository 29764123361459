import React, { useCallback, useEffect, useRef, useState } from "react";
import { Button } from "primereact/button";
import { MakoListagem } from "@/components/MakoListagem";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import permissoes from "@/assets/constants/permissoes";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import useToast from "@/hooks/useToast";
import { axiosGet, axiosPost } from "@/services/http";
import useEmpresa from "@/hooks/useEmpresa";
import useTroca from "@/hooks/useTroca";

export const DocumentosTrocaForm = () => {
    const { dadosBasicos } = useTroca();
    const [itensTroca, setItensTroca] = useState([]);
    const history = useHistory();
    const listagemRef = useRef();
    const { showSuccess, showError, showWarning } = useToast();
    const { empresaSelecionada } = useEmpresa();

    const carregarItensDevolucao = useCallback(async () => {
        const { status, data } = await axiosGet(`/vendas/itens-troca-devolucao/?troca=${dadosBasicos?.id}`);
        if (status === 200) setItensTroca(data.results);
    }, [dadosBasicos?.id]);

    useEffect(() => {
        carregarItensDevolucao();
    }, [carregarItensDevolucao]);

    async function enviarNota() {
        try {
            if ((await empresaSelecionada?.enderecoperfil_set?.length) > 0) {
                const { cidade } = await empresaSelecionada?.enderecoperfil_set[0];
                const body = {
                    modelo: "55",
                    operacao_fiscal: itensTroca[0].operacao_fiscal,
                    natureza_operacao: null,
                    evento_id: dadosBasicos.id,
                    venda: null,
                    app_label: "vendas",
                    model: "troca",
                    tipo_operacao: 0,
                    transferencia: null,
                    dados_basicos: {
                        codigo_uf: cidade.estado?.codigo_uf,
                        emitente: dadosBasicos.empresa,
                        destinatario: dadosBasicos.cliente.id || null,
                        requer_identificacao: dadosBasicos.cliente?.id ? true : false,
                        indicador_pagamento: 3,
                        municipio_ocorrencia: cidade.id,
                        transportador: null,
                        indicador_final: 1,
                        formato_impressao: 0,
                        nota_referencia: [],
                    },
                    itens: itensTroca.map((item) => {
                        return {
                            ...item,
                            unidade: item.unidade_medida.sigla,
                            sku: item.sku.id,
                            valor_total: item.valor_unitario * item.quantidade,
                            valor_desconto: 0,
                            valor_frete: 0,
                            valor_seguro: 0,
                            quantidade_nf: item.quantidade,
                            outras_despesas: 0,
                            municipio_issqn: cidade.id,
                            municipio_incidencia: cidade.id,
                            app_label: "vendas",
                            model: "itemtrocadevolucao",
                        };
                    }),
                };

                const resposta = await axiosPost("/fiscal/inserir-nota-fiscal/", body);

                if (resposta.status === 200) {
                    listagemRef.current?.buscarDados();
                    showSuccess({
                        summary: "Sucesso!",
                        detail: "Nota fiscal incluida com sucesso!",
                        life: 3000,
                    });
                } else if (resposta.status === 400) {
                    showWarning({
                        summary: "Falha ao inserir",
                        detail: `Não foi possível inserir a nota fiscal, verifique as informações da operação fiscal e tente novamente (${resposta.data.erro}.)`,
                        life: 5000,
                    });
                } else {
                    showError({
                        summary: "Erro",
                        detail: "Desculpe, não foi possível inserir a nota fiscal.",
                        life: 3000,
                    });
                }
            }
        } catch (e) {
            showWarning({
                summary: "Cadastro Incompleto!",
                detail: "A empresa atual não possui todas as informações registradas, verifique o cadastro e tente novamente.",
                life: 5000,
            });
        }
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <MakoControleAcesso
                    permissao={[permissoes.FISCAL_NOTASAIDA_EDITAR]}
                    componente={Button}
                    icon="pi pi-pencil"
                    className="p-button-rounded p-button-warning p-mr-2 p-mb-1"
                    tooltip="Editar"
                    tooltipOptions={{ position: "left" }}
                    disabled={rowData.status === "C" || rowData.status === "T"}
                    onClick={() =>
                        history.push({
                            pathname: "/fiscal/nota-saida/emissao-nota",
                            state: { notaFiscal: rowData },
                        })
                    }
                />
            </div>
        );
    };

    const verificaEmissao = (chave) => {
        if (chave) return chave;
        else return "NÃO TRANSMITIDO";
    };

    const verificaProtocolo = (protocolo) => {
        if (protocolo) return protocolo;
        else return "-";
    };

    const colunas = [
        {
            field: "numero",
            header: "Número",
            filter: true,
            style: { minWidth: "50px" },
        },
        {
            field: "serie",
            header: "Série",
            filter: true,
            style: { minWidth: "50px" },
        },
        {
            field: "modelo",
            header: "Modelo",
            filter: true,
            style: { minWidth: "50px" },
        },
        {
            field: "chave_nf",
            header: "Chave",
            action: (e) => verificaEmissao(e.chave_nf),
            filter: true,
            align: "left",
            style: { minWidth: "400px" },
        },
        {
            field: "protocolo",
            header: "Protocolo",
            action: (e) => verificaProtocolo(e.protocolo),
            filter: true,
            style: { minWidth: "200px" },
        },
        {
            field: "valor_total_nf",
            header: "Total Nf",
            filter: true,
            align: "right",
            style: { minWidth: "120px" },
        },
        {
            field: "action",
            header: "Ações",
            action: (e) => actionBodyTemplate(e),
            style: { width: "15%" },
        },
    ];

    const painelEsquerdo = (
        <MakoControleAcesso
            permissao={[permissoes.FISCAL_NOTASAIDA_INCLUIR]}
            componente={Button}
            icon="pi pi-cog"
            type="button"
            label="Gerar Documento Fiscal"
            onClick={() => enviarNota()}
            className="p-mr-2"
        />
    );

    const rowClassNF = (rowData) => {
        return {
            "mako-table-status-inativo": rowData.status === "C",
            "table-recebimentos-effective": rowData.status === "T",
        };
    };

    return (
        <>
            <MakoListagem
                ref={listagemRef}
                painelEsquerdo={painelEsquerdo}
                titulo="Documentos fiscais de devolução da troca"
                urlPesquisa={`/fiscal/notas-fiscais?evento_id=${dadosBasicos.id}&evento_content_type__model=troca`}
                colunas={colunas}
                configTabela={{
                    lazy: true,
                    paginator: true,
                    rowClassName: rowClassNF,
                }}
            />
        </>
    );
};
