import React, { useState, useRef } from "react";
import { Button } from "primereact/button";
import { Redirect } from "react-router-dom";
import { ConfirmDialog } from "primereact/confirmdialog";
import { Toast } from "primereact/toast";
import { Dropdown } from "primereact/dropdown";
import { axiosDelete } from "@/services/http";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import { Tag } from "primereact/tag";

import permissoes from "@/assets/constants/permissoes";
import MakoListagem from "@/components/MakoListagem";
import useLoading from "@/hooks/useLoading";
import { CodigoFiltroTemplate } from "@/components/MakoFiltrosCabecalho";

export const RegraApuracaoComissaoPage = () => {
    const [redirecionar, setRedirecionar] = useState(null);
    const [deleteDialog, setDeleteDialog] = useState(false);
    const [regra, setRegra] = useState(null);
    const { showLoading, hideLoading } = useLoading();
    const toastRef = useRef(null);
    const listagemRef = useRef(null);

    const confirmarDelete = (regra) => {
        setRegra(regra);
        setDeleteDialog(true);
    };

    const esconderDeleteDialog = () => {
        setDeleteDialog(false);
    };

    const deleteRegra = async () => {
        showLoading();
        const resposta = await axiosDelete(`/vendas/regra-apuracao-comissao-venda/${regra.id}`);
        hideLoading();

        if (resposta.status === 204) {
            toastRef.current.show({
                severity: "success",
                summary: "Sucesso",
                detail: "Regra de apuração de comissão deletada com sucesso!",
                life: 3000,
            });

            listagemRef.current?.buscarDados();
        } else {
            toastRef.current.show({
                severity: "error",
                summary: "Erro",
                detail: "Desculpe, não conseguimos processar a sua requisição.",
                life: 3000,
            });
            hideLoading();
        }
        setDeleteDialog(false);
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <MakoControleAcesso
                    permissao={[permissoes.VENDAS_CADASTRO_REGRAAPURACAO_EDITAR]}
                    componente={Button}
                    icon="pi pi-pencil"
                    className="p-button-rounded p-button-warning p-mr-2 p-mb-1"
                    tooltip="Alterar regra de apuração"
                    onClick={() =>
                        setRedirecionar({
                            pathname: "/vendas/cadastros/regra-apuracao-comissao/form",
                            state: { regra: rowData },
                        })
                    }
                />
                <MakoControleAcesso
                    permissao={[permissoes.VENDAS_CADASTRO_REGRAAPURACAO_EXCLUIR]}
                    componente={Button}
                    icon="pi pi-trash"
                    tooltip="Deletar regra de apuração"
                    className="p-button-rounded p-button-danger p-mr-2 p-mb-1"
                    onClick={() => confirmarDelete(rowData)}
                />
            </div>
        );
    };

    const cabecalhoTabela = (
        <>
            <MakoControleAcesso
                permissao={[permissoes.VENDAS_CADASTRO_REGRAAPURACAO_INCLUIR]}
                componente={Button}
                label="Novo"
                icon="pi pi-plus"
                className="p-button-success p-mr-2"
                onClick={() => setRedirecionar("/vendas/cadastros/regra-apuracao-comissao/form")}
            />
        </>
    );

    const STATUS_HIERARQUIA = [
        { value: true, label: "Sim" },
        { value: false, label: "Não" },
    ];

    const hierarquiaBodyTemplate = (rowData) => {
        if (rowData.usa_hierarquia) {
            return <Tag severity="success" value="SIM" />;
        }

        return <Tag severity="danger" value="NÃO" />;
    };

    const hierarquiaFiltroTemplate = (options) => {
        return (
            <Dropdown
                placeholder="Selecione"
                showClear
                value={options.value}
                options={STATUS_HIERARQUIA}
                onChange={(e) => options.filterCallback(e.value)}
            />
        );
    };

    const STATUS_REGRA = [
        { value: true, label: "Ativo" },
        { value: false, label: "Desativado" },
    ];

    const statusBodyTemplate = (rowData) => {
        if (rowData.ativo) {
            return <Tag severity="success" value="ATIVO" />;
        }

        return <Tag severity="danger" value="DESATIVADO" />;
    };

    const statusFiltroTemplate = (options) => {
        return (
            <Dropdown
                placeholder="Selecione"
                showClear
                value={options.value}
                options={STATUS_REGRA}
                onChange={(e) => options.filterCallback(e.value)}
            />
        );
    };

    const colunas = [
        { field: "id", header: "Código", style: { width: "12%" }, filter: true, filterElement: CodigoFiltroTemplate },
        { field: "descricao", header: "Descrição", filter: true },
        {
            field: "ativo",
            header: "Ativo",
            filter: true,
            filterElement: statusFiltroTemplate,
            action: (e) => statusBodyTemplate(e),
            style: { width: "15%" },
        },
        {
            field: "usa_hierarquia",
            header: "Usa hierarquia?",
            filter: true,
            filterElement: hierarquiaFiltroTemplate,
            action: (e) => hierarquiaBodyTemplate(e),
            style: { width: "15%" },
        },
        {
            field: "action",
            header: "Ações",
            action: (e) => actionBodyTemplate(e),
            style: { width: "15%" },
        },
    ];

    return redirecionar ? (
        <Redirect to={redirecionar} />
    ) : (
        <div className="p-grid">
            <Toast ref={toastRef} />
            <div className="p-col-12">
                <div className="card">
                    <MakoListagem
                        ref={listagemRef}
                        titulo="Regras de Comissão"
                        colunas={colunas}
                        painelEsquerdo={cabecalhoTabela}
                        urlPesquisa={"/vendas/regra-apuracao-comissao-venda/"}
                        responsiva
                        filtros={{
                            id: { value: "", matchMode: "equals" },
                            descricao: {
                                operator: "and",
                                constraints: [{ value: "", matchMode: "icontains" }],
                            },
                            usa_hierarquia: { value: "", matchMode: "equals" },
                            ativo: { value: true, matchMode: "equals" },
                        }}
                        configTabela={{
                            paginator: true,
                            lazy: true,
                            filterDisplay: "menu",
                        }}
                    />
                    <ConfirmDialog
                        visible={deleteDialog}
                        onHide={esconderDeleteDialog}
                        header="Confirmação"
                        message={
                            regra && (
                                <span>
                                    {"Deseja realmente excluir a regra de apuração "}
                                    <b>{regra.descricao}</b>?
                                </span>
                            )
                        }
                        icon="pi pi-info-circle p-mr-3"
                        accept={deleteRegra}
                        acceptLabel="Sim"
                        acceptClassName="p-button-danger"
                        reject={esconderDeleteDialog}
                        rejectLabel="Não"
                    />
                </div>
            </div>
        </div>
    );
};
