import React from "react";
import classNames from "classnames";
import { useHistory } from "react-router-dom";
import { InputText } from "primereact/inputtext";
import { Checkbox } from "primereact/checkbox";
import { Button } from "primereact/button";
import { useFormik } from "formik";
import * as Yup from "yup";

import { PageBase } from "@/components/PageBase";
import { CamposObrigatorios } from "@/components/CamposObrigatorios";
import { axiosPost, axiosPut } from "@/services/http";
import useLoading from "@/hooks/useLoading";
import useToast from "@/hooks/useToast";

const url = "/compras/categorias-ordens-compra/";

export const CategoriaOcForm = (props) => {
    const history = useHistory();
    const { showLoading, hideLoading } = useLoading();
    const { showSuccess, showError } = useToast();

    const categoriaVazia = {
        id: "",
        nome: "",
        requer_financeiro: true,
    };

    const formik = useFormik({
        initialValues: props.location && props.location.state ? props.location.state.categoria : categoriaVazia,
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                nome: Yup.string().required("O campo 'nome' é obrigatório."),
            });

            await formSchema.validate(values, {
                abortEarly: false,
            });

            if (!values.id) {
                showLoading();
                const resposta = await axiosPost(`${url}`, values);
                hideLoading();

                if (resposta.status === 201) {
                    showSuccess({
                        summary: "Sucesso",
                        detail: "Categoria cadastrada com sucesso!",
                        life: 1500,
                    });
                    setTimeout(() => {
                        voltar();
                    }, 2000);
                } else {
                    showError({
                        summary: "Erro",
                        detail: "Desculpe, não conseguimos processar a sua requisição.",
                        life: 1500,
                    });
                }
            } else {
                showLoading();
                const resposta = await axiosPut(`${url}${values.id}/`, values);
                hideLoading();
                if (resposta.status === 200) {
                    showSuccess({
                        summary: "Sucesso",
                        detail: "Categoria alterada com sucesso!",
                        life: 1500,
                    });
                    setTimeout(() => {
                        voltar();
                    }, 2000);
                } else {
                    showError({
                        summary: "Erro",
                        detail: "Desculpe, não conseguimos processar a sua requisição.",
                        life: 1500,
                    });
                }
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            } else {
                showError({
                    summary: "Erro",
                    detail: "Desculpe, não conseguimos processar a sua requisição.",
                    life: 1500,
                });
            }
        }
    }

    const voltar = () => {
        formik.resetForm();
        history.push("/compras/ordens-compra/categorias-ordens-compra");
    };

    return (
        <PageBase>
            <h3>
                {!formik.values.id
                    ? "Nova categoria de ordem de compras"
                    : "Manutenção de categoria de ordem de compras"}
            </h3>
            <form onSubmit={formik.handleSubmit}>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-9">
                        <label htmlFor="nome">Nome *</label>
                        <InputText
                            id="nome"
                            name="nome"
                            value={formik.values.nome}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.nome })}
                            autoComplete="off"
                            autoFocus
                        />
                        {formik.errors.nome && <small className="p-error">{formik.errors.nome}</small>}
                    </div>
                    <div className="p-field-checkbox p-col-12 p-md-3 p-mt-4">
                        <Checkbox
                            id="requer-financeiro"
                            name="requer_financeiro"
                            checked={formik.values.requer_financeiro}
                            onChange={formik.handleChange}
                        />
                        <label htmlFor="requer-financeiro">Requer financeiro?</label>
                    </div>
                </div>
                <CamposObrigatorios />
                <div className="p-grid p-col-12 p-md-6">
                    <Button label="Gravar" type="submit" className="p-button-info p-mr-2" />
                    <Button label="Cancelar" type="reset" className="p-button-danger p-mr-2" onClick={voltar} />
                </div>
            </form>
        </PageBase>
    );
};
