import React, { useCallback, useEffect, useImperativeHandle, useState, forwardRef } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Dropdown } from "primereact/dropdown";
import { TabView, TabPanel } from "primereact/tabview";
import { useFormik } from "formik";

import { BotaoAprovarNotificacao } from "../BotaoAprovarNotificacao";
import { BotaoEscalarNotificacao } from "../BotaoEscalarNotificacao";
import { BotaoReprovarNotificacao } from "../BotaoReprovarNotificacao";
import { FactoryResumo } from "../FactoryResumo";
import { MakoCalendar } from "@/components/MakoCalendar";
import { STATUS_NOTIFICACAO_CHOICE, TIPO_NOTIFICACAO_CHOICE } from "@/assets/constants/notificacao";
import { axiosPatch } from "@/services/http";
import useNotificacao from "@/hooks/useNotificacao";
import { MakoActionsButtons } from "@/components/MakoActionsButtons";

const Notificacoes = ({ onClose }, ref) => {
    const [visible, setVisible] = useState(null);
    const { marcarMsgComoLida } = useNotificacao();

    const { setValues, ...formik } = useFormik({
        initialValues: {
            emitente: null,
            descricao: "",
            papelperfil_destinatario: null,
            usuario_destinatario: null,
            datahora_criacao: new Date(),
            datahora_limite: null,
            tipo: null,
            comentario: "",
            status: null,
            notificacao_origem: null,
            executor: null,
            datahora_encerramento: null,
        },
    });

    const lerNotificacao = useCallback(async () => {
        const { status } = await axiosPatch(`/pessoas/notificacoes-perfil/${formik.values.id}/`, { lida: true });
        if (status === 200) marcarMsgComoLida();
    }, [marcarMsgComoLida, formik.values.id]);

    useEffect(() => lerNotificacao(), [lerNotificacao]);

    const abrirModal = (notificacao) => {
        if (notificacao) setValues(notificacao);
        setVisible(true);
    };

    const fecharModal = () => {
        if (typeof onClose === "function") onClose();
        setVisible(false);
    };

    useImperativeHandle(ref, () => ({ abrirModal }));

    const footer = (
        <MakoActionsButtons className="p-jc-end">
            <BotaoEscalarNotificacao notificacao={formik.values} />
            <BotaoReprovarNotificacao notificacao={formik.values} comentario={formik.values.comentario} />
            <BotaoAprovarNotificacao notificacao={formik.values} comentario={formik.values.comentario} />
        </MakoActionsButtons>
    );

    return (
        <>
            <Dialog
                header="Notificação"
                visible={visible}
                onHide={() => fecharModal()}
                style={{ width: "80vw" }}
                footer={footer}
            >
                <TabView>
                    <TabPanel header="Notificação" leftIcon="pi pi-fw pi-bell">
                        <form>
                            <div className="p-fluid p-formgrid p-grid">
                                <div className="p-field p-col-12 p-md-2">
                                    <label htmlFor="id">Protocolo</label>
                                    <InputText id="id" disabled value={formik.values.id || ""} />
                                </div>
                                <div className="p-field p-col-12 p-md-2">
                                    <label htmlFor="notificacao-origem">Protocolo pai</label>
                                    <InputText
                                        id="notificacao-origem"
                                        name="notificacao_origem"
                                        disabled
                                        value={formik.values.notificacao_origem}
                                    />
                                </div>
                                <div className="p-field p-col-12 p-md-4">
                                    <label htmlFor="emitente">Nome</label>
                                    <InputText id="emitente" disabled value={formik.values.nome} />
                                </div>
                                <div className="p-field p-col-12 p-md-4">
                                    <label htmlFor="emitente">Emitente</label>
                                    <InputText id="emitente" disabled value={formik.values.emitente?.nome} />
                                </div>
                            </div>
                            <div className="p-fluid p-formgrid p-grid">
                                <div className="p-field p-col-12">
                                    <label htmlFor="descricao">Descrição</label>
                                    <InputTextarea
                                        id="descricao"
                                        name="descricao"
                                        disabled
                                        rows={5}
                                        autoComplete="off"
                                        autoResize
                                        value={formik.values.descricao}
                                    />
                                </div>
                            </div>
                            <div className="p-fluid p-formgrid p-grid">
                                <div className="p-field p-col-12 p-md-3">
                                    <label htmlFor="papel-perfil">Papel perfil</label>
                                    <InputText id="papel-perfil" name="papelperfil_destinatario" disabled />
                                </div>
                                <div className="p-field p-col-12 p-md-3">
                                    <label htmlFor="usuario-destino">Usuário destino</label>
                                    <InputText
                                        id="usuario-destino"
                                        name="usuario_destinatario"
                                        disabled
                                        value={formik.values.usuario_destinatario?.usuario.username}
                                    />
                                </div>
                                <div className="p-field p-col-12 p-md-3">
                                    <label htmlFor="datahora-criacao">Data/hora da criação</label>
                                    <MakoCalendar
                                        id="datahora-criacao"
                                        name="datahora_criacao"
                                        disabled
                                        showTime
                                        valueCalendar={formik.values.datahora_criacao}
                                    />
                                </div>
                                <div className="p-field p-col-12 p-md-3">
                                    <label htmlFor="datahora-limite">Data/hora limite</label>
                                    <MakoCalendar
                                        id="datahora-limite"
                                        name="datahora_limite"
                                        disabled
                                        showTime
                                        valueCalendar={formik.values.datahora_limite}
                                    />
                                </div>
                            </div>
                            <div className="p-fluid p-formgrid p-grid">
                                <div className="p-field p-col-12 p-md-3">
                                    <label htmlFor="tipo">Tipo</label>
                                    <Dropdown
                                        id="tipo"
                                        name="tipo"
                                        disabled
                                        placeholder="Selecione"
                                        options={TIPO_NOTIFICACAO_CHOICE}
                                        value={formik.values.tipo}
                                    />
                                </div>
                                <div className="p-field p-col-12 p-md-3">
                                    <label htmlFor="status">Status</label>
                                    <Dropdown
                                        id="status"
                                        name="status"
                                        disabled
                                        placeholder="Selecione"
                                        options={STATUS_NOTIFICACAO_CHOICE}
                                        value={formik.values.status}
                                    />
                                </div>
                                <div className="p-field p-col-12 p-md-3">
                                    <label htmlFor="usuario-encerramento">Usuário que encerrou</label>
                                    <InputText
                                        id="usuario-encerramento"
                                        name="usuario_encerramento"
                                        disabled
                                        value={formik.values.executor?.nome}
                                    />
                                </div>
                                <div className="p-field p-col-12 p-md-3">
                                    <label htmlFor="datahora-encerramento">Data/hora encerramento</label>
                                    <MakoCalendar
                                        id="datahora-encerramento"
                                        name="datahora_encerramento"
                                        disabled
                                        showTime
                                        valueCalendar={formik.values.datahora_encerramento}
                                    />
                                </div>
                            </div>
                            <div className="p-fluid p-formgrid p-grid">
                                <div className="p-field p-col-12">
                                    <label htmlFor="comentario">Comentário</label>
                                    <InputTextarea
                                        id="comentario"
                                        name="comentario"
                                        rows={5}
                                        disabled={formik.values.status === "E" || formik.values.status === "R"}
                                        autoComplete="off"
                                        autoResize
                                        value={formik.values.comentario}
                                        onChange={formik.handleChange}
                                    />
                                </div>
                            </div>
                        </form>
                    </TabPanel>
                    <TabPanel header="Resumo" leftIcon="pi pi-fw pi-align-justify" disabled={!formik.values.resumo}>
                        <FactoryResumo notificacao={formik.values} />
                    </TabPanel>
                </TabView>
            </Dialog>
        </>
    );
};

export const ModalNotificacoes = forwardRef(Notificacoes);
