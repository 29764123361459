import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useState } from "react";

import { InputTextarea } from "primereact/inputtextarea";
import { InputNumber } from "primereact/inputnumber";
import { InputText } from "primereact/inputtext";
import { Checkbox } from "primereact/checkbox";
import { Dialog } from "primereact/dialog";
import { Image } from "primereact/image";
import { Panel } from "primereact/panel";
import { Tag } from "primereact/tag";

import { MakoInputQuantidadeSku } from "../MakoInputs/MakoInputQuantidadeSku";
import { MakoCalendar } from "../MakoCalendar";
import { MakoButton } from "../MakoButton";
import { Dropdown } from "../Dropdown";
import { Skeleton } from "./skeleton";
import { Label } from "../Label";
import MakoListagem from "../MakoListagem";

import {
    PRODUTOS_ESTOQUE_CADASTRO_SALDOESTOQUEFORMULA_TODAS,
    PRODUTOS_SKU_GAVETA,
} from "@/assets/constants/parametros";
import { TIPO_ORIGEM_NF_CHOICE } from "@/assets/constants/constants";
import { MAKO_ICONS } from "@/assets/constants/constants_styles";
import { dataToStr } from "@/assets/util/datas";
import { addDays } from "date-fns";
import useEmpresa from "@/hooks/useEmpresa";
import useParam from "@/hooks/useParam";
import useHttp from "@/hooks/useHttp";

const BASE_DATA = addDays(new Date(), 8);

export const MakoVisualizarSku = forwardRef(({ id, buscarPor = "sku" }, ref) => {
    const [visible, setVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const [loadingSaldo, setLoadingSaldo] = useState(false);
    const [error, setError] = useState(false);
    const [dados, setDados] = useState({});
    const [saldo, setSaldo] = useState({ data: BASE_DATA, formula: null, saldo: 0 });
    const [tipo, setTipo] = useState({ id, buscarPor });
    const { empresaSelecionadaId } = useEmpresa();
    const { httpGet } = useHttp();
    const { getParam } = useParam();

    const showModal = ({ id = null, buscarPor = "sku" }) => {
        if (id && id !== tipo.id) setTipo({ id, buscarPor });
        setVisible(true);
    };

    const hiddenModal = () => {
        setVisible(false);
    };

    useImperativeHandle(ref, () => ({ show: showModal, hidden: hiddenModal }));

    const fetchDados = useCallback(
        async (signal) => {
            setError(false);
            const params = {
                id: tipo.id,
                tipo: tipo.buscarPor,
                mostrar_precos: false,
                mostrar_unidades: false,
            };

            const handlers = {
                200: ({ data }) => {
                    setDados(data.dados);
                },
                400: () => setError(true),
                500: () => setError(true),
            };

            setLoading(true);
            await httpGet({ url: "/produtos/visualizar-dados-sku/", signal, params }, handlers);
            setLoading(false);
        },
        [setLoading, setError, httpGet, tipo]
    );

    const fetchSaldo = useCallback(async () => {
        if (saldo.formula && saldo.data && dados?.id) {
            const handlers = {
                200: ({ data }) => {
                    setSaldo((prev) => ({ ...prev, saldo: data.saldo }));
                },
            };

            setLoadingSaldo(true);
            await httpGet(
                {
                    url: `/produtos/visualizar-sku-saldo-formula/${dados?.id}/?formula=${
                        saldo.formula
                    }&data=${dataToStr(saldo.data, "yyyy-MM-dd")}`,
                },
                handlers
            );
            setLoadingSaldo(false);
        }
    }, [saldo, dados, httpGet, setSaldo, setLoadingSaldo]);

    const header = useMemo(() => {
        return loading
            ? "Visualização de produto: buscando informações..."
            : `Visualização de produto: ${dados?.codigo} - ${dados.descricao_reduzida}`;
    }, [loading, dados]);

    const statusBodyTemplate = (status) => {
        if (status) return <Tag severity="success" value="ATIVO" />;
        return <Tag severity="danger" value="DESATIVADO" />;
    };

    const imageBodyTemplate = (rowData) => {
        return (
            <Image
                imageClassName="product-image"
                src={rowData.imagem}
                alt={rowData.descricao}
                onError={(e) =>
                    (e.target.src = "https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png")
                }
                width="80"
                height="80"
                preview
                downloadable
            />
        );
    };

    const colunasUN = [
        { field: "unidade.nome", header: "Unidade de medida" },
        { field: "tipo_mov_und_medida.descricao", header: "Tipo de movimentação", style: { minWidth: "20%" } },
        {
            field: "ativo",
            header: "Situação",
            align: "center",
            action: (e) => statusBodyTemplate(e.ativo),
        },
    ];

    const colunasImagem = [
        { field: "descricao", header: "Descrição", style: { width: "20%" } },
        { field: "imagem", header: "Imagem", style: { width: "25%" }, action: (e) => imageBodyTemplate(e) },
        { field: "galeria.nome", header: "Galeria", style: { width: "15%" } },
    ];

    const urlFormula = useMemo(() => {
        const BASE_URL = "/produtos/saldo-estoque-formula/?limit=1000&ativa=true";
        const parametro = getParam(PRODUTOS_ESTOQUE_CADASTRO_SALDOESTOQUEFORMULA_TODAS);
        if (parametro) return parametro.valor !== "1" ? `${BASE_URL}&empresa=${empresaSelecionadaId}` : BASE_URL;
        return `${BASE_URL}&empresa=${empresaSelecionadaId}`;
    }, [getParam, empresaSelecionadaId]);

    useEffect(() => {
        const controler = new AbortController();
        fetchDados(controler.signal);
        return () => controler.abort();
    }, [fetchDados]);

    return (
        <Dialog
            header={header}
            visible={visible}
            breakpoints={{ "960px": "75vw" }}
            style={{ width: "65vw" }}
            onHide={hiddenModal}
        >
            <Skeleton loading={loading}>
                {error ? (
                    <span className="p-error">Desculpa não conseguimos listar as informações do produto</span>
                ) : (
                    <div>
                        <div>
                            <div className="p-fluid p-formgrid p-grid ">
                                <div className="p-field p-col-12 p-md-4">
                                    <label htmlFor="descricao_template">Descrição dinâmica</label>
                                    <InputText
                                        id="descricao_template"
                                        name="descricao_template"
                                        value={dados?.descricao_template?.descricao}
                                        disabled
                                    />
                                </div>
                                <div className="p-field p-col-12 p-md-4">
                                    <label htmlFor="modelo">Modelo</label>
                                    <InputText
                                        id="modelo"
                                        name="modelo"
                                        optionValue="id"
                                        optionLabel="nome"
                                        value={dados?.modelo?.nome}
                                        disabled
                                    />
                                </div>
                                <div className="p-field p-col-12 p-md-4">
                                    <label htmlFor="comissao_venda">Comissão de venda</label>
                                    <InputText
                                        id="comissao_venda"
                                        name="comissao_venda"
                                        value={dados?.comissao_venda?.descricao}
                                        disabled
                                    />
                                </div>
                            </div>
                            <div className="p-fluid p-formgrid p-grid ">
                                <div className="p-field p-col-12 p-md-2">
                                    <label htmlFor="codigo">Código</label>
                                    <InputText
                                        id="codigo"
                                        name="codigo"
                                        autoComplete="off"
                                        value={dados?.codigo}
                                        disabled
                                    />
                                </div>
                                <div className="p-field p-col-12 p-md-10">
                                    <label htmlFor="descricao_complementar">Descrição complementar</label>
                                    <div className="p-inputgroup">
                                        <InputText
                                            id="descricao_complementar"
                                            name="descricao_complementar"
                                            autoComplete="off"
                                            value={dados?.descricao_complementar}
                                            disabled
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="p-fluid p-formgrid p-grid ">
                                <div className="p-field p-col-12 p-md-6">
                                    <label htmlFor="descricao_reduzida">Descrição reduzida</label>
                                    <InputText
                                        id="descricao_reduzida"
                                        name="descricao_reduzida"
                                        autoComplete="off"
                                        value={dados?.descricao_reduzida}
                                        disabled
                                    />
                                </div>
                                <div className="p-field p-col-12 p-md-6">
                                    <label htmlFor="sku_controle_saldo">Movimenta saldo de</label>
                                    <InputText
                                        id="sku_controle_saldo"
                                        name="sku_controle_saldo"
                                        value={`${dados?.sku_controle_saldo?.codigo} - ${dados?.sku_controle_saldo?.descricao_derivada}`}
                                        disabled
                                    />
                                </div>
                            </div>
                            <div className="p-fluid p-formgrid p-grid ">
                                <div className="p-field p-col-12 p-md-6">
                                    <label htmlFor="unidade_padrao">Unidade padrão</label>
                                    <InputText
                                        id="unidade_padrao"
                                        name="unidade_padrao"
                                        value={dados?.unidade_padrao?.nome}
                                        disabled
                                    />
                                </div>
                                <div className="p-field p-col-12 p-md-3">
                                    <label htmlFor="peso_bruto">Peso bruto (kg)</label>
                                    <InputNumber
                                        id="peso_bruto"
                                        name="peso_bruto"
                                        mode="decimal"
                                        min={0}
                                        suffix=" kg"
                                        minFractionDigits={1}
                                        maxFractionDigits={3}
                                        value={dados?.peso_bruto}
                                        disabled
                                    />
                                </div>
                                <div className="p-field p-col-12 p-md-3">
                                    <label htmlFor="peso_liquido">Peso líquido (kg)</label>
                                    <InputNumber
                                        id="peso_liquido"
                                        name="peso_liquido"
                                        mode="decimal"
                                        min={0}
                                        suffix=" kg"
                                        minFractionDigits={1}
                                        maxFractionDigits={3}
                                        value={dados?.peso_liquido}
                                        disabled
                                    />
                                </div>
                            </div>
                            <div className="p-fluid p-formgrid p-grid ">
                                <div className="p-field p-col-12 p-md-12">
                                    <label htmlFor="origem">Origem</label>
                                    <div className="p-inputgroup">
                                        <Dropdown
                                            id="origem"
                                            name="origem"
                                            placeholder="Selecione uma origem"
                                            optionValue="id"
                                            optionLabel="label"
                                            options={TIPO_ORIGEM_NF_CHOICE}
                                            value={dados?.origem}
                                            disabled
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="p-fluid p-formgrid p-grid ">
                                <div className="p-field p-col-12 p-md-12">
                                    <label htmlFor="caracteristicas">Características</label>
                                    <InputTextarea
                                        id="caracteristicas"
                                        name="caracteristicas"
                                        rows={5}
                                        autoComplete="off"
                                        autoResize
                                        disabled
                                        value={dados?.caracteristicas}
                                    />
                                </div>
                            </div>
                            <div className="p-fluid p-formgrid p-grid ">
                                <div className="p-field p-col-12 p-md-3">
                                    <label htmlFor="qtd_min_venda">Quantidade mínima</label>
                                    <InputNumber
                                        id="qtd_min_venda"
                                        name="qtd_min_venda"
                                        min={0}
                                        value={dados?.qtd_min_venda}
                                        disabled
                                    />
                                </div>
                                <div className="p-field p-col-12 p-md-3">
                                    <label htmlFor="qtd_volumes">Quantidade volumes</label>
                                    <InputNumber
                                        id="qtd_volumes"
                                        name="qtd_volumes"
                                        min={0}
                                        value={dados?.qtd_volumes}
                                        disabled
                                    />
                                </div>
                                <div className="p-field p-col-12 p-md-3">
                                    <label htmlFor="dias_garantia">Dias Garantia</label>
                                    <InputNumber
                                        id="dias_garantia"
                                        name="dias_garantia"
                                        value={dados?.dias_garantia}
                                        disabled
                                    />
                                </div>
                                <div className="p-field p-col-12 p-md-3">
                                    <label htmlFor="intervalo_compra_chegada">Intervalo compra/chegada</label>
                                    <InputNumber
                                        id="intervalo_compra_chegada"
                                        name="intervalo_compra_chegada"
                                        value={dados?.intervalo_compra_chegada}
                                        disabled
                                    />
                                </div>
                            </div>
                            <div className="p-fluid p-formgrid p-grid ">
                                <div className="p-field p-col-12 p-md-6">
                                    <label htmlFor="gaveta">{getParam(PRODUTOS_SKU_GAVETA)?.valor || "Gaveta"}</label>
                                    <InputText id="gaveta" name="gaveta" value={dados?.gaveta} disabled />
                                </div>
                                <div className="p-field p-col-12 p-md-6">
                                    <label htmlFor="controle_fci">Controle FCI</label>
                                    <InputText
                                        id="controle_fci"
                                        name="controle_fci"
                                        value={dados?.controle_fci}
                                        disabled
                                    />
                                </div>
                            </div>
                            <div className="p-fluid p-formgrid p-grid ">
                                <div className="p-field p-col-12 p-md-5">
                                    <label htmlFor="formacao_preco">Formação de preço</label>
                                    <InputText
                                        id="formacao_preco"
                                        name="formacao_preco"
                                        value={dados?.formacao_preco?.descricao}
                                        disabled
                                    />
                                </div>
                                <div className="p-field-checkbox p-col-12 p-md-3">
                                    <br></br>
                                    <Checkbox
                                        inputId="permite_fracionamento"
                                        id="permite_fracionamento"
                                        name="permite_fracionamento"
                                        value={dados?.permite_fracionamento}
                                        disabled
                                        className="p-mt-5"
                                    />
                                    <label htmlFor="permite_fracionamento" className="p-mt-5">
                                        <b>Permite fracionamento</b>
                                    </label>
                                </div>
                                <div className="p-field-checkbox p-col-12 p-md-2">
                                    <br></br>
                                    <Checkbox
                                        inputId="e_commerce"
                                        id="e_commerce"
                                        name="e_commerce"
                                        value={dados?.e_commerce}
                                        className="p-mt-5"
                                        disabled
                                    />
                                    <label htmlFor="e_commerce" className="p-mt-5">
                                        <b>E-commerce</b>
                                    </label>
                                </div>
                                <div className="p-field-checkbox p-col-12 p-md-2">
                                    <br></br>
                                    <Checkbox
                                        inputId="composto"
                                        id="composto"
                                        name="composto"
                                        value={dados?.composto}
                                        className="p-mt-5"
                                        disabled
                                    />
                                    <label htmlFor="composto" className="p-mt-5">
                                        <b>Composto</b>
                                    </label>
                                </div>
                            </div>
                            <div className="p-fluid p-formgrid p-grid ">
                                <div className="p-field p-col-12 p-md-12">
                                    <label htmlFor="observacoes">Observações</label>
                                    <InputTextarea
                                        id="observacoes"
                                        name="observacoes"
                                        rows={3}
                                        value={dados?.observacoes}
                                        disabled
                                        autoResize
                                    />
                                </div>
                            </div>
                        </div>
                        <Panel header="Unidades de medida" toggleable collapsed className="p-my-1">
                            <MakoListagem
                                colunas={colunasUN}
                                urlPesquisa={`/produtos/unidades-medida-sku?sku__id=${dados?.id}`}
                                fazerBusca={!!dados?.id}
                                configTabela={{
                                    scrollable: true,
                                    paginator: true,
                                    lazy: true,
                                }}
                            />
                        </Panel>
                        <Panel header="Imagens" toggleable collapsed className="p-my-1">
                            <MakoListagem
                                colunas={colunasImagem}
                                urlPesquisa={`/produtos/imagens-sku?sku__id=${dados?.id}`}
                                fazerBusca={!!dados?.id}
                                configTabela={{
                                    scrollable: true,
                                    paginator: true,
                                    lazy: true,
                                }}
                            />
                        </Panel>
                        <Panel header="Saldo" toggleable collapsed className="p-my-1">
                            <div className="p-fluid p-formgrid p-grid">
                                <div className="p-field p-col-12 p-md-7">
                                    <Label obrigatorio htmlFor="formula" label="Formula" />
                                    <Dropdown
                                        id="formula"
                                        name="formula"
                                        placeholder="Selecione uma formula"
                                        url={urlFormula}
                                        value={saldo.formula}
                                        onChange={({ value }) => setSaldo((prev) => ({ ...prev, formula: value }))}
                                        optionValue="id"
                                        optionLabel="descricao"
                                        buscar={!!empresaSelecionadaId || loadingSaldo}
                                        className="p-mb-2"
                                        style={{ width: "100%" }}
                                    />
                                </div>
                                <div className="p-field p-col-12 p-md-2">
                                    <Label obrigatorio htmlFor="data" label="Data" />
                                    <MakoCalendar
                                        id="data"
                                        name="data"
                                        valueCalendar={saldo.data}
                                        disabled={loadingSaldo}
                                        onChange={({ target }) => setSaldo((prev) => ({ ...prev, data: target.value }))}
                                    />
                                </div>
                                <div className="p-field p-col-12 p-md-3">
                                    <Label htmlFor="saldo" label="Saldo" />
                                    <div className="p-inputgroup">
                                        <MakoButton
                                            type="button"
                                            onClick={fetchSaldo}
                                            tooltip="Consultar saldo"
                                            icon={MAKO_ICONS.PESQUISAR}
                                            loading={loadingSaldo}
                                            disabled={!saldo?.formula || !saldo?.data}
                                        />
                                        <MakoInputQuantidadeSku id="saldo" name="saldo" value={saldo.saldo} disabled />
                                    </div>
                                </div>
                            </div>
                        </Panel>
                    </div>
                )}
            </Skeleton>
        </Dialog>
    );
});
