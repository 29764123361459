import React, { useCallback } from "react";
import { Button } from "primereact/button";
import { useFormik } from "formik";
import { useHistory } from "react-router-dom";
import { axiosPatch, axiosPost } from "@/services/http";
import { PageBase } from "@/components/PageBase";
import { MakoCalendar } from "@/components/MakoCalendar";
import { MakoCalendarTime } from "@/components/MakoCalendarTime";
import { Dropdown } from "@/components/Dropdown";
import useLoading from "@/hooks/useLoading";
import classNames from "classnames";
import * as Yup from "yup";
import { InputText } from "primereact/inputtext";
import { TIPO_REQUISICAO_TRANSF } from "@/assets/constants/estoque";
import useAuth from "@/hooks/useAuth";
import { Tag } from "primereact/tag";
import { MakoBuscaSkuPersonalizada } from "@/components/MakoBuscaSkuPersonalizada";
import { Divider } from "primereact/divider";
import useFormatCNPJCPF from "@/hooks/useFomatCNPJCPF";
import { dataToStr } from "@/assets/util/datas";
import useToast from "@/hooks/useToast";
import { InputNumber } from "primereact/inputnumber";
import useEmpresa from "@/hooks/useEmpresa";

export const RequisicoesTransferenciaForm = (props) => {
    const { showLoading, hideLoading } = useLoading();
    const { user } = useAuth();
    const { empresaSelecionadaId } = useEmpresa();
    const history = useHistory();
    const [formatarDocumento] = useFormatCNPJCPF();
    const { showError, showSuccess } = useToast();

    const { setFieldValue, ...formik } = useFormik({
        initialValues: props.location.state
            ? props.location.state
            : {
                  id: null,
                  ce_origem: null,
                  ce_destino: null,
                  lote: null,
                  tipo_requisicao: "E",
                  requerente: user,
                  unidade_medida: null,
                  operacao_fiscal: null,
                  sku: null,
                  data_limite: null,
                  quantidade: 0,
                  data_hora: new Date(),
                  requisicao_original: "",
                  justificativa: "",
                  situacao: "P",
              },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                ce_origem: Yup.number().required("O campo 'centro de origem' é obrigatório."),
                ce_destino: Yup.number().required("O campo 'centro de destino' é obrigatório."),
                lote: Yup.string().max(8, "O tamanho máximo de digitos é 8.").nullable(),
                tipo_requisicao: Yup.string().required("O campo 'tipo de requisição' é obrigatório."),
                justificativa: Yup.string().max(255, "O limite de caractéres da justificativa é de 255.").nullable(),
                requerente: Yup.object().required("O campo 'requerente' é obrigatório."),
                data_hora: Yup.date().required("O campo 'data/hora' é obrigatório."),
                quantidade: Yup.number().required("O campo 'quantidade' é obrigatório."),
                sku: Yup.object().required("O campo 'produto' é obrigatório."),
                data_limite: Yup.date().required("O campo 'data limite' é obrigatório."),
                unidade_medida: Yup.number().required("O campo 'unidade' é obrigatório."),
            });

            await formSchema.validate(values, {
                abortEarly: false,
            });

            if (!values.id) {
                showLoading();
                const { status } = await axiosPost(`/transferencias/requisicao-transferencia/`, {
                    ...values,
                    data_limite: dataToStr(values.data_limite, "yyyy-MM-dd"),
                    requerente: values.requerente.id,
                    sku: values.sku.id,
                });
                hideLoading();

                if (status === 201) {
                    showSuccess({
                        summary: "Sucesso",
                        detail: "Requisição de transferência cadastrada com sucesso!",
                        life: 1500,
                    });

                    setTimeout(() => {
                        formik.resetForm();
                        history.push("/estoque/movimentacoes/requisicoes-transferencia");
                    }, 2000);
                } else {
                    showError({
                        summary: "Erro",
                        detail: "Desculpe, não conseguimos processar a sua requisição.",
                        life: 1500,
                    });
                }
            } else {
                showLoading();
                const { status } = await axiosPatch(`/transferencias/requisicao-transferencia/${values.id}/`, {
                    ...values,
                    data_limite: dataToStr(values.data_limite, "yyyy-MM-dd"),
                    requerente: values.requerente.id,
                    sku: values.sku.id,
                });
                hideLoading();

                if (status === 200) {
                    showSuccess({
                        summary: "Sucesso",
                        detail: "Requisição de transferência alterada com sucesso!",
                        life: 1500,
                    });

                    setTimeout(() => {
                        formik.resetForm();
                        history.push("/estoque/movimentacoes/requisicoes-transferencia");
                    }, 2000);
                } else {
                    showError({
                        summary: "Erro",
                        detail: "Desculpe, não conseguimos processar a sua requisição.",
                        life: 1500,
                    });
                }
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
                hideLoading();
            } else {
                showError({
                    summary: "Erro",
                    detail: "Desculpe, não conseguimos processar a sua requisição.",
                    life: 1500,
                });
                hideLoading();
            }
        }
    }

    const cancelar = () => {
        formik.resetForm();
        history.push("/estoque/movimentacoes/requisicoes-transferencia");
    };

    const onChangeDimensao = useCallback(
        (e) => {
            setFieldValue("cubagem", e);
        },
        [setFieldValue]
    );

    const formatarUnidades = (results) => {
        return results.map((e) => e.unidade);
    };

    const itemTemplate = ({ estoque_empresa, nome }) => {
        return `${estoque_empresa.descricao} - ${nome} (${estoque_empresa.empresa.nome}:${formatarDocumento(
            estoque_empresa.empresa.identificacao
        )})`;
    };

    const valueTemplate = (centro) => {
        if (centro)
            return `${centro.estoque_empresa.descricao} - ${centro.nome} (${
                centro.estoque_empresa.empresa.nome
            }:${formatarDocumento(centro.estoque_empresa.empresa.identificacao)})`;
    };

    return (
        <PageBase>
            <h3>
                {!formik.values.id ? "Incluindo Requisição de Transferência" : "Editando Requisição de Transferência"}
            </h3>
            <form onSubmit={formik.handleSubmit}>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-5">
                        <label htmlFor="ce_origem">Centro de Estocagem Origem *</label>
                        <Dropdown
                            id="ce_origem"
                            name="ce_origem"
                            placeholder="Selecione um centro de estocagem"
                            url="/produtos/centros-estocagem?limit=1000"
                            optionValue="id"
                            optionLabel="nome"
                            style={{ height: "58%" }}
                            itemTemplate={itemTemplate}
                            valueTemplate={valueTemplate}
                            filter
                            filterBy="id,nome,descricao"
                            value={formik.values.ce_origem}
                            onChange={(e) => setFieldValue("ce_origem", e.target.value)}
                            className={classNames({ "p-invalid": formik.errors.data })}
                            disabled={props.location.visualizar}
                        />
                        {formik.errors.ce_origem && <small className="p-error">{formik.errors.ce_origem}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-5">
                        <label htmlFor="ce_destino">Centro de Estocagem Destino *</label>
                        <Dropdown
                            id="ce_destino"
                            name="ce_destino"
                            placeholder="Selecione um centro de estocagem"
                            url="/produtos/centros-estocagem?limit=1000"
                            optionValue="id"
                            optionLabel="nome"
                            style={{ height: "58%" }}
                            itemTemplate={itemTemplate}
                            valueTemplate={valueTemplate}
                            filter
                            filterBy="id,nome,descricao"
                            value={formik.values.ce_destino}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.data })}
                            disabled={props.location.visualizar}
                        />
                        {formik.errors.ce_destino && <small className="p-error">{formik.errors.ce_destino}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="lote">Lote</label>
                        <InputText
                            id="lote"
                            name="lote"
                            keyfilter="num"
                            value={formik.values.lote}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.lote })}
                            disabled={props.location.visualizar}
                        />
                        {formik.errors.lote && <small className="p-error">{formik.errors.lote}</small>}
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="tipo_requisicao">Tipo de requisição *</label>
                        <Dropdown
                            id="tipo_requisicao"
                            name="tipo_requisicao"
                            placeholder="Selecione..."
                            options={TIPO_REQUISICAO_TRANSF}
                            value={formik.values.tipo_requisicao}
                            showClear={false}
                            optionDisabled={(e) => e.value === "V"}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.tipo_requisicao })}
                            disabled={props.location.visualizar}
                        />
                        {formik.errors.tipo_requisicao && (
                            <small className="p-error">{formik.errors.tipo_requisicao}</small>
                        )}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="data-hora">Data/hora requisição *</label>
                        <MakoCalendarTime
                            id="data-hora"
                            name="data_hora"
                            valueCalendar={formik.values.data_hora}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.data_hora })}
                            disabled={props.location.visualizar}
                        />
                        {formik.errors.data_hora && <small className="p-error">{formik.errors.data_hora}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-6">
                        <label htmlFor="requerente">Nome do requerente</label>
                        <InputText
                            id="requerente"
                            name="requerente"
                            keyfilter="text"
                            value={formik.values.requerente?.nome}
                            disabled
                        />
                    </div>
                </div>
                {formik.values.venda ? (
                    <div>
                        <Divider>Venda</Divider>
                        <div className="p-fluid p-formgrid p-grid">
                            <div className="p-field p-col-12 p-md-3">
                                <label htmlFor="n-venda">Número da venda</label>
                                <InputText
                                    id="n-venda"
                                    name="id"
                                    disabled
                                    value={formik.values.venda?.id}
                                    onChange={formik.handleChange}
                                />
                            </div>
                            <div className="p-field p-col-12 p-md-6">
                                <label htmlFor="nome-cliente">Nome do cliente</label>
                                <InputText
                                    id="nome-cliente"
                                    name="id"
                                    disabled
                                    value={formik.values.venda?.cliente?.nome}
                                    onChange={formik.handleChange}
                                />
                            </div>
                            <div className="p-field p-col-12 p-md-3">
                                <label htmlFor="identificacao-cliente">CPF/CNPJ</label>
                                <InputText
                                    id="identificacao-cliente"
                                    name="id"
                                    disabled
                                    value={formatarDocumento(formik.values.venda?.cliente?.identificacao)}
                                    onChange={formik.handleChange}
                                />
                            </div>
                            <div className="p-field p-col-12 p-md-5">
                                <label htmlFor="loja-venda">Entidade/Loja que vendeu</label>
                                <InputText
                                    id="loja-venda"
                                    name="id"
                                    disabled
                                    value={formik.values.venda?.empresa?.nome}
                                    onChange={formik.handleChange}
                                />
                            </div>
                            <div className="p-field p-col-12 p-md-3">
                                <label htmlFor="item-entrega">Item da entrega</label>
                                <InputText
                                    id="item-entrega"
                                    name="id"
                                    disabled
                                    value={formik.values.item_entrega}
                                    onChange={formik.handleChange}
                                />
                            </div>
                        </div>
                        <Divider />
                    </div>
                ) : null}
                <MakoBuscaSkuPersonalizada
                    statusItem="F"
                    skuValue={formik.values.sku}
                    skuChange={(e) => setFieldValue("sku", e)}
                    skuError={formik.errors.sku}
                    exibeDimensoes
                    alturaValue={formik.values.altura}
                    onChangeAltura={(e) => setFieldValue("altura", e)}
                    larguraValue={formik.values.largura}
                    onChangeLargura={(e) => setFieldValue("largura", e)}
                    comprimentoValue={formik.values.comprimento}
                    onChangeComprimento={(e) => setFieldValue("comprimento", e)}
                    dimensaoValue={formik.values.cubagem}
                    onChangeDimensao={onChangeDimensao}
                    disabledBusca={props.location.visualizar}
                />
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="unidade_medida">Unidade de medida *</label>
                        <Dropdown
                            id="unidade_medida"
                            name="unidade_medida"
                            url={`/produtos/unidades-medida-sku?sku=${formik.values.sku?.id}&tipo_mov_und_medida=T`}
                            optionValue="id"
                            optionLabel="nome"
                            aposBuscar={formatarUnidades}
                            value={formik.values.unidade_medida}
                            onChange={formik.handleChange}
                            disabled={!formik.values.sku?.id}
                            className={classNames({ "p-invalid": formik.errors.unidade_medida })}
                        />
                        {formik.errors.unidade_medida && (
                            <small className="p-error">{formik.errors.unidade_medida}</small>
                        )}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="quantidade">Quantidade *</label>
                        <InputNumber
                            id="quantidade"
                            name="quantidade"
                            mode="decimal"
                            value={formik.values.quantidade}
                            onValueChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.quantidade })}
                        />
                        {formik.errors.quantidade && <small className="p-error">{formik.errors.quantidade}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="data_limite">Data limite *</label>
                        <MakoCalendar
                            id="data_limite"
                            name="data_limite"
                            valueCalendar={formik.values.data_limite}
                            onChange={formik.handleChange}
                            className={classNames({
                                "p-invalid": formik.errors.data_limite,
                            })}
                            disabled={props.location.visualizar}
                        />
                        {formik.errors.data_limite && <small className="p-error">{formik.errors.data_limite}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="requisicao_original">Requisição de origem</label>
                        <InputText
                            id="requisicao_original"
                            name="requisicao_original"
                            keyfilter="text"
                            value={formik.values.requisicao_original}
                            disabled
                        />
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="operacao_fiscal">Operação fiscal</label>
                        <Dropdown
                            id="operacao_fiscal"
                            name="operacao_fiscal"
                            placeholder="Selecione uma operação fiscal"
                            url={`/fiscal/operacoes-fiscais?limit=1000&empresa=${empresaSelecionadaId}`}
                            filter
                            filterBy="id,descricao"
                            optionValue="id"
                            optionLabel="descricao"
                            value={formik.values.operacao_fiscal}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.operacao_fiscal })}
                        />
                        {formik.errors.operacao_fiscal && (
                            <small className="p-error">{formik.errors.operacao_fiscal}</small>
                        )}
                    </div>
                    <div className="p-field p-col-12 p-md-7">
                        <label htmlFor="justificativa">Justificativa</label>
                        <InputText
                            id="justificativa"
                            name="justificativa"
                            value={formik.values.justificativa}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.lote })}
                            disabled={props.location.visualizar}
                        />
                        {formik.errors.lote && <small className="p-error">{formik.errors.lote}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-1">
                        <label htmlFor="situacao">Situação</label>
                        <br />
                        <Tag severity="info" value="Pendente" className="p-mt-2" style={{ width: "100px" }} />
                    </div>
                </div>
                <p>
                    <b>* Campos obrigatórios.</b>
                </p>
                <div className="p-grid p-col-12 p-md-3">
                    {props.location.visualizar ? null : (
                        <Button label="Gravar" type="submit" className="p-button-info p-mr-2 p-mb-6" />
                    )}
                    <Button
                        label="Cancelar"
                        type="reset"
                        className="p-button-danger p-mr-2 p-mb-6"
                        onClick={cancelar}
                    />
                </div>
            </form>
        </PageBase>
    );
};
