import React, { useRef } from "react";
import { useHistory } from "react-router-dom";
import { InputNumber } from "primereact/inputnumber";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { useFormik } from "formik";

import MakoListagem from "@/components/MakoListagem";
import { parseNumberToMoneyHTML } from "@/assets/util/util";
import { axiosPost, axiosPut, axiosDelete } from "@/services/http";
import useLoading from "@/hooks/useLoading";

export const DetalheRegraArredondamentoValorForm = ({ regraArredondamentoValor }) => {
    const listagemRef = useRef(null);
    const toastRef = useRef(null);
    const history = useHistory();
    const { showLoading, hideLoading } = useLoading();

    const formik = useFormik({
        initialValues: {
            decimal: 0,
            inteiro: 0,
        },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        const detalhe = {
            regra_arredondamento: regraArredondamentoValor.id,
            inteiro: values.inteiro === 0 ? null : values.inteiro,
            decimal: values.decimal / 100,
        };

        if (!values.id) {
            showLoading();
            const resposta = await axiosPost("/vendas/regra-arredondamento-valor-detalhe/", detalhe);
            hideLoading();

            if (resposta.status === 201) {
                toastRef.current.show({
                    severity: "success",
                    summary: "Sucesso",
                    detail: "Detalhe de arredondamento de preço cadastrado com sucesso!",
                    life: 1500,
                });

                formik.resetForm();
                listagemRef.current?.buscarDados();
            } else {
                toastRef.current.show({
                    severity: "error",
                    summary: "Erro :(",
                    detail: "Desculpe, não conseguimos processar a sua requisição.",
                    life: 3000,
                });
            }
        } else {
            showLoading();
            const resposta = await axiosPut(`/vendas/regra-arredondamento-valor-detalhe/${values.id}/`, detalhe);
            hideLoading();

            if (resposta.status === 200) {
                toastRef.current.show({
                    severity: "success",
                    summary: "Sucesso",
                    detail: "Detalhe de arredondamento de preço alterado com sucesso!",
                    life: 1500,
                });

                formik.resetForm();
                listagemRef.current?.buscarDados();
            } else {
                toastRef.current.show({
                    severity: "error",
                    summary: "Erro :(",
                    detail: "Desculpe, não conseguimos processar a sua requisição.",
                    life: 3000,
                });
            }
        }
    }

    const deletarDetalheRegraArredondamento = async (value) => {
        showLoading();
        const resposta = await axiosDelete(`/vendas/regra-arredondamento-valor-detalhe/${value.id}/`);
        hideLoading();

        if (resposta.status === 204) {
            listagemRef.current?.buscarDados();
        } else {
            toastRef.current.show({
                severity: "error",
                summary: "Erro :(",
                detail: "O detalhe de arredondamento não pode ser excluído.",
                life: 3000,
            });
        }
    };

    const confirmarDelete = (value) => {
        confirmDialog({
            header: "Confirmação de exclusão",
            message: "Deseja mesmo excluir o detalhe de arredondamento?",
            icon: "pi pi-info-circle",
            acceptClassName: "p-button-danger",
            acceptLabel: "Deletar",
            accept: () => deletarDetalheRegraArredondamento(value),
            rejectLabel: "Cancelar",
        });
    };

    const valorInteiroBodyTemplate = (rowData) => {
        if (!rowData.inteiro) {
            return <span>--</span>;
        }

        return parseNumberToMoneyHTML(rowData.inteiro);
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button
                    icon="pi pi-pencil"
                    className="p-button-rounded p-button-warning p-mr-2"
                    onClick={() => {
                        formik.setValues({
                            ...rowData,
                            inteiro: rowData.inteiro === null ? 0 : rowData.inteiro,
                            decimal: rowData.decimal * 100,
                        });
                    }}
                />
                <Button
                    icon="pi pi-trash"
                    className="p-button-rounded p-button-danger p-mr-2"
                    onClick={() => confirmarDelete(rowData)}
                />
            </div>
        );
    };

    const colunas = [
        {
            field: "inteiro",
            header: "Parte inteira",
            action: (e) => valorInteiroBodyTemplate(e),
        },
        {
            field: "decimal",
            header: "Decimal",
            money: true,
        },
        {
            field: "actions",
            header: "Ações",
            style: { width: "10%" },
            action: (e) => actionBodyTemplate(e),
        },
    ];

    return (
        <>
            <Toast ref={toastRef} />
            <ConfirmDialog />
            <form onSubmit={formik.handleSubmit}>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="inteiro">Parte inteira</label>
                        <InputNumber
                            id="inteiro"
                            name="inteiro"
                            value={formik.values.inteiro}
                            prefix="R$ "
                            onValueChange={formik.handleChange}
                        />
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="decimal">Decimal</label>
                        <InputNumber
                            id="decimal"
                            name="decimal"
                            value={formik.values.decimal}
                            prefix="R$ 0."
                            max={99}
                            onValueChange={formik.handleChange}
                        />
                    </div>
                    <div className="p-field p-mt-auto p-col-12 p-md-2">
                        <Button icon="pi pi-save" label="Gravar" className="p-button-info" />
                    </div>
                </div>
            </form>
            <MakoListagem
                ref={listagemRef}
                colunas={colunas}
                urlPesquisa={`/vendas/regra-arredondamento-valor-detalhe/?regra_arredondamento=${regraArredondamentoValor?.id}`}
                configTabela={{ paginator: true, lazy: true }}
            />
            <Button
                icon="pi pi-angle-double-left"
                label="Voltar"
                type="button"
                className="p-button-danger p-mr-2 p-mt-3"
                onClick={() => history.push("/vendas/cadastros/arredondamento-valor")}
            />
        </>
    );
};
