import React, { useRef, useState } from "react";

import { axiosPost, axiosPatch } from "@/services/http";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { useFormik } from "formik";
import { ConfirmDialog } from "primereact/confirmdialog";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import { InputText } from "primereact/inputtext";
import { MakoCalendar } from "@/components/MakoCalendar";
import { InputSwitch } from "primereact/inputswitch";
import { dataToStr } from "@/assets/util/datas";
import { Dialog } from "primereact/dialog";
import { MakoAutoComplete } from "@/components/MakoAutoComplete";

import permissoes from "@/assets/constants/permissoes";
import MakoListagem from "@/components/MakoListagem";
import useLoading from "@/hooks/useLoading";
import classNames from "classnames";
import * as Yup from "yup";
import useToast from "@/hooks/useToast";
import { PageBase } from "@/components/PageBase";

export const FiscalCestForm = (props) => {
    const { showLoading, hideLoading } = useLoading();
    const [cest, setCest] = useState(null);
    const [cestAdicionar, setCestAdicionar] = useState(null);
    const [deleteDialog, setDeleteDialog] = useState(false);
    const [cadastraCest, setCadastraCest] = useState(false);
    const listagemRef = useRef(null);
    const { showSuccess, showError, showWarning } = useToast();

    const { resetForm, setValues, setFieldValue, ...formik } = useFormik({
        initialValues: {
            id: null,
            codigo: "",
            descricao: "",
            vigencia_final: null,
            ativo: true,
        },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                codigo: Yup.string().required("O campo 'código' é obrigatório."),
                descricao: Yup.string().required("O campo 'descrição' é obrigatório."),
            });

            await formSchema.validate(values, {
                abortEarly: false,
            });

            let data = {
                ...values,
                ncm: {},
                vigencia_final: dataToStr(values.vigencia_final, "yyyy-MM-dd") || null,
            };

            showLoading();
            const resposta = await axiosPost(`/fiscal/cest/`, data);
            hideLoading();

            if (resposta.status === 201) {
                showSuccess({
                    summary: "Sucesso",
                    detail: "Cest cadastrado com sucesso!",
                    life: 1500,
                });

                esconderCadastraCest();
                listagemRef.current?.buscarDados();
            } else if (resposta.status === 400) {
                if (resposta.data?.codigo)
                    showWarning({
                        summary: "Duplicidade",
                        detail: "O código informado já existe!",
                        life: 5000,
                    });
                else
                    showWarning({
                        summary: "Erro",
                        detail: "Por favor, verifique se as informações estão corretas!",
                        life: 2500,
                    });
            } else {
                showError({
                    summary: "Erro",
                    detail: "Desculpe, não conseguimos processar a sua requisição.",
                    life: 1500,
                });
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
                hideLoading();
            } else {
                showError({
                    summary: "Erro",
                    detail: "Desculpe, não conseguimos processar a sua requisição.",
                    life: 1500,
                });
                hideLoading();
            }
        }
    }

    const esconderDeleteDialog = () => {
        setDeleteDialog(false);
    };

    const confirmarDelete = (cest) => {
        setCest(cest.id);
        setDeleteDialog(true);
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <MakoControleAcesso
                    permissao={[permissoes.FISCAL_FISCAL_CEST_EXCLUIR]}
                    componente={Button}
                    icon="pi pi-trash"
                    className="p-button-rounded p-button-danger p-mr-2 p-mb-1"
                    onClick={() => confirmarDelete(rowData)}
                    tooltip="Deletar Cest"
                    tooltipOptions={{ position: "left" }}
                />
            </div>
        );
    };

    const adicionaRemoveCest = async ({ op = "rmv" }) => {
        showLoading();
        const resposta = await axiosPatch(
            `/fiscal/cest/${op === "rmv" ? cest : cestAdicionar.id}/`,
            op === "rmv" ? { ncm: { remove: [props.ncm?.id] } } : { ncm: { add: [props.ncm?.id] } }
        );
        hideLoading();

        if (resposta.status === 200) {
            showSuccess({
                summary: "Sucesso",
                detail: `Cest ${op === "rmv" ? "removido" : "adicionado"} com sucesso!`,
                life: 3000,
            });

            setCest(null);
            setCestAdicionar(null);
            listagemRef.current?.buscarDados();
        } else {
            showError({
                summary: "Erro",
                detail: "Desculpe, não conseguimos processar a sua requisição.",
                life: 3000,
            });
            hideLoading();
        }
        setDeleteDialog(false);
    };

    const autoCompleteTemplate = (cest) => {
        cest.label = `${cest.codigo} - ${cest.descricao}`;
        return `${cest.codigo} - ${cest.descricao}`;
    };

    const esconderCadastraCest = () => {
        setCadastraCest(false);
        resetForm();
    };

    const [ativo, setAtivo] = useState(formik.values?.ativo);

    const colunasCest = [
        { field: "id", header: "#ID", style: { width: "10%" } },
        { field: "codigo", header: "Código" },
        { field: "descricao", header: "Descrição" },
        { field: "vigencia_final", header: "Vigência Final", dateFormat: "dd/MM/yyyy", style: { width: "12%" } },
        {
            field: "action",
            header: "Ações",
            action: (e) => actionBodyTemplate(e),
            style: { width: "10%" },
        },
    ];

    return (
        <PageBase>
            <h5>{`Cadastro de Cest para o NCM:  `}</h5>
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12 p-md-12">
                    <label htmlFor="cest">Selecione um Cest: </label>
                    <div className="p-inputgroup">
                        <MakoAutoComplete
                            id="cest"
                            name="cest"
                            value={cestAdicionar}
                            onChange={(e) => setCestAdicionar(e.target.value)}
                            minCaracteresBusca={4}
                            itemTemplate={autoCompleteTemplate}
                            field="label"
                            urlSearch="/fiscal/cest?ativo=true&search="
                            placeholder="Busque pelo código ou descrição... (min 4 caracteres)"
                        />
                        <MakoControleAcesso
                            permissao={[permissoes.FISCAL_FISCAL_CEST_INCLUIR]}
                            componente={Button}
                            label="Vincular"
                            icon="pi pi-save"
                            type="button"
                            onClick={() => adicionaRemoveCest({ op: "add" })}
                            disabled={!cestAdicionar || typeof cestAdicionar !== "object"}
                            className="p-button-info p-ml-2 p-mr-2"
                        />
                        <MakoControleAcesso
                            permissao={[permissoes.FISCAL_FISCAL_CEST_INCLUIR]}
                            componente={Button}
                            icon="pi pi-plus"
                            label="Criar"
                            type="button"
                            className="p-button-success"
                            onClick={() => setCadastraCest(true)}
                        />
                    </div>
                    {formik.errors.cest && <small className="p-error">{formik.errors.cest}</small>}
                </div>
            </div>

            <Dialog
                header="Cadastrar novo Cest"
                visible={cadastraCest}
                breakpoints={{ "960px": "75vw" }}
                style={{ width: "60vw", display: "block" }}
                onHide={() => esconderCadastraCest()}
            >
                <form onSubmit={formik.handleSubmit}>
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-12 p-md-3">
                            <label htmlFor="codigo">Código *</label>
                            <InputText
                                id="codigo"
                                name="codigo"
                                value={formik.values.codigo}
                                onChange={formik.handleChange}
                                keyfilter={/^[0-9]/}
                                className={classNames({
                                    "p-invalid": formik.errors.codigo,
                                })}
                                tooltip="Código do cest Exemplo: Código '3021564' do Cest 'Outros produtos'."
                                autoComplete="off"
                                autoFocus
                            />
                            {formik.errors.codigo && <small className="p-error">{formik.errors.codigo}</small>}
                        </div>
                        <div className="p-field p-col-12 p-md-9">
                            <label htmlFor="descricao">Descrição *</label>
                            <InputText
                                id="descricao"
                                name="descricao"
                                value={formik.values.descricao}
                                onChange={formik.handleChange}
                                className={classNames({
                                    "p-invalid": formik.errors.descricao,
                                })}
                                tooltip="Descrição do Cest."
                                autoComplete="off"
                            />
                            {formik.errors.descricao && <small className="p-error">{formik.errors.descricao}</small>}
                        </div>
                    </div>
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-12 p-md-4">
                            <label htmlFor="vigencia_final">Vigência Final</label>
                            <MakoCalendar
                                id="vigencia_final"
                                name="vigencia_final"
                                dateFormat="dd/mm/yy"
                                valueCalendar={formik.values.vigencia_final}
                                onChange={formik.handleChange}
                                className={classNames({
                                    "p-invalid": formik.errors.vigencia_final,
                                })}
                            />
                            {formik.errors.vigencia_final && (
                                <small className="p-error">{formik.errors.vigencia_final}</small>
                            )}
                        </div>
                        <div className="p-field p-col-1 ">
                            <h6>
                                <label htmlFor="ativo">Status</label>
                            </h6>
                            <InputSwitch
                                id="ativo"
                                name="ativo"
                                checked={ativo}
                                disabled={formik.values.id ? false : true}
                                value={formik.values.ativo}
                                onChange={(e) => setAtivo(e.value)}
                            />
                        </div>
                    </div>
                    <p>
                        <b>* Campos obrigatórios.</b>
                    </p>

                    <div className="p-grid p-col-12 p-md-12 p-justify-end">
                        <MakoControleAcesso
                            permissao={[permissoes.FISCAL_FISCAL_CEST_INCLUIR]}
                            componente={Button}
                            label="Cadastrar"
                            icon="pi pi-save"
                            type="submit"
                            className="p-button-success p-mr-2"
                        />
                        <Button
                            icon="pi pi-times"
                            label="Fechar"
                            type="reset"
                            className="p-button-danger"
                            onClick={() => esconderCadastraCest()}
                        />
                    </div>
                </form>
            </Dialog>
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12 p-md-12">
                    <MakoListagem
                        ref={listagemRef}
                        colunas={colunasCest}
                        titulo={`Cest's Existentes para o NCM: ${props.ncm?.codigo}`}
                        urlPesquisa={`/fiscal/cest?ncm__id=${props.ncm?.id}`}
                        responsiva
                        configTabela={{
                            paginator: true,
                            lazy: true,
                        }}
                    />
                </div>
            </div>
            <ConfirmDialog
                visible={deleteDialog}
                onHide={esconderDeleteDialog}
                header="Confirmação"
                message={
                    formik && (
                        <span>
                            {"Deseja realmente remover o Cest n "}
                            <b>{formik.codigo}</b>?
                        </span>
                    )
                }
                icon="pi pi-info-circle p-mr-3"
                accept={adicionaRemoveCest}
                acceptLabel="Sim"
                acceptClassName="p-button-danger"
                reject={esconderDeleteDialog}
                rejectLabel="Não"
            />
        </PageBase>
    );
};
