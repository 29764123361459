import React, { useRef } from "react";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import permissoes from "@/assets/constants/permissoes";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import MakoListagem from "@/components/MakoListagem";
import { Tag } from "primereact/tag";
import useClearRefs from "@/hooks/useClearRefs";
import useEmpresa from "@/hooks/useEmpresa";
import { BotaoDelete } from "@/components/BotaoDelete";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const url = "/fiscal/cst-cofins/";

export const FiscalCofinsPage = () => {
    const { empresaSelecionadaId } = useEmpresa();
    const listagemRef = useRef(null);
    const history = useHistory();

    useClearRefs(listagemRef);

    const STATUS_COFINS = [
        { value: true, label: "Ativo" },
        { value: false, label: "Inativo" },
    ];

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <MakoControleAcesso
                    permissao={[permissoes.FISCAL_FISCAL_COFINS_EDITAR]}
                    componente={Button}
                    icon="pi pi-pencil"
                    className="p-button-rounded p-button-warning p-mr-2 p-mb-1"
                    tooltip="Alterar Cadastro de Cofins"
                    tooltipOptions={{ position: "left" }}
                    onClick={() =>
                        history.push({
                            pathname: "/fiscal/cadastros/outros/cofins/form",
                            state: {
                                cofins: {
                                    ...rowData,
                                    reverso: rowData.reverso?.id,
                                },
                            },
                        })
                    }
                />
                <MakoControleAcesso
                    permissao={[permissoes.FISCAL_FISCAL_COFINS_EXCLUIR]}
                    componente={BotaoDelete}
                    url={url}
                    objetoId={rowData.id}
                    classNames="p-mb-1"
                    exigeConfirmacao
                    msgConfirmacao={
                        <span>
                            Deseja realmente excluir o Cofins <b>{rowData.descricao}</b>?
                        </span>
                    }
                    msgToastErroExclusao="O CST de Cofins não pode ser excluído."
                    tooltip="Deletar Cofins"
                    tooltipOptions={{ position: "left" }}
                    onDelete={() => listagemRef.current?.buscarDados()}
                />
            </div>
        );
    };

    const cabecalhoTabela = (
        <>
            <MakoControleAcesso
                permissao={[permissoes.FISCAL_FISCAL_NCM_INCLUIR]}
                componente={Button}
                label="Novo"
                icon="pi pi-plus"
                disabled={!empresaSelecionadaId}
                className="p-button-success p-mr-2"
                onClick={() => history.push("/fiscal/cadastros/outros/cofins/form")}
            />
        </>
    );

    const statusFiltroTemplate = (options) => {
        return (
            <Dropdown
                placeholder="Selecione"
                showClear
                value={options.value}
                options={STATUS_COFINS}
                onChange={(e) => options.filterCallback(e.value)}
            />
        );
    };

    const statusBodyTemplate = (rowData) => {
        if (rowData.ativo) return <Tag severity="success" value="ATIVO" />;
        return <Tag severity="danger" value="INATIVO" />;
    };

    const colunas = [
        {
            field: "id",
            header: "#ID",
            style: { width: "10%" },
            filter: true,
        },
        {
            field: "codigo",
            header: "Código",
            style: { width: "10%" },
            filter: true,
        },
        {
            field: "descricao",
            header: "Nome",
            filter: true,
        },
        {
            field: "ativo",
            header: "Situação",
            style: { width: "10%" },
            filter: true,
            filterElement: statusFiltroTemplate,
            action: (e) => statusBodyTemplate(e),
        },
        { field: "action", header: "Ações", action: (e) => actionBodyTemplate(e), style: { width: "10%" } },
    ];

    return (
        <div className="p-grid">
            <div className="p-col-12">
                <div className="card">
                    <MakoListagem
                        ref={listagemRef}
                        titulo="Cofins"
                        colunas={colunas}
                        painelEsquerdo={cabecalhoTabela}
                        filtarPorEmpresa
                        naoBuscarSemEmpresa
                        urlPesquisa={url}
                        filtros={{
                            id: {
                                operator: "and",
                                constraints: [{ value: "", matchMode: "icontains" }],
                            },
                            codigo: {
                                operator: "and",
                                constraints: [{ value: "", matchMode: "icontains" }],
                            },
                            descricao: {
                                operator: "and",
                                constraints: [{ value: "", matchMode: "icontains" }],
                            },
                            ativo: { value: "", matchMode: "equals" },
                        }}
                        configTabela={{
                            paginator: true,
                            lazy: true,
                            filterDisplay: "menu",
                        }}
                    />
                </div>
            </div>
        </div>
    );
};
