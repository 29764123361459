import React, { useState, useRef } from "react";
import { Button } from "primereact/button";
import { Redirect } from "react-router-dom";
import { ConfirmDialog } from "primereact/confirmdialog";
import { axiosDelete } from "@/services/http";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";

import permissoes from "@/assets/constants/permissoes";
import MakoListagem from "@/components/MakoListagem";
import useLoading from "@/hooks/useLoading";
import useToast from "@/hooks/useToast";
import { CodigoFiltroTemplate, DateFiltroTemplate, TextoFiltroTemplate } from "@/components/MakoFiltrosCabecalho";

const url = "/financeiro/competencias/";

export const FinanceiroCompetenciaPage = () => {
    const [redirecionar, setRedirecionar] = useState(null);
    const [deleteDialog, setDeleteDialog] = useState(false);
    const [competencia, setCompetencia] = useState(null);
    const { showLoading, hideLoading } = useLoading();
    const { showSuccess, showError } = useToast();
    const listagemRef = useRef(null);

    const confirmarDelete = (competencia) => {
        setCompetencia(competencia);
        setDeleteDialog(true);
    };

    const esconderDeleteDialog = () => {
        setDeleteDialog(false);
    };

    const deleteCompetencia = async () => {
        showLoading();
        const resposta = await axiosDelete(`${url}${competencia.id}`);
        hideLoading();

        if (resposta.status === 200 || resposta.status === 204) {
            showSuccess({
                summary: "Sucesso",
                detail: "Competência deletada com sucesso!",
                life: 3000,
            });

            listagemRef.current?.buscarDados();
        } else {
            showError({
                summary: "Erro",
                detail: "Desculpe, não conseguimos processar a sua requisição.",
                life: 3000,
            });
            hideLoading();
        }
        setDeleteDialog(false);
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <MakoControleAcesso
                    permissao={[permissoes.FINANCEIRO_CADASTRO_COMPETENCIA_EDITAR]}
                    componente={Button}
                    icon="pi pi-pencil"
                    className="p-button-rounded p-button-warning p-mr-2 p-mb-1"
                    tooltip="Alterar cadastro da competencia"
                    tooltipOptions={{ position: "left" }}
                    onClick={() =>
                        setRedirecionar({
                            pathname: "/financeiro/cadastros/competencia/form",
                            state: { competencia: rowData },
                        })
                    }
                />
                <MakoControleAcesso
                    permissao={[permissoes.FINANCEIRO_CADASTRO_COMPETENCIA_EXCLUIR]}
                    componente={Button}
                    icon="pi pi-trash"
                    className="p-button-rounded p-button-danger p-mr-2 p-mb-1"
                    onClick={() => confirmarDelete(rowData)}
                    tooltip="Deletar competência"
                    tooltipOptions={{ position: "left" }}
                />
            </div>
        );
    };

    const cabecalhoTabela = (
        <>
            <MakoControleAcesso
                permissao={[permissoes.FINANCEIRO_CADASTRO_COMPETENCIA_INCLUIR]}
                componente={Button}
                label="Novo"
                icon="pi pi-plus"
                className="p-button-success p-mr-2"
                onClick={() => setRedirecionar("/financeiro/cadastros/competencia/form")}
            />
        </>
    );

    const colunas = [
        { field: "codigo", header: "Código", filter: true, filterElement: CodigoFiltroTemplate },
        {
            field: "grupo_competencia",
            header: "Grupo de competência",
            filter: true,
            filterElement: TextoFiltroTemplate,
        },
        {
            field: "data_inicio",
            header: "Data inicial",
            dateFormat: "dd/MM/yyyy",
            filter: true,
            filterElement: DateFiltroTemplate,
        },
        {
            field: "data_fim",
            header: "Data final",
            dateFormat: "dd/MM/yyyy",
            filter: true,
            filterElement: DateFiltroTemplate,
        },
        { field: "ativa", style: { width: "0" } },
        {
            field: "action",
            header: "Ações",
            action: (e) => actionBodyTemplate(e),
            style: { width: "20%", textAlign: "center" },
        },
    ];

    return redirecionar ? (
        <Redirect to={redirecionar} />
    ) : (
        <div className="p-grid">
            <div className="p-col-12">
                <div className="card">
                    <MakoListagem
                        ref={listagemRef}
                        titulo="Competências"
                        colunas={colunas}
                        painelEsquerdo={cabecalhoTabela}
                        urlPesquisa={url}
                        responsiva
                        configTabela={{
                            paginator: true,
                            lazy: true,
                        }}
                        filtros={{
                            codigo: { value: null, matchMode: "equals" },
                            grupo_competencia: { value: null, matchMode: "equals" },
                            data_inicio: { value: null, matchMode: "equals" },
                            data_fim: { value: null, matchMode: "equals" },
                        }}
                    />
                    <ConfirmDialog
                        visible={deleteDialog}
                        onHide={esconderDeleteDialog}
                        header="Confirmação"
                        message={
                            competencia && (
                                <span>
                                    {"Deseja realmente excluir a competência "}
                                    <b>{competencia.descricao}</b>?
                                </span>
                            )
                        }
                        icon="pi pi-info-circle p-mr-3"
                        accept={deleteCompetencia}
                        acceptLabel="Sim"
                        acceptClassName="p-button-danger"
                        reject={esconderDeleteDialog}
                        rejectLabel="Não"
                    />
                </div>
            </div>
        </div>
    );
};
