import React, { useState, forwardRef, useImperativeHandle } from "react";
import { Dialog } from "primereact/dialog";
import { TabPanel, TabView } from "primereact/tabview";
import { useFormik } from "formik";
import useEmpresa from "@/hooks/useEmpresa";
import { InputTextarea } from "primereact/inputtextarea";
import classNames from "classnames";
import { Dropdown } from "../Dropdown";
import { SIM_NAO_BOOLEAN, TIPO_MODELO_OPCAO_FATURAMENTO } from "@/assets/constants/constants";
import { Button } from "primereact/button";
import { SelectButton } from "primereact/selectbutton";
import { InputNumber } from "primereact/inputnumber";
import * as Yup from "yup";
import useToast from "@/hooks/useToast";
import { axiosPatch, axiosPost } from "@/services/http";
import { MakoControleAcesso } from "../MakoControleAcesso";
import permissoes from "@/assets/constants/permissoes";

const Modal = ({ venda, onSuccess, onContext }, ref) => {
    const [visible, setVisible] = useState(null);
    const { empresaSelecionadaId } = useEmpresa();
    const { showError } = useToast();

    const { setValues, resetForm, ...formik } = useFormik({
        initialValues: {
            id: null,
            empresa: empresaSelecionadaId,
            modelo_documento: "QQ",
            info_fisco: "",
            info_adicionais: "",
            faturamento_antecipado: false,
            min_faturamento_antecipado: null,
            entrega_futura: false,
            min_entrega_futura: null,
        },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                modelo_documento: Yup.string().required().typeError("Informe um modelo válido"),
            });

            await formSchema.validate(values, {
                abortEarly: false,
            });

            if (!values.id) {
                const { status } = await axiosPost(`/fiscal/opcoes-faturamento/`, values);

                if (status === 201) {
                    if (typeof onSuccess === "function") onSuccess();
                    fecharModal();
                } else {
                    showError({
                        summary: "Erro",
                        detail: "Desculpe, não conseguimos processar sua requisição",
                        life: 3000,
                    });
                }
            } else {
                const { status } = await axiosPatch(`/fiscal/opcoes-faturamento/${values.id}/`, values);

                if (status === 200) {
                    if (typeof onSuccess === "function") onSuccess();
                    fecharModal();
                } else {
                    showError({
                        summary: "Erro",
                        detail: "Desculpe, não conseguimos processar sua requisição",
                        life: 3000,
                    });
                }
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            }
        }
    }
    useImperativeHandle(ref, () => {
        return { abrirModal };
    });

    const abrirModal = (values) => {
        if (values) setValues(values);
        setVisible(true);
    };

    const fecharModal = () => {
        resetForm();
        setVisible(false);
    };

    return (
        <>
            <Dialog header="Opções de faturamento" visible={visible} onHide={fecharModal} style={{ width: "70vw" }}>
                <form onSubmit={formik.handleSubmit}>
                    <TabView className="tabview-custom">
                        <TabPanel header="Geral" leftIcon="pi pi-fw pi-cog">
                            <div className="p-fluid p-formgrid p-grid">
                                <div className="p-field p-col-12 p-md-3">
                                    <label htmlFor="modelo_documento">Modelo do documento: </label>
                                    <Dropdown
                                        id="modelo_documento"
                                        name="modelo_documento"
                                        placeholder="Selecione..."
                                        options={TIPO_MODELO_OPCAO_FATURAMENTO}
                                        onChange={formik.handleChange}
                                        value={formik.values.modelo_documento}
                                        className={classNames({ "p-invalid": formik.errors.modelo_documento })}
                                    />
                                    {formik.errors.modelo_documento && (
                                        <small className="p-error">{formik.errors.modelo_documento}</small>
                                    )}
                                </div>
                                <div className="p-field p-col-12 p-md-3 p-mt-5">
                                    <MakoControleAcesso
                                        permissao={[permissoes.FISCAL_FISCAL_OPCOESFATURAMENTO_EDITAR]}
                                        componente={Button}
                                        type="submit"
                                        label="Definir como padrão"
                                        className="p-mr-2 p-mb-2 p-button-info"
                                    />
                                </div>
                            </div>
                            <div className="p-fluid p-formgrid p-grid">
                                <div className="p-field p-col-12 p-md-12">
                                    <label htmlFor="info_fisco">Informações do Fisco</label>
                                    <InputTextarea
                                        id="info_fisco"
                                        name="info_fisco"
                                        value={formik.values.info_fisco}
                                        onChange={formik.handleChange}
                                        autoResize
                                        rows={4}
                                        className={classNames({ "p-invalid": formik.errors.info_fisco })}
                                    />
                                    {formik.errors.info_fisco && (
                                        <small className="p-error">{formik.errors.info_fisco}</small>
                                    )}
                                </div>
                                <div className="p-field p-col-12 p-md-12">
                                    <label htmlFor="info_adicionais">Informações Adicionais</label>
                                    <InputTextarea
                                        id="info_adicionais"
                                        name="info_adicionais"
                                        value={formik.values.info_adicionais}
                                        onChange={formik.handleChange}
                                        autoResize
                                        rows={4}
                                        className={classNames({ "p-invalid": formik.errors.info_adicionais })}
                                    />
                                    {formik.errors.info_adicionais && (
                                        <small className="p-error">{formik.errors.info_adicionais}</small>
                                    )}
                                </div>
                            </div>
                        </TabPanel>
                        <TabPanel header="Venda" leftIcon="pi pi-fw pi-shopping-bag" disabled={!venda}>
                            <div className="p-fluid p-formgrid p-grid">
                                <div className="p-field p-col-12 p-md-3">
                                    <label htmlFor="faturamento_antecipado">Faturamento antecipado</label>
                                    <SelectButton
                                        id="faturamento_antecipado"
                                        name="faturamento_antecipado"
                                        value={formik.values.faturamento_antecipado}
                                        onChange={formik.handleChange}
                                        optionValue="id"
                                        optionLabel="label"
                                        options={SIM_NAO_BOOLEAN}
                                    />
                                </div>
                                <div className="p-field p-col-12 p-md-2">
                                    <label htmlFor="min_faturamento_antecipado">Dias no mínimo</label>
                                    <InputNumber
                                        id="min_faturamento_antecipado"
                                        name="min_faturamento_antecipado"
                                        showButtons
                                        tooltip="Intervalo mínimo de dias entre a venda e a entrega para considerar faturamento antecipado
                                        se não houver saldo disponível para a entrega."
                                        onValueChange={formik.handleChange}
                                        value={formik.values.min_faturamento_antecipado}
                                        className={classNames({
                                            "p-invalid": formik.errors.min_faturamento_antecipado,
                                        })}
                                        autoComplete="off"
                                    />
                                    {formik.errors.min_faturamento_antecipado && (
                                        <small className="p-error">{formik.errors.min_faturamento_antecipado}</small>
                                    )}
                                </div>
                            </div>
                            <div className="p-fluid p-formgrid p-grid">
                                <div className="p-field p-col-12 p-md-3">
                                    <label htmlFor="entrega_futura">Entrega futura</label>
                                    <SelectButton
                                        id="entrega_futura"
                                        name="entrega_futura"
                                        value={formik.values.entrega_futura}
                                        onChange={formik.handleChange}
                                        optionValue="id"
                                        optionLabel="label"
                                        options={SIM_NAO_BOOLEAN}
                                    />
                                </div>
                                <div className="p-field p-col-12 p-md-2">
                                    <label htmlFor="min_entrega_futura">Dias no mínimo</label>
                                    <InputNumber
                                        id="min_entrega_futura"
                                        name="min_entrega_futura"
                                        showButtons
                                        tooltip="Intervalo mínimo de dias entre a venda e a entrega para considerar venda para entrega futura
                                        se existir saldo disponível para a entrega."
                                        onValueChange={formik.handleChange}
                                        value={formik.values.min_entrega_futura}
                                        className={classNames({
                                            "p-invalid": formik.errors.min_entrega_futura,
                                        })}
                                        autoComplete="off"
                                    />
                                    {formik.errors.min_entrega_futura && (
                                        <small className="p-error">{formik.errors.min_entrega_futura}</small>
                                    )}
                                </div>
                            </div>
                        </TabPanel>
                    </TabView>
                    <div className="p-grid p-ml-3 p-mt-3">
                        <Button
                            type="button"
                            label="Gravar"
                            icon="pi pi-save"
                            className="p-mr-2 p-mb-2"
                            onClick={() => {
                                onContext(formik.values);
                                fecharModal();
                            }}
                        />
                        <Button
                            type="button"
                            label="Cancelar"
                            icon="pi pi-times"
                            className="p-mb-2 p-button-danger"
                            onClick={() => fecharModal()}
                        />
                    </div>
                </form>
            </Dialog>
        </>
    );
};

export const OpcoesFaturamento = forwardRef(Modal);
