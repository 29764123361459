import React, { useRef } from "react";
import { useHistory } from "react-router-dom";
import { Button } from "primereact/button";

import { PageBase } from "@/components/PageBase";
import MakoListagem from "@/components/MakoListagem";
import { BotaoDelete } from "@/components/BotaoDelete";
import useClearRefs from "@/hooks/useClearRefs";
import { BooleanFiltroTemplate, TextoFiltroTemplate } from "@/components/MakoFiltrosCabecalho";

export const TiposEntradaPage = () => {
    const listagemRef = useRef(null);
    const history = useHistory();

    useClearRefs(listagemRef);

    const painelEsquerdoTabela = (
        <>
            <Button
                label="Novo"
                icon="pi pi-plus"
                className="p-button-success p-mr-2 p-mb-2"
                onClick={() => history.push("/compras/cadastros/tipos-entrada/form")}
            />
        </>
    );

    const booleanBodyTemplate = (field) => {
        if (field) return <span>Sim</span>;
        return <span>Não</span>;
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button
                    icon="pi pi-pencil"
                    className="p-button-rounded p-button-warning p-mr-2"
                    onClick={() =>
                        history.push({
                            pathname: "/compras/cadastros/tipos-entrada/form",
                            state: rowData,
                        })
                    }
                />
                <BotaoDelete
                    url="/compras/tipos-entradas/"
                    objetoId={rowData.id}
                    exigeConfirmacao
                    msgConfirmacao={
                        <span>
                            Deseja mesmo excluir o tipo de entrada <b>{rowData.descricao}</b>?
                        </span>
                    }
                    msgToastErroExclusao="O tipo de entrada não pode ser excluído."
                    onDelete={() => listagemRef.current?.buscarDados()}
                />
            </div>
        );
    };

    const colunas = [
        { field: "descricao", header: "Descrição", filter: true, filterElemente: TextoFiltroTemplate },
        {
            field: "gera_financeiro",
            header: "Gera financeiro?",
            style: { width: "20%" },
            action: (e) => booleanBodyTemplate(e.gera_financeiro),
            filter: true,
            filterElement: BooleanFiltroTemplate,
        },
        {
            field: "requer_ordem_compra",
            header: "Requer ordem de compra?",
            style: { width: "20%" },
            action: (e) => booleanBodyTemplate(e.requer_ordem_compra),
            filter: true,
            filterElement: BooleanFiltroTemplate,
        },
        { field: "actions", header: "Ações", style: { width: "10%" }, action: (e) => actionBodyTemplate(e) },
    ];

    return (
        <PageBase>
            <MakoListagem
                ref={listagemRef}
                titulo="Listagem de tipos de entrada"
                colunas={colunas}
                urlPesquisa="/compras/tipos-entradas/"
                painelEsquerdo={painelEsquerdoTabela}
                configTabela={{
                    lazy: true,
                    paginator: true,
                }}
                filtros={{
                    gera_financeiro: { value: null, matchMode: "equals" },
                    descricao: {
                        operator: "and",
                        constraints: [{ value: "", matchMode: "unaccent_icontains" }],
                    },
                    requer_ordem_compra: { value: null, matchMode: "equals" },
                }}
            />
        </PageBase>
    );
};
