import React, { useEffect, useMemo, useState } from "react";

import classNames from "classnames";

import { useFormik } from "formik";
import * as Yup from "yup";

import { InputTextarea } from "primereact/inputtextarea";
import { InputText } from "primereact/inputtext";
import { Checkbox } from "primereact/checkbox";
import { Button } from "primereact/button";

import { MakoInputPercent } from "@/components/MakoInputs/MakoInputPercent";
import { MakoAutoComplete } from "@/components/MakoAutoComplete";
import { MakoInputPerfil } from "@/components/MakoInputs/MakoInputPerfil";
import { MakoInputMoeda } from "@/components/MakoInputMoeda";
import { Dropdown } from "@/components/Dropdown";
import { Label } from "@/components/Label";

import { TIPOS_CHOICE_SERVICOS_SERVICO } from "@/assets/constants/constants";
import { axiosPatch, axiosPost } from "@/services/http";
import { gerarStringAleatoria } from "@/assets/util/util";

import useContratoServico from "@/hooks/useContratoServico";
import useLoading from "@/hooks/useLoading";
import useEmpresa from "@/hooks/useEmpresa";
import useToast from "@/hooks/useToast";

export const ModalNovoServico = ({ onFinish, onClose, novoServico, servico }) => {
    const [templates, setTemplates] = useState([]);

    const { empresaSelecionadaId, empresaSelecionada } = useEmpresa();
    const { contrato, atualizarValorContrato } = useContratoServico();
    const { showLoading, hideLoading } = useLoading();
    const { showSuccess, showError } = useToast();

    const initialForm = {
        empresa: empresaSelecionadaId,
        codigo: "",
        descricao: "",
        custo: 0,
        valor_venda: 0,
        tipo: null,
        terceirizado: false,
        ativo: true,
        comissao_interveniente: 0,
        requer_apontamento: false,
        template: false,
        custo_hora: false,
        detalhes: "",
        executor: empresaSelecionada,
        servico_municipio: null,
        uf: null,
        municipio: null,
        aliquota: 0,
        operacao_fiscal: null,
    };

    const { setValues, setFieldValue, ...formik } = useFormik({
        initialValues: initialForm,
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                codigo: Yup.string()
                    .max(10, "Máximo de caracteres atingido: 10")
                    .required("O campo 'codigo' é obrigatório")
                    .typeError("Informe um 'código' válido"),
                descricao: Yup.string()
                    .max(100, "Máximo de caracteres atingido: 100")
                    .required("O campo 'descrição' é obrigatório")
                    .typeError("Informe um 'descrição' válido"),
                custo: Yup.number().required("O campo 'custo' é obrigatório.").typeError("Informe um 'custo' válido"),
                valor_venda: Yup.number()
                    .required("O campo 'valor venda' é obrigatório.")
                    .typeError("Informe um 'valor venda' válido"),
                tipo: Yup.string()
                    .max(1, "Máximo de caracteres atingido: 1")
                    .required("O campo 'tipo' é obrigatório")
                    .typeError("Informe um 'tipo' válido"),
                detalhes: Yup.string()
                    .max(255, "Máximo de caracteres atingido: 255")
                    .required("O campo 'detalhes' é obrigatório")
                    .typeError("Informe um 'detalhes' válido"),
                comissao_interveniente: Yup.number()
                    .required("O campo 'valor de comissão' é obrigatório.")
                    .typeError("Informe um 'valor de comissão' válido"),
                operacao_fiscal: Yup.number()
                    .required("O campo 'operacao fiscal' é obrigatório.")
                    .typeError("Informe um 'operacao fiscal' válido"),
            });
            await formSchema.validate(values, { abortEarly: false });

            let dataServico = {
                empresa: values.empresa,
                codigo: values.codigo,
                descricao: values.descricao,
                custo: values.custo,
                valor_venda: values.valor_venda,
                tipo: values.tipo,
                terceirizado: values.terceirizado,
                ativo: values.ativo,
                comissao_interveniente: values.comissao_interveniente,
                requer_apontamento: values.requer_apontamento,
                template: values.template,
                custo_hora: values.custo_hora,
                detalhes: values.detalhes,
                template_origem: values.template_origem,
            };
            let dataVinculo = {
                contrato: contrato.id,
                servico: null,
                executor: values.executor.id,
                servico_municipio: values.servico_municipio.id,
                operacao_fiscal: values.operacao_fiscal,
                aliquota: values.aliquota,
            };
            if (!values.id) {
                showLoading();
                const { status, data } = await axiosPost("/servicos/servicos/", dataServico);
                hideLoading();
                if (status === 201) {
                    dataVinculo.servico = data.id;
                    showLoading();
                    const { status } = await axiosPost("/servicos/servicos-contrato/", dataVinculo);
                    hideLoading();
                    if (status === 201) {
                        showSuccess({
                            summary: "Sucesso",
                            detail: "Serviço cadastrato com sucesso!",
                            life: 1500,
                        });
                        atualizarValorContrato();
                        onFinish();
                    } else {
                        throw new Error("Não foi possivel cadastrar o vinculo de serviço");
                    }
                } else {
                    throw new Error("Não foi possivel cadastrar o serviço");
                }
            } else {
                if (dataServico.empresa instanceof Object) dataServico.empresa = dataServico.empresa.id;
                if (dataServico.template_origem instanceof Object)
                    dataServico.template_origem = dataServico.template_origem.id;
                dataVinculo.servico = values.servico_id;
                showLoading();
                const { status } = await axiosPatch(`/servicos/servicos/${values.servico_id}/`, dataServico);
                hideLoading();
                if (status === 200) {
                    showSuccess({
                        summary: "Sucesso",
                        detail: "Serviço alterado com sucesso!",
                        life: 1500,
                    });
                    showLoading();
                    const { status } = await axiosPatch(`/servicos/servicos-contrato/${values.id}/`, dataVinculo);
                    hideLoading();
                    if (status === 200) {
                        showSuccess({
                            summary: "Sucesso",
                            detail: "Serviço cadastrato com sucesso!",
                            life: 1500,
                        });
                        atualizarValorContrato();
                        onFinish();
                    } else {
                        throw new Error("Não foi possivel atualizar o vinculo de serviço");
                    }
                } else {
                    throw new Error("Não foi possivel alterar o serviço");
                }
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};

                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });

                formik.setErrors(errorMessages);
            } else {
                showError({
                    summary: "Erro :(",
                    detail: "Desculpe, não foi possível processar a sua requisição.",
                    life: 3000,
                });
            }
        }
    }

    function gerarCodigo() {
        setFieldValue("codigo", gerarStringAleatoria(10));
    }

    const formatarTemplates = useMemo(() => {
        return (data) => {
            const _data = data.map((item) => ({
                ...item,
                label: `${item.codigo} | ${item.descricao}`,
            }));
            setTemplates(_data);
            return _data;
        };
    }, [setTemplates]);

    const onChangeTercerizado = (e) => {
        const { checked } = e.target;
        if (!checked) setFieldValue("comissao_interveniente", 0);
        formik.handleChange(e);
    };

    const onChangeParticipacao = (e) => {
        const { value } = e;
        setFieldValue("terceirizado", value > 0);
        formik.handleChange(e);
    };

    const onChangeTemplateOrigem = (e) => {
        if (e.value) {
            const template = templates.find(({ id }) => id === e.value);
            if (template) {
                const data = { ...initialForm, ...template, template: false, empresa: template.empresa.id };
                delete data.id;
                setValues(data);
            }
        }
        formik.handleChange(e);
    };

    const onChangeUf = (e) => {
        if (!e.value) setFieldValue("municipio", null);
        formik.handleChange(e);
    };

    const onChangeServicoMunicipio = (e) => {
        if (e.value instanceof Object) setFieldValue("aliquota", e.value.aliquota);
        formik.handleChange(e);
    };

    const tipoValido = useMemo(() => {
        if (formik.values.tipo === "R") {
            setFieldValue("comissao_interveniente", 0);
            setFieldValue("terceirizado", false);
            return false;
        }
        return true;
    }, [formik.values.tipo, setFieldValue]);

    const urlServico = useMemo(() => {
        return `/servicos/servicos-municipio/?municipio=${formik.values.municipio}&search=`;
    }, [formik.values.municipio]);

    useEffect(() => {
        if (servico) {
            const _servico = { ...servico };
            delete _servico.servico.apontamento;
            setValues({
                executor: _servico.executor,
                servico_municipio: _servico.servico_municipio,
                aliquota: _servico.aliquota,
                uf: _servico.servico_municipio.municipio.estado.id,
                municipio: _servico.servico_municipio.municipio.id,
                ..._servico.servico,
                id: _servico.id,
                servico_id: _servico.servico.id,
                operacao_fiscal: _servico.operacao_fiscal,
            });
        }
    }, [servico, setValues]);

    return (
        <>
            <form onSubmit={formik.handleSubmit}>
                <div className="p-fluid p-formgrid p-grid">
                    {!novoServico ? (
                        <>
                            <div className="p-field p-col-12 p-md-2">
                                <label htmlFor="codigo">Código *</label>
                                <div className="p-inputgroup">
                                    <InputText
                                        id="codigo"
                                        name="codigo"
                                        onChange={formik.handleChange}
                                        value={formik.values.codigo}
                                        autoComplete="off"
                                        maxLength={12}
                                        className={classNames({ "p-invalid": formik.errors.codigo })}
                                    />
                                    <Button
                                        type="button"
                                        icon="pi pi-refresh"
                                        className="p-button-info"
                                        tooltip="Gerar código aleatório"
                                        onClick={gerarCodigo}
                                    />
                                </div>
                                {formik.errors.codigo && <small className="p-error">{formik.errors.codigo}</small>}
                            </div>
                            <div className="p-field p-col-12 p-md-7">
                                <label htmlFor="descricao">Descrição *</label>
                                <InputText
                                    id="descricao"
                                    name="descricao"
                                    onChange={formik.handleChange}
                                    value={formik.values.descricao}
                                    autoComplete="off"
                                    maxLength={100}
                                    className={classNames({ "p-invalid": formik.errors.descricao })}
                                />
                                {formik.errors.descricao && (
                                    <small className="p-error">{formik.errors.descricao}</small>
                                )}
                            </div>
                        </>
                    ) : (
                        <>
                            <div className="p-field p-col-12 p-md-9">
                                <Label obrigatorio={!formik.values.template} label="Serviço de origem" />
                                <Dropdown
                                    id="template_origem"
                                    name="template_origem"
                                    url={`/servicos/servicos/?query={id,empresa,codigo,descricao,custo,valor_venda,tipo,terceirizado,ativo,comissao_interveniente,requer_apontamento,template,custo_hora,detalhes}&empresa=${empresaSelecionadaId}&ativo=true&template=true`}
                                    buscar={!!empresaSelecionadaId}
                                    aposBuscar={formatarTemplates}
                                    filter
                                    showClear
                                    filterBy="label"
                                    optionValue="id"
                                    optionLabel="label"
                                    onChange={onChangeTemplateOrigem}
                                    value={formik.values.template_origem}
                                    className={classNames({ "p-invalid": formik.errors.template_origem })}
                                />
                                {formik.errors.template_origem && (
                                    <small className="p-error">{formik.errors.template_origem}</small>
                                )}
                            </div>
                        </>
                    )}
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="tipo">Tipo *</label>
                        <Dropdown
                            id="tipo"
                            name="tipo"
                            options={TIPOS_CHOICE_SERVICOS_SERVICO}
                            optionValue="value"
                            optionLabel="label"
                            onChange={formik.handleChange}
                            disabled={!!novoServico}
                            value={formik.values.tipo}
                            className={classNames({ "p-invalid": formik.errors.tipo })}
                        />
                        {formik.errors.tipo && <small className="p-error">{formik.errors.tipo}</small>}
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="custo">Custo *</label>
                        <MakoInputMoeda
                            id="custo"
                            name="custo"
                            onChangeMoeda={formik.handleChange}
                            valueMoeda={formik.values.custo}
                            className={classNames({ "p-invalid": formik.errors.custo })}
                        />
                        {formik.errors.custo && <small className="p-error">{formik.errors.custo}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="valor_venda">Preço</label>
                        <MakoInputMoeda
                            id="valor_venda"
                            name="valor_venda"
                            onChangeMoeda={formik.handleChange}
                            valueMoeda={formik.values.valor_venda}
                            className={classNames({ "p-invalid": formik.errors.valor_venda })}
                        />
                        {formik.errors.valor_venda && <small className="p-error">{formik.errors.valor_venda}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="comissao_interveniente">% participação</label>
                        <MakoInputPercent
                            id="comissao_interveniente"
                            name="comissao_interveniente"
                            onValueChange={onChangeParticipacao}
                            value={formik.values.comissao_interveniente}
                            disabled={!tipoValido || !formik.values.terceirizado}
                            className={classNames({ "p-invalid": formik.errors.comissao_interveniente })}
                        />
                        {formik.errors.comissao_interveniente && (
                            <small className="p-error">{formik.errors.comissao_interveniente}</small>
                        )}
                    </div>
                    <div className="p-field-checkbox p-col-12 p-md-2 p-mt-5">
                        <Checkbox
                            id="terceirizado"
                            name="terceirizado"
                            checked={formik.values.terceirizado}
                            disabled={!tipoValido}
                            onChange={onChangeTercerizado}
                        />
                        <label htmlFor="terceirizado">Terceirizado?</label>
                    </div>
                    <div className="p-field-checkbox p-col-12 p-md-2 p-mt-5">
                        <Checkbox
                            id="custo_hora"
                            name="custo_hora"
                            checked={formik.values.custo_hora}
                            onChange={formik.handleChange}
                        />
                        <label htmlFor="custo_hora">Custo por hora?</label>
                    </div>
                    <div className="p-field-checkbox p-col-12 p-md-2 p-mt-5">
                        <Checkbox
                            id="requer_apontamento"
                            name="requer_apontamento"
                            checked={formik.values.requer_apontamento}
                            onChange={formik.handleChange}
                        />
                        <label htmlFor="requer_apontamento">Apontar?</label>
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-12">
                        <label htmlFor="detalhes">Detalhes do serviço</label>
                        <InputTextarea
                            id="detalhes"
                            name="detalhes"
                            value={formik.values.detalhes}
                            onChange={formik.handleChange}
                            rows={2}
                            autoResize
                            maxLength={4096}
                            className={classNames({ "p-invalid": formik.errors.detalhes })}
                        />
                        {formik.errors.detalhes && <small className="p-error">{formik.errors.detalhes}</small>}
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-6">
                        <label htmlFor="executor">Executor (Terceiro) *</label>
                        <MakoInputPerfil
                            id="executor"
                            name="executor"
                            value={formik.values.executor}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.executor })}
                        />
                        {formik.errors.executor && <small className="p-error">{formik.errors.executor}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="uf">UF *</label>
                        <Dropdown
                            id="uf"
                            name="uf"
                            url="/pessoas/estados?query={id,nome,uf,codigo_uf}&&limit=100"
                            optionLabel="nome"
                            optionValue="id"
                            filter
                            showClear
                            filterBy="nome"
                            value={formik.values.uf}
                            onChange={onChangeUf}
                            className={classNames({
                                "p-invalid": formik.errors.uf,
                            })}
                        />
                        {formik.errors.uf && <small className="p-error">{formik.errors.uf}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="municipio">Município *</label>
                        <Dropdown
                            id="municipio"
                            name="municipio"
                            url={`/pessoas/cidades/?estado=${formik.values.uf}&query={id,nome}&limit=1000&ordering=nome`}
                            buscar={!!formik.values.uf}
                            optionLabel="nome"
                            optionValue="id"
                            filter
                            showClear
                            filterBy="nome"
                            value={formik.values.municipio}
                            onChange={formik.handleChange}
                            className={classNames({
                                "p-invalid": formik.errors.municipio,
                            })}
                        />
                        {formik.errors.municipio && <small className="p-error">{formik.errors.municipio}</small>}
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-6">
                        <label htmlFor="servico_municipio">Serviço do cadastro municipal *</label>
                        <MakoAutoComplete
                            id="servico_municipio"
                            name="servico_municipio"
                            minCaracteresBusca={3}
                            field="descricao"
                            placeholder="Comece a digitar para buscar... (min 3 caracteres)"
                            urlSearch={urlServico}
                            value={formik.values.servico_municipio}
                            onChange={onChangeServicoMunicipio}
                            className={classNames({ "p-invalid": formik.errors.servico_municipio })}
                        />
                        {formik.errors.servico_municipio && (
                            <small className="p-error">{formik.errors.servico_municipio}</small>
                        )}
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="aliquota">Aliquota</label>
                        <MakoInputPercent
                            id="aliquota"
                            name="aliquota"
                            onValueChange={formik.handleChange}
                            value={formik.values.aliquota}
                            className={classNames({ "p-invalid": formik.errors.aliquota })}
                        />
                        {formik.errors.aliquota && <small className="p-error">{formik.errors.aliquota}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-4">
                        <label htmlFor="operacao_fiscal">Operação fiscal do serviço *</label>
                        <Dropdown
                            id="operacao_fiscal"
                            name="operacao_fiscal"
                            url={`/servicos/operacoes-fiscais-servicos/?empresa=${empresaSelecionadaId}`}
                            buscar={!!empresaSelecionadaId}
                            optionLabel="descricao"
                            optionValue="id"
                            filter
                            showClear
                            filterBy="descricao"
                            value={formik.values.operacao_fiscal}
                            onChange={formik.handleChange}
                            className={classNames({
                                "p-invalid": formik.errors.operacao_fiscal,
                            })}
                        />
                        {formik.errors.operacao_fiscal && (
                            <small className="p-error">{formik.errors.operacao_fiscal}</small>
                        )}
                    </div>
                </div>
                <div className="p-grid p-col-12 p-md-6 p-mt-2">
                    <Button icon="pi pi-save" label="Gravar" type="submit" className="p-button-info p-mr-2" />
                    <Button
                        icon="pi pi-trash"
                        label="Limpar"
                        type="button"
                        className="p-button-warning p-mr-2"
                        onClick={formik.resetForm}
                    />
                    <Button label="Cancelar" type="button" className="p-button-danger" onClick={onClose} />
                </div>
            </form>
        </>
    );
};
