import React, { useCallback, useEffect, useState, useRef } from "react";
import { InputText } from "primereact/inputtext";
import { Panel } from "primereact/panel";
import { Button } from "primereact/button";

import { ModalEditarParcela } from "./ModalEditarParcela";
import MakoListagem from "@/components/MakoListagem";
import { MakoInputMoeda } from "@/components/MakoInputMoeda";
import { LancamentoParcelas } from "@/components/LancamentoParcelas";
import { dataToStr } from "@/assets/util/datas";
import { parseMoeda } from "@/assets/util/util";
import { parseNumber } from "@/assets/helpers/number";
import { axiosGet, axiosPost } from "@/services/http";
import useEntrada from "@/hooks/useEntrada";
import useFormatCNPJCPF from "@/hooks/useFomatCNPJCPF";
import useToast from "@/hooks/useToast";
import useClearRefs from "@/hooks/useClearRefs";

export const FinanceiroFreteEntradaForm = () => {
    const [parcelas, setParcelas] = useState([]);
    const [loading, setLoading] = useState(false);
    const [transportador, setTransportador] = useState(null);
    const [toggleInfoEntrada, setToggleInfoEntrada] = useState(true);
    const [toggleInfoFrete, setToggleInfoFrete] = useState(true);
    const listagemRef = useRef(null);
    const modalParcelaRef = useRef(null);
    const [formatarDocumento] = useFormatCNPJCPF();
    const { dadosBasicos, permiteAlterar } = useEntrada();
    const { showSuccess, showWarning, showError } = useToast();

    useClearRefs(listagemRef, modalParcelaRef);

    const edicaoBloqueada = !permiteAlterar;

    async function handleSubmit() {
        const parcelasInvalidas = parcelas.filter(
            (p) =>
                !p.emissao ||
                !p.template_rateio ||
                !p.historico_padrao ||
                p.forma_pagamento === null ||
                p.forma_pagamento === undefined
        );
        if (parcelasInvalidas.length > 0) {
            showWarning({
                summary: "Aviso!",
                detail: "Você precisa configurar as formas de pagamento das parcelas.",
                life: 3000,
            });
            return;
        }
        const totalParcelas = parcelas.reduce((total, parcela) => total + parseNumber(parcela.valor_parcela), 0);
        if (parseNumber(totalParcelas.toFixed(2)) !== parseNumber(dadosBasicos.valor_total_frete)) {
            showWarning({
                summary: "Aviso!",
                detail: "O somatório das parcelas é diferente do valor total de parcelas da entrada.",
                life: 3000,
            });
            return;
        }
        const parcelasFormatadas = parcelas.map((parcela) => ({
            ...parcela,
            parcela_frete: true,
            emissao: dataToStr(parcela.emissao, "yyyy-MM-dd"),
            vencimento: dataToStr(parcela.vencimento, "yyyy-MM-dd"),
            entrada: dadosBasicos?.id,
            forma_pagamento: parcela.forma_pagamento.id,
            historico_padrao: parcela.historico_padrao.id,
            template_rateio: parcela.template_rateio.id,
            numero_boleto: parcela.forma_pagamento.tipo.id === 5 ? parcela.numero_boleto : "",
            numero_cheque: parcela.forma_pagamento.tipo.id === 4 ? parcela.numero_cheque : "",
            conta_bancaria:
                parcela.forma_pagamento.tipo.id === 7 && parcela.conta_bancaria ? parcela.conta_bancaria.id : null,
            chave_pix: parcela.forma_pagamento.tipo.id === 3 && parcela.chave_pix ? parcela.chave_pix.id : null,
        }));
        const { status, data } = await axiosPost("/compras/parcelas-entradas/", parcelasFormatadas);
        if (status === 201) {
            showSuccess({
                summary: "Sucesso!",
                detail: "Parcelas lançadas com sucesso.",
                life: 1500,
            });
            setParcelas((old) =>
                old.map((p) => {
                    delete p._editada;
                    return p;
                })
            );
        } else if (status < 500) {
            showWarning({
                summary: "Aviso!",
                detail: data?.message || data?.msg || "Não foi possível lançar as parcelas.",
                life: 4000,
            });
        } else {
            showError({
                summary: "Erro :(",
                detail: "Desculpe, não foi possível processar sua requisição.",
                life: 3000,
            });
        }
    }

    const popularParcelas = (parcelas) => {
        setParcelas(
            parcelas.map((parcela) => ({
                credor: transportador?.id,
                numero_parcela: parcela.numParcela,
                emissao: dadosBasicos?.datahora_entrada,
                vencimento: parcela.vencimento,
                valor_parcela: parcela.valorParcela,
                _editada: true,
            }))
        );
    };

    const carregarParcelas = useCallback(async () => {
        setLoading(true);
        const { status, data } = await axiosGet(`/compras/parcelas-frete-entradas/?entrada=${dadosBasicos?.id}`);
        setLoading(false);
        if (status === 200) {
            setParcelas(data.results);
        } else {
            showError({
                summary: "Erro :(",
                detail: "Não foi possível carregar as parcelas da entrada.",
                life: 3000,
            });
        }
    }, [dadosBasicos, showError]);

    useEffect(() => {
        carregarParcelas();
    }, [carregarParcelas]);

    const carregarTransportadorFreteAvulso = useCallback(async () => {
        const { status, data } = await axiosGet(
            `/compras/entrada-fretes/?entrada=${dadosBasicos?.id}&chave__isnull=True&chave_nf__isnull=True`
        );
        if (status === 200) {
            if (data.count > 0) {
                const [transportador] = data.results;
                setTransportador(transportador.transportador);
            }
        } else {
            showError({
                summary: "Erro :(",
                detail: "Não foi possível carregar as parcelas da entrada.",
                life: 3000,
            });
        }
    }, [dadosBasicos, showError]);

    useEffect(() => {
        carregarTransportadorFreteAvulso();
    }, [carregarTransportadorFreteAvulso]);

    const infoPgtoBodyTemplate = (rowData) => {
        if (rowData.forma_pagamento?.tipo.id === 3 && rowData.chave_pix) {
            const { chave_pix } = rowData;
            return <span>{`${chave_pix.tipo_chave.descricao} - ${chave_pix.chave}`}</span>;
        } else if (rowData.forma_pagamento?.tipo.id === 4) {
            return <span>{rowData.numero_cheque}</span>;
        } else if (rowData.forma_pagamento?.tipo.id === 5) {
            return <span>Boleto bancário</span>;
        } else if (rowData.forma_pagamento?.tipo.id === 7 && rowData.conta_bancaria) {
            const { conta_bancaria } = rowData;
            return (
                <span>{`Bco: ${conta_bancaria.banco.descricao} - AG: ${conta_bancaria.agencia} - CC: ${conta_bancaria.numero_conta}-${conta_bancaria.numero_conta_dv}`}</span>
            );
        }
        return <span></span>;
    };

    const editarParcelas = (e) => {
        let _parcelas = [...parcelas];
        const { rowIndex, alterar_todas, ...parcelaEditada } = e;
        if (rowIndex === 0 && alterar_todas) {
            _parcelas = _parcelas.map((parcela) => {
                return {
                    ...parcela,
                    emissao: parcelaEditada.emissao,
                    adiantamento: parcelaEditada.adiantamento,
                    forma_pagamento: parcelaEditada.forma_pagamento,
                    historico_padrao: parcelaEditada.historico_padrao,
                    template_rateio: parcelaEditada.template_rateio,
                    chave_pix: parcelaEditada.chave_pix,
                    numero_cheque: parcelaEditada.numero_cheque,
                    numero_boleto: parcelaEditada.numero_boleto,
                    conta_bancaria: parcelaEditada.conta_bancaria,
                    _editada: true,
                };
            });
        } else {
            _parcelas[rowIndex] = parcelaEditada;
        }
        setParcelas(_parcelas);
    };

    const actionBodyTemplate = (rowData, { rowIndex }) => {
        return (
            <div className="actions">
                <Button
                    icon="pi pi-pencil"
                    disabled={edicaoBloqueada}
                    className="p-button-rounded p-button-warning p-mr-2 p-mb-1"
                    onClick={() => modalParcelaRef.current?.abrirModal({ ...rowData, rowIndex })}
                />
            </div>
        );
    };

    const rowClass = (rowData) => {
        return {
            "table-recebimentos-overdue": rowData._editada,
        };
    };

    const colunas = [
        { field: "numero_parcela", header: "Parcela", style: { width: "8%" } },
        {
            field: "emissao",
            header: "Emissão",
            dateFormat: "dd/MM/yyyy",
            style: { width: "8%" },
        },
        {
            field: "vencimento",
            header: "Vencimento",
            dateFormat: "dd/MM/yyyy",
            style: { width: "8%" },
        },
        {
            field: "valor_parcela",
            header: "Valor",
            money: true,
            style: { width: "10%" },
        },
        {
            field: "forma_pagamento.descricao",
            header: "Forma pgto",
            style: { width: "12%" },
        },
        { field: "template_rateio.descricao", header: "Regra de rateio", style: { width: "15%" } },
        { field: "historico_padrao.descricao", header: "Histórico", style: { width: "20%" } },
        {
            field: "info_pgto",
            header: "Info. pagamento",
            action: (e) => infoPgtoBodyTemplate(e),
        },
        { field: "actions", header: "Ações", style: { width: "8%" }, action: actionBodyTemplate },
    ];

    return (
        <>
            <Panel
                header="Informações da entrada"
                toggleable
                collapsed={toggleInfoEntrada}
                onToggle={(e) => setToggleInfoEntrada(e.value)}
            >
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="n-entrada">Número da entrada</label>
                        <InputText id="n-entrada" disabled value={dadosBasicos?.id || 0} />
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="data-entrada"> Data da entrada</label>
                        <InputText
                            id="data-entrada"
                            disabled
                            value={dataToStr(dadosBasicos?.datahora_entrada, "dd/MM/yyyy") || ""}
                        />
                    </div>
                    <div className="p-field p-col-12 p-md-5">
                        <label htmlFor="fornecedor">Fornecedor</label>
                        <InputText id="fornecedor" disabled value={dadosBasicos?.fornecedor?.nome || ""} />
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="identificacao">CPF / CNPJ</label>
                        <InputText
                            id="identificacao"
                            disabled
                            value={formatarDocumento(dadosBasicos?.fornecedor?.identificacao)}
                        />
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="valor-frete">Valor total do frete</label>
                        <MakoInputMoeda id="valor-frete" disabled valueMoeda={dadosBasicos?.valor_total_frete} />
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="valor-produtos">Valor total dos produtos</label>
                        <MakoInputMoeda id="valor-produtos" disabled valueMoeda={dadosBasicos?.valor_total_produtos} />
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="valor-entrada">Valor total da entrada</label>
                        <MakoInputMoeda id="valor-entrada" disabled valueMoeda={dadosBasicos?.valor_total_entrada} />
                    </div>
                </div>
            </Panel>
            <Panel
                header="Informações do frete"
                toggleable
                collapsed={toggleInfoFrete}
                onToggle={(e) => setToggleInfoFrete(e.value)}
                className="p-mt-3 p-mb-3"
            >
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-6">
                        <label htmlFor="transportador">Transportador frete avulso</label>
                        <InputText id="transportador" disabled value={transportador?.nome || ""} />
                    </div>
                </div>
            </Panel>
            <LancamentoParcelas
                valorBruto={dadosBasicos?.valor_total_frete}
                vencimento={dadosBasicos?.datahora_entrada}
                minVencimento={dadosBasicos?.datahora_entrada}
                onSubmit={popularParcelas}
            />
            <MakoListagem
                dadosLocal={parcelas}
                colunas={colunas}
                configTabela={{ paginator: true, loading, rowClassName: rowClass }}
            />
            {parcelas.filter((p) => p._editada).length > 0 && (
                <p className="p-error p-mt-2">É necessário realizar uma nova gravação das parcelas.</p>
            )}
            <div className="p-mt-3">
                <div className="p-text-left">
                    <Button
                        icon="pi pi-plus"
                        label="Gravar parcelas"
                        disabled={edicaoBloqueada}
                        onClick={handleSubmit}
                        className="p-button-success"
                    />
                </div>
                <div className="p-text-right">
                    <h5>{`Total das parcelas: ${parseMoeda(
                        parcelas.reduce((total, parcela) => total + parseNumber(parcela.valor_parcela), 0),
                        true
                    )}`}</h5>
                </div>
            </div>
            <ModalEditarParcela ref={modalParcelaRef} parcelas={parcelas} onSuccess={editarParcelas} />
        </>
    );
};
