import React, { useCallback, useRef } from "react";

import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import { ModaisPendencias } from "../../../modals";
import { MakoButton } from "@/components/MakoButton";

import permissoes from "@/assets/constants/permissoes";

import useAtribuirPendenciaCaixa from "../hooks/useAtribuirPendencia";
import useCaixaMovimento from "@/hooks/useCaixaMovimento";
import useLoadingLocal from "@/hooks/useLoadingLocal";

const { ReceberEntrada: ModalRecebimentoEntrada } = ModaisPendencias;

const { FINANCEIRO_FINANCEIRO_CAIXAMOV_PENDENCIAS_PROCESSAR, FINANCEIRO_FINANCEIRO_CAIXAMOV_PENDENCIAS_ATRIBUIR } =
    permissoes;

export const ReceberEntrada = ({ pendencia, onCloseModal = () => {} }) => {
    const [loading, showLoading, hideLoading] = useLoadingLocal();
    const { caixaMov } = useCaixaMovimento();

    const handler = useAtribuirPendenciaCaixa(caixaMov?.id);
    const modalRef = useRef(null);

    const onClick = useCallback(async () => {
        showLoading();
        const data = await handler({ pendencia_id: pendencia?.id, tipo_chave: pendencia?.tipo_chave });
        hideLoading();
        if (data) modalRef.current?.show({ pendencia: { ...data, tipo_chave: pendencia?.tipo_chave } });
    }, [pendencia, handler, showLoading, hideLoading]);

    return (
        <>
            <MakoControleAcesso
                componente={MakoButton}
                permissao={[
                    FINANCEIRO_FINANCEIRO_CAIXAMOV_PENDENCIAS_PROCESSAR,
                    FINANCEIRO_FINANCEIRO_CAIXAMOV_PENDENCIAS_ATRIBUIR,
                ]}
                label="Receber entrada"
                className="p-button p-button-success p-py-1 p-text-bold"
                type="button"
                onClick={onClick}
                loading={loading}
                disabled={caixaMov?.bloqueado}
            />
            <ModalRecebimentoEntrada ref={modalRef} onHide={onCloseModal} />
        </>
    );
};
