import React, { useRef, useState } from "react";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { axiosGet } from "@/services/http";
import { InputTextarea } from "primereact/inputtextarea";
import useLoading from "@/hooks/useLoading";
import axios from "axios";
import { PageBase } from "@/components/PageBase";
import { Dialog } from "primereact/dialog";
import useEmpresa from "@/hooks/useEmpresa";

export const CartaCorrecaoNFSFormModal = (props) => {
    const [correcao, setCorrecao] = useState("");
    const { empresaSelecionada } = useEmpresa();
    const { showLoading, hideLoading } = useLoading();
    const toastRef = useRef(null);

    async function handleSubmit() {
        try {
            showLoading();
            const apiServico = await axiosGet("/configuracoes/api-servicos/");
            hideLoading();

            const body = [
                {
                    ModeloDocumento: "NFSe",
                    Versao: 1,
                    Evento: {
                        CNPJ: empresaSelecionada.identificacao,
                        NFSNumero: `${props.nota.rps_numero}`,
                        RPSNumero: `${props.nota.rps_numero}`,
                        RPSSerie: `${props.nota.rps_serie}`,
                        EveTp: 110111,
                        tpAmb: 2,
                        EveCodigo: "1",
                        EveMotivo: correcao,
                    },
                },
            ];

            showLoading();
            const resposta = await axios.post(
                `http://localhost:4000/documents/${apiServico.data.results[0].chave_acesso}/${apiServico.data.results[0].cnpj_integrado}/nfe/eventos`,
                body
            );
            hideLoading();

            if (resposta.data?.length > 0)
                if (resposta.data[0].Codigo === 100 || resposta.data[0].Codigo === 101) {
                    toastRef.current.show({
                        severity: resposta.data[0].Documentos[0].Situacao.SitCodigo === 220 ? "warn" : "success",
                        detail: `${resposta.data[0].Documentos[0].Situacao.SitDescricao}!
                         \n
                         Código: ${resposta.data[0].Documentos[0].Situacao.SitCodigo}`,
                        life: 5000,
                    });
                    setTimeout(() => {
                        props.dialog(false);
                    }, 2000);
                } else {
                    toastRef.current.show({
                        severity: "warn",
                        summary: "Falha",
                        detail: `${resposta.data[0].Descricao} \n
                    Código: ${resposta.data[0].Codigo}`,
                        life: 5000,
                    });
                }
            else if (resposta.data)
                toastRef.current.show({
                    severity: "warn",
                    summary: "Falha",
                    detail: `${resposta.data.Descricao} \n
                    Código: ${resposta.data.Codigo}`,
                    life: 5000,
                });
        } catch (error) {
            toastRef.current.show({
                severity: "error",
                summary: "Erro",
                detail: `Falha ao comunicar com o agente fiscal.`,
                life: 3000,
            });
        }
    }

    function validateForm() {
        return correcao.length >= 15;
    }

    return (
        <>
            <Dialog
                header="Carta de Correção (CC-e)"
                visible={props.correcaoDialog}
                breakpoints={{ "960px": "80vw" }}
                style={{ width: "35vw" }}
                onHide={() => props.setCorrecaoDialog(false)}
            >
                <Toast ref={toastRef} />
                <form>
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-12 p-md-12">
                            <InputTextarea
                                id="correcao"
                                placeholder="Digite a correção (min. 15 caracteres)"
                                value={correcao}
                                rows={6}
                                onChange={(e) => setCorrecao(e.target.value)}
                                autoComplete="off"
                            />
                        </div>
                    </div>
                    <div className="p-grid p-col-12 p-md-12">
                        <Button
                            label="Enviar"
                            type="button"
                            className="p-button-info"
                            disabled={!validateForm()}
                            onClick={handleSubmit}
                        />
                    </div>
                </form>
            </Dialog>
        </>
    );
};
