import React, { useRef } from "react";
import classNames from "classnames";
import { InputText } from "primereact/inputtext";
import { Checkbox } from "primereact/checkbox";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { InputNumber } from "primereact/inputnumber";
import { useFormik } from "formik";
import * as Yup from "yup";
import { axiosPatch, axiosPost } from "@/services/http";
import useLoading from "@/hooks/useLoading";
import { useHistory } from "react-router-dom";

const url = "/produtos/formacoes-precos/";
const urlvoltar = "/vendas/cadastros/formacao-precos";

export const FormacaoPrecoForm = (props) => {
    const history = useHistory();
    const { showLoading, hideLoading } = useLoading();
    const toastRef = useRef(null);
    const paramVazio = {
        descricao: "",
        margem_lucro: 0,
        encargo_alvo: 0,
        indice_markup: 0,
        usar_markup: false,
    };

    const formik = useFormik({
        initialValues: props.location.state ? props.location.state.formacao : paramVazio,
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                descricao: Yup.string().required("O campo 'descrição' é obrigatório."),
                margem_lucro: Yup.number().required("O campo 'margem de lucro' é obrigatório."),
                encargo_alvo: Yup.number().required("O campo 'encargo alvo' é obrigatório."),
                indice_markup: Yup.number().when("usar_markup", {
                    is: (val) => val === true,
                    then: Yup.number()
                        .min(0.01, "O campo 'índice de markup' não pode ser ZERO.")
                        .required("O campo 'índice de markup' é obrigatório."),
                }),
            });

            await formSchema.validate(values, {
                abortEarly: false,
            });

            if (!values.id) {
                showLoading();
                const resposta = await axiosPost(`${url}`, values);
                hideLoading();

                if (resposta.status === 201) {
                    toastRef.current.show({
                        severity: "success",
                        summary: "Sucesso",
                        detail: "Formação de preços cadastrada com sucesso!",
                        life: 1500,
                    });

                    setTimeout(() => {
                        formik.resetForm();
                        history.push(urlvoltar);
                    }, 2000);
                } else {
                    toastRef.current.show({
                        severity: "error",
                        summary: "Erro :(",
                        detail: "Desculpe, não conseguimos processar a sua requisição.",
                        life: 1500,
                    });
                }
            } else {
                showLoading();
                const resposta = await axiosPatch(`${url}${values.id}/`, values);
                hideLoading();

                if (resposta.status === 200) {
                    toastRef.current.show({
                        severity: "success",
                        summary: "Sucesso",
                        detail: "Formação de preços de vendas alterada com sucesso!",
                        life: 1500,
                    });

                    setTimeout(() => {
                        formik.resetForm();
                        history.push(urlvoltar);
                    }, 2000);
                } else {
                    toastRef.current.show({
                        severity: "error",
                        summary: "Erro :(",
                        detail: "Desculpe, não conseguimos processar a sua requisição.",
                        life: 1500,
                    });
                }
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};

                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });

                formik.setErrors(errorMessages);
            }
        }
    }

    const cancelar = () => {
        formik.resetForm();
        history.push(urlvoltar);
    };

    return (
        <div className="p-grid">
            <Toast ref={toastRef} />
            <div className="p-col-12">
                <div className="card">
                    <h3>{!formik.values.id ? "Nova formação de preços" : "Manutenção de formação de preços"}</h3>
                    <form onSubmit={formik.handleSubmit}>
                        <div className="p-fluid p-formgrid p-grid">
                            <div className="p-field p-col-12">
                                <label htmlFor="descricao">Descrição *</label>
                                <InputText
                                    id="descricao"
                                    name="descricao"
                                    value={formik.values.descricao}
                                    onChange={formik.handleChange}
                                    className={classNames({ "p-invalid": formik.errors.descricao })}
                                    tooltip="Descrição que será dada a formação de preços."
                                    tooltipOptions={{ position: "top" }}
                                />
                                {formik.errors.descricao && (
                                    <small className="p-error">{formik.errors.descricao}</small>
                                )}
                            </div>
                        </div>
                        <div className="p-fluid p-formgrid p-grid">
                            <div className="p-field p-col-12 p-md-3">
                                <label htmlFor="margem_lucro">% Margem Lucro *</label>
                                <InputNumber
                                    id="margem_lucro"
                                    name="margem_lucro"
                                    prefix="% "
                                    min={0}
                                    mode="decimal"
                                    maxFractionDigits={2}
                                    value={formik.values.margem_lucro}
                                    onValueChange={formik.handleChange}
                                    className={classNames({ "p-invalid": formik.errors.margem_lucro })}
                                />
                                {formik.errors.margem_lucro && (
                                    <small className="p-error">{formik.errors.margem_lucro}</small>
                                )}
                            </div>
                            <div className="p-field p-col-12 p-md-3">
                                <label htmlFor="encargo_alvo">% Encargo Alvo *</label>
                                <InputNumber
                                    id="encargo_alvo"
                                    name="encargo_alvo"
                                    prefix="% "
                                    min={0}
                                    mode="decimal"
                                    maxFractionDigits={2}
                                    value={formik.values.encargo_alvo}
                                    onValueChange={formik.handleChange}
                                    className={classNames({ "p-invalid": formik.errors.encargo_alvo })}
                                />
                                {formik.errors.encargo_alvo && (
                                    <small className="p-error">{formik.errors.encargo_alvo}</small>
                                )}
                            </div>
                            <div className="p-field-checkbox p-col-12 p-md-3 p-mt-4">
                                <Checkbox
                                    id="usar_markup"
                                    name="usar_markup"
                                    onChange={formik.handleChange}
                                    checked={formik.values.usar_markup}
                                />
                                <label htmlFor="usar_markup">Usar markup?</label>
                            </div>
                            <div className="p-field p-col-12 p-md-3">
                                <label htmlFor="indice_markup">% Indice Markup *</label>
                                <InputNumber
                                    id="indice_markup"
                                    name="indice_markup"
                                    prefix="% "
                                    min={0}
                                    mode="decimal"
                                    maxFractionDigits={6}
                                    disabled={!formik.values.usar_markup}
                                    value={formik.values.indice_markup}
                                    onValueChange={formik.handleChange}
                                    className={classNames({ "p-invalid": formik.errors.indice_markup })}
                                />
                                {formik.errors.indice_markup && (
                                    <small className="p-error">{formik.errors.indice_markup}</small>
                                )}
                            </div>
                        </div>
                        <p>
                            <b>* Campos obrigatórios.</b>
                        </p>

                        <div className="p-grid p-col-12 p-md-6">
                            <Button icon="pi pi-save" label="Gravar" type="submit" className="p-button-info p-mr-2" />
                            <Button
                                label="Cancelar"
                                icon="pi pi-angle-double-left"
                                type="reset"
                                className="p-button-danger"
                                onClick={cancelar}
                            />
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};
