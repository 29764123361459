import React, { memo, useCallback, useRef } from "react";

import { Button } from "primereact/button";

import { MakoActionsButtonsColumn } from "@/components/MakoActionsButtonsColumn";
import { MakoActionsButtons } from "@/components/MakoActionsButtons";

import { Buttons, Listagens, ModalAdicionarParcela, PainelResumoDevolucao, PainelResumoParcelas } from "./components";

import useDevolucaoFornecedor from "@/hooks/useDevolucaoFornecedor";
import useClearRefs from "@/hooks/useClearRefs";

const MemoButtonParcelaRemover = memo(Buttons.ParcelaRemover);
const MemoButtonParcelaEditar = memo(Buttons.ParcelaEditar);

export const DevolucaoFornecedorFormFinanceiro = () => {
    const { dadosBasicos, editavel } = useDevolucaoFornecedor();

    const modalEditarParcelaRef = useRef(null);
    const modalAddParcelaRef = useRef(null);
    const listagemRef = useRef(null);
    const painelTotaisRef = useRef(null);

    useClearRefs(modalAddParcelaRef, modalEditarParcelaRef, listagemRef, painelTotaisRef);

    const aposSalvar = useCallback(() => {
        listagemRef.current?.buscarDados();
        painelTotaisRef.current?.fetch();
    }, []);

    const actions = useCallback(
        ({ data, model, parcela, data_vencimento, pagamento_abatido }) => {
            const { quitado } = data;

            const onDelete = () => {
                listagemRef.current?.buscarDados();
                painelTotaisRef.current?.fetch();
            };

            return (
                <MakoActionsButtonsColumn>
                    <MemoButtonParcelaEditar
                        parcela={{ quitado, data, model, pagamento_abatido }}
                        disabled={!editavel}
                        successCallback={aposSalvar}
                    />
                    <MemoButtonParcelaRemover
                        disabled={!editavel}
                        parcela={{
                            id: data.id,
                            quitado,
                            model,
                            parcela,
                            data_vencimento,
                        }}
                        successCallback={onDelete}
                    />
                </MakoActionsButtonsColumn>
            );
        },
        [editavel, aposSalvar]
    );

    return (
        <>
            <PainelResumoDevolucao />
            <PainelResumoParcelas ref={painelTotaisRef} />
            <h5>Parcelas da entrada / compra</h5>
            <MakoActionsButtons className="p-mb-2">
                <Button
                    type="button"
                    icon="pi pi-plus"
                    label="Parcela"
                    severity="success"
                    onClick={() => modalAddParcelaRef.current?.abrirModal()}
                    disabled={!editavel}
                />
                <Buttons.ParcelaFrete
                    disabled={!editavel}
                    onClick={() => modalAddParcelaRef.current?.abrirModal(true)}
                />
            </MakoActionsButtons>
            <div className="p-mt-4">
                <Listagens.Parcelas
                    listagemRef={listagemRef}
                    devolucaoFornecedorId={dadosBasicos?.id}
                    actions={actions}
                />
            </div>
            <ModalAdicionarParcela ref={modalAddParcelaRef} aposSalvar={aposSalvar} />
        </>
    );
};
