import React, { useEffect, useRef } from "react";

import { Toast } from "primereact/toast";
import { useFormik } from "formik";
import { InputText } from "primereact/inputtext";
import { axiosPost, axiosPatch } from "@/services/http";
import { Button } from "primereact/button";
import { useHistory } from "react-router-dom";
import { dataToStr } from "@/assets/util/datas";
import useLoading from "@/hooks/useLoading";
import classNames from "classnames";
import * as Yup from "yup";
import { MakoCalendar } from "@/components/MakoCalendar";
import { Checkbox } from "primereact/checkbox";
import useEmpresa from "@/hooks/useEmpresa";

export const ParametroServicoForm = (props) => {
    const { empresaSelecionada } = useEmpresa();
    const { showLoading, hideLoading } = useLoading();
    const history = useHistory();
    const toastRef = useRef(null);

    const { setValues, setFieldValue, ...formik } = useFormik({
        initialValues: {
            empresa: empresaSelecionada?.id,
            descricao: "",
            vigencia_inicial: new Date(),
            vigencia_final: null,
            ativo: true,
        },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                descricao: Yup.string().required("O campo é obrigatório.").max(60),
                vigencia_inicial: Yup.date().required("O campo é obrigatório.").typeError("Informe uma data válida."),
            });

            await formSchema.validate(values, {
                abortEarly: false,
            });

            values.vigencia_final = dataToStr(values.vigencia_final, "yyyy-MM-dd");
            if (!values.id) {
                showLoading();
                const resposta = await axiosPost("/servicos/operacoes-fiscais-servicos/", values);
                hideLoading();

                if (resposta.status === 201) {
                    toastRef.current.show({
                        severity: "success",
                        summary: "Sucesso",
                        detail: "Operação financeira criada com sucesso!",
                        life: 1500,
                    });

                    toastRef.current.show({
                        severity: "info",
                        summary: "Operação cadastrada!",
                        detail: "Agora você já pode configurar as outras informações da operação fiscal de serviço.",
                        sticky: true,
                    });

                    setTimeout(() => {
                        formik.resetForm();
                        history.push({
                            pathname: "/fiscal/cadastros/operacoes-fiscais-servicos/form",
                            opFiscal: resposta.data,
                        });
                    }, 100);
                } else {
                    toastRef.current.show({
                        severity: "error",
                        summary: "Erro",
                        detail: "Desculpe, não conseguimos processar a sua requisição.",
                        life: 1500,
                    });
                }
            } else {
                showLoading();
                const resposta = await axiosPatch(`/servicos/operacoes-fiscais-servicos/${values.id}/`, values);
                hideLoading();

                if (resposta.status === 200) {
                    toastRef.current.show({
                        severity: "success",
                        summary: "Sucesso",
                        detail: "Operação financeira alterada com sucesso!",
                        life: 1500,
                    });
                } else {
                    toastRef.current.show({
                        severity: "error",
                        summary: "Erro",
                        detail: "Desculpe, não conseguimos processar a sua requisição.",
                        life: 1500,
                    });
                }
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
                hideLoading();
            } else {
                toastRef.current.show({
                    severity: "error",
                    summary: "Erro",
                    detail: "Desculpe, não conseguimos processar a sua requisição.",
                    life: 1500,
                });
                hideLoading();
            }
        }
    }

    useEffect(() => {
        if (props?.opFiscal) setValues(props.opFiscal);
    }, [props?.opFiscal, setValues]);

    const limparFormulario = () => {
        formik.resetForm();
    };

    return (
        <div className="p-grid">
            <div className="p-col-12">
                <div className="card">
                    <Toast ref={toastRef} />
                    <form onSubmit={formik.handleSubmit}>
                        <div className="p-fluid p-formgrid p-grid">
                            <div className="p-field p-col-12 p-md-8">
                                <label htmlFor="descricao">Descrição *</label>
                                <InputText
                                    id="descricao"
                                    name="descricao"
                                    value={formik.values.descricao}
                                    onChange={formik.handleChange}
                                    className={classNames({ "p-invalid": formik.errors.descricao })}
                                    autoComplete="off"
                                    autoFocus
                                />
                                {formik.errors.descricao && (
                                    <small className="p-error">{formik.errors.descricao}</small>
                                )}
                            </div>
                            <div className="p-field p-col-12 p-md-2">
                                <label htmlFor="vigencia_inicial">Vigência inicial</label>
                                <MakoCalendar
                                    id="vigencia_inicial"
                                    name="vigencia_inicial"
                                    valueCalendar={formik.values.vigencia_inicial}
                                    onChange={formik.handleChange}
                                    className={classNames({
                                        "p-invalid": formik.errors.vigencia_inicial,
                                    })}
                                />
                                {formik.errors.vigencia_inicial && (
                                    <small className="p-error">{formik.errors.vigencia_inicial}</small>
                                )}
                            </div>
                            <div className="p-field p-col-12 p-md-2">
                                <label htmlFor="vigencia_final">Vigência final</label>
                                <MakoCalendar
                                    id="vigencia_final"
                                    name="vigencia_final"
                                    valueCalendar={formik.values.vigencia_final}
                                    onChange={formik.handleChange}
                                    className={classNames({
                                        "p-invalid": formik.errors.vigencia_final,
                                    })}
                                />
                                {formik.errors.vigencia_final && (
                                    <small className="p-error">{formik.errors.vigencia_final}</small>
                                )}
                            </div>
                        </div>
                        <div className="p-fluid p-formgrid p-grid">
                            <div className="p-field-checkbox p-col-12 p-md-2">
                                <Checkbox
                                    id="ativo"
                                    name="ativo"
                                    checked={formik.values.ativo}
                                    onChange={formik.handleChange}
                                />
                                <label htmlFor="ativo">Ativo</label>
                            </div>
                        </div>
                        <div className="p-grid p-col-12 p-md-12 p-mt-1">
                            <Button
                                label={formik.values.id ? "Salvar" : "Criar Operação"}
                                icon="pi pi-save"
                                className="p-button-success p-mr-2"
                                type="submit"
                            />
                            <Button
                                label="Resetar"
                                className="p-button-warning p-mr-2"
                                icon="pi pi-trash"
                                type="reset"
                                disabled={formik.values?.id}
                                onClick={() => limparFormulario()}
                            />
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};
