import React from "react";
import { Button } from "primereact/button";
import useMakoPermissaoCookies from "@/hooks/useMakoPermissaoCookies";

const AppFooter = () => {
    const { abrirModalCookies } = useMakoPermissaoCookies();
    return (
        <div className="layout-footer">
            <div className="footer-logo-container">
                <img
                    id="footer-logo"
                    src={`${process.env.PUBLIC_URL}/assets/layout/images/logos-mako/logo_sysmako_lateral_gestao_ROSA_BRANCO.png`}
                    style={{ width: "100px", height: "30px" }}
                    alt="mako layout"
                />
            </div>
            <Button type="button" label="Política de Cookies" className="p-mt-1" text onClick={abrirModalCookies} />
            <span className="copyright">v0.91.7 &#169; {`${new Date().getFullYear()} - SharkDev`}</span>
        </div>
    );
};

export default AppFooter;
