import React, { createContext, useEffect, useCallback } from "react";
import useLocalStorage from "use-local-storage";

import { ROOT } from "@/assets/constants/permissoes";
import api from "@/services/axios";
import storageKeys from "@/assets/constants/storage";
import { axiosGet, axiosPost } from "@/services/http";
import useToast from "@/hooks/useToast";

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
    const [storageUser, setStorageUser] = useLocalStorage(storageKeys.USER_KEY);
    const [storageToken, setStorageToken] = useLocalStorage(storageKeys.TOKEN_KEY);
    const [storageParams, setStorageParams] = useLocalStorage(storageKeys.PARAMS_KEY);
    const [, setStorageCaixa] = useLocalStorage(storageKeys.CAIXA_KEY);
    const { showWarning, showError } = useToast();

    useEffect(() => {
        if (storageToken) {
            api.defaults.headers["Authorization"] = `token ${storageToken}`;
        }
    }, [storageToken]);

    const loadParams = useCallback(async () => {
        const { data } = await axiosGet("/configuracoes/parametros/?limit=1000");
        if (data?.results?.length > 0) setStorageParams([...data.results]);
    }, [setStorageParams]);

    const setCaixaUsuario = useCallback(
        (cx, clear = false) => {
            setStorageCaixa(cx);
        },
        [setStorageCaixa]
    );

    const reloadPermissions = useCallback(
        async (empresaId) => {
            if (storageUser) {
                const params = {
                    empresa: empresaId,
                    query: "{id, permissoes_vigentes,papeis_vigentes,ativo}",
                };
                const { status, data } = await axiosGet(`/pessoas/perfis/${storageUser.id}/`, { params });

                if (status === 200) {
                    const { permissoes_vigentes, papeis_vigentes } = data;
                    setStorageUser({
                        ...storageUser,
                        permissoes_vigentes: permissoes_vigentes,
                        papeis_vigentes: papeis_vigentes,
                    });
                } else {
                    showError({
                        summary: "Erro :(",
                        detail: "Desculpe, não foi possível recarregar as suas permissões.",
                        life: 3000,
                    });
                }
            }
        },
        [storageUser, setStorageUser, showError]
    );

    const signIn = useCallback(
        async (username, password) => {
            const { status: statusToken, data: dataToken } = await axiosPost("/auth/token/login", {
                username,
                password,
            });

            if (statusToken !== 200) {
                if (statusToken === 400) {
                    showWarning({
                        summary: "Credenciais inválidas!",
                        detail: "Impossível fazer login com as credenciais fornecidas.",
                        life: 3000,
                    });
                } else {
                    showError({
                        summary: "Erro",
                        detail: "A sua requisição não pode ser concluída.",
                        life: 3000,
                    });
                }

                return null;
            }

            const { auth_token } = dataToken;
            api.defaults.headers["Authorization"] = `token ${auth_token}`;
            setStorageToken(auth_token);

            const { status: statusMe, data: dataMe } = await axiosGet("/auth/users/me");

            if (statusMe !== 200) {
                showError({
                    summary: "Erro",
                    detail: "Desculpe, não foi possível encontrar os dados do perfil.",
                    life: 3000,
                });
                return null;
            }

            const params = {
                usuario__id: dataMe.id,
                query: "{id,usuario,usuario_empresapadrao,perfil_pf,permissoes_vigentes,papeis_vigentes,foto,nome,identificacao,tipo_pessoa,ativo}",
            };
            const { status: statuPerfil, data: dataPerfil } = await axiosGet("/pessoas/perfis/", { params });

            if (statuPerfil !== 200 || dataPerfil?.results?.length === 0) {
                showError({
                    summary: "Erro",
                    detail: "Desculpe, não foi possível encontrar os dados do perfil.",
                    life: 3000,
                });
                return null;
            }

            const [perfil] = dataPerfil.results;

            setStorageUser(perfil);
            await reloadPermissions(perfil.usuario_empresapadrao);
            loadParams();
            if (perfil.permissoes_vigentes?.length === 0 && perfil.papeis_vigentes?.length === 0)
                showWarning({
                    summary: "Aviso!",
                    detail: "O usuário atual não possui permissões/papeis vigêntes.",
                    sticky: true,
                });
            window.location.reload();
        },
        [setStorageUser, setStorageToken, reloadPermissions, loadParams, showWarning, showError]
    );

    const getParam = useCallback(
        (param, perfilId) => {
            if (param) {
                let parametro = storageParams?.find((p) => p.chave.chave === param);

                if (perfilId) {
                    parametro = storageParams?.find((p) => p.chave.chave === param && p.perfil.id === perfilId);
                }

                return parametro
                    ? {
                          id: parametro.id,
                          chave: parametro.chave?.chave,
                          valor: parametro.valor_id ? parametro.valor_id : parametro.valor,
                          perfil: parametro.perfil,
                      }
                    : null;
            }

            return null;
        },
        [storageParams]
    );

    const clearStorage = useCallback(() => {
        setStorageUser(null);
        setStorageToken(null);
        setStorageParams(null);
        setStorageCaixa(null);
    }, [setStorageUser, setStorageToken, setStorageParams, setStorageCaixa]);

    const signOut = useCallback(async () => {
        //await axiosPost("/auth/token/logout/");
        setStorageParams([]);
        clearStorage();
    }, [clearStorage, setStorageParams]);

    const verifyPermission = useCallback(
        (permissions) => {
            for (let x = 0; x < storageUser.permissoes_vigentes.length; x++) {
                const userPermission = storageUser.permissoes_vigentes[x].chave;
                for (let y = 0; y < permissions.length; y++) {
                    if (permissions[y] === userPermission || userPermission === ROOT) {
                        return true;
                    }
                }
            }

            storageUser.papeis_vigentes.forEach((papel) => {
                for (let x = 0; x < papel.permissoes.length; x++) {
                    const userPermission = papel.permissoes[x].chave;
                    for (let y = 0; y < permissions.length; y++) {
                        if (permissions[y] === userPermission || userPermission === ROOT) {
                            return true;
                        }
                    }
                }
            });

            return false;
        },
        [storageUser]
    );

    return (
        <AuthContext.Provider
            value={{
                signed: !!storageUser,
                user: storageUser,
                token: storageToken,
                params: setStorageParams,
                signIn,
                signOut,
                verifyPermission,
                reloadPermissions,
                loadParams,
                getParam,
                setCaixaUsuario,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

export default AuthContext;
