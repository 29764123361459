import React, { useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { Button } from "primereact/button";
import { ConfirmDialog } from "primereact/confirmdialog";

import MakoListagem from "@/components/MakoListagem";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import permissoes from "@/assets/constants/permissoes";
import { axiosDelete } from "@/services/http";
import useLoading from "@/hooks/useLoading";
import useToast from "@/hooks/useToast";
import useClearRefs from "@/hooks/useClearRefs";
import { CodigoFiltroTemplate, DropdownFiltroTemplate, TextoFiltroTemplate } from "@/components/MakoFiltrosCabecalho";
import { CLASSE_MOVIMENTACAO_ESTOQUE, TIPO_MOVIMENTACAO_ESTOQUE_CHOICE } from "@/assets/constants/constants";

export const TipoMovimentacaoEstoquePage = () => {
    const [deleteDialog, setDeleteDialog] = useState(false);
    const [tipoMovimentacaoEstoque, setTipoMovimentacaoEstoque] = useState(null);
    const listagemRef = useRef(null);
    const history = useHistory();
    const { showLoading, hideLoading } = useLoading();
    const { showSuccess, showError } = useToast();
    useClearRefs(listagemRef);

    const deletarTipoMovimentacaoEstoque = async () => {
        showLoading();
        const resp = await axiosDelete(`/produtos/tipos-movimentacoes-estoques/${tipoMovimentacaoEstoque.id}/`);
        hideLoading();

        if (resp.status === 204) {
            showSuccess({
                summary: "Sucesso",
                detail: "Tipo de movimentação de estoque deletado com sucesso!",
                life: 2000,
            });

            setTimeout(() => {
                fecharDeleteModal();
                listagemRef.current?.buscarDados();
            }, 2000);
        } else {
            showError({
                summary: "Erro :(",
                detail: "Desculpe, não conseguimos processar a sua requisição.",
                life: 3000,
            });
        }
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <MakoControleAcesso
                    permissao={[permissoes.ESTOQUE_MOVIMENTACOES_TIPOMOVIMENTACAO_EDITAR]}
                    componente={Button}
                    icon="pi pi-pencil"
                    className="p-button-rounded p-button-warning p-mr-2 p-mb-1"
                    tooltip="Alterar registro."
                    tooltipOptions={{ position: "left" }}
                    onClick={() => {
                        history.push({
                            pathname: "/estoque/cadastros/tipos-movimentacoes/form",
                            state: { ...rowData, classe_movimentacao: rowData.classe_movimentacao.id },
                        });
                    }}
                />
                <MakoControleAcesso
                    permissao={[permissoes.ESTOQUE_MOVIMENTACOES_TIPOMOVIMENTACAO_EXCLUIR]}
                    componente={Button}
                    icon="pi pi-trash"
                    className="p-button-rounded p-button-danger p-mr-2 p-mb-1"
                    tooltip="Deletar registro."
                    tooltipOptions={{ position: "left" }}
                    onClick={() => {
                        setDeleteDialog(true);
                        setTipoMovimentacaoEstoque(rowData);
                    }}
                />
            </div>
        );
    };

    const tipoMovimentacaoTemplate = (rowData) => {
        if (rowData.tipo === "E") return <span>Entrada</span>;
        return <span>Saída</span>;
    };

    const TipoMovimentacaoTemplate = (options) => {
        return (
            <DropdownFiltroTemplate
                dropdownProps={{
                    optionValue: "id",
                    optionLabel: "label",
                    options: TIPO_MOVIMENTACAO_ESTOQUE_CHOICE,
                }}
                options={options}
            />
        );
    };

    const ClasseMovimentacaoTemplate = (options) => {
        return (
            <DropdownFiltroTemplate
                dropdownProps={{
                    optionValue: "value",
                    optionLabel: "label",
                    options: CLASSE_MOVIMENTACAO_ESTOQUE,
                }}
                options={options}
            />
        );
    };

    const ContentTemplate = (options) => {
        return (
            <DropdownFiltroTemplate
                dropdownProps={{
                    optionValue: "id",
                    optionLabel: "model",
                    url: "/custom-fields/content-types/?limit=300",
                }}
                options={options}
            />
        );
    };

    const colunas = [
        { field: "id", header: "Código", style: { width: "8%" }, filter: true, filterElement: CodigoFiltroTemplate },
        {
            field: "codigo",
            header: "Código",
            style: { width: "10%" },
            filter: true,
            filterElement: TextoFiltroTemplate,
        },
        { field: "nome", header: "Nome", filter: true, filterElement: TextoFiltroTemplate },
        {
            field: "tipo",
            header: "Tipo",
            style: { width: "10%" },
            action: (e) => tipoMovimentacaoTemplate(e),
            filter: true,
            filterElement: TipoMovimentacaoTemplate,
        },
        {
            field: "classe_movimentacao.descricao",
            header: "Classe de movimentação",
            style: { width: "15%" },
            filter: true,
            filterElement: ClasseMovimentacaoTemplate,
            filterField: "classe_movimentacao",
        },
        {
            field: "content_type_representation.model",
            header: "Modelo",
            filter: true,
            filterElement: ContentTemplate,
            filterField: "content_type",
        },
        { field: "action", header: "Ações", style: { width: "10%" }, action: (e) => actionBodyTemplate(e) },
    ];

    const painelEsquerdoTabela = (
        <>
            <MakoControleAcesso
                permissao={[permissoes.ESTOQUE_MOVIMENTACOES_TIPOMOVIMENTACAO_INCLUIR]}
                componente={Button}
                label="Novo"
                icon="pi pi-plus"
                className="p-button-success p-mr-2"
                onClick={() => history.push("/estoque/cadastros/tipos-movimentacoes/form")}
            />
        </>
    );

    const fecharDeleteModal = () => {
        setTipoMovimentacaoEstoque(null);
        setDeleteDialog(false);
    };

    return (
        <div className="p-grid">
            <div className="p-col-12">
                <div className="card">
                    <MakoListagem
                        ref={listagemRef}
                        titulo="Tipo de movimentação de estoque"
                        painelEsquerdo={painelEsquerdoTabela}
                        botaoExportar
                        colunas={colunas}
                        urlPesquisa="/produtos/tipos-movimentacoes-estoques/"
                        configTabela={{
                            paginator: true,
                            lazy: true,
                        }}
                        filtros={{
                            id: { value: null, matchMode: "equals" },
                            codigo: { value: null, matchMode: "unaccent_icontains" },
                            tipo: { value: null, matchMode: "equals" },
                            classe_movimentacao: { value: null, matchMode: "equals" },
                            nome: { value: null, matchMode: "unaccent_icontains" },
                            content_type: { value: null, matchMode: "equals" },
                        }}
                    />
                    <ConfirmDialog
                        visible={deleteDialog}
                        onHide={fecharDeleteModal}
                        message={
                            <p>
                                Deseja mesmo apagar o tipo de movimentação de estoque{" "}
                                <b>{tipoMovimentacaoEstoque && tipoMovimentacaoEstoque.nome}</b>?
                            </p>
                        }
                        header="Confirmação"
                        icon="pi pi-exclamation-triangle"
                        accept={() => deletarTipoMovimentacaoEstoque()}
                        acceptLabel="Sim"
                        acceptClassName="p-button-danger"
                        reject={() => fecharDeleteModal}
                        rejectLabel="Não"
                    />
                </div>
            </div>
        </div>
    );
};
