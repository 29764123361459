import React, { useEffect, useMemo, useRef, useState } from "react";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import { Button } from "primereact/button";
import { Panel } from "primereact/panel";
import { Ripple } from "primereact/ripple";
import { Dialog } from "primereact/dialog";
import { ItemOrcamentoModalForm } from "./modal";
import { formatarCasasDecimais, parseNumberToMoneyHTML } from "@/assets/util/util";
import {
    VENDAS_ORCAMENTORAPIDO_CATEGORIA_SIMILARIDADE,
    VENDAS_ORCAMENTORAPIDO_DESCRICAO_SIMILARIDADE,
} from "@/assets/constants/parametros";
import MakoListagem from "@/components/MakoListagem";
import permissoes from "@/assets/constants/permissoes";
import useParam from "@/hooks/useParam";
import { axiosPost } from "@/services/http";
import useLoading from "@/hooks/useLoading";
import { MakoDropdownCategoriasHierarquicas } from "@/components/MakoDropdownCategoriasHierarquicas";
import useToast from "@/hooks/useToast";

export const ItensOrcamentoRapidoForm = (props) => {
    const [exibirModal, setExibirModal] = useState(false);
    const [essenciaAtual, setEssenciaAtual] = useState(null);
    const [novaEssencia, setNovaEssencia] = useState(null);
    const [dadosTabela, setDadosTabela] = useState([]);
    const listagemRef = useRef(null);
    const { getParam } = useParam();
    const { showLoading, hideLoading } = useLoading();
    const { showSuccess, showError } = useToast();

    const descricaoSimilaridade = useMemo(() => {
        const descricao = getParam(VENDAS_ORCAMENTORAPIDO_DESCRICAO_SIMILARIDADE);
        if (descricao) {
            return descricao.valor;
        }
        return "Similaridade";
    }, [getParam]);

    async function trocarEssencias() {
        try {
            const body = {
                itens: props.itensOrcamento,
                atual: essenciaAtual,
                nova: novaEssencia,
            };

            showLoading();
            const resposta = await axiosPost("/materiais/trocar-essencias-orcamento/", body);
            hideLoading();

            if (resposta.status === 200) {
                let lista = Object.assign([], props.itensOrcamento);
                resposta.data.novos_itens.forEach((item) => {
                    const index = lista?.findIndex((e) => e.sku.id === parseInt(item.sku_antigo));
                    if (index >= 0) {
                        lista.push({
                            ...lista[index],
                            valor_unitario: item.valor_unitario,
                            valor_total: parseFloat(item.valor_unitario) * lista[index].quantidade,
                            sku: item.sku,
                            status: "novo",
                        });
                        lista[index].status = "deletar";
                    }
                });
                props.setItensOrcamento(lista);
                showSuccess({
                    summary: "Sucesso",
                    detail: `${descricaoSimilaridade} trocadas com sucesso!`,
                    life: 3000,
                });
                setEssenciaAtual(null);
                setNovaEssencia(null);
            } else {
                showError({
                    summary: "Erro",
                    detail: "Desculpe, não foi possível trocar os itens",
                    life: 2500,
                });
            }
        } catch (error) {
            showError({
                summary: "Erro",
                detail: "Desculpe, não conseguimos processar a sua requisição",
                life: 2500,
            });
        }
    }

    const categoriaSimilaridade = useMemo(() => {
        const categoria = getParam(VENDAS_ORCAMENTORAPIDO_CATEGORIA_SIMILARIDADE);
        if (categoria) {
            return categoria.valor;
        }
        return null;
    }, [getParam]);

    async function deletarItem(rowData) {
        try {
            let novaLista = props.itensOrcamento,
                lista = [];

            const index = props.itensOrcamento?.findIndex((e) => e.id === rowData.id);
            novaLista[index].status = "deletar";
            props.setItensOrcamento(novaLista);

            novaLista.forEach((item) => {
                if (!item.status || item.status !== "deletar") lista.push(item);
            });
            setDadosTabela(lista);

            props.toastRef.current.show({
                severity: "success",
                summary: "Sucesso",
                detail: "Item removido com sucesso!",
                life: 3000,
            });
        } catch {
            props.toastRef.current.show({
                severity: "error",
                summary: "Erro",
                detail: "Não foi possível remover o item.",
                life: 3000,
            });
        }
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <MakoControleAcesso
                    permissao={[permissoes.VENDAS_VENDA_ORCAMENTORAPIDO_EDITAR]}
                    componente={Button}
                    icon="pi pi-trash"
                    className="p-button-rounded p-button-danger p-mr-2 p-mb-1"
                    onClick={() => deletarItem(rowData)}
                    tooltip="Remover item"
                    tooltipOptions={{ position: "left" }}
                />
            </div>
        );
    };

    const template = (options) => {
        const toggleIcon = options.collapsed ? "pi pi-chevron-down" : "pi pi-chevron-up";
        const className = `${options.className} justify-content-start`;
        const titleClassName = `${options.titleClassName} pl-1`;

        return (
            <div className={className}>
                <span className={titleClassName}>{`Trocar ${descricaoSimilaridade}`}</span>
                <button className={options.togglerClassName} onClick={options.onTogglerClick}>
                    <span className={toggleIcon}></span>
                    <Ripple />
                </button>
            </div>
        );
    };

    const calcularValorTotalItemTabela = (rowData) => {
        const { quantidade, cubagem, valor_unitario, valor_desconto_unitario } = rowData;

        const valorItem = valor_unitario - valor_desconto_unitario;
        const valorTotal = quantidade * cubagem * valorItem;

        return parseNumberToMoneyHTML(valorTotal);
    };

    const colunas = [
        { field: "sku.codigo", header: "Código", style: { minWidth: "100px" } },
        { field: "sku.descricao_reduzida", header: "Descrição", style: { minWidth: "300px" } },
        { field: "quantidade", header: "Qtd.", style: { minWidth: "100px" } },
        {
            field: "cubagem",
            header: "Cubagem",
            style: { minWidth: "100px" },
            action: (e) => formatarCasasDecimais(e.cubagem, 5),
        },
        {
            field: "tabela_preco.nome",
            header: "Tabela de Preço",
            style: { minWidth: "200px" },
        },
        {
            field: "valor_unitario",
            header: "Valor unitário (R$)",
            style: { minWidth: "150px" },
            action: (e) => parseNumberToMoneyHTML(e.valor_unitario),
        },
        {
            field: "valor_desconto_unitario",
            header: "Valor descontos (R$)",
            style: { minWidth: "150px" },
            action: (e) => parseNumberToMoneyHTML(e.valor_desconto_unitario * e.quantidade),
        },
        {
            field: "valor_total",
            header: "Valor total (R$)",
            style: { minWidth: "150px" },
            action: (e) => calcularValorTotalItemTabela(e),
            frozen: true,
            alignFrozen: "right",
        },
        {
            field: "action",
            header: "Ações",
            style: { minWidth: "80px" },
            action: (rowData) => actionBodyTemplate(rowData),
            frozen: true,
            alignFrozen: "right",
        },
    ];

    useEffect(() => {
        let lista = [];
        props.itensOrcamento.forEach((item) => {
            if (!item.status || item.status !== "deletar") lista.push(item);
        });
        setDadosTabela(lista);
    }, [props.itensOrcamento, props.setItensOrcamento]);

    return (
        <div className="p-grid">
            <div className="p-col-12">
                <div className="card">
                    <div className="p-grid p-col-12 p-md-12">
                        <MakoControleAcesso
                            permissao={[permissoes.VENDAS_VENDA_ORCAMENTORAPIDO_EDITAR]}
                            componente={Button}
                            label="Adicionar itens"
                            icon="pi pi-plus"
                            className="p-button-success p-button-outlined p-mr-2"
                            onClick={() => setExibirModal(true)}
                        />
                    </div>
                    {categoriaSimilaridade ? (
                        <Panel headerTemplate={template} toggleable collapsed={true}>
                            <div className="p-fluid p-formgrid p-grid p-justify-center">
                                <div className="p-field p-col-5 p-md-4">
                                    <label htmlFor="essencia_atual">{descricaoSimilaridade} atual *</label>
                                    <MakoDropdownCategoriasHierarquicas
                                        id="essencia_atual"
                                        name="essencia_atual"
                                        categoriaPai={categoriaSimilaridade}
                                        value={essenciaAtual}
                                        onChange={(e) => setEssenciaAtual(e.value)}
                                    />
                                </div>
                                <i className="pi pi-fw pi-angle-double-right p-mt-5" style={{ fontSize: "2vw" }}></i>
                                <div className="p-field p-col-5 p-md-4">
                                    <label htmlFor="nova_essencia">{descricaoSimilaridade} nova *</label>
                                    <MakoDropdownCategoriasHierarquicas
                                        id="nova_essencia"
                                        name="nova_essencia"
                                        categoriaPai={categoriaSimilaridade}
                                        value={novaEssencia}
                                        onChange={(e) => setNovaEssencia(e.value)}
                                    />
                                </div>
                                <div className="p-field p-col-5 p-md-2 p-mt-5">
                                    <Button
                                        label="Trocar"
                                        type="button"
                                        icon="pi pi-sync"
                                        className="p-button-help p-mr-2"
                                        tooltip={`As categorias informadas serão levadas em conta para a troca de ${descricaoSimilaridade}, bem como a tabela de preço dos itens.`}
                                        tooltipOptions={{ position: "left" }}
                                        disabled={!novaEssencia || !essenciaAtual}
                                        onClick={() => trocarEssencias()}
                                    />
                                </div>
                            </div>
                        </Panel>
                    ) : null}
                    <MakoListagem
                        ref={listagemRef}
                        colunas={colunas}
                        titulo="Itens do Orçamento Rápido"
                        dadosLocal={dadosTabela}
                        responsiva
                        configTabela={{
                            scrollable: true,
                            scrollHeight: "400px",
                            frozenWidth: "0.5vw",
                        }}
                    />
                </div>
                <Dialog
                    header={"Inserir Item do Orçamento"}
                    visible={exibirModal}
                    breakpoints={{ "960px": "75vw" }}
                    style={{ width: "70vw", display: "block" }}
                    onHide={() => setExibirModal(false)}
                >
                    <ItemOrcamentoModalForm
                        setItensOrcamento={props.setItensOrcamento}
                        itensOrcamento={props.itensOrcamento}
                        listagemRef={listagemRef}
                        exibirDialog={setExibirModal}
                        toastRef={props.toastRef}
                    />
                </Dialog>
            </div>
        </div>
    );
};
