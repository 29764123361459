import { ContratosServico } from "@/pages/Relatorios/Servicos/ContratosServicos";
import { ContratosServicoAtrasos } from "@/pages/Relatorios/Servicos/ContratosServicosAtrasos";
import { ContratoServicoPage } from "@/pages/Servicos/Servicos/ContratoServico";
import { ContratoServicoForm } from "@/pages/Servicos/Servicos/ContratoServico/form";
import { ServicosPage } from "@/pages/Servicos/Servicos/Servico";
import { ServicosForm } from "@/pages/Servicos/Servicos/Servico/form";
import { ServicosMunicipioPage } from "@/pages/Servicos/Servicos/ServicoMunicipio";
import { ServicoMunicipioForm } from "@/pages/Servicos/Servicos/ServicoMunicipio/form";

export const servicosRoutes = [
    { path: "/servicos/servicos/servico", component: ServicosPage },
    { path: "/servicos/servicos/servico/form", component: ServicosForm },
    { path: "/servicos/servicos/contrato-servico", component: ContratoServicoPage },
    { path: "/servicos/servicos/contrato-servico/form", component: ContratoServicoForm },
    { path: "/servicos/servicos/servico-municipio", component: ServicosMunicipioPage },
    { path: "/servicos/servicos/servico-municipio/form", component: ServicoMunicipioForm },
    { path: "/servicos/relatorios/contratos-servicos", component: ContratosServico },
    { path: "/servicos/relatorios/contratos-servicos-atrasos", component: ContratosServicoAtrasos },
];
