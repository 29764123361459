import React, { useRef, useState } from "react";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { useFormik } from "formik";
import { MakoCalendar } from "@/components/MakoCalendar";
import { MakoAutoComplete } from "@/components/MakoAutoComplete";
import { Checkbox } from "primereact/checkbox";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import { ConfirmDialog } from "primereact/confirmdialog";
import { dataToStr } from "@/assets/util/datas";
import classNames from "classnames";
import * as Yup from "yup";
import useCrediario from "@/hooks/useCrediario";
import MakoListagem from "@/components/MakoListagem";
import permissoes from "@/assets/constants/permissoes";
import { Dropdown } from "@/components/Dropdown";
import { Tag } from "primereact/tag";
import { PageBase } from "@/components/PageBase";

export const GrupoFamiliarForm = () => {
    const [deleteDialog, setDeleteDialog] = useState(false);
    const [relacao, setRelacao] = useState(null);
    const { crediario, handleRelacao } = useCrediario();
    const toastRef = useRef(null);
    const listagemRef = useRef(null);

    const { resetForm, ...formik } = useFormik({
        initialValues: {
            perfil_titular: crediario?.perfil_titular.id,
            perfil_vinculado: null,
            nome_relacao: null,
            data_fim: null,
            data_inicio: null,
            gera_renda: false,
            op: "novo",
        },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                perfil_vinculado: Yup.object()
                    .required("O campo é obrigatório.")
                    .typeError("Selecione um perfil válido."),
                nome_relacao: Yup.object()
                    .required("O campo é obrigatório.")
                    .typeError("Selecione uma relação válida."),
            });

            await formSchema.validate(values, {
                abortEarly: false,
            });

            handleRelacao(values.op, {
                ...values,
                data_fim: dataToStr(values.data_fim, "yyyy-MM-dd"),
                data_inicio: dataToStr(values.data_inicio, "yyyy-MM-dd"),
            });

            resetForm();
            listagemRef.current?.buscarDados();
            toastRef.current.show({
                severity: "success",
                summary: "Sucesso",
                detail: "Relação adicionada com sucesso!",
                life: 2500,
            });
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            } else {
                toastRef.current.show({
                    severity: "error",
                    summary: "Erro",
                    detail: "Desculpe, não conseguimos processar a sua requisição.",
                    life: 1500,
                });
            }
        }
    }

    async function deletaRelacao() {
        try {
            handleRelacao("deletar", relacao);
            listagemRef.current?.buscarDados();
        } catch (error) {
            toastRef.current.show({
                severity: "error",
                summary: "Erro",
                detail: "Desculpe, não conseguimos processar a sua requisição.",
                life: 1500,
            });
        }
    }

    const autoCompleteTemplate = (valor) => {
        valor.label = `${valor.nome} - ${valor.identificacao}`;
        return `${valor.nome} - ${valor.identificacao}`;
    };

    const esconderConfirmacao = () => {
        setRelacao(null);
        setDeleteDialog(false);
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <MakoControleAcesso
                    permissao={[permissoes.VENDAS_VENDA_CREDIARIO_EDITAR]}
                    componente={Button}
                    icon="pi pi-trash"
                    className="p-button-rounded p-button-danger p-mr-2 p-mb-1"
                    tooltip="Remover relação familiar"
                    tooltipOptions={{ position: "left" }}
                    onClick={() => {
                        setRelacao({ ...rowData, op: "deletar" });
                        setDeleteDialog(true);
                    }}
                />
            </div>
        );
    };

    const colunas = [
        {
            field: "perfil_vinculado.nome",
            header: "Protocolo",
            style: { minWidth: "200px" },
            action: ({ perfil_vinculado, gera_renda }) => (
                <div className="p-inputgroup">
                    <label>{perfil_vinculado.nome}</label>
                    {gera_renda && (
                        <Tag severity="success" rounded className="p-ml-1">
                            <i className="pi pi-dollar" />
                        </Tag>
                    )}
                </div>
            ),
        },
        { field: "perfil_vinculado.identificacao", header: "CPF", style: { minWidth: "80px" } },
        { field: "nome_relacao.relacao", header: "Relação", style: { minWidth: "70px" } },
        { field: "data_inicio", header: "Início", style: { minWidth: "70px" } },
        { field: "data_fim", header: "Fim", style: { minWidth: "70px" } },
        {
            field: "action",
            header: "Ações",
            action: (e) => actionBodyTemplate(e),
            style: { width: "8%" },
        },
    ];

    return (
        <PageBase>
            <Toast ref={toastRef} />
            <form onSubmit={formik.handleSubmit}>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-9">
                        <label htmlFor="perfil_vinculado">Perfil *</label>
                        <MakoAutoComplete
                            id="perfil_vinculado"
                            name="perfil_vinculado"
                            placeholder="Busque pelo nome ou cpf ... (min 4 caractéres)"
                            minCaracteresBusca={4}
                            value={formik.values.perfil_vinculado}
                            onChange={formik.handleChange}
                            itemTemplate={autoCompleteTemplate}
                            field="label"
                            urlSearch={"/pessoas/perfis?tipo_pessoa=PF&query={id, nome, identificacao}&search="}
                            className={classNames({ "p-invalid": formik.errors.perfil_vinculado })}
                        />
                        {formik.errors.perfil_vinculado && (
                            <small className="p-error">{formik.errors.perfil_vinculado}</small>
                        )}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="nome_relacao">Relação *</label>
                        <Dropdown
                            id="nome_relacao"
                            name="nome_relacao"
                            placeholder="Selecione uma relação"
                            url={"/crediario/nome-relacao?query={id, relacao}&limit=300&tipo__lt=4"}
                            filter
                            filterBy="relacao"
                            optionLabel="relacao"
                            value={formik.values.nome_relacao}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.nome_relacao })}
                        />
                        {formik.errors.nome_relacao && <small className="p-error">{formik.errors.nome_relacao}</small>}
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="data_inicio">Data de início</label>
                        <MakoCalendar
                            id="data_inicio"
                            name="data_inicio"
                            valueCalendar={formik.values.data_inicio}
                            onChange={formik.handleChange}
                            className={classNames({
                                "p-invalid": formik.errors.data_inicio,
                            })}
                        />
                        {formik.errors.data_inicio && <small className="p-error">{formik.errors.data_inicio}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="data_fim">Data final</label>
                        <MakoCalendar
                            id="data_fim"
                            name="data_fim"
                            valueCalendar={formik.values.data_fim}
                            onChange={formik.handleChange}
                            className={classNames({
                                "p-invalid": formik.errors.data_fim,
                            })}
                        />
                        {formik.errors.data_fim && <small className="p-error">{formik.errors.data_fim}</small>}
                    </div>
                    <div className="p-field-checkbox p-col-12 p-md-2">
                        <br></br>
                        <Checkbox
                            inputId="gera_renda"
                            id="gera_renda"
                            name="gera_renda"
                            onChange={formik.handleChange}
                            checked={formik.values.gera_renda}
                            value={formik.values.gera_renda}
                            className="p-mt-5"
                        />
                        <label htmlFor="gera_renda" className="p-mt-5">
                            Gera renda?
                        </label>
                    </div>
                </div>
                <p>
                    <b>* Campos obrigatórios.</b>
                </p>
                <div className="p-grid">
                    <div className="p-col-12 p-md-6">
                        <Button
                            type="submit"
                            icon="pi pi-plus"
                            label="Adicionar"
                            className="p-button-primary p-button-success p-mr-2 p-mb-2"
                        />
                        <Button
                            type="reset"
                            icon="pi pi-trash"
                            label="Limpar"
                            className="p-button-warning p-mr-2 p-mb-2"
                            onClick={() => resetForm()}
                        />
                    </div>
                </div>
            </form>
            <MakoListagem
                ref={listagemRef}
                titulo="Relações Familiares"
                colunas={colunas}
                dadosLocal={crediario.relacaoperfil_set.filter(
                    (e) => e.op !== "deletar" && e.nome_relacao.tipo.id !== 4
                )}
            />
            <ConfirmDialog
                visible={deleteDialog}
                onHide={esconderConfirmacao}
                header="Confirmação"
                message={<span>{"Deseja realmente remover essa relação?"}</span>}
                icon="pi pi-info-circle p-mr-3"
                accept={deletaRelacao}
                acceptLabel="Sim"
                acceptClassName="p-button-danger"
                reject={esconderConfirmacao}
                rejectLabel="Não"
            />
        </PageBase>
    );
};
