import React, { useRef } from "react";
import { useHistory } from "react-router-dom";

import { PageBase } from "@/components/PageBase";

import { TiposDevolucao } from "./listagens/TipoDevolucaoFornecedor";
import { Buttons } from "./buttons";

import useClearRefs from "@/hooks/useClearRefs";

const BASE_URL_FORM = "/compras/cadastros/tipo-devolucao/form";

export const TipoDevolucaoPage = () => {
    const listagemRef = useRef(null);
    const history = useHistory();

    useClearRefs(listagemRef);

    const painelEsquerdo = (
        <>
            <Buttons.Novo onClick={() => history.push(BASE_URL_FORM)} />
        </>
    );

    const onSuccessCallbackStatus = () => listagemRef.current?.buscarDados();

    const actions = (rowData) => {
        return (
            <div className="actions">
                <Buttons.Editar
                    className="p-mr-2"
                    to={{
                        pathname: BASE_URL_FORM,
                        state: { tipo: rowData },
                    }}
                />
                <Buttons.Status tipoDevolucao={rowData} onSuccessCallback={onSuccessCallbackStatus} />
            </div>
        );
    };

    return (
        <PageBase>
            <TiposDevolucao
                listagemRef={listagemRef}
                actions={actions}
                listagemProps={{
                    painelEsquerdo,
                }}
            />
        </PageBase>
    );
};
