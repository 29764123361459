import React, { useCallback, useEffect, useRef, useState } from "react";
import classNames from "classnames";
import { useHistory } from "react-router-dom";
import { InputText } from "primereact/inputtext";
import { MultiSelect } from "primereact/multiselect";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { useFormik } from "formik";
import * as Yup from "yup";

import useLoading from "@/hooks/useLoading";
import { axiosGet, axiosPost, axiosPut } from "@/services/http";
import { SIM_NAO_BOOLEAN } from "@/assets/constants/constants";

export const TiposEntradaForm = (props) => {
    const [destinacoes, setDestinacoes] = useState([]);
    const history = useHistory();
    const toastRef = useRef(null);
    const { showLoading, hideLoading } = useLoading();

    const { setValues, ...formik } = useFormik({
        initialValues: {
            descricao: "",
            gera_financeiro: null,
            requer_ordem_compra: null,
            destinacoes: null,
        },
        onSubmit: handleSubmit,
    });

    const listarDestinacoes = useCallback(async () => {
        showLoading();
        const json = await axiosGet("/compras/destinacoes-entradas/?query={id,nome}");
        hideLoading();

        if (json.status === 200) {
            setDestinacoes(json.data.results);
        } else {
            toastRef.current.show({
                severity: "error",
                summary: "Erro",
                detail: "Não conseguimos listar suas destinações de entrada.",
                life: 3000,
            });
        }
    }, [showLoading, hideLoading]);

    useEffect(() => {
        listarDestinacoes();
    }, [listarDestinacoes]);

    useEffect(() => {
        if (props.location.state) {
            setValues(props.location.state);
        }
    }, [props.location.state, setValues]);

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                descricao: Yup.string().required("O campo 'descricao' é obrigatório."),
                gera_financeiro: Yup.bool().required("O campo 'gera financeiro' é obrigatório.").typeError("Selecione uma opção válida."),
                requer_ordem_compra: Yup.bool().required("O campo 'requer ordem de compra' é obrigatório.").typeError("Selecione uma opção válida."),
                destinacoes: Yup.array().min(1, "Selecione ao menos uma destinação.").typeError("Selecione uma opção válida."),
            });

            await formSchema.validate(values, {
                abortEarly: false,
            });

            if (!values.id) {
                showLoading();
                const resposta = await axiosPost("/compras/tipos-entradas/", values);
                hideLoading();

                if (resposta.status === 201) {
                    toastRef.current.show({
                        severity: "success",
                        summary: "Sucesso",
                        detail: "Tipo de entrada cadastrado com sucesso!",
                        life: 1500,
                    });

                    setTimeout(() => {
                        history.push("/compras/cadastros/tipos-entrada");
                    }, 2000);
                } else {
                    toastRef.current.show({
                        severity: "error",
                        summary: "Erro",
                        detail: "Desculpe, não conseguimos processar a sua requisição.",
                        life: 3000,
                    });
                }
            } else {
                showLoading();
                const resposta = await axiosPut(`/compras/tipos-entradas/${values.id}/`, values);
                hideLoading();

                if (resposta.status === 200) {
                    toastRef.current.show({
                        severity: "success",
                        summary: "Sucesso",
                        detail: "Tipo de entrada alterado com sucesso!",
                        life: 1500,
                    });

                    setTimeout(() => {
                        history.push("/compras/cadastros/tipos-entrada");
                    }, 2000);
                } else {
                    toastRef.current.show({
                        severity: "error",
                        summary: "Erro",
                        detail: "Desculpe, não conseguimos processar a sua requisição.",
                        life: 3000,
                    });
                }
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};

                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });

                formik.setErrors(errorMessages);
            }
        }
    }

    return (
        <div className="p-grid">
            <Toast ref={toastRef} />
            <div className="p-col-12">
                <div className="card">
                    <h3>{!formik.values.id ? "Novo tipo de entrada" : "Manutenção de tipo de entrada"}</h3>
                    <form onSubmit={formik.handleSubmit}>
                        <div className="p-fluid p-formgrid p-grid">
                            <div className="p-field p-col-12 p-md-5">
                                <label htmlFor="descricao">Descrição *</label>
                                <InputText
                                    id="descricao"
                                    name="descricao"
                                    value={formik.values.descricao}
                                    onChange={formik.handleChange}
                                    className={classNames({ "p-invalid": formik.errors.descricao })}
                                />
                                {formik.errors.descricao && (
                                    <small className="p-error">{formik.errors.descricao}</small>
                                )}
                            </div>
                            <div className="p-field p-col-12 p-md-2">
                                <label htmlFor="gera-financeiro">Gera financeiro? *</label>
                                <Dropdown
                                    id="gera-financeiro"
                                    name="gera_financeiro"
                                    options={SIM_NAO_BOOLEAN}
                                    optionValue="id"
                                    optionLabel="label"
                                    placeholder="Selecione"
                                    value={formik.values.gera_financeiro}
                                    onChange={formik.handleChange}
                                    className={classNames({ "p-invalid": formik.errors.gera_financeiro })}
                                />
                                {formik.errors.gera_financeiro && (
                                    <small className="p-error">{formik.errors.gera_financeiro}</small>
                                )}
                            </div>
                            <div className="p-field p-col-12 p-md-2">
                                <label htmlFor="requer-ordem-compra">Requer OC? *</label>
                                <Dropdown
                                    id="requer-ordem-compra"
                                    name="requer_ordem_compra"
                                    options={SIM_NAO_BOOLEAN}
                                    optionValue="id"
                                    optionLabel="label"
                                    placeholder="Selecione"
                                    value={formik.values.requer_ordem_compra}
                                    onChange={formik.handleChange}
                                    className={classNames({ "p-invalid": formik.errors.requer_ordem_compra })}
                                />
                                {formik.errors.requer_ordem_compra && (
                                    <small className="p-error">{formik.errors.requer_ordem_compra}</small>
                                )}
                            </div>
                            <div className="p-field p-col-12 p-md-3">
                                <label htmlFor="destinacoes">Destinações *</label>
                                <MultiSelect
                                    id="destinacoes"
                                    name="destinacoes"
                                    options={destinacoes}
                                    optionValue="id"
                                    optionLabel="nome"
                                    placeholder="Selecione"
                                    selectedItemsLabel="{0} destinações selecionadas"
                                    maxSelectedLabels={3}
                                    value={formik.values.destinacoes}
                                    onChange={formik.handleChange}
                                    className={classNames({ "p-invalid": formik.errors.destinacoes })}
                                />
                                {formik.errors.destinacoes && (
                                    <small className="p-error">{formik.errors.destinacoes}</small>
                                )}
                            </div>
                        </div>
                        <p>* Campos obrigatórios.</p>
                        <div className="p-grid p-col-12 p-md-6">
                            <Button label="Gravar" icon="pi pi-save" type="submit" className="p-button-info p-mr-2" />
                            <Button
                                label="Cancelar"
                                icon="pi pi-times"
                                type="button"
                                className="p-button-danger p-mr-2"
                                onClick={() => history.push("/compras/cadastros/tipos-entrada")}
                            />
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};
