import React from "react";
import classNames from "classnames";
import { SelectButton } from "primereact/selectbutton";
import { InputText } from "primereact/inputtext";
import { useFormik } from "formik";
import * as Yup from "yup";

import { MakoButton as Button } from "@/components/MakoButton";
import { CamposObrigatorios } from "@/components/CamposObrigatorios";
import { Dropdown } from "@/components/Dropdown";
import { Label } from "@/components/Label";

import { axiosPost, axiosPut } from "@/services/http";
import {
    SIM_NAO_BOOLEAN,
    TIPO_CHOICE_MOTIVO_DEVOLUCAO_FORNECEDOR,
    TIPO_CHOICE_TIPO_SKU,
} from "@/assets/constants/constants";
import { MAKO_ICONS } from "@/assets/constants/constants_styles";

import useLoadingLocal from "@/hooks/useLoadingLocal";
import useToast from "@/hooks/useToast";

const INITIAL_VALUES = {
    descricao: "",
    tipo_sku: null,
    motivo: null,
    gera_financeiro: true,
    ativo: true,
};

const URL_BACKEND = "/compras/tipos-devolucoes-fornecedor/";
const URL_LISTAGEM = "/compras/cadastros/tipo-devolucao/";

export const Form = ({ tipoDevolucao, onSuccessCallback = () => {}, actions = true }) => {
    const [loading, showLoading, hideLoading] = useLoadingLocal();
    const { showError, showSuccess } = useToast();

    const { setValues, ...formik } = useFormik({
        initialValues: !tipoDevolucao ? INITIAL_VALUES : { ...INITIAL_VALUES, ...tipoDevolucao },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                descricao: Yup.string()
                    .required("O campo 'descrição' é obrigatório.")
                    .typeError("O campo 'descrição' é obrigatório."),
                motivo: Yup.string()
                    .required("O campo 'motivo' é obrigatório.")
                    .typeError("O campo 'motivo' é obrigatório."),
            });

            let dadosValidados = await formSchema.validate(values, { abortEarly: false });

            if (!values.id) {
                showLoading();
                const resposta = await axiosPost(URL_BACKEND, dadosValidados);
                hideLoading();
                if (resposta.status === 201) {
                    showSuccess({
                        summary: "Sucesso",
                        detail: "Tipo devolução cadastrada com sucesso!",
                        life: 3000,
                    });
                    onSuccessCallback(resposta.data);
                } else showError();
            } else {
                showLoading();
                const resposta = await axiosPut(`${URL_BACKEND}${values.id}/`, dadosValidados);
                hideLoading();
                if (resposta.status === 200) {
                    showSuccess({
                        summary: "Sucesso",
                        detail: "Tipo devolução alterada com sucesso!",
                        life: 3000,
                    });
                    onSuccessCallback(resposta.data);
                } else showError();
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            }
        }
    }

    return (
        <>
            <form onSubmit={formik.handleSubmit}>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-4">
                        <Label htmlFor="descricao" label="Descrição" obrigatorio />
                        <InputText
                            id="descricao"
                            name="descricao"
                            maxLength={60}
                            value={formik.values.descricao}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.descricao })}
                        />
                        {formik.errors.descricao && <small className="p-error">{formik.errors.descricao}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <Label htmlFor="motivo" label="Motivo" obrigatorio />
                        <Dropdown
                            id="motivo"
                            name="motivo"
                            placeholder="Selecione"
                            options={TIPO_CHOICE_MOTIVO_DEVOLUCAO_FORNECEDOR}
                            optionValue="value"
                            optionLabel="label"
                            value={formik.values.motivo}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.motivo })}
                        />
                        {formik.errors.motivo && <small className="p-error">{formik.errors.motivo}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <Label htmlFor="tipo_sku" label="Tipo Mercadoria" obrigatorio />
                        <Dropdown
                            id="tipo_sku"
                            name="tipo_sku"
                            placeholder="Selecione"
                            options={TIPO_CHOICE_TIPO_SKU}
                            optionValue="value"
                            optionLabel="label"
                            value={formik.values.tipo_sku}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.tipo_sku })}
                        />
                        {formik.errors.tipo_sku && <small className="p-error">{formik.errors.tipo_sku}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <Label htmlFor="gera_financeiro" label="Gera Finaceiro" />
                        <SelectButton
                            id="gera_financeiro"
                            name="gera_financeiro"
                            value={formik.values.gera_financeiro}
                            onChange={formik.handleChange}
                            options={SIM_NAO_BOOLEAN}
                            optionLabel="label"
                            optionValue="id"
                        />
                    </div>
                </div>
                <CamposObrigatorios />
                {actions && (
                    <div className="p-grid p-col-12 p-md-6">
                        <Button
                            label="Gravar"
                            icon={MAKO_ICONS.GRAVAR}
                            type="submit"
                            loading={loading}
                            className="p-button p-button-success p-mr-2"
                        />
                        <Button
                            label="Cancelar"
                            type="button"
                            loading={loading}
                            to={URL_LISTAGEM}
                            className="p-button p-button-danger"
                        />
                    </div>
                )}
            </form>
        </>
    );
};
