import React, { useCallback, useEffect, useRef, useState } from "react";
import { Toast } from "primereact/toast";
import { TabView, TabPanel } from "primereact/tabview";
import { Button } from "primereact/button";
import { EmissaoForm } from "./formEmissao";
import { TotaisForm } from "./formTotais";
import { InformacoesAdicionaisForm } from "./formInfAdicionais";
import { TransporteForm } from "./formTransporte";
import { ProdutosForm } from "./formProdutos";
import { CobrancaForm } from "./FormCobrancas/formCobranca";
import { useHistory } from "react-router-dom";
import { NotaFiscalProvider } from "@/contexts/notaFiscalContext";
import useNotaFiscal from "@/hooks/useNotaFiscal";
import useEmpresa from "@/hooks/useEmpresa";
import { Dropdown } from "@/components/Dropdown";

const TabEmissao = ({ financeiro, id, venda, op }) => {
    const { empresaSelecionada } = useEmpresa();
    const [activeTab, setActiveTab] = useState(0);
    const { submit, handlePreencherNotaFiscal } = useNotaFiscal();
    const toastRef = useRef(null);
    const history = useHistory();

    const carregarNF = useCallback(async () => {
        if (id) await handlePreencherNotaFiscal(id);
    }, [id, handlePreencherNotaFiscal]);

    useEffect(() => {
        carregarNF();
    }, [carregarNF, id]);

    const alterarTab = (aba) => {
        if (aba === -1) {
            if (activeTab > 0) {
                setActiveTab(activeTab - 1);
            }
        } else {
            if (activeTab < 3) {
                setActiveTab(activeTab + 1);
            }
        }
    };

    return (
        <div className="p-grid">
            <div className="p-col-12">
                <div className="card">
                    <Toast ref={toastRef} />
                    <h5>Nota Fiscal Eletrônica</h5>
                    <div>
                        <TabView
                            className="tabview-custom"
                            activeIndex={activeTab}
                            onTabChange={(e) => setActiveTab(e.index)}
                        >
                            <TabPanel header="NF-e" leftIcon="pi pi-book">
                                <EmissaoForm
                                    estado={
                                        empresaSelecionada?.enderecoperfil_set?.length > 0 &&
                                        empresaSelecionada?.enderecoperfil_set[0].cidade
                                            ? empresaSelecionada?.enderecoperfil_set[0].cidade.estado.codigo_uf
                                            : null
                                    }
                                    empresa={venda || null}
                                />
                            </TabPanel>
                            <TabPanel header="Produtos" leftIcon="pi pi-briefcase" disabled={!submit}>
                                <ProdutosForm />
                            </TabPanel>
                            <TabPanel header="Transporte" leftIcon="pi pi-sort-alt" disabled={!submit}>
                                <TransporteForm />
                            </TabPanel>
                            <TabPanel header="Totais" leftIcon="pi pi-sort-amount-up" disabled={!submit}>
                                <TotaisForm />
                            </TabPanel>
                            <TabPanel header="Cobrança" leftIcon="pi pi-dollar" disabled={!submit}>
                                <CobrancaForm cobranca={financeiro || null} />
                            </TabPanel>
                            <TabPanel header="Inf. Adicionais" leftIcon="pi pi-pencil" disabled={!submit}>
                                <InformacoesAdicionaisForm />
                            </TabPanel>
                        </TabView>
                    </div>
                    <div className="p-grid p-fluid" style={{ paddingTop: "2%" }}>
                        {activeTab > 0 && activeTab !== 3 ? (
                            <div className="p-col-6 p-md-3">
                                <Button
                                    label="Anterior"
                                    icon="pi pi-angle-left"
                                    className="p-button-info"
                                    onClick={() => alterarTab(-1)}
                                />
                            </div>
                        ) : null}
                        {activeTab < 3 ? (
                            <div className="p-col-6 p-md-3">
                                <Button
                                    label="Próximo"
                                    disabled={!submit}
                                    icon="pi pi-angle-right"
                                    iconPos="right"
                                    className="p-button-info"
                                    onClick={() => alterarTab(1)}
                                />
                            </div>
                        ) : null}
                        {activeTab !== 3 ? (
                            <div className="p-col-6 p-md-3">
                                <Button
                                    label="Voltar"
                                    className="p-button-danger"
                                    onClick={() => history.push("/fiscal/nota-saida/lista-notas")}
                                />
                            </div>
                        ) : null}
                    </div>
                </div>
            </div>
        </div>
    );
};

export const TabNotaFiscalContext = (props) => {
    const { venda = null, financeiro = null, notaFiscal = null } = props.location?.state || props.location;
    return (
        <NotaFiscalProvider>
            <TabEmissao venda={venda} financeiro={financeiro} id={notaFiscal?.id} op={notaFiscal?.operacao_fiscal} />
        </NotaFiscalProvider>
    );
};
