import React, { useState, useRef } from "react";
import { Button } from "primereact/button";
import { Redirect } from "react-router-dom";
import { ConfirmDialog } from "primereact/confirmdialog";
import { Toast } from "primereact/toast";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import { axiosDelete } from "@/services/http";
import permissoes from "@/assets/constants/permissoes";
import MakoListagem from "@/components/MakoListagem";
import useLoading from "@/hooks/useLoading";

const url = "/fiscal/cfop/";

export const FiscalCfopPage = () => {
    const [redirecionar, setRedirecionar] = useState(null);
    const [deleteDialog, setDeleteDialog] = useState(false);
    const [cfop, setCfop] = useState(null);
    const { showLoading, hideLoading } = useLoading();
    const toastRef = useRef(null);
    const listagemRef = useRef(null);

    const confirmarDelete = (cfop) => {
        setCfop(cfop);
        setDeleteDialog(true);
    };

    const esconderDeleteDialog = () => {
        setDeleteDialog(false);
    };

    const deleteParametro = async () => {
        showLoading();
        const resposta = await axiosDelete(`${url}${cfop.id}`);
        hideLoading();

        if (resposta.status === 200 || resposta.status === 204) {
            toastRef.current.show({
                severity: "success",
                summary: "Sucesso",
                detail: "CFOP deletado com sucesso!",
                life: 3000,
            });

            listagemRef.current?.buscarDados();
        } else {
            toastRef.current.show({
                severity: "error",
                summary: "Erro",
                detail: "Desculpe, não conseguimos processar a sua requisição.",
                life: 3000,
            });
            hideLoading();
        }
        setDeleteDialog(false);
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <MakoControleAcesso
                    permissao={[permissoes.FISCAL_FISCAL_CFOP_EDITAR]}
                    componente={Button}
                    icon="pi pi-pencil"
                    className="p-button-rounded p-button-warning p-mr-2 p-mb-1"
                    tooltip="Alterar Cadastro de CFOP"
                    tooltipOptions={{ position: "left" }}
                    onClick={() =>
                        setRedirecionar({
                            pathname: "/fiscal/cadastros/cfop/form",
                            state: { cfop: rowData },
                        })
                    }
                />
                <MakoControleAcesso
                    permissao={[permissoes.FISCAL_FISCAL_CFOP_EXCLUIR]}
                    componente={Button}
                    icon="pi pi-trash"
                    className="p-button-rounded p-button-danger p-mr-2 p-mb-1"
                    onClick={() => confirmarDelete(rowData)}
                    tooltip="Deletar CFOP"
                    tooltipOptions={{ position: "left" }}
                />
            </div>
        );
    };

    const cabecalhoTabela = (
        <>
            <MakoControleAcesso
                permissao={[permissoes.FISCAL_FISCAL_CFOP_INCLUIR]}
                componente={Button}
                label="Novo"
                icon="pi pi-plus"
                className="p-button-success p-mr-2"
                onClick={() => setRedirecionar("/fiscal/cadastros/cfop/form")}
            />
        </>
    );

    // const MOVIMENTACAO = [
    //     { value: "E", label: "Entrada" },
    //     { value: "S", label: "Saída" },
    // ];

    // const movimentoFiltroTemplate = (options) => {
    //     return (
    //         <Dropdown
    //             placeholder="Selecione"
    //             showClear
    //             value={options.value}
    //             options={MOVIMENTACAO}
    //             onChange={(e) => options.filterApplyCallback(e.value)}
    //         />
    //     );
    // };

    // const movimentoBodyTemplate = (rowData) => {
    //     if (rowData.tipo === "E") {
    //         return <Tag severity="success" value="Entrada" />;
    //     }

    //     return <Tag severity="danger" value="Saída" />;
    // };

    const colunas = [
        {
            field: "id",
            header: "#ID",
            style: { width: "50px" },
            filter: true,
            showFilterMatchModes: false,
            showFilterMenu: false,
        },
        {
            field: "codigo",
            header: "Código",
            style: { minWidth: "50px" },
            filter: true,
        },
        {
            field: "descricao",
            header: "Descrição",
            style: { minWidth: "800px" },
            filter: true,
        },
        {
            field: "tipo",
            header: "Movimentação",
            // filter: true,
            // filterElement: movimentoFiltroTemplate,
            // action: (e) => movimentoBodyTemplate(e),
            style: { minWidth: "120px" },
        },
        {
            frozen: true,
            alignFrozen: "rigth",
            field: "action",
            header: "Ações",
            action: (e) => actionBodyTemplate(e),
            style: { minWidth: "100px" },
        },
    ];

    return redirecionar ? (
        <Redirect to={redirecionar} />
    ) : (
        <div className="p-grid">
            <Toast ref={toastRef} />
            <div className="p-col-12">
                <div className="card">
                    <MakoListagem
                        style={{ width: "10px" }}
                        ref={listagemRef}
                        titulo="CFOP's"
                        colunas={colunas}
                        painelEsquerdo={cabecalhoTabela}
                        urlPesquisa={url}
                        responsiva
                        mostrarGlobalSearch={true}
                        filtros={{
                            id: {
                                operator: "and",
                                constraints: [{ value: "", matchMode: "icontains" }],
                            },
                            codigo: {
                                operator: "and",
                                constraints: [{ value: "", matchMode: "icontains" }],
                            },
                            descricao: {
                                operator: "and",
                                constraints: [{ value: "", matchMode: "icontains" }],
                            },
                            tipo: { value: "", matchMode: "equals" },
                        }}
                        configTabela={{
                            paginator: true,
                            lazy: true,
                            filterDisplay: "menu",
                            scrollable: true,
                            frozenWidth: "0.5vw",
                        }}
                    />
                    <ConfirmDialog
                        visible={deleteDialog}
                        onHide={esconderDeleteDialog}
                        header="Confirmação"
                        message={
                            cfop && (
                                <span>
                                    {"Deseja realmente excluir o CFOP"}
                                    <b>{cfop.descricao}</b>?
                                </span>
                            )
                        }
                        icon="pi pi-info-circle p-mr-3"
                        accept={deleteParametro}
                        acceptLabel="Sim"
                        acceptClassName="p-button-danger"
                        reject={esconderDeleteDialog}
                        rejectLabel="Não"
                    />
                </div>
            </div>
        </div>
    );
};
