import React, { useCallback, useEffect, useState, useRef } from "react";
import classNames from "classnames";
import { useHistory } from "react-router-dom";
import { Dropdown } from "primereact/dropdown";
import { InputNumber } from "primereact/inputnumber";
import { Checkbox } from "primereact/checkbox";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { useFormik } from "formik";
import * as Yup from "yup";

import { axiosGet, axiosPost, axiosPut } from "@/services/http";
import { MakoAutoComplete } from "@/components/MakoAutoComplete";
import useLoading from "@/hooks/useLoading";

export const CarteiraClienteForm = (props) => {
    const [profissionais, setProfissionais] = useState([]);
    const [chavesPapelPerfil, setChavesPapelPerfil] = useState([]);
    const toastRef = useRef(null);
    const history = useHistory();
    const { showLoading, hideLoading } = useLoading();

    const { setValues, ...formik } = useFormik({
        initialValues: {
            profissional: null,
            chave_papel_perfil: null,
            cliente: null,
            prioridade: 1,
            ativo: true,
        },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                profissional: Yup.string()
                    .required("O campo 'profissional' é obrigatório.")
                    .typeError("Selecione um profissional."),
                chave_papel_perfil: Yup.string()
                    .required("O campo 'chave' é obrigatório.")
                    .typeError("Selecione uma chave de papel perfil."),
                cliente: Yup.object()
                    .shape({
                        id: Yup.number().required("O campo 'cliente' é obrigatório."),
                    })
                    .typeError("Você precisa pesquisar e escolher um cliente."),
                prioridade: Yup.number()
                    .required("O campo 'prioridade' é obrigatório.")
                    .min(1, "O valor mínimo para esse campo é 1."),
            });

            await formSchema.validate(values, { abortEarly: false });

            const carteiraCliente = {
                ...values,
                cliente: values.cliente.id,
            };

            if (!values.id) {
                showLoading();
                const json = await axiosPost("/pessoas/carteiras-clientes/", carteiraCliente);
                hideLoading();

                if (json.status === 201) {
                    toastRef.current.show({
                        severity: "success",
                        summary: "Sucesso",
                        detail: "Carteira de cliente cadastrada com sucesso!",
                        life: 1500,
                    });

                    setTimeout(() => {
                        voltar();
                    }, 2000);
                } else {
                    toastRef.current.show({
                        severity: "error",
                        summary: "Erro :(",
                        detail: "Desculpe, não conseguimos processar a sua requisição.",
                        life: 3000,
                    });
                }
            } else {
                showLoading();
                const json = await axiosPut(`/pessoas/carteiras-clientes/${carteiraCliente.id}/`, carteiraCliente);
                hideLoading();

                if (json.status === 200) {
                    toastRef.current.show({
                        severity: "success",
                        summary: "Sucesso",
                        detail: "Carteira de cliente alterada com sucesso!",
                        life: 1500,
                    });

                    setTimeout(() => {
                        voltar();
                    }, 2000);
                } else {
                    toastRef.current.show({
                        severity: "error",
                        summary: "Erro :(",
                        detail: "Desculpe, não conseguimos processar a sua requisição.",
                        life: 3000,
                    });
                }
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};

                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });

                formik.setErrors(errorMessages);
            }
        }
    }

    const voltar = () => {
        history.push("/vendas/cadastros/carteira-clientes");
    };

    const listarProfissionais = useCallback(async () => {
        showLoading();
        const json = await axiosGet(
            "/pessoas/perfis/?usuario__isnull=False&query={id,usuario,nome,identificacao,papeis_vigentes}&limit=100"
        );
        hideLoading();

        if (json.status === 200) {
            setProfissionais(json.data.results);
        }
    }, [showLoading, hideLoading]);

    useEffect(() => {
        listarProfissionais();
    }, [listarProfissionais]);

    const listarChavesPapelPerfilPorProfissional = useCallback(() => {
        if (formik.values.profissional) {
            const profissional = profissionais.find((e) => e.id === formik.values.profissional);

            if (profissional) {
                const chaves = profissional.papeis_vigentes.map((e) => ({ value: e.chave.id, label: e.chave.nome }));

                setChavesPapelPerfil(chaves);
            }
        }
    }, [formik.values.profissional, profissionais]);

    useEffect(() => {
        listarChavesPapelPerfilPorProfissional();
    }, [listarChavesPapelPerfilPorProfissional]);

    useEffect(() => {
        if (props.location.state) {
            const { profissional, ...values } = props.location.state;

            const chaves = profissional.papeis_vigentes.map((e) => ({ value: e.chave.id, label: e.chave.nome }));

            setChavesPapelPerfil(chaves);

            setValues({
                ...values,
                profissional: profissional.id,
            });
        }
    }, [props, setValues]);

    const autoCompleteClienteTemplate = (item) => {
        return <div>{`${item.nome} - ${item.identificacao}${item.telefone ? ` - ${item.telefone}` : ""}`}</div>;
    };

    return (
        <div className="p-grid">
            <Toast ref={toastRef} />
            <div className="p-col-12">
                <div className="card">
                    <h3>{!formik.values.id ? "Nova carteira de cliente" : "Manutenção de carteira de cliente"}</h3>
                    <form onSubmit={formik.handleSubmit}>
                        <div className="p-fluid p-formgrid p-grid">
                            <div className="p-field p-col-12 p-md-6">
                                <label htmlFor="profissional">Profissional *</label>
                                <Dropdown
                                    id="profissional"
                                    name="profissional"
                                    options={profissionais}
                                    placeholder="Selecione"
                                    filter
                                    filterBy="nome,usuario.username"
                                    optionValue="id"
                                    optionLabel="nome"
                                    emptyMessage="Nenhum registro disponível"
                                    emptyFilterMessage="Nenhum registro encontrado"
                                    value={formik.values.profissional}
                                    onChange={formik.handleChange}
                                    className={classNames({ "p-invalid": formik.errors.profissional })}
                                />
                                {formik.errors.profissional && (
                                    <small className="p-error">{formik.errors.profissional}</small>
                                )}
                            </div>
                            <div className="p-field p-col-12 p-md-6">
                                <label htmlFor="cliente">Cliente *</label>
                                <MakoAutoComplete
                                    id="cliente"
                                    name="cliente"
                                    value={formik.values.cliente}
                                    onChange={formik.handleChange}
                                    minCaracteresBusca={4}
                                    itemTemplate={autoCompleteClienteTemplate}
                                    field="nome"
                                    urlSearch="/pessoas/perfis?query={id,nome,identificacao,tipo_pessoa,telefoneperfil_set}&ativo=true&nome__unaccent__icontains="
                                    placeholder="Digite o nome do cliente para buscar... (min 4 caracteres)"
                                    className={classNames({ "p-invalid": formik.errors.cliente })}
                                />
                                {formik.errors.cliente && <small className="p-error">{formik.errors.cliente}</small>}
                            </div>
                        </div>
                        <div className="p-fluid p-formgrid p-grid">
                            <div className="p-field p-col-12 p-md-4">
                                <label htmlFor="chave">Chave *</label>
                                <Dropdown
                                    id="chave"
                                    name="chave_papel_perfil"
                                    options={chavesPapelPerfil}
                                    disabled={!!!formik.values.profissional}
                                    placeholder="Selecione"
                                    emptyMessage="Nenhum registro disponível"
                                    value={formik.values.chave_papel_perfil}
                                    onChange={formik.handleChange}
                                    className={classNames({ "p-invalid": formik.errors.chave_papel_perfil })}
                                />
                                {formik.errors.chave_papel_perfil && (
                                    <small className="p-error">{formik.errors.chave_papel_perfil}</small>
                                )}
                            </div>
                            <div className="p-field p-col-12 p-md-4">
                                <label htmlFor="prioridade">Prioridade *</label>
                                <InputNumber
                                    inputId="prioridade"
                                    name="prioridade"
                                    mode="decimal"
                                    useGrouping={false}
                                    min={1}
                                    value={formik.values.prioridade}
                                    onValueChange={formik.handleChange}
                                    className={classNames({ "p-invalid": formik.errors.prioridade })}
                                />
                                {formik.errors.prioridade && (
                                    <small className="p-error">{formik.errors.prioridade}</small>
                                )}
                            </div>
                            <div className="p-field-checkbox p-col-12 p-md-4 p-mt-5">
                                <Checkbox
                                    inputId="ativo"
                                    name="ativo"
                                    checked={formik.values.ativo}
                                    onChange={formik.handleChange}
                                />
                                <label htmlFor="ativo">Ativo?</label>
                            </div>
                        </div>
                        <p>
                            <b>* Campos obrigatórios</b>
                        </p>
                        <div className="p-grid">
                            <div className="p-col-12 p-md-6">
                                <Button type="submit" icon="pi pi-check" label="Gravar" className="p-mr-2" />
                                <Button
                                    label="Cancelar"
                                    type="button"
                                    icon="pi pi-times"
                                    className="p-button-danger"
                                    onClick={voltar}
                                />
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};
