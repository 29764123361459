import React, { useEffect, memo, useState, forwardRef, useCallback, useRef } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { useFormik } from "formik";
import { Dialog } from "primereact/dialog";
import { Checkbox } from "primereact/checkbox";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import { ProdutoMarcasForm } from "../../Cadastros/Marcas/form";
import { MakoInputNcm } from "@/components/MakoInputs/MakoInputNcm";
import * as Yup from "yup";
import useProduto from "@/hooks/useProduto";
import permissoes from "@/assets/constants/permissoes";
import classNames from "classnames";
import { FormikAutoSave } from "@/components/FormikAutoSave";
import { Dropdown } from "@/components/Dropdown";
import { MultiSelect } from "primereact/multiselect";
import useEmpresa from "@/hooks/useEmpresa";
import { Tag } from "primereact";
import useHttp from "@/hooks/useHttp";
import useToast from "@/hooks/useToast";

const DadosBasicosForm = () => {
    const { handleDadosBasicos, handleValidar, dadosBasicos } = useProduto();
    const [cests, setCests] = useState([]);
    const [empresas, setEmpresas] = useState([]);
    const [cadastrarMarca, setCadastrarMarca] = useState(false);
    const { empresaSelecionadaId } = useEmpresa();
    const { httpGet } = useHttp();
    const { showError } = useToast();
    const marcaRef = useRef();

    const { setFieldValue, setValues, ...formik } = useFormik({
        enableReinitialize: true,
        initialValues: {
            empresa: [empresaSelecionadaId],
            nome: "",
            ncm: null,
            cest: null,
            marca: null,
            formacao_preco: null,
            comissao_venda: null,
            status: "P",
            cubagem: false,
        },
        onSubmit: handleSubmit,
    });

    useEffect(() => {
        if (dadosBasicos?.id || dadosBasicos?.nome) {
            const {
                id,
                nome,
                ncm,
                cest,
                empresa,
                marca = null,
                formacao_preco = null,
                comissao_venda = null,
                status = "P",
                cubagem,
            } = dadosBasicos;

            setValues({
                id: id,
                nome: nome,
                ncm: ncm,
                cest: cest,
                empresa: empresa,
                marca: marca,
                formacao_preco: formacao_preco,
                comissao_venda: comissao_venda,
                status: status,
                cubagem: cubagem,
            });
        }
    }, [dadosBasicos, setValues]);

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                empresa: Yup.array().required("O campo 'empresas' é obrigatório."),
                nome: Yup.string().required("O campo 'descrição' é obrigatório."),
            });

            await formSchema.validate(values, {
                abortEarly: false,
            });

            handleDadosBasicos(values);
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            } else {
                showError({
                    summary: "Não há empresa vinculada.",
                    detail: "Por favor, selecione uma empresa para que possa prosseguir o cadastro de itens.",
                    life: 3000,
                });
            }
        }
    }

    const fetchCests = useCallback(async () => {
        const handlers = {
            200: ({ data }) => {
                const { results } = data;
                setCests(
                    results.flatMap((aux) => {
                        return { value: aux.id, label: aux.codigo + " - " + aux.descricao };
                    }) || []
                );
            },
        };

        await httpGet(
            {
                url: `fiscal/cest/?limit=100000&ncm__id=${formik.values.ncm?.id}`,
            },
            handlers
        );
    }, [formik.values.ncm]);

    useEffect(() => {
        fetchCests();
    }, [fetchCests]);

    const listarEmpresasVinculadas = useCallback(async () => {
        const handlers = {
            200: async ({ data }) => {
                if (data.results?.length > 0)
                    setEmpresas(
                        await data.results.map((vinculo) => {
                            const { perfil } = vinculo;
                            return { ...perfil, label: `${perfil.nome} - ${perfil.identificacao}` };
                        })
                    );
            },
        };

        await httpGet(
            {
                url: "/pessoas/grupos-perfis?limit=100",
            },
            handlers
        );
    }, []);

    useEffect(() => {
        listarEmpresasVinculadas();
    }, [listarEmpresasVinculadas]);

    const esconderMarca = () => {
        setCadastrarMarca(false);
        marcaRef.current?.fetchDados();
    };

    const onChange = (field, e) => {
        setFieldValue(field, e || null);
    };

    return (
        <>
            <form onSubmit={formik.handleSubmit}>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-12">
                        <label htmlFor="empresa">Empresas *</label>
                        <MultiSelect
                            id="empresa"
                            name="empresa"
                            value={formik.values.empresa}
                            options={empresas}
                            onChange={(e) => setFieldValue("empresa", e.value)}
                            optionLabel="label"
                            optionValue="id"
                            placeholder="Selecione uma ou mais empresa(s)"
                            filterBy="nome,identificacao"
                            filter
                            className="multiselect-custom"
                        />
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-12">
                        <label htmlFor="nome">Nome do produto ou mercadoria *</label>
                        <InputText
                            id="nome"
                            name="nome"
                            value={formik.values.nome}
                            onChange={formik.handleChange}
                            placeholder="Digite o nome do produto ou mercadoria"
                            className={classNames({ "p-invalid": formik.errors.nome })}
                            autoFocus
                        />
                        {formik.errors.nome && <small className="p-error">{formik.errors.nome}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-12">
                        <label htmlFor="ncm">NCM *</label>
                        <MakoInputNcm fieldValue={setFieldValue} valorNcm={formik.values.ncm || null} />
                        {formik.errors.ncm && <small className="p-error">{formik.errors.ncm}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-6">
                        <label htmlFor="estado">CEST</label>
                        <Dropdown
                            id="cest"
                            name="cest"
                            options={cests}
                            optionLabel="label"
                            placeholder={
                                !formik.values.ncm ? "Selecione um NCM para exibir o Cest" : "Selecione um cest"
                            }
                            disabled={!formik.values.ncm}
                            emptyMessage="Nenhum Cest correspondente ao NCM selecionado."
                            filter
                            showClear
                            filterBy="label"
                            value={formik.values.cest}
                            onChange={formik.handleChange}
                            className={classNames({
                                "p-invalid": formik.errors.cest,
                            })}
                        />
                        {formik.errors.cest && <small className="p-error">{formik.errors.cest}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-6">
                        <label htmlFor="marca">Marca </label>
                        <div className="p-inputgroup">
                            <Dropdown
                                ref={marcaRef}
                                id="marca"
                                name="marca"
                                url="/produtos/marcas?limit=1000"
                                optionValue="id"
                                optionLabel="nome"
                                placeholder="Selecione uma marca"
                                filter
                                filterBy="id,nome"
                                showClear
                                value={formik.values.marca}
                                onChange={formik.handleChange}
                                className={classNames({ "p-invalid": formik.errors.marca })}
                            />
                            <MakoControleAcesso
                                permissao={[permissoes.PRODUTO_CADASTRO_MARCA_INCLUIR]}
                                componente={Button}
                                type="button"
                                icon="pi pi-plus"
                                className="p-button-success"
                                tooltip="Cadastrar marca"
                                tooltipOptions={{ position: "left" }}
                                onClick={() => setCadastrarMarca(true)}
                            />
                            {formik.errors.marca && <small className="p-error">{formik.errors.marca}</small>}
                        </div>
                    </div>
                    <div className="p-field p-col-12 p-md-4">
                        <label htmlFor="comissao_venda">% Comissão de Vendas</label>
                        <Dropdown
                            id="comissao_venda"
                            name="comissao_venda"
                            url="/vendas/comissoes-vendas?limit=1000"
                            optionValue="id"
                            optionLabel="descricao"
                            placeholder="Selecione uma comissão de vendas"
                            value={formik.values.comissao_venda}
                            onChange={(e) => onChange("comissao_venda", e.value)}
                        />
                    </div>
                    <div className="p-field p-col-12 p-md-4">
                        <label htmlFor="formacao_preco">Formação de preço</label>
                        <Dropdown
                            id="formacao_preco"
                            name="formacao_preco"
                            url="/produtos/formacoes-precos?limit=1000"
                            optionValue="id"
                            optionLabel="descricao"
                            placeholder="Selecione uma formação de preço"
                            emptyMessage="Não existem formações"
                            value={formik.values.formacao_preco}
                            onChange={(e) => onChange("formacao_preco", e.value)}
                        />
                    </div>

                    <div className="p-field-checkbox p-col-12 p-md-4">
                        <br></br>
                        <Checkbox
                            inputId="cubagem"
                            id="cubagem"
                            name="cubagem"
                            onChange={formik.handleChange}
                            checked={formik.values.cubagem}
                            value={formik.values.cubagem}
                            className="p-mt-5"
                        />
                        <label htmlFor="cubagem" className="p-mt-5">
                            Movimentação por cubagem ou área
                        </label>
                    </div>
                </div>
                {!formik.values?.id ? null : (
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-12 p-md-4">
                            <label htmlFor="situacao">
                                <b>Situação cadastral: </b>
                            </label>
                            <Tag
                                severity={formik.values.status === "F" ? "success" : "warning"}
                                value={formik.values.status === "F" ? <b>Finalizado</b> : <b>Pendente</b>}
                                icon={formik.values.status === "F" ? "pi pi-check" : "pi pi-exclamation-triangle"}
                                style={{ width: "100px" }}
                                className="p-ml-2"
                                rounded
                            />
                        </div>
                    </div>
                )}
                {!formik.values?.id ? null : (
                    <Button
                        icon="fa fa-solid fa-check-double"
                        type="button"
                        label="Validar cadastro"
                        onClick={() => handleValidar()}
                        className="p-button-secondary p-mb-2"
                        tooltip="Validar cadastro de item"
                    />
                )}
                <p>* Campos obrigatórios</p>
                {!dadosBasicos?.id ? (
                    <div className="p-grid">
                        <div className="p-col-12 p-md-6">
                            <Button
                                type="submit"
                                icon="pi pi-save"
                                label="Incluir dados básicos"
                                className="p-button-info"
                            />
                        </div>
                    </div>
                ) : null}
                <FormikAutoSave intervalo={500} autosave={typeof dadosBasicos?.id === "number"} formik={formik} />
            </form>
            <Dialog
                header="Nova marca"
                visible={cadastrarMarca}
                breakpoints={{ "960px": "75vw" }}
                style={{ width: "35vw" }}
                onHide={() => esconderMarca()}
            >
                <ProdutoMarcasForm
                    marca
                    dialog={esconderMarca}
                    onSubmit={({ id }) => {
                        setFieldValue("marca", id);
                    }}
                />
            </Dialog>
        </>
    );
};

export default memo(forwardRef(DadosBasicosForm));
