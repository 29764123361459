import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useState } from "react";
import { Button } from "primereact/button";
import { useFormik } from "formik";
import { Dropdown } from "@/components/Dropdown";
import { InputText } from "primereact/inputtext";
import { MakoInputQuantidadeSku } from "@/components/MakoInputs/MakoInputQuantidadeSku";
import { MakoInputMoeda } from "@/components/MakoInputMoeda";
import * as Yup from "yup";
import classNames from "classnames";
import useTroca from "@/hooks/useTroca";
import { Dialog } from "primereact/dialog";
import { parseNumber } from "@/assets/helpers/number";
import useToast from "@/hooks/useToast";
import { MakoBuscaSkuPersonalizada } from "@/components/MakoBuscaSkuPersonalizada";
import useParam from "@/hooks/useParam";
import { PESSOAS_TAGPERFIL_PADRAO_CLIENTE } from "@/assets/constants/parametros";

const Modal = ({ aposSalvar }, ref) => {
    const { dadosBasicos, handleItemSaida } = useTroca();
    const [visible, setVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const [unidadesVenda, setUnidadesVenda] = useState([]);
    const { showError, showSuccess, showWarning } = useToast();
    const { getParam } = useParam();
    const [qtdMinimaTabelaPreco, setQtdMinimaTabelaPreco] = useState(0);

    const { setFieldValue, setValues, resetForm, ...formik } = useFormik({
        initialValues: {
            id: null,
            sku: null,
            unidade_medida: null,
            item_venda: null,
            operacao_fiscal: null,
            quantidade: 0,
            cubagem: 1,
            valor_unitario: 0,
            subtotal: 0,
            encargos: 0,
            desconto: 0,
            preco_tabela_preco: null,
            tabela_preco: null,
            motivo_devolucao: null,
            justificativa: "",
            retorno_estoque: true,
            defeito: false,
            estoque_retorno: null,
            unidade_venda: null,
            sku_movimenta_estoque: null,
            _encargos_venda: 0,
            _unidade: null,
            _cubagem: null,
            _qtd_vendida: null,
            _qtd_devolvida: null,
        },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                sku: Yup.object()
                    .typeError("Você precisa buscar e selecionar um produto.")
                    .required("Você precisa buscar e selecionar um produto."),
                desconto: Yup.number().required("O campo 'desconto' é obrigatório."),
                encargos: Yup.number().required("O campo 'encargos' é obrigatório."),
                unidade_venda: Yup.number().required("O campo 'unidade' é obrigatório."),
                quantidade: Yup.number()
                    .required("O campo 'quantidade' é obrigatório.")
                    .min(1, "A quantidade deve ser maior que 0."),
            });

            await formSchema.validate(values, {
                abortEarly: false,
            });

            const { status } = await handleItemSaida(values);

            if (status === 200 || status === 201) {
                if (typeof aposSalvar === "function") aposSalvar();
                showSuccess({
                    summary: "Sucesso",
                    detail: "O item foi inserido com sucesso.",
                    life: 3000,
                });
                setVisible(false);
                resetForm();
            } else
                showError({
                    summary: "Erro",
                    detail: "Desculpe, não foi possível inserir o item da troca.",
                    life: 3000,
                });
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            }
        }
    }

    const abrirModal = (values) => {
        setValues({
            ...values,
            unidade_venda: values.unidade_venda.id,
        });
        setVisible(true);
    };

    const obterPrecoTabela = useCallback(() => {
        if (formik.values.tabela_preco && formik.values.sku) {
            const { precostabelapreco_set } = formik.values.tabela_preco;
            const preco = precostabelapreco_set.find((el) => el.sku.id === formik.values.sku.id);
            if (preco) {
                setFieldValue("preco_tabela_preco", preco.id);
                setFieldValue("valor_unitario", parseNumber(preco.preco_arredondado));
            } else {
                showWarning({
                    summary: "Aviso!",
                    detail: "O produto não possui nenhum preço, portanto não pode ser vendido.",
                    life: 5000,
                });
            }
        }
    }, [formik.values.tabela_preco, formik.values.sku, setFieldValue, showWarning]);

    useEffect(() => {
        obterPrecoTabela();
    }, [obterPrecoTabela]);

    const calcularSubtotalItem = useCallback(() => {
        if (!formik.values.quantidade) setFieldValue("subtotal", 0);
        else {
            const subtotal =
                formik.values.quantidade * formik.values.valor_unitario +
                formik.values.encargos -
                formik.values.desconto;
            setFieldValue("subtotal", subtotal);
        }
    }, [
        formik.values.quantidade,
        formik.values.valor_unitario,
        formik.values.encargos,
        formik.values.desconto,
        setFieldValue,
    ]);

    useEffect(() => {
        calcularSubtotalItem();
    }, [calcularSubtotalItem]);

    const esconderDialog = () => {
        resetForm();
        setVisible(false);
    };

    const onChangeDimensao = useCallback(
        (e) => {
            setFieldValue("cubagem", e);
        },
        [setFieldValue]
    );

    useImperativeHandle(ref, () => {
        return {
            setVisible,
            abrirModal,
        };
    });

    const onChangeTabelaPreco = ({ value }) => {
        setFieldValue("tabela_preco", value);
        if (value) {
            const qtdMinima = parseNumber(value.qtd_minima_tag);
            setQtdMinimaTabelaPreco(qtdMinima);
            setFieldValue("quantidade", qtdMinima);
        }
    };

    const urlBuscaTabelaPreco = useMemo(() => {
        if (!!dadosBasicos && formik.values.sku instanceof Object) {
            const param = getParam(PESSOAS_TAGPERFIL_PADRAO_CLIENTE);
            if (!param) return "";
            const sku = formik.values.sku?.id;
            const tagCliente = param.valor;
            const planoRecebimentoId = dadosBasicos?.plano_recebimento;
            return `/produtos/tabelas-preco/?precostabelapreco__sku=${sku}&tag_cliente=${tagCliente}&plano_recebimento=${planoRecebimentoId}`;
        }
        return "";
    }, [dadosBasicos, formik.values.sku, getParam]);

    const setInformacoesItem = useCallback(() => {
        if (formik.values.sku instanceof Object) {
            setLoading(true);
            const {
                codigo,
                descricao_derivada,
                descricao_reduzida,
                precosku_set,
                unidademedidasku_set,
                unidade_padrao,
            } = formik.values.sku;
            if (precosku_set.length === 0) {
                showWarning({
                    summary: "Aviso!",
                    detail: "O produto não possui nenhum preço cadastrado, portanto não pode ser vendido.",
                    life: 5000,
                });
                resetForm();
                return;
            }
            const unidades = unidademedidasku_set
                .filter((und) => und.ativo && und.tipo_mov_und_medida.id === "V")
                .map((und) => ({
                    id: und.unidade.id,
                    nome: `${und.unidade.nome} (${und.unidade.sigla})`,
                }));
            if (unidades.length === 0) {
                showWarning({
                    summary: "Aviso!",
                    detail: "O produto não possui nenhuma unidade de medida cadastrada, portanto não pode ser vendido.",
                    life: 5000,
                });
                resetForm();
                return;
            }
            setUnidadesVenda(unidades);
            setFieldValue("codigo", codigo);
            setFieldValue("descricao", descricao_derivada);
            setFieldValue("unidade_venda", unidade_padrao.id);
            setFieldValue("descricao_reduzida", descricao_reduzida);
            setFieldValue("valor_unitario", parseNumber(precosku_set[0].preco_base));
            setLoading(false);
        }
    }, [formik.values.sku, resetForm, setFieldValue, showWarning]);

    useEffect(() => {
        setInformacoesItem();
    }, [setInformacoesItem]);

    return (
        <Dialog
            header="Inserir Item da Saída"
            visible={visible}
            breakpoints={{ "960px": "75vw" }}
            style={{ width: "80vw" }}
            onHide={() => esconderDialog()}
        >
            <form onSubmit={formik.handleSubmit}>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-12">
                        <label htmlFor="sku">Item *</label>
                        <MakoBuscaSkuPersonalizada
                            id="sku"
                            name="sku"
                            skuValue={formik.values.sku}
                            skuChange={(e) => setFieldValue("sku", e)}
                            skuError={formik.errors.sku}
                            exibeDimensoes
                            onChangeAltura={(e) => setFieldValue("altura", e)}
                            alturaValue={formik.values.altura}
                            onChangeLargura={(e) => setFieldValue("largura", e)}
                            larguraValue={formik.values.largura}
                            onChangeComprimento={(e) => setFieldValue("comprimento", e)}
                            comprimentoValue={formik.values.comprimento}
                            dimensaoChange={onChangeDimensao}
                        />
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="tabela-preco">Tabela de preço</label>
                        <Dropdown
                            id="tabela-preco"
                            buscar={!!urlBuscaTabelaPreco}
                            url={urlBuscaTabelaPreco}
                            name="tabela_preco"
                            optionLabel="nome"
                            value={formik.values.tabela_preco}
                            onChange={onChangeTabelaPreco}
                            className={classNames({ "p-invalid": formik.errors.tabela_preco })}
                        />
                        {formik.errors.tabela_preco && <small className="p-error">{formik.errors.tabela_preco}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="unidade_venda">Unidade de venda *</label>
                        <Dropdown
                            id="unidade_venda"
                            name="unidade_venda"
                            options={unidadesVenda}
                            optionValue="id"
                            optionLabel="nome"
                            placeholder={loading ? "Carregando unidades..." : "Selecione..."}
                            disabled={loading}
                            emptyMessage="Nenhuma unidade disponível."
                            value={formik.values.unidade_venda}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.unidade_venda })}
                        />
                        {formik.errors.unidade_venda && (
                            <small className="p-error">{formik.errors.unidade_venda}</small>
                        )}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="valor_unitario">Valor Unitário *</label>
                        <MakoInputMoeda
                            id="valor_unitario"
                            name="valor_unitario"
                            value={formik.values.valor_unitario}
                            onValueChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.valor_unitario })}
                        />
                        {formik.errors.valor_unitario && (
                            <small className="p-error">{formik.errors.valor_unitario}</small>
                        )}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="quantidade">Quantidade * </label>
                        <MakoInputQuantidadeSku
                            id="quantidade"
                            name="quantidade"
                            permiteFracionario
                            min={qtdMinimaTabelaPreco}
                            showButtons
                            value={formik.values.quantidade}
                            onValueChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.quantidade })}
                        />
                        {formik.errors.quantidade && <small className="p-error">{formik.errors.quantidade}</small>}
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="desconto">Desconto *</label>
                        <MakoInputMoeda
                            id="desconto"
                            name="desconto"
                            value={formik.values.desconto}
                            onValueChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.desconto })}
                        />
                        {formik.errors.desconto && <small className="p-error">{formik.errors.desconto}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="encargos">Total Encargos *</label>
                        <MakoInputMoeda
                            id="encargos"
                            name="encargos"
                            value={formik.values.encargos}
                            onValueChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.encargos })}
                        />
                        {formik.errors.encargos && <small className="p-error">{formik.errors.encargos}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="subtotal">Subtotal *</label>
                        <MakoInputMoeda
                            id="subtotal"
                            name="subtotal"
                            value={formik.values.subtotal}
                            disabled
                            onValueChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.subtotal })}
                        />
                        {formik.errors.subtotal && <small className="p-error">{formik.errors.subtotal}</small>}
                    </div>
                </div>

                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12">
                        <label htmlFor="observacoes">Observações</label>
                        <InputText
                            id="observacoes"
                            name="observacoes"
                            value={formik.values.observacoes}
                            onChange={formik.handleChange}
                        />
                    </div>
                </div>
                <div className="p-grid p-col-12 p-md-12 p-justify-end p-mt-1">
                    <Button icon="pi pi-plus" label="Inserir" type="submit" className="p-button-success p-mr-2" />
                    <Button
                        icon="pi pi-times"
                        label="Fechar"
                        type="reset"
                        className="p-button-danger"
                        onClick={() => esconderDialog()}
                    />
                </div>
            </form>
        </Dialog>
    );
};

export const ItensSaidaModal = forwardRef(Modal);
