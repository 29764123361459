import React, { forwardRef, memo, useCallback, useEffect, useRef, useState } from "react";
import { InputNumber } from "primereact/inputnumber";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { useFormik } from "formik";
import { TIPO_LOCAL_PRESTACAO_CHOICE } from "@/assets/constants/constants";
import { MakoAutoComplete } from "@/components/MakoAutoComplete";
import classNames from "classnames";
import * as Yup from "yup";
import { MakoCalendar } from "@/components/MakoCalendar";
import { Divider } from "primereact/divider";
import { Dropdown } from "@/components/Dropdown";
import { InputText } from "primereact/inputtext";
import { FormikAutoSave } from "@/components/FormikAutoSave";
import useNotaServico from "@/hooks/useNotaServico";
import useEmpresa from "@/hooks/useEmpresa";
import { MakoDropdownEmpresas } from "@/components/MakoDropdownEmpresas";

export const DadosNotaForm = (props) => {
    const { empresaSelecionadaId } = useEmpresa();
    const { handleDadosGerais, notaServico, submit } = useNotaServico();
    const toastRef = useRef(null);

    const { setValues, setFieldValue, ...formik } = useFormik({
        initialValues: {
            id: null,
            operacao_servico: null,
            rps_numero: 1,
            rps_serie: 1,
            natureza_operacao: null,
            data_competencia: null,
            empresa: empresaSelecionadaId,
            local_prestacao: 1,
            logradouro_prestador: "",
            bairro_prestador: "",
            municipio_prestador: null,
            numero_end_prestador: null,
            cep_prestador: "",
            logradouro_tomador: "",
            bairro_tomador: "",
            municipio_tomador: null,
            numero_end_tomador: null,
            cep_tomador: "",
            tomador: null,
            endereco_prestador: null,
            endereco_tomador: null,
            status: 0,
        },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                natureza_operacao: Yup.object()
                    .required("O campo é obrigatório!")
                    .typeError("Informe uma 'natureza' válida."),
                data_competencia: Yup.date().required("O campo é obrigatório!").typeError("Informe uma data válida."),
                rps_numero: Yup.number().required("O campo é obrigatório!"),
                rps_serie: Yup.number().required("O campo é obrigatório!"),
                local_prestacao: Yup.number().required("O campo é obrigatório!"),
                logradouro_prestador: Yup.string().required("O campo é obrigatório!"),
                bairro_prestador: Yup.string().required("O campo é obrigatório!"),
                municipio_prestador: Yup.object().required("O campo é obrigatório!"),
                numero_end_prestador: Yup.number().required("O campo é obrigatório!"),
                cep_prestador: Yup.string().required("O campo é obrigatório!"),
                logradouro_tomador: Yup.string().required("O campo é obrigatório!"),
                bairro_tomador: Yup.string().required("O campo é obrigatório!"),
                municipio_tomador: Yup.object().required("O campo é obrigatório!"),
                numero_end_tomador: Yup.number().required("O campo é obrigatório!"),
                cep_tomador: Yup.string().required("O campo é obrigatório!"),
            });

            await formSchema.validate(values, {
                abortEarly: false,
            });

            await handleDadosGerais({ ...values, operacao_servico: props.operacao });
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            } else {
                toastRef.current.show({
                    severity: "error",
                    summary: "Erro",
                    detail: "Desculpe, não conseguimos processar a sua requisição.",
                    life: 1500,
                });
            }
        }
    }

    const autoCompleteCfopTemplate = (valor) => {
        valor.label = `${valor.codigo} - ${valor.descricao}`;
        return `${valor.codigo} - ${valor.descricao}`;
    };

    const onChangeEnderecoPrestador = (endereco) => {
        setFieldValue("bairro_prestador", endereco.bairro);
        setFieldValue("logradouro_prestador", endereco.logradouro);
        setFieldValue("endereco_prestador", endereco);
        setFieldValue("numero_end_prestador", parseInt(endereco.numero));
        setFieldValue("cep_prestador", endereco.cep);
        setFieldValue("municipio_prestador", endereco.cidade);
    };

    const autoCompleteTemplate = (tomador) => {
        return `${tomador.identificacao} - ${tomador.nome}`;
    };

    const onChangeEnderecoTomador = (endereco) => {
        setFieldValue("bairro_tomador", endereco.bairro);
        setFieldValue("logradouro_tomador", endereco.logradouro);
        setFieldValue("endereco_tomador", endereco);
        setFieldValue("numero_end_tomador", parseInt(endereco.numero));
        setFieldValue("cep_tomador", endereco.cep);
        setFieldValue("municipio_tomador", endereco.cidade);
    };

    useEffect(() => {
        setValues(notaServico);
    }, [notaServico, setValues]);

    return (
        <div className="p-grid">
            <div className="p-col-12">
                <div className="card">
                    <Toast ref={toastRef} />
                    <form onSubmit={formik.handleSubmit}>
                        <Divider align="center">
                            <b>Dados Gerais</b>
                        </Divider>
                        <div className="p-fluid p-formgrid p-grid">
                            <div className="p-field p-col-12 p-md-8">
                                <label htmlFor="natureza_operacao">Natureza da Operação *</label>
                                <MakoAutoComplete
                                    id="natureza_operacao"
                                    name="natureza_operacao"
                                    placeholder="Busque pelo código ou descrição ... (min 2 caracteres)"
                                    minCaracteresBusca={2}
                                    value={formik.values.natureza_operacao}
                                    onChange={formik.handleChange}
                                    itemTemplate={autoCompleteCfopTemplate}
                                    field="label"
                                    urlSearch={"/fiscal/cfop?limit=60&search="}
                                    className={classNames({ "p-invalid": formik.errors.natureza_operacao })}
                                />
                                {formik.errors.natureza_operacao && (
                                    <small className="p-error">{formik.errors.natureza_operacao}</small>
                                )}
                            </div>
                            <div className="p-field p-col-12 p-md-2">
                                <label htmlFor="rps_numero">Número *</label>
                                <InputNumber
                                    id="rps_numero"
                                    name="rps_numero"
                                    value={formik.values.rps_numero}
                                    onValueChange={formik.handleChange}
                                    className={classNames({ "p-invalid": formik.errors.rps_numero })}
                                />
                                {formik.errors.rps_numero && (
                                    <small className="p-error">{formik.errors.rps_numero}</small>
                                )}
                            </div>
                            <div className="p-field p-col-12 p-md-2">
                                <label htmlFor="rps_serie">Série *</label>
                                <InputNumber
                                    id="rps_serie"
                                    name="rps_serie"
                                    value={formik.values.rps_serie}
                                    onValueChange={formik.handleChange}
                                    className={classNames({ "p-invalid": formik.errors.rps_serie })}
                                />
                                {formik.errors.rps_serie && (
                                    <small className="p-error">{formik.errors.rps_serie}</small>
                                )}
                            </div>
                        </div>
                        <div className="p-fluid p-formgrid p-grid">
                            <div className="p-field p-col-12 p-md-3">
                                <label htmlFor="data_competencia">Data competência *</label>
                                <MakoCalendar
                                    id="data_competencia"
                                    name="data_competencia"
                                    valueCalendar={formik.values.data_competencia}
                                    onChange={formik.handleChange}
                                    className={classNames({
                                        "p-invalid": formik.errors.data_competencia,
                                    })}
                                />
                                {formik.errors.data_competencia && (
                                    <small className="p-error">{formik.errors.data_competencia}</small>
                                )}
                            </div>
                            <div className="p-field p-col-12 p-md-5">
                                <label htmlFor="local_prestacao">Local prestação *</label>
                                <Dropdown
                                    id="local_prestacao"
                                    name="local_prestacao"
                                    placeholder="Selecione..."
                                    options={TIPO_LOCAL_PRESTACAO_CHOICE}
                                    optionValue="value"
                                    optionLabel="label"
                                    showClear={false}
                                    value={formik.values.local_prestacao}
                                    onChange={formik.handleChange}
                                    className={classNames({ "p-invalid": formik.errors.local_prestacao })}
                                />
                                {formik.errors.local_prestacao && (
                                    <small className="p-error">{formik.errors.local_prestacao}</small>
                                )}
                            </div>
                        </div>
                        <Divider align="center">
                            <b>Dados do Prestador</b>
                        </Divider>
                        <div className="p-fluid p-formgrid p-grid">
                            <div className="p-field p-col-12 p-md-9">
                                <label htmlFor="empresa">Prestador *</label>
                                <MakoDropdownEmpresas
                                    id="empresa"
                                    name="empresa"
                                    value={formik.values.empresa}
                                    className={classNames({ "p-invalid": formik.errors.empresa })}
                                    onChange={(e) => setFieldValue("empresa", e.id)}
                                />
                                {formik.errors.empresa && <small className="p-error">{formik.errors.empresa}</small>}
                            </div>
                            <div className="p-field p-col-12 p-md-3">
                                <label htmlFor="identificacao">CPF/Cnpj </label>
                                <InputText
                                    id="identificacao"
                                    name="identificacao"
                                    value={formik.values.empresa?.identificacao || ""}
                                    disabled
                                />
                            </div>
                        </div>
                        <div className="p-fluid p-formgrid p-grid">
                            <div className="p-field p-col-12 p-md-5">
                                <label htmlFor="endereco_prestador">Endereço *</label>
                                <Dropdown
                                    id="endereco_prestador"
                                    name="endereco_prestador"
                                    placeholder="Selecione..."
                                    url={`/pessoas/enderecos-perfis?perfil_id=${formik.values?.empresa?.id || null}`}
                                    optionLabel="logradouro"
                                    showClear={false}
                                    value={formik.values.endereco_prestador}
                                    onChange={(e) => onChangeEnderecoPrestador(e.target.value)}
                                    className={classNames({ "p-invalid": formik.errors.endereco_prestador })}
                                />
                                {formik.errors.endereco_prestador && (
                                    <small className="p-error">{formik.errors.endereco_prestador}</small>
                                )}
                            </div>
                            <div className="p-field p-col-12 p-md-3">
                                <label htmlFor="bairro_prestador">Bairro *</label>
                                <InputText
                                    id="bairro_prestador"
                                    name="bairro_prestador"
                                    value={formik.values.bairro_prestador}
                                    onChange={formik.handleChange}
                                    className={classNames({ "p-invalid": formik.errors.bairro_prestador })}
                                />
                                {formik.errors.bairro_prestador && (
                                    <small className="p-error">{formik.errors.bairro_prestador}</small>
                                )}
                            </div>
                            <div className="p-field p-col-12 p-md-2">
                                <label htmlFor="numero_end_prestador">Número *</label>
                                <InputNumber
                                    id="numero_end_prestador"
                                    name="numero_end_prestador"
                                    value={formik.values.numero_end_prestador}
                                    onValueChange={formik.handleChange}
                                    className={classNames({ "p-invalid": formik.errors.numero_end_prestador })}
                                />
                                {formik.errors.numero_end_prestador && (
                                    <small className="p-error">{formik.errors.numero_end_prestador}</small>
                                )}
                            </div>
                            <div className="p-field p-col-12 p-md-2">
                                <label htmlFor="cep_prestador">CEP *</label>
                                <InputText
                                    id="cep_prestador"
                                    name="cep_prestador"
                                    value={formik.values.cep_prestador}
                                    onChange={formik.handleChange}
                                    className={classNames({ "p-invalid": formik.errors.cep_prestador })}
                                />
                                {formik.errors.cep_prestador && (
                                    <small className="p-error">{formik.errors.cep_prestador}</small>
                                )}
                            </div>
                        </div>
                        <div className="p-fluid p-formgrid p-grid">
                            <div className="p-field p-col-12 p-md-4">
                                <label htmlFor="municipio_prestador">Município *</label>
                                <InputText
                                    id="municipio_prestador"
                                    name="municipio_prestador"
                                    value={formik.values.municipio_prestador?.nome}
                                    onChange={formik.handleChange}
                                    className={classNames({ "p-invalid": formik.errors.municipio_prestador })}
                                    disabled
                                />
                                {formik.errors.municipio_prestador && (
                                    <small className="p-error">{formik.errors.municipio_prestador}</small>
                                )}
                            </div>
                            <div className="p-field p-col-12 p-md-4">
                                <label htmlFor="uf_prestador">UF *</label>
                                <InputText
                                    id="uf_prestador"
                                    name="uf_prestador"
                                    value={formik.values.municipio_prestador?.estado?.nome}
                                    onChange={formik.handleChange}
                                    className={classNames({ "p-invalid": formik.errors.uf_prestador })}
                                    disabled
                                />
                                {formik.errors.uf_prestador && (
                                    <small className="p-error">{formik.errors.uf_prestador}</small>
                                )}
                            </div>
                        </div>
                        <Divider align="center">
                            <b>Dados do Tomador</b>
                        </Divider>
                        <div className="p-fluid p-formgrid p-grid">
                            <div className="p-field p-col-12 p-md-9">
                                <label htmlFor="tomador">Tomador *</label>
                                <MakoAutoComplete
                                    id="tomador"
                                    name="tomador"
                                    placeholder="Busque pelo nome ou cpf/cnpj ... (min 4 caractéres)"
                                    minCaracteresBusca={4}
                                    value={formik.values.tomador}
                                    onChange={formik.handleChange}
                                    itemTemplate={autoCompleteTemplate}
                                    field="nome"
                                    urlSearch={"/pessoas/perfis?search="}
                                />
                                {formik.errors.tomador && <small className="p-error">{formik.errors.tomador}</small>}
                            </div>
                            <div className="p-field p-col-12 p-md-3">
                                <label htmlFor="identificacao">CPF/Cnpj </label>
                                <InputText
                                    id="identificacao"
                                    name="identificacao"
                                    value={formik.values.tomador?.identificacao || ""}
                                    disabled
                                />
                            </div>
                        </div>
                        <div className="p-fluid p-formgrid p-grid">
                            <div className="p-field p-col-12 p-md-5">
                                <label htmlFor="endereco_tomador">Endereço *</label>
                                <Dropdown
                                    id="endereco_tomador"
                                    name="endereco_tomador"
                                    placeholder="Selecione..."
                                    options={formik.values.tomador?.enderecoperfil_set || []}
                                    optionLabel="logradouro"
                                    showClear={false}
                                    disabled={!formik.values?.tomador?.id}
                                    value={formik.values.endereco_tomador}
                                    onChange={(e) => onChangeEnderecoTomador(e.target.value)}
                                    className={classNames({ "p-invalid": formik.errors.endereco_tomador })}
                                />
                                {formik.errors.endereco_tomador && (
                                    <small className="p-error">{formik.errors.endereco_tomador}</small>
                                )}
                            </div>
                            <div className="p-field p-col-12 p-md-3">
                                <label htmlFor="bairro_tomador">Bairro *</label>
                                <InputText
                                    id="bairro_tomador"
                                    name="bairro_tomador"
                                    value={formik.values.bairro_tomador}
                                    onChange={formik.handleChange}
                                    className={classNames({ "p-invalid": formik.errors.bairro_tomador })}
                                />
                                {formik.errors.bairro_tomador && (
                                    <small className="p-error">{formik.errors.bairro_tomador}</small>
                                )}
                            </div>
                            <div className="p-field p-col-12 p-md-2">
                                <label htmlFor="numero_end_tomador">Número *</label>
                                <InputNumber
                                    id="numero_end_tomador"
                                    name="numero_end_tomador"
                                    value={formik.values.numero_end_tomador}
                                    onValueChange={formik.handleChange}
                                    className={classNames({ "p-invalid": formik.errors.numero_end_tomador })}
                                />
                                {formik.errors.numero_end_tomador && (
                                    <small className="p-error">{formik.errors.numero_end_tomador}</small>
                                )}
                            </div>
                            <div className="p-field p-col-12 p-md-2">
                                <label htmlFor="cep_tomador">CEP *</label>
                                <InputText
                                    id="cep_tomador"
                                    name="cep_tomador"
                                    value={formik.values.cep_tomador}
                                    onChange={formik.handleChange}
                                    className={classNames({ "p-invalid": formik.errors.cep_tomador })}
                                />
                                {formik.errors.cep_tomador && (
                                    <small className="p-error">{formik.errors.cep_tomador}</small>
                                )}
                            </div>
                        </div>
                        <div className="p-fluid p-formgrid p-grid">
                            <div className="p-field p-col-12 p-md-4">
                                <label htmlFor="municipio_tomador">Município *</label>
                                <InputText
                                    id="municipio_tomador"
                                    name="municipio_tomador"
                                    value={formik.values.municipio_tomador?.nome}
                                    onChange={formik.handleChange}
                                    className={classNames({ "p-invalid": formik.errors.municipio_tomador })}
                                    disabled
                                />
                                {formik.errors.municipio_tomador && (
                                    <small className="p-error">{formik.errors.municipio_tomador}</small>
                                )}
                            </div>
                            <div className="p-field p-col-12 p-md-4">
                                <label htmlFor="uf_tomador">UF *</label>
                                <InputText
                                    id="uf_tomador"
                                    name="uf_tomador"
                                    value={formik.values.municipio_tomador?.estado?.nome}
                                    onChange={formik.handleChange}
                                    className={classNames({ "p-invalid": formik.errors.uf_tomador })}
                                    disabled
                                />
                                {formik.errors.uf_tomador && (
                                    <small className="p-error">{formik.errors.uf_tomador}</small>
                                )}
                            </div>
                        </div>
                        <p>
                            <b>* Campos obrigatórios.</b>
                        </p>
                        <br></br>
                        {!submit ? (
                            <div className="p-grid">
                                <div className="p-grid p-col-12 p-md-6 p-ml-1">
                                    <Button
                                        label="Incluir dados básicos"
                                        icon="pi pi-save"
                                        type="submit"
                                        className="p-mr-2 p-mb-6 p-button-info"
                                    />
                                </div>
                            </div>
                        ) : null}
                        <FormikAutoSave intervalo={500} autosave={submit && !notaServico?.id} formik={formik} />
                    </form>
                </div>
            </div>
        </div>
    );
};

export default memo(forwardRef(DadosNotaForm));
