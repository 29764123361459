import React from "react";

import { MakoButton } from "@/components/MakoButton";

export const Cancelar = () => {
    return (
        <MakoButton
            icon="pi pi-sign-out"
            tooltip="Cancelar"
            type="button"
            className="p-button-rounded p-button-danger"
        />
    );
};
