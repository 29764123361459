import React, { useRef, useState } from "react";
import classNames from "classnames";
import { InputText } from "primereact/inputtext";
import { InputSwitch } from "primereact/inputswitch";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { useFormik } from "formik";
import { Dropdown } from "primereact/dropdown";
import * as Yup from "yup";
import { dataToStr } from "@/assets/util/datas";
import { axiosPost, axiosPut } from "@/services/http";
import useLoading from "@/hooks/useLoading";
import { MakoCalendar } from "@/components/MakoCalendar";
import { useHistory } from "react-router-dom";
import { TIPO_MOVIMENTO_FISCAL_CHOICE } from "@/assets/constants/constants";

const url = "/fiscal/cfop/";
const urlvoltar = "/fiscal/cadastros/cfop";

export const FiscalCfopForm = (props) => {
    const history = useHistory();
    const { showLoading, hideLoading } = useLoading();
    const toastRef = useRef(null);

    const cfopVazio = {
        codigo: "",
        descricao: "",
        descricao_reduzida: "",
        vigencia_inicial: null,
        vigencia_final: null,
        tipo_movimentacao: null,
        ativo: true,
    };

    const formik = useFormik({
        initialValues: props.location.state ? props.location.state.cfop : cfopVazio,
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                codigo: Yup.string().required("O campo 'código' é obrigatório.").max(5),
                descricao: Yup.string().required("O campo 'descrição' é obrigatório."),
                descricao_reduzida: Yup.string().required("O campo 'descrição reduzida' é obrigatório.").max(50),
                tipo_movimentacao: Yup.string().required("O campo 'tipo de movimentação' é obrigatório."),
                vigencia_inicial: Yup.date().required("O campo 'vigência inicial' é obrigatório."),
            });

            await formSchema.validate(values, {
                abortEarly: false,
            });

            values.vigencia_inicial = dataToStr(values.vigencia_inicial, "yyyy-MM-dd");
            values.vigencia_final = dataToStr(values.vigencia_final, "yyyy-MM-dd");

            if (!values.id) {
                showLoading();
                const resposta = await axiosPost(`${url}`, values);
                hideLoading();

                if (resposta.status === 201) {
                    toastRef.current.show({
                        severity: "success",
                        summary: "Sucesso",
                        detail: "CFOP cadastrado com sucesso!",
                        life: 1500,
                    });

                    setTimeout(() => {
                        formik.resetForm();
                        history.push(urlvoltar);
                    }, 2000);
                } else if (resposta.status === 400) {
                    if (resposta.data && resposta.data.codigo)
                        toastRef.current.show({
                            severity: "error",
                            summary: "CFOP Existente!",
                            detail: "O CFOP com este Código já existe.",
                            life: 1500,
                        });
                    else
                        toastRef.current.show({
                            severity: "error",
                            summary: "Erro",
                            detail: "Desculpe, não conseguimos processar a sua requisição.",
                            life: 1500,
                        });
                } else {
                    toastRef.current.show({
                        severity: "error",
                        summary: "Erro",
                        detail: "Desculpe, não conseguimos processar a sua requisição.",
                        life: 1500,
                    });
                }
            } else {
                values.ativo = ativo;

                showLoading();
                const resposta = await axiosPut(`${url}${values.id}/`, values);
                hideLoading();

                if (resposta.status === 200) {
                    toastRef.current.show({
                        severity: "success",
                        summary: "Sucesso",
                        detail: "CFOP alterado com sucesso!",
                        life: 1500,
                    });

                    setTimeout(() => {
                        formik.resetForm();
                        history.push(urlvoltar);
                    }, 2000);
                } else {
                    toastRef.current.show({
                        severity: "error",
                        summary: "Erro",
                        detail: "Desculpe, não conseguimos processar a sua requisição.",
                        life: 1500,
                    });
                }
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};

                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });

                formik.setErrors(errorMessages);
                hideLoading();
            } else {
                toastRef.current.show({
                    severity: "error",
                    summary: "Erro",
                    detail: "Desculpe, não conseguimos processar a sua requisição.",
                    life: 1500,
                });
                hideLoading();
            }
        }
    }

    const cancelar = () => {
        formik.resetForm();
        history.push(urlvoltar);
    };

    const [ativo, setAtivo] = useState(formik.values.ativo);

    return (
        <div className="p-grid">
            <div className="p-col-12">
                <div className="card">
                    <Toast ref={toastRef} />
                    <h3>{!formik.values.id ? "Novo CFOP" : "Manutenção de CFOP"}</h3>
                    <form onSubmit={formik.handleSubmit}>
                        <div className="p-fluid p-formgrid p-grid">
                            <div className="p-field p-col-12 p-md-2">
                                <label htmlFor="codigo">Código *</label>
                                <InputText
                                    id="codigo"
                                    name="codigo"
                                    keyfilter="int"
                                    value={formik.values.codigo}
                                    onChange={formik.handleChange}
                                    className={classNames({ "p-invalid": formik.errors.codigo })}
                                    tooltip="Código do CFOP Exemplo: Código '999' da descrição 'Outros'."
                                    autoComplete="off"
                                    autoFocus
                                />
                                {formik.errors.codigo && <small className="p-error">{formik.errors.codigo}</small>}
                            </div>
                            <div className="p-field p-col-12 p-md-6">
                                <label htmlFor="descricao">Descricao *</label>
                                <InputText
                                    id="descricao"
                                    name="descricao"
                                    value={formik.values.descricao}
                                    onChange={formik.handleChange}
                                    className={classNames({ "p-invalid": formik.errors.descricao })}
                                    tooltip="Descricao que será dado ao CFOP."
                                    autoComplete="off"
                                />
                                {formik.errors.descricao && (
                                    <small className="p-error">{formik.errors.descricao}</small>
                                )}
                            </div>
                            <div className="p-field p-col-12 p-md-4">
                                <label htmlFor="descricao_reduzida">Descricao reduzida *</label>
                                <InputText
                                    id="descricao_reduzida"
                                    name="descricao_reduzida"
                                    value={formik.values.descricao_reduzida}
                                    onChange={formik.handleChange}
                                    className={classNames({ "p-invalid": formik.errors.descricao_reduzida })}
                                    tooltip="Descricao reduzida que aparecerá na nota fiscal."
                                    autoComplete="off"
                                />
                                {formik.errors.descricao_reduzida && (
                                    <small className="p-error">{formik.errors.descricao_reduzida}</small>
                                )}
                            </div>
                        </div>
                        <div className="p-fluid p-formgrid p-grid">
                            <div className="p-field p-col-12 p-md-3">
                                <label htmlFor="tipo_movimentacao">Tipo de movimentação *</label>
                                <Dropdown
                                    id="tipo_movimentacao"
                                    name="tipo_movimentacao"
                                    placeholder="Selecione um tipo de movimentação"
                                    options={TIPO_MOVIMENTO_FISCAL_CHOICE}
                                    optionValue="id"
                                    optionLabel="label"
                                    value={formik.values.tipo_movimentacao}
                                    onChange={formik.handleChange}
                                    className={classNames({ "p-invalid": formik.errors.tipo_movimentacao })}
                                />
                                {formik.errors.tipo_movimentacao && (
                                    <small className="p-error">{formik.errors.tipo_movimentacao}</small>
                                )}
                            </div>
                            <div className="p-field p-col-12 p-md-3">
                                <label htmlFor="vigencia_inicial">Vigência inicial *</label>
                                <MakoCalendar
                                    id="vigencia_inicial"
                                    name="vigencia_inicial"
                                    valueCalendar={formik.values.vigencia_inicial}
                                    onChange={formik.handleChange}
                                    className={classNames({
                                        "p-invalid": formik.errors.vigencia_inicial,
                                    })}
                                />
                                {formik.errors.vigencia_inicial && (
                                    <small className="p-error">{formik.errors.vigencia_inicial}</small>
                                )}
                            </div>
                            <div className="p-field p-col-12 p-md-3">
                                <label htmlFor="vigencia_final">Vigência final</label>
                                <MakoCalendar
                                    id="vigencia_final"
                                    name="vigencia_final"
                                    valueCalendar={formik.values.vigencia_final}
                                    onChange={formik.handleChange}
                                    className={classNames({
                                        "p-invalid": formik.errors.vigencia_final,
                                    })}
                                />
                                {formik.errors.vigencia_final && (
                                    <small className="p-error">{formik.errors.vigencia_final}</small>
                                )}
                            </div>
                        </div>
                        <div className="p-fluid p-formgrid p-grid">
                            <div className="p-field p-col-1 ">
                                <h5>
                                    <label htmlFor="status">Status</label>
                                </h5>
                                <InputSwitch
                                    id="ativo"
                                    name="ativo"
                                    checked={ativo}
                                    disabled={formik.values.id ? false : true}
                                    value={formik.values.ativo}
                                    onChange={(e) => setAtivo(e.value)}
                                />
                            </div>
                        </div>

                        <p>
                            <b>* Campos obrigatórios.</b>
                        </p>

                        <div className="p-grid p-col-12 p-md-6">
                            <Button label="Gravar" type="submit" className="p-button-info p-mr-2 p-mb-6" />
                            <Button
                                label="Cancelar"
                                type="reset"
                                className="p-button-danger p-mr-2 p-mb-6"
                                onClick={cancelar}
                            />
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};
