import React, { useCallback, useEffect, useImperativeHandle, useState, forwardRef } from "react";
import { TreeTable } from "primereact/treetable";
import { Column } from "primereact/column";
import { Dropdown } from "primereact/dropdown";
import { axiosGet } from "@/services/http";
import useToast from "@/hooks/useToast";
import { useLocalFiltro } from "@/hooks/useLocalFiltro";
import { DJANGO_FILTERS_MATCHMODE } from "@/assets/constants/constants";

const Listagem = (
    {
        titulo,
        urlPesquisa,
        colunas,
        cabecalho,
        limit,
        filtros,
        dados,
        mensagemTabelaVazia,
        tabelaConfig,
        aposPesquisar,
        keyFiltrosStorage = null,
        fazerBusca = true,
    },
    ref
) => {
    const [loading, setLoading] = useState(false);
    const [nodes, setNodes] = useState([]);
    const { showWarning, showError } = useToast();

    const [lazyParams, setLazyParams] = useLocalFiltro(keyFiltrosStorage, {
        first: 0,
        rows: limit,
        page: 0,
        filters: filtros,
    });

    function adicionarClausulaFiltro(filtros, field, matchMode, value) {
        if (value !== null && value !== undefined && value.toString().length > 0) {
            filtros[`${field}${DJANGO_FILTERS_MATCHMODE[matchMode] || ""}`] =
                value instanceof Object ? value?.id : value;
        }

        return filtros;
    }

    const criarFiltro = useCallback((filtros) => {
        let filtroPronto = {};
        for (let [k, v] of Object.entries(filtros)) {
            let { value, matchMode, constraints } = v;

            if (constraints instanceof Array) {
                let aux = {};
                constraints.forEach((constraint) => {
                    const { matchMode: constraintMatchMode, value: constraintValue } = constraint;
                    aux = adicionarClausulaFiltro(aux, k, constraintMatchMode, constraintValue);
                });

                filtroPronto = { ...filtroPronto, ...aux };
            }

            filtroPronto = adicionarClausulaFiltro(filtroPronto, k, matchMode, value);
        }

        return filtroPronto;
    }, []);

    const buscarDadosBackend = useCallback(async () => {
        if (typeof urlPesquisa !== "string" && !fazerBusca) return null;
        const [urlBase, query] = urlPesquisa.split("?");
        const limit = parseInt(lazyParams.rows);
        const offset = limit * parseInt(lazyParams.page);
        let params = { limit, offset };

        if (query) {
            const queryParams = query.split("&");

            queryParams.forEach((param) => {
                const [campo, valor] = param.split("=");
                params[campo] = valor;
            });
        }

        if (lazyParams.filters) {
            const _filtro = criarFiltro(lazyParams.filters);

            params = {
                ...params,
                ..._filtro,
            };
        }
        setLoading(true);
        const { status, data } = await axiosGet(urlBase, { params });
        setLoading(false);

        if (status === 200) {
            if (typeof aposPesquisar === "function") {
                setNodes(aposPesquisar(data));
            } else {
                setNodes(data);
            }
        } else if (status === 401) {
            showWarning({
                summary: "Acesso expirado!",
                detail: "Você não está autenticado, faça login novamente.",
                life: 3000,
            });
        } else {
            showError({
                summary: "Erro :(",
                detail: "Desculpe, não conseguimos listar suas informações.",
                life: 3000,
            });
        }
    }, [lazyParams, urlPesquisa, fazerBusca, aposPesquisar, criarFiltro, showWarning, showError]);

    const popularTabela = useCallback(async () => {
        if (dados) {
            if (typeof aposPesquisar === "function") {
                setNodes(aposPesquisar(dados));
            } else {
                setNodes(dados);
            }
        } else if (urlPesquisa) {
            buscarDadosBackend();
        }
    }, [dados, urlPesquisa, aposPesquisar, buscarDadosBackend]);

    useEffect(() => {
        popularTabela();
    }, [popularTabela]);

    useImperativeHandle(ref, () => ({ buscarDados: popularTabela }));

    const templatePaginacao = {
        layout: "CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown",
        RowsPerPageDropdown: (options) => {
            const dropdownOptions = [
                { label: 20, value: 20 },
                { label: 50, value: 50 },
                { label: 100, value: 100 },
                { label: 200, value: 200 },
            ];

            return <Dropdown value={options.value} options={dropdownOptions} onChange={options.onChange} />;
        },
        CurrentPageReport: (options) => {
            return (
                <span style={{ color: "var(--text-color)", userSelect: "none" }}>
                    Exibindo {options.first} a {options.last} de {options.totalRecords}
                </span>
            );
        },
    };

    const trocarPagina = (event) => {
        let _lazyParams = { ...lazyParams, ...event, filters: { ...lazyParams.filters } };
        setLazyParams(_lazyParams);
    };

    const ordenarRegistros = (event) => {
        let _lazyParams = { ...lazyParams, ...event, filters: { ...lazyParams.filters } };
        setLazyParams(_lazyParams);
    };

    const filtrarRegistros = (event) => {
        let _lazyParams = { ...lazyParams, ...event, first: 0 };
        setLazyParams(_lazyParams);
    };

    const colunasDinamicas = colunas.map((col, i) => {
        const { field, header, action = null, ...rest } = col;
        return <Column key={i} field={field} header={header} body={action} {...rest} />;
    });

    const header = cabecalho ? <div className="table-header">{cabecalho}</div> : null;

    return (
        <>
            {titulo && <h5>{titulo}</h5>}
            <div className="treetable-responsive-demo">
                <TreeTable
                    header={header}
                    value={nodes}
                    loading={loading}
                    sortField={lazyParams?.sortField}
                    sortOrder={lazyParams?.sortOrder}
                    filters={lazyParams?.filters}
                    first={lazyParams?.first}
                    rows={lazyParams?.rows}
                    onFilter={filtrarRegistros}
                    onPage={trocarPagina}
                    onSort={ordenarRegistros}
                    paginatorTemplate={templatePaginacao}
                    emptyMessage={mensagemTabelaVazia || "Nenhum registro encontrado."}
                    className="p-datatable-sm mako-table"
                    {...tabelaConfig}
                >
                    {colunasDinamicas}
                </TreeTable>
            </div>
        </>
    );
};

export const MakoListagemHierarquica = forwardRef(Listagem);
