import React, { useRef } from "react";
import { useHistory } from "react-router-dom";
import { Button } from "primereact/button";
import { Tag } from "primereact/tag";

import { PageBase } from "@/components/PageBase";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import { BotaoDelete } from "@/components/BotaoDelete";
import { SITUACAO_ASSISTENCIA_TECNICA } from "@/assets/constants/vendas";
import MakoListagem from "@/components/MakoListagem";
import permissoes from "@/assets/constants/permissoes";

const BASE_URL_API = "/vendas/tipos-ocorrencia-assistencia-tecnica";
const URL_FORM = "/vendas/cadastros/assistencia-tecnica/tipos-ocorrencia/form";

export const TipoOcorrenciaAssistTecnicaPage = () => {
    const listagemRef = useRef(null);
    const history = useHistory();

    const novaSituacaoBodyTemplate = (rowData) => {
        const situacao = SITUACAO_ASSISTENCIA_TECNICA.find((el) => el.value === rowData.novo_status);
        if (!situacao) return <span>N/A</span>;
        return <span>{situacao.label}</span>;
    };

    const emitirNFBodyTemplate = (rowData) => {
        if (rowData.emitir_nota) return <span>Sim</span>;
        return <span>Não</span>;
    };

    const ativoBodyTemplate = (rowData) => {
        if (!rowData.ativo) return <Tag value="INATIVO" severity="danger" />;
        return <Tag value="ATIVO" severity="success" />;
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <MakoControleAcesso
                    permissao={[permissoes.VENDAS_CADASTRO_TIPOOCORRENCIAASSITTECNICA_EDITAR]}
                    componente={Button}
                    icon="pi pi-pencil"
                    disabled={!rowData.ativo}
                    className="p-button-rounded p-button-warning p-mr-2 p-mb-1"
                    onClick={() => history.push({ pathname: URL_FORM, state: rowData })}
                />
                <MakoControleAcesso
                    permissao={[permissoes.VENDAS_CADASTRO_TIPOOCORRENCIAASSITTECNICA_EXCLUIR]}
                    componente={BotaoDelete}
                    url={BASE_URL_API}
                    objetoId={rowData.id}
                    classNames="p-mb-1"
                    exigeConfirmacao
                    msgConfirmacao={
                        <span>
                            Deseja mesmo excluir o tipo de ocorrência <b>{rowData.descricao}</b>?
                        </span>
                    }
                    msgToastErroExclusao="O tipo de ocorrência não pode ser excluído."
                    onDelete={() => listagemRef.current?.buscarDados()}
                />
            </div>
        );
    };

    const cabecalhoTabela = (
        <>
            <MakoControleAcesso
                permissao={[permissoes.OUTROS_IMPRESSORATERMICA_INCLUIR]}
                componente={Button}
                label="Nova"
                icon="pi pi-plus"
                className="p-button-success p-mr-2"
                onClick={() => history.push(URL_FORM)}
            />
        </>
    );

    const colunas = [
        { field: "id", header: "Código", style: { width: "10%" } },
        { field: "descricao", header: "Descrição" },
        { field: "prazo_dias", header: "Prazo (dias)", style: { width: "15%" } },
        { field: "novo_status", header: "Nova situação", style: { width: "10%" }, action: novaSituacaoBodyTemplate },
        { field: "emitir_nf", header: "Emitir NF", style: { width: "10%" }, action: emitirNFBodyTemplate },
        { field: "ativa", header: "Situação", style: { width: "10%" }, action: ativoBodyTemplate },
        {
            field: "action",
            header: "Ações",
            style: { width: "10%" },
            action: (e) => actionBodyTemplate(e),
        },
    ];

    return (
        <PageBase>
            <MakoListagem
                ref={listagemRef}
                titulo="Tipos de ocorrência de assistência técnica"
                colunas={colunas}
                painelEsquerdo={cabecalhoTabela}
                urlPesquisa={BASE_URL_API}
                responsiva
                configTabela={{
                    paginator: true,
                    lazy: true,
                }}
            />
        </PageBase>
    );
};
