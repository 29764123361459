import React, { useEffect, useMemo } from "react";

import classNames from "classnames";

import { useFormik } from "formik";
import * as Yup from "yup";

import { InputTextarea } from "primereact/inputtextarea";
import { InputNumber } from "primereact/inputnumber";
import { Button } from "primereact/button";

import { MakoAutoComplete } from "@/components/MakoAutoComplete";
import { MakoInputMoeda } from "@/components/MakoInputMoeda";
import { MakoCalendar } from "@/components/MakoCalendar";
import { Dropdown } from "@/components/Dropdown";

import { TIPOS_CHOICE_SERVICOS_SERVICO } from "@/assets/constants/constants";
import { axiosPatch, axiosPost } from "@/services/http";
import { dataToStr, parseData } from "@/assets/util/datas";

import useContratoServico from "@/hooks/useContratoServico";
import useEmpresa from "@/hooks/useEmpresa";

export const ModalFaturamento = ({ onFinish, onClose }) => {
    const { empresaSelecionada } = useEmpresa();
    const { contrato } = useContratoServico();

    const { setValues, setFieldValue, ...formik } = useFormik({
        initialValues: {
            data_inicial: parseData(contrato.data_iniciacao),
            data_final: parseData(contrato.data_finalizacao),
            endereco_prestacao_id: null,
            template_rateio: contrato.template_rateio,
        },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                data_inicial: Yup.date()
                    .required("O campo 'data inicial' é obrigatório")
                    .typeError("Informe uma 'data inicial' válida"),
                data_final: Yup.date().when("data_inicial", {
                    is: (val) => !!val,
                    then: Yup.date()
                        .min(values.data_inicial || new Date(), "O campo 'data final' não pode ser anterior a inicial")
                        .required("O campo 'data final' é obrigatório")
                        .typeError("Informe uma 'data final' válida"),
                }),
                template_rateio: Yup.number()
                    .required("O campo 'template de ratio' é obrigatório.")
                    .typeError("Informe uma 'template de ratio' válida."),
            });
            await formSchema.validate(values, { abortEarly: false });
            onFinish({
                ...values,
                data_inicial: dataToStr(values.data_inicial, "yyyy-MM-dd"),
                data_final: dataToStr(values.data_final, "yyyy-MM-dd"),
            });
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};

                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });

                formik.setErrors(errorMessages);
            }
        }
    }

    const aposBuscarEnderecos = useMemo(() => {
        return (dados) => {
            dados.map((endereco) => {
                endereco.label = `${endereco.identificacao} - ${endereco.cidade.nome}, ${endereco.bairro}, ${endereco.logradouro} Nº ${endereco.numero}`;
            });
            return dados;
        };
    }, []);

    return (
        <>
            <form onSubmit={formik.handleSubmit}>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="data_inicial">Data Inicial *</label>
                        <MakoCalendar
                            id="data_inicial"
                            name="data_inicial"
                            valueCalendar={formik.values.data_inicial}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.data_inicial })}
                        />
                        {formik.errors.data_inicial && <small className="p-error">{formik.errors.data_inicial}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="data_final">Data final *</label>
                        <MakoCalendar
                            id="data_final"
                            name="data_final"
                            valueCalendar={formik.values.data_final}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.data_final })}
                        />
                        {formik.errors.data_final && <small className="p-error">{formik.errors.data_final}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-4">
                        <label htmlFor="template_rateio">Regra de rateio *</label>
                        <Dropdown
                            id="template_rateio"
                            name="template_rateio"
                            url={`/financeiro/templates-rateios/?query={id,descricao}`}
                            optionValue="id"
                            optionLabel="descricao"
                            emptyMessage="Não existem registros cadastrados"
                            value={formik.values.template_rateio}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.template_rateio })}
                        />
                        {formik.errors.template_rateio && (
                            <small className="p-error">{formik.errors.template_rateio}</small>
                        )}
                    </div>
                    <div className="p-field p-col-12 p-md-4">
                        <label htmlFor="endereco_prestacao_id">Endereço prestação serviço (cliente) *</label>
                        <Dropdown
                            id="endereco_prestacao_id"
                            name="endereco_prestacao_id"
                            url={`/pessoas/enderecos-perfis/?perfil__id=${contrato.cliente.id}`}
                            aposBuscar={aposBuscarEnderecos}
                            optionValue="id"
                            optionLabel="label"
                            emptyMessage="Não existem registros cadastrados"
                            value={formik.values.endereco_prestacao_id}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.endereco_prestacao_id })}
                        />
                        {formik.errors.endereco_prestacao_id && (
                            <small className="p-error">{formik.errors.endereco_prestacao_id}</small>
                        )}
                    </div>
                </div>
                <div className="p-grid p-col-12 p-md-6">
                    <Button icon="pi pi-save" label="Gravar" type="submit" className="p-button-info p-mr-2" />
                    <Button
                        icon="pi pi-trash"
                        label="Limpar"
                        type="button"
                        className="p-button-warning p-mr-2"
                        onClick={formik.resetForm}
                    />
                    <Button label="Cancelar" type="button" className="p-button-danger" onClick={onClose} />
                </div>
            </form>
        </>
    );
};
