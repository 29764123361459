import React, { useCallback, useEffect, useRef, useState } from "react";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { Dropdown } from "primereact/dropdown";
import { useFormik } from "formik";
import { useHistory } from "react-router-dom";
import { axiosPatch, axiosPost, axiosGet } from "@/services/http";
import { TIPO_TITULARIDADE_CONTA_BANCARIA_CHOICE } from "@/assets/constants/constants";

import useLoading from "@/hooks/useLoading";
import classNames from "classnames";
import * as Yup from "yup";
import { MakoInputPerfil } from "@/components/MakoInputs/MakoInputPerfil";
import { Checkbox } from "primereact/checkbox";

const url = "/financeiro/titulares-contas-bancarias/";
const urlvoltar = "/financeiro/cadastros/titulares-contas-bancarias";

export const FinanceiroTitularContaBancariaForm = (props) => {
    const { showLoading, hideLoading } = useLoading();
    const [contasBancarias, setContasBancarias] = useState([]);
    const toastRef = useRef(null);
    const history = useHistory();

    const formik = useFormik({
        initialValues: props.location.state
            ? {
                  ...props.location.state.titularContaBancaria,
                  tipo_titularidade: props.location.state.titularContaBancaria.tipo_titularidade.id,
                  conta_bancaria: props.location.state.titularContaBancaria.conta_bancaria.id,
              }
            : {
                  conta_bancaria: null,
                  perfil: null,
                  tipo_titularidade: null,
                  ativo: true,
              },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            if (!values.id) {
                const formSchema = Yup.object().shape({
                    tipo_titularidade: Yup.number()
                        .required("O campo 'tipo da titularidade' é obrigatório.")
                        .typeError("Selecione um tipo válido."),

                    conta_bancaria: Yup.number()
                        .required("O campo 'conta bancária' é obrigatório.")
                        .typeError("Informe uma data válida."),
                    perfil: Yup.object()
                        .required("O campo 'perfil' é obrigatório.")
                        .typeError("Selecione um perfil válido."),
                });

                await formSchema.validate(values, {
                    abortEarly: false,
                });

                showLoading();
                const resposta = await axiosPost(`${url}`, { ...values, perfil: values.perfil.id });
                hideLoading();

                if (resposta.status === 201) {
                    toastRef.current.show({
                        severity: "success",
                        summary: "Sucesso",
                        detail: "Titular de conta bancária cadastrado com sucesso!",
                        life: 1500,
                    });

                    setTimeout(() => {
                        formik.resetForm();
                        history.push(urlvoltar);
                    }, 2000);
                } else {
                    toastRef.current.show({
                        severity: "error",
                        summary: "Erro",
                        detail: "Desculpe, não conseguimos processar a sua requisição.",
                        life: 1500,
                    });
                }
            } else {
                showLoading();
                const resposta = await axiosPatch(`${url}${values.id}/`, { ...values, perfil: values.perfil.id });
                hideLoading();

                if (resposta.status === 200) {
                    toastRef.current.show({
                        severity: "success",
                        summary: "Sucesso",
                        detail: "Titular de conta bancária alterado com sucesso!",
                        life: 1500,
                    });

                    setTimeout(() => {
                        formik.resetForm();
                        history.push(urlvoltar);
                    }, 2000);
                } else {
                    toastRef.current.show({
                        severity: "error",
                        summary: "Erro",
                        detail: "Desculpe, não conseguimos processar a sua requisição.",
                        life: 1500,
                    });
                }
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
                hideLoading();
            } else {
                toastRef.current.show({
                    severity: "error",
                    summary: "Erro",
                    detail: "Desculpe, não conseguimos processar a sua requisição.",
                    life: 1500,
                });
                hideLoading();
            }
        }
    }

    const cancelar = () => {
        formik.resetForm();
        history.push(urlvoltar);
    };

    const listarContasBancarias = useCallback(async () => {
        showLoading();
        const resposta = await axiosGet("/financeiro/contas-bancarias/");
        hideLoading();

        if (resposta.status === 200) {
            let lista = [];
            resposta.data.results.forEach((item) => {
                lista.push({
                    ...item,
                    label: `Agência: ${item.agencia} | Conta: ${item.numero_conta}-${item.numero_conta_dv}`,
                });
            });
            setContasBancarias(lista);
        }
    }, [showLoading, hideLoading]);

    useEffect(() => {
        listarContasBancarias();
    }, [listarContasBancarias]);

    return (
        <div className="p-grid">
            <div className="p-col-12">
                <div className="card">
                    <Toast ref={toastRef} />
                    <h3>
                        {!formik.values.id ? "Novo titular de conta bancária" : "Manutenção titular de conta bancária"}
                    </h3>
                    <form onSubmit={formik.handleSubmit}>
                        <div className="p-fluid p-formgrid p-grid">
                            <div className="p-field p-col-12 p-md-3">
                                <label htmlFor="tipo_titularidade">Tipo de titularidade *</label>
                                <Dropdown
                                    id="tipo_titularidade"
                                    name="tipo_titularidade"
                                    placeholder="Selecione um tipo de titularidade"
                                    options={TIPO_TITULARIDADE_CONTA_BANCARIA_CHOICE}
                                    optionValue="id"
                                    optionLabel="label"
                                    value={formik.values.tipo_titularidade}
                                    onChange={formik.handleChange}
                                    className={classNames({ "p-invalid": formik.errors.tipo_titularidade })}
                                />
                                {formik.errors.tipo_titularidade && (
                                    <small className="p-error">{formik.errors.tipo_titularidade}</small>
                                )}
                            </div>
                            <div className="p-field p-col-12 p-md-9">
                                <label htmlFor="perfil">Perfil *</label>
                                <MakoInputPerfil
                                    id="perfil"
                                    name="perfil"
                                    value={formik.values.perfil}
                                    onChange={formik.handleChange}
                                    className={classNames({ "p-invalid": formik.errors.perfil })}
                                    autoComplete="off"
                                    autoFocus
                                />
                                {formik.errors.perfil && <small className="p-error">{formik.errors.perfil}</small>}
                            </div>
                            <div className="p-field p-col-12 p-md-5">
                                <label htmlFor="conta_bancaria">Conta bancária *</label>
                                <Dropdown
                                    id="conta_bancaria"
                                    name="conta_bancaria"
                                    placeholder="Selecione uma conta bancária"
                                    options={contasBancarias}
                                    optionValue="id"
                                    optionLabel="label"
                                    value={formik.values.conta_bancaria}
                                    onChange={formik.handleChange}
                                    className={classNames({ "p-invalid": formik.errors.conta_bancaria })}
                                />
                                {formik.errors.conta_bancaria && (
                                    <small className="p-error">{formik.errors.conta_bancaria}</small>
                                )}
                            </div>
                            <div className="p-field-checkbox p-col-12 p-md-3">
                                <br></br>
                                <Checkbox
                                    inputId="ativo"
                                    id="ativo"
                                    name="ativo"
                                    onChange={formik.handleChange}
                                    checked={formik.values.ativo}
                                    value={formik.values.ativo}
                                    disabled={!formik.values.id}
                                    className="p-mt-5"
                                />
                                <label htmlFor="ativo" className="p-mt-5">
                                    Status
                                </label>
                            </div>
                        </div>

                        <p>
                            <b>* Campos obrigatórios.</b>
                        </p>

                        <div className="p-grid p-col-12 p-md-6">
                            <Button label="Gravar" type="submit" className="p-button-info p-mr-2 p-mb-6" />
                            <Button
                                label="Cancelar"
                                type="reset"
                                className="p-button-danger p-mr-2 p-mb-6"
                                onClick={cancelar}
                            />
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};
