import React, { useMemo } from "react";

import MakoListagem from "@/components/MakoListagem";
import { gerarFiltrosUrl, parseMoeda } from "@/assets/util/util";

const BASE_URL_PENDENCIAS = "/financeiro/caixas/{id}/pendencias/";

const BASE_COLUMS = [
    {
        field: "id",
        header: "Documento",
        style: { width: "8%" },
        action: ({ id, venda_id }) => venda_id || id,
    },
    {
        field: "descricao",
        header: "Descrição",
    },
    {
        field: "valor",
        header: "Valor",
        action: ({ valor, recebido, tipo_chave, pago = 0 }) => {
            if (tipo_chave !== "pagamento") return parseMoeda(parseFloat(valor) - parseFloat(recebido), false);
            return parseMoeda(parseFloat(valor) - parseFloat(pago), false);
        },
        align: "right",
    },
];

const BASE_COLUMN_ACTIONS = {
    field: "actions",
    header: "Ações",
    style: { width: "15%" },
};

const BASE_FILTROS = { ordering: "id" };

export const ListagemPendencias = ({ caixaMovimento, filtros = BASE_FILTROS, listagemRef, actions }) => {
    const colunas = useMemo(() => {
        if (!actions) return BASE_COLUMS;
        const actionColum = {
            ...BASE_COLUMN_ACTIONS,
            action: (e) => actions(e),
        };
        return [...BASE_COLUMS, actionColum];
    }, [actions]);

    const url = useMemo(() => {
        const _url = BASE_URL_PENDENCIAS.replace("{id}", caixaMovimento?.id);
        let query = {};
        if (typeof filtros === "object") query = { ...query, ...filtros };
        return `${_url}?${gerarFiltrosUrl(query)}`;
    }, [caixaMovimento, filtros]);

    return (
        <MakoListagem
            ref={listagemRef}
            titulo="Pendências do caixa"
            colunas={colunas}
            responsiva
            urlPesquisa={url}
            fazerBusca={!!caixaMovimento}
            botaoReload={true}
            configTabela={{
                paginator: true,
                lazy: true,
            }}
        />
    );
};
