import React from "react";

import { InputNumber } from "primereact/inputnumber";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";

import { MakoInputCliente } from "@/components/MakoInputs";
import { MakoInputMoeda } from "@/components/MakoInputMoeda";
import { MakoCalendar } from "@/components/MakoCalendar";
import { Label } from "@/components/Label";

import { MAKO_ICONS } from "@/assets/constants/constants_styles";

export const Dados = () => {
    return (
        <div>
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12 p-md-2">
                    <Label htmlFor="venda" label="Venda / Orçamento" />
                    <InputText id="venda" name="venda" disabled />
                </div>
                <div className="p-field p-col-12 p-md-7">
                    <Label htmlFor="cliente" label="Cliente" />
                    <MakoInputCliente id="cliente" name="cliente" disabled />
                </div>
                <div className="p-field p-col-12 p-md-2">
                    <Label htmlFor="vencimento" label="Vencimento" />
                    <MakoCalendar id="vencimento" name="vencimento" disabled />
                </div>
                <div className="p-field p-col-12 p-md-1">
                    <Label htmlFor="parcela" label="Parcela" />
                    <InputText id="parcela" name="parcela" disabled />
                </div>
            </div>
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12 p-md-2">
                    <Label htmlFor="atraso" label="Dias atraso" />
                    <InputText id="atraso" name="atraso" disabled />
                </div>
                <div className="p-field p-col-12 p-md-3">
                    <Label htmlFor="descontos" label="Descontos" />
                    <div className="p-inputgroup">
                        <InputNumber inputId="descontos" name="descontos" suffix=" %" minFractionDigits={2} disabled />
                        <Button icon="pi pi-dollar" type="button" className="p-button-help" />
                    </div>
                </div>
                <div className="p-field p-col-12 p-md-3">
                    <Label htmlFor="multa" label="Valor da multa" />
                    <MakoInputMoeda id="multa" name="multa" disabled />
                </div>
                <div className="p-field p-col-12 p-md-3">
                    <Label htmlFor="juros" label="Valor dos juros" />
                    <MakoInputMoeda id="juros" name="juros" disabled />
                </div>
                <div className="p-field p-col-12 p-md-1">
                    <Button
                        icon={MAKO_ICONS.CONFIRMAR}
                        type="button"
                        className="p-button-success"
                        style={{ top: "1.9rem", width: "100%" }}
                    />
                </div>
            </div>
        </div>
    );
};
