import React, { useState, useEffect } from "react";

import { InputNumber } from "primereact/inputnumber";
import { InputText } from "primereact/inputtext";

import { MakoButton as Button } from "@/components/MakoButton";
import { MakoInputMoeda } from "@/components/MakoInputMoeda";
import { Label } from "@/components/Label";
import MakoListagem from "@/components/MakoListagem";

import { gerarParcelas } from "@/assets/util/calcFinanceiros";
import { MAKO_ICONS } from "@/assets/constants/constants_styles";
import { axiosPost } from "@/services/http";

import useLoadingLocal from "@/hooks/useLoadingLocal";
import useToast from "@/hooks/useToast";

export const Form = ({ recebimento = {}, successCallback = () => {}, cancelCallback = () => {} }) => {
    const [qtdFracionamentos, setQtdFracionamentos] = useState(2);
    const [fracionamentos, setFracionamentos] = useState([]);
    const [valorPrimeira, setValorPrimeira] = useState(0);
    const [value, setValue] = useState(null);

    const { showError, showSuccess, showWarning } = useToast();
    const [loading, showLoading, hideLoading] = useLoadingLocal();

    async function handleSubmit() {
        const _fracionamentos = fracionamentos.map((frac) => ({
            recebimento_id: value.id,
            documento: frac.documento,
            valor: frac.valor,
        }));

        showLoading();
        const resposta = await axiosPost("/financeiro/incluir-fracionamentos/", { fracionamentos: _fracionamentos });
        hideLoading();

        if (resposta.status === 200) {
            showSuccess({
                summary: "Sucesso",
                detail: "Fracionamento realizado com sucesso!",
                life: 3000,
            });
            successCallback();
        } else if (resposta.status === 400) {
            showWarning({
                summary: "Falha no fracionamento",
                detail: resposta.data.msg,
                life: 3000,
            });
        } else showError();
    }

    async function fracionarRecebimento() {
        if (valorPrimeira > 0) {
            let _fracionamentos = [
                {
                    documento: `${value.documento} FRA-1`,
                    vencimento: value.vencimento,
                    valor: valorPrimeira,
                },
            ];
            const fracionamentos = gerarParcelas(value.valor - valorPrimeira, qtdFracionamentos - 1);
            setFracionamentos([
                ..._fracionamentos,
                ...fracionamentos.map((fracionamento, index) => ({
                    documento: `${value.documento} FRA-${index + 2}`,
                    vencimento: value.vencimento,
                    valor: fracionamento,
                })),
            ]);
        } else {
            const fracionamentos = gerarParcelas(value.valor, qtdFracionamentos);
            setFracionamentos(
                fracionamentos.map((fracionamento, index) => ({
                    documento: `${value.documento} FRA-${index + 1}`,
                    vencimento: value.vencimento,
                    valor: fracionamento,
                }))
            );
        }
    }

    useEffect(() => {
        if (recebimento) {
            const { quantidade_parcelas, numero_parcela } = recebimento || {};
            setValue({
                ...recebimento,
                parcela: numero_parcela === 0 ? "ENTRADA" : `${numero_parcela}/${quantidade_parcelas}`,
            });
        }
    }, [recebimento]);

    const colunas = [
        { field: "documento", header: "Documento" },
        { field: "vencimento", header: "Vencimento", dateFormat: "dd/MM/yyyy" },
        { field: "valor", header: "Valor", money: true },
    ];

    return (
        <div>
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12 p-md-4">
                    <Label htmlFor="documento" label="Documento" />
                    <InputText id="documento" name="documento" disabled value={value?.documento || ""} />
                </div>
                <div className="p-field p-col-12 p-md-2">
                    <Label htmlFor="parcela" label="Parcela" />
                    <InputText id="parcela" name="parcela" disabled value={value?.parcela || ""} />
                </div>
                <div className="p-field p-col-12 p-md-2">
                    <Label htmlFor="valor" label="Valor" />
                    <MakoInputMoeda id="valor" name="valor" disabled valueMoeda={value?.valor} />
                </div>
                <div className="p-field p-col-12 p-md-2">
                    <Label htmlFor="qtd-fracionamentos" label="Quant. parcelas" obrigatorio />
                    <InputNumber
                        id="qtd-fracionamentos"
                        min={2}
                        value={qtdFracionamentos}
                        onValueChange={(e) => setQtdFracionamentos(e.value)}
                    />
                </div>
                <div className="p-field p-col-12 p-md-2">
                    <Label htmlFor="valor-primeira" label="Valor da primeira" obrigatorio />
                    <MakoInputMoeda
                        id="valor-primeira"
                        min={0}
                        max={value?.valor - 0.01}
                        valueMoeda={valorPrimeira}
                        tooltip="Deixe 0 para fracionar igualmente."
                        tooltipOptions={{ position: "bottom" }}
                        onChangeMoeda={(e) => setValorPrimeira(e.value)}
                    />
                </div>
            </div>
            <Button
                type="submit"
                label="Fracionar"
                icon={MAKO_ICONS.FRACIONAR}
                className="p-button-secondary p-mb-3 p-mr-2"
                onClick={fracionarRecebimento}
            />
            <Button
                type="button"
                label="Limpar"
                icon={MAKO_ICONS.LIMPAR_FORM}
                className="p-button-warning p-mb-3"
                onClick={() => setFracionamentos([])}
            />
            <MakoListagem colunas={colunas} dadosLocal={fracionamentos} configTabela={{ paginator: true }} />
            <Button
                loading={loading}
                icon={MAKO_ICONS.GRAVAR}
                label="Gravar"
                className="p-mt-3"
                onClick={handleSubmit}
            />
            <Button
                label="Cancelar"
                loading={loading}
                icon={MAKO_ICONS.CANCEL}
                className="p-button-danger p-ml-2"
                onClick={cancelCallback}
            />
        </div>
    );
};
