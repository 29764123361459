import React, { useCallback, useRef } from "react";

import { MakoActionsButtonsColumn } from "@/components/MakoActionsButtonsColumn";
import { Parametros as Listagem } from "./listagens/Parametros";
import { PageBase } from "@/components/PageBase";
import { Buttons } from "./buttons";

import useClearRefs from "@/hooks/useClearRefs";

export const ParametrosPage = () => {
    const listagemRef = useRef(null);

    useClearRefs(listagemRef);

    const buscarDados = useCallback(() => {
        listagemRef.current?.buscarDados();
    }, []);

    const actions = (rowData) => {
        return (
            <MakoActionsButtonsColumn>
                <Buttons.Visualizar onSuccessCallback={buscarDados} parametro={rowData} />
                <Buttons.Editar onSuccessCallback={buscarDados} parametro={rowData} />
                <Buttons.Remover
                    parametro={{ id: rowData.id, chave: rowData.chave.chave }}
                    onSuccessCallback={buscarDados}
                />
            </MakoActionsButtonsColumn>
        );
    };

    const painelEsquerdo = (
        <>
            <Buttons.Novo className="p-mr-2" onSuccessCallback={buscarDados} />
        </>
    );

    return (
        <PageBase>
            <Listagem
                listagemRef={listagemRef}
                listagemProps={{
                    painelEsquerdo,
                }}
                actions={actions}
            />
        </PageBase>
    );
};
