import React, { createContext, useCallback, useState } from "react";
import { axiosDelete, axiosGet, axiosPatch, axiosPost } from "@/services/http";
import useLoading from "@/hooks/useLoading";
import useToast from "@/hooks/useToast";
import * as pd from "@/assets/util/persistenciaDjango";

const ProdutoContext = createContext({});

export const ProdutoProvider = ({ children }) => {
    const [submit, setSubmit] = useState(false);
    const [submitSku, setSubmitSku] = useState(false);
    const [dadosBasicos, setDadosBasicos] = useState(null);
    const [descricaoPadrao, setDescricaoPadrao] = useState([]);
    const [customFields, setCustomFields] = useState(null);
    const { showLoading, hideLoading } = useLoading();
    const { showError, showWarning } = useToast();

    const handlePosicaoDescricao = useCallback(
        (posicao, texto) => {
            let lista = descricaoPadrao;
            lista[posicao] = texto;
            setDescricaoPadrao(lista);
        },
        [descricaoPadrao]
    );

    const handleDadosBasicos = useCallback(
        async (values) => {
            if (!values.id) {
                const { status, data } = await axiosPost("/produtos/itens/", {
                    ...values,
                    ncm: values.ncm.id,
                });
                setDadosBasicos({ ...data, cest: data.cest?.id || null, marca: data.marca?.id || null });
                return { status, data };
            } else {
                let diffProd = {};
                Object.entries(values).forEach(([k, v]) => {
                    if (v !== dadosBasicos[k]) {
                        if (k === "empresa") {
                            // não remover, necessário para gerar log backend
                            diffProd["empresa_anterior"] = dadosBasicos[k];
                        }
                        diffProd[k] = v;
                    }
                });
                if (Object.keys(diffProd).length > 0) {
                    if (diffProd.ncm) diffProd = { ...diffProd, ncm: diffProd.ncm?.id || diffProd.ncm?.value };
                    const { status, data } = await axiosPatch(`/produtos/itens/${values.id}/`, diffProd);
                    if (status === 200)
                        setDadosBasicos({ ...data, marca: data.marca?.id || null, cest: data.cest?.id || null });
                    return data;
                }
                return { status: 204, data: values };
            }
        },
        [dadosBasicos]
    );

    const handleValidar = async () => {
        showLoading();
        const { status, data } = await axiosPatch(`/produtos/itens/${dadosBasicos.id}/`, {
            status: "F",
        });
        hideLoading();

        if (status === 200) {
            setDadosBasicos({ ...data, marca: data.marca?.id || null, cest: data.cest?.id || null });
        } else {
            if (data?.cadastro_validado?.length > 0 && data?.cadastro_validado[0] === "False") {
                let msgErro = "";
                data.erros.forEach((erro) => {
                    msgErro = `${msgErro} ${msgErro !== "" ? "-" : ""} ${erro}`;
                });
                if (msgErro !== "")
                    msgErro = `O cadastro não pode ser validado, por favor verifique as informações. (${msgErro} )`;
                else msgErro = "O cadastro não pode ser validado, por favor verifique as informações.";

                showWarning({
                    summary: "Informações pendentes",
                    detail: msgErro,
                    life: 10000,
                });
            } else {
                showError({
                    summary: "Falha ao validar",
                    detail: "Desculpe, não foi possível validar o status do produto.",
                    life: 10000,
                });
            }
        }
    };

    const handleDetalhe = useCallback(
        async (values, op) => {
            if (op === pd.OP_CRUD_DJANGO.novo) {
                const { status, data } = await axiosPost("/produtos/sku/", {
                    ...values,
                    item: dadosBasicos.id,
                });
                if (status === 201) return { status, data };
                else {
                    showError({
                        summary: "Erro",
                        detail: "Desculpe, não foi possível inserir o produto.",
                        life: 3000,
                    });
                    return { status: 400, data: data };
                }
            } else if (op === pd.OP_CRUD_DJANGO.editar) {
                const { status, data } = await axiosPatch(`/produtos/sku/${values.id}/`, {
                    ...values,
                    item: values.item.id,
                });
                if (status === 200) return { status, data };
                else {
                    showError({
                        summary: "Erro",
                        detail: "Desculpe, não foi possível editar o produto.",
                        life: 3000,
                    });
                    return { status: 400, data: data };
                }
            } else if (op === pd.OP_CRUD_DJANGO.deletar) {
                const { status } = await axiosDelete(`/produtos/sku/${values.id}/`);
                if (status === 204) return { status: 204 };
                else {
                    showError({
                        summary: "Erro",
                        detail: "Desculpe, não foi possível remover o produto.",
                        life: 3000,
                    });
                    return { status: 400 };
                }
            }
        },
        [showError, dadosBasicos?.id]
    );

    const handleInfo = useCallback(
        async (url, values, op) => {
            if (op === pd.OP_CRUD_DJANGO.novo) {
                const { status, data } = await axiosPost(url, values);
                if (status === 201) return { status, data };
                else {
                    showError({
                        summary: "Erro",
                        detail: "Desculpe, não foi possível inserir.",
                        life: 3000,
                    });
                    return { status: 400, data: data };
                }
            } else if (op === pd.OP_CRUD_DJANGO.editar) {
                const { status, data } = await axiosPatch(`${url}${values.id}/`, values);
                if (status === 200) return { status, data };
                else {
                    showError({
                        summary: "Erro",
                        detail: "Desculpe, não foi possível editar.",
                        life: 3000,
                    });
                    return { status: 400, data: data };
                }
            }
        },
        [showError]
    );

    const handleUnidade = useCallback(
        async (values, op) => {
            if (op === pd.OP_CRUD_DJANGO.novo) {
                if (values.tipo_mov_und_medida === "A") {
                    return await Promise.all(
                        ["V", "C", "T"].map(async (tipo) => {
                            const { status, data } = await axiosPost("/produtos/unidades-medida-sku/", {
                                ...values,
                                tipo_mov_und_medida: tipo,
                            });
                            return { status, data };
                        })
                    );
                } else {
                    const { status, data } = await axiosPost("/produtos/unidades-medida-sku/", values);
                    if (status === 201) return { status, data };
                    else {
                        showError({
                            summary: "Erro",
                            detail: "Desculpe, não foi possível inserir.",
                            life: 3000,
                        });
                        return { status: 400, data: data };
                    }
                }
            } else if (op === pd.OP_CRUD_DJANGO.editar) {
                const { status, data } = await axiosPatch(`/produtos/unidades-medida-sku/${values.id}/`, values);
                if (status === 200) return { status, data };
                else {
                    showError({
                        summary: "Erro",
                        detail: "Desculpe, não foi possível editar.",
                        life: 3000,
                    });
                    return { status: 400, data: data };
                }
            }
        },
        [showError]
    );

    const handleCustomFields = useCallback(
        async (values) => {
            try {
                showLoading();
                const promisses = values?.map(async (custom, index) => {
                    const dataJson = {
                        ...custom.value,
                        field: custom.value.field.id ? custom.value.field.id : custom.value.field,
                        object_id: custom.value.object_id ? custom.value.object_id : dadosBasicos?.id,
                    };
                    const { status, data } = custom.value.id
                        ? await axiosPatch(`/custom-fields/values/${custom.value.id}/`, dataJson)
                        : await axiosPost("/custom-fields/values/", dataJson);
                    if (status === 201 || status === 200) {
                        values[index].value = data;
                    }
                    return { status, data };
                });
                await Promise.all(promisses);
            } finally {
                setCustomFields(values);
                hideLoading();
            }
        },
        [showLoading, hideLoading, dadosBasicos?.id]
    );

    const handlePreencherProduto = useCallback(
        async (idProduto) => {
            showLoading();
            const { status, data } = await axiosGet(`/produtos/itens/${idProduto}/`);
            hideLoading();

            if (status === 200)
                setDadosBasicos({ ...data, marca: data.marca?.id || null, cest: data.cest?.id || null });
        },
        [showLoading, hideLoading]
    );

    const verificarCadastroGenerico = useCallback(async () => {
        if (!dadosBasicos?.id) return false;
        showLoading();
        const { status, data } = await axiosGet(`/produtos/sku?limit=1&item__id=${dadosBasicos?.id}`);
        hideLoading();
        if (status === 200) return !data.results?.length;
        return false;
    }, [showLoading, hideLoading, dadosBasicos]);

    return (
        <ProdutoContext.Provider
            value={{
                submit,
                submitSku,
                dadosBasicos,
                descricaoPadrao,
                customFields,
                setDescricaoPadrao,
                setSubmit,
                setSubmitSku,
                handleDadosBasicos,
                handleDetalhe,
                handleValidar,
                handleInfo,
                handleUnidade,
                handlePreencherProduto,
                handlePosicaoDescricao,
                handleCustomFields,
                verificarCadastroGenerico,
            }}
        >
            {children}
        </ProdutoContext.Provider>
    );
};

export default ProdutoContext;
