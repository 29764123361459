import React, { useState, useRef } from "react";
import { Button } from "primereact/button";
import { Redirect } from "react-router-dom";
import { ConfirmDialog } from "primereact/confirmdialog";
import { Toast } from "primereact/toast";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import { axiosDelete } from "@/services/http";

import permissoes from "@/assets/constants/permissoes";
import MakoListagem from "@/components/MakoListagem";
import useLoading from "@/hooks/useLoading";

const url = "/plano-operacional/projetos/";

export const OperacionalProjetosPage = () => {
    const [redirecionar, setRedirecionar] = useState(null);
    const [deleteDialog, setDeleteDialog] = useState(false);
    const [projeto, setProjeto] = useState(null);
    const { showLoading, hideLoading } = useLoading();
    const toastRef = useRef(null);
    const listagemRef = useRef(null);

    const confirmarDelete = (projeto) => {
        setProjeto(projeto);
        setDeleteDialog(true);
    };

    const esconderDeleteDialog = () => {
        setDeleteDialog(false);
    };

    const deleteParametro = async () => {
        showLoading();
        const resposta = await axiosDelete(`${url}${projeto.id}`);
        hideLoading();

        if (resposta.status === 200 || resposta.status === 204) {
            toastRef.current.show({
                severity: "success",
                summary: "Sucesso",
                detail: "Projeto deletado com sucesso!",
                life: 3000,
            });

            listagemRef.current?.buscarDados();
        } else {
            toastRef.current.show({
                severity: "error",
                summary: "Erro",
                detail: "Desculpe, não conseguimos processar a sua requisição.",
                life: 3000,
            });
            hideLoading();
        }
        setDeleteDialog(false);
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <MakoControleAcesso
                    permissao={[permissoes.OPERACIONAL_PLANOOPERACIONAL_PROJETOS_EDITAR]}
                    componente={Button}
                    icon="pi pi-pencil"
                    className="p-button-rounded p-button-warning p-mr-2 p-mb-1"
                    tooltip="Alterar cadastro de projeto"
                    tooltipOptions={{ position: "left" }}
                    onClick={() =>
                        setRedirecionar({
                            pathname: "/operacional/plano-operacional/projetos/form",

                            state: { projeto: rowData },
                        })
                    }
                />
                <MakoControleAcesso
                    permissao={[permissoes.OPERACIONAL_PLANOOPERACIONAL_PROJETOS_EXCLUIR]}
                    componente={Button}
                    icon="pi pi-trash"
                    className="p-button-rounded p-button-danger p-mr-2 p-mb-1"
                    onClick={() => confirmarDelete(rowData)}
                    tooltip="Deletar projeto"
                    tooltipOptions={{ position: "left" }}
                />
            </div>
        );
    };

    const cabecalhoTabela = (
        <>
            <MakoControleAcesso
                permissao={[permissoes.OPERACIONAL_PLANOOPERACIONAL_PROJETOS_INCLUIR]}
                componente={Button}
                label="Novo"
                icon="pi pi-plus"
                className="p-button-success p-mr-2"
                onClick={() => setRedirecionar("/operacional/plano-operacional/projetos/form")}
            />
        </>
    );

    const colunas = [
        { field: "id", header: "Código", style: { width: "10%" } },
        { field: "nome", header: "Nome" },
        { field: "descricao", header: "Descrição" },
        { field: "responsavel.nome", header: "Responsável" },
        { field: "action", header: "Ações", action: (e) => actionBodyTemplate(e), style: { width: "12%" } },
    ];

    return redirecionar ? (
        <Redirect to={redirecionar} />
    ) : (
        <div className="p-grid">
            <Toast ref={toastRef} />
            <div className="p-col-12">
                <div className="card">
                    <MakoListagem
                        ref={listagemRef}
                        titulo="Projetos"
                        colunas={colunas}
                        painelEsquerdo={cabecalhoTabela}
                        urlPesquisa={url}
                        responsiva
                        filtarPorEmpresa
                        configTabela={{
                            paginator: true,
                            lazy: true,
                        }}
                    />
                    <ConfirmDialog
                        visible={deleteDialog}
                        onHide={esconderDeleteDialog}
                        header="Confirmação"
                        message={
                            projeto && (
                                <span>
                                    {"Deseja realmente excluir o projeto "}
                                    <b>{projeto.nome}</b>?
                                </span>
                            )
                        }
                        icon="pi pi-info-circle p-mr-3"
                        accept={deleteParametro}
                        acceptLabel="Sim"
                        acceptClassName="p-button-danger"
                        reject={esconderDeleteDialog}
                        rejectLabel="Não"
                    />
                </div>
            </div>
        </div>
    );
};
