import React, { useRef, useState } from "react";

import { Toast } from "primereact/toast";
import { TabView, TabPanel } from "primereact/tabview";
import { Button } from "primereact/button";
import { useHistory } from "react-router-dom";
import { PageBase } from "@/components/PageBase";
import { ParametroServicoForm } from "./formParametros";
import { ServicosForm } from "./formServicos";

export const TabOperacoesServico = (props) => {
    const [activeTab, setActiveTab] = useState(0);
    const toastRef = useRef(null);
    const history = useHistory();

    const alterarTab = (aba) => {
        if (aba === -1) {
            if (activeTab > 0) {
                setActiveTab(activeTab - 1);
            }
        } else {
            if (activeTab < 3) {
                setActiveTab(activeTab + 1);
            }
        }
    };

    return (
        <PageBase>
            <Toast ref={toastRef} />
            <h5>Cadastro de Operação Fiscal de Serviço</h5>
            <TabView className="tabview-custom" activeIndex={activeTab} onTabChange={(e) => setActiveTab(e.index)}>
                <TabPanel header="Parâmetros gerais" leftIcon="pi pi-book">
                    <ParametroServicoForm opFiscal={props.location?.opFiscal || null} />
                </TabPanel>
                <TabPanel header="Serviços" leftIcon="pi pi-briefcase" disabled={!props.location?.opFiscal}>
                    <ServicosForm opFiscal={props.location?.opFiscal} />
                </TabPanel>
            </TabView>
            <div className="p-grid p-fluid" style={{ paddingTop: "2%" }}>
                {activeTab > 0 ? (
                    <div className="p-col-6 p-md-3">
                        <Button
                            label="Anterior"
                            icon="pi pi-angle-left"
                            className="p-button-info"
                            onClick={() => alterarTab(-1)}
                        />
                    </div>
                ) : null}
                {activeTab < 1 ? (
                    <div className="p-col-6 p-md-3">
                        <Button
                            label="Próximo"
                            icon="pi pi-angle-right"
                            iconPos="right"
                            className="p-button-info"
                            onClick={() => alterarTab(1)}
                        />
                    </div>
                ) : null}
                {activeTab !== 3 ? (
                    <div className="p-col-6 p-md-3">
                        <Button
                            label="Voltar"
                            className="p-button-danger"
                            onClick={() => history.push("/fiscal/cadastros/operacoes-fiscais-servicos")}
                        />
                    </div>
                ) : null}
            </div>
        </PageBase>
    );
};
