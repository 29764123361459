import React, { useState } from "react";
import classNames from "classnames";
import { useHistory } from "react-router-dom";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { useFormik } from "formik";
import * as Yup from "yup";

import { PageBase } from "@/components/PageBase";
import { CamposObrigatorios } from "@/components/CamposObrigatorios";
import { axiosPatch, axiosPost } from "@/services/http";
import useLoading from "@/hooks/useLoading";
import useToast from "@/hooks/useToast";
import { MakoCalendarTime } from "@/components/MakoCalendarTime";
import { Dropdown } from "@/components/Dropdown";
import { MakoAutoComplete } from "@/components/MakoAutoComplete/index";
import { Divider } from "primereact/divider";
import { MakoInputMoeda } from "@/components/MakoInputMoeda";
import { InputNumber } from "primereact/inputnumber";
import {
    TIPO_FINALIDADE_CTE_CHOICE,
    TIPO_INDICADOR_EMITENTE_CHOICE,
    TIPO_OPERACAO_NF_CHOICE,
    TIPO_SERVICO_CTE_CHOICE,
    TIPO_SITUACAO_DOCUMENTO_CHOICE,
} from "@/assets/constants/constants";
import { Dialog } from "primereact/dialog";

export const NotaTransporteForm = (props) => {
    const [exibirNotas, setExibirNotas] = useState(false);
    const [notaRef, setNotaRef] = useState(null);
    const { showLoading, hideLoading } = useLoading();
    const history = useHistory();
    const { showSuccess, showError } = useToast();

    const { setFieldValue, resetForm, setErrors, ...formik } = useFormik({
        initialValues: props.location.state?.cte || {
            tipo_operacao: 0,
            indicador_emitente: 0,
            finalidade_emissao: 1,
            tipo_servico: 1,
            situacao_documento: "00",
            serie: "1",
            numero: "1",
            chave_cte: "",
            protocolo: "",
            data_emissao: new Date(),
            cfop: null,
            municipio_emissao: null,
            municipio_coleta: null,
            municipio_entrega: null,
            remetente: null,
            destinatario: null,
            tomador: null,
            notas_fiscais: [],
            rntrc: null,
            data_prestacao: null,
            cst_icms: null,
            base_icms: 0,
            aliquota_icms: 0,
            reducao_base_icms: 0,
            valor_icms: 0,
            valor_receber: 0,
            valor_carga: 0,
            valor_desconto: 0,
            valor_servico: 0,
            valor_documento: 0,
        },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            values = { ...values, numero: parseInt(values.numero), serie: parseInt(values.serie) };
            const formSchema = Yup.object().shape({
                numero: Yup.number().min(1, "Informe um número maior que 0.").required("O campo é obrigatório."),
                serie: Yup.number().min(1, "Informe um número maior que 0.").required("O campo é obrigatório."),
                chave_cte: Yup.string().required("O campo é obrigatório."),
                tipo_operacao: Yup.number().required("O campo é obrigatório."),
                indicador_emitente: Yup.number().required("O campo é obrigatório."),
                finalidade_emissao: Yup.number().required("O campo é obrigatório."),
                tipo_servico: Yup.number().required("O campo é obrigatório."),
                situacao_documento: Yup.string().required("O campo é obrigatório."),
                data_emissao: Yup.date().required("O campo é obrigatório."),
                cfop: Yup.number().required("O campo é obrigatório."),
                municipio_emissao: Yup.object().required("O campo é obrigatório."),
                municipio_coleta: Yup.object().required("O campo é obrigatório."),
                municipio_entrega: Yup.object().required("O campo é obrigatório."),
                remetente: Yup.object().required("O campo é obrigatório."),
                destinatario: Yup.object().required("O campo é obrigatório."),
                cst_icms: Yup.number().required("O campo é obrigatório."),
                base_icms: Yup.number().required("O campo é obrigatório."),
                aliquota_icms: Yup.number().required("O campo é obrigatório."),
                reducao_base_icms: Yup.number().required("O campo é obrigatório."),
                valor_icms: Yup.number().required("O campo é obrigatório."),
                valor_receber: Yup.number().required("O campo é obrigatório."),
                valor_carga: Yup.number().required("O campo é obrigatório."),
                valor_desconto: Yup.number().required("O campo é obrigatório."),
                valor_servico: Yup.number().required("O campo é obrigatório."),
                valor_documento: Yup.number().required("O campo é obrigatório."),
            });

            await formSchema.validate(values, {
                abortEarly: false,
            });

            if (!values.id) {
                showLoading();
                const { status, data } = await axiosPost(`/fiscal/notas-conhecimento-transporte/`, {
                    ...values,
                    notas_fiscais: values.notas_fiscais.map((e) => e.id),
                    remetente: values.remetente?.id,
                    destinatario: values.destinatario?.id,
                    tomador: values.tomador?.id || null,
                    municipio_emissao: values.municipio_emissao.id,
                    municipio_coleta: values.municipio_coleta.id,
                    municipio_entrega: values.municipio_entrega.id,
                });
                hideLoading();

                if (status === 201) {
                    showSuccess({
                        summary: "Sucesso",
                        detail: "Nota de conhecimento de transporte cadastrado com sucesso!",
                        life: 1500,
                    });
                    voltarParaListagem();
                } else {
                    let errorMessages = {};
                    const val = Object.values(data);
                    Object.keys(data).forEach((err, index) => {
                        errorMessages[err] = val[index];
                    });
                    setErrors(errorMessages);
                    showError({
                        summary: "Erro",
                        detail: "Desculpe, não conseguimos processar a sua requisição.",
                        life: 3000,
                    });
                }
            } else {
                showLoading();
                const { status, data } = await axiosPatch(`/fiscal/notas-conhecimento-transporte/${values.id}/`, {
                    ...values,
                    notas_fiscais: values.notas_fiscais.map((e) => e.id),
                    remetente: values.remetente?.id,
                    destinatario: values.destinatario?.id,
                    tomador: values.tomador?.id || null,
                    municipio_emissao: values.municipio_emissao.id,
                    municipio_coleta: values.municipio_coleta.id,
                    municipio_entrega: values.municipio_entrega.id,
                });
                hideLoading();

                if (status === 200) {
                    showSuccess({
                        summary: "Sucesso",
                        detail: "Nota de conhecimento de transporte alterado com sucesso!",
                        life: 1500,
                    });
                    voltarParaListagem();
                } else {
                    showError({
                        summary: "Erro",
                        detail: data.msg || "Desculpe, não conseguimos processar a sua requisição.",
                        life: 3000,
                    });
                }
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                setErrors(errorMessages);
            }
        }
    }

    const autoCompleteCidadeTemplate = (valor) => {
        return `${valor.codigo} - ${valor.nome}`;
    };

    const voltarParaListagem = () => {
        resetForm();
        history.push("/fiscal/nota-transporte/");
    };

    const autoCompleteDestTemplate = (valor) => {
        return `${valor.nome} - ${valor.identificacao}`;
    };

    const cstTemplate = (cst) => {
        if (cst) return `${cst.codigo} - ${cst.descricao}`;
    };

    const insereNotaRef = () => {
        let lista = [];
        if (formik.values.notas_fiscais?.length > 0) lista = formik.values.notas_fiscais;
        lista.push(notaRef);
        setFieldValue("notas_fiscais", lista);
        setNotaRef(null);
    };

    return (
        <PageBase>
            <h5>{!!formik.values.id ? "Incluir CT-e" : "Editar CT-e"}</h5>
            <form onSubmit={formik.handleSubmit}>
                <Divider align="center">Dados básicos</Divider>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="numero">Número *</label>
                        <InputText
                            id="numero"
                            name="numero"
                            autoComplete="off"
                            keyfilter="num"
                            value={formik.values.numero}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.numero })}
                        />
                        {formik.errors.numero && <small className="p-error">{formik.errors.numero}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="serie">Série *</label>
                        <InputText
                            id="serie"
                            name="serie"
                            autoComplete="off"
                            keyfilter="num"
                            value={formik.values.serie}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.serie })}
                        />
                        {formik.errors.serie && <small className="p-error">{formik.errors.serie}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-5">
                        <label htmlFor="chave_cte">Chave *</label>
                        <InputText
                            id="chave_cte"
                            name="chave_cte"
                            autoComplete="off"
                            value={formik.values.chave_cte}
                            keyfilter="num"
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.chave_cte })}
                        />
                        {formik.errors.chave_cte && <small className="p-error">{formik.errors.chave_cte}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="data_emissao">Data emissão *</label>
                        <MakoCalendarTime
                            id="data_emissao"
                            name="data_emissao"
                            valueCalendar={formik.values.data_emissao}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.data_emissao })}
                        />
                        {formik.errors.data_emissao && <small className="p-error">{formik.errors.data_emissao}</small>}
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="tipo_operacao">Tipo operação *</label>
                        <Dropdown
                            id="tipo_operacao"
                            name="tipo_operacao"
                            options={TIPO_OPERACAO_NF_CHOICE}
                            optionLabel="label"
                            optionValue="id"
                            placeholder="Selecione..."
                            showClear={false}
                            value={formik.values.tipo_operacao}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.tipo_operacao })}
                        />
                        {formik.errors.tipo_operacao && (
                            <small className="p-error">{formik.errors.tipo_operacao}</small>
                        )}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="indicador_emitente">Indicador emitente *</label>
                        <Dropdown
                            id="indicador_emitente"
                            name="indicador_emitente"
                            options={TIPO_INDICADOR_EMITENTE_CHOICE}
                            optionLabel="label"
                            optionValue="value"
                            placeholder="Selecione..."
                            showClear={false}
                            value={formik.values.indicador_emitente}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.indicador_emitente })}
                        />
                        {formik.errors.indicador_emitente && (
                            <small className="p-error">{formik.errors.indicador_emitente}</small>
                        )}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="finalidade_emissao">Finalidade *</label>
                        <Dropdown
                            id="finalidade_emissao"
                            name="finalidade_emissao"
                            options={TIPO_FINALIDADE_CTE_CHOICE}
                            optionLabel="label"
                            optionValue="value"
                            placeholder="Selecione..."
                            showClear={false}
                            value={formik.values.finalidade_emissao}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.finalidade_emissao })}
                        />
                        {formik.errors.finalidade_emissao && (
                            <small className="p-error">{formik.errors.finalidade_emissao}</small>
                        )}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="tipo_servico">Tipo serviço *</label>
                        <Dropdown
                            id="tipo_servico"
                            name="tipo_servico"
                            options={TIPO_SERVICO_CTE_CHOICE}
                            optionLabel="label"
                            optionValue="value"
                            placeholder="Selecione..."
                            showClear={false}
                            value={formik.values.tipo_servico}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.tipo_servico })}
                        />
                        {formik.errors.tipo_servico && <small className="p-error">{formik.errors.tipo_servico}</small>}
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-6">
                        <label htmlFor="situacao_documento">Situação documento *</label>
                        <Dropdown
                            id="situacao_documento"
                            name="situacao_documento"
                            options={TIPO_SITUACAO_DOCUMENTO_CHOICE}
                            optionLabel="label"
                            optionValue="value"
                            placeholder="Selecione..."
                            showClear={false}
                            value={formik.values.situacao_documento}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.situacao_documento })}
                        />
                        {formik.errors.situacao_documento && (
                            <small className="p-error">{formik.errors.situacao_documento}</small>
                        )}
                    </div>
                    <div className="p-field p-col-12 p-md-6">
                        <label htmlFor="remetente">Remetente *</label>
                        <MakoAutoComplete
                            id="remetente"
                            name="remetente"
                            placeholder="Busque pelo nome ou cpf/cnpj ... (min 4 caractéres)"
                            minCaracteresBusca={4}
                            delay={1000}
                            value={formik.values.remetente}
                            onChange={formik.handleChange}
                            itemTemplate={autoCompleteDestTemplate}
                            field="nome"
                            urlSearch={"/pessoas/perfis?query={id, nome, identificacao}&search="}
                        />
                        {formik.errors.remetente && <small className="p-error">{formik.errors.remetente}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-6">
                        <label htmlFor="destinatario">Destinatario *</label>
                        <MakoAutoComplete
                            id="destinatario"
                            name="destinatario"
                            placeholder="Busque pelo nome ou cpf/cnpj ... (min 4 caractéres)"
                            minCaracteresBusca={4}
                            delay={1000}
                            value={formik.values.destinatario}
                            onChange={formik.handleChange}
                            itemTemplate={autoCompleteDestTemplate}
                            field="nome"
                            urlSearch={"/pessoas/perfis?query={id, nome, identificacao}&search="}
                        />
                        {formik.errors.destinatario && <small className="p-error">{formik.errors.destinatario}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-6">
                        <label htmlFor="tomador">Tomador</label>
                        <MakoAutoComplete
                            id="tomador"
                            name="tomador"
                            placeholder="Busque pelo nome ou cpf/cnpj ... (min 4 caractéres)"
                            minCaracteresBusca={4}
                            delay={1000}
                            value={formik.values.tomador}
                            onChange={formik.handleChange}
                            itemTemplate={autoCompleteDestTemplate}
                            field="nome"
                            urlSearch={"/pessoas/perfis?query={id, nome, identificacao}&search="}
                        />
                        {formik.errors.tomador && <small className="p-error">{formik.errors.tomador}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="data_prestacao">Data prestação *</label>
                        <MakoCalendarTime
                            id="data_prestacao"
                            name="data_prestacao"
                            valueCalendar={formik.values.data_prestacao}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.data_prestacao })}
                        />
                        {formik.errors.data_prestacao && (
                            <small className="p-error">{formik.errors.data_prestacao}</small>
                        )}
                    </div>
                </div>
                <Divider align="center">Local</Divider>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-4">
                        <label htmlFor="municipio_emissao">Município emissão *</label>
                        <MakoAutoComplete
                            id="municipio_emissao"
                            name="municipio_emissao"
                            placeholder="Busque pelo nome ou código do município ... (min 4 caracteres)"
                            minCaracteresBusca={4}
                            value={formik.values.municipio_emissao}
                            onChange={formik.handleChange}
                            itemTemplate={autoCompleteCidadeTemplate}
                            field="nome"
                            urlSearch={"/pessoas/cidades?query={id, codigo, nome}&search="}
                            className={classNames({ "p-invalid": formik.errors.municipio_emissao })}
                        />
                        {formik.errors.municipio_emissao && (
                            <small className="p-error">{formik.errors.municipio_emissao}</small>
                        )}
                    </div>
                    <div className="p-field p-col-12 p-md-4">
                        <label htmlFor="municipio_coleta">Município coleta *</label>
                        <MakoAutoComplete
                            id="municipio_coleta"
                            name="municipio_coleta"
                            placeholder="Busque pelo nome ou código do município ... (min 4 caracteres)"
                            minCaracteresBusca={4}
                            value={formik.values.municipio_coleta}
                            onChange={formik.handleChange}
                            itemTemplate={autoCompleteCidadeTemplate}
                            field="nome"
                            urlSearch={"/pessoas/cidades?query={id, codigo, nome}&search="}
                            className={classNames({ "p-invalid": formik.errors.municipio_coleta })}
                        />
                        {formik.errors.municipio_coleta && (
                            <small className="p-error">{formik.errors.municipio_coleta}</small>
                        )}
                    </div>
                    <div className="p-field p-col-12 p-md-4">
                        <label htmlFor="municipio_entrega">Município entrega *</label>
                        <MakoAutoComplete
                            id="municipio_entrega"
                            name="municipio_entrega"
                            placeholder="Busque pelo nome ou código do município ... (min 4 caracteres)"
                            minCaracteresBusca={4}
                            value={formik.values.municipio_entrega}
                            onChange={formik.handleChange}
                            itemTemplate={autoCompleteCidadeTemplate}
                            field="nome"
                            urlSearch={"/pessoas/cidades?query={id, codigo, nome}&search="}
                            className={classNames({ "p-invalid": formik.errors.municipio_entrega })}
                        />
                        {formik.errors.municipio_entrega && (
                            <small className="p-error">{formik.errors.municipio_entrega}</small>
                        )}
                    </div>
                </div>
                <Divider align="center">Dados fiscais</Divider>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-12">
                        <label htmlFor="notas_fiscais">Nota(s) referência</label>
                        <div className="p-inputgroup">
                            <MakoAutoComplete
                                id="notas_fiscais"
                                name="notas_fiscais"
                                placeholder="Busque pela chave... (min 4 caracteres)"
                                minCaracteresBusca={4}
                                value={notaRef}
                                onChange={(e) => setNotaRef(e.value)}
                                field="chave_nf"
                                urlSearch={
                                    "/fiscal/notas-fiscais?query={id, chave_nf, numero, serie}&limit=30&chave_nf__icontains=False&chave_nf__icontains="
                                }
                            />
                            <Button
                                type="button"
                                icon="pi pi-plus"
                                className="p-button-success"
                                disabled={!notaRef || typeof notaRef !== "object"}
                                onClick={() => insereNotaRef()}
                            />
                            <Button
                                type="button"
                                icon="pi pi-trash"
                                className="p-button-warning p-ml-1"
                                disabled={!(formik.values.notas_fiscais?.length > 0)}
                                tooltip="Limpar notas"
                                tooltipOptions={{ position: "left" }}
                                onClick={() => setFieldValue("notas_fiscais", [])}
                            />
                            <Button
                                type="button"
                                icon="pi pi-book"
                                className="p-button-info p-ml-1"
                                style={{ width: "50px" }}
                                badge={`${formik.values.notas_fiscais?.length || 0}`}
                                tooltip={
                                    formik.values.notas_fiscais?.length > 0
                                        ? "Exibir notas referenciadas"
                                        : "Não existem notas referenciadas"
                                }
                                tooltipOptions={{ position: "left" }}
                                onClick={() => setExibirNotas(true)}
                            />
                        </div>
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-6">
                        <label htmlFor="cfop">Cfop *</label>
                        <Dropdown
                            id="cfop"
                            name="cfop"
                            placeholder="Selecione um cfop"
                            url="/fiscal/cfop?limit=10000"
                            filter
                            filterBy="codigo,descricao"
                            optionValue="id"
                            optionLabel="descricao"
                            showClear={false}
                            value={formik.values.cfop}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.cfop })}
                        />
                        {formik.errors.cfop && <small className="p-error">{formik.errors.cfop}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-6">
                        <label htmlFor="cst_icms">CST ICMS *</label>
                        <Dropdown
                            id="cst_icms"
                            name="cst_icms"
                            placeholder="Selecione..."
                            url="/fiscal/cst-icms"
                            filter
                            filterBy="codigo,descricao"
                            optionValue="codigo"
                            optionLabel="descricao"
                            itemTemplate={cstTemplate}
                            showClear={false}
                            value={formik.values.cst_icms}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.cst_icms })}
                        />
                        {formik.errors.cst_icms && <small className="p-error">{formik.errors.cst_icms}</small>}
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="base_icms">Base ICMS *</label>
                        <MakoInputMoeda
                            id="base_icms"
                            name="base_icms"
                            valueMoeda={formik.values.base_icms}
                            onChangeMoeda={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.base_icms })}
                        />
                        {formik.errors.base_icms && <small className="p-error">{formik.errors.base_icms}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="aliquota_icms">% Alíquota ICMS *</label>
                        <InputNumber
                            id="aliquota_icms"
                            name="aliquota_icms"
                            mode="decimal"
                            prefix={"% "}
                            minFractionDigits={2}
                            maxFractionDigits={4}
                            min={0}
                            max={100}
                            value={formik.values.aliquota_icms}
                            onValueChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.aliquota_icms })}
                        />
                        {formik.errors.aliquota_icms && (
                            <small className="p-error">{formik.errors.aliquota_icms}</small>
                        )}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="valor_icms">Valor ICMS *</label>
                        <MakoInputMoeda
                            id="valor_icms"
                            name="valor_icms"
                            valueMoeda={formik.values.valor_icms}
                            onChangeMoeda={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.valor_icms })}
                        />
                        {formik.errors.valor_icms && <small className="p-error">{formik.errors.valor_icms}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="reducao_base_icms">Red. BC ICMS *</label>
                        <MakoInputMoeda
                            id="reducao_base_icms"
                            name="reducao_base_icms"
                            valueMoeda={formik.values.reducao_base_icms}
                            onChangeMoeda={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.reducao_base_icms })}
                        />
                        {formik.errors.reducao_base_icms && (
                            <small className="p-error">{formik.errors.reducao_base_icms}</small>
                        )}
                    </div>
                </div>
                <Divider align="center">Totais</Divider>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="valor_receber">Valor a receber *</label>
                        <MakoInputMoeda
                            id="valor_receber"
                            name="valor_receber"
                            valueMoeda={formik.values.valor_receber}
                            onChangeMoeda={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.valor_receber })}
                        />
                        {formik.errors.valor_receber && (
                            <small className="p-error">{formik.errors.valor_receber}</small>
                        )}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="valor_carga">Valor da carga *</label>
                        <MakoInputMoeda
                            id="valor_carga"
                            name="valor_carga"
                            valueMoeda={formik.values.valor_carga}
                            onChangeMoeda={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.valor_carga })}
                        />
                        {formik.errors.valor_carga && <small className="p-error">{formik.errors.valor_carga}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="valor_desconto">Valor desconto *</label>
                        <MakoInputMoeda
                            id="valor_desconto"
                            name="valor_desconto"
                            valueMoeda={formik.values.valor_desconto}
                            onChangeMoeda={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.valor_desconto })}
                        />
                        {formik.errors.valor_desconto && (
                            <small className="p-error">{formik.errors.valor_desconto}</small>
                        )}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="valor_servico">Valor serviço *</label>
                        <MakoInputMoeda
                            id="valor_servico"
                            name="valor_servico"
                            valueMoeda={formik.values.valor_servico}
                            onChangeMoeda={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.valor_servico })}
                        />
                        {formik.errors.valor_servico && (
                            <small className="p-error">{formik.errors.valor_servico}</small>
                        )}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="valor_documento">Valor documento *</label>
                        <MakoInputMoeda
                            id="valor_documento"
                            name="valor_documento"
                            valueMoeda={formik.values.valor_documento}
                            onChangeMoeda={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.valor_documento })}
                        />
                        {formik.errors.valor_documento && (
                            <small className="p-error">{formik.errors.valor_documento}</small>
                        )}
                    </div>
                </div>
                <CamposObrigatorios />
                <div className="p-grid p-col-12 p-md-6">
                    <Button type="submit" label="Gravar" icon="pi pi-save" className="p-button-info p-mr-2" />
                    <Button
                        type="reset"
                        label="Voltar"
                        icon="pi pi-angle-double-left"
                        className="p-button-danger"
                        onClick={voltarParaListagem}
                    />
                </div>
            </form>
            <Dialog
                header="Nota(s) Referênciadas"
                visible={exibirNotas}
                breakpoints={{ "960px": "80vw" }}
                style={{ width: "30vw" }}
                onHide={() => setExibirNotas(false)}
            >
                {formik.values.notas_fiscais?.length > 0
                    ? formik.values.notas_fiscais.map(({ chave_nf }) => {
                          return (
                              <div key={chave_nf}>
                                  <card className="p-mb-1">
                                      <b>{chave_nf}</b>
                                  </card>
                              </div>
                          );
                      })
                    : null}
            </Dialog>
        </PageBase>
    );
};
