import React from "react";

import { Form } from "./form";
import { Listagem } from "./listagem";

export const Recebimentos = () => {
    return (
        <>
            <Form />
            <div className="p-mt-3">
                <Listagem />
            </div>
        </>
    );
};
