import { useCallback } from "react";

import { axiosGet } from "@/services/http";

const useGetNumeroParcela = (devolucaoId) => {
    const buscarNumeroParcela = useCallback(async () => {
        const { status, data } = await axiosGet(
            `compras/parcelas-devolucao-fornecedor-com-entrada/${devolucaoId}/numero-parcela/`
        );
        return status === 200 ? data.numero : 1;
    }, [devolucaoId]);

    return buscarNumeroParcela;
};

export default useGetNumeroParcela;
