import React, { useCallback, useEffect, useImperativeHandle, useRef, useState, forwardRef, useMemo } from "react";
import { Dialog } from "primereact/dialog";
import { Panel } from "primereact/panel";
import { Chip } from "primereact/chip";
import { Steps } from "primereact/steps";

import { BlockUI } from "@/components/BlockUI";
import { Dropdown } from "@/components/Dropdown";
import { axiosPatch, axiosPut } from "@/services/http";
import { Button } from "primereact/button";
import { parseMoeda } from "@/assets/util/util";
import useToast from "@/hooks/useToast";
import useFormatCNPJCPF from "@/hooks/useFomatCNPJCPF";

const Modal = ({ onSuccess }, ref) => {
    const [visible, setVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const [edicaoBloqueada, setEdicaoBloqueada] = useState(false);
    const [fluxosVenda, setFluxosVenda] = useState([]);
    const [fluxoVendaSelecionado, setFluxoVendaSelecionado] = useState(null);
    const [sequencia, setSequencia] = useState([]);
    const [activeIndex, setActiveIndex] = useState(0);
    const vendaRef = useRef(null);
    const { showSuccess, showWarning, showError } = useToast();
    const [formatarDocumento] = useFormatCNPJCPF();

    const alterarEstagioVenda = useCallback(
        async (novaSequenciaId) => {
            const url = `/vendas/alterar-estagio-venda/${vendaRef.current?.id}/${novaSequenciaId}/`;
            setLoading(true);
            const { status, data } = await axiosPut(url);
            setLoading(false);
            if (status === 200) {
                showSuccess({
                    summary: "Sucesso!",
                    detail: data?.msg || "Estágio processado com sucesso.",
                    life: 2000,
                });
                if (typeof onSuccess === "function") onSuccess();
            } else if (status !== 500) {
                showWarning({
                    summary: "Aviso",
                    detail: data?.msg || "Não foi possível processar o estágio de venda.",
                    life: 3000,
                });
            } else {
                showError({
                    summary: "Erro :(",
                    detail: "Desculpe, não foi possível processar o estágio de venda.",
                    life: 3000,
                });
            }
        },
        [onSuccess, showSuccess, showWarning, showError]
    );

    const definirSequencia = useCallback(() => {
        const estagio = fluxosVenda.find((el) => el.id === fluxoVendaSelecionado);
        const itens = estagio?.sequenciaestagiovenda_set
            .filter((seq) => seq.tipo_estagio_venda.ativo)
            .map((seq) => ({
                id: seq.tipo_estagio_venda.id,
                label: seq.tipo_estagio_venda.descricao,
                command: () => alterarEstagioVenda(seq.id),
            }));
        setSequencia(itens);
    }, [fluxosVenda, fluxoVendaSelecionado, alterarEstagioVenda]);

    useEffect(() => {
        definirSequencia();
    }, [definirSequencia]);

    function formatarCliente(cliente) {
        if (!cliente) return "";
        let identificacao = "";
        if (cliente.tipo_pessoa === "PF") identificacao = "CPF";
        if (cliente.tipo_pessoa === "PJ") identificacao = "CNPJ";
        const doc = formatarDocumento(cliente.identificacao);
        return `${cliente.nome} - ${identificacao} ${doc}`;
    }

    function formatarUsuario(perfil) {
        if (!perfil) return "";
        const { id, nome } = perfil;
        return `${nome} (${id})`;
    }

    function definirIcon(situacao) {
        let icon = "pi pi-check-square";
        let style = { fontSize: "1.7rem", color: "green" };
        if (situacao === "P") {
            icon = "fas fa-hourglass-half";
            style = { fontSize: "1.7rem", color: "#FBC02D" };
        }
        if (situacao === "R") {
            icon = "fas fa-hand-paper";
            style = { fontSize: "1.7rem", color: "#D32F2F" };
        }
        return <i className={icon} style={style} />;
    }

    function formatarSituacao(situacao, frase) {
        const prep = situacao !== "N" ? "com" : "sem";
        return (
            <>
                {definirIcon(situacao)}
                {"  "}
                <span>{frase.replace("{preposicao}", prep)}</span>
            </>
        );
    }

    function formatarSituacaoPlanoRecebimento(situacao, plano) {
        const prefixo = situacao !== "N" ? "requer" : "não requer";
        return (
            <>
                {definirIcon(situacao)}
                {"  "}
                <span>{`Plano "${plano?.descricao}" (${prefixo} crediário)`}</span>
            </>
        );
    }

    const removeEstagioVenda = useCallback(async () => {
        setLoading(true);
        const { status } = await axiosPatch(`/vendas/vendas/${vendaRef.current?.id}/`, {
            estagio_venda: null,
            sequencia_estagio_venda: null,
        });
        setLoading(false);
        if (status === 200) {
            if (typeof onSuccess === "function") onSuccess();
            setEdicaoBloqueada(false);
            setFluxoVendaSelecionado(null);
            setSequencia([]);
            showSuccess({
                summary: "Sucesso!",
                detail: "Fluxo de venda removido com sucesso.",
                life: 3000,
            });
        } else {
            showError({
                summary: "Erro :(",
                detail: "Desculpe, não foi possível processar o estágio de venda.",
                life: 3000,
            });
        }
    }, [showSuccess, showError, onSuccess]);

    const bloqueiaRemocao = useMemo(() => {
        const estagio = fluxosVenda.find((el) => el.id === fluxoVendaSelecionado);
        if (!estagio) return true;
        const { baixa_estoque, gera_comissao, gera_documento_fiscal, gera_financeiro, reserva_estoque } =
            estagio?.sequenciaestagiovenda_set[activeIndex].tipo_estagio_venda;
        return baixa_estoque || gera_comissao || gera_documento_fiscal || gera_financeiro || reserva_estoque;
    }, [fluxosVenda, activeIndex, fluxoVendaSelecionado]);

    const abrirModal = (venda) => {
        const { sequencia_estagio_venda } = venda;
        const { estagio_venda: estagioId, sequencia: sequenciaAtual } = sequencia_estagio_venda || {};
        setEdicaoBloqueada(!!estagioId);
        if (estagioId) setFluxoVendaSelecionado(estagioId);
        if (sequenciaAtual) setActiveIndex(sequenciaAtual ? sequenciaAtual - 1 : -1);
        vendaRef.current = venda;
        setVisible(true);
    };

    useImperativeHandle(ref, () => ({ abrirModal }));

    return (
        <Dialog
            header={`Alterando fluxo da venda ${vendaRef.current?.id.toString().padStart(6, "0")} - ${parseMoeda(
                vendaRef.current?.valor_total_ap
            )}`}
            visible={visible}
            onHide={() => setVisible(false)}
            style={{ width: "70vw" }}
        >
            <BlockUI loading={loading}>
                <div className="p-grid">
                    <div className="p-col-12 p-md-6">
                        <Panel header>
                            <p>
                                <Chip style={{ minWidth: "6rem" }} className="p-justify-center" label="Cliente" />{" "}
                                <span>{formatarCliente(vendaRef.current?.cliente)}</span>
                            </p>
                            <p></p>
                            <p>
                                <Chip style={{ minWidth: "6rem" }} className="p-justify-center" label="Vendedor" />{" "}
                                <span>{formatarUsuario(vendaRef.current?.vendedor)}</span>
                            </p>
                            <p>
                                <Chip style={{ minWidth: "6rem" }} className="p-justify-center" label="Supervisor" />{" "}
                                <span>{formatarUsuario(vendaRef.current?.supervisor)}</span>
                            </p>
                        </Panel>
                    </div>
                    <div className="p-col-12 p-md-6">
                        <Panel header>
                            <p>
                                {formatarSituacao(
                                    vendaRef.current?.situacao_desconto_itens,
                                    "Venda {preposicao} desconto no preço"
                                )}
                            </p>
                            <p>
                                {formatarSituacao(
                                    vendaRef.current?.situacao_desconto_encargos,
                                    "Venda {preposicao} desconto de encargo"
                                )}
                            </p>
                            <p>
                                {formatarSituacaoPlanoRecebimento(
                                    vendaRef.current?.situacao_crediario,
                                    vendaRef.current?.plano_recebimento
                                )}
                            </p>
                        </Panel>
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid p-justify-center">
                    <div className="p-field p-col-12 p-md-4">
                        <label htmlFor="competencia">Fluxo de venda</label>
                        <div className="p-inputgroup">
                            <Dropdown
                                url="/vendas/estagios-vendas/?ativo=true"
                                setObjects={setFluxosVenda}
                                optionValue="id"
                                optionLabel="descricao"
                                disabled={edicaoBloqueada}
                                value={fluxoVendaSelecionado}
                                onChange={(e) => setFluxoVendaSelecionado(e.value)}
                                className="p-mb-3"
                            />
                            <Button
                                tooltip="Remover fluxo de venda"
                                icon="pi pi-trash"
                                type="button"
                                disabled={bloqueiaRemocao}
                                onClick={() => removeEstagioVenda()}
                                className="p-button-warning p-mb-3"
                            />
                        </div>
                    </div>
                </div>
                <Steps
                    model={sequencia}
                    activeIndex={activeIndex}
                    onSelect={(e) => setActiveIndex(e.index)}
                    readOnly={false}
                />
            </BlockUI>
        </Dialog>
    );
};

export const ModalAlterarEstagio = forwardRef(Modal);
