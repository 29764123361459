import React, { useCallback, useEffect, useState } from "react";

import { useFormik } from "formik";
import classNames from "classnames";
import * as Yup from "yup";

import { MakoAutoComplete } from "@/components/MakoAutoComplete";
import { MakoCalendarTime } from "@/components/MakoCalendarTime";
import { MakoInputMoeda } from "@/components/MakoInputMoeda";

import { InputTextarea } from "primereact/inputtextarea";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";

import useLoading from "@/hooks/useLoading";
import useToast from "@/hooks/useToast";

import { TIPO_STATUS_REQUISICAO } from "@/assets/constants/constants";
import { axiosGet } from "@/services/http";
import { dataToStr } from "@/assets/util/datas";

export const FormFiltroAvancado = ({ onClose, updateUrl }) => {
    const [categorias, setCategorias] = useState([]);
    const [centros, setCentros] = useState([]);
    const [centroResultado, setCentroResultado] = useState([]);
    const [setores, setSetores] = useState([]);

    const { showLoading, hideLoading } = useLoading();
    const { showError } = useToast();

    const { setValues, resetForm, ...formik } = useFormik({
        initialValues: {
            solicitante: null,
            data_hora: null,
            grupo_categoria: null,
            centro_estocagem: null,
            centro_resultados: null,
            setor: null,
            valor_total: null,
            status: null,
            observacao: "",
            motivo_cancelamento: "",
        },
        onSubmit: handleSubmit,
    });

    const baseUrl = "/materiais/requisicoes/";

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                solicitante: Yup.object()
                    .nullable()
                    .shape({
                        id: Yup.number(),
                    })
                    .typeError("Informe um 'solicitante' válido"),
                data_hora: Yup.date().nullable().typeError("Informe uma 'data/hora' válido"),
                grupo_categoria: Yup.number().nullable().typeError("O campo 'grupo categoria' é obrigatório"),
                centro_estocagem: Yup.number().nullable().typeError("O campo 'centro estocagem' é obrigatório"),
                centro_resultados: Yup.number().nullable().typeError("Informe um 'centro de resultado' válido"),
                setor: Yup.number().nullable().typeError("Informe um 'setor' válido"),
                status: Yup.string().nullable().max(1, "Informe um status válido."),
                valor_total: Yup.number().nullable().typeError("Informe um 'custo total' válido"),
                observacao: Yup.string().max(100, "Informe no máximo 100 caracteres."),
                motivo_cancelamento: Yup.string().max(60, "Informe no máximo 60 caracteres."),
            });

            await formSchema.validate(values, {
                abortEarly: false,
            });

            let params = {};

            if (values.solicitante.id != null) {
                params = { ...params, solicitante: values.solicitante.id };
            }

            if (values.data_hora != null) {
                params = { ...params, data_hora: dataToStr(values.data_hora, "yyyy-MM-dd hh:mm:ss") };
            }

            if (values.grupo_categoria != null) {
                params = { ...params, grupo_categoria: values.grupo_categoria };
            }

            if (values.centro_estocagem != null) {
                params = { ...params, centro_estocagem: values.centro_estocagem };
            }

            if (values.centro_resultados != null) {
                params = { ...params, centro_resultados: values.centro_resultados };
            }

            if (values.setor != null) {
                params = { ...params, setor: values.setor };
            }

            if (values.status != null) {
                params = { ...params, status: values.status };
            }

            if (values.valor_total != null) {
                params = { ...params, valor_total: values.valor_total };
            }

            if (values.observacao != "") {
                params = { ...params, observacao: values.observacao };
            }

            if (values.motivo_cancelamento != "") {
                params = { ...params, motivo_cancelamento: values.motivo_cancelamento };
            }

            params =
                "?" +
                Object.keys(params)
                    .map((key) => `${key}=${params[key]}`)
                    .join("&");

            updateUrl(baseUrl + params);
            onClose();
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};

                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });

                formik.setErrors(errorMessages);
            }
        }
    }

    const handleReset = useCallback(() => {
        resetForm();
    }, [resetForm]);

    const handleCancel = useCallback(() => {
        resetForm();
        updateUrl(baseUrl);
        onClose();
    }, [baseUrl, resetForm, updateUrl, onClose]);

    const fetchCategoriasGrupos = useCallback(async () => {
        showLoading();

        const response = await axiosGet(`/produtos/categorias-grupos/`);

        if (response.status === 200) {
            setCategorias(response.data.results);
        } else {
            showError({
                summary: "Erro :(",
                detail: "Desculpe, não conseguimos listas as categorias.",
                life: 3000,
            });
        }

        hideLoading();
    }, [showLoading, hideLoading]);

    const fetchCentrosEstocagem = useCallback(async () => {
        showLoading();

        const response = await axiosGet(`/produtos/centros-estocagem/`);

        if (response.status === 200) {
            setCentros(response.data.results);
        } else {
            showError({
                summary: "Erro :(",
                detail: "Desculpe, não conseguimos listas os centros de estocagem.",
                life: 3000,
            });
        }

        hideLoading();
    }, [showLoading, hideLoading]);

    const fetchSetores = useCallback(async () => {
        showLoading();

        const response = await axiosGet(`/plano-operacional/setores/`);

        if (response.status === 200) {
            setSetores(response.data.results);
        } else {
            showError({
                summary: "Erro :(",
                detail: "Desculpe, não conseguimos listas os setores.",
                life: 3000,
            });
        }

        hideLoading();
    }, [showLoading, hideLoading]);

    const fetchCentroResultado = useCallback(async () => {
        showLoading();

        const response = await axiosGet(`/plano-operacional/centros-resultados/`);

        if (response.status === 200) {
            setCentroResultado(response.data.results);
        } else {
            showError({
                summary: "Erro :(",
                detail: "Desculpe, não conseguimos listas os centros de resultados.",
                life: 3000,
            });
        }

        hideLoading();
    }, [showLoading, hideLoading]);

    useEffect(() => {
        fetchCategoriasGrupos();
        fetchCentrosEstocagem();
        fetchSetores();
        fetchCentroResultado();
    }, [fetchCategoriasGrupos, fetchCentrosEstocagem, fetchSetores, fetchCentroResultado]);

    return (
        <div className="">
            <form onSubmit={formik.handleSubmit}>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-4">
                        <label htmlFor="solicitante">Solicitante </label>
                        <MakoAutoComplete
                            id="solicitante"
                            name="solicitante"
                            placeholder="Busque pelo nome... (min 3 caracteres)"
                            minCaracteresBusca={3}
                            value={formik.values.solicitante}
                            onChange={formik.handleChange}
                            field="nome"
                            urlSearch="/pessoas/perfis?query={id,nome,vinculoperfilempresa_set}&nome__contains="
                            className={classNames({ "p-invalid": formik.errors.solicitante })}
                        />
                        {formik.errors.solicitante && <small className="p-error">{formik.errors.solicitante}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="data_hora">Data/Hora </label>
                        <MakoCalendarTime
                            id="data_hora"
                            name="data_hora"
                            value={formik.values.data_hora}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.data_hora })}
                        />
                        {formik.errors.data_hora && <small className="p-error">{formik.errors.data_hora}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="grupo_categoria">Grupo categoria </label>
                        <Dropdown
                            id="grupo_categoria"
                            name="grupo_categoria"
                            options={categorias}
                            optionValue="id"
                            optionLabel="descricao"
                            filter
                            filterBy="descricao"
                            placeholder="Selecione um grupo categoria..."
                            value={formik.values.grupo_categoria}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.grupo_categoria })}
                        />
                        {formik.errors.grupo_categoria && (
                            <small className="p-error">{formik.errors.grupo_categoria}</small>
                        )}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="centro_estocagem">Centro de estocagem </label>
                        <Dropdown
                            id="centro_estocagem"
                            name="centro_estocagem"
                            options={centros}
                            optionValue="id"
                            optionLabel="nome"
                            filter
                            filterBy="nome"
                            placeholder="Selecione um centro de estocagem..."
                            value={formik.values.centro_estocagem}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.centro_estocagem })}
                        />
                        {formik.errors.centro_estocagem && (
                            <small className="p-error">{formik.errors.centro_estocagem}</small>
                        )}
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-6">
                        <label htmlFor="centro_resultados">Centro de resultado </label>
                        <Dropdown
                            id="centro_resultados"
                            name="centro_resultados"
                            options={centroResultado}
                            optionValue="id"
                            optionLabel="nome"
                            filter
                            filterBy="nome"
                            placeholder="Selecione um centro de resultado..."
                            value={formik.values.centro_resultados}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.centro_resultados })}
                        />
                        {formik.errors.centro_resultados && (
                            <small className="p-error">{formik.errors.centro_resultados}</small>
                        )}
                    </div>
                    <div className="p-field p-col-12 p-md-6">
                        <label htmlFor="setor">Setor</label>
                        <Dropdown
                            id="setor"
                            name="setor"
                            options={setores}
                            optionValue="id"
                            optionLabel="nome"
                            filter
                            filterBy="nome"
                            placeholder="Selecione um setor..."
                            value={formik.values.setor}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.setor })}
                        />
                        {formik.errors.setor && <small className="p-error">{formik.errors.setor}</small>}
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-6">
                        <label htmlFor="status">Status</label>
                        <Dropdown
                            id="status"
                            name="status"
                            options={TIPO_STATUS_REQUISICAO}
                            optionValue="value"
                            optionLabel="label"
                            filter
                            filterBy="label"
                            placeholder="Selecione um status..."
                            value={formik.values.status}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.status })}
                        />
                        {formik.errors.status && <small className="p-error">{formik.errors.status}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-6">
                        <label htmlFor="valor_total">Valor total</label>
                        <MakoInputMoeda
                            id="valor_total"
                            name="valor_total"
                            valueMoeda={formik.values.valor_total}
                            onChangeMoeda={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.valor_total })}
                        />
                        {formik.errors.valor_total && <small className="p-error">{formik.errors.valor_total}</small>}
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-12">
                        <label htmlFor="observacao">Observação</label>
                        <InputTextarea
                            id="observacao"
                            name="observacao"
                            value={formik.values.observacao}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.observacao })}
                            autoResize
                            rows={3}
                            maxLength={100}
                        />
                        {formik.errors.observacao && <small className="p-error">{formik.errors.observacao}</small>}
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-12">
                        <label htmlFor="motivo_cancelamento">Motivo cancelamento</label>
                        <InputTextarea
                            id="motivo_cancelamento"
                            name="motivo_cancelamento"
                            value={formik.values.motivo_cancelamento}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.motivo_cancelamento })}
                            autoResize
                            rows={3}
                            maxLength={60}
                        />
                        {formik.errors.motivo_cancelamento && (
                            <small className="p-error">{formik.errors.motivo_cancelamento}</small>
                        )}
                    </div>
                </div>
                <div className="p-grid">
                    <div className="p-col-12 p-md-6">
                        <Button type="submit" icon="pi pi-search" label="Pesquisar" className="p-mr-2 p-mb-2" />
                        <Button
                            type="reset"
                            icon="pi pi-trash"
                            label="Limpar"
                            className="p-button-warning p-mr-2 p-mb-2"
                            onClick={handleReset}
                        />
                        <Button
                            type="reset"
                            label="Cancelar Filtros"
                            className="p-button-danger p-mr-2 p-mb-2"
                            onClick={handleCancel}
                        />
                    </div>
                </div>
            </form>
        </div>
    );
};
