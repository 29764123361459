import React, { useState, useRef } from "react";
import { Button } from "primereact/button";
import { Redirect } from "react-router-dom";
import { ConfirmDialog } from "primereact/confirmdialog";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import { axiosDelete } from "@/services/http";

import permissoes from "@/assets/constants/permissoes";
import MakoListagem from "@/components/MakoListagem";
import useLoading from "@/hooks/useLoading";
import useClearRefs from "@/hooks/useClearRefs";
import useToast from "@/hooks/useToast";
import {
    BooleanFiltroTemplate,
    CodigoFiltroTemplate,
    NumeroFiltroTemplate,
    TextoFiltroTemplate,
} from "@/components/MakoFiltrosCabecalho";

const url = "/produtos/formacoes-precos/";

export const FormacaoPrecoPage = () => {
    const [redirecionar, setRedirecionar] = useState(null);
    const [deleteDialog, setDeleteDialog] = useState(false);

    const [formacao, setFormacao] = useState(null);
    const { showLoading, hideLoading } = useLoading();
    const { showSuccess, showError } = useToast();
    const listagemRef = useRef(null);
    useClearRefs(listagemRef);

    const confirmarDelete = (formacao) => {
        setFormacao(formacao);
        setDeleteDialog(true);
    };

    const esconderDeleteDialog = () => {
        setDeleteDialog(false);
    };

    const deleteParametro = async () => {
        showLoading();
        const resposta = await axiosDelete(`${url}${formacao.id}`);
        hideLoading();

        if (resposta.status === 200 || resposta.status === 204) {
            showSuccess({
                summary: "Sucesso",
                detail: "Formação de preços deletada com sucesso!",
                life: 3000,
            });

            listagemRef.current?.buscarDados();
        } else {
            showError({
                summary: "Erro :(",
                detail: "Desculpe, não conseguimos processar a sua requisição.",
                life: 3000,
            });
            hideLoading();
        }
        setDeleteDialog(false);
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <MakoControleAcesso
                    permissao={[permissoes.VENDAS_CADASTRO_FORMACAOPRECO_EDITAR]}
                    componente={Button}
                    icon="pi pi-pencil"
                    className="p-button-rounded p-button-warning p-mr-2 p-mb-1"
                    tooltip="Alterar Cadastro de formação de preços"
                    tooltipOptions={{ position: "left" }}
                    onClick={() =>
                        setRedirecionar({
                            pathname: "/vendas/cadastros/formacao-precos/form",
                            state: { formacao: rowData },
                        })
                    }
                />
                <MakoControleAcesso
                    permissao={[permissoes.VENDAS_CADASTRO_FORMACAOPRECO_EXCLUIR]}
                    componente={Button}
                    icon="pi pi-trash"
                    className="p-button-rounded p-button-danger p-mr-2 p-mb-1"
                    onClick={() => confirmarDelete(rowData)}
                    tooltip="Deletar formação de preços"
                    tooltipOptions={{ position: "left" }}
                />
            </div>
        );
    };

    const cabecalhoTabela = (
        <>
            <MakoControleAcesso
                permissao={[permissoes.VENDAS_CADASTRO_FORMACAOPRECO_INCLUIR]}
                componente={Button}
                label="Novo"
                icon="pi pi-plus"
                className="p-button-success p-mr-2"
                onClick={() => setRedirecionar("/vendas/cadastros/formacao-precos/form")}
            />
        </>
    );

    const PorcentagemFiltroTemplate = (options) => {
        return (
            <NumeroFiltroTemplate
                inputProps={{
                    mode: "decimal",
                    suffix: " %",
                    min: 0,
                    maxFractionDigits: 5,
                }}
                options={options}
            />
        );
    };

    const colunas = [
        { field: "id", header: "Código", style: { width: "12%" }, filter: true, filterElement: CodigoFiltroTemplate },
        {
            field: "descricao",
            header: "Descrição",
            style: { width: "15%" },
            filter: true,
            filterElement: TextoFiltroTemplate,
        },
        {
            field: "margem_lucro",
            header: "Margem lucro",
            style: { width: "10%" },
            filter: true,
            filterElement: PorcentagemFiltroTemplate,
        },
        {
            field: "encargo_alvo",
            header: "Encargo alvo",
            style: { width: "10%" },
            filter: true,
            filterElement: PorcentagemFiltroTemplate,
        },
        {
            field: "usar_markup",
            header: "Usa Markup",
            style: { width: "10%" },
            filter: true,
            action: ({ usar_markup }) => (usar_markup ? "Sim" : "Não"),
            filterElement: BooleanFiltroTemplate,
        },
        {
            field: "indice_markup",
            header: "Indice Markup",
            style: { width: "10%" },
            filter: true,
            filterElement: PorcentagemFiltroTemplate,
        },
        {
            field: "action",
            header: "Ações",
            action: (e) => actionBodyTemplate(e),
            style: { width: "15%" },
        },
    ];

    return redirecionar ? (
        <Redirect to={redirecionar} />
    ) : (
        <div className="p-grid">
            <div className="p-col-12">
                <div className="card">
                    <MakoListagem
                        ref={listagemRef}
                        titulo="Listagem de formação de preços"
                        colunas={colunas}
                        painelEsquerdo={cabecalhoTabela}
                        urlPesquisa={url}
                        configTabela={{
                            paginator: true,
                            lazy: true,
                        }}
                        filtros={{
                            id: { value: null, matchMode: "equals" },
                            descricao: {
                                operator: "and",
                                constraints: [{ value: "", matchMode: "unaccent_icontains" }],
                            },
                            margem_lucro: { value: null, matchMode: "equals" },
                            encargo_alvo: { value: null, matchMode: "equals" },
                            indice_markup: { value: null, matchMode: "equals" },
                            usar_markup: { value: null, matchMode: "equals" },
                        }}
                    />
                    <ConfirmDialog
                        visible={deleteDialog}
                        onHide={esconderDeleteDialog}
                        header="Confirmação"
                        message={
                            formacao && (
                                <span>
                                    {"Deseja realmente excluir a formação de preços "}
                                    <b>{formacao.descricao}</b>?
                                </span>
                            )
                        }
                        icon="pi pi-info-circle p-mr-3"
                        accept={deleteParametro}
                        acceptLabel="Sim"
                        acceptClassName="p-button-danger"
                        reject={esconderDeleteDialog}
                        rejectLabel="Não"
                    />
                </div>
            </div>
        </div>
    );
};
