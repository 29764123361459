import React from "react";
import classNames from "classnames";
import { useHistory } from "react-router-dom";
import { InputText } from "primereact/inputtext";
import { InputSwitch } from "primereact/inputswitch";
import { Button } from "primereact/button";
import { useFormik } from "formik";
import * as Yup from "yup";

import { PageBase } from "@/components/PageBase";
import { CamposObrigatorios } from "@/components/CamposObrigatorios";
import { MakoCalendar } from "@/components/MakoCalendar";
import { dataToStr } from "@/assets/util/datas";
import { axiosPost, axiosPut } from "@/services/http";
import useLoading from "@/hooks/useLoading";
import useToast from "@/hooks/useToast";

const url = "/financeiro/versoes-planos-contas-financeiras/";
const urlvoltar = "/financeiro/versao-plano-contas";

export const FinVersaoPlanoContasForm = (props) => {
    const history = useHistory();
    const { showLoading, hideLoading } = useLoading();
    const { showSuccess, showError } = useToast();

    const formik = useFormik({
        initialValues: props.location.state?.versaoContas || {
            codigo: "",
            descricao: "",
            inicio_vigencia: new Date(),
            final_vigencia: null,
            ativo: true,
        },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                descricao: Yup.string().required("O campo 'descrição' é obrigatório."),
                inicio_vigencia: Yup.date().required("O campo 'vigência inicial' é obrigatório."),
                final_vigencia: Yup.date().when("inicio_vigencia", {
                    is: (val) => !!val,
                    then: Yup.date()
                        .min(
                            Yup.ref("inicio_vigencia") || new Date(),
                            "O campo 'vigência final' não pode ser anterior a inicial"
                        )
                        .nullable()
                        .typeError("Informe uma 'vigência final' válida"),
                }),
            });
            await formSchema.validate(values, { abortEarly: false });
            values.inicio_vigencia = dataToStr(values.inicio_vigencia, "yyyy-MM-dd");
            values.final_vigencia = dataToStr(values.final_vigencia, "yyyy-MM-dd");
            if (!values.id) {
                showLoading();
                const resposta = await axiosPost(`${url}`, values);
                hideLoading();
                if (resposta.status === 201) {
                    showSuccess({
                        summary: "Sucesso",
                        detail: "Versão do plano de contas cadastrado com sucesso!",
                        life: 1500,
                    });
                    setTimeout(() => {
                        cancelar();
                    }, 2000);
                } else {
                    showError({
                        summary: "Erro",
                        detail: "Desculpe, não conseguimos processar a sua requisição.",
                        life: 3000,
                    });
                }
            } else {
                delete values.empresas;
                showLoading();
                const resposta = await axiosPut(`${url}${values.id}/`, values);
                hideLoading();

                if (resposta.status === 200) {
                    showSuccess({
                        summary: "Sucesso",
                        detail: "Versão do plano de contas alterado com sucesso!",
                        life: 1500,
                    });
                    setTimeout(() => {
                        cancelar();
                    }, 2000);
                } else {
                    showError({
                        summary: "Erro",
                        detail: "Desculpe, não conseguimos processar a sua requisição.",
                        life: 3000,
                    });
                }
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            }
        }
    }

    const cancelar = () => {
        formik.resetForm();
        history.push(urlvoltar);
    };

    return (
        <PageBase>
            <h3>{!formik.values.id ? "Nova versão do plano de contas" : "Manutenção de versão do plano de contas"}</h3>
            <form onSubmit={formik.handleSubmit}>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-8">
                        <label htmlFor="descricao">Descrição *</label>
                        <InputText
                            id="descricao"
                            name="descricao"
                            autoComplete="off"
                            value={formik.values.descricao}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.descricao })}
                        />
                        {formik.errors.descricao && <small className="p-error">{formik.errors.descricao}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="inicio_vigencia">Vigência Inicial *</label>
                        <MakoCalendar
                            id="inicio_vigencia"
                            name="inicio_vigencia"
                            valueCalendar={formik.values.inicio_vigencia}
                            onChange={formik.handleChange}
                            className={classNames({
                                "p-invalid": formik.errors.inicio_vigencia,
                            })}
                        />
                        {formik.errors.inicio_vigencia && (
                            <small className="p-error">{formik.errors.inicio_vigencia}</small>
                        )}
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="final_vigencia">Vigência Final </label>
                        <MakoCalendar
                            id="final_vigencia"
                            name="final_vigencia"
                            valueCalendar={formik.values.final_vigencia}
                            onChange={formik.handleChange}
                            className={classNames({
                                "p-invalid": formik.errors.final_vigencia,
                            })}
                        />
                        {formik.errors.final_vigencia && (
                            <small className="p-error">{formik.errors.final_vigencia}</small>
                        )}
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-1 ">
                        <h5>
                            <label htmlFor="status">Status</label>
                        </h5>
                        <InputSwitch
                            id="ativo"
                            name="ativo"
                            checked={formik.values.ativo}
                            disabled={!formik.values.id}
                            value={formik.values.ativo}
                            onChange={formik.handleChange}
                        />
                    </div>
                </div>
                <CamposObrigatorios />
                <div className="p-grid p-col-12 p-md-6">
                    <Button type="submit" icon="pi pi-save" label="Gravar" className="p-mr-2" />
                    <Button
                        type="button"
                        icon="pi pi-angle-double-left"
                        label="Voltar"
                        className="p-button-danger"
                        onClick={cancelar}
                    />
                </div>
            </form>
        </PageBase>
    );
};
