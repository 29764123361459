import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useState, useRef } from "react";
import classNames from "classnames";
import { confirmDialog } from "primereact/confirmdialog";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";

import { ModalBuscaSku } from "./ModalBuscaSKU";
import useFormatCNPJCPF from "@/hooks/useFomatCNPJCPF";
import useLoading from "@/hooks/useLoading";
import useEntrada from "@/hooks/useEntrada";
import MakoListagem from "@/components/MakoListagem";
import { parseNumberToMoneyHTML } from "@/assets/util/util";
import { axiosGet, axiosPatch, axiosPost, axiosPut } from "@/services/http";

const EntradaXML = ({ aposFechar }, ref) => {
    const [xml, setXml] = useState(0);
    const [emitente, setEmitente] = useState(null);
    const [emitenteExiste, setEmitenteExiste] = useState(true);
    const [emitenteConfere, setEmitenteConfere] = useState(false);
    const [docEmitente, setDocEmitente] = useState("");
    const [nomeEmitente, setNomeEmitente] = useState("");
    const [visible, setVisible] = useState(false);
    const [loadingVinculo, setLoadingVinculo] = useState(false);
    const toastRef = useRef(null);
    const modalSkuRef = useRef(null);
    const listagemRef = useRef(null);
    const [formatarDocumento, limparDocumento] = useFormatCNPJCPF();
    const { showLoading, hideLoading } = useLoading();
    const { dadosBasicos, handleChecarDocEmitente, handleGetEntrada } = useEntrada();

    const cadastrarEmitente = async () => {
        showLoading();
        const json = await axiosPost(`/pessoas/cadastrar-fornecedor-xml/${xml?.id}/`);
        hideLoading();

        if (json.status === 201) {
            setEmitente(json.data.fornecedor);
            setEmitenteExiste(true);
        } else if (json.status === 400) {
            toastRef.current.show({
                severity: "warn",
                summary: "Aviso!",
                detail: json.data.msg || "Não foi possível cadastrar o emitente automaticamente.",
                life: 5000,
            });
        } else {
            toastRef.current.show({
                severity: "error",
                summary: "Erro :(",
                detail: "Desculpe, não conseguimos cadastrar o emitente de forma automática.",
                life: 5000,
            });
        }
    };

    const vincularItensXmlAutomaticamente = async () => {
        setLoadingVinculo(true);
        const json = await axiosPut(`/compras/vincular-itensxml-com-sku/${xml?.id}/`);
        setLoadingVinculo(false);
        if (json.status === 200) {
            if (json.data.itens_vinculados > 0) {
                const { itens_vinculados } = json.data;
                toastRef.current.show({
                    severity: "success",
                    summary: "Sucesso",
                    detail:
                        itens_vinculados === 1
                            ? "Um item vinculado automaticamente."
                            : `Foram vinculados automaticamente ${itens_vinculados} itens.`,
                    life: 3000,
                });

                listagemRef.current?.buscarDados();
            } else {
                toastRef.current.show({
                    severity: "info",
                    summary: "Info",
                    detail: "Não foi possível vincular nenhum item automaticamente, tente manualmente ou revise seu cadastro de produtos.",
                    life: 10000,
                });
            }
        } else {
            toastRef.current.show({
                severity: "error",
                summary: "Erro :(",
                detail: "Desculpe, não foi possível vincular os itens automaticamente.",
                life: 3000,
            });
        }
    };

    const alterarFornecedorEntrada = async () => {
        showLoading();
        const json = await axiosPatch(`/compras/entradas/${dadosBasicos.id}/`, { fornecedor: emitente });
        hideLoading();

        if (json.status === 200) {
            setEmitenteConfere(true);
            await handleGetEntrada(xml?.entrada);
        }
    };

    const confirmarCadastroEmitente = () => {
        confirmDialog({
            message: "Confirma o cadastro do emitente no sistema?",
            header: "Confirmação",
            icon: "pi pi-exclamation-triangle",
            accept: () => cadastrarEmitente(),
            acceptLabel: "Sim",
            rejectLabel: "Não",
            rejectClassName: "p-button-outlined p-button-danger",
        });
    };

    const confirmarSinconizacaoEmitente = () => {
        confirmDialog({
            message: "Confirma a alteração do fornecedor da entrada para este?",
            header: "Confirmação",
            icon: "pi pi-exclamation-triangle",
            accept: () => alterarFornecedorEntrada(),
            acceptLabel: "Sim",
            rejectLabel: "Não",
            rejectClassName: "p-button-outlined p-button-danger",
        });
    };

    const verificarDocumentoExiste = useCallback(async () => {
        if (docEmitente !== "") {
            const doc = limparDocumento(docEmitente);

            const json = await axiosGet(`/pessoas/perfis/?query={id}&identificacao=${doc}`);

            if (json.status === 200) {
                if (json.data.count === 0) {
                    setEmitenteExiste(false);
                } else {
                    setEmitenteExiste(true);
                    setEmitente(json.data.results[0].id);
                }
            }
        }
    }, [docEmitente, limparDocumento]);

    const checarDocEmitente = useCallback(() => {
        const doc = limparDocumento(docEmitente);

        const check = handleChecarDocEmitente(doc);

        setEmitenteConfere(check);
    }, [docEmitente, handleChecarDocEmitente, limparDocumento]);

    useEffect(() => {
        verificarDocumentoExiste();
        checarDocEmitente();
    }, [verificarDocumentoExiste, checarDocEmitente]);

    const abrirModal = (xml) => {
        if (xml) {
            const { cpf_cnpj_emitente, nome_emitente } = xml;

            setXml(xml);
            setDocEmitente(formatarDocumento(cpf_cnpj_emitente));
            setNomeEmitente(nome_emitente);
        }

        setVisible(true);
    };

    const fecharModal = () => {
        setVisible(false);

        if (aposFechar) {
            aposFechar();
        }
    };

    useImperativeHandle(ref, () => {
        return {
            abrirModal,
        };
    });

    const rowClass = (rowData) => {
        return {
            "entrada-xml-item-vinculado": rowData.sku !== null,
        };
    };

    const edicaoBloqueada = dadosBasicos?.status === "C";

    const painelEsquerdoTabela = (
        <Button
            label="Fazer vínculo automaticamente"
            icon="pi pi-refresh"
            loading={loadingVinculo}
            disabled={edicaoBloqueada}
            onClick={() => vincularItensXmlAutomaticamente()}
            className="p-button-help p-mr-2 p-mb-2"
        />
    );

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button
                    icon="fas fa-search"
                    className="p-button-rounded"
                    disabled={!emitenteConfere || edicaoBloqueada}
                    onClick={() => modalSkuRef.current?.abrirModal(rowData.id)}
                />
            </div>
        );
    };

    const colunas = [
        { field: "codigo", header: "Código NF", style: { maxWidth: "10%" } },
        { field: "sku.codigo", header: "Código interno", style: { maxWidth: "10%" } },
        { field: "descricao", header: "Descrição" },
        { field: "unidade_compra", header: "Und.", style: { maxWidth: "5%" } },
        { field: "quantidade", header: "Quant.", decimal: true, style: { maxWidth: "8%" } },
        {
            field: "valor_unitario",
            header: "Valor unitário",
            style: { maxWidth: "12%" },
            action: (e) => parseNumberToMoneyHTML(e.valor_unitario),
        },
        {
            field: "valor_total",
            header: "Valor total",
            style: { maxWidth: "12%" },
            action: (e) => parseNumberToMoneyHTML(e.valor_total),
        },
        { field: "action", header: "Ações", style: { maxWidth: "7%" }, action: (e) => actionBodyTemplate(e) },
    ];

    return (
        <Dialog header="Conferência de XML" visible={visible} onHide={fecharModal} style={{ width: "75vw" }}>
            <Toast ref={toastRef} />
            <ModalBuscaSku ref={modalSkuRef} onVinculo={() => listagemRef.current?.buscarDados()} />
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12 p-md-3">
                    <label htmlFor="doc-emitente">CPF / CNPJ emitente</label>
                    <div className="p-inputgroup">
                        <InputText
                            id="doc-emitente"
                            disabled
                            value={docEmitente}
                            className={classNames({ "p-invalid": !emitenteExiste || !emitenteConfere })}
                        />
                        {!emitenteExiste && !edicaoBloqueada && (
                            <Button
                                icon="pi pi-plus"
                                label="Cadastrar"
                                className="p-button-success"
                                onClick={confirmarCadastroEmitente}
                            />
                        )}
                        {emitenteExiste && !emitenteConfere && !edicaoBloqueada && (
                            <Button
                                icon="fas fa-exclamation"
                                label="Sincronizar"
                                className="p-button-warning"
                                onClick={confirmarSinconizacaoEmitente}
                            />
                        )}
                    </div>
                    {!emitenteExiste && (
                        <small className="p-error">O emitente da NF não é reconhecido pelo sistema</small>
                    )}
                    {emitenteExiste && !emitenteConfere && (
                        <small className="p-error">O emitente da NF é diferente do informado anteriormente</small>
                    )}
                </div>
                <div className="p-field p-col-12 p-md-9">
                    <label htmlFor="nome-emitente">Nome do emitente</label>
                    <InputText id="nome-emitente" disabled value={nomeEmitente} />
                </div>
            </div>
            <MakoListagem
                ref={listagemRef}
                colunas={colunas}
                painelEsquerdo={painelEsquerdoTabela}
                urlPesquisa={`/compras/itens-entradas-xml/?entrada_xml=${xml?.id || 0}`}
                configTabela={{
                    lazy: true,
                    paginator: true,
                    scrollable: true,
                    scrollHeight: "50vh",
                    rowClassName: rowClass,
                }}
            />
        </Dialog>
    );
};

export const ModalEntradaXML = forwardRef(EntradaXML);
