import React, { createContext, useCallback, useState } from "react";

import { axiosGet, axiosPost, axiosPatch, axiosDelete } from "@/services/http";
import { dataToStr } from "@/assets/util/datas";
import useAuth from "@/hooks/useAuth";
import useEmpresa from "@/hooks/useEmpresa";
import useToast from "@/hooks/useToast";

const OrdemCompraContext = createContext({});

export const OrdemCompraProvider = ({ children }) => {
    const [dadosBasicos, setDadosBasicos] = useState(null);
    const [fornecedorOC, setFornecedorOC] = useState(null);
    const [submit, setSubmit] = useState(false);
    const [loadingOC, setLoadingOC] = useState(false);
    const [permiteAlterar, setPermiteAlterar] = useState(true);
    const { user } = useAuth();
    const { empresaSelecionadaId } = useEmpresa();
    const { showWarning, showError } = useToast();

    const handleDadosBasicos = useCallback(
        async (values) => {
            const ordemCompra = {
                ...values,
                fornecedor: values.fornecedor.id,
                representante: values.representante?.id,
                data_pedido: dataToStr(values.data_pedido, "yyyy-MM-dd"),
                vencimento_antecipacao: dataToStr(values.vencimento_antecipacao, "yyyy-MM-dd"),
                previsao_padrao: dataToStr(values.previsao_padrao, "yyyy-MM-dd"),
            };
            if (!values.id) {
                const novaOrdemCompra = {
                    ...ordemCompra,
                    status: "PE",
                    empresa: empresaSelecionadaId,
                    usuario_cadastro: user.id,
                    quantidade_parcelas: 0,
                    itemordemcompra_set: {},
                };
                const resposta = await axiosPost("/compras/ordens-compra/", novaOrdemCompra);
                setDadosBasicos(resposta.data);
                setFornecedorOC(resposta.data.fornecedor);
                return resposta;
            } else {
                const ordemCompraEditada = {
                    fornecedor: ordemCompra.fornecedor,
                    representante: ordemCompra.representante,
                    data_pedido: ordemCompra.data_pedido,
                    categoria: ordemCompra.categoria,
                    categoria_item: ordemCompra.categoria_item,
                    valor_antecipado: ordemCompra.valor_antecipado,
                    vencimento_antecipacao: ordemCompra.vencimento_antecipacao,
                    anotacoes: ordemCompra?.anotacoes,
                    forma_pagamento: ordemCompra?.forma_pagamento,
                    previsao_padrao: ordemCompra?.previsao_padrao,
                };
                let diffOrdemCompra = {};
                for (const [k, v] of Object.entries(ordemCompraEditada)) {
                    if (v !== dadosBasicos[k]) {
                        diffOrdemCompra[k] = v;
                    }
                }
                if (Object.keys(diffOrdemCompra).length > 0) {
                    const resposta = await axiosPatch(`/compras/ordens-compra/${values.id}/`, diffOrdemCompra);
                    setDadosBasicos(resposta.data);
                    setFornecedorOC(resposta.data.fornecedor);
                    return resposta;
                }
                return { status: 204, data: values };
            }
        },
        [dadosBasicos, user, empresaSelecionadaId]
    );

    const handleRemoverParcelas = useCallback(async () => {
        const { status, data } = await axiosDelete(`/compras/ordens-compra/${dadosBasicos?.id}/deletar-parcelas/`);
        if (status === 204) {
            return true;
        } else if (status !== 500) {
            showWarning({
                summary: "Aviso!",
                detail: data.msg,
                life: 2000,
            });
        } else {
            showError({
                summary: "Erro :(",
                detail: "Desculpe, não foi possível excluir as parcelas.",
                life: 3000,
            });
        }
        return false;
    }, [dadosBasicos, showWarning, showError]);

    const handlePreencherOrdemCompra = useCallback(async (ordemCompraId) => {
        setLoadingOC(true);
        const json = await axiosGet(`/compras/ordens-compra/${ordemCompraId}/`);
        setLoadingOC(false);
        if (json.status === 200) {
            const {
                vinculotransportadorordemcompra_set,
                itemordemcompra_set,
                parcelaordemcompra_set,
                status,
                ...rest
            } = json.data;
            setDadosBasicos(rest);
            setFornecedorOC(rest.fornecedor);
            setSubmit(true);
            setPermiteAlterar(() => {
                if (rest.vinculado_entrada) return false;
                return status === "PE";
            });
        }
    }, []);

    const atualizarValoresOC = useCallback(async () => {
        if (dadosBasicos) {
            const campos = [
                "valor_total_itens",
                "valor_frete_previsto",
                "percentual_frete_emitente",
                "percentual_frete_destinatario",
                "valor_total_parcelas",
                "valor_total_ordem_compra",
                "valor_total_ipi",
                "valor_total_pis",
                "valor_total_cofins",
                "valor_total_icms",
                "valor_total_icms_st",
            ];
            const params = {
                query: `{${campos.join(",")}}`,
            };
            const { status, data } = await axiosGet(`/compras/ordens-compra/${dadosBasicos.id}/`, { params });
            if (status === 200) {
                setDadosBasicos((old) => ({ ...old, ...data }));
            }
        }
    }, [dadosBasicos]);

    return (
        <OrdemCompraContext.Provider
            value={{
                submit,
                loadingOC,
                permiteAlterar,
                dadosBasicos,
                fornecedorOC,
                handleRemoverParcelas,
                handlePreencherOrdemCompra,
                handleDadosBasicos,
                atualizarValoresOC,
                setSubmit,
            }}
        >
            {children}
        </OrdemCompraContext.Provider>
    );
};

export default OrdemCompraContext;
