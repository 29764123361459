import React, { useCallback, useEffect, useRef, useState } from "react";
import { Button } from "primereact/button";
import { axiosGet, axiosPost } from "@/services/http";
import { Toast } from "primereact/toast";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";

import useLoading from "@/hooks/useLoading";

export const ClonarTabelaForm = (props) => {
    const [tabela, setTabela] = useState(null);
    const [listaTabelas, setListaTabelas] = useState([]);
    const [novoNome, setNovoNome] = useState("");
    const { showLoading, hideLoading } = useLoading();
    const toastRef = useRef(null);

    async function clonarTabela() {
        try {
            showLoading();
            const dadosTabela = await axiosGet(`/produtos/tabelas-preco/${tabela}/`);
            hideLoading();

            let precoItens = [],
                empresasVinculadas = [];

            dadosTabela.data?.precostabelapreco_set?.forEach((preco) => {
                precoItens.push({
                    sku: preco.sku.id,
                    preco_calculado: preco.preco_calculado,
                    preco_arredondado: preco.preco_arredondado,
                    plano_recebimento: preco.plano_recebimento.id,
                });
            });
            dadosTabela.data?.empresastabelapreco_set?.forEach((empresa) => {
                empresasVinculadas.push({ unidade_venda: empresa.unidade_venda.id });
            });

            const body = {
                ...dadosTabela.data,
                nome: novoNome,
                precostabelapreco_set: { create: precoItens },
                empresastabelapreco_set: { create: empresasVinculadas },
            };

            showLoading();
            const resposta = await axiosPost("/produtos/tabelas-preco/", body);
            hideLoading();

            if (resposta.status === 201) {
                toastRef.current.show({
                    severity: "success",
                    summary: "Sucesso",
                    detail: "Tabela clonada com sucesso!",
                    life: 2500,
                });
                props.callBack();
            } else {
                toastRef.current.show({
                    severity: "error",
                    summary: "Erro",
                    detail: "Desculpe, não conseguimos processar a sua requisição.",
                    life: 1500,
                });
            }
        } catch (error) {
            toastRef.current.show({
                severity: "error",
                summary: "Erro",
                detail: "Desculpe, não conseguimos processar a sua requisição.",
                life: 1500,
            });
            hideLoading();
        }
    }

    const listarTabelas = useCallback(async () => {
        showLoading();
        const resposta = await axiosGet("/produtos/tabelas-preco?query={id, nome}");
        hideLoading();

        if (resposta.status === 200 || resposta.status === 201) setListaTabelas(resposta.data.results);
    }, [showLoading, hideLoading]);

    useEffect(() => {
        listarTabelas();
    }, [listarTabelas]);

    return (
        <div className="p-grid">
            <Toast ref={toastRef} />
            <div className="p-col-12">
                <form>
                    <label htmlFor="natureza_operacao">Selecione uma tabela para realizar a clonagem: *</label>
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-12 p-md-12">
                            <Dropdown
                                id="tabela"
                                name="tabela"
                                options={listaTabelas}
                                optionValue="id"
                                optionLabel="nome"
                                filter
                                filterBy="nome"
                                showClear
                                placeholder="Selecione uma tabela"
                                value={tabela}
                                onChange={(e) => setTabela(e.target.value)}
                            />
                        </div>
                    </div>
                    {tabela ? (
                        <div className="p-fluid p-formgrid p-grid">
                            <div className="p-field p-col-12 p-md-12">
                                <label htmlFor="nome">Nome tabela *</label>
                                <InputText
                                    id="nome"
                                    name="nome"
                                    value={novoNome}
                                    onChange={(e) => setNovoNome(e.target.value)}
                                    autoComplete="off"
                                    autoFocus
                                />
                            </div>
                        </div>
                    ) : null}
                    <br />
                    <div className="p-grid p-justify-center p-col-12 p-md-12">
                        <Button
                            label="Clonar tabela"
                            type="button"
                            icon="pi pi-align-justify"
                            className="p-button-info p-mr-2"
                            disabled={!(tabela && novoNome)}
                            onClick={() => clonarTabela()}
                        />
                    </div>
                </form>
            </div>
        </div>
    );
};
