import React, { forwardRef, useImperativeHandle, useMemo, useState } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import { useFormik } from "formik";

import { MakoInputMoeda } from "@/components/MakoInputMoeda";
import { axiosPut } from "@/services/http";
import useToast from "@/hooks/useToast";
import useEntrada from "@/hooks/useEntrada";
import useParam from "@/hooks/useParam";
import { COMPRAS_ENTRADA_NOTAFISCAL_DEFINEESCRITURACAO } from "@/assets/constants/parametros";
import { formatarCasasDecimais, parseMoeda } from "@/assets/util/util";

const EntradaComplementoNfManual = ({ onSave }, ref) => {
    const [visible, setVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const [notaFiscalEntrada, setNotaFiscalEntrada] = useState(null);
    const { permiteAlterar } = useEntrada();
    const { showError } = useToast();
    const { getParam } = useParam();

    const { setFieldValue, ...formik } = useFormik({
        initialValues: {
            entrada: null,
            nota_fiscal: null,
            outros_valores: 0,
            custos_extras: 0,
            frete_complementar: 0,
            total_custos: 0,
            valor_nf: 0,
            valor_total_fin: 0,
            pis_cofins_frete_aprop: 0,
            escriturar: true,
        },
        onSubmit: handleSubmit,
    });

    const permiteDefinirEscrituracao = useMemo(() => {
        const param = getParam(COMPRAS_ENTRADA_NOTAFISCAL_DEFINEESCRITURACAO);
        if (param && param.valor === "1") return true;
        return false;
    }, [getParam]);

    async function handleSubmit(values) {
        const body = {
            ...values,
            outros_valores: formatarCasasDecimais(values.outros_valores),
            custos_extras: formatarCasasDecimais(values.custos_extras),
            frete_complementar: formatarCasasDecimais(values.frete_complementar),
            total_custos: formatarCasasDecimais(values.total_custos),
            valor_nf: formatarCasasDecimais(values.valor_nf),
            valor_total_fin: formatarCasasDecimais(values.valor_total_fin),
            pis_cofins_frete_aprop: formatarCasasDecimais(values.pis_cofins_frete_aprop),
        };
        setLoading(true);
        const { status } = await axiosPut(`/compras/notafiscal-entradas/${values.id}/`, body);
        setLoading(false);
        if (status === 200) {
            if (typeof onSave === "function") onSave();
            setVisible(false);
        } else {
            showError({
                summary: "Erro :(",
                detail: "Desculpe, não foi possível processar a requisição.",
                life: 3000,
            });
        }
    }

    const abrirModal = (nfEntrada) => {
        if (nfEntrada) {
            setNotaFiscalEntrada(nfEntrada);
            formik.setValues({
                id: nfEntrada.id,
                entrada: nfEntrada.entrada,
                nota_fiscal: nfEntrada.nota_fiscal.id,
                outros_valores: nfEntrada.outros_valores,
                custos_extras: nfEntrada.custos_extras,
                frete_complementar: nfEntrada.frete_complementar,
                total_custos: nfEntrada.total_custos,
                valor_nf: nfEntrada.nota_fiscal.valor_total_nf,
                valor_total_fin: nfEntrada.valor_total_fin,
                pis_cofins_frete_aprop: nfEntrada.pis_cofins_frete_aprop,
                escriturar: nfEntrada.escriturar,
            });
        }
        setVisible(true);
    };

    useImperativeHandle(ref, () => ({ abrirModal }));

    // const calcularTotalDocumento = useCallback(() => {
    //     const { outros_valores, custos_extras, frete_complementar, valor_nf } = formik.values;
    //     const totalDocumento =
    //         parseNumber(valor_nf) +
    //         parseNumber(outros_valores) +
    //         parseNumber(custos_extras) +
    //         parseNumber(frete_complementar);
    //     setFieldValue("total_custos", totalDocumento);
    //     setFieldValue("valor_total_fin", totalDocumento);
    // }, [formik.values, setFieldValue]);

    // useEffect(() => {
    //     calcularTotalDocumento();
    // }, [calcularTotalDocumento]);

    const renderFooter = () => {
        return (
            <div>
                <Button
                    type="submit"
                    label="Gravar"
                    icon="pi pi-save"
                    disabled={!permiteAlterar}
                    loading={loading}
                    onClick={formik.handleSubmit}
                />
            </div>
        );
    };

    return (
        <Dialog
            header={`Complemento de nota fiscal: ${notaFiscalEntrada?.nota_fiscal?.numero} (${parseMoeda(
                notaFiscalEntrada?.nota_fiscal?.valor_total_nf
            )})`}
            visible={visible}
            onHide={() => setVisible(false)}
            style={{ width: "50vw" }}
            footer={renderFooter}
            blockScroll
        >
            <form>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-4">
                        <label htmlFor="outros-valores">Outros valores</label>
                        <MakoInputMoeda
                            id="outros-valores"
                            name="outros_valores"
                            valueMoeda={formik.values.outros_valores}
                            onChangeMoeda={formik.handleChange}
                        />
                    </div>
                    <div className="p-field p-col-12 p-md-4">
                        <label htmlFor="custos-extras">Custos extras</label>
                        <MakoInputMoeda
                            id="custos-extras"
                            name="custos_extras"
                            valueMoeda={formik.values.custos_extras}
                            onChangeMoeda={formik.handleChange}
                        />
                    </div>
                    <div className="p-field p-col-12 p-md-4">
                        <label htmlFor="frete-complementar">Frete complementar</label>
                        <MakoInputMoeda
                            id="frete-complementar"
                            name="frete_complementar"
                            valueMoeda={formik.values.frete_complementar}
                            onChangeMoeda={formik.handleChange}
                        />
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-4">
                        <label htmlFor="total-custos">Total do documento</label>
                        <MakoInputMoeda
                            id="total-custos"
                            name="total_custos"
                            disabled
                            valueMoeda={formik.values.total_custos}
                        />
                    </div>
                    <div className="p-field p-col-12 p-md-4">
                        <label htmlFor="valor-total-financeiro">Valor total financeiro</label>
                        <MakoInputMoeda
                            id="valor-total-financeiro"
                            name="valor_total_fin"
                            valueMoeda={formik.values.valor_total_fin}
                            onChangeMoeda={formik.handleChange}
                        />
                    </div>
                    <div className="p-field p-col-12 p-md-4">
                        <label htmlFor="pis-cofins-frete-apropriado">PIS/COFINS sobre frete aprop.</label>
                        <MakoInputMoeda
                            id="pis-cofins-frete-apropriado"
                            name="pis_cofins_frete_aprop"
                            valueMoeda={formik.values.pis_cofins_frete_aprop}
                            onChangeMoeda={formik.handleChange}
                        />
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    {permiteDefinirEscrituracao && (
                        <div className="p-field-checkbox p-col-12">
                            <Checkbox
                                id="escriturar"
                                name="escriturar"
                                checked={formik.values.escriturar}
                                onChange={formik.handleChange}
                            />
                            <label htmlFor="escriturar">Escriturar NF?</label>
                        </div>
                    )}
                </div>
            </form>
        </Dialog>
    );
};

export const ModalEntradaComplementoNfManual = forwardRef(EntradaComplementoNfManual);
