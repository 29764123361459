import React, { useCallback, useEffect, useRef, useState } from "react";
import { TabView, TabPanel } from "primereact/tabview";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { useHistory } from "react-router-dom";
import { PageBase } from "@/components/PageBase";
import { TransferenciaProvider } from "@/contexts/transferenciaContext";
import { DadosBasicosForm } from "./formDadosBasicos";
import { ItensTransferenciaForm } from "./formItens";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "@/components/Dropdown";
import { axiosGet } from "@/services/http";
import useTransferencia from "@/hooks/useTransferencia";
import { DocumentosFiscaisForm } from "./formDocumentosFiscais";
import axios from "axios";

const TabTransferencia = (props) => {
    const { transferencia, handlePreencherTransferencia } = useTransferencia();
    const [activeTab, setActiveTab] = useState(0);
    const [listaCentros, setListaCentros] = useState([]);
    const toastRef = useRef(null);
    const history = useHistory();

    const alterarTab = (aba) => {
        if (aba === -1) {
            if (activeTab > 0) {
                setActiveTab(activeTab - 1);
            }
        } else {
            if (activeTab < 6) {
                setActiveTab(activeTab + 1);
            }
        }
    };

    const listarCentrosEstocagem = useCallback(async () => {
        const { status, data } = await axiosGet("/produtos/centros-estocagem?limit=1000");

        if (status === 200) if (data?.results?.length > 0) setListaCentros(data.results);
    }, []);

    useEffect(() => {
        listarCentrosEstocagem();
    }, [listarCentrosEstocagem]);

    const carregaTransferencia = useCallback(() => {
        const cancelToken = axios.CancelToken.source();
        if (props.props?.location?.state?.transferencia)
            handlePreencherTransferencia(props.props.location.state.transferencia);
        return function clear() {
            cancelToken.cancel();
        };
    }, [props.props?.location?.state?.transferencia, handlePreencherTransferencia]);

    useEffect(() => {
        carregaTransferencia();
    }, [carregaTransferencia]);

    const cabecalhoTransferencia = (
        <div className="card" style={{ backgroundColor: "#F5F5F5" }}>
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12 p-md-2">
                    <label htmlFor="id">
                        <b>Transferência</b>
                    </label>
                    <InputText id="id" name="id" value={transferencia?.id || "-"} disabled />
                </div>
                <div className="p-field p-col-12 p-md-5">
                    <label htmlFor="ce_origem">Centro de Estocagem Origem</label>
                    <Dropdown
                        id="ce_origem"
                        name="ce_origem"
                        options={listaCentros}
                        optionValue="id"
                        optionLabel="nome"
                        placeholder="-"
                        filter
                        filterBy="nome"
                        value={transferencia?.ce_origem}
                        readOnly
                        disabled
                    />
                </div>
                <div className="p-field p-col-12 p-md-5">
                    <label htmlFor="ce_destino">Centro de Estocagem Destino</label>
                    <Dropdown
                        id="ce_destino"
                        name="ce_destino"
                        placeholder="-"
                        options={listaCentros}
                        optionValue="id"
                        optionLabel="nome"
                        filter
                        filterBy="nome"
                        value={transferencia?.ce_destino}
                        readOnly
                        disabled
                    />
                </div>
            </div>
        </div>
    );

    return (
        <PageBase>
            <Toast ref={toastRef} />
            <h5>Transferência Entre Centros de Estocagem</h5>
            <TabView activeIndex={activeTab} onTabChange={(e) => setActiveTab(e.index)}>
                <TabPanel header="Dados Básicos" leftIcon="pi pi-save">
                    <DadosBasicosForm tipo={props.props.location?.state?.tipoTransf} />
                </TabPanel>
                <TabPanel header="Itens" leftIcon="pi pi-ellipsis-v" disabled={!transferencia}>
                    {transferencia ? cabecalhoTransferencia : null}
                    <ItensTransferenciaForm />
                </TabPanel>
                <TabPanel header="Documentos fiscais" leftIcon="pi pi-file" disabled={!transferencia}>
                    {transferencia ? cabecalhoTransferencia : null}
                    <DocumentosFiscaisForm />
                </TabPanel>
            </TabView>
            <div className="p-grid p-fluid p-justify-start">
                {activeTab > 0 ? (
                    <div className="p-col-6 p-md-3">
                        <Button
                            label="Anterior"
                            icon="pi pi-angle-left"
                            className="p-button-info"
                            onClick={() => alterarTab(-1)}
                        />
                    </div>
                ) : null}
                {activeTab < 2 ? (
                    <div className="p-col-6 p-md-3">
                        <Button
                            label="Próximo"
                            icon="pi pi-angle-right"
                            iconPos="right"
                            className="p-button-info"
                            onClick={() => alterarTab(1)}
                        />
                    </div>
                ) : null}
                <div className="p-col-6 p-md-3">
                    <Button
                        label="Voltar"
                        className="p-button-danger"
                        onClick={() => history.push("/estoque/movimentacoes/transferencia-mercadoria")}
                    />
                </div>
            </div>
        </PageBase>
    );
};

export const TabTransferenciaContext = (props) => {
    return (
        <TransferenciaProvider>
            <TabTransferencia props={props} />
        </TransferenciaProvider>
    );
};
