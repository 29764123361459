import { useCallback, useEffect, useMemo, useState } from "react";
import useAxiosPrivate from "./useAxiosPrivate";

const useFetchRelatorio = ({ chave = "", formato = "pdf" }) => {
    const [relatorio, setRelatorio] = useState(null);
    const [versoes, setVersoes] = useState([]);
    const { axiosGet } = useAxiosPrivate();

    if (!chave) throw new Error("Informe uma chave de relatório");

    const fetchDados = useCallback(
        async (signal) => {
            const params = {
                relatorio__chave: chave,
                ativa: true,
                query: "{id,versao,descricao,nome_curto,formato_saida,ativa,relatorio}",
                ordering: "versao",
            };
            const { status, data } = await axiosGet("/relatorios/relatorios-versao/", { params, signal });
            if (status === 200) {
                const _relatorio = data.results?.length
                    ? data.results.filter(({ formato_saida }) => formato_saida !== "csv")[0].relatorio
                    : null;
                setRelatorio(_relatorio);
                setVersoes(data.results);
            }
        },
        [chave, axiosGet]
    );

    const versao = useMemo(() => {
        if (!versoes?.length) return null;
        const _v = versoes.filter(({ formato_saida }) => formato_saida === formato)[0];
        if (!_v) return null;
        return _v.versao;
    }, [versoes, formato]);

    useEffect(() => {
        const controller = new AbortController();
        fetchDados(controller.signal);

        return () => {
            controller.abort();
        };
    }, [fetchDados]);

    return { relatorio, versoes, versao };
};

export default useFetchRelatorio;
