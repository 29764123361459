import React, { useState, useImperativeHandle, forwardRef } from "react";
import { ConfirmDialog } from "primereact/confirmdialog";

import { MakoButton as Button } from "../MakoButton";

import { axiosDelete } from "@/services/http";
import useLoadingLocal from "@/hooks/useLoadingLocal";
import useToast from "@/hooks/useToast";

const Componente = (
    { url, objetoId, exigeConfirmacao, msgConfirmacao, onDelete, msgToastErroExclusao, msgErro400, hideLoad },
    ref
) => {
    const [visible, setVisible] = useState(false);
    const [loading, showLoading, hideLoading] = useLoadingLocal();
    const { showWarning, showError } = useToast();

    const deletar = async () => {
        const urlExclusao = url.endsWith("/") ? url.slice(0, -1) : url;

        if (!hideLoad) showLoading();
        const { status, data } = await axiosDelete(`${urlExclusao}/${objetoId}/`);
        if (!hideLoad) hideLoading();

        if (status === 204) {
            if (typeof onDelete === "function") onDelete();
            setVisible(false);
        } else if (status === 400) {
            showWarning({
                summary: "Alerta!",
                detail: msgErro400 || "Desculpe, não foi possível efetuar a exclusão.",
                life: 5000,
            });
        } else {
            if (
                typeof data === "string" &&
                data?.includes("because they are referenced through protected foreign keys:")
            ) {
                showWarning({
                    summary: "Alerta!",
                    detail: "Esse registro não pode ser excluído pois está vinculado a outra informação do sistema.",
                    life: 5000,
                });
            } else {
                showError({
                    summary: "Erro :(",
                    detail: msgToastErroExclusao || "Desculpe, não conseguimos processar a sua requisição.",
                    life: 3000,
                });
            }
        }
    };

    const footer = (
        <>
            <Button label="Cancelar" text disabled={loading} onClick={() => setVisible(false)} />
            <Button label="Deletar" loading={loading} onClick={deletar} icon={null} className="p-button-danger" />
        </>
    );

    const handleClick = () => {
        if (exigeConfirmacao) {
            setVisible(true);
        } else {
            deletar();
        }
    };

    useImperativeHandle(ref, () => ({ handleClick }));

    return (
        <ConfirmDialog
            visible={visible}
            onHide={() => setVisible(false)}
            message={msgConfirmacao}
            header="Confirmação de exclusão"
            icon="pi pi-info-circle"
            accept={deletar}
            acceptLabel="Deletar"
            acceptClassName="p-button-danger"
            rejectLabel="Cancelar"
            footer={footer}
        />
    );
};

export const Delete = forwardRef(Componente);
