import React, { useCallback, useEffect, useRef, useState } from "react";
import classNames from "classnames";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { useFormik } from "formik";
import * as Yup from "yup";
import { axiosDelete, axiosGet, axiosPost } from "@/services/http";
import useLoading from "@/hooks/useLoading";
import { useHistory } from "react-router-dom";
import { TabPanel, TabView } from "primereact/tabview";
import permissoes from "@/assets/constants/permissoes";
import { Dropdown } from "primereact/dropdown";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import MakoListagem from "@/components/MakoListagem";
import { ConfirmDialog } from "primereact/confirmdialog";

const url = "/produtos/descricao-padrao/";

export const ProdutoDescricaoPadraoForm = (props) => {
    const [niveis, setNiveis] = useState([]);
    const [nivelAtivo, setNivelAtivo] = useState(0);
    const [ultimoNivel, setUltimoNivel] = useState([]);
    const [deleteDialog, setDeleteDialog] = useState(false);
    const [deleteNivel, setDeleteNivel] = useState(false);
    const [descricao, setDescricao] = useState(null);
    const { showLoading, hideLoading } = useLoading();
    const listagemRef = useRef(null);
    const toastRef = useRef(null);
    const history = useHistory();

    const descricaoVazia = {
        id: "",
        descricao: "",
    };

    const formik = useFormik({
        initialValues: props.location.state ? props.location.state.descricao : descricaoVazia,
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                descricao: Yup.string().required("O campo 'descrição' é obrigatório."),
                nivel: Yup.string().required("O campo 'nível' é obrigatório."),
            });

            await formSchema.validate(values, {
                abortEarly: false,
            });

            showLoading();
            const resposta = await axiosPost(`${url}`, values);
            hideLoading();

            if (resposta.status === 201) {
                toastRef.current.show({
                    severity: "success",
                    summary: "Sucesso",
                    detail: "Descrição cadastrada com sucesso!",
                    life: 1500,
                });

                formik.resetForm();
                listagemRef.current?.buscarDados();
            } else {
                toastRef.current.show({
                    severity: "error",
                    summary: "Erro",
                    detail: "Desculpe, não conseguimos processar a sua requisição.",
                    life: 1500,
                });
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};

                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });

                formik.setErrors(errorMessages);
                hideLoading();
            } else {
                toastRef.current.show({
                    severity: "error",
                    summary: "Erro",
                    detail: "Desculpe, não conseguimos processar a sua requisição.",
                    life: 1500,
                });
                hideLoading();
            }
        }
    }

    async function criarNivel() {
        try {
            showLoading();
            const resposta = await axiosPost("/produtos/niveis-padrao-descricao/", { nivel: ultimoNivel.nivel + 1 });
            hideLoading();

            if (resposta.status === 201) {
                toastRef.current.show({
                    severity: "success",
                    summary: "Sucesso",
                    detail: "Nível cadastrada com sucesso!",
                    life: 1500,
                });
                carregarNiveis();
                setUltimoNivel(resposta.data);
            } else {
                toastRef.current.show({
                    severity: "error",
                    summary: "Erro",
                    detail: "Desculpe, não conseguimos processar a sua requisição.",
                    life: 1500,
                });
            }
        } catch (error) {
            toastRef.current.show({
                severity: "error",
                summary: "Erro",
                detail: "Desculpe, não conseguimos processar a sua requisição.",
                life: 1500,
            });
            hideLoading();
        }
    }

    async function deletarNivel() {
        try {
            showLoading();
            const resposta = await axiosDelete(`/produtos/niveis-padrao-descricao/${ultimoNivel.id}/`);
            hideLoading();

            if (resposta.status === 204) {
                toastRef.current.show({
                    severity: "success",
                    summary: "Sucesso",
                    detail: "Nível deletado com sucesso!",
                    life: 1500,
                });
                carregarNiveis();
                carregarUltimoNivel();
            } else {
                toastRef.current.show({
                    severity: "error",
                    summary: "Erro",
                    detail: "Desculpe, não conseguimos processar a sua requisição.",
                    life: 1500,
                });
            }
        } catch (error) {
            toastRef.current.show({
                severity: "error",
                summary: "Erro",
                detail: "Desculpe, não conseguimos processar a sua requisição.",
                life: 1500,
            });
            hideLoading();
        }
    }

    const deleteDescricao = async () => {
        showLoading();
        const resposta = await axiosDelete(`${url}${descricao.id}`);
        hideLoading();

        if (resposta.status === 200 || resposta.status === 204) {
            toastRef.current.show({
                severity: "success",
                summary: "Sucesso",
                detail: "Descrição deletada com sucesso!",
                life: 3000,
            });

            listagemRef.current?.buscarDados();
        } else {
            toastRef.current.show({
                severity: "error",
                summary: "Erro",
                detail: "Desculpe, não conseguimos processar a sua requisição.",
                life: 3000,
            });
            hideLoading();
        }
        setDeleteDialog(false);
    };

    const confirmarDelete = (descricao) => {
        setDescricao(descricao);
        setDeleteDialog(true);
    };

    const confirmarNivelDelete = () => {
        setDeleteNivel(true);
    };

    const carregarNiveis = useCallback(async () => {
        showLoading();
        const resposta = await axiosGet("/produtos/niveis-padrao-descricao?ordering=id&limit=50");
        hideLoading();

        if (resposta.status === 200) {
            setNiveis(resposta.data.results);
        }
    }, [showLoading, hideLoading]);

    const carregarUltimoNivel = useCallback(async () => {
        showLoading();
        const resposta = await axiosGet("/produtos/niveis-padrao-descricao?ordering=-id&limit=1");
        hideLoading();

        if (resposta.status === 200) {
            if (resposta.data.results?.length > 0) setUltimoNivel(resposta.data.results[0]);
            else setUltimoNivel({ nivel: 0 });
        }
    }, [showLoading, hideLoading]);

    useEffect(() => {
        carregarNiveis();
        carregarUltimoNivel();
    }, [carregarNiveis, carregarUltimoNivel]);

    const cancelar = () => {
        formik.resetForm();
        history.push("/");
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <MakoControleAcesso
                    permissao={[permissoes.PRODUTO_CADASTRO_DESCRICAOPADRAO_EXCLUIR]}
                    componente={Button}
                    icon="pi pi-trash"
                    className="p-button-rounded p-button-danger p-mr-2 p-mb-1"
                    onClick={() => confirmarDelete(rowData)}
                    tooltip="Deletar descrição"
                    tooltipOptions={{ position: "left" }}
                />
            </div>
        );
    };

    const colunas = [
        { field: "id", header: "Código", style: { width: "15%" } },
        { field: "descricao", header: "Descrição" },
        {
            field: "action",
            header: "Ações",
            action: (e) => actionBodyTemplate(e),
            style: { width: "15%" },
        },
    ];

    const colunasDinamicas = niveis.map((nivel, index) => {
        return (
            <TabPanel key={index} header={`Nível ${nivel.nivel}`} leftIcon="pi pi-circle-fill">
                <MakoListagem
                    ref={listagemRef}
                    colunas={colunas}
                    urlPesquisa={`/produtos/descricao-padrao?nivel=${nivel.id}`}
                    responsiva
                    configTabela={{
                        paginator: true,
                        lazy: true,
                    }}
                />
            </TabPanel>
        );
    });

    return (
        <div className="p-grid">
            <Toast ref={toastRef} />
            <div className="p-col-12">
                <div className="card">
                    <h3>Descrição Padronizada</h3>
                    <form onSubmit={formik.handleSubmit}>
                        <div className="p-fluid p-formgrid p-grid">
                            <div className="p-field p-col-12 p-md-8">
                                <label htmlFor="descricao">Descrição *</label>
                                <InputText
                                    id="descricao"
                                    name="descricao"
                                    value={formik.values.descricao}
                                    keyfilter={/^[a-zA-Z0-9}{)(/#$@!%&*-*+]/}
                                    onChange={formik.handleChange}
                                    className={classNames({ "p-invalid": formik.errors.descricao })}
                                    autoComplete="off"
                                    autoFocus
                                />
                                {formik.errors.descricao && (
                                    <small className="p-error">{formik.errors.descricao}</small>
                                )}
                            </div>
                            <div className="p-field p-col-12 p-md-4">
                                <label htmlFor="nivel">Selecione um nível: *</label>
                                <div className="p-inputgroup">
                                    <Dropdown
                                        id="nivel"
                                        name="nivel"
                                        placeholder="Selecione um nivel"
                                        onChange={formik.handleChange}
                                        value={formik.values.nivel}
                                        showClear
                                        optionValue="id"
                                        optionLabel="nivel"
                                        options={niveis}
                                        autoComplete="off"
                                        autoFocus
                                    />
                                    <MakoControleAcesso
                                        permissao={[permissoes.PRODUTO_CADASTRO_NIVELDESCRICAOPADRAO_INCLUIR]}
                                        componente={Button}
                                        type="button"
                                        icon="pi pi-plus"
                                        className="p-button-success"
                                        onClick={() => criarNivel()}
                                    />
                                    <MakoControleAcesso
                                        permissao={[permissoes.PRODUTO_CADASTRO_NIVELDESCRICAOPADRAO_EXCLUIR]}
                                        componente={Button}
                                        type="button"
                                        icon="pi pi-minus"
                                        className="p-button-danger"
                                        onClick={() => confirmarNivelDelete()}
                                    />
                                </div>
                                {formik.errors.nivel && <small className="p-error">{formik.errors.nivel}</small>}
                            </div>
                        </div>
                        <p>
                            <b>* Campos obrigatórios.</b>
                        </p>

                        <div className="p-grid p-col-12">
                            <Button label="Adicionar" type="submit" className="p-button-info p-mr-2" />
                            <Button label="Voltar" type="reset" className="p-button-danger p-mr-2" onClick={cancelar} />
                        </div>
                    </form>
                    <TabView
                        className="tabview-custom"
                        activeIndex={nivelAtivo}
                        onTabChange={(e) => setNivelAtivo(e.index)}
                        type={"button"}
                    >
                        {colunasDinamicas ? colunasDinamicas : null}
                    </TabView>
                </div>
            </div>
            <ConfirmDialog
                visible={deleteDialog}
                onHide={() => setDeleteDialog(false)}
                header="Confirmação"
                message={
                    descricao && (
                        <span>
                            {"Deseja realmente excluir a descrição "}
                            <b>{descricao.descricao}</b>?
                        </span>
                    )
                }
                icon="pi pi-info-circle p-mr-3"
                accept={deleteDescricao}
                acceptLabel="Sim"
                acceptClassName="p-button-danger"
                reject={() => setDeleteDialog(false)}
                rejectLabel="Não"
            />
            <ConfirmDialog
                visible={deleteNivel}
                onHide={() => setDeleteNivel(false)}
                header="Confirmação"
                message={
                    ultimoNivel && (
                        <span>
                            {"Deseja realmente excluir o nível "}
                            <b>{ultimoNivel.nivel}</b>?
                        </span>
                    )
                }
                icon="pi pi-info-circle p-mr-3"
                accept={() => deletarNivel()}
                acceptLabel="Sim"
                acceptClassName="p-button-danger"
                reject={() => setDeleteNivel(false)}
                rejectLabel="Não"
            />
        </div>
    );
};
