import React, { useCallback, useMemo, useState } from "react";

import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import { MakoButton } from "@/components/MakoButton";

import permissoes from "@/assets/constants/permissoes";

import useCaixaMovimento from "@/hooks/useCaixaMovimento";
import useLoadingLocal from "@/hooks/useLoadingLocal";
import useToast, { DEFAULT_ERROR_TOAST } from "@/hooks/useToast";
import useHttp from "@/hooks/useHttp";
import { MakoConfirmDialog } from "@/components/MakoConfirmDialog";

const { FINANCEIRO_FINANCEIRO_CAIXAMOV_PENDENCIAS_PROCESSAR } = permissoes;

const BASE_URL = "/financeiro/caixas/{caixa}/pendencias/{pendencia}/processar";

export const ConfirmarCrediario = ({ pendencia, onConfirm = () => {} }) => {
    const [loading, show, hide] = useLoadingLocal();
    const [visible, setVisible] = useState(false);
    const { showSuccess, showError } = useToast();
    const { caixaMov } = useCaixaMovimento();
    const { httpPost } = useHttp();

    const handler = useCallback(async () => {
        show();
        const url = BASE_URL.replace("{caixa}", caixaMov?.id).replace("{pendencia}", pendencia?.id);
        const body = { tipo_chave: pendencia.tipo_chave };
        const handlers = {
            200: () => {
                showSuccess({
                    summary: "Sucesso!",
                    detail: "Crédiario confirmado com sucesso!",
                    life: 1500,
                });
                onConfirm();
            },
            400: ({ err }) => {
                const { msg } = err || {};
                const message = msg || DEFAULT_ERROR_TOAST.detail;
                showError({
                    ...DEFAULT_ERROR_TOAST,
                    detail: message,
                });
            },
        };
        await httpPost({ url, body }, handlers);
        hide();
    }, [show, caixaMov?.id, pendencia, httpPost, hide, showSuccess, onConfirm, showError]);

    const message = useMemo(() => {
        return `O carnê da pendencia '${pendencia?.descricao}' ja foi emitido?`;
    }, [pendencia]);

    return (
        <>
            <MakoControleAcesso
                componente={MakoButton}
                permissao={[FINANCEIRO_FINANCEIRO_CAIXAMOV_PENDENCIAS_PROCESSAR]}
                label="Confirmar crediário"
                className="p-button p-button-warning p-py-1 p-text-bold"
                type="button"
                loading={loading}
                onClick={() => setVisible(true)}
                disabled={caixaMov?.bloqueado}
            />
            <MakoConfirmDialog
                visible={visible}
                setVisible={setVisible}
                showErrorToast={false}
                message={message}
                accept={handler}
            />
        </>
    );
};
