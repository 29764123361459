import React, { createContext, useCallback, useState } from "react";
import { axiosGet, axiosPatch, axiosPost } from "@/services/http";
import useEmpresa from "@/hooks/useEmpresa";
import * as pd from "@/assets/util/persistenciaDjango";
import useToast from "@/hooks/useToast";

const NotaFiscalContext = createContext({});

export const NotaFiscalProvider = ({ children }) => {
    const [submit, setSubmit] = useState(false);
    const [notaFiscal, setNotaFiscal] = useState({});
    const [dadosBasicos, setDadosBasicos] = useState(null);
    const [activeTab, setActiveTab] = useState(0);
    const { empresaSelecionada } = useEmpresa();

    const { showError } = useToast();

    const handleDados = useCallback(
        (dados) => {
            setNotaFiscal({
                ...notaFiscal,
                ...dados,
            });
            setSubmit(true);
        },
        [notaFiscal, setNotaFiscal]
    );

    const handleDadosBasicos = useCallback(
        async (values) => {
            if (!values.id) {
                const { status, data } = await axiosPost("/fiscal/notas-fiscais/", {
                    ...values,
                    natureza_operacao: values.cfop.codigo,
                    cfop: values.cfop.id,
                    destinatario: values.destinatario.id,
                    municipio_ocorrencia: values.municipio_ocorrencia.id,
                    emitente: empresaSelecionada.id,
                });
                if (status === 201) setSubmit(true);
                setDadosBasicos(data);
                return { status, data };
            } else {
                let diffProd = {};
                Object.entries(values).forEach(([k, v]) => {
                    if (v !== dadosBasicos[k]) {
                        diffProd[k] = v;
                    }
                });
                if (diffProd.cfop)
                    diffProd = {
                        ...diffProd,
                        natureza_operacao: diffProd.cfop?.codigo,
                        cfop: diffProd.cfop?.id,
                    };
                if (diffProd.transportador?.id)
                    diffProd = {
                        ...diffProd,
                        transportador: diffProd.transportador?.id,
                    };
                if (diffProd.destinatario?.id)
                    diffProd = {
                        ...diffProd,
                        destinatario: diffProd.destinatario?.id,
                    };
                if (Object.keys(diffProd).length > 0) {
                    const { status, data } = await axiosPatch(`/fiscal/notas-fiscais/${values.id}/`, diffProd);
                    if (status === 200)
                        setDadosBasicos({ ...data, marca: data.marca?.id || null, cest: data.cest?.id || null });
                }
                return { status: 200, data: values };
            }
        },
        [dadosBasicos, empresaSelecionada]
    );

    const handleItem = useCallback(
        async (values, op) => {
            if (op === pd.OP_CRUD_DJANGO.novo) {
                const { status, data } = await axiosPost("/fiscal/itens-notas-fiscais/", {
                    ...values,
                    item: values.item?.id,
                    nota_fiscal: dadosBasicos?.id,
                    codigo_item: values.item?.codigo,
                    codigo_barras: values.codigo_barras || "SEM GTIN",
                    descricao_reduzida: values.item?.descricao_reduzida,
                    descricao_derivada: values.item?.descricao_derivada || values.item?.descricao_complementar,
                    municipio_incidencia: values.municipio_incidencia.id,
                    municipio_issqn: values.municipio_issqn.id,
                });
                if (status === 201) return { status, data };
                else {
                    showError({
                        summary: "Erro",
                        detail: "Desculpe, não foi possível inserir o item na nota fiscal.",
                        life: 3000,
                    });
                    return { status: 400, data: data };
                }
            } else if (op === pd.OP_CRUD_DJANGO.editar) {
                const { status, data } = await axiosPatch(`/fiscal/itens-notas-fiscais/${values.id}/`, {
                    ...values,
                    codigo_barras: values.codigo_barras || "SEM GTIN",
                    municipio_incidencia: values.municipio_incidencia.id,
                    municipio_issqn: values.municipio_issqn.id,
                    item: values.item?.id,
                });
                if (status === 200) return { status, data };
                else {
                    showError({
                        summary: "Erro",
                        detail: "Desculpe, não foi possível editar o item da nota fiscal.",
                        life: 3000,
                    });
                    return { status: 400, data: data };
                }
            }
        },
        [dadosBasicos?.id, showError]
    );

    const handleCobranca = useCallback(
        async (url, values) => {
            const { status, data } = await axiosPost(url, {
                nota_fiscal: dadosBasicos.id,
                ...values,
            });
            if (status === 201) return { status, data };
            else {
                showError({
                    summary: "Erro",
                    detail: "Desculpe, não foi possível inserir a duplicata.",
                    life: 3000,
                });
                return { status: 400, data: data };
            }
        },
        [showError, dadosBasicos?.id]
    );

    const handlePreencherNotaFiscal = useCallback(async (idNotaFiscal) => {
        const { status, data } = await axiosGet(`/fiscal/notas-fiscais/${idNotaFiscal}/`);
        if (status === 200) {
            setDadosBasicos(await data);
            setSubmit(true);
        }
    }, []);

    return (
        <NotaFiscalContext.Provider
            value={{
                submit,
                dadosBasicos,
                notaFiscal,
                activeTab,
                setActiveTab,
                setNotaFiscal,
                setSubmit,
                handleDados,
                handleDadosBasicos,
                handleItem,
                handleCobranca,
                handlePreencherNotaFiscal,
            }}
        >
            {children}
        </NotaFiscalContext.Provider>
    );
};

export default NotaFiscalContext;
