import React, { useState, useImperativeHandle, forwardRef } from "react";
import classNames from "classnames";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Checkbox } from "primereact/checkbox";
import { Button } from "primereact/button";
import { useFormik } from "formik";
import * as Yup from "yup";

import { MakoCalendar } from "@/components/MakoCalendar";
import { MakoInputMoeda } from "@/components/MakoInputMoeda";
import usePagamento from "@/hooks/usePagamento";

const EditarPagamentoModalForm = (props, ref) => {
    const [visible, setVisible] = useState(false);
    const { handleEditarPagamento } = usePagamento();

    const formik = useFormik({
        initialValues: {
            index: 0,
            vencimento: null,
            valor: 0,
            historico_original: "",
            previsao: false,
        },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                vencimento: Yup.date()
                    .required("O campo 'vencimento' é obrigatório.")
                    .typeError("Informe uma data válida."),
                valor: Yup.number()
                    .required("O campo 'valor' é obrigatório.")
                    .min(0.01, "Esse campo não pode ser ZERO."),
                historico_original: Yup.string().required("O campo 'histórico' é obrigatório."),
                previsao: Yup.boolean().default(false),
            });

            await formSchema.validate(values, {
                abortEarly: false,
            });
            await handleEditarPagamento(values, values.index);
            formik.resetForm();
            setVisible(false);
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                const errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            }
        }
    }

    const abrirModal = (pagamento) => {
        if (pagamento) formik.setValues(pagamento);
        setVisible(true);
    };

    useImperativeHandle(ref, () => ({ abrirModal }));

    return (
        <Dialog header="Editar pagamento" visible={visible} onHide={() => setVisible(false)} style={{ width: "60vw" }}>
            <form onSubmit={formik.handleSubmit}>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="vencimento">Vencimento</label>
                        <MakoCalendar
                            id="vencimento"
                            name="vencimento"
                            valueCalendar={formik.values.vencimento}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.vencimento })}
                        />
                        {formik.errors.vencimento && <small className="p-error">{formik.errors.vencimento}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="valor">Valor</label>
                        <MakoInputMoeda
                            id="valor"
                            name="valor"
                            min={0}
                            valueMoeda={formik.values.valor}
                            onChangeMoeda={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.valor })}
                        />
                        {formik.errors.valor && <small className="p-error">{formik.errors.valor}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-7">
                        <label htmlFor="historico-original">Histórico</label>
                        <InputText
                            id="historico-original"
                            name="historico_original"
                            value={formik.values.historico_original}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.historico_original })}
                        />
                        {formik.errors.historico_original && (
                            <small className="p-error">{formik.errors.historico_original}</small>
                        )}
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field-checkbox p-col-12 p-md-3">
                        <Checkbox
                            id="previsao"
                            name="previsao"
                            checked={formik.values.previsao}
                            onChange={formik.handleChange}
                        />
                        <label htmlFor="previsao">Gerar previsão?</label>
                    </div>
                </div>
                <div className="p-grid p-col-12 p-md-2 p-mt-1">
                    <Button type="submit" icon="pi pi-pencil" label="Gravar" />
                </div>
            </form>
        </Dialog>
    );
};

export const ModalEditarPagamento = forwardRef(EditarPagamentoModalForm);
