import React, { useCallback, useEffect, useState } from "react";

import { useFormik } from "formik";
import classNames from "classnames";
import * as Yup from "yup";

import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";

import { MakoInputCliente } from "@/components/MakoInputs/MakoInputCliente";
import { MakoInputPercent } from "@/components/MakoInputs/MakoInputPercent";
import { MakoCalendar } from "@/components/MakoCalendar";
import { Dropdown } from "@/components/Dropdown";

import { TIPO_CHOICE_CONTRATOSERVICO__INTERVALOREC, TIPO_STATUS_CONTRATO_CHOICE } from "@/assets/constants/constants";
import { gerarStringAleatoria } from "@/assets/util/util";
import { axiosGet } from "@/services/http";

import useContratoServico from "@/hooks/useContratoServico";
import useEmpresa from "@/hooks/useEmpresa";
import useLoading from "@/hooks/useLoading";
import useToast from "@/hooks/useToast";
import axios from "axios";
import { getDate } from "date-fns";
import { parseData } from "@/assets/util/datas";

const FormDadosBasicos = () => {
    const [vendedores, setVendedores] = useState([]);
    const { contrato, updateContrato } = useContratoServico();
    const { showLoading, hideLoading } = useLoading();
    const { empresaSelecionadaId } = useEmpresa();
    const { showError } = useToast();

    const { setValues, setFieldValue, ...formik } = useFormik({
        initialValues: {
            empresa: empresaSelecionadaId,
            numero: "",
            nome_curto: "",
            descricao: "",
            cliente: null,
            data_negociacao: null,
            data_iniciacao: new Date(),
            data_finalizacao: null,
            vendedor: null,
            percentual_comissao: 0,
            primeiro_vencimento: null,
            dia_vencimento: null,
            intervalo_recebimento: null,
            link_documento: "",
            template_rateio: null,
            status: "P",
        },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                numero: Yup.string()
                    .max(9, "Máximo de caracteres atingido: 9")
                    .required("O campo 'numero' é obrigatório")
                    .typeError("Informe um 'numero' válido"),
                nome_curto: Yup.string()
                    .max(30, "Máximo de caracteres atingido: 30")
                    .required("O campo 'numero' é obrigatório")
                    .typeError("Informe um 'numero' válido"),
                descricao: Yup.string()
                    .required("O campo 'descricao' é obrigatório")
                    .typeError("Informe um 'descricao' válido"),
                cliente: Yup.object()
                    .shape({
                        id: Yup.number().required("O campo 'cliente' é obrigatório"),
                    })
                    .typeError("Informe um 'cliente' válida"),
                data_negociacao: Yup.date().nullable().typeError("Informe uma 'negociação' válida"),
                data_iniciacao: Yup.date()
                    .required("O campo 'data inicicao' é obrigatório")
                    .typeError("Informe uma 'data iniciação' válida"),
                data_finalizacao: Yup.date().when("data_iniciacao", {
                    is: (val) => !!val,
                    then: Yup.date()
                        .min(
                            values.data_iniciacao || new Date(),
                            "O campo 'data finalização' não pode ser anterior a inicial"
                        )
                        .required("O campo 'data finalizacao' é obrigatório")
                        .typeError("Informe uma 'data finalização' válida"),
                }),
                vendedor: Yup.number()
                    .required("O campo 'vendedor' é obrigatório.")
                    .typeError("Informe um 'vendedor' válido."),
                percentual_comissao: Yup.number()
                    .required("O campo 'percentual comissão' é obrigatório.")
                    .typeError("Informe um 'percentual comissão' válido"),
                primeiro_vencimento: Yup.date()
                    .required("O campo '1° vencimento' é obrigatório")
                    .typeError("Informe uma 'primeiro vencimento' válida"),
                intervalo_recebimento: Yup.number()
                    .required("O campo 'intervalo de recebimento' é obrigatório.")
                    .typeError("Informe um 'intervalo de recebimento' válido"),
                template_rateio: Yup.number()
                    .required("O campo 'template de ratio' é obrigatório.")
                    .typeError("Informe uma 'template de ratio' válida."),
                link_documento: Yup.string().nullable().typeError("Informe um 'descricao' válido"),
            });
            await formSchema.validate(values, {
                abortEarly: false,
            });

            updateContrato({
                ...values,
                dia_vencimento: values.primeiro_vencimento
                    ? getDate(values.primeiro_vencimento) || getDate(parseData(values.primeiro_vencimento))
                    : null,
            });
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            } else {
                showError({
                    summary: "Erro :(",
                    detail: "Desculpe, a requisição não pode ser concluída.",
                    life: 3000,
                });
            }
        }
    }

    function gerarCodigo() {
        setFieldValue("numero", gerarStringAleatoria(9));
    }

    const buscarVendedores = useCallback(
        async (cancelToken) => {
            const params = {
                usuario__isnull: false,
                query: "{id,perfil_pf,papeis_vigentes}",
                limit: 200,
            };
            showLoading();
            const { status, data } = await axiosGet("/pessoas/perfis/", { params, cancelToken });
            hideLoading(false);
            let _vendedores = [];
            if (status === 200) {
                data.results.forEach((perfil) => {
                    perfil.papeis_vigentes.forEach((papel) => {
                        if (papel.chave?.id === "VND" || papel.chave?.id === "ATD") {
                            _vendedores.push({
                                id: perfil.id,
                                nome: perfil.perfil_pf.nome_completo,
                            });
                        }
                    });
                });
                setVendedores(_vendedores);
            } else {
                showError({
                    summary: "Erro :(",
                    detail: "Desculpe, não conseguimos listar os vendedores.",
                    life: 3000,
                });
            }
        },
        [hideLoading, showLoading, setFieldValue, showError]
    );

    const handleReset = useCallback(() => {
        const contratoId = formik.values?.id;
        formik.resetForm();
        if (contratoId) setFieldValue("id", contratoId);
    }, []);

    useEffect(() => {
        if (contrato) setValues({ ...contrato });
    }, [contrato]);

    useEffect(() => {
        const cancelToken = axios.CancelToken.source();
        buscarVendedores(cancelToken.token);
        return function clear() {
            cancelToken.cancel();
        };
    }, [buscarVendedores]);

    return (
        <div>
            <form onSubmit={formik.handleSubmit}>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="numero">N° do contrato *</label>
                        <div className="p-inputgroup">
                            <InputText
                                id="numero"
                                name="numero"
                                onChange={formik.handleChange}
                                value={formik.values.numero}
                                autoComplete="off"
                                disabled={contrato?.status === "F"}
                                maxLength={8}
                                className={classNames({ "p-invalid": formik.errors.numero })}
                            />
                            <Button
                                type="button"
                                icon="pi pi-refresh"
                                className="p-button-info"
                                tooltip="Gerar código aleatório"
                                disabled={contrato?.status === "F"}
                                onClick={gerarCodigo}
                            />
                        </div>
                        {formik.errors.numero && <small className="p-error">{formik.errors.numero}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="nome_curto">Nome curto *</label>
                        <InputText
                            id="nome_curto"
                            name="nome_curto"
                            maxLength={30}
                            value={formik.values.nome_curto}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.nome_curto })}
                        />
                        {formik.errors.nome_curto && <small className="p-error">{formik.errors.nome_curto}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-8">
                        <label htmlFor="descricao">Descrição do contrato(Titulo) *</label>
                        <InputText
                            id="descricao"
                            name="descricao"
                            value={formik.values.descricao}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.descricao })}
                        />
                        {formik.errors.descricao && <small className="p-error">{formik.errors.descricao}</small>}
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="cliente">Cliente *</label>
                        <MakoInputCliente
                            id="cliente"
                            name="cliente"
                            value={formik.values.cliente}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.cliente })}
                            disabled={contrato?.status === "F"}
                        />
                        {formik.errors.cliente && <small className="p-error">{formik.errors.cliente}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="template_rateio">Regra de rateio *</label>
                        <Dropdown
                            id="template_rateio"
                            name="template_rateio"
                            url={`/financeiro/templates-rateios/?query={id,descricao}`}
                            optionValue="id"
                            optionLabel="descricao"
                            emptyMessage="Não existem registros cadastrados"
                            value={formik.values.template_rateio}
                            onChange={formik.handleChange}
                            disabled={contrato?.status === "F"}
                            className={classNames({ "p-invalid": formik.errors.template_rateio })}
                        />
                        {formik.errors.template_rateio && (
                            <small className="p-error">{formik.errors.template_rateio}</small>
                        )}
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="data_negociacao">Data negociação </label>
                        <MakoCalendar
                            id="data_negociacao"
                            name="data_negociacao"
                            valueCalendar={formik.values.data_negociacao}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.data_negociacao })}
                        />
                        {formik.errors.data_negociacao && (
                            <small className="p-error">{formik.errors.data_negociacao}</small>
                        )}
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="data_iniciacao">Data Inicio *</label>
                        <MakoCalendar
                            id="data_iniciacao"
                            name="data_iniciacao"
                            valueCalendar={formik.values.data_iniciacao}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.data_iniciacao })}
                        />
                        {formik.errors.data_iniciacao && (
                            <small className="p-error">{formik.errors.data_iniciacao}</small>
                        )}
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="data_finalizacao">Previsão encerramento *</label>
                        <MakoCalendar
                            id="data_finalizacao"
                            name="data_finalizacao"
                            valueCalendar={formik.values.data_finalizacao}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.data_finalizacao })}
                        />
                        {formik.errors.data_finalizacao && (
                            <small className="p-error">{formik.errors.data_finalizacao}</small>
                        )}
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-4">
                        <label htmlFor="vendedor">Vendedor *</label>
                        <Dropdown
                            id="vendedor"
                            name="vendedor"
                            placeholder="Selecione"
                            options={vendedores}
                            optionValue="id"
                            optionLabel="nome"
                            filter
                            filterBy="nome"
                            disabled={contrato?.status === "F"}
                            value={formik.values.vendedor}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.vendedor })}
                        />
                        {formik.errors.vendedor && <small className="p-error">{formik.errors.vendedor}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="percentual_comissao">% comissão *</label>
                        <MakoInputPercent
                            id="percentual_comissao"
                            name="percentual_comissao"
                            onValueChange={formik.handleChange}
                            value={formik.values.percentual_comissao}
                            disabled={contrato?.status === "F"}
                            className={classNames({ "p-invalid": formik.errors.percentual_comissao })}
                        />
                        {formik.errors.percentual_comissao && (
                            <small className="p-error">{formik.errors.percentual_comissao}</small>
                        )}
                    </div>
                    <div className="p-field p-col-12 p-md-4">
                        <label htmlFor="intervalo_recebimento">Intervalo de recebimento do contrato *</label>
                        <Dropdown
                            id="intervalo_recebimento"
                            name="intervalo_recebimento"
                            placeholder="Selecione"
                            options={TIPO_CHOICE_CONTRATOSERVICO__INTERVALOREC}
                            optionValue="value"
                            optionLabel="label"
                            disabled={contrato?.status === "F"}
                            value={formik.values.intervalo_recebimento}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.intervalo_recebimento })}
                        />
                        {formik.errors.intervalo_recebimento && (
                            <small className="p-error">{formik.errors.intervalo_recebimento}</small>
                        )}
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="primeiro_vencimento">1° vencimento * </label>
                        <MakoCalendar
                            id="primeiro_vencimento"
                            name="primeiro_vencimento"
                            valueCalendar={formik.values.primeiro_vencimento}
                            onChange={formik.handleChange}
                            // disabled={contrato?.status === "F"}
                            className={classNames({ "p-invalid": formik.errors.primeiro_vencimento })}
                        />
                        {formik.errors.primeiro_vencimento && (
                            <small className="p-error">{formik.errors.primeiro_vencimento}</small>
                        )}
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-10">
                        <label htmlFor="link_documento">Link do documento do contrato </label>
                        <InputText
                            id="link_documento"
                            name="link_documento"
                            value={formik.values.link_documento}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.link_documento })}
                        />
                        {formik.errors.link_documento && (
                            <small className="p-error">{formik.errors.link_documento}</small>
                        )}
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="status">Status</label>
                        <Dropdown
                            id="status"
                            disabled
                            name="status"
                            placeholder="Selecione"
                            options={TIPO_STATUS_CONTRATO_CHOICE}
                            optionValue="value"
                            optionLabel="label"
                            value={formik.values.status}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.status })}
                        />
                        {formik.errors.status && <small className="p-error">{formik.errors.status}</small>}
                    </div>
                </div>
                <p>
                    <b>* Campos obrigatórios</b>
                </p>
                <p className="p-error">* Lembre-se de gravar os dados antes de prosseguir ou finalizar</p>
                <div className="p-grid">
                    <div className="p-col-12 p-md-6">
                        <Button
                            type="submit"
                            icon="pi pi-save"
                            label={!contrato?.id ? "Incluir dados básicos" : "Grava dados básicos"}
                            className="p-mr-2 p-mb-2"
                        />
                        <Button
                            type="reset"
                            icon="pi pi-trash"
                            label="Limpar"
                            className="p-button-warning p-mr-2 p-mb-2"
                            onClick={handleReset}
                        />
                    </div>
                </div>
            </form>
        </div>
    );
};

export default React.memo(FormDadosBasicos);
