import React, { useCallback, useEffect, useState } from "react";
import { InputText } from "primereact/inputtext";
import { axiosGet } from "@/services/http";
import { Button } from "primereact/button";

export const MakoItemNcm = ({ ncmItem, ...props }) => {
    const [ncm, setNcm] = useState(null);
    const [loading, setLoading] = useState(false);

    const carregaNcm = useCallback(async () => {
        setLoading(true);
        const resposta = await axiosGet(`/fiscal/ncm-descricao-cumulativa/${ncmItem}/`);
        setLoading(false);
        if (resposta.status === 200) setNcm(resposta.data.ncm_descricao);
    }, [ncmItem]);

    useEffect(() => {
        carregaNcm();
    }, [carregaNcm, ncmItem]);

    return (
        <>
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12 p-md-12">
                    <label>NCM</label>
                    <div className="p-inputgroup">
                        <InputText id="ncm" name="ncm" value={ncm} onChange={(e) => setNcm(e.target.value)} disabled />
                        <Button
                            type="button"
                            icon="pi pi-search"
                            loading={loading}
                            className="p-button-help"
                            tooltip={ncm}
                            tooltipOptions={{ position: "left" }}
                            disabled={!ncm}
                        />
                    </div>
                </div>
            </div>
        </>
    );
};
