import React, { createContext, useCallback, useState } from "react";

import { axiosGet, axiosPost } from "@/services/http";

import useEmpresa from "@/hooks/useEmpresa";
import useAuth from "@/hooks/useAuth";
import useToast from "@/hooks/useToast";

const DevolucaoContext = createContext({});

export const DevolucaoProvider = ({ children }) => {
    const [dadosBasicos, setDadosBasicos] = useState(null);
    const [submit, setSubmit] = useState(false);
    const { user } = useAuth();
    const { showError } = useToast();
    const { empresaSelecionadaId } = useEmpresa();

    const handleDadosBasicos = useCallback(
        async (values) => {
            if (!values.id) {
                const novaDevolucao = {
                    ...values,
                    empresa: empresaSelecionadaId,
                    cliente: values.cliente.id,
                    incluido_por: user?.id,
                    venda: values.venda.id,
                };
                const { status, data: dataResponse } = await axiosPost("/vendas/devolucao/", novaDevolucao);
                let data = null;
                if (status === 201) {
                    data = { ...dataResponse };
                    setDadosBasicos(data);
                    setSubmit(true);
                }
                return { status, data };
            }
        },
        [empresaSelecionadaId, user]
    );

    const buscarDevolucao = useCallback(
        async (devolucaoId) => {
            const { status, data } = await axiosGet(`/vendas/devolucao/${devolucaoId}/`);
            if (status === 200) {
                setDadosBasicos(data);
                setSubmit(true);
            } else {
                showError({
                    summary: "Erro :(",
                    detail: "Desculpe, não foi possível localizar a devolução.",
                    life: 3000,
                });
            }
        },
        [showError]
    );

    const atualizarTotalDevolucao = useCallback(async () => {
        if (dadosBasicos) {
            const { status, data } = await axiosGet(`/vendas/devolucao/${dadosBasicos.id}/?query={valor_total}`);
            if (status === 200) {
                setDadosBasicos((old) => ({ ...old, valor_total: data.valor_total }));
            }
        }
    }, [dadosBasicos]);

    return (
        <DevolucaoContext.Provider
            value={{
                dadosBasicos,
                submit,
                handleDadosBasicos,
                buscarDevolucao,
                atualizarTotalDevolucao,
            }}
        >
            {children}
        </DevolucaoContext.Provider>
    );
};

export default DevolucaoContext;
