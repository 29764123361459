import React, { useRef } from "react";

import { InputNumber } from "primereact/inputnumber";
import { Toast } from "primereact/toast";
import useNotaFiscal from "@/hooks/useNotaFiscal";

export const TotaisForm = () => {
    const toastRef = useRef(null);
    const { dadosBasicos } = useNotaFiscal();

    return (
        <div className="p-grid">
            <div className="p-col-12">
                <Toast ref={toastRef} />
                <div className="card">
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-12 p-md-3">
                            <label htmlFor="base_icms">Base Icms</label>
                            <InputNumber
                                id="base_icms"
                                name="base_icms"
                                mode="currency"
                                currency="BRL"
                                locale="pt-BR"
                                disabled
                                value={dadosBasicos.base_icms}
                            />
                        </div>
                        <div className="p-field p-col-12 p-md-3">
                            <label htmlFor="valor_icms_calculado">Icms calulado</label>
                            <InputNumber
                                id="valor_icms_calculado"
                                name="valor_icms_calculado"
                                mode="currency"
                                currency="BRL"
                                locale="pt-BR"
                                disabled
                                value={dadosBasicos.valor_icms_calculado}
                            />
                        </div>
                        <div className="p-field p-col-12 p-md-3">
                            <label htmlFor="valor_icms_desoneracao">Icms desoneração</label>
                            <InputNumber
                                id="valor_icms_desoneracao"
                                name="valor_icms_desoneracao"
                                mode="currency"
                                currency="BRL"
                                locale="pt-BR"
                                disabled
                                value={dadosBasicos.valor_icms_desoneracao}
                            />
                        </div>
                        <div className="p-field p-col-12 p-md-3">
                            <label htmlFor="base_sub">Base ST.</label>
                            <InputNumber
                                id="base_sub"
                                name="base_sub"
                                mode="currency"
                                currency="BRL"
                                locale="pt-BR"
                                disabled
                                value={dadosBasicos.base_sub}
                            />
                        </div>
                    </div>
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-12 p-md-3">
                            <label htmlFor="valor_sub">Valor ST.</label>
                            <InputNumber
                                id="valor_sub"
                                name="valor_sub"
                                mode="currency"
                                currency="BRL"
                                locale="pt-BR"
                                disabled
                                value={dadosBasicos.valor_sub}
                            />
                        </div>
                        <div className="p-field p-col-12 p-md-3">
                            <label htmlFor="valor_produto">Valor produtos</label>
                            <InputNumber
                                id="valor_produto"
                                name="valor_produto"
                                mode="currency"
                                currency="BRL"
                                locale="pt-BR"
                                disabled
                                value={dadosBasicos.valor_produto}
                            />
                        </div>
                        <div className="p-field p-col-12 p-md-3">
                            <label htmlFor="valor_frete">Valor frete</label>
                            <InputNumber
                                id="valor_frete"
                                name="valor_frete"
                                mode="currency"
                                currency="BRL"
                                locale="pt-BR"
                                disabled
                                value={dadosBasicos.valor_frete}
                            />
                        </div>
                        <div className="p-field p-col-12 p-md-3">
                            <label htmlFor="valor_seguro">Valor seguro</label>
                            <InputNumber
                                id="valor_seguro"
                                name="valor_seguro"
                                mode="currency"
                                currency="BRL"
                                locale="pt-BR"
                                disabled
                                value={dadosBasicos.valor_seguro}
                            />
                        </div>
                    </div>
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-12 p-md-3">
                            <label htmlFor="valor_desconto">Valor desconto</label>
                            <InputNumber
                                id="valor_desconto"
                                name="valor_desconto"
                                mode="currency"
                                currency="BRL"
                                locale="pt-BR"
                                disabled
                                value={dadosBasicos.valor_desconto}
                            />
                        </div>
                        <div className="p-field p-col-12 p-md-3">
                            <label htmlFor="valor_ii">Valor II.</label>
                            <InputNumber
                                id="valor_ii"
                                name="valor_ii"
                                mode="currency"
                                currency="BRL"
                                locale="pt-BR"
                                disabled
                                value={dadosBasicos.valor_ii}
                            />
                        </div>
                        <div className="p-field p-col-12 p-md-3">
                            <label htmlFor="valor_ipi">Valor IPI</label>
                            <InputNumber
                                id="valor_ipi"
                                name="valor_ipi"
                                mode="currency"
                                currency="BRL"
                                locale="pt-BR"
                                disabled
                                value={dadosBasicos.valor_ipi}
                            />
                        </div>
                        <div className="p-field p-col-12 p-md-3">
                            <label htmlFor="valor_pis">Valor PIS</label>
                            <InputNumber
                                id="valor_pis"
                                name="valor_pis"
                                mode="currency"
                                currency="BRL"
                                locale="pt-BR"
                                disabled
                                value={dadosBasicos.valor_pis}
                            />
                        </div>
                    </div>
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-12 p-md-3">
                            <label htmlFor="valor_cofins">Valor cofins</label>
                            <InputNumber
                                id="valor_cofins"
                                name="valor_cofins"
                                mode="currency"
                                currency="BRL"
                                locale="pt-BR"
                                disabled
                                value={dadosBasicos.valor_cofins}
                            />
                        </div>
                        <div className="p-field p-col-12 p-md-3">
                            <label htmlFor="outros_valores">Outros valores</label>
                            <InputNumber
                                id="outros_valores"
                                name="outros_valores"
                                mode="currency"
                                currency="BRL"
                                locale="pt-BR"
                                disabled
                                value={dadosBasicos.outros_valores}
                            />
                        </div>
                        <div className="p-field p-col-12 p-md-3">
                            <label htmlFor="icms_fcp">Icms FCP</label>
                            <InputNumber
                                id="icms_fcp"
                                name="icms_fcp"
                                mode="currency"
                                currency="BRL"
                                locale="pt-BR"
                                disabled
                                value={dadosBasicos.icms_fcp}
                            />
                        </div>

                        <div className="p-field p-col-12 p-md-3">
                            <label htmlFor="valor_fcp">Valor FCP</label>
                            <InputNumber
                                id="valor_fcp"
                                name="valor_fcp"
                                mode="currency"
                                currency="BRL"
                                locale="pt-BR"
                                disabled
                                value={dadosBasicos.valor_fcp}
                            />
                        </div>
                    </div>
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-12 p-md-3">
                            <label htmlFor="fcp_st">FCP ST.</label>
                            <InputNumber
                                id="fcp_st"
                                name="fcp_st"
                                mode="currency"
                                currency="BRL"
                                locale="pt-BR"
                                disabled
                                value={dadosBasicos.fcp_st}
                            />
                        </div>
                        <div className="p-field p-col-12 p-md-3">
                            <label htmlFor="fcp_retido_st">FCP retido ST.</label>
                            <InputNumber
                                id="fcp_retido_st"
                                name="fcp_retido_st"
                                mode="currency"
                                currency="BRL"
                                locale="pt-BR"
                                disabled
                                value={dadosBasicos.fcp_retido_st}
                            />
                        </div>

                        <div className="p-field p-col-12 p-md-3">
                            <label htmlFor="ipi_devolvido">IPI devolvido</label>
                            <InputNumber
                                id="ipi_devolvido"
                                name="ipi_devolvido"
                                mode="currency"
                                currency="BRL"
                                locale="pt-BR"
                                disabled
                                value={dadosBasicos.ipi_devolvido}
                            />
                        </div>
                        <div className="p-field p-col-12 p-md-3">
                            <label htmlFor="valor_afrmm">Valor Afrmm</label>
                            <InputNumber
                                id="valor_afrmm"
                                name="valor_afrmm"
                                mode="currency"
                                currency="BRL"
                                locale="pt-BR"
                                disabled
                                value={dadosBasicos.valor_afrmm}
                            />
                        </div>
                    </div>
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-12 p-md-3">
                            <label htmlFor="valor_servico">Valor serviço</label>
                            <InputNumber
                                id="valor_servico"
                                name="valor_servico"
                                mode="currency"
                                currency="BRL"
                                locale="pt-BR"
                                disabled
                                value={dadosBasicos.valor_servico}
                            />
                        </div>
                        <div className="p-field p-col-12 p-md-3">
                            <label htmlFor="base_iss">Base ISS</label>
                            <InputNumber
                                id="base_iss"
                                name="base_iss"
                                mode="currency"
                                currency="BRL"
                                locale="pt-BR"
                                disabled
                                value={dadosBasicos.base_iss}
                            />
                        </div>
                        <div className="p-field p-col-12 p-md-3">
                            <label htmlFor="valor_iss">Valor ISS</label>
                            <InputNumber
                                id="valor_iss"
                                name="valor_iss"
                                mode="currency"
                                currency="BRL"
                                locale="pt-BR"
                                disabled
                                value={dadosBasicos.valor_iss}
                            />
                        </div>
                        <div className="p-field p-col-12 p-md-3">
                            <label htmlFor="pis_servico">PIS serviço</label>
                            <InputNumber
                                id="pis_servico"
                                name="pis_servico"
                                mode="currency"
                                currency="BRL"
                                locale="pt-BR"
                                disabled
                                value={dadosBasicos.pis_servico}
                            />
                        </div>
                    </div>
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-12 p-md-3">
                            <label htmlFor="cofins_servico">Cofins serviço</label>
                            <InputNumber
                                id="cofins_servico"
                                name="cofins_servico"
                                mode="currency"
                                currency="BRL"
                                locale="pt-BR"
                                disabled
                                value={dadosBasicos.cofins_servico}
                            />
                        </div>
                        <div className="p-field p-col-12 p-md-3">
                            <label htmlFor="deducao_bc">Dedução BC.</label>
                            <InputNumber
                                id="deducao_bc"
                                name="deducao_bc"
                                mode="currency"
                                currency="BRL"
                                locale="pt-BR"
                                disabled
                                value={dadosBasicos.deducao_bc}
                            />
                        </div>

                        <div className="p-field p-col-12 p-md-3">
                            <label htmlFor="outras_retencoes">Outras retenções</label>
                            <InputNumber
                                id="outras_retencoes"
                                name="outras_retencoes"
                                mode="currency"
                                currency="BRL"
                                locale="pt-BR"
                                disabled
                                value={dadosBasicos.outras_retencoes}
                            />
                        </div>
                        <div className="p-field p-col-12 p-md-3">
                            <label htmlFor="desc_incondicionado">Desconto incondicionado</label>
                            <InputNumber
                                id="desc_incondicionado"
                                name="desc_incondicionado"
                                mode="currency"
                                currency="BRL"
                                locale="pt-BR"
                                disabled
                                value={dadosBasicos.desc_incondicionado}
                            />
                        </div>
                    </div>
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-12 p-md-3">
                            <label htmlFor="desc_condicionado">Desconto condicionado</label>
                            <InputNumber
                                id="desc_condicionado"
                                name="desc_condicionado"
                                mode="currency"
                                currency="BRL"
                                locale="pt-BR"
                                disabled
                                value={dadosBasicos.desc_condicionado}
                            />
                        </div>
                        <div className="p-field p-col-12 p-md-3">
                            <label htmlFor="retencao_iss">Retençãao ISS</label>
                            <InputNumber
                                id="retencao_iss"
                                name="retencao_iss"
                                mode="currency"
                                currency="BRL"
                                locale="pt-BR"
                                disabled
                                value={dadosBasicos.retencao_iss}
                            />
                        </div>

                        <div className="p-field p-col-12 p-md-3">
                            <label htmlFor="retido_pis">Retido PIS</label>
                            <InputNumber
                                id="retido_pis"
                                name="retido_pis"
                                mode="currency"
                                currency="BRL"
                                locale="pt-BR"
                                disabled
                                value={dadosBasicos.retido_pis}
                            />
                        </div>
                        <div className="p-field p-col-12 p-md-3">
                            <label htmlFor="retido_cofins">Retido cofins</label>
                            <InputNumber
                                id="retido_cofins"
                                name="retido_cofins"
                                mode="currency"
                                currency="BRL"
                                locale="pt-BR"
                                disabled
                                value={dadosBasicos.retido_cofins}
                            />
                        </div>
                    </div>
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-12 p-md-3">
                            <label htmlFor="retido_csll">Retido Csll</label>
                            <InputNumber
                                id="retido_csll"
                                name="retido_csll"
                                mode="currency"
                                currency="BRL"
                                locale="pt-BR"
                                disabled
                                value={dadosBasicos.retido_csll}
                            />
                        </div>
                        <div className="p-field p-col-12 p-md-3">
                            <label htmlFor="base_irrf">Base Irrf</label>
                            <InputNumber
                                id="base_irrf"
                                name="base_irrf"
                                mode="currency"
                                currency="BRL"
                                locale="pt-BR"
                                disabled
                                value={dadosBasicos.base_irrf}
                            />
                        </div>

                        <div className="p-field p-col-12 p-md-3">
                            <label htmlFor="valor_irrf">Valor Irrf</label>
                            <InputNumber
                                id="valor_irrf"
                                name="valor_irrf"
                                mode="currency"
                                currency="BRL"
                                locale="pt-BR"
                                disabled
                                value={dadosBasicos.valor_irrf}
                            />
                        </div>
                        <div className="p-field p-col-12 p-md-3">
                            <label htmlFor="base_ret_previdencia">Base retenção prev.</label>
                            <InputNumber
                                id="base_ret_previdencia"
                                name="base_ret_previdencia"
                                mode="currency"
                                currency="BRL"
                                locale="pt-BR"
                                disabled
                                value={dadosBasicos.base_ret_previdencia}
                            />
                        </div>
                    </div>
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-12 p-md-3">
                            <label htmlFor="retencao_previdencia">Retenção previdência</label>
                            <InputNumber
                                id="retencao_previdencia"
                                name="retencao_previdencia"
                                mode="currency"
                                currency="BRL"
                                locale="pt-BR"
                                disabled
                                value={dadosBasicos.retencao_previdencia}
                            />
                        </div>
                        <div className="p-field p-col-12 p-md-3">
                            <label htmlFor="valor_total_nf">
                                <b>Valor NF</b>
                            </label>
                            <InputNumber
                                id="valor_total_nf"
                                name="valor_total_nf"
                                mode="currency"
                                currency="BRL"
                                locale="pt-BR"
                                disabled
                                value={dadosBasicos.valor_total_nf}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
