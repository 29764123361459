import React, { useRef } from "react";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import { useFormik } from "formik";
import { useHistory } from "react-router-dom";

import classNames from "classnames";
import * as Yup from "yup";
import { Dropdown } from "@/components/Dropdown";
import { MakoButton as Button } from "@/components/MakoButton";
import { TIPO_HISTORICO_PADRAO } from "@/assets/constants/financeiro";
import { Checkbox } from "primereact/checkbox";
import useLoadingLocal from "@/hooks/useLoadingLocal";
import useHttp from "@/hooks/useHttp";
import useToast from "@/hooks/useToast";
import { PageBase } from "@/components/PageBase";
import { MAKO_ICONS } from "@/assets/constants/constants_styles";
import { MakoActionsButtons } from "@/components/MakoActionsButtons";
import { CamposObrigatorios } from "@/components/CamposObrigatorios";
import { Label } from "@/components/Label";

export const FinanceiroHistoricoPadraoForm = (props) => {
    const [loading, showLoading, hideLoading] = useLoadingLocal();
    const { httpPatch, httpPost } = useHttp();
    const { showSuccess } = useToast();
    const toastRef = useRef(null);
    const history = useHistory();

    const formik = useFormik({
        initialValues: props.location.state
            ? { ...props.location.state.historico, tipo: props.location.state.historico.tipo.value }
            : {
                  descricao: "",
                  tipo: "P",
                  ativo: true,
              },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                tipo: Yup.string().required("O campo 'tipo' é obrigatório."),
                descricao: Yup.string().max(140).required("O campo 'descrição' é obrigatório."),
            });
            await formSchema.validate(values, {
                abortEarly: false,
            });
            if (!values.id) {
                const handlers = {
                    201: () => {
                        showSuccess({
                            summary: "Sucesso",
                            detail: "Histórico cadastrado com sucesso!",
                            life: 1500,
                        });
                        formik.resetForm();
                        history.push("/financeiro/cadastros/historico-padrao");
                    },
                };
                showLoading();
                await httpPost({ url: `/financeiro/historico-padrao/`, body: values }, handlers);
                hideLoading();
            } else {
                const handlers = {
                    200: () => {
                        showSuccess({
                            summary: "Sucesso",
                            detail: "Histórico alterado com sucesso!",
                            life: 1500,
                        });
                        formik.resetForm();
                        history.push("/financeiro/cadastros/historico-padrao");
                    },
                };
                showLoading();
                await httpPatch({ url: `/financeiro/historico-padrao/${values.id}/`, body: values }, handlers);
                hideLoading();
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            }
        }
    }

    const cancelar = () => {
        formik.resetForm();
        history.push("/financeiro/cadastros/historico-padrao");
    };

    return (
        <PageBase>
            <Toast ref={toastRef} />
            <h3>{!formik.values.id ? "Novo histórico padrão" : "Manutenção de histórico padrão"}</h3>
            <form onSubmit={formik.handleSubmit}>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-8">
                        <Label htmlFor="descricao" label="Descrição" obrigatorio />
                        <InputText
                            id="descricao"
                            name="descricao"
                            value={formik.values.descricao}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.descricao })}
                            autoComplete="off"
                            autoFocus
                        />
                        {formik.errors.descricao && <small className="p-error">{formik.errors.descricao}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <Label htmlFor="tipo" label="Tipo de histórico" />
                        <Dropdown
                            id="tipo"
                            name="tipo"
                            placeholder="Selecione um tipo"
                            options={TIPO_HISTORICO_PADRAO}
                            optionValue="value"
                            optionLabel="label"
                            value={formik.values.tipo}
                            onChange={formik.handleChange}
                        />
                        {formik.errors.tipo && <small className="p-error">{formik.errors.tipo}</small>}
                    </div>
                    <div className="p-field-checkbox p-col-12 p-md-1 p-mt-5">
                        <Checkbox
                            inputId="ativo"
                            name="ativo"
                            checked={formik.values.ativo}
                            onChange={formik.handleChange}
                        />
                        <Label htmlFor="ativo" label="Ativo?" />
                    </div>
                </div>
                <CamposObrigatorios />
                <MakoActionsButtons>
                    <Button
                        label="Gravar"
                        icon={MAKO_ICONS.GRAVAR}
                        type="submit"
                        className="p-button-info"
                        loading={loading}
                    />
                    <Button
                        label="Cancelar"
                        type="reset"
                        icon={MAKO_ICONS.CANCEL}
                        className="p-button-danger"
                        onClick={cancelar}
                        loading={loading}
                    />
                </MakoActionsButtons>
            </form>
        </PageBase>
    );
};
