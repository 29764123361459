import React, { useCallback, useEffect, useRef } from "react";

import { InputText } from "primereact/inputtext";

import useLoadingLocal from "@/hooks/useLoadingLocal";
import { axiosGet } from "@/services/http";

const BASE_URL = "/vendas/rotas-entrega/{id}/cidades";

export const InputCidadesRota = ({ rotaId, className }) => {
    const [loading, show, hide] = useLoadingLocal();

    const inputRef = useRef(null);

    const fetch = useCallback(
        async (signal) => {
            if (rotaId) {
                show();
                const url = BASE_URL.replace("{id}", rotaId);
                const { status, data } = await axiosGet(url, { signal });
                hide();
                if (status === 200) {
                    inputRef.current.value = data.results
                        ?.map(({ nome, estado }) => `${nome} - ${estado.uf}`)
                        .join(", ");
                }
            } else inputRef.current.value = "";
        },
        [hide, rotaId, show]
    );

    useEffect(() => {
        const controller = new AbortController();
        fetch(controller.signal);
        return () => {
            controller.abort();
        };
    }, [fetch]);

    return (
        <InputText
            ref={inputRef}
            placeholder={loading ? "Buscando cidades..." : undefined}
            className={className}
            readOnly
        />
    );
};
