import React, { useCallback, useEffect, useRef, useState } from "react";
import { Toast } from "primereact/toast";
import { TabView, TabPanel } from "primereact/tabview";
import { BlockUI } from "primereact/blockui";
import { Button } from "primereact/button";
import { useHistory } from "react-router-dom";
import { Dropdown } from "@/components/Dropdown";
import { ServicosForm } from "./formServicos";
import { DadosNotaForm } from "./formDadosNota";
import { NotaServicoProvider } from "@/contexts/notaServicoContext";
import useNotaServico from "@/hooks/useNotaServico";
import { ParcelasServicoForm } from "./formParcelas";
import useEmpresa from "@/hooks/useEmpresa";
import { axiosGet } from "@/services/http";
import useLoading from "@/hooks/useLoading";

const TabNotaServico = (props) => {
    const { empresaSelecionada, empresaSelecionadaId } = useEmpresa();
    const [blockedTab, setBlockedTab] = useState(false);
    const [operacao, setOperacao] = useState(null);
    const [activeTab, setActiveTab] = useState(0);
    const [listaOperacoes, setListaOperacoes] = useState([]);
    const { handleServico, handlePreencherServico, submit } = useNotaServico();
    const { showLoading, hideLoading } = useLoading();
    const toastRef = useRef(null);
    const history = useHistory();

    const carregarNotaFiscal = useCallback(async () => {
        if (props.props?.location?.notaServico) {
            await handlePreencherServico(props.props.location.notaServico);
            setOperacao(props.props.location.opServico);
        }
    }, [handlePreencherServico, props.props?.location]);

    useEffect(() => {
        carregarNotaFiscal();
    }, [carregarNotaFiscal]);

    async function persisteNFS() {
        try {
            const { status } = await handleServico();
            if (status === 201 || status === 200) history.push("/fiscal/nota-servico/lista-notas");
            else
                toastRef.current.show({
                    severity: "error",
                    summary: "Erro",
                    detail: "Desculpe, não conseguimos processar a sua requisição.",
                    life: 1500,
                });
        } catch {
            toastRef.current.show({
                severity: "error",
                summary: "Erro",
                detail: "Desculpe, não conseguimos processar a sua requisição.",
                life: 1500,
            });
        }
    }

    const alterarTab = (aba) => {
        if (aba === -1) {
            if (activeTab > 0) {
                setActiveTab(activeTab - 1);
            }
        } else {
            if (activeTab < 3) {
                setActiveTab(activeTab + 1);
            }
        }
    };

    const listarOperacoes = useCallback(async () => {
        if (!empresaSelecionadaId) return;

        const params = {
            empresa: empresaSelecionadaId,
            tipo_movimento: "S",
            ativo: true,
        };
        showLoading();
        const { status, data } = await axiosGet("/servicos/operacoes-fiscais-servicos/", { params });
        hideLoading();

        if (status === 200) setListaOperacoes(data.results);
    }, [showLoading, hideLoading, empresaSelecionadaId]);

    useEffect(() => {
        listarOperacoes();
    }, [listarOperacoes]);

    useEffect(() => {
        if (operacao) setBlockedTab(false);
        else setBlockedTab(true);
    }, [operacao]);

    return (
        <div className="p-grid">
            <div className="p-col-12">
                <div className="card">
                    <Toast ref={toastRef} />
                    <h5>Nota Fiscal de Serviço</h5>
                    <label className="html">Operação fiscal de serviço * </label>
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-12 p-md-6">
                            <Dropdown
                                id="operacao_servico"
                                name="operacao_servico"
                                options={listaOperacoes}
                                placeholder="Selecione uma operação fiscal"
                                disabled={!empresaSelecionada?.id}
                                optionValue="id"
                                optionLabel="descricao"
                                showClear={false}
                                value={operacao}
                                onChange={(e) => setOperacao(e.target.value)}
                            />
                        </div>
                    </div>
                    <BlockUI blocked={blockedTab} template={<i className="pi pi-lock" style={{ fontSize: "6rem" }} />}>
                        <div>
                            <TabView
                                className="tabview-custom"
                                activeIndex={activeTab}
                                onTabChange={(e) => setActiveTab(e.index)}
                            >
                                <TabPanel header="Dados Gerais" leftIcon="pi pi-book">
                                    <DadosNotaForm operacao={operacao} />
                                </TabPanel>
                                <TabPanel header="Serviços" disabled={!submit} leftIcon="pi pi-book">
                                    <ServicosForm />
                                </TabPanel>
                                <TabPanel header="Parcelas" disabled={!submit} leftIcon="pi pi-book">
                                    <ParcelasServicoForm />
                                </TabPanel>
                            </TabView>
                        </div>
                    </BlockUI>
                    <div className="p-grid p-fluid" style={{ paddingTop: "2%" }}>
                        {activeTab > 0 && activeTab !== 4 ? (
                            <div className="p-col-6 p-md-3">
                                <Button
                                    label="Anterior"
                                    icon="pi pi-angle-left"
                                    className="p-button-info"
                                    onClick={() => alterarTab(-1)}
                                />
                            </div>
                        ) : null}
                        {activeTab < 2 ? (
                            <div className="p-col-6 p-md-3">
                                <Button
                                    label="Próximo"
                                    icon="pi pi-angle-right"
                                    iconPos="right"
                                    className="p-button-info"
                                    onClick={() => alterarTab(1)}
                                />
                            </div>
                        ) : null}
                        {activeTab !== 4 ? (
                            <div className="p-col-6 p-md-3">
                                <Button label="Finalizar" className="p-button-success" onClick={() => persisteNFS()} />
                            </div>
                        ) : null}
                        {activeTab !== 4 ? (
                            <div className="p-col-6 p-md-3">
                                <Button
                                    label="Cancelar"
                                    className="p-button-danger"
                                    onClick={() => history.push("/fiscal/nota-servico/lista-notas")}
                                />
                            </div>
                        ) : null}
                    </div>
                </div>
            </div>
        </div>
    );
};

export const TabNotaServicoContext = (props) => {
    return (
        <NotaServicoProvider>
            <TabNotaServico props={props} />
        </NotaServicoProvider>
    );
};
