import React, { forwardRef, useImperativeHandle, useRef, useState } from "react";
import classNames from "classnames";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Button } from "primereact/button";
import { useFormik } from "formik";

import { PainelAssistenciaTecnica } from "./panel";
import { ListagemModal } from "./listagem";
import { Label } from "@/components/Label";
import { Dropdown } from "@/components/Dropdown";
import { MakoCalendar } from "@/components/MakoCalendar";
import { MakoInputPerfil } from "@/components/MakoInputs";
import { CamposObrigatorios } from "@/components/CamposObrigatorios";
import { SITUACAO_ASSISTENCIA_TECNICA } from "@/assets/constants/vendas";
import { dataToStr } from "@/assets/util/datas";
import { axiosPost, axiosPatch } from "@/services/http";
import useToast from "@/hooks/useToast";
import useAuth from "@/hooks/useAuth";
import useLoadingLocal from "@/hooks/useLoadingLocal";

const URL_API = "/vendas/ocorrencias-assistencia-tecnica/";

const Modal = ({ onFechar }, ref) => {
    const [visible, setVisible] = useState(false);
    const [assistencia, setAssistencia] = useState(null);
    const [tipoOcorrencia, setTipoOcorrencia] = useState(null);
    const listagemRef = useRef(null);
    const dropdownTpOcorrenciaRef = useRef(null);
    const [loading, showLoading, hideLoading] = useLoadingLocal();
    const { showSuccess, showError } = useToast();
    const { user } = useAuth();

    const formik = useFormik({
        initialValues: {
            assistencia: null,
            tipo_ocorrencia: null,
            datahora_ocorrencia: new Date(),
            resumo: "",
            detalhes: "",
            cadastrado_por: "",
            excluida_em: null,
            perfil_origem: null,
            perfil_destino: null,
            nota_fiscal: null,
        },
        onSubmit: handleSubmit,
    });

    function handleErrors(errors) {
        let errorMessages = {};
        Object.entries(errors).forEach(([k, v]) => {
            errorMessages[k] = v;
        });
        formik.setErrors(errorMessages);
    }

    async function handleSubmit(values) {
        try {
            if (!values.id) {
                const body = {
                    ...values,
                    assistencia: assistencia.id,
                    perfil_origem: values.perfil_origem?.id || null,
                    perfil_destino: values.perfil_destino?.id || null,
                    cadastrado_por: user.id,
                };
                showLoading();
                const { status, data } = await axiosPost(URL_API, body);
                hideLoading();
                if (status === 201) {
                    showSuccess({
                        summary: "Sucesso",
                        detail: "Tipo de ocorrência cadastrado com sucesso.",
                        life: 1500,
                    });
                    formik.resetForm();
                    listagemRef.current?.buscarDados();
                } else if (status === 400) {
                    handleErrors(data);
                } else {
                    showError({
                        summary: "Erro :(",
                        detail: "Desculpe, não foi possível processar a requisição.",
                        life: 3000,
                    });
                }
            } else {
                showLoading();
                const { status, data } = await axiosPatch(`${URL_API}${values.id}/`, values);
                hideLoading();
                if (status === 200) {
                    showSuccess({
                        summary: "Sucesso",
                        detail: "Tipo de ocorrência alterado com sucesso!",
                        life: 1500,
                    });
                    formik.resetForm();
                    listagemRef.current?.buscarDados();
                } else if (status === 400) {
                    handleErrors(data);
                } else {
                    showError({
                        summary: "Erro :(",
                        detail: "Desculpe, não foi possível processar a requisição.",
                        life: 3000,
                    });
                }
            }
        } catch (error) {
            showError({
                summary: "Erro :(",
                detail: `Não foi possível submeter as informações. MOTIVO: ${error}`,
                life: 3000,
            });
        }
    }

    const abrirModal = (assistencia) => {
        setVisible(true);
        setAssistencia(assistencia);
    };

    const cancelar = () => {
        formik.resetForm();
    };

    const fecharModal = () => {
        setVisible(false);
        cancelar();
        if (typeof onFechar === "function") onFechar();
    };

    useImperativeHandle(ref, () => ({ abrirModal }));

    const getTipoOcorrencia = (tpOcorrencia) => {
        const callback = (obj) => obj.id === tpOcorrencia;
        const tipoOcorrencia = dropdownTpOcorrenciaRef.current?.getObject(callback);
        setTipoOcorrencia(tipoOcorrencia);
    };

    const formatarDataExclusao = (data) => {
        if (!data) return "-";
        return dataToStr(data, "dd/MM/yyyy");
    };

    return (
        <Dialog
            header="Ocorrência de assistência técnica"
            visible={visible}
            onHide={fecharModal}
            breakpoints={{ "1060px": "90vw" }}
            style={{ width: "90vw" }}
        >
            <PainelAssistenciaTecnica assistenciaTecnica={assistencia} />
            <form onSubmit={formik.handleSubmit}>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-4">
                        <Label htmlFor="tipo-ocorrencia" label="Tipo de ocorrência" obrigatorio />
                        <Dropdown
                            ref={dropdownTpOcorrenciaRef}
                            id="tipo-ocorrencia"
                            name="tipo_ocorrencia"
                            url="/vendas/tipos-ocorrencia-assistencia-tecnica/"
                            optionValue="id"
                            optionLabel="descricao"
                            value={formik.values.tipo_ocorrencia}
                            onChange={(e) => {
                                formik.handleChange(e);
                                getTipoOcorrencia(e.value);
                            }}
                            className={classNames({ "p-invalid": formik.errors.tipo_ocorrencia })}
                        />
                        {formik.errors.tipo_ocorrencia && (
                            <small className="p-error">{formik.errors.tipo_ocorrencia}</small>
                        )}
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <Label htmlFor="dh-ocorrencia" label="Data/hora ocorrência" obrigatorio />
                        <MakoCalendar
                            id="dh-ocorrencia"
                            name="datahora_ocorrencia"
                            valueCalendar={formik.values.datahora_ocorrencia}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.datahora_ocorrencia })}
                        />
                        {formik.errors.datahora_ocorrencia && (
                            <small className="p-error">{formik.errors.datahora_ocorrencia}</small>
                        )}
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <Label htmlFor="sit-apos-ocorrencia" label="Situação após ocorrência" />
                        <InputText
                            id="sit-apos-ocorrencia"
                            name="situacao_apos_ocorrencia"
                            disabled
                            value={
                                SITUACAO_ASSISTENCIA_TECNICA.find((s) => s.value === tipoOcorrencia?.novo_status)
                                    ?.label || "N/A"
                            }
                        />
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <Label htmlFor="requer-nf" label="Requer emissão NF" />
                        <InputText
                            id="requer-nf"
                            name="requer_nf"
                            disabled
                            value={tipoOcorrencia?.emitir_nota ? "Sim" : "Não"}
                        />
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <Label htmlFor="faz-movimentacao" label="Faz movimentação" />
                        <InputText
                            id="faz-movimentacao"
                            name="faz_movimentacao"
                            disabled
                            value={tipoOcorrencia?.com_movimentacao ? "Sim" : "Não"}
                        />
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-6">
                        <Label htmlFor="perfil-origem" label="Pessoa de origem" />
                        <MakoInputPerfil
                            id="perfil-origem"
                            name="perfil_origem"
                            value={formik.values.perfil_origem}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.perfil_origem })}
                        />
                        {formik.errors.perfil_origem && (
                            <small className="p-error">{formik.errors.perfil_origem}</small>
                        )}
                    </div>
                    <div className="p-field p-col-12 p-md-6">
                        <Label htmlFor="perfil-destino" label="Pessoa de destino" />
                        <MakoInputPerfil
                            id="perfil-destino"
                            name="perfil_destino"
                            value={formik.values.perfil_destino}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.perfil_destino })}
                        />
                        {formik.errors.perfil_destino && (
                            <small className="p-error">{formik.errors.perfil_destino}</small>
                        )}
                    </div>
                </div>
                <div className="p-fluid p-grid">
                    <div className="p-col-12 p-md-6">
                        <div className="p-fluid p-formgrid p-grid">
                            <div className="p-field p-col-12">
                                <Label htmlFor="resumo" label="Resumo" obrigatorio />
                                <InputText
                                    id="resumo"
                                    name="resumo"
                                    maxLength={60}
                                    value={formik.values.resumo}
                                    onChange={formik.handleChange}
                                    className={classNames({ "p-invalid": formik.errors.resumo })}
                                />
                                {formik.errors.resumo && <small className="p-error">{formik.errors.resumo}</small>}
                            </div>
                        </div>
                        <div className="p-fluid p-formgrid p-grid">
                            <div className="p-field p-col-12 p-md-6">
                                <Label htmlFor="registrado-por" label="Registrado por" />
                                <InputText
                                    id="registrado-por"
                                    name="registrado_por"
                                    value={formik.values.registrado_por?.usuario.username || user.usuario.username}
                                    disabled
                                />
                            </div>
                            <div className="p-field p-col-12 p-md-6">
                                <Label htmlFor="excluida-em" label="Excluída em" />
                                <InputText
                                    id="excluida-em"
                                    name="excluida_em"
                                    value={formatarDataExclusao(formik.values.excluida_em)}
                                    disabled
                                />
                            </div>
                        </div>
                    </div>
                    <div className="p-col-12 p-md-6">
                        <div className="p-fluid p-formgrid p-grid">
                            <div className="p-field p-col-12">
                                <Label htmlFor="detalhes" label="Detalhes da ocorrência" />
                                <InputTextarea
                                    id="detalhes"
                                    name="detalhes"
                                    autoResize
                                    value={formik.values.detalhes}
                                    onChange={formik.handleChange}
                                    className={classNames({ "p-invalid": formik.errors.detalhes })}
                                />
                                {formik.errors.detalhes && <small className="p-error">{formik.errors.detalhes}</small>}
                            </div>
                        </div>
                    </div>
                </div>
                <CamposObrigatorios />
                <div className="p-grid p-col-12 p-md-6">
                    <Button
                        type="submit"
                        label="Gravar"
                        icon="pi pi-save"
                        disabled={assistencia?.situacao !== "A"}
                        loading={loading}
                        className="p-button-info p-mr-2"
                    />
                    <Button
                        type="button"
                        label="Cancelar"
                        icon="pi pi-times"
                        disabled={assistencia?.situacao !== "A"}
                        loading={loading}
                        onClick={cancelar}
                        className="p-button-danger p-mr-2"
                    />
                </div>
            </form>
            <ListagemModal
                assistenciaId={assistencia?.id}
                listagemRef={listagemRef}
                tipoOcorrencia={tipoOcorrencia}
                onClickBotaoEditar={(e) => {
                    formik.setValues({
                        ...e,
                        tipo_ocorrencia: e.tipo_ocorrencia.id,
                    });
                    getTipoOcorrencia(e.tipo_ocorrencia.id);
                }}
            />
        </Dialog>
    );
};

export const ModalOcorrenciaAssistenciaTecnica = forwardRef(Modal);
