import React, { useCallback } from "react";

import { MakoActionsButtonsColumn } from "@/components/MakoActionsButtonsColumn";

import { Sessoes } from "./sessoes";
import { Buttons } from "./buttons";

export const Parcelas = () => {
    const actions = useCallback((rowData) => {
        return (
            <MakoActionsButtonsColumn>
                <Buttons.Calcular />
                <Buttons.Cancelar />
            </MakoActionsButtonsColumn>
        );
    }, []);

    return (
        <>
            <Sessoes.Dados />
            <Sessoes.BuscarParcela />
            <Sessoes.Listagem actions={actions} />
        </>
    );
};
