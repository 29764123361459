import React, { useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { useFormik } from "formik";
import { InputNumber } from "primereact/inputnumber";
import classNames from "classnames";
import * as Yup from "yup";
import { Dropdown } from "@/components/Dropdown";
import { axiosPatch, axiosPut } from "@/services/http";
import { Button } from "primereact/button";
import { SelectButton } from "primereact/selectbutton";
import { SIM_NAO_BOOLEAN } from "@/assets/constants/constants";

export const EditarItemModalForm = ({ produto, setEditarProduto, editarProduto, toastRef, listagemRef }) => {
    const { setValues, setFieldValue, resetForm, ...formik } = useFormik({
        initialValues: {
            preco_calculado: 0,
            plano_recebimento: null,
            meses_encargos: 0,
            isento_encargos: false,
        },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                preco_calculado: Yup.number().required("O campo é obrigatório."),
                plano_recebimento: Yup.number().required("O campo é obrigatório"),
                meses_encargos: Yup.number().required("O campo é obrigatório"),
            });

            await formSchema.validate(values, {
                abortEarly: false,
            });

            const arredonda = await axiosPut("/vendas/arredondar-precos/", {
                precos: [values.preco_calculado],
            });

            if (arredonda.status === 200) {
                const resposta = await axiosPatch(`/produtos/tabela-preco-itens/${produto?.id}/`, {
                    ...values,
                    preco_arredondado: arredonda.data.result[values.preco_calculado],
                });

                if (resposta.status === 200) {
                    listagemRef.current?.buscarDados();
                    toastRef.current.show({
                        severity: "success",
                        summary: "Sucesso",
                        detail: "Cadastro do item alterado com sucesso!",
                        life: 1500,
                    });
                    esconderEditar();
                } else {
                    toastRef.current.show({
                        severity: "error",
                        summary: "Erro",
                        detail: "Desculpe, não conseguimos processar a sua requisição.",
                        life: 1500,
                    });
                }
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            } else {
                toastRef.current.show({
                    severity: "error",
                    summary: "Erro",
                    detail: "Desculpe, não conseguimos processar a sua requisição.",
                    life: 1500,
                });
            }
        }
    }

    const esconderEditar = () => {
        resetForm();
        setEditarProduto(false);
    };

    useEffect(() => {
        if (produto)
            setValues({
                meses_encargos: produto.meses_encargos,
                isento_encargos: produto.isento_encargos,
                preco_calculado: produto.preco_calculado,
                plano_recebimento: produto.plano_recebimento?.id || null,
            });
    }, [produto, setValues]);

    return (
        <Dialog
            header={`Editando produto: ${produto?.sku?.codigo} - ${produto?.sku?.descricao_reduzida}`}
            visible={editarProduto}
            onHide={() => esconderEditar()}
            breakpoints={{ "960px": "75vw" }}
            style={{ width: "45vw" }}
        >
            <form onSubmit={formik.handleSubmit}>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-6">
                        <label htmlFor="preco_calculado">Preço *</label>
                        <InputNumber
                            id="preco_calculado"
                            name="preco_calculado"
                            mode="currency"
                            currency="BRL"
                            locale="br-BR"
                            min={0}
                            minFractionDigits={2}
                            maxFractionDigits={2}
                            value={formik.values.preco_calculado}
                            onValueChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.preco_calculado })}
                        />
                        {formik.errors.preco_calculado && (
                            <small className="p-error">{formik.errors.preco_calculado}</small>
                        )}
                    </div>
                    <div className="p-field p-col-12 p-md-6">
                        <label htmlFor="plano_recebimento">Plano recebimento</label>
                        <Dropdown
                            id="plano_recebimento"
                            name="plano_recebimento"
                            url="/financeiro/planos-recebimentos/"
                            placeholder="Selecione"
                            filter
                            filterBy="descricao"
                            optionValue="id"
                            optionLabel="descricao"
                            emptyMessage="Nenhum registro disponível"
                            emptyFilterMessage="Nenhum registro encontrado"
                            value={formik.values.plano_recebimento}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.plano_recebimento })}
                        />
                        {formik.errors.plano_recebimento && (
                            <small className="p-error">{formik.errors.plano_recebimento}</small>
                        )}
                    </div>
                    <div className="p-field p-col-12 p-md-6">
                        <label htmlFor="meses_encargos">Meses de encargos incluídos</label>
                        <InputNumber
                            id="meses_encargos"
                            name="meses_encargos"
                            min={0}
                            minFractionDigits={0}
                            maxFractionDigits={0}
                            value={formik.values.meses_encargos}
                            onValueChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.meses_encargos })}
                        />
                        {formik.errors.meses_encargos && (
                            <small className="p-error">{formik.errors.meses_encargos}</small>
                        )}
                    </div>
                    <div className="p-field p-col-12 p-md-6">
                        <label htmlFor="isento_encargos">Isento de encargos</label>
                        <SelectButton
                            id="isento_encargos"
                            name="isento_encargos"
                            options={SIM_NAO_BOOLEAN}
                            optionValue="id"
                            optionLabel="label"
                            value={formik.values.isento_encargos}
                            onChange={formik.handleChange}
                        />
                    </div>
                </div>
                <div className="p-grid p-justify-end p-mt-2">
                    <Button
                        label="Fechar"
                        type="reset"
                        icon="pi pi-times"
                        className="p-button-danger p-mr-2"
                        onClick={() => esconderEditar()}
                    />
                    <Button label="Gravar" type="submit" icon="pi pi-save" className="p-button-success p-mr-3" />
                </div>
            </form>
        </Dialog>
    );
};
