import React from "react";

import { InputTextarea } from "primereact/inputtextarea";
import { InputNumber } from "primereact/inputnumber";

import { MakoAutoComplete } from "@/components/MakoAutoComplete";
import { MakoInputMoeda } from "@/components/MakoInputMoeda";
import { MakoCalendar } from "@/components/MakoCalendar";
import { Dropdown } from "@/components/Dropdown";

import { TIPOS_CHOICE_SERVICOS_SERVICO } from "@/assets/constants/constants";

export const ModalApontamentoVisualizar = ({ apontamento }) => {
    return (
        <>
            <form>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-9">
                        <label htmlFor="servico_contratado">Identificação do serviço</label>
                        <MakoAutoComplete
                            id="servico_contratado"
                            name="servico_contratado"
                            field="label"
                            value={apontamento?.servico_contratado}
                            disabled
                        />
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="tipo">Tipo</label>
                        <Dropdown
                            id="tipo"
                            name="tipo"
                            options={TIPOS_CHOICE_SERVICOS_SERVICO}
                            optionValue="value"
                            optionLabel="label"
                            disabled
                            value={apontamento?.tipo}
                        />
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="data_inicial">Data Inicial</label>
                        <MakoCalendar
                            id="data_inicial"
                            name="data_inicial"
                            valueCalendar={apontamento?.data_inicial}
                            disabled
                        />
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="data_final">Data final</label>
                        <MakoCalendar
                            id="data_final"
                            name="data_final"
                            valueCalendar={apontamento?.data_final}
                            disabled
                        />
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="total_horas">Número de horas</label>
                        <InputNumber id="total_horas" name="total_horas" value={apontamento?.total_horas} disabled />
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="custo">Custo do serviço</label>
                        <MakoInputMoeda id="custo" name="custo" valueMoeda={apontamento?.custo} disabled />
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="valor">Valor do serviço</label>
                        <MakoInputMoeda id="valor" name="valor" valueMoeda={apontamento?.valor} disabled />
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="valor_nfs">Valor para NFSe</label>
                        <MakoInputMoeda id="valor_nfs" name="valor_nfs" valueMoeda={apontamento?.valor_nfs} disabled />
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-12">
                        <label htmlFor="descricao">Detalhes do serviço</label>
                        <InputTextarea
                            id="descricao"
                            name="descricao"
                            value={apontamento?.descricao}
                            disabled
                            rows={2}
                            autoResize
                            maxLength={255}
                        />
                    </div>
                </div>
            </form>
        </>
    );
};
