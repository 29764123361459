import React, { useCallback, useEffect, useRef, useState } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { useFormik } from "formik";
import { InputNumber } from "primereact/inputnumber";
import { Dropdown } from "primereact/dropdown";
import { dataToStr } from "@/assets/util/datas";
import { MakoCalendar } from "@/components/MakoCalendar";
import { useHistory } from "react-router-dom";
import { axiosPost, axiosPut, axiosGet } from "@/services/http";

import useLoading from "@/hooks/useLoading";
import classNames from "classnames";
import * as Yup from "yup";

const url = "/plano-operacional/fases-projetos/";
const urlvoltar = "/operacional/plano-operacional/fases-projetos";

export const OperacionalFasesProjetosForm = (props) => {
    const { showLoading, hideLoading } = useLoading();
    const [projetos, setProjetos] = useState([]);
    const toastRef = useRef(null);
    const history = useHistory();

    const faseProjetoVazio = {
        fase: null,
        descricao: "",
        projeto: "",
        data_inicio: "",
        data_fim: "",
    };

    const { setFieldValue, ...formik } = useFormik({
        initialValues: props.location?.state?.fasesProjetos
            ? { ...props.location.state.fasesProjetos, projeto: props.location.state.fasesProjetos.projeto?.id }
            : faseProjetoVazio,
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                fase: Yup.number().required("O campo é obrigatório.").typeError("Informe uma 'fase' válida"),
                projeto: Yup.number().required("O campo é obrigatório.").typeError("Informe uma 'projeto' válida"),
                descricao: Yup.string().required("O campo é obrigatório.").max(255).typeError("Informe uma 'descrição' válida"),
                data_inicio: Yup.date().required("O campo é obrigatório.").typeError("Informe uma 'data inicial' válida"),
                data_fim: Yup.date().when("data_inicio", {
                    is: (val) => !!val,
                    then: Yup.date()
                        .min(values.data_inicio || new Date(), "O campo 'data final' não pode ser anterior a inicial")
                        .typeError("Informe uma 'data final' válida")
                        .required("O campo é obrigatório.")
                }),
            });

            await formSchema.validate(values, {
                abortEarly: false,
            });

            values.data_fim = dataToStr(values.data_fim, "yyyy-MM-dd");
            values.data_inicio = dataToStr(values.data_inicio, "yyyy-MM-dd");

            if (!values.id) {
                showLoading();
                const resposta = await axiosPost(`${url}`, { ...values, responsavel: values.responsavel?.id });
                hideLoading();

                if (resposta.status === 201) {
                    toastRef.current.show({
                        severity: "success",
                        summary: "Sucesso",
                        detail: "Fases de projetos cadastrado com sucesso!",
                        life: 1500,
                    });

                    setTimeout(() => {
                        formik.resetForm();
                        history.push(urlvoltar);
                    }, 2000);
                } else {
                    toastRef.current.show({
                        severity: "error",
                        summary: "Erro",
                        detail: "Desculpe, não conseguimos processar a sua requisição.",
                        life: 1500,
                    });
                }
            } else {
                showLoading();
                const resposta = await axiosPut(`${url}${values.id}/`, {
                    ...values,
                    responsavel: values.responsavel?.id,
                });
                hideLoading();

                if (resposta.status === 200) {
                    toastRef.current.show({
                        severity: "success",
                        summary: "Sucesso",
                        detail: "Fases de projetos alterado com sucesso!",
                        life: 1500,
                    });

                    setTimeout(() => {
                        formik.resetForm();
                        history.push(urlvoltar);
                    }, 2000);
                } else {
                    toastRef.current.show({
                        severity: "error",
                        summary: "Erro",
                        detail: "Desculpe, não conseguimos processar a sua requisição.",
                        life: 1500,
                    });
                }
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};

                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });

                formik.setErrors(errorMessages);
                hideLoading();
            } else {
                toastRef.current.show({
                    severity: "error",
                    summary: "Erro",
                    detail: "Desculpe, não conseguimos processar a sua requisição.",
                    life: 1500,
                });
                hideLoading();
            }
        }
    }

    const listarProjetos = useCallback(async () => {
        showLoading();
        const resposta = await axiosGet("/plano-operacional/projetos/");
        hideLoading();

        if (resposta.status === 200 || resposta.status === 204) {
            setProjetos(resposta.data.results);
        }
    }, [showLoading, hideLoading]);

    useEffect(() => {
        listarProjetos();
    }, [listarProjetos]);

    const cancelar = () => {
        formik.resetForm();
        history.push(urlvoltar);
    };

    return (
        <div className="p-grid">
            <div className="p-col-12">
                <div className="card">
                    <Toast ref={toastRef} />
                    <h3>{!formik.values.id ? "Novo fases de projetos" : "Manutenção de fases de projetos"}</h3>
                    <form onSubmit={formik.handleSubmit}>
                        <div className="p-fluid p-formgrid p-grid">
                            <div className="p-field p-col-12 p-md-7">
                                <label htmlFor="projeto">Projeto * </label>
                                <Dropdown
                                    id="projeto"
                                    name="projeto"
                                    placeholder="Selecione um projeto"
                                    options={projetos}
                                    optionValue="id"
                                    optionLabel="nome"
                                    value={formik.values.projeto}
                                    onChange={formik.handleChange}
                                    className={classNames({
                                        "p-invalid": formik.errors.projeto,
                                    })}
                                />
                                {formik.errors.projeto && <small className="p-error">{formik.errors.projeto}</small>}
                            </div>
                        </div>
                        <div className="p-fluid p-formgrid p-grid">
                            <div className="p-field p-col-12 p-md-12">
                                <label htmlFor="descricao">Descrição *</label>
                                <InputText
                                    id="descricao"
                                    name="descricao"
                                    value={formik.values.descricao}
                                    onChange={formik.handleChange}
                                    className={classNames({
                                        "p-invalid": formik.errors.descricao,
                                    })}
                                    autoComplete="off"
                                />
                                {formik.errors.descricao && (
                                    <small className="p-error">{formik.errors.descricao}</small>
                                )}
                            </div>
                        </div>
                        <div className="p-fluid p-formgrid p-grid">
                            <div className="p-field p-col-12 p-md-3">
                                <label htmlFor="fase">Fase *</label>
                                <InputNumber
                                    id="fase"
                                    name="fase"
                                    showButtons
                                    min={0}
                                    value={formik.values.fase}
                                    onValueChange={formik.handleChange}
                                    className={classNames({
                                        "p-invalid": formik.errors.fase,
                                    })}
                                    autoComplete="off"
                                />
                                {formik.errors.fase && <small className="p-error">{formik.errors.fase}</small>}
                            </div>
                            <div className="p-field p-col-12 p-md-3">
                                <label htmlFor="data_inicio">Data inicio *</label>
                                <MakoCalendar
                                    id="data_inicio"
                                    name="data_inicio"
                                    valueCalendar={formik.values.data_inicio}
                                    onChange={formik.handleChange}
                                    className={classNames({
                                        "p-invalid": formik.errors.data_inicio,
                                    })}
                                />
                                {formik.errors.data_inicio && (
                                    <small className="p-error">{formik.errors.data_inicio}</small>
                                )}
                            </div>
                            <div className="p-field p-col-12 p-md-3">
                                <label htmlFor="data_fim">Data final *</label>
                                <MakoCalendar
                                    id="data_fim"
                                    name="data_fim"
                                    valueCalendar={formik.values.data_fim}
                                    onChange={formik.handleChange}
                                    className={classNames({
                                        "p-invalid": formik.errors.data_fim,
                                    })}
                                />
                                {formik.errors.data_fim && <small className="p-error">{formik.errors.data_fim}</small>}
                            </div>
                        </div>
                        <p>
                            <b>* Campos obrigatórios.</b>
                        </p>
                        <div className="p-grid p-col-12 p-md-6">
                            <Button label="Gravar" type="submit" className="p-button-info p-mr-2 p-mb-6" />
                            <Button
                                label="Cancelar"
                                type="reset"
                                className="p-button-danger p-mr-2 p-mb-6"
                                onClick={cancelar}
                            />
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};
