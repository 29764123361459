import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from "react";
import { axiosGet, axiosPost } from "@/services/http";
import useLoading from "@/hooks/useLoading";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import useToast from "@/hooks/useToast";
import { useFormik } from "formik";
import * as Yup from "yup";
import { parseNumberToMoneyHTML } from "@/assets/util/util";
import { Dropdown } from "@/components/Dropdown";
import classNames from "classnames";
import { MakoAutoComplete } from "@/components/MakoAutoComplete";
import { MakoCalendar } from "@/components/MakoCalendar";
import useEmpresa from "@/hooks/useEmpresa";
import { dataToStr } from "@/assets/util/datas";
import useAuth from "@/hooks/useAuth";

export const Modal = ({ apuracao, aposSalvar }, ref) => {
    const [visible, setVisible] = useState(false);
    const [comissoes, setComissoes] = useState([]);
    const [total, setTotal] = useState(0);
    const { showLoading, hideLoading } = useLoading();
    const { empresaSelecionadaId } = useEmpresa();
    const listagemRef = useRef(null);
    const { showSuccess, showError } = useToast();
    const { user } = useAuth();

    const formik = useFormik({
        initialValues: {
            template_rateio: null,
            historico_padrao: null,
            vencimento: null,
        },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                template_rateio: Yup.number().required("O campo é obrigatório."),
                historico_padrao: Yup.object()
                    .required("O campo é obrigatório.")
                    .typeError("Informe um histórico válido."),
                vencimento: Yup.date().required("O campo é obrigatório.").typeError("Informe uma data válida."),
            });

            await formSchema.validate(values, {
                abortEarly: false,
            });

            showLoading();
            const { status } = await axiosPost(`/vendas/processar-comissoes/${apuracao?.id}/`, {
                ...values,
                vencimento: dataToStr(values.vencimento, "yyyy-MM-dd"),
                empresa: empresaSelecionadaId,
                usuario: user.id,
            });
            hideLoading();

            if (status === 200) {
                if (typeof aposSalvar === "function") aposSalvar();
                showSuccess({
                    summary: "Sucesso",
                    detail: "Comissões processadas com sucesso!",
                    life: 2500,
                });
                setVisible(false);
                listagemRef.current?.buscarDados();
            } else {
                showError({
                    summary: "Erro",
                    detail: "Desculpe, não conseguimos processar a sua requisição.",
                    life: 1500,
                });
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
                hideLoading();
            } else {
                showError({
                    summary: "Erro",
                    detail: "Desculpe, não conseguimos processar a sua requisição.",
                    life: 1500,
                });
            }
        }
    }

    useImperativeHandle(ref, () => {
        return {
            setVisible,
        };
    });

    const carregaComissoes = useCallback(async () => {
        if (apuracao?.id && visible) {
            const resposta = await axiosGet(
                `/vendas/comissoes-apuradas?apuracao=${apuracao.id}&limit=3000&group_by=comissionado`
            );
            if (resposta.status === 200) {
                setComissoes(resposta.data.results);
                let total = 0;
                resposta.data.results.forEach((comissao) => {
                    total += parseFloat(comissao.valor_comissao);
                });
                setTotal(total);
            }
        }
    }, [apuracao, visible]);

    useEffect(() => {
        carregaComissoes();
    }, [carregaComissoes]);

    const autoCompleteHistoricoTemplate = (item) => {
        return <div>{`${item.descricao}`}</div>;
    };

    return (
        <>
            <Dialog
                header="Confirmação para processar comissão de venda:"
                visible={visible}
                breakpoints={{ "960px": "75vw" }}
                style={{ width: "40vw", display: "block" }}
                onHide={() => setVisible(false)}
            >
                <form onSubmit={formik.handleSubmit}>
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-col-12 p-md-12 p-mb-2">
                            <b>Valor total: {parseNumberToMoneyHTML(total)}</b>
                            <br />
                            <b>Comissicionados: {comissoes?.length}</b>
                        </div>
                        <div className="p-field p-col-12 p-md-6">
                            <label htmlFor="template_rateio">Template de rateio *</label>
                            <Dropdown
                                id="template_rateio"
                                name="template_rateio"
                                url={`/financeiro/templates-rateios/?query={id,descricao}`}
                                optionValue="id"
                                optionLabel="descricao"
                                emptyMessage="Não existem registros cadastrados"
                                value={formik.values.template_rateio}
                                onChange={formik.handleChange}
                                className={classNames({ "p-invalid": formik.errors.template_rateio })}
                            />
                            {formik.errors.template_rateio && (
                                <small className="p-error">{formik.errors.template_rateio}</small>
                            )}
                        </div>
                        <div className="p-field p-col-12 p-md-6">
                            <label htmlFor="historico_padrao">Histórico *</label>
                            <MakoAutoComplete
                                id="historico_padrao"
                                name="historico_padrao"
                                value={formik.values.historico_padrao}
                                onChange={formik.handleChange}
                                field="descricao"
                                minCaracteresBusca={4}
                                itemTemplate={autoCompleteHistoricoTemplate}
                                urlSearch="/financeiro/historico-padrao?tipo=P&descricao_icontains="
                                placeholder="Digite para começar a buscar... (min 4 caracteres)"
                                className={classNames({ "p-invalid": formik.errors.historico_padrao })}
                            />
                            {formik.errors.historico_padrao && (
                                <small className="p-error">{formik.errors.historico_padrao}</small>
                            )}
                        </div>
                        <div className="p-field p-col-12 p-md-6">
                            <label htmlFor="vencimento">Data de vencimento</label>
                            <MakoCalendar
                                id="vencimento"
                                name="vencimento"
                                valueCalendar={formik.values.vencimento}
                                onChange={formik.handleChange}
                                className={classNames({
                                    "p-invalid": formik.errors.vencimento,
                                })}
                            />
                            {formik.errors.vencimento && <small className="p-error">{formik.errors.vencimento}</small>}
                        </div>
                    </div>
                    <div className="p-grid p-justify-end p-mr-1 p-mt-2">
                        <Button
                            icon="pi pi-times"
                            label="Fechar"
                            type="button"
                            severity="danger"
                            className="p-mr-2"
                            onClick={() => setVisible(false)}
                        />
                        <Button
                            icon="pi pi-save"
                            label="Salvar"
                            type="submit"
                            severity="success"
                            className="p-button-info"
                        />
                    </div>
                </form>
            </Dialog>
        </>
    );
};

export const ProcessaComissaoForm = forwardRef(Modal);
