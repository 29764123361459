import React, { forwardRef, useCallback, useImperativeHandle, useMemo, useRef, useState } from "react";
import classNames from "classnames";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Divider } from "primereact/divider";
import { Button } from "primereact/button";
import { useFormik } from "formik";

import { PainelDetalheLoteSKU } from "./panel";
import { ListagemModal } from "./listagem";
import { Label } from "@/components/Label";
import { MakoCalendar } from "@/components/MakoCalendar";
import { Dropdown } from "@/components/Dropdown";
import { MakoInputQuantidadeSku } from "@/components/MakoInputs";
import { CamposObrigatorios } from "@/components/CamposObrigatorios";
import { PRODUTOS_ESTOQUE_LOTE_FIFO } from "@/assets/constants/parametros";
import { fmtCasasDecimais } from "@/assets/util/util";
import useHttp from "@/hooks/useHttp";
import useToast from "@/hooks/useToast";
import useLoadingLocal from "@/hooks/useLoadingLocal";
import useParam from "@/hooks/useParam";
import useVenda from "@/hooks/useVenda";

const URL_API = "/produtos/lotes-sku-movimentacao/";

const LoteSKU = ({ onFechar }, ref) => {
    const [visible, setVisible] = useState(false);
    const [itemVenda, setItemVenda] = useState(null);
    const [loteSKUSelecionado, setLoteSKUSelecionado] = useState(null);
    const listagemRef = useRef(null);
    const painelRef = useRef(null);
    const dropdownLoteSKURef = useRef(null);
    const { httpPost, httpPut } = useHttp();
    const { showSuccess, showWarning, showError } = useToast();
    const [loading, showLoading, hideLoading] = useLoadingLocal();
    const [loadingSugestao, showLoadingSugestao, hideLoadingSugestao] = useLoadingLocal();
    const { dadosBasicos } = useVenda();
    const { getParam } = useParam();

    const formik = useFormik({
        initialValues: {
            lote: null,
            tipo_movimentacao: null,
            quantidade: 0,
            processado: false,
        },
        onSubmit: handleSubmit,
    });

    function handleErrors(errors) {
        let errorMessages = {};
        Object.entries(errors).forEach(([k, v]) => {
            errorMessages[k] = v;
        });
        formik.setErrors(errorMessages);
    }

    async function handleSubmit(values) {
        const body = {
            ...values,
            tipo_movimentacao: dadosBasicos.tipo_movimentacao,
            evento: "vendas.itemvenda",
            evento_id: itemVenda?.id,
        };
        if (!values.id) {
            const handlers = {
                201: () => {
                    showSuccess({
                        summary: "Sucesso",
                        detail: "Movimentação de nº série / lote cadastrada com sucesso.",
                        life: 1500,
                    });
                    setTimeout(() => {
                        limparFormulario();
                        listagemRef.current?.buscarDados();
                        painelRef.current?.calcularRemanescente();
                    }, 1500);
                },
                400: ({ err }) => handleErrors(err),
            };
            showLoading();
            await httpPost({ url: URL_API, body }, handlers);
            hideLoading();
        } else {
            const handlers = {
                200: () => {
                    showSuccess({
                        summary: "Sucesso",
                        detail: "Movimentação de nº série / lote alterada com sucesso.",
                        life: 1500,
                    });
                    setTimeout(() => {
                        limparFormulario();
                        listagemRef.current?.buscarDados();
                        painelRef.current?.calcularRemanescente();
                    }, 1500);
                },
                400: ({ err }) => handleErrors(err),
            };
            showLoading();
            await httpPut({ url: `${URL_API}${values.id}/`, body }, handlers);
            hideLoading();
        }
    }

    async function sugerirMovimentacaoLoteSku(estrategia) {
        const body = {
            evento_id: itemVenda?.id,
            evento: "vendas.itemvenda",
            estrategia,
        };
        const url = `/vendas/itens-vendas/sugerir-lotes-sku/`;
        const handlers = {
            200: () => {
                listagemRef.current?.buscarDados();
                painelRef.current?.calcularRemanescente();
            },
            error: ({ status, err }) => {
                if (status !== 500) {
                    showWarning({
                        summary: "Opsss",
                        detail: err?.msg || "Não foi possível gerar as movimentações de lote.",
                        life: 3000,
                    });
                } else {
                    showError({
                        summary: "Erro :(",
                        detail: "Desculpe, não foi possível processar a requisição.",
                        life: 3000,
                    });
                }
            },
        };
        showLoadingSugestao();
        await httpPost({ url, body }, handlers);
        hideLoadingSugestao();
    }

    const limparFormulario = () => {
        formik.resetForm();
    };

    const abrirModal = (itemVenda) => {
        setVisible(true);
        setItemVenda(itemVenda);
    };

    const fecharModal = () => {
        if (typeof onFechar === "function") onFechar();
        limparFormulario();
        setItemVenda(null);
        setLoteSKUSelecionado(null);
        setVisible(false);
    };

    useImperativeHandle(ref, () => ({ abrirModal }));

    const header = itemVenda?.sku.lote_serial === "S" ? "nº de série" : "lote";

    const fifo = useMemo(() => {
        const param = getParam(PRODUTOS_ESTOQUE_LOTE_FIFO);
        return !!param?.valor;
    }, [getParam]);

    const dropdownLoteSkuOpcaoTemplate = (option) => {
        const { lote, saldo_virtual } = option;
        return (
            <span>
                {lote.lote_serial} ({fmtCasasDecimais(saldo_virtual)})
            </span>
        );
    };

    const dropdownLoteSkuSelecionadoTemplate = (option, props) => {
        if (option) return dropdownLoteSkuOpcaoTemplate(option);
        return <span>{props.placeholder}</span>;
    };

    const filtrarLotesSKUComSaldo = useCallback((lotesSKU) => {
        return lotesSKU.filter((lote) => lote.saldo > 0);
    }, []);

    return (
        <Dialog
            header={`Venda - Detalhamento de ${header}`}
            visible={visible}
            onHide={fecharModal}
            style={{ width: "50vw" }}
        >
            <PainelDetalheLoteSKU ref={painelRef} itemVenda={itemVenda} />
            <form onSubmit={formik.handleSubmit}>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-3">
                        <Label htmlFor="n-lote" label="Selecione o lote" obrigatorio />
                        <Dropdown
                            ref={dropdownLoteSKURef}
                            id="n-lote"
                            name="lote"
                            buscar={!!itemVenda}
                            url={`/produtos/saldos-lotes-sku/?centro_estocagem=${itemVenda?.centro_estocagem}&lote__sku=${itemVenda?.sku.id}`}
                            aposBuscar={filtrarLotesSKUComSaldo}
                            optionValue="id"
                            optionLabel="lote_serial"
                            itemTemplate={dropdownLoteSkuOpcaoTemplate}
                            valueTemplate={dropdownLoteSkuSelecionadoTemplate}
                            value={formik.values.lote}
                            onChange={(e) => {
                                formik.handleChange(e);
                                const callback = (lote) => e.value === lote.id;
                                const { lote: loteSKU } = dropdownLoteSKURef.current?.getObject(callback);
                                setLoteSKUSelecionado(loteSKU);
                            }}
                            className={classNames({ "p-invalid": formik.errors.lote })}
                        />
                        {formik.errors.lote && <small className="p-error">{formik.errors.lote}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <Label htmlFor="data-fabricacao" label="Data da fabricação" />
                        <MakoCalendar
                            id="data-fabricacao"
                            name="fabricacao"
                            disabled
                            valueCalendar={loteSKUSelecionado?.fabricacao}
                        />
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <Label htmlFor="vencimento" label="Vencimento" />
                        <MakoCalendar
                            id="vencimento"
                            name="vencimento"
                            disabled
                            valueCalendar={loteSKUSelecionado?.vencimento}
                        />
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <Label htmlFor="quantidade" label="Quantidade" obrigatorio />
                        <MakoInputQuantidadeSku
                            id="quantidade"
                            name="quantidade"
                            value={formik.values.quantidade}
                            onValueChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.quantidade })}
                        />
                        {formik.errors.quantidade && <small className="p-error">{formik.errors.quantidade}</small>}
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-4">
                        <Label htmlFor="enderecamento" label="Endereçamento" />
                        <InputText
                            id="enderecamento"
                            name="enderecamento"
                            disabled
                            value={loteSKUSelecionado?.enderecamento}
                        />
                    </div>
                    <div className="p-field p-col-12 p-md-4">
                        <Label htmlFor="complemento" label="Complemento" />
                        <InputText
                            id="complemento"
                            name="complemento"
                            disabled
                            value={loteSKUSelecionado?.complemento}
                        />
                    </div>
                </div>
                <CamposObrigatorios />
                <Divider />
                <div className="p-d-flex p-jc-between p-mb-2 p-mt-2">
                    <div>
                        <Button
                            type="button"
                            label={`Sugerir ${fifo ? "conforme FIFO" : "automaticamente"}`}
                            icon="far fa-lightbulb"
                            loading={loadingSugestao}
                            severity="warning"
                            onClick={() => sugerirMovimentacaoLoteSku(fifo ? "fifo" : "auto")}
                        />
                    </div>
                    <div>
                        <Button
                            type="submit"
                            label="Adicionar"
                            icon="pi pi-save"
                            loading={loading}
                            severity="success"
                            className="p-mr-2"
                        />
                        <Button
                            type="button"
                            label="Fechar"
                            icon="pi pi-times"
                            loading={loading}
                            severity="danger"
                            onClick={fecharModal}
                        />
                    </div>
                </div>
                <Divider />
            </form>
            <ListagemModal
                listagemRef={listagemRef}
                itemVendaId={itemVenda?.id}
                onClickBotaoEditar={(e) =>
                    formik.setValues({
                        ...e,
                        lote: e.lote.id,
                    })
                }
            />
        </Dialog>
    );
};

export const ModalLoteSKU = forwardRef(LoteSKU);
