import React, { useCallback, useEffect, useRef, useState } from "react";
import { MakoCalendar } from "@/components/MakoCalendar";
import { useFormik } from "formik";
import { InputText } from "primereact/inputtext";
import { Dialog } from "primereact/dialog";
import { MakoAutoComplete } from "@/components/MakoAutoComplete";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import { Button } from "primereact/button";
import { useHistory } from "react-router-dom";
import {
    SIM_NAO_BOOLEAN,
    TIPO_ESFERA_FISCAL_CHOICE,
    TIPO_MOVIMENTO_FISCAL_CHOICE,
    TIPO_OPERACAO_ENTRADA_FISCAL_CHOICE,
} from "@/assets/constants/constants";
import { dataToStr } from "@/assets/util/datas";
import permissoes from "@/assets/constants/permissoes";
import MakoListagem from "@/components/MakoListagem";
import useLoading from "@/hooks/useLoading";
import classNames from "classnames";
import useEmpresa from "@/hooks/useEmpresa";
import * as Yup from "yup";
import useToast from "@/hooks/useToast";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { BotaoDelete } from "@/components/BotaoDelete";
import { Dropdown } from "@/components/Dropdown";
import useHttp from "@/hooks/useHttp";

export const ParametrosGeraisForm = () => {
    const [adicionaCfop, setAdicionaCfop] = useState(false);
    const [cfop, setCfop] = useState(null);
    const { empresaSelecionada } = useEmpresa();
    const { showLoading, hideLoading } = useLoading();
    const { showError, showInfo, showSuccess } = useToast();
    const { opFiscal } = useLocation();
    const { httpPost, httpPatch } = useHttp();
    const history = useHistory();
    const listagemRef = useRef(null);

    const { setValues, setFieldValue, resetForm, ...formik } = useFormik({
        initialValues: {
            empresa: empresaSelecionada?.id,
            descricao: "",
            tipo_movimento: null,
            esfera: null,
            vigencia_final: null,
            destinacao: null,
            operacao_entrada: null,
            movimenta_estoque: true,
            transferencia: false,
            assistencia_tecnica: false,
            devolucao: false,
        },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                descricao: Yup.string().required("O campo é obrigatório.").max(60),
                movimenta_estoque: Yup.boolean().required("O campo é obrigatório."),
                transferencia: Yup.boolean().required("O campo é obrigatório."),
                esfera: Yup.string().required("O campo é obrigatório.").typeError("Informe uma 'esfera' válida."),
                tipo_movimento: Yup.string().required("O campo é obrigatório.").typeError("Informe um 'tipo' válido."),
                destinacao: Yup.string()
                    .nullable()
                    .when("tipo_movimento", {
                        is: (val) => val === "E",
                        then: Yup.string().required("O campo é obrigatório."),
                    }),
            });

            await formSchema.validate(values, {
                abortEarly: false,
            });

            const body = {
                ...values,
                vigencia_final: dataToStr(values.vigencia_final, "yyyy-MM-dd"),
                destinacao: values.tipo_movimento === "S" ? null : values.destinacao,
            };

            if (!values.id) {
                const handlers = {
                    201: ({ data }) => {
                        showSuccess({
                            summary: "Sucesso",
                            detail: "Operação financeira criada com sucesso!",
                            life: 1500,
                        });
                        showInfo({
                            summary: "Operação cadastrada!",
                            detail: "Agora você já pode configurar as outras informações da operação fiscal.",
                            sticky: true,
                        });
                        resetForm();
                        history.push({
                            pathname: "/fiscal/cadastros/operacoes-fiscais/form",
                            opFiscal: data,
                        });
                    },
                };

                showLoading();
                await httpPost({ url: "/fiscal/operacoes-fiscais/", body: body }, handlers);
                hideLoading();
            } else {
                delete body.ncmoperacaofiscal_set;
                delete body.itemoperacaofiscal_set;
                delete body.cfopoperacaofiscal_set;

                const handlers = {
                    200: ({ data }) => {
                        showSuccess({
                            summary: "Sucesso",
                            detail: "Operação financeira alterada com sucesso!",
                            life: 1500,
                        });
                        history.push({
                            pathname: "/fiscal/cadastros/operacoes-fiscais/form",
                            opFiscal: data,
                        });
                    },
                };

                showLoading();
                await httpPatch(
                    {
                        url: `/fiscal/operacoes-fiscais/${values.id}/`,
                        body: {
                            operacao_entrada: body.operacao_entrada || null,
                            ...body,
                        },
                    },
                    handlers
                );
                hideLoading();
            }
        } catch (error) {
            hideLoading();
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            } else showError();
        }
    }

    useEffect(() => {
        if (opFiscal) setValues({ ...opFiscal, destinacao: opFiscal.destinacao?.id || null });
    }, [opFiscal, setValues]);

    async function handleInsertCfop() {
        try {
            const handlers = {
                201: ({ data }) => {
                    showSuccess({
                        summary: "Sucesso",
                        detail: "Cfop inserido com sucesso!",
                        life: 1500,
                    });
                    setCfop(null);
                    setFieldValue("cfopoperacaofiscal_set", [data]);
                    listagemRef.current?.buscarDados();
                },
            };

            await httpPost(
                {
                    url: "/fiscal/cfop-operacoes-fiscais/",
                    body: {
                        operacao_fiscal: formik.values.id,
                        cfop: cfop.id,
                    },
                },
                handlers
            );
        } catch (error) {
            hideLoading();
            showError();
        }
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <MakoControleAcesso
                    permissao={[permissoes.FISCAL_CADASTRO_OPERACAOFISCAL_EDITAR]}
                    componente={BotaoDelete}
                    url="/fiscal/cfop-operacoes-fiscais/"
                    objetoId={rowData.id}
                    exigeConfirmacao
                    msgConfirmacao={<span>Deseja mesmo excluir o CFOP {rowData.codigo}?</span>}
                    tooltip="Deletar"
                    msgToastErroExclusao="O CFOP não pode ser excluído."
                    className="p-mt-1"
                    onDelete={() => listagemRef.current?.buscarDados()}
                />
            </div>
        );
    };

    const cabecalhoTabela = (
        <>
            <Button
                label="Adicionar"
                icon="pi pi-plus"
                className="p-button-success p-button-outlined p-mr-2"
                onClick={() => setAdicionaCfop(true)}
            />
        </>
    );

    const colunas = [
        { field: "cfop.codigo", header: "Código", style: { width: "15%" } },
        { field: "cfop.descricao", header: "Descrição" },
        {
            field: "action",
            header: "Ações",
            action: (e) => actionBodyTemplate(e),
            style: { width: "15%" },
        },
    ];

    const aposPesquisarCfop = useCallback((data) => {
        return data.map((e) => {
            return { ...e, label: `${e.codigo} - ${e.descricao}` };
        });
    }, []);

    return (
        <>
            <form onSubmit={formik.handleSubmit}>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12">
                        <label htmlFor="descricao">Descrição *</label>
                        <InputText
                            id="descricao"
                            name="descricao"
                            value={formik.values.descricao}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.descricao })}
                            autoComplete="off"
                            autoFocus
                        />
                        {formik.errors.descricao && <small className="p-error">{formik.errors.descricao}</small>}
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="tipo_movimento">Entrada / Saída *</label>
                        <Dropdown
                            id="tipo_movimento"
                            name="tipo_movimento"
                            placeholder="Selecione um tipo de movimentação"
                            options={TIPO_MOVIMENTO_FISCAL_CHOICE}
                            optionValue="id"
                            optionLabel="label"
                            value={formik.values.tipo_movimento}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.tipo_movimento })}
                        />
                        {formik.errors.tipo_movimento && (
                            <small className="p-error">{formik.errors.tipo_movimento}</small>
                        )}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="esfera">Esfera *</label>
                        <Dropdown
                            id="esfera"
                            name="esfera"
                            placeholder="Selecione uma esfera"
                            options={TIPO_ESFERA_FISCAL_CHOICE}
                            optionValue="id"
                            optionLabel="label"
                            value={formik.values.esfera}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.esfera })}
                        />
                        {formik.errors.esfera && <small className="p-error">{formik.errors.esfera}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="movimenta_estoque">Movimenta estoque *</label>
                        <Dropdown
                            id="movimenta_estoque"
                            name="movimenta_estoque"
                            placeholder="Selecione..."
                            options={SIM_NAO_BOOLEAN}
                            optionValue="id"
                            optionLabel="label"
                            value={formik.values.movimenta_estoque}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.movimenta_estoque })}
                        />
                        {formik.errors.movimenta_estoque && (
                            <small className="p-error">{formik.errors.movimenta_estoque}</small>
                        )}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="transferencia">Transferência *</label>
                        <Dropdown
                            id="transferencia"
                            name="transferencia"
                            placeholder="Selecione..."
                            options={SIM_NAO_BOOLEAN}
                            optionValue="id"
                            optionLabel="label"
                            value={formik.values.transferencia}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.transferencia })}
                        />
                        {formik.errors.transferencia && (
                            <small className="p-error">{formik.errors.transferencia}</small>
                        )}
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="devolucao">Devolução *</label>
                        <Dropdown
                            id="devolucao"
                            name="devolucao"
                            placeholder="Selecione..."
                            options={SIM_NAO_BOOLEAN}
                            optionValue="id"
                            optionLabel="label"
                            value={formik.values.devolucao}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.devolucao })}
                        />
                        {formik.errors.devolucao && <small className="p-error">{formik.errors.devolucao}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="assistencia_tecnica">Assistência técnica *</label>
                        <Dropdown
                            id="assistencia_tecnica"
                            name="assistencia_tecnica"
                            placeholder="Selecione..."
                            options={SIM_NAO_BOOLEAN}
                            optionValue="id"
                            optionLabel="label"
                            value={formik.values.assistencia_tecnica}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.assistencia_tecnica })}
                        />
                        {formik.errors.assistencia_tecnica && (
                            <small className="p-error">{formik.errors.assistencia_tecnica}</small>
                        )}
                    </div>
                    <div className="p-field p-col-12 p-md-6">
                        <label htmlFor="operacao_entrada">Classe de movimento fiscal</label>
                        <Dropdown
                            id="operacao_entrada"
                            name="operacao_entrada"
                            placeholder="Selecione uma operação"
                            options={TIPO_OPERACAO_ENTRADA_FISCAL_CHOICE}
                            optionValue="value"
                            optionLabel="label"
                            showClear
                            value={formik.values.operacao_entrada}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.operacao_entrada })}
                        />
                        {formik.errors.operacao_entrada && (
                            <small className="p-error">{formik.errors.operacao_entrada}</small>
                        )}
                    </div>
                    {formik.values.tipo_movimento === "E" ? (
                        <div className="p-field p-col-12 p-md-6">
                            <label htmlFor="destinacao">Destinação da entrada *</label>
                            <Dropdown
                                id="destinacao"
                                name="destinacao"
                                placeholder="Selecione uma destinação"
                                url="/compras/destinacoes-entradas?limit=3000&query={id, nome}"
                                filter
                                filterBy="nome"
                                optionValue="id"
                                optionLabel="nome"
                                value={formik.values.destinacao}
                                onChange={formik.handleChange}
                                className={classNames({ "p-invalid": formik.errors.destinacao })}
                            />
                            {formik.errors.destinacao && <small className="p-error">{formik.errors.destinacao}</small>}
                        </div>
                    ) : null}
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="vigencia_final">Vigência final</label>
                        <MakoCalendar
                            id="vigencia_final"
                            name="vigencia_final"
                            valueCalendar={formik.values.vigencia_final}
                            onChange={formik.handleChange}
                            className={classNames({
                                "p-invalid": formik.errors.vigencia_final,
                            })}
                        />
                        {formik.errors.vigencia_final && (
                            <small className="p-error">{formik.errors.vigencia_final}</small>
                        )}
                    </div>
                </div>
                {!!!formik.values.movimenta_estoque && formik.values.tipo_movimento === "S" && (
                    <p style={{ color: "red" }}>
                        ATENÇÃO: Se o campo <b>"Mov. saldo na emissão"</b> estiver como <b>Não</b> ao emitir uma nota
                        fiscal não será gerado movimentação de saída.
                    </p>
                )}
                <Button
                    label={formik.values.id ? "Salvar" : "Criar Operação"}
                    icon="pi pi-save"
                    className="p-button-success p-mr-2"
                    type="submit"
                />
                <Button
                    label="Resetar"
                    className="p-button-warning p-mr-2"
                    icon="pi pi-trash"
                    type="reset"
                    disabled={formik.values?.id}
                    onClick={() => resetForm()}
                />
            </form>
            {formik.values.id ? (
                <MakoListagem
                    ref={listagemRef}
                    titulo="CFOP's permitidos"
                    colunas={colunas}
                    painelEsquerdo={cabecalhoTabela}
                    urlPesquisa={`/fiscal/cfop-operacoes-fiscais?operacao_fiscal=${formik.values?.id || null}`}
                    configTabela={{
                        paginator: true,
                        lazy: true,
                    }}
                />
            ) : null}
            <Dialog
                header="Adicionar CFOP"
                visible={adicionaCfop}
                breakpoints={{ "960px": "75vw" }}
                style={{ width: "45vw", display: "block" }}
                onHide={() => setAdicionaCfop(false)}
            >
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-12">
                        <label htmlFor="cfop">CFOP *</label>
                        <MakoAutoComplete
                            id="cfop"
                            name="cfop"
                            placeholder="Busque pelo código ou descrição ... (min 3 caractéres)"
                            minCaracteresBusca={3}
                            value={cfop}
                            onChange={(e) => setCfop(e.target.value)}
                            aposPesquisar={(e) => aposPesquisarCfop(e)}
                            field="label"
                            urlSearch={`/fiscal/cfop?tipo_movimentacao=S&search=`}
                        />
                    </div>
                </div>
                {formik.values.tipo_movimento === "E" && (
                    <h7>
                        <b>OBS:</b> O código selecionado deverá ser o código informado pelo seu fornecedor.
                    </h7>
                )}
                <div className="p-grid p-ml-1 p-mt-3">
                    <Button
                        label="Inserir"
                        icon="pi pi-plus"
                        className="p-button-success"
                        type="button"
                        onClick={() => handleInsertCfop()}
                        disabled={!cfop}
                    />
                </div>
            </Dialog>
        </>
    );
};
