import React, { useMemo } from "react";

import MakoListagem from "@/components/MakoListagem";

const BASE_URl = "";

const BASE_COLUMS = [
    {
        field: "conta_financeira.nome",
        header: "Conta financeira",
    },
    {
        field: "forma_recebimento.descricao",
        header: "Forma recebimento",
    },
    {
        field: "valor_recebido",
        header: "Valor recebido",
        style: { width: "10%" },
    },
];

const BASE_COLUMN_ACTIONS = {
    field: "actions",
    header: "Ações",
    style: { width: "15%" },
};

export const Listagem = ({ listagemRef, actions }) => {
    const colunas = useMemo(() => {
        if (!actions) return BASE_COLUMS;
        const actionColum = {
            ...BASE_COLUMN_ACTIONS,
            action: (e) => actions(e),
        };
        return [...BASE_COLUMS, actionColum];
    }, [actions]);

    return (
        <MakoListagem
            ref={listagemRef}
            colunas={colunas}
            urlPesquisa={BASE_URl}
            fazerBusca={false}
            configTabela={{
                paginator: true,
                lazy: true,
            }}
        />
    );
};
