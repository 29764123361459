import React, { useRef } from "react";
import classNames from "classnames";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { useFormik } from "formik";
import { axiosPost, axiosPut } from "@/services/http";
import { useHistory } from "react-router-dom";
import { Checkbox } from "primereact/checkbox";
import * as Yup from "yup";
import useLoading from "@/hooks/useLoading";
import { PageBase } from "@/components/PageBase";
import useHttp from "@/hooks/useHttp";
import useToast from "@/hooks/useToast";

const url = "/produtos/categorias-grupos/";
const urlvoltar = "/produto/produto/grupos-categorias";

export const GrupoCategoriasForm = (props) => {
    const history = useHistory();
    const { showLoading, hideLoading } = useLoading();
    const toastRef = useRef(null);
    const { httpPost, httpPatch } = useHttp();
    const { showSuccess, showError } = useToast();

    const { resetForm, ...formik } = useFormik({
        initialValues: props.location.state
            ? props.location.state.grupo_categoria
            : {
                  id: "",
                  descricao: "",
                  padrao_sistema: false,
                  obrigatorio: false,
              },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                descricao: Yup.string().required("O campo 'vigência inicial' é obrigatório."),
            });

            await formSchema.validate(values, {
                abortEarly: false,
            });

            if (!values.id) {
                const handlers = {
                    201: () => {
                        showSuccess({
                            summary: "Sucesso",
                            detail: "Grupo de categoria cadastrado com sucesso!",
                            life: 1500,
                        });
                        resetForm();
                        history.push(urlvoltar);
                    },
                    400: () => {
                        showError({
                            summary: "Erro :(",
                            detail: "Desculpe, não conseguimos processar a sua requisição.",
                            life: 1500,
                        });
                    },
                };

                await httpPost(
                    {
                        url: url,
                        body: values,
                    },
                    handlers
                );
            } else {
                const handlers = {
                    200: () => {
                        showSuccess({
                            summary: "Sucesso",
                            detail: "Grupo de categoria alterado com sucesso!",
                            life: 1500,
                        });
                        resetForm();
                        history.push(urlvoltar);
                    },
                    400: () => {
                        showError({
                            summary: "Erro :(",
                            detail: "Desculpe, não conseguimos processar a sua requisição.",
                            life: 1500,
                        });
                    },
                };

                showLoading();
                await httpPatch(
                    {
                        url: `${url}${values.id}/`,
                        body: values,
                    },
                    handlers
                );
                hideLoading();
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
                hideLoading();
            } else {
                toastRef.current.show({
                    severity: "error",
                    summary: "Erro :(",
                    detail: "Desculpe, não conseguimos processar a sua requisição.",
                    life: 1500,
                });
                hideLoading();
            }
        }
    }

    const cancelar = () => {
        resetForm();
        history.push(urlvoltar);
    };

    return (
        <PageBase>
            <Toast ref={toastRef} />
            <h3>{!formik.values.id ? "Novo grupo de categoria" : "Manutenção de grupo de categoria"}</h3>
            <form onSubmit={formik.handleSubmit}>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-8">
                        <label htmlFor="descricao">Descricao *</label>
                        <InputText
                            id="descricao"
                            name="descricao"
                            value={formik.values.descricao}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.descricao })}
                            tooltip="Descricao que será dado ao grupo de categoria."
                            autoComplete="off"
                        />
                        {formik.errors.descricao && <small className="p-error">{formik.errors.descricao}</small>}
                    </div>
                    <div className="p-field-checkbox p-col-12 p-md-4">
                        <br></br>
                        <Checkbox
                            inputId="obrigatorio"
                            id="obrigatorio"
                            name="obrigatorio"
                            onChange={formik.handleChange}
                            checked={formik.values.obrigatorio}
                            value={formik.values.obrigatorio}
                            className="p-mt-5"
                        />
                        <label htmlFor="obrigatorio" className="p-mt-5">
                            Obrigatório
                        </label>
                    </div>
                </div>
                <p>
                    <b>* Campos obrigatórios.</b>
                </p>
                <div className="p-grid p-col-12 p-md-6">
                    <Button label="Gravar" type="submit" className="p-button-info p-mr-2 p-mb-6" />
                    <Button
                        label="Cancelar"
                        type="reset"
                        className="p-button-danger p-mr-2 p-mb-6"
                        onClick={cancelar}
                    />
                </div>
            </form>
        </PageBase>
    );
};
