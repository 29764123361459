import React, { useState, useRef } from "react";

import { ConfirmDialog } from "primereact/confirmdialog";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { Tag } from "primereact/tag";

import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import MakoListagem from "@/components/MakoListagem";

import { RegistroNecessidadeCompraForm } from "./form";

import permissoes from "@/assets/constants/permissoes";
import { axiosDelete, axiosPatch } from "@/services/http";

import useLoading from "@/hooks/useLoading";
import useToast from "@/hooks/useToast";

import { FiltroAvancadoNecessidadeCompra } from "./filtroAvancado";
import {
    AtivoFiltroTemplate,
    CodigoFiltroTemplate,
    DateFiltroTemplate,
    MoedaFiltroTemplate,
    TextoFiltroTemplate,
} from "@/components/MakoFiltrosCabecalho";

const BASE_URL = "/compras/registro-necessidade-compra/";

export const RegistroNecessidadeCompraPage = () => {
    const [showModal, setShowModal] = useState(null);
    const [deleteDialog, setDeleteDialog] = useState(false);
    const [desativarDialog, setDesativarDialog] = useState(false);
    const [registroNecessidade, setRegistroNecessidade] = useState(null);
    const [url, setUrl] = useState(BASE_URL);
    const [filtroAvancado, setFiltroAvancado] = useState(false);

    const { showLoading, hideLoading } = useLoading();
    const { showError, showSuccess } = useToast();

    const listagemRef = useRef(null);

    const confirmarStatus = (registroNecessidade) => {
        setRegistroNecessidade(registroNecessidade);
        setDesativarDialog(true);
    };

    const confirmarDelete = (registroNecessidade) => {
        setRegistroNecessidade(registroNecessidade);
        setDeleteDialog(true);
    };

    const esconderDeleteDialog = () => {
        setDeleteDialog(false);
    };

    const esconderDesativarDialog = () => {
        setDesativarDialog(false);
    };

    const desativarRegistroNecessidade = async (status) => {
        showLoading();
        const resposta = await axiosPatch(`${url}${registroNecessidade.id}/`, { ativo: status });
        hideLoading();

        if (resposta.status === 200 || resposta.status === 204) {
            showSuccess({
                summary: "Sucesso",
                detail: `Registro de necessidade de compra ${!status ? "desativado" : "ativado"} com sucesso!`,
                life: 3000,
            });

            listagemRef.current?.buscarDados();
        } else {
            showError({
                summary: "Erro",
                detail: "Desculpe, não conseguimos processar a sua requisição.",
                life: 3000,
            });
        }
        setDeleteDialog(false);
    };

    const deletarRegistroNecessidade = async () => {
        showLoading();
        const resposta = await axiosDelete(`${url}${registroNecessidade.id}/`);
        hideLoading();

        if (resposta.status === 200 || resposta.status === 204) {
            showSuccess({
                summary: "Sucesso",
                detail: `Registro de necessidade de compra deletado com sucesso!`,
                life: 3000,
            });

            listagemRef.current?.buscarDados();
        } else {
            showError({
                summary: "Erro",
                detail: "Desculpe, não conseguimos processar a sua requisição.",
                life: 3000,
            });
        }
        setDeleteDialog(false);
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <MakoControleAcesso
                    permissao={[permissoes.VENDAS_CADASTRO_REGISTRONECESSIDADECOMPRA_EDITAR]}
                    componente={Button}
                    icon="pi pi-pencil"
                    className="p-button-rounded p-button-warning p-mr-2"
                    tooltip="Editar"
                    tooltipOptions={{ position: "left" }}
                    onClick={() => {
                        setRegistroNecessidade(rowData);
                        setShowModal(true);
                    }}
                />
                <MakoControleAcesso
                    permissao={[permissoes.VENDAS_CADASTRO_REGISTRONECESSIDADECOMPRA_MANUTENCAO]}
                    componente={Button}
                    icon={rowData.ativo ? "pi pi-unlock" : "pi pi-lock"}
                    className={`p-button-rounded p-mr-2 p-button-${rowData.ativo ? "danger" : "success"}`}
                    onClick={() => confirmarStatus(rowData)}
                    tooltip={`${rowData.ativo ? "Desativar" : "Ativar"}`}
                    tooltipOptions={{ position: "left" }}
                />
                <MakoControleAcesso
                    permissao={[permissoes.VENDAS_CADASTRO_REGISTRONECESSIDADECOMPRA_EXCLUIR]}
                    componente={Button}
                    icon="pi pi-trash"
                    className="p-button-rounded p-button-danger"
                    onClick={() => confirmarDelete(rowData)}
                    tooltip="Excluir"
                    tooltipOptions={{ position: "left" }}
                />
            </div>
        );
    };

    const fecharDialog = () => {
        setShowModal(false);
    };

    const cabecalhoTabela = (
        <>
            <MakoControleAcesso
                permissao={[permissoes.VENDAS_CADASTRO_REGISTRONECESSIDADECOMPRA_INCLUIR]}
                componente={Button}
                label="Novo"
                icon="pi pi-plus"
                className="p-button-success p-mr-2"
                onClick={() => setShowModal(true)}
            />
            <Button
                label="Filtro Avançado"
                icon="pi pi-filter"
                className="p-button-help p-mr-2"
                onClick={() => setFiltroAvancado(true)}
            />
        </>
    );

    const STATUS = [
        { value: true, label: "Ativo", tag: "success" },
        { value: false, label: "Inativo", tag: "danger" },
    ];

    const colunas = [
        { field: "id", header: "Código", style: { width: "5%" }, filter: true, filterElement: CodigoFiltroTemplate },
        { field: "solicitante", header: "Solicitante", filter: true, filterElement: TextoFiltroTemplate },
        { field: "descritivo", header: "Descrição", filter: true, filterElement: TextoFiltroTemplate },
        {
            field: "data",
            header: "Data",
            dateFormat: "dd/MM/yyyy",
            style: { width: "10%" },
            filter: true,
            filterElement: DateFiltroTemplate,
        },
        {
            field: "preco_venda",
            header: "Preço Venda",
            money: true,
            style: { width: "10%" },
            filter: true,
            filterElement: MoedaFiltroTemplate,
        },
        {
            field: "ativo",
            header: "Ativo",
            align: "center",
            style: { width: "10%" },
            action: ({ ativo }) => {
                const _tag = STATUS.find((item) => item.value === ativo);
                return <Tag severity={_tag.tag} value={_tag.label.toLocaleUpperCase()} />;
            },
            filter: true,
            filterElement: AtivoFiltroTemplate,
        },
        {
            field: "action",
            header: "Ações",
            action: (e) => actionBodyTemplate(e),
            style: { width: "10%" },
        },
    ];

    const onSubmit = (isEdit = false) => {
        listagemRef?.current.buscarDados();
        if (isEdit) setRegistroNecessidade(null);
    };

    const onCancel = () => {
        setRegistroNecessidade(null);
        setShowModal(false);
    };

    const fecharFiltro = () => {
        setFiltroAvancado(() => false);
    };

    const onConfirmFiltro = (newUrl) => {
        setUrl(newUrl);
        fecharDialog();
    };

    return (
        <div className="p-grid">
            <div className="p-col-12">
                <div className="card">
                    <MakoListagem
                        ref={listagemRef}
                        titulo="Registros de Necessidade de Compra"
                        colunas={colunas}
                        painelEsquerdo={cabecalhoTabela}
                        urlPesquisa={url}
                        configTabela={{
                            paginator: true,
                            lazy: true,
                        }}
                        filtros={{
                            id: { value: null, matchMode: "equals" },
                            solicitante: {
                                operator: "and",
                                constraints: [{ value: "", matchMode: "unaccent_icontains" }],
                            },
                            descritivo: {
                                operator: "and",
                                constraints: [{ value: "", matchMode: "unaccent_icontains" }],
                            },
                            data: { value: null, matchMode: "equals" },
                            preco_venda: { value: null, matchMode: "equals" },
                            ativo: { value: true, matchMode: "equals" },
                        }}
                    />
                    <ConfirmDialog
                        visible={desativarDialog}
                        onHide={esconderDesativarDialog}
                        header="Confirmação"
                        message={
                            registroNecessidade && (
                                <span>
                                    {`Deseja realmente ${
                                        registroNecessidade.status ? "ativar" : "desativar"
                                    } este registro de necessidade de compra: `}
                                    <b>{registroNecessidade.id}</b>?
                                </span>
                            )
                        }
                        icon="pi pi-info-circle p-mr-3"
                        accept={() => desativarRegistroNecessidade(!registroNecessidade.ativo)}
                        acceptLabel="Sim"
                        acceptClassName="p-button-danger"
                        reject={esconderDesativarDialog}
                        rejectLabel="Não"
                    />
                    <ConfirmDialog
                        visible={deleteDialog}
                        onHide={esconderDeleteDialog}
                        header="Confirmação"
                        message={
                            registroNecessidade && (
                                <span>
                                    {`Deseja realmente deletar este registro de necessidade de compra: `}
                                    <b>{registroNecessidade.id}</b>?
                                </span>
                            )
                        }
                        icon="pi pi-info-circle p-mr-3"
                        accept={deletarRegistroNecessidade}
                        acceptLabel="Sim"
                        acceptClassName="p-button-danger"
                        reject={esconderDeleteDialog}
                        rejectLabel="Não"
                    />
                    <Dialog
                        header={`${registroNecessidade ? "Editar" : "Cadastrar"} necessidade de compra`}
                        visible={showModal}
                        breakpoints={{ "960px": "75vw" }}
                        style={{ width: "80vw" }}
                        onHide={fecharDialog}
                    >
                        <RegistroNecessidadeCompraForm
                            onSubmit={onSubmit}
                            registro={registroNecessidade}
                            onClose={onCancel}
                        />
                    </Dialog>
                    <Dialog
                        header="Filtro avançado"
                        visible={filtroAvancado}
                        breakpoints={{ "960px": "75vw" }}
                        style={{ width: "75vw" }}
                        onHide={fecharFiltro}
                    >
                        <FiltroAvancadoNecessidadeCompra
                            onConfirm={onConfirmFiltro}
                            onCancel={fecharFiltro}
                            url={BASE_URL}
                        />
                    </Dialog>
                </div>
            </div>
        </div>
    );
};
