import React, { useCallback, useEffect, useState } from "react";
import { Dropdown } from "primereact/dropdown";
import { isAfter, isBefore, isEqual } from "date-fns";

import { dataToStr } from "@/assets/util/datas";
import { axiosGet } from "@/services/http";
import useLoadingLocal from "@/hooks/useLoadingLocal";
import useToast from "@/hooks/useToast";

const BASE_URL = "/financeiro/competencias/";

export const MakoDropdownCompetenciaFinanceira = ({
    habilitarInativas,
    aposBuscar,
    disabled = false,
    periodo_inicio,
    periodo_fim,
    ...props
}) => {
    const [competenciasFinanceira, setCompetenciasFinanceira] = useState([]);
    const [loading, showLoading, hideLoading] = useLoadingLocal();
    const { showError } = useToast();

    const desabilitarCompetenciaFinanceira = (competencia) => {
        const { data_inicio, data_fim } = competencia;
        if (habilitarInativas) return false;
        if (
            (isBefore(new Date(data_inicio), new Date()) || isEqual(new Date(data_inicio), new Date())) &&
            (isAfter(new Date(data_fim), new Date()) || isEqual(new Date(data_fim), new Date()))
        ) {
            return false;
        }
        return true;
    };

    const buscarCompetenciasFinanceira = useCallback(async () => {
        let params = {};
        if (periodo_inicio) params.data_inicio__lte = dataToStr(periodo_inicio, "yyyy-MM-dd");
        if (periodo_fim) params.data_fim__lte = dataToStr(periodo_fim, "yyyy-MM-dd");
        showLoading();
        const { status, data } = await axiosGet(BASE_URL, { params });
        hideLoading();

        if (status === 200) {
            setCompetenciasFinanceira(!aposBuscar ? data.results : aposBuscar(data.results));
        } else {
            showError({
                summary: "Erro :(",
                detail: "Desculpe, não conseguimos listar suas competências financeiras.",
                life: 3000,
            });
        }
    }, [showLoading, hideLoading, showError, aposBuscar, periodo_fim, periodo_inicio]);

    useEffect(() => {
        buscarCompetenciasFinanceira();
    }, [buscarCompetenciasFinanceira]);

    const competenciaFinanceiraSelecionadaTemplate = (option, props) => {
        if (option) {
            return (
                <span>{`${option.grupo_competencia} ${dataToStr(option.data_inicio, "dd/MM/yyyy")} até ${dataToStr(
                    option.data_fim,
                    "dd/MM/yyyy"
                )}`}</span>
            );
        }

        return <span>{props.placeholder}</span>;
    };

    const opcaoCompetenciaFinanceiraTemplate = (option) => {
        return (
            <span>{`${option.grupo_competencia} ${dataToStr(option.data_inicio, "dd/MM/yyyy")} até ${dataToStr(
                option.data_fim,
                "dd/MM/yyyy"
            )}`}</span>
        );
    };

    return (
        <Dropdown
            options={competenciasFinanceira}
            optionValue="id"
            optionLabel="codigo"
            valueTemplate={competenciaFinanceiraSelecionadaTemplate}
            itemTemplate={opcaoCompetenciaFinanceiraTemplate}
            optionDisabled={desabilitarCompetenciaFinanceira}
            emptyMessage="Nenhum registro disponível."
            emptyFilterMessage="Nenhum registro encontrado."
            placeholder="Selecione"
            showClear
            disabled={loading || disabled}
            {...props}
        />
    );
};
