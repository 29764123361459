import React, { useCallback } from "react";

import * as Yup from "yup";
import { useFormik } from "formik";

import { classNames } from "primereact/utils";

import { MakoButton as Button } from "@/components/MakoButton";
import { MakoActionsButtons } from "@/components/MakoActionsButtons";
import { MakoInputMoeda } from "@/components/MakoInputMoeda";
import { Dropdown } from "@/components/Dropdown";
import { Label } from "@/components/Label";

import useCaixaMovimento from "@/hooks/useCaixaMovimento";
import useLoadingLocal from "@/hooks/useLoadingLocal";
import useHttp from "@/hooks/useHttp";

const BASE_URL = "/financeiro/caixas/{caixa}/pendencias/{pendencia}/processar";

const INITIAL_VALUES = {
    conta_financeira: null,
    valor_original: 0,
    valor_recebido: 0,
    troco: 0,
    valor_calculado: 0,
    juros: 0,
    multa: 0,
    descontos: 0,
};

export const ReceberDinheiro = ({ pendencia, successCallback = () => {} }) => {
    const [loading, showLoading, hideLoading] = useLoadingLocal();
    const { caixaMov } = useCaixaMovimento();
    const { httpPost } = useHttp();

    const { setValues, setFieldValue, ...formik } = useFormik({
        initialValues: {
            ...INITIAL_VALUES,
            conta_financeira: caixaMov?.caixa?.conta_financeira?.id,
            valor_original: pendencia?.valor_receber,
            valor_calculado: pendencia?.valor_receber,
            forma_recebimento: pendencia?.forma_recebimento,
            id: pendencia?.id,
            tipo_chave: pendencia?.tipo_chave,
        },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values, formikHelpers) {
        try {
            const formSchema = Yup.object().shape({
                conta_financeira: Yup.number().nullable().required("O campo 'conta financeira' é obrigatório"),
            });

            await formSchema.validate(values, { abortEarly: false });

            const { id, tipo, troco, valor_recebido, ...body } = values;

            let success = false;
            let dataCallback = null;

            const handlers = {
                200: ({ data }) => {
                    success = true;
                    dataCallback = data;
                    formikHelpers.resetForm({ values: INITIAL_VALUES });
                },
            };

            const url = BASE_URL.replace("{caixa}", caixaMov?.id).replace("{pendencia}", id);
            showLoading();
            await httpPost({ url, body }, handlers);
            hideLoading();
            if (success) successCallback(dataCallback);
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formikHelpers.setErrors(errorMessages);
            }
        }
    }

    const onChangeValor = useCallback(
        (e) => {
            const { value } = e || {};
            setFieldValue("troco", parseFloat(value || 0) - formik.values.valor_original);
            formik.handleChange(e);
        },
        [formik, setFieldValue]
    );

    return (
        <form onSubmit={formik.handleSubmit}>
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12 p-md-4">
                    <Label htmlFor="conta_financeira" label="Conta financeira" obrigatorio />
                    <Dropdown
                        id="conta_financeira"
                        name="conta_financeira"
                        url="/financeiro/contas-financeiras?limit=200"
                        optionValue="id"
                        optionLabel="descricao"
                        showClear={false}
                        value={formik.values.conta_financeira}
                        onChange={formik.handleChange}
                        disabled
                        className={classNames({ "p-invalid": formik.errors.conta_financeira })}
                    />
                    {formik.errors.conta_financeira && (
                        <small className="p-error">{formik.errors.conta_financeira}</small>
                    )}
                </div>
                <div className="p-field p-col-12 p-md-4">
                    <Label htmlFor="valor_recebido" label="Valor" obrigatorio />
                    <MakoInputMoeda
                        id="valor_recebido"
                        name="valor_recebido"
                        valueMoeda={formik.values.valor_recebido}
                        onChangeMoeda={onChangeValor}
                        min={formik.values.valor_original}
                    />
                </div>
                <div className="p-field p-col-12 p-md-4">
                    <Label htmlFor="troco" label="Troco" />
                    <MakoInputMoeda
                        id="troco"
                        name="troco"
                        valueMoeda={formik.values.troco}
                        onChangeMoeda={formik.handleChange}
                        min={formik.values.troco}
                        disabled
                    />
                </div>
            </div>
            <MakoActionsButtons className="p-jc-end">
                <Button loading={loading} label="Confirmar" type="submit" className="p-button p-button-success" />
            </MakoActionsButtons>
        </form>
    );
};
