import React, { createContext, useState, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { addDays } from "date-fns";

import { axiosGet, axiosPost, axiosPatch } from "@/services/http";
import { dataToStr } from "@/assets/util/datas";
import { formatarCasasDecimais } from "@/assets/util/util";
import { gerarParcelas } from "@/assets/util/calcFinanceiros";
import { parseNumber } from "@/assets/helpers/number";
import useToast from "@/hooks/useToast";

const RecebimentoContext = createContext({});

export const RecebimentoProvider = ({ children }) => {
    const [loading, setLoading] = useState(false);
    const [parcelar, setParcelar] = useState(false);
    const [submit, setSubmit] = useState(false);
    const [submitParcelas, setSubmitParcelas] = useState(false);
    const [recebimento, setRecebimento] = useState(null);
    const [recebimentos, setRecebimentos] = useState([]);
    const { showSuccess, showError } = useToast();
    const history = useHistory();

    const handleSubmitRecebimento = (values, parcelaUnica = false) => {
        setRecebimento(values);
        setSubmit(true);
        if (parcelaUnica) {
            const { historico_padrao, valor_total, vencimento, ...receb } = values;
            let _parcela = {
                ...receb,
                vencimento,
                valor_total,
                agrupador: new Date().getTime(),
                historico_padrao: historico_padrao.id,
                quantidade_parcelas: 1,
                numero_parcela: 1,
                valor: valor_total,
                previsao: false,
            };
            if (_parcela?.id > 0) {
                _parcela = {
                    ..._parcela,
                    quantidade_parcelas: values.quantidade_parcelas,
                    numero_parcela: values.numero_parcela,
                    _editado: true,
                };
            }
            setRecebimentos([_parcela]);
            setSubmitParcelas(true);
        } else {
            setRecebimentos([]);
            setSubmitParcelas(false);
        }
    };

    const handleLancarParcelas = (value) => {
        const { tipo_parcela, repeticao, quantidade, data_primeira_parcela, ...configParcela } = value;
        const qtdParcelas = parseNumber(quantidade);
        let valorParcelas = [];
        if (tipo_parcela === "dividir") {
            valorParcelas = gerarParcelas(recebimento.valor_total, qtdParcelas);
        } else {
            valorParcelas.push(recebimento.valor_total);
        }
        const _parcelas = [];
        const { historico_padrao, valor_total, ...receb } = recebimento;
        const agrupador = new Date().getTime();
        for (let numParcela = 0; numParcela < qtdParcelas; numParcela++) {
            _parcelas.push({
                ...receb,
                ...configParcela,
                agrupador,
                historico_padrao: historico_padrao.id,
                vencimento: addDays(data_primeira_parcela, parseNumber(repeticao) * numParcela),
                quantidade_parcelas: qtdParcelas,
                numero_parcela: numParcela + 1,
                valor_total: tipo_parcela === "dividir" ? valor_total : valor_total * qtdParcelas,
                valor: tipo_parcela === "dividir" ? valorParcelas[numParcela] : valorParcelas[0],
            });
        }
        setRecebimentos(_parcelas);
        setSubmitParcelas(true);
    };

    const handleEditarRecebimento = useCallback(
        async (values, index, multiUpdate = false) => {
            const { vencimento, valor, historico_original, paga_comissao_recebimento, previsao } = values;
            const _recebimentos = [...recebimentos];
            if (!multiUpdate) {
                _recebimentos[index] = {
                    ...recebimentos[index],
                    vencimento,
                    valor,
                    historico_original,
                    paga_comissao_recebimento,
                    previsao,
                    _editado: true,
                };
            } else {
                for (let _index = index; _index < recebimentos.length; _index++) {
                    _recebimentos[_index] = {
                        ...recebimentos[_index],
                        valor,
                        _editado: true,
                    };
                }
            }
            const totalParcelas = _recebimentos.reduce((total, receb) => total + parseNumber(receb.valor), 0);
            setSubmitParcelas(parseNumber(totalParcelas) === parseNumber(_recebimentos[0].valor_total));
            setRecebimentos([..._recebimentos]);
        },
        [recebimentos]
    );

    const handleRemoverParcela = useCallback(
        async (index, repeticao) => {
            const data_primeira_parcela = recebimentos[0]?.vencimento;
            const _recebimentos = [...recebimentos].toSpliced(index, 1).map((item, i) => ({
                ...item,
                numero_parcela: i + 1,
                quantidade_parcelas: recebimentos.length - 1,
                vencimento: addDays(data_primeira_parcela, parseNumber(repeticao) * i),
            }));
            const totalParcelas = _recebimentos.reduce((total, receb) => total + parseNumber(receb.valor), 0);
            setSubmitParcelas(parseNumber(totalParcelas) === parseNumber(_recebimentos[0].valor_total));
            setRecebimentos([..._recebimentos]);
        },
        [recebimentos]
    );

    const handleBuscarRecebimentos = useCallback(
        async (agrupador) => {
            if (agrupador) {
                setLoading(true);
                const { status, data } = await axiosGet(`/financeiro/recebimentos/?agrupador=${agrupador}`);
                setLoading(false);
                if (status === 200) {
                    const _recebimentos = data.results.map((receb) => ({
                        ...receb,
                        empresa: receb.empresa.id,
                        forma_recebimento: receb.forma_recebimento?.id,
                        template_rateio: receb.template_rateio.id,
                    }));
                    setRecebimento(_recebimentos[0]);
                    setRecebimentos(_recebimentos);
                    setSubmit(true);
                    setSubmitParcelas(true);
                } else {
                    showError({
                        summary: "Erro :(",
                        detail: "Desculpe, não conseguimos processar sua requisição.",
                        life: 3000,
                    });
                }
            }
        },
        [showError]
    );

    const handlePersistirRecebimentos = useCallback(async () => {
        const body = recebimentos.map((receb) => ({
            ...receb,
            devedor: receb.devedor.id,
            data_lancamento: dataToStr(receb.data_lancamento, "yyyy-MM-dd"),
            data_emissao: dataToStr(receb.data_emissao, "yyyy-MM-dd"),
            vencimento: dataToStr(receb.vencimento, "yyyy-MM-dd"),
            historico_padrao:
                typeof receb.historico_padrao === "object" ? receb.historico_padrao.id : receb.historico_padrao,
            valor: formatarCasasDecimais(receb.valor),
        }));
        if (recebimento.id === 0) {
            setLoading(true);
            const { status, data } = await axiosPost("/financeiro/recebimentos/", body);
            setLoading(false);
            if (status === 201) {
                showSuccess({
                    summary: "Sucesso!",
                    detail: "Recebimentos cadastrados com sucesso.",
                    life: 1500,
                });
                if (parcelar) {
                    setRecebimentos(data);
                } else {
                    history.push("/financeiro/financeiro/recebimentos");
                }
            } else {
                showError({
                    summary: "Erro :(",
                    detail: "Desculpe, não conseguimos processar sua requisição.",
                    life: 3000,
                });
            }
        } else {
            const alterados = body.filter((receb) => receb._editado);
            await Promise.all(
                alterados.map(async (receb) => {
                    setLoading(true);
                    const { status } = await axiosPatch(`/financeiro/recebimentos/${receb.id}/`, receb);
                    setLoading(false);
                    if (status !== 200) {
                        showError({
                            summary: "Erro :(",
                            detail: "Desculpe, não conseguimos processar sua requisição.",
                            life: 3000,
                        });
                    }
                })
            );
        }
    }, [recebimento, recebimentos, parcelar, history, showSuccess, showError]);

    return (
        <RecebimentoContext.Provider
            value={{
                loading,
                submit,
                parcelar,
                submitParcelas,
                recebimento,
                recebimentos,
                setParcelar,
                setRecebimentos,
                setSubmitParcelas,
                handleSubmitRecebimento,
                handleBuscarRecebimentos,
                handleLancarParcelas,
                handleEditarRecebimento,
                handlePersistirRecebimentos,
                handleRemoverParcela,
            }}
        >
            {children}
        </RecebimentoContext.Provider>
    );
};

export default RecebimentoContext;
