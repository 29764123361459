import React, { useCallback, useRef, useState } from "react";

import { useHistory } from "react-router-dom";

import { VisualizacaoRequisicao } from "./visualizacaoRequisicao";
import { FormFiltroAvancado } from "./formFiltroAvancado";

import { SplitButton } from "primereact/splitbutton";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { Tag } from "primereact/tag";

import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import MakoListagem from "@/components/MakoListagem";

import { TIPO_STATUS_REQUISICAO } from "@/assets/constants/constants";
import { dataToStr } from "@/assets/util/datas";
import permissoes from "@/assets/constants/permissoes";

import useLoading from "@/hooks/useLoading";
import useToast from "@/hooks/useToast";

import { axiosPatch } from "@/services/http";
import useRelatorio from "@/hooks/useRelatorio";
import { RELATORIO_MATERIAIS_RECIBOSAIDA } from "@/assets/constants/relatorios";

export const ListagemRequisicao = () => {
    const [url, setUrl] = useState("/materiais/requisicoes/");
    const [visible, setVisible] = useState(false);
    const [modalProps, setModalProps] = useState({
        header: "",
        style: undefined,
        content: "",
    });

    const { showSuccess, showError } = useToast();
    const { showLoading, hideLoading } = useLoading();
    const { solicitarRelatorio } = useRelatorio();

    const listagemRef = useRef();
    const history = useHistory();

    const headerTemplate = (rowData) => {
        return (
            <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                <span>{`Solicitação de código: ${rowData.id} |`}</span>
                {statusTemplate(rowData.status)}
            </div>
        );
    };

    const handleUpdateStatus = async (id, status) => {
        showLoading();

        const request = await axiosPatch(`/materiais/requisicoes/${id}/`, { status: status });

        hideLoading();

        if (request.status === 200) {
            showSuccess({
                summary: "Sucesso :)",
                detail: "Requisição atualizada.",
                life: 3000,
            });

            listagemRef?.current.buscarDados();
        } else {
            showError({
                summary: "Erro :(",
                detail: "Não conseguimos processar sua requisição.",
                life: 3000,
            });
        }
    };

    const handleRecibo = (id) => {
        solicitarRelatorio({ chave: RELATORIO_MATERIAIS_RECIBOSAIDA, filtros: { requisicao_id: id } });
    };

    const actionBodyTemplate = (rowData) => {
        let mutavelMenu = null;
        let subMenus = [
            {
                label: "Editar",
                disabled: rowData.status !== "A" && rowData.status !== "P",
                command: () =>
                    history.push({
                        pathname: "/materiais/requisicao/form",
                        state: {
                            type: "update",
                            requisicao: rowData,
                        },
                    }),
            },
            {
                label: "Encerrar",
                disabled: rowData.status === "E",
                command: () => handleUpdateStatus(rowData.id, "E"),
            },
            {
                label: "Cancelar",
                disabled: rowData.status === "C",
                command: () =>
                    history.push({
                        pathname: "/materiais/requisicao/form",
                        state: {
                            type: "update",
                            requisicao: { ...rowData, status: "C" },
                        },
                    }),
            },
        ];

        if (rowData.status === "A") {
            mutavelMenu = {
                label: "Fechar",
                command: () => handleUpdateStatus(rowData.id, "F"),
            };
        }

        if (rowData.status === "F" || rowData.status === "C") {
            mutavelMenu = {
                label: "Abrir",
                command: () => handleUpdateStatus(rowData.id, "A"),
            };
        }

        if (mutavelMenu !== null) subMenus.unshift(mutavelMenu);

        if (rowData.status === "E" || rowData.status === "F" || rowData.status === "P") {
            subMenus.push({
                label: "Gerar recibo",
                disabled: true,
                command: () => handleRecibo(rowData.id),
            });
        }

        return (
            <div className="actions">
                <MakoControleAcesso
                    permissao={[permissoes.MATERIAIS_REQUISICAO_VISUALIZAR]}
                    componente={Button}
                    icon="pi pi-eye"
                    className="p-button-rounded p-button-info p-mr-2"
                    tooltip="Visualizar."
                    tooltipOptions={{ position: "left" }}
                    onClick={() =>
                        handleModal({
                            header: headerTemplate(rowData),
                            content: <VisualizacaoRequisicao data={{ ...rowData }} />,
                            style: { width: "80vw" },
                        })
                    }
                />
                <MakoControleAcesso
                    permissao={[permissoes.MATERIAIS_REQUISICAO_EDITAR]}
                    componente={SplitButton}
                    dropdownIcon="pi pi-cog"
                    className="p-button-rounded p-button-warning"
                    model={subMenus}
                    buttonProps={{ style: { display: "none" } }}
                    menuButtonProps={{ className: "p-button-rounded" }}
                />
            </div>
        );
    };

    const statusTemplate = (status) => {
        const tipo = TIPO_STATUS_REQUISICAO.filter((item) => item.value === status)[0];
        return <Tag value={tipo.label.toLocaleUpperCase()} severity={tipo.badge} />;
    };

    const colunas = [
        {
            field: "id",
            header: "Código",
            style: { width: "10%" },
        },
        {
            field: "solicitante",
            header: "Solicitante",
            action: ({ solicitante }) => solicitante.nome,
        },
        {
            field: "observacao",
            header: "Observacao",
        },
        {
            field: "status",
            header: "Situação",
            style: { width: "12%" },
            align: "center",
            action: ({ status }) => statusTemplate(status),
        },
        {
            field: "data_hora",
            header: "Data/Hora",
            style: { width: "12%" },
            action: ({ data_hora }) => dataToStr(data_hora, "dd/MM/yyyy HH:mm"),
        },
        {
            field: "action",
            header: "Ações",
            style: { width: "10%" },
            align: "center",
            action: (e) => actionBodyTemplate(e),
        },
    ];

    const handleModal = (props) => {
        setModalProps({ ...props });
        setVisible(true);
    };

    const fecharModal = useCallback(() => {
        setVisible(false);
    }, []);

    const updateUrl = useCallback((value) => {
        setUrl(value);
    }, []);

    const painelEsquerdoTabela = (
        <>
            <MakoControleAcesso
                permissao={[permissoes.MATERIAIS_REQUISICAO_SOLICITAR]}
                componente={Button}
                label="Novo"
                icon="pi pi-plus"
                className="p-button-success p-mr-2 p-mb-2"
                onClick={() =>
                    history.push({
                        pathname: "/materiais/requisicao/form",
                        state: { type: "new" },
                    })
                }
            />
            <Button
                label="Filtro Avançado"
                icon="pi pi-filter"
                className="p-button-help p-mr-2 p-mb-2"
                onClick={() =>
                    handleModal({
                        header: "Filtro avançado",
                        content: <FormFiltroAvancado onClose={fecharModal} updateUrl={updateUrl} />,
                        style: { width: "85vw" },
                    })
                }
            />
        </>
    );

    return (
        <div className="p-grid">
            <div className="p-col-12">
                <div className="card">
                    <MakoListagem
                        ref={listagemRef}
                        titulo="Requisições"
                        painelEsquerdo={painelEsquerdoTabela}
                        colunas={colunas}
                        urlPesquisa={url}
                        configTabela={{
                            paginator: true,
                            lazy: true,
                        }}
                    />
                </div>
            </div>
            <Dialog visible={visible} header={modalProps.header} style={modalProps.style} onHide={fecharModal}>
                {modalProps.content}
            </Dialog>
        </div>
    );
};
