import React, { useCallback, useEffect, useState, memo } from "react";

import { MultiSelect } from "primereact/multiselect";
import useAxiosPrivate from "@/hooks/useAxiosPrivate";
import useToast from "@/hooks/useToast";

const Component = ({
    url = "",
    options,
    aposBuscar,
    setObjects,
    buscar = true,
    disabled = false,
    id = "",
    ...props
}) => {
    const [loading, setLoading] = useState(false);
    const [dados, setDados] = useState([]);

    const { showWarning, showError } = useToast();
    const { axiosGet } = useAxiosPrivate();

    const fetchDados = useCallback(
        async (signal) => {
            if (!url || !buscar) return null;

            setLoading(true);
            const { status, data } = await axiosGet(url, { signal });
            setLoading(false);
            if (status === 200) {
                let { results } = data;
                if (typeof aposBuscar === "function") results = await aposBuscar(results);
                setDados(results);
                if (typeof setObjects === "function") setObjects(results);
            } else if (status === 401) {
                showWarning({
                    summary: "Acesso expirado!",
                    detail: "Você não está autenticado, faça login novamente.",
                    life: 3000,
                });
            } else if (status !== 500) {
                showError({
                    summary: "Erro :(",
                    detail: "Desculpe, não conseguimos listar suas informações.",
                    life: 3000,
                });
            }
        },
        [url, buscar, axiosGet, aposBuscar, setObjects, showWarning, showError]
    );

    useEffect(() => {
        const controller = new AbortController();
        if (options instanceof Array) {
            setDados(options);
        } else {
            fetchDados(controller.signal);
        }

        return () => {
            controller.abort();
        };
    }, [fetchDados, options]);

    return (
        <MultiSelect
            options={dados}
            disabled={loading || disabled}
            placeholder={!loading ? "Selecione" : "Buscando..."}
            selectedItemsLabel="{0} items selecionadas"
            emptyFilterMessage="Nenhum registro encontrado"
            inputId={id}
            {...props}
        />
    );
};

export const MakoMultiSelect = memo(Component);
