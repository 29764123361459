import React, { createContext, useState, useCallback } from "react";

import * as pd from "@/assets/util/persistenciaDjango";
import { formatarCasasDecimais } from "@/assets/util/util";
import { parseNumber } from "@/assets/helpers/number";
import { axiosGet, axiosPost, axiosPut, axiosPatch } from "@/services/http";
import useAuth from "@/hooks/useAuth";
import useEmpresa from "@/hooks/useEmpresa";
import useToast from "@/hooks/useToast";

const EntradaContext = createContext({});

export const EntradaProvider = ({ children }) => {
    const [loadingEntrada, setLoadingEntrada] = useState(false);
    const [dadosBasicos, setDadosBasicos] = useState(null);
    const [itensEntrada, setItensEntrada] = useState([]);
    const [ordensCompra, setOrdensCompra] = useState([]);
    const { empresaSelecionadaId } = useEmpresa();
    const { user } = useAuth();
    const { showError } = useToast();

    const formatarEntradaApiParaFrontend = (data) => {
        const { categoria_titulo, ...rest } = data;
        const _dadosBasicos = {
            ...rest,
            categoria_titulo: categoria_titulo.id,
        };
        setDadosBasicos(_dadosBasicos);
        if (rest.ordem_compra_principal) {
            setOrdensCompra([rest.ordem_compra_principal]);
        }
        return _dadosBasicos;
    };

    const handleDadosBasicos = useCallback(
        async (values) => {
            const entrada = {
                ...values,
                fornecedor: values.fornecedor.id,
                representante: values.representante?.id || null,
                numero_parcelas: values.intervalo_parcelas.split(",").length,
                ordem_compra_principal: values.ordem_compra_principal?.id || null,
            };
            if (!values.id) {
                const novaEntrada = {
                    ...entrada,
                    usuario_cadastro: user?.id,
                    empresa: empresaSelecionadaId,
                };
                const { status, data: dataResponse } = await axiosPost("/compras/entradas/", novaEntrada);
                let data = null;
                if (status === 201) {
                    data = formatarEntradaApiParaFrontend(dataResponse);
                }
                return { status, data };
            } else {
                const entradaEditada = { ...entrada, status: "P" };
                delete entradaEditada.empresa;
                delete entradaEditada.usuario_cadastro;
                delete entradaEditada.fornecedor;
                let diffEntrada = {};
                for (const [k, v] of Object.entries(entradaEditada)) {
                    if (v !== dadosBasicos[k]) {
                        diffEntrada[k] = v;
                    }
                }
                if (Object.keys(diffEntrada).length > 0) {
                    const { status, data: dataResponse } = await axiosPatch(
                        `/compras/entradas/${values.id}/`,
                        diffEntrada
                    );
                    let data = null;
                    if (status === 200) {
                        data = formatarEntradaApiParaFrontend(dataResponse);
                    }
                    return { status, data };
                }
                return { status: 204, data: values };
            }
        },
        [dadosBasicos, empresaSelecionadaId, user]
    );

    const atualizarValoresEntrada = useCallback(async () => {
        if (dadosBasicos) {
            const campos = [
                "valor_total_produtos",
                "valor_total_servicos",
                "valor_total_frete",
                "valor_total_seguro",
                "valor_total_outros",
                "valor_custos_adicionais",
                "valor_custos_extras",
                "valor_antecipacao",
                "valor_total_parcelas",
                "valor_total_entrada",
            ];
            const params = {
                query: `{${campos.join(",")}}`,
            };
            const { status, data } = await axiosGet(`/compras/entradas/${dadosBasicos.id}/`, { params });
            if (status === 200) {
                setDadosBasicos((old) => ({
                    ...old,
                    valor_total_produtos: parseNumber(data.valor_total_produtos),
                    valor_total_servicos: parseNumber(data.valor_total_servicos),
                    valor_total_frete: parseNumber(data.valor_total_frete),
                    valor_total_seguro: parseNumber(data.valor_total_seguro),
                    valor_total_outros: parseNumber(data.valor_total_outros),
                    valor_custos_adicionais: parseNumber(data.valor_custos_adicionais),
                    valor_custos_extras: parseNumber(data.valor_custos_extras),
                    valor_antecipacao: parseNumber(data.valor_antecipacao),
                    valor_total_parcelas: parseNumber(data.valor_total_parcelas),
                    valor_total_entrada: parseNumber(data.valor_total_entrada),
                }));
            }
        }
    }, [dadosBasicos]);

    const handleChecarDocEmitente = useCallback(
        (doc) => {
            const { fornecedor } = dadosBasicos;
            if (doc === fornecedor?.identificacao) {
                return true;
            }
            return false;
        },
        [dadosBasicos]
    );

    // Funções referentes aos Itens da Entrada
    const handleItemEntrada = useCallback(
        async (values, op) => {
            let _itensEntrada = [...itensEntrada];
            const item = {
                ...values,
                valor_total_item: formatarCasasDecimais(values.valor_total_item),
                entrada: dadosBasicos.id,
                sku: values.sku.id,
            };
            if (op === pd.OP_CRUD_DJANGO.novo) {
                const { status, data } = await axiosPost("/compras/itens-entradas/", item);
                if (status === 201) {
                    _itensEntrada.push(data);
                    setItensEntrada(_itensEntrada);
                }
                return { status, data };
            } else if (op === pd.OP_CRUD_DJANGO.editar) {
                const { status, data } = await axiosPut(`/compras/itens-entradas/${values.id}/`, item);
                if (status === 200) {
                    const index = _itensEntrada?.findIndex((e) => e.id === values.id);
                    _itensEntrada[index] = data;
                    setItensEntrada(_itensEntrada);
                }
                return { status, data };
            }
        },
        [itensEntrada, dadosBasicos]
    );

    // Funções referentes as Ordens de Compra
    const handleAddOrdemCompra = useCallback(
        (ordemCompra) => {
            const oc = ordensCompra.find((e) => e.ordem_compra.id === ordemCompra.id);
            if (!oc) {
                let _ordensCompra = [...ordensCompra];
                _ordensCompra = pd.criarListaProvisoriaParaPersistencia(
                    _ordensCompra,
                    { ordem_compra: ordemCompra },
                    pd.OP_CRUD_DJANGO.novo
                );
                setOrdensCompra(_ordensCompra);
            }
        },
        [ordensCompra]
    );

    const handleRemoverOrdemCompra = useCallback(async () => {
        const entrada = {
            ordem_compra_principal: null,
        };
        setLoadingEntrada(true);
        const { status } = await axiosPatch(`/compras/entradas/${dadosBasicos?.id}/`, entrada);
        setLoadingEntrada(false);
        if (status === 200) {
            setOrdensCompra([]);
            setDadosBasicos((prev) => ({ ...prev, ordem_compra_principal: null }));
        } else {
            showError({
                summary: "Erro :(",
                detail: "Desculpe, não foi possível excluir a ordem de compra da entrada.",
                life: 3000,
            });
        }
    }, [dadosBasicos, showError]);

    const handleGetEntrada = useCallback(async (id) => {
        setLoadingEntrada(true);
        const json = await axiosGet(`/compras/entradas/${id}/`);
        setLoadingEntrada(false);
        if (json.status === 200) {
            formatarEntradaApiParaFrontend(json.data);
        }
    }, []);

    return (
        <EntradaContext.Provider
            value={{
                loadingEntrada,
                dadosBasicos,
                itensEntrada,
                ordensCompra,
                permiteAlterar: dadosBasicos?.status ? ["P", "V"].includes(dadosBasicos.status) : true,
                atualizarValoresEntrada,
                handleDadosBasicos,
                handleChecarDocEmitente,
                handleItemEntrada,
                handleAddOrdemCompra,
                handleRemoverOrdemCompra,
                handleGetEntrada,
            }}
        >
            {children}
        </EntradaContext.Provider>
    );
};

export default EntradaContext;
