import React, { useRef, useState } from "react";
import { Button } from "primereact/button";

import { MakoDialogConfirmarSenha } from "@/components/MakoDialogConfirmarSenha";
import { axiosPut } from "@/services/http";
import useToast from "@/hooks/useToast";
import useNotificacao from "@/hooks/useNotificacao";

export const BotaoReprovarNotificacao = ({ notificacao, comentario }) => {
    const [loading, setLoading] = useState(false);
    const modalSenhaRef = useRef(null);
    const { showSuccess, showWarning, showError } = useToast();
    const { marcarMsgComoLida } = useNotificacao();

    async function reprovarNotificacao() {
        const body = {
            notificacao: notificacao.id,
            comentario: comentario || "",
        };
        setLoading(true);
        const { status, data } = await axiosPut("/pessoas/reprovar-notificacao/", body);
        setLoading(false);
        if (status === 200) {
            marcarMsgComoLida();
            showSuccess({
                summary: "Sucesso!",
                detail: data.msg,
                life: 2000,
            });
        } else if (status === 400 || status === 404) {
            showWarning({
                summary: "Falhou...",
                detail: data.msg,
                life: 3000,
            });
        } else {
            showError({
                summary: "Erro :(",
                detail: "Desculpe, não foi possível processar a sua requisição.",
                life: 3000,
            });
        }
    }

    const handleClick = () => {
        if (notificacao.tipo === "AS") {
            modalSenhaRef.current?.abrirModal();
        } else {
            reprovarNotificacao();
        }
    };

    return (
        <>
            <Button
                icon="pi pi-times"
                label="Reprovar"
                loading={loading}
                disabled={notificacao.status !== "P"}
                className="p-button-danger"
                onClick={handleClick}
            />
            <MakoDialogConfirmarSenha ref={modalSenhaRef} onConfirm={() => reprovarNotificacao()} />
        </>
    );
};
