import React, { useEffect, useRef, useState, useCallback, useImperativeHandle, forwardRef } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { axiosGet } from "@/services/http";
import { SelectButton } from "primereact/selectbutton";
import useLoading from "@/hooks/useLoading";
import axios from "axios";
import useEmpresa from "@/hooks/useEmpresa";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "@/components/Dropdown";

const Modal = ({ nota }, ref) => {
    const { showLoading, hideLoading } = useLoading();
    const toastRef = useRef(null);
    const [emailNaoCadastrado, setEmailNaoCadastrado] = useState("");
    const [emailCadastrado, setEmailCadastrado] = useState("");
    const [emailManual, setEmailManual] = useState(false);
    const { empresaSelecionada } = useEmpresa();
    const [visible, setVisible] = useState(false);

    async function handleSubmit() {
        try {
            showLoading();
            const apiServico = await axiosGet("/configuracoes/api-servicos/");
            hideLoading();

            const body = [
                {
                    EmpCNPJ: empresaSelecionada.identificacao,
                    Modulo: nota === 55 ? "NFe" : "NFCe",
                    NFSe: {
                        Email: emailManual ? emailNaoCadastrado : emailCadastrado,
                        NumeroRPSInicial: nota.numero,
                        NumeroRPSFinal: nota.numero,
                        Serie: nota.serie,
                    },
                },
            ];

            showLoading();
            const resposta = await axios.post(
                `${process.env.REACT_APP_INTEGRACAO_FISCAL}/documents/${apiServico.data.results[0].chave_acesso}/${apiServico.data.results[0].cnpj_integrado}/enviar-email`,
                body
            );
            hideLoading();

            if (resposta.data && resposta.data[0])
                if (resposta.data[0].Codigo === 100 || resposta.data[0].Codigo === 101) {
                    toastRef.current.show({
                        severity: "success",
                        summary: "Sucesso!",
                        detail: `${resposta.data[0].Descricao}!
                         \n
                         Código: ${resposta.data[0].Codigo}`,
                        life: 5000,
                    });
                    fecharDialog(false);
                } else {
                    toastRef.current.show({
                        severity: "warn",
                        summary: "Mensagem",
                        detail: `${resposta.data[0].Descricao} \n
                    Código: ${resposta.data[0].Codigo}`,
                        life: 5000,
                    });
                }
            else if (resposta.data)
                toastRef.current.show({
                    severity: "warn",
                    summary: "Mensagem",
                    detail: `${resposta.data.Descricao} \n
                    Código: ${resposta.data.Codigo}`,
                    life: 5000,
                });
        } catch (error) {
            toastRef.current.show({
                severity: "error",
                summary: "Erro",
                detail: `Falha ao comunicar com o agente fiscal.`,
                life: 3000,
            });
        }
    }

    const EMAIL_OPCOES = [
        { label: "Email cadastrado", value: false },
        { label: "Envio manual", value: true },
    ];

    useEffect(() => {
        if (emailManual) setEmailCadastrado("");
        else setEmailNaoCadastrado("");
    }, [emailManual]);

    const fecharDialog = () => {
        setEmailNaoCadastrado("");
        setEmailCadastrado("");
        setEmailManual(false);
        setVisible(false);
    };

    useImperativeHandle(ref, () => ({ setVisible }));

    return (
        <>
            <Toast ref={toastRef} />
            <Dialog
                header="Confirmação de envio"
                visible={visible}
                breakpoints={{ "960px": "80vw" }}
                style={{ width: "30vw" }}
                onHide={() => fecharDialog()}
            >
                <div className="p-col-12">
                    <form>
                        <div className="p-fluid p-formgrid p-grid">
                            <div className="p-field p-col-12 p-md-12">
                                <SelectButton
                                    value={emailManual}
                                    options={EMAIL_OPCOES}
                                    onChange={(e) => setEmailManual(e.value)}
                                />
                            </div>
                            {!emailManual ? (
                                <div className="p-field p-col-12 p-md-12">
                                    <Dropdown
                                        id="email_cadastrado"
                                        name="email_cadastrado"
                                        value={emailCadastrado}
                                        placeholder="Selecione um email"
                                        showClear
                                        url={`/pessoas/emails-perfis?perfil=${nota?.destinatario?.id}&query={id, email}`}
                                        buscar={nota?.destinatario}
                                        onChange={({ value }) => setEmailCadastrado(value)}
                                        optionLabel="email"
                                        optionValue="email"
                                        emptyMessage="Destinatário não possui emails cadastrados"
                                    />
                                </div>
                            ) : (
                                <div className="p-field p-col-12 p-md-12">
                                    <InputText
                                        id="conteudo"
                                        placeholder="Digite um email válido"
                                        value={emailNaoCadastrado}
                                        onChange={({ value }) => setEmailNaoCadastrado(value)}
                                        autoComplete="off"
                                    />
                                </div>
                            )}
                        </div>
                        <div className="p-grid p-col-12 p-md-12">
                            <Button label="Enviar" type="button" className="p-button-info" onClick={handleSubmit} />
                        </div>
                    </form>
                </div>
            </Dialog>
        </>
    );
};

export const EnvioEmailFormModal = forwardRef(Modal);
