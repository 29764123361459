import React, { createContext, useCallback, useEffect, useState } from "react";

import { useLocation } from "react-router-dom";
import useLocalStorage from "use-local-storage";

import {
    FINANCEIRO_PLANODECONTAS_PADRAO_SANGRIA,
    FINANCEIRO_PLANODECONTAS_PADRAO_SUPRIMENTO,
    FINANCEIRO_PLANODECONTAS_PADRAO_TRANSFERENCIA,
} from "@/assets/constants/parametros";
import storageKeys from "@/assets/constants/storage";

import useAxiosPrivate from "@/hooks/useAxiosPrivate";
import useParam from "@/hooks/useParam";
import useToast from "@/hooks/useToast";
import useAuth from "@/hooks/useAuth";

const CaixaMovimentoContext = createContext({});

const PADRAO_PLANO = {
    sangria: null,
    suprimento: null,
    transferencia: null,
};

export const CaixaMovimentoProvider = ({ children }) => {
    const [storageCaixa, setStorageCaixa] = useLocalStorage(storageKeys.CAIXA_KEY);
    const [padraoPlanos, setPadraoPlanos] = useState(PADRAO_PLANO);
    const { pathname } = useLocation();

    const { axiosGet } = useAxiosPrivate();
    const { user } = useAuth();

    const { showWarning, showError } = useToast();
    const { getParam } = useParam();

    const setCaixaUsuario = useCallback(
        (cx, clear = false) => {
            setStorageCaixa(cx);
        },
        [setStorageCaixa]
    );

    const atualizarResumos = useCallback(
        (resumos = []) => {
            setStorageCaixa((prev) => ({ ...prev, resumos }));
        },
        [setStorageCaixa]
    );

    const loadCaixa = useCallback(async () => {
        if (user) {
            const { status, data } = await axiosGet(
                `/financeiro/caixas-movimento/?operador=${user.id}&datahora_fechamento__isnull=True`
            );
            let _caixa = null;
            if (status === 200 && data.results?.length) {
                _caixa = data.results[0];
            }
            setCaixaUsuario(_caixa);
            return _caixa;
        }
    }, [setCaixaUsuario, user, axiosGet]);

    const clearCaixa = useCallback(() => {
        setStorageCaixa(null);
    }, [setStorageCaixa]);

    const obterCaixaAbertoMaisRecentePorEmpresa = useCallback(
        async (empresaId) => {
            const { status, data } = await axiosGet(`/financeiro/caixas/caixa-aberto/?empresa=${empresaId}`);
            if (status !== 200) {
                showError({
                    summary: "Erro :(",
                    detail: "Não foi possível obter o caixa aberto mais recente.",
                    life: 3000,
                });
                return null;
            }
            if (Object.keys(data).length === 0) return null;
            return data;
        },
        [axiosGet, showError]
    );

    const verifyParams = useCallback(() => {
        if (pathname?.includes("gerenciar-caixa")) {
            let _padrao = PADRAO_PLANO;
            let _params = getParam(FINANCEIRO_PLANODECONTAS_PADRAO_SANGRIA);
            if (_params) {
                _padrao.sangria = _params.valor;
            }

            _params = getParam(FINANCEIRO_PLANODECONTAS_PADRAO_SUPRIMENTO);
            if (_params) {
                _padrao.suprimento = _params.valor;
            }

            _params = getParam(FINANCEIRO_PLANODECONTAS_PADRAO_TRANSFERENCIA);
            if (_params) {
                _padrao.transferencia = _params.valor;
            }

            let message = "Você precisa definir o plano de contas padrão para o parâmetro:";
            let hasNull = false;

            Object.keys(_padrao).forEach((key) => {
                if (_padrao[key] === null) {
                    hasNull = true;
                    message = `${message} ${key},`;
                }
            });

            if (hasNull) {
                showWarning({
                    summary: "Alerta :(",
                    detail: message.slice(0, message.length - 1),
                    life: 5000,
                });
            } else {
                setPadraoPlanos(_padrao);
            }
        }
    }, [getParam, pathname, showWarning]);

    useEffect(() => {
        verifyParams();
    }, [verifyParams]);

    useEffect(() => loadCaixa(), [loadCaixa]);

    return (
        <CaixaMovimentoContext.Provider
            value={{
                loadCaixa,
                clearCaixa,
                obterCaixaAbertoMaisRecentePorEmpresa,
                padraoPlanos,
                caixaMov: storageCaixa,
                setCaixaUsuario,
                atualizarResumos,
            }}
        >
            {children}
        </CaixaMovimentoContext.Provider>
    );
};

export default CaixaMovimentoContext;
