import React, { useEffect, useRef, useState, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { InputText } from "primereact/inputtext";
import { Checkbox } from "primereact/checkbox";
import { Dropdown } from "primereact/dropdown";
import { TreeSelect } from "primereact/treeselect";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { useFormik } from "formik";
import { NATUREZA_PLANO_CONTAS } from "@/assets/constants/constants";
import { axiosPost, axiosPatch, axiosGet } from "@/services/http";

import useLoading from "@/hooks/useLoading";
import classNames from "classnames";
import * as Yup from "yup";

export const FinanceiroPlanoContasForm = (props) => {
    const [contaPai, setContaPai] = useState(null);
    const [planosConta, setPlanosConta] = useState([]);
    const { showLoading, hideLoading } = useLoading();
    const toastRef = useRef(null);
    const history = useHistory();

    const { setFieldValue, setValues, ...formik } = useFormik({
        initialValues: props.location.state?.planContas
            ? { ...props.location.state.planContas.data, versao: props.location.state.planContas.data.versao.id }
            : {
                  codigo: "",
                  descricao: "",
                  natureza: null,
                  estrutura: "",
                  conta_titulo: false,
                  plano_conta_financeira: null,
                  ativo: true,
              },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                codigo: Yup.string().required("O campo 'código' é obrigatório."),
                descricao: Yup.string().required("O campo 'descrição' é obrigatório."),
                natureza: Yup.string().required("O campo 'natureza' é obrigatório.").typeError("Informe uma 'natureza' válida."),
                estrutura: Yup.string().required("O campo 'estrutura' é obrigatório."),
                plano_conta_financeira: Yup.number()
                    .when("conta_titulo", {
                        is: (val) => !val,
                        then: Yup.number().required("Necessário selecionar uma conta pai.").typeError("Informe uma 'conta' válida."),
                    })
                    .nullable(),
            });

            await formSchema.validate(values, {
                abortEarly: false,
            });

            const planoConta = {
                ...values,
                versao: props.location.state.versaoId,
                nivel: contaPai ? contaPai.nivel + 1 : 1,
            };

            if (!values.id) {
                showLoading();
                const resposta = await axiosPost("/financeiro/planos-contas-financeiras/", planoConta);
                hideLoading();

                if (resposta.status === 201) {
                    toastRef.current.show({
                        severity: "success",
                        summary: "Sucesso",
                        detail: "Plano de conta cadastrado com sucesso!",
                        life: 1500,
                    });

                    formik.resetForm();
                    setTimeout(() => {
                        history.push({
                            pathname: "/financeiro/plano-contas",
                            state: {
                                versaoId: props.location.state.versaoId,
                            },
                        });
                    }, 1500);
                } else {
                    toastRef.current.show({
                        severity: "error",
                        summary: "Erro",
                        detail: "Desculpe, não conseguimos processar a sua requisição.",
                        life: 1500,
                    });
                }
            } else {
                showLoading();
                const resposta = await axiosPatch(`/financeiro/planos-contas-financeiras/${values.id}/`, planoConta);
                hideLoading();

                if (resposta.status === 200) {
                    toastRef.current.show({
                        severity: "success",
                        summary: "Sucesso",
                        detail: "Plano de conta alterado com sucesso!",
                        life: 1500,
                    });

                    setTimeout(() => {
                        history.push({
                            pathname: "/financeiro/plano-contas",
                            state: {
                                versaoId: props.location.state.versaoId,
                            },
                        });
                    }, 1500);
                } else {
                    toastRef.current.show({
                        severity: "error",
                        summary: "Erro",
                        detail: "Desculpe, não conseguimos processar a sua requisição.",
                        life: 1500,
                    });
                }
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};

                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });

                formik.setErrors(errorMessages);
            }
        }
    }

    const buscarContaPai = useCallback(
        async (contaPaiId) => {
            showLoading();
            const resposta = await axiosGet(`/financeiro/planos-contas-financeiras/${contaPaiId}/`);
            hideLoading();

            if (resposta.status === 200) {
                setContaPai(resposta.data);
                setFieldValue("plano_conta_financeira", contaPaiId);
                setFieldValue("natureza", resposta.data.natureza);
            }
        },
        [showLoading, hideLoading, setFieldValue]
    );

    const listarPlanoContasTitulo = useCallback(
        async (versaoId) => {
            showLoading();
            const resposta = await axiosGet(
                `/financeiro/planos-contas-financeiras-estruturas/?id_versao=${versaoId}&apenas_titulo=True`
            );
            hideLoading();

            if (resposta.status === 200) {
                setPlanosConta(resposta.data);
            } else {
                toastRef.current.show({
                    severity: "error",
                    summary: "Mako",
                    detail: "Desculpe, não foi possível listar os planos de contas.",
                    life: 3000,
                });
            }
        },
        [showLoading, hideLoading]
    );

    useEffect(() => {
        if (props.location.state?.versaoId) {
            listarPlanoContasTitulo(props.location.state.versaoId);
        }
    }, [props.location.state?.versaoId, listarPlanoContasTitulo]);

    useEffect(() => {
        if (props.location.state?.contaPaiId) {
            buscarContaPai(props.location.state?.contaPaiId);
        }
    }, [props.location.state?.contaPaiId, buscarContaPai]);

    useEffect(() => {
        if (props.location.state?.planContas?.data) {
            setValues(props.location.state.planContas.data);
            buscarContaPai(props.location.state.planContas.data.plano_conta_financeira);
        }
    }, [props.location.state?.planContas, setValues, buscarContaPai]);

    function gerarCodigo() {
        let id = "";
        let chars = "252626516002312680123456789012345678901234567890123456789";

        for (let i = 0; i < 5; i++) {
            id += chars.charAt(Math.floor(Math.random() * chars.length));
        }

        setFieldValue("codigo", id);

        toastRef.current.show({
            severity: "success",
            summary: "Mako",
            detail: "Código gerado com sucesso!",
            life: 1500,
        });
    }

    return (
        <div className="p-grid">
            <Toast ref={toastRef} />
            <div className="p-col-12">
                <div className="card">
                    <h3>{!formik.values.id ? "Inserir plano de conta" : "Manutenção de plano de conta"}</h3>
                    <form onSubmit={formik.handleSubmit}>
                        <div className="p-fluid p-formgrid p-grid">
                            <div className="p-field p-col-12 p-md-3">
                                <label htmlFor="natureza">Natureza *</label>
                                <Dropdown
                                    id="natureza"
                                    name="natureza"
                                    placeholder="D/C"
                                    options={NATUREZA_PLANO_CONTAS}
                                    optionValue="id"
                                    optionLabel="label"
                                    value={formik.values.natureza}
                                    onChange={formik.handleChange}
                                    className={classNames({ "p-invalid": formik.errors.natureza })}
                                />
                                {formik.errors.natureza && <small className="p-error">{formik.errors.natureza}</small>}
                            </div>
                            <div className="p-field p-col-12 p-md-6">
                                <label htmlFor="plano_conta_financeira">Conta pai *</label>
                                <TreeSelect
                                    id="plano_conta_financeira"
                                    name="plano_conta_financeira"
                                    options={planosConta}
                                    filter
                                    filterBy="data.descricao"
                                    emptyMessage="Nenhum registro encontrado."
                                    placeholder="Selecione uma conta pai"
                                    value={formik.values.plano_conta_financeira}
                                    onChange={(e) => buscarContaPai(e.value)}
                                    className={classNames({ "p-invalid": formik.errors.plano_conta_financeira })}
                                />
                                {formik.errors.plano_conta_financeira && (
                                    <small className="p-error">{formik.errors.plano_conta_financeira}</small>
                                )}
                            </div>
                            <div className="p-field-checkbox p-mt-4 p-col-12 p-md-3">
                                <Checkbox
                                    inputId="conta-titulo"
                                    name="conta_titulo"
                                    onChange={formik.handleChange}
                                    checked={formik.values.conta_titulo}
                                    value={formik.values.conta_titulo}
                                />
                                <label htmlFor="conta-titulo">Conta título</label>
                            </div>
                        </div>
                        <div className="p-fluid p-formgrid p-grid">
                            <div className="p-field p-col-12 p-md-2">
                                <label htmlFor="codigo">Código *</label>
                                <div className="p-inputgroup">
                                    <InputText
                                        id="codigo"
                                        name="codigo"
                                        keyfilter={/^[A-Z0-9]/}
                                        value={formik.values.codigo}
                                        onChange={formik.handleChange}
                                        className={classNames({ "p-invalid": formik.errors.codigo })}
                                    />
                                    <Button
                                        type="button"
                                        icon="pi pi-refresh"
                                        className="p-button-info"
                                        tooltip="Gerar código aleatório"
                                        onClick={() => gerarCodigo()}
                                    />
                                </div>
                                {formik.errors.codigo && <small className="p-error">{formik.errors.codigo}</small>}
                            </div>
                            <div className="p-field p-col-12 p-md-6">
                                <label htmlFor="descricao">Descrição *</label>
                                <InputText
                                    id="descricao"
                                    name="descricao"
                                    value={formik.values.descricao}
                                    onChange={formik.handleChange}
                                    className={classNames({ "p-invalid": formik.errors.descricao })}
                                />
                                {formik.errors.descricao && (
                                    <small className="p-error">{formik.errors.descricao}</small>
                                )}
                            </div>
                            <div className="p-field p-col-12 p-md-2">
                                <label htmlFor="estrutura">Estrutura *</label>
                                <InputText
                                    id="estrutura"
                                    name="estrutura"
                                    value={formik.values.estrutura}
                                    keyfilter={/[.0-9]/}
                                    onChange={formik.handleChange}
                                    className={classNames({ "p-invalid": formik.errors.estrutura })}
                                />
                                {formik.errors.estrutura && (
                                    <small className="p-error">{formik.errors.estrutura}</small>
                                )}
                            </div>
                            <div className="p-field-checkbox p-mt-4 p-col-12 p-md-2">
                                <Checkbox
                                    inputId="ativo"
                                    name="ativo"
                                    onChange={formik.handleChange}
                                    checked={formik.values.ativo}
                                    value={formik.values.ativo}
                                />
                                <label htmlFor="ativo">{formik.values.ativo ? "Ativo" : "Inativo"}</label>
                            </div>
                        </div>
                        <p>
                            <b>* Campos obrigatórios.</b>
                        </p>
                        <div className="p-grid p-col-12 p-md-6">
                            <Button label="Gravar" type="submit" className="p-button-info p-mr-2" />
                            <Button
                                label="Cancelar"
                                type="reset"
                                className="p-button-danger p-mr-2"
                                onClick={() =>
                                    history.push({
                                        pathname: "/financeiro/plano-contas",
                                        state: {
                                            versaoId: props.location.state.versaoId,
                                        },
                                    })
                                }
                            />
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};
