import React, { useCallback, useEffect, useState } from "react";
import { useFormik } from "formik";
import { InputNumber } from "primereact/inputnumber";
import { MakoCalendar } from "@/components/MakoCalendar";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { DataScroller } from "primereact/datascroller";
import { Button } from "primereact/button";
import useCrediario from "@/hooks/useCrediario";
import useLoading from "@/hooks/useLoading";
import classNames from "classnames";
import * as Yup from "yup";
import { dataToStr } from "@/assets/util/datas";
import { parseNumberToMoneyHTML } from "@/assets/util/util";
import { TIPO_STATUS_ANALISE_CHOICE } from "@/assets/constants/constants";
import useAuth from "@/hooks/useAuth";
import useToast from "@/hooks/useToast";

export const AnaliseCrediarioForm = () => {
    const [loading, setLoading] = useState(false);
    const { user } = useAuth();
    const { crediario, handleAnaliseCrediario } = useCrediario();
    const { showSuccess, showError } = useToast();

    const { setFieldValue, setValues, resetForm, ...formik } = useFormik({
        initialValues: {
            data_analise: new Date(),
            limite_liberado: 0,
            valor_aprovado: 0,
            valor_endosso: 0,
            usuario_nome: user?.nome,
            usuario_aprovacao: user,
            status: "A",
            op: "novo",
        },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            setLoading(true);
            const formSchema = Yup.object().shape({
                valor_aprovado: Yup.number().required("O campo é obrigatório.").typeError("Informe um valor válido."),
            });

            await formSchema.validate(values, {
                abortEarly: false,
            });

            handleAnaliseCrediario(values.op, {
                ...values,
                data_analise: dataToStr(values.data_analise, "yyyy-MM-dd"),
                valor_endosso:
                    values.valor_aprovado > values.limite_liberado
                        ? (values.valor_aprovado - values.limite_liberado).toFixed(2)
                        : 0,
            });

            showSuccess({
                summary: "Sucesso",
                detail: "Análise registrada com sucesso!",
                life: 2500,
            });
            resetForm();
            setLoading(false);
        } catch (error) {
            setLoading(false);
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};

                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });

                formik.setErrors(errorMessages);
            } else {
                showError({
                    summary: "Erro",
                    detail: "Desculpe, não conseguimos processar a sua requisição.",
                    life: 1500,
                });
            }
        }
    }

    const analiseTemplate = useCallback((data) => {
        const limite = parseNumberToMoneyHTML(data.valor_aprovado);
        const endosso = parseNumberToMoneyHTML(data.valor_endosso);
        return (
            <div className="product-list-item">
                <div className="product-list-detail">
                    <span className={`product-badge status-${data.status === "A" ? "instock" : "outofstock"}`}>
                        <i className={`pi pi-${data.status === "A" ? "check" : "times"} product-category-icon`} />
                        {`${data.status === "A" ? "APROVADO" : "REPROVADO"} (${data.data_analise})`}
                    </span>
                    <div className="product-name">{`Limite aprovado: ${limite.props.children} ${
                        endosso.props.children ? `(endosso: ${endosso.props.children})` : ""
                    }`}</div>
                    <i className="pi pi-user product-category-icon" />
                    <span className="product-category">{`Validado pelo usuário: ${
                        data.usuario_aprovacao
                            ? `${data.usuario_aprovacao.nome} (#${data.usuario_aprovacao?.id})`
                            : "SISTEMA"
                    }`}</span>
                </div>
            </div>
        );
    }, []);

    useEffect(() => {
        if (crediario?.limite_liberado) {
            setFieldValue("limite_liberado", crediario?.limite_liberado);
            setFieldValue("valor_aprovado", crediario?.limite_liberado);
        }
    }, [crediario]);

    return (
        <div className="p-grid">
            <div className="p-col-12">
                <form onSubmit={formik.handleSubmit}>
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-12 p-md-3">
                            <label htmlFor="limite_liberado">Limite de crédito *</label>
                            <InputNumber
                                id="limite_liberado"
                                name="limite_liberado"
                                mode="currency"
                                currency="BRL"
                                locale="pt-BR"
                                disabled
                                value={formik.values.limite_liberado}
                                onValueChange={formik.handleChange}
                                className={classNames({ "p-invalid": formik.errors.limite_liberado })}
                            />
                            {formik.errors.limite_liberado && (
                                <small className="p-error">{formik.errors.limite_liberado}</small>
                            )}
                        </div>
                    </div>
                    <div className="card">
                        <h5>Registrar Análise</h5>
                        <div className="p-fluid p-formgrid p-grid">
                            <div className="p-field p-col-12 p-md-3">
                                <label htmlFor="data_analise">Data análise *</label>
                                <MakoCalendar
                                    id="data_analise"
                                    name="data_analise"
                                    valueCalendar={formik.values.data_analise}
                                    onChange={formik.handleChange}
                                    className={classNames({
                                        "p-invalid": formik.errors.data_analise,
                                    })}
                                    disabled
                                />
                                {formik.errors.data_analise && (
                                    <small className="p-error">{formik.errors.data_analise}</small>
                                )}
                            </div>
                            <div className="p-field p-col-12 p-md-3">
                                <label htmlFor="usuario_nome">Usuário *</label>
                                <InputText
                                    id="usuario_nome"
                                    name="usuario_nome"
                                    value={formik.values.usuario_nome}
                                    onChange={formik.handleChange}
                                    className={classNames({ "p-invalid": formik.errors.usuario_nome })}
                                    autoComplete="off"
                                    disabled
                                />
                                {formik.errors.usuario_nome && (
                                    <small className="p-error">{formik.errors.usuario_nome}</small>
                                )}
                            </div>
                            <div className="p-field p-col-12 p-md-3">
                                <label htmlFor="valor_aprovado">Limite aprovado *</label>
                                <InputNumber
                                    id="valor_aprovado"
                                    name="valor_aprovado"
                                    mode="currency"
                                    currency="BRL"
                                    locale="pt-BR"
                                    maxFractionDigits={2}
                                    minFractionDigits={2}
                                    disabled={formik.values.status === "R"}
                                    value={formik.values.valor_aprovado}
                                    onValueChange={formik.handleChange}
                                    className={classNames({ "p-invalid": formik.errors.valor_aprovado })}
                                />
                                {formik.errors.valor_aprovado && (
                                    <small className="p-error">{formik.errors.valor_aprovado}</small>
                                )}
                            </div>
                            <div className="p-field p-col-12 p-md-3">
                                <label htmlFor="valor_endosso">Limite endosso *</label>
                                <InputNumber
                                    id="valor_endosso"
                                    name="valor_endosso"
                                    mode="currency"
                                    currency="BRL"
                                    locale="pt-BR"
                                    maxFractionDigits={2}
                                    minFractionDigits={2}
                                    disabled
                                    value={Math.abs(formik.values?.valor_aprovado - crediario?.limite_liberado)}
                                    onValueChange={formik.handleChange}
                                    className={classNames({ "p-invalid": formik.errors.valor_endosso })}
                                />
                                {formik.errors.valor_endosso && (
                                    <small className="p-error">{formik.errors.valor_endosso}</small>
                                )}
                            </div>
                        </div>
                        <div className="p-fluid p-formgrid p-grid">
                            <div className="p-field p-col-12 p-md-3">
                                <label htmlFor="status">Status *</label>
                                <Dropdown
                                    id="status"
                                    name="status"
                                    placeholder="Selecione uma relação"
                                    options={TIPO_STATUS_ANALISE_CHOICE}
                                    value={formik.values.status}
                                    onChange={(e) => {
                                        setFieldValue("status", e.target.value);
                                        if (e.target.value === "R") {
                                            setFieldValue("valor_aprovado", 0);
                                            setFieldValue("valor_endosso", 0);
                                        }
                                    }}
                                    className={classNames({ "p-invalid": formik.errors.status })}
                                />
                                {formik.errors.status && <small className="p-error">{formik.errors.status}</small>}
                            </div>
                        </div>
                        <div className="p-grid">
                            <div className="p-field p-col-12 p-md-6">
                                <Button
                                    type="submit"
                                    icon="pi pi-save"
                                    disabled={crediario.status === "I"}
                                    label="Registrar análise"
                                    className="p-button-success p-mr-2 p-mb-2"
                                />
                                {crediario.status === "I" ? (
                                    <label style={{ color: "#FF0000" }}>
                                        Valide o crediário para liberar o registro de análise.
                                    </label>
                                ) : null}
                            </div>
                        </div>
                    </div>
                </form>
                <div className="list-demo">
                    {!loading ? (
                        <DataScroller
                            value={crediario.analisecrediariovenda_set.reverse()}
                            itemTemplate={analiseTemplate}
                            rows={3}
                            inline
                            scrollHeight="300px"
                            header="Análises Registradas"
                            emptyMessage="Nenhuma análise registrada anteriormente."
                        />
                    ) : (
                        <i className="pi pi-spin pi-spinner" style={{ fontSize: "8rem" }}></i>
                    )}
                </div>
            </div>
        </div>
    );
};
