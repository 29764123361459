import React, { useImperativeHandle, useState, useEffect, useCallback, forwardRef } from "react";
import classNames from "classnames";
import { Dialog } from "primereact/dialog";
import { InputTextarea } from "primereact/inputtextarea";
import { Button } from "primereact/button";
import { useFormik } from "formik";
import * as Yup from "yup";

import { MakoBuscaSkuPersonalizada } from "@/components/MakoBuscaSkuPersonalizada";
import { MakoInputMoeda } from "@/components/MakoInputMoeda";
import { MakoInputPercent } from "@/components/MakoInputs/MakoInputPercent";
import { MakoInputQuantidadeSku } from "@/components/MakoInputs/MakoInputQuantidadeSku";
import { CamposObrigatorios } from "@/components/CamposObrigatorios";
import { Dropdown } from "@/components/Dropdown";
import { axiosGet } from "@/services/http";
import { MakoItemNcm } from "@/components/MakoItemNcm";
import { dataToStr } from "@/assets/util/datas";
import { axiosPost, axiosPatch } from "@/services/http";
import useOrdemCompra from "@/hooks/useOrdemCompra";
import useAuth from "@/hooks/useAuth";
import useEmpresa from "@/hooks/useEmpresa";
import useToast from "@/hooks/useToast";
import { parseNumber } from "@/assets/helpers/number";
import { formatarCasasDecimais } from "@/assets/util/util";

const ItensOrdemCompra = ({ ordemCompraId, onSave }, ref) => {
    const [baseCalculoIPI, setBaseCalculoIPI] = useState(100);
    const [baseCalculoICMS, setBaseCalculoICMS] = useState(100);
    const [ufFornecedorValido, setUfFornecedorValido] = useState(false);
    const [ufEmpresaValido, setUfEmpresaValido] = useState(false);
    const [verificaUF, setVerificaUf] = useState(false);
    const [visible, setVisible] = useState(false);
    const [unidadesMedidaCompra, setUnidadesMedidaCompra] = useState([]);
    const [loadindInfoTributarias, setLoadingInfoTributarias] = useState(false);
    const { user } = useAuth();
    const { empresaSelecionada } = useEmpresa();
    const { dadosBasicos, fornecedorOC, atualizarValoresOC } = useOrdemCompra();
    const { showSuccess, showWarning, showError } = useToast();

    const { setFieldValue, ...formik } = useFormik({
        initialValues: {
            sku: null,
            operacao_fiscal: null,
            unidade_compra: null,
            altura: 0,
            largura: 0,
            comprimento: 0,
            cubagem: 1,
            quantidade: 0,
            quantidade_recebida: 0,
            valor_unitario: 0,
            total_nf_item: 0,
            aliquota_ipi: 0,
            valor_total_ipi: 0,
            aliquota_icms: 0,
            valor_total_icms: 0,
            valor_total_icms_st: 0,
            unitario_outros_valores: 0,
            total_item: 0,
            destinacao_entrada: null,
            anotacoes: "",
        },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                sku: Yup.object()
                    .typeError("Você precisa buscar e selecionar um produto.")
                    .required("Você precisa buscar e selecionar um produto."),
                unidade_compra: Yup.number()
                    .required("Selecione uma unidade de medida.")
                    .typeError("Selecione uma unidade de medida."),
                destinacao_entrada: Yup.number()
                    .required("Selecione uma destinção de entrada.")
                    .typeError("Selecione uma destinção de entrada."),
                quantidade: Yup.number()
                    .when("sku", {
                        is: (val) => val && val.movimenta_cubagem === true,
                        then: Yup.number().min(0.001, "O campo 'quantidade' não pode ser ZERO."),
                        otherwise: Yup.number().min(1, "O campo 'quantidade' não pode ser ZERO."),
                    })
                    .required("O campo 'quantidade' não pode ser ZERO."),
            });
            await formSchema.validate(values, { abortEarly: false });
            const itemOC = {
                ...values,
                sku: values.sku.id,
                ordem_compra: ordemCompraId,
                altura: formatarCasasDecimais(values.altura, 4),
                largura: formatarCasasDecimais(values.largura, 4),
                comprimento: formatarCasasDecimais(values.comprimento, 4),
                cubagem: formatarCasasDecimais(values.cubagem, 6),
                valor_total_ipi: formatarCasasDecimais(values.valor_total_ipi, 4),
                valor_total_icms: formatarCasasDecimais(values.valor_total_icms, 4),
                valor_total_icms_st: formatarCasasDecimais(values.valor_total_icms_st, 4),
            };
            if (!values.id) {
                const novoItemOC = {
                    ...itemOC,
                    total_item: formatarCasasDecimais(values.total_item),
                    usuario_cadastro: user.id,
                    datahora_gravacao: dataToStr(new Date(), "yyyy-MM-dd HH:mm:ss"),
                };
                const { status } = await axiosPost("/compras/itens-ordens-compra/", novoItemOC);
                if (status === 201) {
                    showSuccess({
                        summary: "Sucesso!",
                        detail: "Item cadastrado com sucesso.",
                        life: 1500,
                    });
                    setTimeout(async () => {
                        if (typeof onSave === "function") onSave();
                        await atualizarValoresOC();
                        fecharModal();
                    }, 1500);
                }
            } else {
                const { status } = await axiosPatch(`/compras/itens-ordens-compra/${values.id}/`, itemOC);
                if (status === 200) {
                    showSuccess({
                        summary: "Sucesso!",
                        detail: "Item alterado com sucesso.",
                        life: 1500,
                    });
                    setTimeout(async () => {
                        if (typeof onSave === "function") onSave();
                        await atualizarValoresOC();
                        fecharModal();
                    }, 1500);
                }
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            }
        }
    }

    const resetarForm = () => {
        formik.resetForm();
        setUfEmpresaValido(false);
        setUfFornecedorValido(false);
        setVerificaUf(false);
    };

    const abrirModal = (item) => {
        if (item) {
            formik.setValues({
                ...item,
                _total_item_original: item.total_item,
            });
        }
        setVisible(true);
    };

    const fecharModal = () => {
        resetarForm();
        setVisible(false);
    };

    const calcularValorTotalIpi = useCallback(() => {
        const quantidade = parseNumber(formik.values.quantidade) || 0;
        const valor_unitario = parseNumber(formik.values.valor_unitario) || 0;
        const aliquota_ipi = parseNumber(formik.values.aliquota_ipi) || 0;
        const valor = (valor_unitario * quantidade * aliquota_ipi) / 100;
        setFieldValue("valor_total_ipi", valor);
    }, [formik.values.quantidade, formik.values.valor_unitario, formik.values.aliquota_ipi, setFieldValue]);

    useEffect(() => calcularValorTotalIpi(), [calcularValorTotalIpi]);

    useImperativeHandle(ref, () => ({ abrirModal }));

    const setInformacoesItem = useCallback(() => {
        if (formik.values.sku instanceof Object) {
            const { unidademedidasku_set } = formik.values.sku;

            const unidades = unidademedidasku_set
                .map((und) => {
                    if (und.ativo && und.tipo_mov_und_medida.id === "C") {
                        return {
                            value: und.unidade.id,
                            label: `${und.unidade.nome} (${und.unidade.sigla})`,
                        };
                    }

                    return null;
                })
                .filter(Boolean);

            setUnidadesMedidaCompra(unidades);

            if (unidades.length === 1) {
                setFieldValue("unidade_compra", unidades[0].value);
            }
        }
    }, [formik.values.sku, setFieldValue]);

    useEffect(() => {
        setInformacoesItem();
    }, [setInformacoesItem]);

    const validarUfEmpresa = useCallback(
        (ufDestinoOF) => {
            const enderecoPrincipalEmpresa = empresaSelecionada.enderecoperfil_set.find((e) => e.principal);
            if (enderecoPrincipalEmpresa) {
                setUfEmpresaValido(enderecoPrincipalEmpresa.cidade.estado.id === ufDestinoOF?.id || !ufDestinoOF);
            }
        },
        [empresaSelecionada]
    );

    const validarUfFornecedor = useCallback(
        (ufOrigemOF) => {
            const enderecoPrincipal = fornecedorOC.enderecoperfil_set.find((e) => e.principal);
            if (enderecoPrincipal) {
                setUfFornecedorValido(enderecoPrincipal.cidade.estado.id === ufOrigemOF?.id || !ufOrigemOF);
            }
        },
        [fornecedorOC]
    );

    const buscarInformacoesTributarias = useCallback(async () => {
        if (formik.values.sku instanceof Object && formik.values.operacao_fiscal) {
            const skuId = formik.values.sku.id;
            const ofId = formik.values.operacao_fiscal;

            setLoadingInfoTributarias(true);
            const { status, data } = await axiosGet(`/fiscal/buscar-informacoes-tributarias-sku/${skuId}/${ofId}/`);
            setLoadingInfoTributarias(false);
            if (status === 200) {
                const infoTributaria = data.results;

                if (infoTributaria) {
                    setFieldValue("aliquota_ipi", infoTributaria.aliquota_ipi || 0);
                    setFieldValue("aliquota_icms", infoTributaria.aliquota_icms_origem || 0);
                    setBaseCalculoIPI(100 - (Number(infoTributaria.reducao_base_ipi) || 0));
                    setBaseCalculoICMS(100 - (Number(infoTributaria.reducao_base_icms) || 0));
                    setVerificaUf(true);
                    validarUfFornecedor(infoTributaria.uf_origem);
                    validarUfEmpresa(infoTributaria.uf_destino);
                }
            } else if (status === 400 || status === 404) {
                showWarning({
                    summary: "Falhou...",
                    detail: data.msg,
                    life: 4000,
                });
            } else {
                showError({
                    summary: "Erro :(",
                    detail: "Desculpe, não conseguimos listar as informações tributárias do produto.",
                    life: 3000,
                });
            }
        }
    }, [
        formik.values.sku,
        formik.values.operacao_fiscal,
        validarUfEmpresa,
        validarUfFornecedor,
        setFieldValue,
        showWarning,
        showError,
    ]);

    useEffect(() => {
        buscarInformacoesTributarias();
    }, [buscarInformacoesTributarias]);

    const buscarPrecoCompraSKU = useCallback(async () => {
        if (formik.values.sku instanceof Object) {
            const params = {
                fornecedor: fornecedorOC.id,
                sku: formik.values.sku.id,
            };
            const { status, data } = await axiosGet("/produtos/vinculo-produtos-fornecedor/", { params });
            if (status !== 200) {
                showError({
                    summary: "Erro :(",
                    detail: "Desculpe, não conseguimos buscar o preço de compra do produto.",
                    life: 3000,
                });
            }
            if (data.results.length > 0) {
                const [precoCompra] = data.results;

                setFieldValue("valor_unitario", precoCompra.preco_compra || 0);
            }
        }
    }, [formik.values.sku, fornecedorOC, setFieldValue, showError]);

    useEffect(() => {
        buscarPrecoCompraSKU();
    }, [buscarPrecoCompraSKU]);

    const totalizarItemOrdemCompra = useCallback(() => {
        const quant = parseNumber(formik.values.quantidade);
        const cubagem = parseNumber(formik.values.cubagem);
        const unitario = parseNumber(formik.values.valor_unitario);
        const outrosVlrs = parseNumber(formik.values.unitario_outros_valores);
        const total = quant * cubagem * (unitario + outrosVlrs);
        setFieldValue("total_item", total);
    }, [
        formik.values.quantidade,
        formik.values.cubagem,
        formik.values.valor_unitario,
        formik.values.unitario_outros_valores,
        setFieldValue,
    ]);

    useEffect(() => {
        totalizarItemOrdemCompra();
    }, [totalizarItemOrdemCompra]);

    const onChangeDimensao = useCallback(
        (e) => {
            setFieldValue("cubagem", e);
        },
        [setFieldValue]
    );

    return (
        <Dialog
            header="Adicionar item"
            visible={visible}
            breakpoints={{ "960px": "75vw" }}
            style={{ width: "70vw", display: "block" }}
            onHide={fecharModal}
        >
            <form onSubmit={formik.handleSubmit}>
                <MakoBuscaSkuPersonalizada
                    exibeDimensoes
                    categoriaId={dadosBasicos?.categoria_item}
                    statusItem="F"
                    skuValue={formik.values.sku}
                    skuChange={(e) => setFieldValue("sku", e)}
                    skuError={formik.errors.sku}
                    alturaValue={formik.values.altura}
                    onChangeAltura={(e) => setFieldValue("altura", e)}
                    larguraValue={formik.values.largura}
                    onChangeLargura={(e) => setFieldValue("largura", e)}
                    comprimentoValue={formik.values.comprimento}
                    onChangeComprimento={(e) => setFieldValue("comprimento", e)}
                    dimensaoValue={formik.values.cubagem}
                    onChangeDimensao={onChangeDimensao}
                />
                {formik.values.sku && typeof formik.values.sku === "object" ? (
                    <MakoItemNcm ncmItem={formik.values.sku.item.ncm.codigo} />
                ) : null}
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-6">
                        <label htmlFor="operacao-fiscal">Operação fiscal</label>
                        <Dropdown
                            id="operacao-fiscal"
                            name="operacao_fiscal"
                            url={`/fiscal/operacoes-fiscais/?empresa=${empresaSelecionada.id}&tipo_movimento=E&limit=100`}
                            optionValue="id"
                            optionLabel="descricao"
                            filter
                            filterBy="descricao"
                            showFilterClear
                            optionDisabled={(e) => !e.ativo}
                            value={formik.values.operacao_fiscal}
                            onChange={formik.handleChange}
                        />
                    </div>
                    <div className="p-field p-col-12 p-md-6">
                        <label htmlFor="destinacao_entrada">Destinação entrada *</label>
                        <Dropdown
                            id="destinacao_entrada"
                            name="destinacao_entrada"
                            url={`/compras/destinacoes-entradas/?query={id,nome,centro_estocagem_destino}&limit=100`}
                            optionValue="id"
                            optionLabel="nome"
                            value={formik.values.destinacao_entrada}
                            disabled={!!formik.values?.id}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.destinacao_entrada })}
                        />
                        {formik.errors.destinacao_entrada && (
                            <small className="p-error">{formik.errors.destinacao_entrada}</small>
                        )}
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="unidade-compra">Und. de compra *</label>
                        <Dropdown
                            id="unidade-compra"
                            name="unidade_compra"
                            options={unidadesMedidaCompra}
                            placeholder="Selecione"
                            emptyMessage="Nenhum registro disponível"
                            emptyFilterMessage="Nenhum registro encontrado"
                            value={formik.values.unidade_compra}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.unidade_compra })}
                        />
                        {formik.errors.unidade_compra && (
                            <small className="p-error">{formik.errors.unidade_compra}</small>
                        )}
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="quantidade">Quantidade *</label>
                        <MakoInputQuantidadeSku
                            id="quantidade"
                            name="quantidade"
                            permiteFracionario={formik.values.sku?.movimenta_cubagem}
                            value={formik.values.quantidade}
                            onValueChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.quantidade })}
                        />
                        {formik.errors.quantidade && <small className="p-error">{formik.errors.quantidade}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="quantidade_recebida">Qtd. recebida</label>
                        <MakoInputQuantidadeSku
                            id="quantidade_recebida"
                            name="quantidade_recebida"
                            disabled
                            permiteFracionario={formik.values.sku?.movimenta_cubagem}
                            value={formik.values.quantidade_recebida}
                        />
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="vlr-unitario">Valor unitário *</label>
                        <MakoInputMoeda
                            id="vlr-unitario"
                            name="valor_unitario"
                            somentePositivo
                            quatroCasasDecimais
                            valueMoeda={formik.values.valor_unitario}
                            onChangeMoeda={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.valor_unitario })}
                        />
                        {formik.errors.valor_unitario && (
                            <small className="p-error">{formik.errors.valor_unitario}</small>
                        )}
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="outros-valores">Outros valores (un)</label>
                        <MakoInputMoeda
                            id="outros-valores"
                            name="unitario_outros_valores"
                            somentePositivo
                            quatroCasasDecimais
                            valueMoeda={formik.values.unitario_outros_valores}
                            onChangeMoeda={formik.handleChange}
                        />
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="aliquota-ipi">{`% IPI ${
                            baseCalculoIPI !== 100 ? `- BC ${baseCalculoIPI} %` : ""
                        }`}</label>
                        <MakoInputPercent
                            id="aliquota-ipi"
                            name="aliquota_ipi"
                            disabled={loadindInfoTributarias}
                            placeholder={loadindInfoTributarias ? "Buscando alíquota..." : null}
                            max={100}
                            value={formik.values.aliquota_ipi}
                            onValueChange={formik.handleChange}
                        />
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="valor-ipi">Valor total do IPI</label>
                        <MakoInputMoeda
                            id="valor-ipi"
                            name="valor_total_ipi"
                            somentePositivo
                            quatroCasasDecimais
                            valueMoeda={formik.values.valor_total_ipi}
                            onChangeMoeda={formik.handleChange}
                        />
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="aliquota-icms">{`% ICMS ${
                            baseCalculoICMS !== 100 ? `- BC ${baseCalculoICMS} %` : ""
                        }`}</label>
                        <MakoInputPercent
                            id="aliquota-icms"
                            name="aliquota_icms"
                            disabled={loadindInfoTributarias}
                            placeholder={loadindInfoTributarias ? "Buscando alíquota..." : null}
                            max={100}
                            value={formik.values.aliquota_icms}
                            onValueChange={formik.handleChange}
                        />
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="valor-total-icms">Valor total ICMS</label>
                        <MakoInputMoeda
                            id="valor-total-icms"
                            name="valor_total_icms"
                            somentePositivo
                            quatroCasasDecimais
                            valueMoeda={formik.values.valor_total_icms}
                            onChangeMoeda={formik.handleChange}
                        />
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="valor-icms-st">Valor ICMS/ST</label>
                        <MakoInputMoeda
                            id="valor-icms-st"
                            name="valor_total_icms_st"
                            somentePositivo
                            valueMoeda={formik.values.valor_total_icms_st}
                            onChangeMoeda={formik.handleChange}
                        />
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="total_item">Total do item</label>
                        <MakoInputMoeda
                            id="total_item"
                            name="total_item"
                            disabled
                            valueMoeda={formik.values.total_item}
                        />
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12">
                        <label htmlFor="anotacoes">Anotações</label>
                        <InputTextarea
                            id="anotacoes"
                            name="anotacoes"
                            rows={5}
                            autoComplete="off"
                            autoResize
                            value={formik.values.anotacoes}
                            onChange={formik.handleChange}
                        />
                    </div>
                </div>
                <CamposObrigatorios />
                <div className="p-grid">
                    <div className="p-col-12 p-md-6">
                        <Button
                            type="submit"
                            disabled={!(!formik.values.operacao_fiscal || (ufFornecedorValido && ufEmpresaValido))}
                            icon="pi pi-save"
                            label="Gravar"
                            className="p-mr-2"
                        />
                        <Button
                            label="Limpar"
                            type="reset"
                            icon="pi pi-trash"
                            className="p-button-warning p-mr-2"
                            onClick={() => resetarForm()}
                        />
                    </div>
                </div>
            </form>
            <div>
                {!ufEmpresaValido && verificaUF && (
                    <small className="p-error">
                        O estado de <strong>DESTINO</strong> da operação fiscal está incorreto.
                    </small>
                )}
            </div>
            <div>
                {!ufFornecedorValido && verificaUF && (
                    <small className="p-error">
                        O estado de <strong>ORIGEM</strong> da operação fiscal está incorreto.
                    </small>
                )}
            </div>
        </Dialog>
    );
};

export const ModalItensOrdemCompra = forwardRef(ItensOrdemCompra);
