import React, { useState, useRef, memo, forwardRef } from "react";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { useFormik } from "formik";
import { InputNumber } from "primereact/inputnumber";
import { Toast } from "primereact/toast";
import { ConfirmDialog } from "primereact/confirmdialog";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import { InputText } from "primereact/inputtext";
import { MakoAutoComplete } from "@/components/MakoAutoComplete";
import { parseNumberToMoneyHTML } from "@/assets/util/util";

import permissoes from "@/assets/constants/permissoes";
import MakoListagem from "@/components/MakoListagem";
import classNames from "classnames";
import * as Yup from "yup";
import { Dropdown } from "@/components/Dropdown";
import { Divider } from "primereact/divider";
import { MakoInputMoeda } from "@/components/MakoInputMoeda";
import useNotaServico from "@/hooks/useNotaServico";

export const ServicosForm = (props) => {
    const [deleteDialog, setDeleteDialog] = useState(false);
    const [servico, setServico] = useState(null);
    const [adicionaServiço, setAdicionaServiço] = useState(false);
    const { handleServicos, notaServico, servicos } = useNotaServico();
    const toastRef = useRef(null);
    const listagemRef = useRef(null);

    const { setFieldValue, setValues, resetForm, ...formik } = useFormik({
        enableReinitialize: true,
        initialValues: {
            status: "novo",
            servico: null,
            codigo: "",
            descricao: "",
            cnae: "",
            discriminacao: "",
            endereco_prestacao: null,
            logradouro_prestacao: "",
            bairro_prestacao: "",
            numero_end_prestacao: "",
            cep_prestacao: "",
            municipio_prestacao: "",
            valor_servico: 0,
            valor_deducoes: 0,
            valor_pis: 0,
            base_pis: 0,
            valor_cofins: 0,
            base_cofins: 0,
            valor_inss: 0,
            base_inss: 0,
            valor_ir: 0,
            base_irrf: 0,
            valor_csll: 0,
            base_csll: 0,
            tributavel: 0,
            valor_iss: 0,
            iss_retido: 2,
            valor_iss_retido: 0,
            valor_total: 0,
            total_recebido: 0,
            valor_bc: 0,
            outras_retencoes: 0,
            aliquota_iss: 0,
            aliquota_pis: 0,
            pis_retido: 2,
            aliquota_cofins: 0,
            cofins_retido: 1,
            aliquota_ir: 0,
            ir_retido: 1,
            aliquota_csll: 0,
            csll_retido: 1,
            aliquota_inss: 0,
            inss_retido: 1,
            aliquota_cpp: 0,
            cpp_retido: 0,
            valor_cpp: 0,
            outras_retencoes_retido: 2,
            base_outras_retencoes: 0,
            aliquota_outras_retencoes: 0,
            aliquota_total_tributos: 0,
            valor_liquido: 0,
            desconto_incondicional: 0,
            desconto_condicional: 0,
            valor_acrescimos: 0,
            aliquota_iss_municip: 0,
            info_valor_pis: 0,
            info_valor_cofins: 0,
            valor_liq_fatura: 0,
            base_iss_retido: 0,
            numero_fatura: null,
            carga_tributaria: 0,
            valor_carga_tributaria: 0,
            perc_carga_tributaria: 0,
            carga_tributaria_fonte: 0,
            justificativa_deducao: "",
            valor_credito: 0,
            outros_impostos: 0,
            reducao_bc: 0,
            retencoes_federais: 0,
        },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                servico: Yup.object().required("O campo é obrigatório!"),
                codigo: Yup.string().required("O campo é obrigatório!"),
                descricao: Yup.string().required("O campo é obrigatório!"),
                cnae: Yup.string().required("O campo é obrigatório!"),
            });

            await formSchema.validate(values, {
                abortEarly: false,
            });

            handleServicos(values.status, values);
            toastRef.current.show({
                severity: "success",
                summary: "Sucesso",
                detail: `Serviço ${values.status === "novo" ? "adicionado" : "alterado"} com sucesso!`,
                life: 3000,
            });
            if (values.status === "novo") resetForm();
            else esconderInserirServiço();
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            }
        }
    }

    const confirmarDelete = (servico) => {
        setServico({ ...servico, status: "deletar" });
        setDeleteDialog(true);
    };

    const removerServico = async () => {
        try {
            handleServicos("deletar", servico);
            toastRef.current.show({
                severity: "success",
                summary: "Sucesso",
                detail: "Serviço removido com sucesso!",
                life: 3000,
            });
            setDeleteDialog(false);
        } catch (error) {
            toastRef.current.show({
                severity: "error",
                summary: "Erro",
                detail: "Falha ao remover servico!",
                life: 3000,
            });
        }
    };

    const editarServico = (servico) => {
        setValues({ ...servico, status: "editar" });
        setAdicionaServiço(true);
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <MakoControleAcesso
                    permissao={[permissoes.FISCAL_NOTASERVICO_EDITAR]}
                    componente={Button}
                    icon="pi pi-pencil"
                    className="p-button-rounded p-button-warning p-mr-2 p-mb-1"
                    tooltip="Alterar Cadastro do servico"
                    onClick={() => editarServico(rowData)}
                    tooltipOptions={{ position: "left" }}
                />
                <MakoControleAcesso
                    permissao={[permissoes.FISCAL_NOTASERVICO_EDITAR]}
                    componente={Button}
                    icon="pi pi-trash"
                    className="p-button-rounded p-button-danger p-mr-2 p-mb-1"
                    onClick={() => confirmarDelete(rowData)}
                    tooltipOptions={{ position: "left" }}
                />
            </div>
        );
    };

    const cabecalhoTabela = (
        <>
            <Button
                label="Adicionar serviço"
                icon="pi pi-plus"
                className="p-button-success p-button-outlined p-mr-2"
                onClick={(e) => setAdicionaServiço(true)}
            />
        </>
    );

    const colunas = [
        { field: "codigo", header: "Código", style: { width: "50px" } },
        { field: "descricao", header: "Descrição", style: { minWidth: "400px" } },
        { field: "cnae", header: "Cnae", style: { width: "50px" } },
        {
            field: "valor_servico",
            header: "Vlr. serviço",
            style: { width: "150px" },
            action: (e) => parseNumberToMoneyHTML(e.valor_servico),
        },
        {
            field: "valor_total",
            header: "Valor total",
            style: { width: "150px" },
            action: (e) => parseNumberToMoneyHTML(e.valor_total),
        },
        {
            field: "action",
            header: "Ações",
            action: (e) => actionBodyTemplate(e),
            style: { width: "200px" },
        },
    ];

    const esconderInserirServiço = () => {
        resetForm();
        setAdicionaServiço(false);
    };

    const autoCompleteTemplate = (servico) => {
        return `${servico.codigo} - ${servico.descricao}`;
    };

    const onChangeServico = (servico) => {
        setFieldValue("servico", servico);
        setFieldValue("cnae", servico.cnae);
        setFieldValue("codigo", servico.codigo);
        setFieldValue("descricao", servico.descricao);
    };

    const onChangeEndereco = (endereco) => {
        setFieldValue("bairro_prestacao", endereco.bairro);
        setFieldValue("logradouro_prestacao", endereco.logradouro);
        setFieldValue("endereco_prestacao", endereco);
        setFieldValue("numero_end_prestacao", parseInt(endereco.numero));
        setFieldValue("cep_prestacao", endereco.cep);
        setFieldValue("municipio_prestacao", endereco.cidade);
    };

    return (
        <div>
            <Toast ref={toastRef} />
            <MakoListagem
                ref={listagemRef}
                colunas={colunas}
                painelEsquerdo={cabecalhoTabela}
                dadosLocal={servicos}
                mostrarGlobalSearch={false}
                responsiva
                configTabela={{ scrollable: true, frozenWidth: "0.5vw" }}
            />
            <ConfirmDialog
                visible={deleteDialog}
                onHide={() => setDeleteDialog(false)}
                header="Confirmação"
                message={
                    servico && (
                        <span>
                            {"Deseja realmente remover o serviço "}
                            <b>{servico.descricao_reduzida}</b>?
                        </span>
                    )
                }
                icon="pi pi-info-circle p-mr-3"
                accept={removerServico}
                acceptLabel="Sim"
                acceptClassName="p-button-danger"
                reject={() => setDeleteDialog(false)}
                rejectLabel="Não"
            />
            <Dialog
                header="Adicionar serviço"
                visible={adicionaServiço}
                breakpoints={{ "960px": "80vw" }}
                style={{ width: "75vw" }}
                onHide={() => esconderInserirServiço()}
            >
                <form onSubmit={formik.handleSubmit}>
                    <Divider align="center">
                        <b>Endereço da Prestação</b>
                    </Divider>
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-12 p-md-5">
                            <label htmlFor="endereco_prestacao">Endereço</label>
                            <Dropdown
                                id="endereco_prestacao"
                                name="endereco_prestacao"
                                placeholder="Selecione..."
                                options={notaServico.tomador?.enderecoperfil_set || []}
                                optionLabel="logradouro"
                                showClear={false}
                                value={formik.values.endereco_prestacao}
                                onChange={(e) => onChangeEndereco(e.target.value)}
                                className={classNames({ "p-invalid": formik.errors.endereco_prestacao })}
                            />
                            {formik.errors.endereco_prestacao && (
                                <small className="p-error">{formik.errors.endereco_prestacao}</small>
                            )}
                        </div>
                        <div className="p-field p-col-12 p-md-3">
                            <label htmlFor="bairro_prestacao">Bairro *</label>
                            <InputText
                                id="bairro_prestacao"
                                name="bairro_prestacao"
                                value={formik.values.bairro_prestacao}
                                onChange={formik.handleChange}
                                className={classNames({ "p-invalid": formik.errors.bairro_prestacao })}
                            />
                            {formik.errors.bairro_prestacao && (
                                <small className="p-error">{formik.errors.bairro_prestacao}</small>
                            )}
                        </div>
                        <div className="p-field p-col-12 p-md-2">
                            <label htmlFor="numero_end_prestacao">Número *</label>
                            <InputNumber
                                id="numero_end_prestacao"
                                name="numero_end_prestacao"
                                value={formik.values.numero_end_prestacao}
                                onValueChange={formik.handleChange}
                                className={classNames({ "p-invalid": formik.errors.numero_end_prestacao })}
                            />
                            {formik.errors.numero_end_prestacao && (
                                <small className="p-error">{formik.errors.numero_end_prestacao}</small>
                            )}
                        </div>
                        <div className="p-field p-col-12 p-md-2">
                            <label htmlFor="cep_prestacao">CEP *</label>
                            <InputText
                                id="cep_prestacao"
                                name="cep_prestacao"
                                value={formik.values.cep_prestacao}
                                onChange={formik.handleChange}
                                className={classNames({ "p-invalid": formik.errors.cep_prestacao })}
                            />
                            {formik.errors.cep_prestacao && (
                                <small className="p-error">{formik.errors.cep_prestacao}</small>
                            )}
                        </div>
                    </div>
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-12 p-md-4">
                            <label htmlFor="municipio_prestacao">Município *</label>
                            <InputText
                                id="municipio_prestacao"
                                name="municipio_prestacao"
                                value={formik.values.municipio_prestacao?.nome}
                                onChange={formik.handleChange}
                                className={classNames({ "p-invalid": formik.errors.municipio_prestacao })}
                                disabled
                            />
                            {formik.errors.municipio_prestacao && (
                                <small className="p-error">{formik.errors.municipio_prestacao}</small>
                            )}
                        </div>
                        <div className="p-field p-col-12 p-md-4">
                            <label htmlFor="uf_prestacao">UF *</label>
                            <InputText
                                id="uf_prestacao"
                                name="uf_prestacao"
                                value={formik.values.municipio_prestacao?.estado?.nome}
                                onChange={formik.handleChange}
                                className={classNames({ "p-invalid": formik.errors.uf_prestacao })}
                                disabled
                            />
                            {formik.errors.uf_prestacao && (
                                <small className="p-error">{formik.errors.uf_prestacao}</small>
                            )}
                        </div>
                    </div>
                    <Divider align="center">
                        <b>Dados do Serviço</b>
                    </Divider>
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-12 p-md-12">
                            <label htmlFor="servico">Serviço do município *</label>
                            <MakoAutoComplete
                                id="servico"
                                name="servico"
                                placeholder="Busque pelo código ou descrição ... (min 4 caracteres)"
                                minCaracteresBusca={4}
                                value={formik.values.servico}
                                onChange={(e) => onChangeServico(e.target.value)}
                                itemTemplate={autoCompleteTemplate}
                                field="descricao"
                                urlSearch={"/servicos/servicos-municipio?limit=60&search="}
                                className={classNames({ "p-invalid": formik.errors.servico })}
                            />
                            {formik.errors.servico && <small className="p-error">{formik.errors.servico}</small>}
                        </div>
                    </div>
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-12 p-md-2">
                            <label htmlFor="codigo">Código *</label>
                            <InputText
                                id="codigo"
                                name="codigo"
                                value={formik.values.codigo}
                                onChange={formik.handleChange}
                                className={classNames({ "p-invalid": formik.errors.codigo })}
                            />
                            {formik.errors.codigo && <small className="p-error">{formik.errors.codigo}</small>}
                        </div>
                        <div className="p-field p-col-12 p-md-8">
                            <label htmlFor="descricao">Descrição *</label>
                            <InputText
                                id="descricao"
                                name="descricao"
                                value={formik.values.descricao}
                                onChange={formik.handleChange}
                                className={classNames({ "p-invalid": formik.errors.descricao })}
                            />
                            {formik.errors.descricao && <small className="p-error">{formik.errors.descricao}</small>}
                        </div>
                        <div className="p-field p-col-12 p-md-2">
                            <label htmlFor="cnae">Cnae *</label>
                            <InputText
                                id="cnae"
                                name="cnae"
                                value={formik.values.cnae}
                                onChange={formik.handleChange}
                                className={classNames({ "p-invalid": formik.errors.cnae })}
                            />
                            {formik.errors.cnae && <small className="p-error">{formik.errors.cnae}</small>}
                        </div>
                    </div>
                    <Divider align="center">
                        <b>Valores do Serviço</b>
                    </Divider>
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-12 p-md-2">
                            <label htmlFor="valor_servico">Valor serviço *</label>
                            <MakoInputMoeda
                                id="valor_servico"
                                name="valor_servico"
                                valueMoeda={formik.values.valor_servico}
                                onChangeMoeda={formik.handleChange}
                                className={classNames({ "p-invalid": formik.errors.valor_servico })}
                            />
                            {formik.errors.valor_servico && (
                                <small className="p-error">{formik.errors.valor_servico}</small>
                            )}
                        </div>
                        <div className="p-field p-col-12 p-md-2">
                            <label htmlFor="valor_deducoes">Valor deduções *</label>
                            <MakoInputMoeda
                                id="valor_deducoes"
                                name="valor_deducoes"
                                valueMoeda={formik.values.valor_deducoes}
                                onChangeMoeda={formik.handleChange}
                                className={classNames({ "p-invalid": formik.errors.valor_deducoes })}
                            />
                            {formik.errors.valor_deducoes && (
                                <small className="p-error">{formik.errors.valor_deducoes}</small>
                            )}
                        </div>
                        <div className="p-field p-col-12 p-md-2">
                            <label htmlFor="base_pis">Base PIS *</label>
                            <MakoInputMoeda
                                id="base_pis"
                                name="base_pis"
                                valueMoeda={formik.values.base_pis}
                                onChangeMoeda={formik.handleChange}
                                className={classNames({ "p-invalid": formik.errors.base_pis })}
                            />
                            {formik.errors.base_pis && <small className="p-error">{formik.errors.base_pis}</small>}
                        </div>
                        <div className="p-field p-col-12 p-md-2">
                            <label htmlFor="aliquota_pis">Alíq. PIS</label>
                            <InputNumber
                                id="aliquota_pis"
                                name="aliquota_pis"
                                prefix="%"
                                min={0}
                                mode="decimal"
                                minFractionDigits={2}
                                maxFractionDigits={2}
                                value={formik.values.aliquota_pis}
                                onValueChange={formik.handleChange}
                                className={classNames({ "p-invalid": formik.errors.aliquota_pis })}
                            />
                            {formik.errors.aliquota_pis && (
                                <small className="p-error">{formik.errors.aliquota_pis}</small>
                            )}
                        </div>
                        <div className="p-field p-col-12 p-md-2">
                            <label htmlFor="valor_pis">Valor PIS *</label>
                            <MakoInputMoeda
                                id="valor_pis"
                                name="valor_pis"
                                valueMoeda={formik.values.valor_pis}
                                onChangeMoeda={formik.handleChange}
                                className={classNames({ "p-invalid": formik.errors.valor_pis })}
                            />
                            {formik.errors.valor_pis && <small className="p-error">{formik.errors.valor_pis}</small>}
                        </div>
                        <div className="p-field p-col-12 p-md-2">
                            <label htmlFor="base_cofins">Base Cofins *</label>
                            <MakoInputMoeda
                                id="base_cofins"
                                name="base_cofins"
                                valueMoeda={formik.values.base_cofins}
                                onChangeMoeda={formik.handleChange}
                                className={classNames({ "p-invalid": formik.errors.base_cofins })}
                            />
                            {formik.errors.base_cofins && (
                                <small className="p-error">{formik.errors.base_cofins}</small>
                            )}
                        </div>
                        <div className="p-field p-col-12 p-md-2">
                            <label htmlFor="aliquota_cofins">Alíq. Cofins</label>
                            <InputNumber
                                id="aliquota_cofins"
                                name="aliquota_cofins"
                                prefix="%"
                                min={0}
                                mode="decimal"
                                minFractionDigits={2}
                                maxFractionDigits={2}
                                value={formik.values.aliquota_cofins}
                                onValueChange={formik.handleChange}
                                className={classNames({ "p-invalid": formik.errors.aliquota_cofins })}
                            />
                            {formik.errors.aliquota_cofins && (
                                <small className="p-error">{formik.errors.aliquota_cofins}</small>
                            )}
                        </div>
                        <div className="p-field p-col-12 p-md-2">
                            <label htmlFor="valor_cofins">Valor Cofins *</label>
                            <MakoInputMoeda
                                id="valor_cofins"
                                name="valor_cofins"
                                valueMoeda={formik.values.valor_cofins}
                                onChangeMoeda={formik.handleChange}
                                className={classNames({ "p-invalid": formik.errors.valor_cofins })}
                            />
                            {formik.errors.valor_cofins && (
                                <small className="p-error">{formik.errors.valor_cofins}</small>
                            )}
                        </div>
                        <div className="p-field p-col-12 p-md-2">
                            <label htmlFor="base_irrf">Base Irrf *</label>
                            <MakoInputMoeda
                                id="base_irrf"
                                name="base_irrf"
                                valueMoeda={formik.values.base_irrf}
                                onChangeMoeda={formik.handleChange}
                                className={classNames({ "p-invalid": formik.errors.base_irrf })}
                            />
                            {formik.errors.base_irrf && <small className="p-error">{formik.errors.base_irrf}</small>}
                        </div>
                        <div className="p-field p-col-12 p-md-2">
                            <label htmlFor="aliquota_ir">Alíq. IR</label>
                            <InputNumber
                                id="aliquota_ir"
                                name="aliquota_ir"
                                prefix="%"
                                min={0}
                                mode="decimal"
                                minFractionDigits={2}
                                maxFractionDigits={2}
                                value={formik.values.aliquota_ir}
                                onValueChange={formik.handleChange}
                                className={classNames({ "p-invalid": formik.errors.aliquota_ir })}
                            />
                            {formik.errors.aliquota_ir && (
                                <small className="p-error">{formik.errors.aliquota_ir}</small>
                            )}
                        </div>
                        <div className="p-field p-col-12 p-md-2">
                            <label htmlFor="valor_ir">Valor IR *</label>
                            <MakoInputMoeda
                                id="valor_ir"
                                name="valor_ir"
                                valueMoeda={formik.values.valor_ir}
                                onChangeMoeda={formik.handleChange}
                                className={classNames({ "p-invalid": formik.errors.valor_ir })}
                            />
                            {formik.errors.valor_ir && <small className="p-error">{formik.errors.valor_ir}</small>}
                        </div>
                        <div className="p-field p-col-12 p-md-2">
                            <label htmlFor="base_csll">Base Csll *</label>
                            <MakoInputMoeda
                                id="base_csll"
                                name="base_csll"
                                valueMoeda={formik.values.base_csll}
                                onChangeMoeda={formik.handleChange}
                                className={classNames({ "p-invalid": formik.errors.base_csll })}
                            />
                            {formik.errors.base_csll && <small className="p-error">{formik.errors.base_csll}</small>}
                        </div>
                        <div className="p-field p-col-12 p-md-2">
                            <label htmlFor="aliquota_csll">Alíq. Csll</label>
                            <InputNumber
                                id="aliquota_csll"
                                name="aliquota_csll"
                                prefix="%"
                                min={0}
                                mode="decimal"
                                minFractionDigits={2}
                                maxFractionDigits={2}
                                value={formik.values.aliquota_csll}
                                onValueChange={formik.handleChange}
                                className={classNames({ "p-invalid": formik.errors.aliquota_csll })}
                            />
                            {formik.errors.aliquota_csll && (
                                <small className="p-error">{formik.errors.aliquota_csll}</small>
                            )}
                        </div>
                        <div className="p-field p-col-12 p-md-2">
                            <label htmlFor="valor_csll">Valor Csll *</label>
                            <MakoInputMoeda
                                id="valor_csll"
                                name="valor_csll"
                                valueMoeda={formik.values.valor_csll}
                                onChangeMoeda={formik.handleChange}
                                className={classNames({ "p-invalid": formik.errors.valor_csll })}
                            />
                            {formik.errors.valor_csll && <small className="p-error">{formik.errors.valor_csll}</small>}
                        </div>
                        <div className="p-field p-col-12 p-md-2">
                            <label htmlFor="base_inss">Base Inss *</label>
                            <MakoInputMoeda
                                id="base_inss"
                                name="base_inss"
                                valueMoeda={formik.values.base_inss}
                                onChangeMoeda={formik.handleChange}
                                className={classNames({ "p-invalid": formik.errors.base_inss })}
                            />
                            {formik.errors.base_inss && <small className="p-error">{formik.errors.base_inss}</small>}
                        </div>
                        <div className="p-field p-col-12 p-md-2">
                            <label htmlFor="aliquota_inss">Alíq. Inss</label>
                            <InputNumber
                                id="aliquota_inss"
                                name="aliquota_inss"
                                prefix="%"
                                min={0}
                                mode="decimal"
                                minFractionDigits={2}
                                maxFractionDigits={2}
                                value={formik.values.aliquota_inss}
                                onValueChange={formik.handleChange}
                                className={classNames({ "p-invalid": formik.errors.aliquota_inss })}
                            />
                            {formik.errors.aliquota_inss && (
                                <small className="p-error">{formik.errors.aliquota_inss}</small>
                            )}
                        </div>
                        <div className="p-field p-col-12 p-md-2">
                            <label htmlFor="valor_inss">Valor Inss *</label>
                            <MakoInputMoeda
                                id="valor_inss"
                                name="valor_inss"
                                valueMoeda={formik.values.valor_inss}
                                onChangeMoeda={formik.handleChange}
                                className={classNames({ "p-invalid": formik.errors.valor_inss })}
                            />
                            {formik.errors.valor_inss && <small className="p-error">{formik.errors.valor_inss}</small>}
                        </div>
                        <div className="p-field p-col-12 p-md-2">
                            <label htmlFor="aliquota_cpp">Alíq. CPP</label>
                            <InputNumber
                                id="aliquota_cpp"
                                name="aliquota_cpp"
                                prefix="%"
                                min={0}
                                mode="decimal"
                                minFractionDigits={2}
                                maxFractionDigits={2}
                                value={formik.values.aliquota_cpp}
                                onValueChange={formik.handleChange}
                                className={classNames({ "p-invalid": formik.errors.aliquota_cpp })}
                            />
                            {formik.errors.aliquota_cpp && (
                                <small className="p-error">{formik.errors.aliquota_cpp}</small>
                            )}
                        </div>
                        <div className="p-field p-col-12 p-md-2">
                            <label htmlFor="valor_cpp">Valor CPP *</label>
                            <MakoInputMoeda
                                id="valor_cpp"
                                name="valor_cpp"
                                valueMoeda={formik.values.valor_cpp}
                                onChangeMoeda={formik.handleChange}
                                className={classNames({ "p-invalid": formik.errors.valor_cpp })}
                            />
                            {formik.errors.valor_cpp && <small className="p-error">{formik.errors.valor_cpp}</small>}
                        </div>
                        <div className="p-field p-col-12 p-md-2">
                            <label htmlFor="base_outras_retencoes">Base outras reten. *</label>
                            <MakoInputMoeda
                                id="base_outras_retencoes"
                                name="base_outras_retencoes"
                                valueMoeda={formik.values.base_outras_retencoes}
                                onChangeMoeda={formik.handleChange}
                                className={classNames({ "p-invalid": formik.errors.base_outras_retencoes })}
                            />
                            {formik.errors.base_outras_retencoes && (
                                <small className="p-error">{formik.errors.base_outras_retencoes}</small>
                            )}
                        </div>
                        <div className="p-field p-col-12 p-md-2">
                            <label htmlFor="aliquota_outras_retencoes">Alíq. outras reten.</label>
                            <InputNumber
                                id="aliquota_outras_retencoes"
                                name="aliquota_outras_retencoes"
                                prefix="%"
                                min={0}
                                mode="decimal"
                                minFractionDigits={2}
                                maxFractionDigits={2}
                                value={formik.values.aliquota_outras_retencoes}
                                onValueChange={formik.handleChange}
                                className={classNames({ "p-invalid": formik.errors.aliquota_outras_retencoes })}
                            />
                            {formik.errors.aliquota_outras_retencoes && (
                                <small className="p-error">{formik.errors.aliquota_outras_retencoes}</small>
                            )}
                        </div>
                        <div className="p-field p-col-12 p-md-2">
                            <label htmlFor="outras_retencoes">Valor outras reten. *</label>
                            <MakoInputMoeda
                                id="outras_retencoes"
                                name="outras_retencoes"
                                valueMoeda={formik.values.outras_retencoes}
                                onChangeMoeda={formik.handleChange}
                                className={classNames({ "p-invalid": formik.errors.outras_retencoes })}
                            />
                            {formik.errors.outras_retencoes && (
                                <small className="p-error">{formik.errors.outras_retencoes}</small>
                            )}
                        </div>
                        <div className="p-field p-col-12 p-md-2">
                            <label htmlFor="aliquota_total_tributos">Alíq. total trib.</label>
                            <InputNumber
                                id="aliquota_total_tributos"
                                name="aliquota_total_tributos"
                                prefix="%"
                                min={0}
                                mode="decimal"
                                minFractionDigits={2}
                                maxFractionDigits={2}
                                value={formik.values.aliquota_total_tributos}
                                onValueChange={formik.handleChange}
                                className={classNames({ "p-invalid": formik.errors.aliquota_total_tributos })}
                            />
                            {formik.errors.aliquota_total_tributos && (
                                <small className="p-error">{formik.errors.aliquota_total_tributos}</small>
                            )}
                        </div>
                        <div className="p-field p-col-12 p-md-2">
                            <label htmlFor="valor_liquido">Valor líquido *</label>
                            <MakoInputMoeda
                                id="valor_liquido"
                                name="valor_liquido"
                                valueMoeda={formik.values.valor_liquido}
                                onChangeMoeda={formik.handleChange}
                                className={classNames({ "p-invalid": formik.errors.valor_liquido })}
                            />
                            {formik.errors.valor_liquido && (
                                <small className="p-error">{formik.errors.valor_liquido}</small>
                            )}
                        </div>
                        <div className="p-field p-col-12 p-md-2">
                            <label htmlFor="desconto_incondicional">Desc. incond. *</label>
                            <MakoInputMoeda
                                id="desconto_incondicional"
                                name="desconto_incondicional"
                                valueMoeda={formik.values.desconto_incondicional}
                                onChangeMoeda={formik.handleChange}
                                className={classNames({ "p-invalid": formik.errors.desconto_incondicional })}
                            />
                            {formik.errors.desconto_incondicional && (
                                <small className="p-error">{formik.errors.desconto_incondicional}</small>
                            )}
                        </div>
                        <div className="p-field p-col-12 p-md-2">
                            <label htmlFor="desconto_condicional">Desc. condicional *</label>
                            <MakoInputMoeda
                                id="desconto_condicional"
                                name="desconto_condicional"
                                valueMoeda={formik.values.desconto_condicional}
                                onChangeMoeda={formik.handleChange}
                                className={classNames({ "p-invalid": formik.errors.desconto_condicional })}
                            />
                            {formik.errors.desconto_condicional && (
                                <small className="p-error">{formik.errors.desconto_condicional}</small>
                            )}
                        </div>
                        <div className="p-field p-col-12 p-md-2">
                            <label htmlFor="valor_acrescimos">Valor acréscimos *</label>
                            <MakoInputMoeda
                                id="valor_acrescimos"
                                name="valor_acrescimos"
                                valueMoeda={formik.values.valor_acrescimos}
                                onChangeMoeda={formik.handleChange}
                                className={classNames({ "p-invalid": formik.errors.valor_acrescimos })}
                            />
                            {formik.errors.valor_acrescimos && (
                                <small className="p-error">{formik.errors.valor_acrescimos}</small>
                            )}
                        </div>
                        <div className="p-field p-col-12 p-md-2">
                            <label htmlFor="aliquota_iss_municip">Alíq. ISS Mun. *</label>
                            <InputNumber
                                id="aliquota_iss_municip"
                                name="aliquota_iss_municip"
                                prefix="%"
                                min={0}
                                mode="decimal"
                                minFractionDigits={2}
                                maxFractionDigits={2}
                                value={formik.values.aliquota_iss_municip}
                                onValueChange={formik.handleChange}
                                className={classNames({ "p-invalid": formik.errors.aliquota_iss_municip })}
                            />
                            {formik.errors.aliquota_iss_municip && (
                                <small className="p-error">{formik.errors.aliquota_iss_municip}</small>
                            )}
                        </div>
                        <div className="p-field p-col-12 p-md-2">
                            <label htmlFor="info_valor_pis">Inf. valor PIS *</label>
                            <MakoInputMoeda
                                id="info_valor_pis"
                                name="info_valor_pis"
                                valueMoeda={formik.values.info_valor_pis}
                                onChangeMoeda={formik.handleChange}
                                className={classNames({ "p-invalid": formik.errors.info_valor_pis })}
                            />
                            {formik.errors.info_valor_pis && (
                                <small className="p-error">{formik.errors.info_valor_pis}</small>
                            )}
                        </div>
                        <div className="p-field p-col-12 p-md-2">
                            <label htmlFor="info_valor_cofins">Inf. valor Cofins *</label>
                            <MakoInputMoeda
                                id="info_valor_cofins"
                                name="info_valor_cofins"
                                valueMoeda={formik.values.info_valor_cofins}
                                onChangeMoeda={formik.handleChange}
                                className={classNames({ "p-invalid": formik.errors.info_valor_cofins })}
                            />
                            {formik.errors.info_valor_cofins && (
                                <small className="p-error">{formik.errors.info_valor_cofins}</small>
                            )}
                        </div>
                        <div className="p-field p-col-12 p-md-2">
                            <label htmlFor="valor_liq_fatura">Valor líq. fatura *</label>
                            <MakoInputMoeda
                                id="valor_liq_fatura"
                                name="valor_liq_fatura"
                                valueMoeda={formik.values.valor_liq_fatura}
                                onChangeMoeda={formik.handleChange}
                                className={classNames({ "p-invalid": formik.errors.valor_liq_fatura })}
                            />
                            {formik.errors.valor_liq_fatura && (
                                <small className="p-error">{formik.errors.valor_liq_fatura}</small>
                            )}
                        </div>
                        <div className="p-field p-col-12 p-md-2">
                            <label htmlFor="base_iss_retido">Base líq. fatura *</label>
                            <MakoInputMoeda
                                id="base_iss_retido"
                                name="base_iss_retido"
                                valueMoeda={formik.values.base_iss_retido}
                                onChangeMoeda={formik.handleChange}
                                className={classNames({ "p-invalid": formik.errors.base_iss_retido })}
                            />
                            {formik.errors.base_iss_retido && (
                                <small className="p-error">{formik.errors.base_iss_retido}</small>
                            )}
                        </div>
                        <div className="p-field p-col-12 p-md-2">
                            <label htmlFor="carga_tributaria">Valor carga trib.</label>
                            <MakoInputMoeda
                                id="carga_tributaria"
                                name="carga_tributaria"
                                valueMoeda={formik.values.carga_tributaria}
                                onChangeMoeda={formik.handleChange}
                                className={classNames({ "p-invalid": formik.errors.carga_tributaria })}
                            />
                            {formik.errors.carga_tributaria && (
                                <small className="p-error">{formik.errors.carga_tributaria}</small>
                            )}
                        </div>
                        <div className="p-field p-col-12 p-md-2">
                            <label htmlFor="valor_carga_tributaria">Valor carga trib.</label>
                            <MakoInputMoeda
                                id="valor_carga_tributaria"
                                name="valor_carga_tributaria"
                                valueMoeda={formik.values.valor_carga_tributaria}
                                onChangeMoeda={formik.handleChange}
                                className={classNames({ "p-invalid": formik.errors.valor_carga_tributaria })}
                            />
                            {formik.errors.valor_carga_tributaria && (
                                <small className="p-error">{formik.errors.valor_carga_tributaria}</small>
                            )}
                        </div>
                        <div className="p-field p-col-12 p-md-2">
                            <label htmlFor="carga_tributaria">Base carga trib.</label>
                            <MakoInputMoeda
                                id="carga_tributaria"
                                name="carga_tributaria"
                                valueMoeda={formik.values.carga_tributaria}
                                onChangeMoeda={formik.handleChange}
                                className={classNames({ "p-invalid": formik.errors.carga_tributaria })}
                            />
                            {formik.errors.carga_tributaria && (
                                <small className="p-error">{formik.errors.carga_tributaria}</small>
                            )}
                        </div>
                        <div className="p-field p-col-12 p-md-2">
                            <label htmlFor="perc_carga_tributaria">Percent. carga trib.</label>
                            <InputNumber
                                id="perc_carga_tributaria"
                                name="perc_carga_tributaria"
                                prefix="%"
                                min={0}
                                mode="decimal"
                                minFractionDigits={2}
                                maxFractionDigits={2}
                                value={formik.values.perc_carga_tributaria}
                                onValueChange={formik.handleChange}
                                className={classNames({ "p-invalid": formik.errors.perc_carga_tributaria })}
                            />
                            {formik.errors.perc_carga_tributaria && (
                                <small className="p-error">{formik.errors.perc_carga_tributaria}</small>
                            )}
                        </div>
                        <div className="p-field p-col-12 p-md-2">
                            <label htmlFor="valor_carga_tributaria">Valor carga trib.</label>
                            <MakoInputMoeda
                                id="valor_carga_tributaria"
                                name="valor_carga_tributaria"
                                valueMoeda={formik.values.valor_carga_tributaria}
                                onChangeMoeda={formik.handleChange}
                                className={classNames({ "p-invalid": formik.errors.valor_carga_tributaria })}
                            />
                            {formik.errors.valor_carga_tributaria && (
                                <small className="p-error">{formik.errors.valor_carga_tributaria}</small>
                            )}
                        </div>
                        <div className="p-field p-col-12 p-md-2">
                            <label htmlFor="valor_credito">Valor crédito</label>
                            <MakoInputMoeda
                                id="valor_credito"
                                name="valor_credito"
                                valueMoeda={formik.values.valor_credito}
                                onChangeMoeda={formik.handleChange}
                                className={classNames({ "p-invalid": formik.errors.valor_credito })}
                            />
                            {formik.errors.valor_credito && (
                                <small className="p-error">{formik.errors.valor_credito}</small>
                            )}
                        </div>
                        <div className="p-field p-col-12 p-md-2">
                            <label htmlFor="outros_impostos">Outros impostos</label>
                            <MakoInputMoeda
                                id="outros_impostos"
                                name="outros_impostos"
                                valueMoeda={formik.values.outros_impostos}
                                onChangeMoeda={formik.handleChange}
                                className={classNames({ "p-invalid": formik.errors.outros_impostos })}
                            />
                            {formik.errors.outros_impostos && (
                                <small className="p-error">{formik.errors.outros_impostos}</small>
                            )}
                        </div>
                        <div className="p-field p-col-12 p-md-2">
                            <label htmlFor="reducao_bc">Vlr. redução BC</label>
                            <MakoInputMoeda
                                id="reducao_bc"
                                name="reducao_bc"
                                valueMoeda={formik.values.reducao_bc}
                                onChangeMoeda={formik.handleChange}
                                className={classNames({ "p-invalid": formik.errors.reducao_bc })}
                            />
                            {formik.errors.reducao_bc && <small className="p-error">{formik.errors.reducao_bc}</small>}
                        </div>
                        <div className="p-field p-col-12 p-md-2">
                            <label htmlFor="retencoes_federais">Vlr. retenções fed.</label>
                            <MakoInputMoeda
                                id="retencoes_federais"
                                name="retencoes_federais"
                                valueMoeda={formik.values.retencoes_federais}
                                onChangeMoeda={formik.handleChange}
                                className={classNames({ "p-invalid": formik.errors.retencoes_federais })}
                            />
                            {formik.errors.retencoes_federais && (
                                <small className="p-error">{formik.errors.retencoes_federais}</small>
                            )}
                        </div>
                    </div>

                    <Button
                        icon={formik.values.status === "editar" ? "pi pi-save" : "pi pi-plus"}
                        type="submit"
                        label={formik.values.status === "editar" ? "Salvar" : "Inserir"}
                        className="p-button-success p-mr-2 p-mt-5"
                    />
                </form>
            </Dialog>
        </div>
    );
};

export default memo(forwardRef(ServicosForm));
