import React, { useCallback, useEffect, useState } from "react";

import { Dropdown } from "@/components/Dropdown";

import { axiosGet } from "@/services/http";

import useLoading from "@/hooks/useLoading";
import useToast from "@/hooks/useToast";

export const MakoDropdownVersaoRelatorio = ({ chave, ...rest }) => {
    const [versoes, setVersoes] = useState([]);
    const { showLoading, hideLoading } = useLoading();
    const { showError } = useToast();

    const buscarVersoesRelatorios = useCallback(async () => {
        if (chave) {
            showLoading();
            const { status, data } = await axiosGet(`/relatorios/relatorios-versao/?relatorio__chave=${chave}`);
            hideLoading();

            if (status === 200) {
                setVersoes(data.results);
            } else {
                showError({
                    summary: "Erro :(",
                    detail: "Desculpe, não conseguimos listar suas versões.",
                    life: 3000,
                });
            }
        }
    }, [showLoading, hideLoading, showError, chave]);

    useEffect(() => {
        buscarVersoesRelatorios();
    }, [buscarVersoesRelatorios]);

    return (
        <>
            <Dropdown
                options={versoes}
                optionValue="versao"
                optionLabel="descricao"
                placeholder="Selecione"
                disabled={!!!chave || versoes.length <= 1}
                {...rest}
            />
            {!!!chave && <small className="p-error">Falta informar a chave do relatório</small>}
        </>
    );
};
