import React, { useImperativeHandle, useState, forwardRef } from "react";
import XMLViewer from "react-xml-viewer";
import { Dialog } from "primereact/dialog";

import { axiosGet } from "@/services/http";
import useToast from "@/hooks/useToast";

const Modal = (props, ref) => {
    const [visible, setVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const [xml, setXML] = useState("");
    const { showWarning, showError } = useToast();

    const obterXML = async (xmlId) => {
        setLoading(true);
        const { status, data } = await axiosGet(`/compras/entradas-xml/${xmlId}/xml`);
        setLoading(false);
        if (status === 200) {
            setXML(data.xml);
        } else if (status !== 500) {
            showWarning({
                summary: "Aviso!",
                detail: data?.msg || data?.detail || "Não foi possível carregar o XML.",
                life: 3000,
            });
        } else {
            showError({
                summary: "Erro :(",
                detail: "Não foi possível carregar o XML.",
                life: 3000,
            });
        }
    };

    const abrirModal = (xmlId) => {
        setVisible(true);
        if (xmlId) obterXML(xmlId);
    };

    useImperativeHandle(ref, () => ({ abrirModal }));

    return (
        <Dialog visible={visible} onHide={() => setVisible(false)} header="Detalhes do XML" style={{ width: "70vw" }}>
            <div className={loading ? "p-text-center" : ""}>
                {loading ? (
                    <i className="pi pi-spin pi-spinner" style={{ fontSize: "2rem" }} />
                ) : (
                    <XMLViewer xml={xml} collapsible />
                )}
            </div>
        </Dialog>
    );
};

export const ModalVerXML = forwardRef(Modal);
