import { useCallback, useEffect } from "react";

import { AxiosError } from "axios";

import axios from "@/services/axios";
import useToast from "./useToast";
import useAuth from "./useAuth";

const useHttp = () => {
    const { token } = useAuth();
    const { showWarning, showError } = useToast();

    useEffect(() => {
        const requestIntercept = axios.interceptors.request.use((config) => {
            if (!config.headers?.Authorization && token) {
                config.headers = { ...config.headers, Authorization: `token ${token}` };
            }
            return config;
        });
        return () => axios.interceptors.request.eject(requestIntercept);
    }, [token]);

    const request = useCallback(
        async ({ method, url, params, headers, body, signal }, handlers) => {
            try {
                const { status, data = {} } = await axios.request({ method, url, data: body, headers, params, signal });
                const handler = handlers[status];
                if (Object.keys(handlers).includes("success") && typeof handlers.success === "function")
                    handlers.success({ status, data, err: null });
                else if (typeof handler === "function") handler({ data, err: null });
            } catch (error) {
                if (error instanceof AxiosError) {
                    const defaultHandlers = {
                        400: ({ err }) => {
                            showError({
                                summary: "Erro",
                                detail: err?.msg || "Desculpe, não conseguimos processar sua requisição",
                                life: 3000,
                            });
                        },
                        401: () => {
                            showWarning({
                                summary: "Acesso expirado!",
                                detail: "Você não está autenticado, faça login novamente.",
                                life: 3000,
                            });
                        },
                        403: ({ err }) => {
                            showWarning({
                                summary: "Aviso!",
                                detail: err?.detail || "Você não tem permissão para acessar o recurso solicitado.",
                                life: 3000,
                            });
                        },
                        404: ({ err }) => {
                            showWarning({
                                summary: "Opsss",
                                detail: err?.msg || "O recurso não foi encontrado.",
                                life: 3000,
                            });
                        },
                        500: () => {
                            if (error?.code !== "ERR_CANCELED") showError();
                        },
                    };
                    const { status = 500, data } = error.response || {};
                    const errorHandlers = { ...defaultHandlers, ...handlers };
                    const handler = errorHandlers[status];
                    if (Object.keys(errorHandlers).includes("error") && typeof errorHandlers.error === "function")
                        handlers.error({ status, data: {}, err: data });
                    else if (typeof handler === "function") handler({ data: {}, err: data });
                } else {
                    showError({
                        summary: "Erro :(",
                        detail: `Erro ao enviar a requisição. MOTIVO: ${error}`,
                        life: 3000,
                    });
                }
            }
        },
        [showError, showWarning]
    );

    const httpGet = useCallback(
        async ({ url, params, headers, signal }, handlers) => {
            await request({ method: "get", url, params, headers, signal }, handlers);
        },
        [request]
    );

    const httpPost = useCallback(
        async ({ url, params, headers, body }, handlers) => {
            await request({ method: "post", url, params, headers, body }, handlers);
        },
        [request]
    );

    const httpPut = useCallback(
        async ({ url, params, headers, body }, handlers) => {
            await request({ method: "put", url, params, headers, body }, handlers);
        },
        [request]
    );

    const httpPatch = useCallback(
        async ({ url, params, headers, body }, handlers) => {
            await request({ method: "patch", url, params, headers, body }, handlers);
        },
        [request]
    );

    const httpDelete = useCallback(
        async ({ url, params, headers }, handlers) => {
            await request({ method: "delete", url, params, headers }, handlers);
        },
        [request]
    );

    return { httpGet, httpPost, httpPut, httpPatch, httpDelete };
};

export default useHttp;
