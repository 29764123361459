import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useState } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";

import { Dropdown } from "@/components/Dropdown";
import { axiosGet, axiosPut } from "@/services/http";
import useEntrada from "@/hooks/useEntrada";
import useLoading from "@/hooks/useLoading";
import useToast from "@/hooks/useToast";

const ProcessarXML = ({ onSuccess, onError }, ref) => {
    const [visible, setVisible] = useState(false);
    const [xmls, setXmls] = useState([]);
    const [operacoesFiscais, setOperacoesFiscais] = useState([]);
    const [operacaoFiscalSelecionada, setOperacaoFiscalSelecionada] = useState(null);
    const { showLoading, hideLoading } = useLoading();
    const { dadosBasicos } = useEntrada();
    const { showSuccess, showError, showCustom } = useToast();

    async function processarXMLs() {
        let toasts = [];
        let xmlsProcessamento = [];

        xmls.forEach((xml) => {
            if (xml.itens_validados) {
                xmlsProcessamento.push(xml.id);
            } else {
                toasts.push({
                    severity: "warn",
                    summary: "Aviso!",
                    detail: `Primeiramente você precisa validar os itens do documento ${xml.numero_nf}.`,
                    life: 3000,
                });
            }
        });

        if (toasts.length > 0) {
            showCustom(toasts);
        } else {
            const body = {
                xmls: xmlsProcessamento,
                operacao_fiscal_padrao: operacaoFiscalSelecionada,
            };

            showLoading();
            const { status, data } = await axiosPut("/compras/processar-xml/", body);
            hideLoading();

            if (status === 200) {
                const { xmls_processados } = data;
                if (xmls_processados > 0) {
                    showSuccess({
                        summary: "Sucesso!",
                        detail:
                            xmlsProcessamento.length > 1
                                ? "XML's processados com sucesso. Agora valide os itens individualmente."
                                : "XML processado com sucesso. Agora valide os itens individualmente.",
                        life: 3000,
                    });
                    if (typeof onSuccess === "function") onSuccess();
                }
                if (data.erros) if (typeof onError === "function") onError(data.erros);
            } else {
                showError({
                    summary: "Erro :(",
                    detail: "Desculpe, não conseguimos processar os seus XML's.",
                    life: 3000,
                });
            }

            fecharModal();
        }
    }

    const listarOperacoesFiscais = useCallback(async () => {
        const params = {
            empresa: dadosBasicos.empresa,
            tipo_movimento: "E",
            ativo: true,
        };
        const { status, data } = await axiosGet("/fiscal/operacoes-fiscais/", { params });
        if (status === 200) {
            setOperacoesFiscais(data.results);
        } else {
            showError({
                summary: "Erro :(",
                detail: "Desculpe, não conseguimos listar as operações fiscais.",
                life: 3000,
            });
        }
    }, [showError, dadosBasicos]);

    useEffect(() => {
        listarOperacoesFiscais();
    }, [listarOperacoesFiscais]);

    const abrirModal = (xmls) => {
        if (xmls) {
            if (xmls instanceof Array) {
                setXmls(xmls);
            } else {
                setXmls([xmls]);
            }
        }

        setVisible(true);
    };

    const fecharModal = () => {
        setXmls([]);
        setOperacaoFiscalSelecionada(null);
        setVisible(false);
    };

    useImperativeHandle(ref, () => ({ abrirModal }));

    const renderFooter = () => {
        return (
            <Button
                label="Processar"
                icon="fas fa-cogs"
                disabled={!operacaoFiscalSelecionada}
                onClick={processarXMLs}
            />
        );
    };

    const operacaoFiscalOptionTemplate = (option) => {
        return <span>{`${option.descricao} - (${option.destinacao?.nome || "Sem destinação"})`}</span>;
    };

    const operacaoFiscalSelecionadaTemplate = (option, props) => {
        if (option) return operacaoFiscalOptionTemplate(option);
        return <span>{props.placeholder}</span>;
    };

    return (
        <Dialog
            header="Processar XML's"
            visible={visible}
            onHide={() => setVisible(false)}
            style={{ width: "30vw" }}
            footer={renderFooter}
        >
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12">
                    <label htmlFor="operacao-fiscal">Operação fiscal padrão</label>
                    <Dropdown
                        id="operacao-fiscal"
                        name="operacao_fiscal"
                        options={operacoesFiscais}
                        optionValue="id"
                        optionLabel="descricao"
                        filter
                        filterBy="descricao"
                        showClear
                        showFilterClear
                        itemTemplate={operacaoFiscalOptionTemplate}
                        valueTemplate={operacaoFiscalSelecionadaTemplate}
                        optionDisabled={(e) => !e.ativo}
                        value={operacaoFiscalSelecionada}
                        onChange={(e) => setOperacaoFiscalSelecionada(e.value)}
                    />
                </div>
            </div>
        </Dialog>
    );
};

export const ModalProcessarXML = forwardRef(ProcessarXML);
