import React, { useEffect, useRef, useState } from "react";
import { InputText } from "primereact/inputtext";
import { InputSwitch } from "primereact/inputswitch";
import { MakoCalendar } from "@/components/MakoCalendar";
import { dataToStr } from "@/assets/util/datas";
import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import { Toast } from "primereact/toast";
import { InputNumber } from "primereact/inputnumber";
import { Dropdown } from "primereact/dropdown";
import { useFormik } from "formik";
import { axiosPatch, axiosPost } from "@/services/http";
import { useHistory } from "react-router-dom";
import { TIPO_ESCOPO_COMISSAO_CHOICE } from "@/assets/constants/constants";

import * as Yup from "yup";
import classNames from "classnames";
import useLoading from "@/hooks/useLoading";

const url = "/vendas/comissoes-vendas/";
const urlvoltar = "/vendas/cadastros/comissao-vendas";

export const ComissaoVendasForm = (props) => {
    const history = useHistory();
    const { showLoading, hideLoading } = useLoading();
    const toastRef = useRef(null);

    const comissaoVazia = {
        percent_mercadoria: 0,
        percent_servico: 0,
        percent_encargo: 0,
        descricao: "",
        vigencia_final: null,
        escopo: null,
        ativo: true,
        aplica_perfil: false,
    };

    const { setFieldValue, ...formik } = useFormik({
        initialValues: props.location.state ? props.location.state.comissaoVendas : comissaoVazia,
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                descricao: Yup.string().required("O campo 'descrição' é obrigatório."),
                vigencia_final: Yup.date().nullable(),
                escopo: Yup.string()
                    .required("O campo 'escopo' é obrigatório.")
                    .typeError("Informe um 'escopo' válido"),
            });

            await formSchema.validate(values, {
                abortEarly: false,
            });

            values.vigencia_final = dataToStr(values.vigencia_final, "yyyy-MM-dd");

            if (!values.id) {
                showLoading();
                const resposta = await axiosPost(`${url}`, values);
                hideLoading();

                if (resposta.status === 201) {
                    toastRef.current.show({
                        severity: "success",
                        summary: "Sucesso",
                        detail: "Comissão de vendas cadastrada com sucesso!",
                        life: 1500,
                    });

                    setTimeout(() => {
                        formik.resetForm();
                        history.push(urlvoltar);
                    }, 2000);
                } else {
                    toastRef.current.show({
                        severity: "error",
                        summary: "Erro",
                        detail: "Desculpe, não conseguimos processar a sua requisição.",
                        life: 1500,
                    });
                }
            } else {
                values.ativo = ativo;

                values.vigencia_final = dataToStr(values.vigencia_final, "yyyy-MM-dd");

                showLoading();
                const resposta = await axiosPatch(`${url}${values.id}/`, values);
                hideLoading();

                if (resposta.status === 200) {
                    toastRef.current.show({
                        severity: "success",
                        summary: "Sucesso",
                        detail: "Comissão de vendas alterada com sucesso!",
                        life: 1500,
                    });

                    setTimeout(() => {
                        formik.resetForm();
                        history.push(urlvoltar);
                    }, 2000);
                } else {
                    toastRef.current.show({
                        severity: "error",
                        summary: "Erro",
                        detail: "Desculpe, não conseguimos processar a sua requisição.",
                        life: 1500,
                    });
                }
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};

                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });

                formik.setErrors(errorMessages);
                hideLoading();
            } else {
                toastRef.current.show({
                    severity: "error",
                    summary: "Erro",
                    detail: "Desculpe, não conseguimos processar a sua requisição.",
                    life: 1500,
                });
                hideLoading();
            }
        }
    }

    const cancelar = () => {
        formik.resetForm();
        history.push(urlvoltar);
    };

    const [ativo, setAtivo] = useState(formik.values.ativo);

    useEffect(() => {
        if (formik.values.escopo?.id) setFieldValue("escopo", formik.values.escopo.id);
    }, [setFieldValue, formik.values.escopo?.id]);

    return (
        <div className="p-grid">
            <div className="p-col-12">
                <div className="card">
                    <Toast ref={toastRef} />
                    <h3>{!formik.values.id ? "Nova comissão de vendas" : "Manutenção de comissão de vendas"}</h3>
                    <form onSubmit={formik.handleSubmit}>
                        <div className="p-fluid p-formgrid p-grid">
                            <div className="p-field p-col-12 p-md-5">
                                <label htmlFor="descricao">Descrição *</label>
                                <InputText
                                    id="descricao"
                                    name="descricao"
                                    value={formik.values.descricao}
                                    onChange={formik.handleChange}
                                    className={classNames({ "p-invalid": formik.errors.descricao })}
                                    tooltip="Descrição que será dada a comissão de vendas."
                                    autoComplete="off"
                                />
                                {formik.errors.descricao && (
                                    <small className="p-error">{formik.errors.descricao}</small>
                                )}
                            </div>
                            <div className="p-field p-col-12 p-md-2">
                                <label htmlFor="percent_mercadoria">% Mercadoria *</label>
                                <InputNumber
                                    id="percent_mercadoria"
                                    name="percent_mercadoria"
                                    mode="decimal"
                                    prefix="%"
                                    min={0}
                                    minFractionDigits={2}
                                    maxFractionDigits={5}
                                    value={formik.values.percent_mercadoria}
                                    onValueChange={formik.handleChange}
                                    className={classNames({ "p-invalid": formik.errors.percent_mercadoria })}
                                    autoComplete="off"
                                    autoFocus
                                />
                                {formik.errors.percent_mercadoria && (
                                    <small className="p-error">{formik.errors.percent_mercadoria}</small>
                                )}
                            </div>
                            <div className="p-field p-col-12 p-md-2">
                                <label htmlFor="percent_servico">% Serviço *</label>
                                <InputNumber
                                    id="percent_servico"
                                    name="percent_servico"
                                    mode="decimal"
                                    prefix="%"
                                    min={0}
                                    minFractionDigits={2}
                                    maxFractionDigits={5}
                                    value={formik.values.percent_servico}
                                    onValueChange={formik.handleChange}
                                    className={classNames({ "p-invalid": formik.errors.percent_servico })}
                                    autoComplete="off"
                                    autoFocus
                                />
                                {formik.errors.percent_servico && (
                                    <small className="p-error">{formik.errors.percent_servico}</small>
                                )}
                            </div>
                            <div className="p-field p-col-12 p-md-2">
                                <label htmlFor="percent_encargo">% Encargo *</label>
                                <InputNumber
                                    id="percent_encargo"
                                    name="percent_encargo"
                                    mode="decimal"
                                    prefix="%"
                                    min={0}
                                    minFractionDigits={2}
                                    maxFractionDigits={5}
                                    value={formik.values.percent_encargo}
                                    onValueChange={formik.handleChange}
                                    className={classNames({ "p-invalid": formik.errors.percent_encargo })}
                                    autoComplete="off"
                                    autoFocus
                                />
                                {formik.errors.percent_encargo && (
                                    <small className="p-error">{formik.errors.percent_encargo}</small>
                                )}
                            </div>
                        </div>
                        <div className="p-fluid p-formgrid p-grid">
                            <div className="p-field p-col-12 p-md-2">
                                <label htmlFor="vigencia_final">Vigência final</label>
                                <MakoCalendar
                                    id="vigencia_final"
                                    name="vigencia_final"
                                    valueCalendar={formik.values.vigencia_final}
                                    onChange={formik.handleChange}
                                    className={classNames({
                                        "p-invalid": formik.errors.vigencia_final,
                                    })}
                                />
                                {formik.errors.vigencia_final && (
                                    <small className="p-error">{formik.errors.vigencia_final}</small>
                                )}
                            </div>
                            <div className="p-field p-col-12 p-md-3">
                                <label htmlFor="escopo">Escopo</label>
                                <Dropdown
                                    id="escopo"
                                    placeholder="Selecione um escopo"
                                    name="escopo"
                                    showClear
                                    onChange={formik.handleChange}
                                    value={formik.values.escopo}
                                    optionLabel="label"
                                    optionValue="id"
                                    options={TIPO_ESCOPO_COMISSAO_CHOICE}
                                    autoComplete="off"
                                    autoFocus
                                />
                                {formik.errors.escopo && <small className="p-error">{formik.errors.escopo}</small>}
                            </div>
                            <div className="p-field-checkbox" style={{ paddingLeft: "2%" }}>
                                <br></br>
                                <Checkbox
                                    inputId="aplica_perfil"
                                    id="aplica_perfil"
                                    name="aplica_perfil"
                                    onChange={formik.handleChange}
                                    checked={formik.values.aplica_perfil}
                                    value={formik.values.aplica_perfil}
                                    className="p-mt-5"
                                    tooltip="Comissão é aplicável a usuários, caso desmarcado a comissão será padrão do sistema."
                                />
                                <label htmlFor="aplica_perfil" className="p-mt-5">
                                    Aplica em perfil
                                </label>
                            </div>
                        </div>
                        <div className="p-fluid p-formgrid p-grid">
                            <div className="p-field p-col-1 ">
                                <h5>
                                    <label htmlFor="status">Status</label>
                                </h5>
                                <InputSwitch
                                    id="ativo"
                                    name="ativo"
                                    checked={ativo}
                                    disabled={formik.values.id ? false : true}
                                    value={formik.values.ativo}
                                    onChange={(e) => setAtivo(e.value)}
                                />
                            </div>
                        </div>
                        <p>
                            <b>* Campos obrigatórios.</b>
                        </p>

                        <div className="p-grid p-col-12 p-md-6">
                            <Button label="Gravar" type="submit" className="p-button-info p-mr-2 p-mb-6" />
                            <Button
                                label="Cancelar"
                                type="reset"
                                className="p-button-danger p-mr-2 p-mb-6"
                                onClick={cancelar}
                            />
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};
