import React, { useCallback, useEffect, useRef, useState } from "react";

import * as Yup from "yup";

import { MakoDropdownEmpresas } from "@/components/MakoDropdownEmpresas";
import { MakoCalendar } from "@/components/MakoCalendar";

import { COMPONENTES_ESPECIAIS, MakoFormGerador } from "@/components/MakoFormGerador";
import { MakoBaseRelatorio as R } from "@/components/MakoBaseRelatorio";

import useRelatorio from "@/hooks/useRelatorio";

import { TIPOS_FILTROS_TEMPLATE, gerarFiltroTemplate, montarFiltroTemplate } from "@/assets/util/relatorios";
import { RELATORIOS_EMITIR_FINANCEIRO_CONTASPAGAS } from "@/assets/constants/relatorios";
import { dataToStr } from "@/assets/util/datas";

import useClearRefs from "@/hooks/useClearRefs";
import { TIPO_FORMAS_PAGAMENTO_RECEBIMENTO_CHOICE } from "@/assets/constants/financeiro";
import MakoListagem from "@/components/MakoListagem";
import { formatarCasasDecimais, gerarFileName } from "@/assets/util/util";

import { Dropdown } from "@/components/Dropdown";
import { axiosGet } from "@/services/http";
import useFormatCNPJCPF from "@/hooks/useFomatCNPJCPF";

const BASE_URL = "/relatorios/contas-pagas/";

const FILTROS_VIEWSET = {
    empresa: "empresa__id",
    periodo_emissao_start: "emissao__gte",
    periodo_emissao_end: "emissao__lte",
    fornec: "devedor__id",
    forma_recebimento: "forma_recebimento",
    conta_financeira: "conta_financeira",
    periodo_pagamento_start: "pagamento__gte",
    periodo_pagamento_end: "pagamento__lte",
    ordenacao:  "order",
};

const FILE_NAME = gerarFileName("Contas pagas");

const OPTIONS_ORDENACAO = [
    {label: "Fornecedor", value: "fornecedor"},
    {label: "Pagamento (crescente)", value: "data_pagamento"},
    {label: "Pagamento (decrescente)", value: "-data_pagamento"},
    {label: "Emissão (crescente)", value: "data_emissao"},
    {label: "Emissão (decrescente)", value: "-data_emissao"},
    {label: "Documento", value: "documento"},
    {label: "Valor pago", value: "valor_pago"},
]

export const RelatorioContasPagas = () => {
    
    const [url, setUrl] = useState(null);
    const formRef = useRef();
    const empresaSelecionada = useRef();
    const listagemRef = useRef();
    const [buscarListagem, setBuscarListagem] = useState(false);
    const [formatarDocumento] = useFormatCNPJCPF();

    useClearRefs(empresaSelecionada);

    const { solicitarRelatorio } = useRelatorio();

    const { ExportCSV } = R.Buttons;
    const botaoCsv = useRef();
    const filtrosTemplate = [
        {
            key: "empresa",
            label: "Empresa",
            type: TIPOS_FILTROS_TEMPLATE.PESSOA,
        },
        {
            key: ["periodo_emissao_start", "periodo_emissao_end"],
            label: "Período emissão",
            type: TIPOS_FILTROS_TEMPLATE.DATEPERIOD,
        },
        {
            key: ["periodo_vencimento_start", "periodo_vencimento_end"],
            label: "Período vencimento",
            type: TIPOS_FILTROS_TEMPLATE.DATEPERIOD,
        },
        {
            key: "forma_recebimento",
            label: "Forma Recebimento",
            optionKey: "id",
            optionLabel: "label",
            options: TIPO_FORMAS_PAGAMENTO_RECEBIMENTO_CHOICE,
            type: TIPOS_FILTROS_TEMPLATE.CHOICE,
        },
        {
            key: "orderBy",
            label: "Ordenado por",
            optionKey: "value",
            optionLabel: "label",
            options: OPTIONS_ORDENACAO,
            type: TIPOS_FILTROS_TEMPLATE.CHOICE,
        },
    ];

    async function handleSubmit({ background, emails, corpo_email, versao = "1", ...values } = null) {
        try {
            const formSchema = Yup.object().shape({
                periodo_emissao_start: Yup.date()
                    .nullable()
                    .typeError("Informe um 'periodo de emissão inicial' válido"),
                periodo_emissao_end: Yup.date()
                    .when("periodo_vencimento_start", {
                        is: (val) => !!val,
                        then: Yup.date()
                            .min(
                                values.periodo_vencimento_start || new Date(),
                                "A 'data de emissão final' não pode ser anterior a inicial"
                            )

                            .typeError("Informe um 'periodo de emissão final' válido"),
                        otherwise: Yup.date().nullable(),
                    }),
                empresa: Yup.number()
                    .required("O campo 'empresa' é obrigatório")
                    .typeError("Informe uma 'empresa' válida"),
                forma_pgto: Yup.string().nullable().typeError("Informe uma 'forma de pagamento' válida"),
                orderBy: Yup.string().nullable().typeError("Seleciona uma 'ordenação' válida."),
                periodo_pagamento_start: Yup.date()
                    .nullable()
                    .typeError("Informe um 'periodo de pagamento inicial' válido"),
                periodo_pagamento_end: Yup.date()
                    .when("periodo_pagamento_start", {
                        is: (val) => !!val,
                        then: Yup.date()
                            .min(
                                values.periodo_pagamento_start || new Date(),
                                "A 'data de pagamento final' não pode ser anterior a inicial"
                            )

                            .required("Informe um 'periodo de pagamento final' válido"),
                        otherwise: Yup.date().nullable(),
                    })
            });

            let dadosValidados = await formSchema.validate(values, {
                abortEarly: false,
            });

            let filtrosTemplateAplicados = montarFiltroTemplate(filtrosTemplate, {
                ...dadosValidados,
                empresa: dadosValidados?.empresa ? empresaSelecionada.current : null,
                fornec: dadosValidados?.fornec?.id ? dadosValidados?.fornec : null,
            });
            
            filtrosTemplateAplicados = gerarFiltroTemplate(filtrosTemplateAplicados);

            if (dadosValidados.periodo_emissao_start !== null)
                dadosValidados.periodo_emissao_start = dataToStr(dadosValidados.periodo_emissao_start, "yyyy-MM-dd");
            if (dadosValidados.periodo_emissao_end !== null)
                dadosValidados.periodo_emissao_end = dataToStr(dadosValidados.periodo_emissao_end, "yyyy-MM-dd");
            if (dadosValidados.periodo_pagamento_start !== null)
                dadosValidados.periodo_pagamento_start = dataToStr(
                    dadosValidados.periodo_pagamento_start,
                    "yyyy-MM-dd"
                );
            if (dadosValidados.periodo_pagamento_end !== null)
                dadosValidados.periodo_pagamento_end = dataToStr(dadosValidados.periodo_pagamento_end, "yyyy-MM-dd");

            let filtros = {};
            if (dadosValidados) {
                Object.keys(dadosValidados).forEach((key) => {
                    if (dadosValidados[key]) filtros[key] = dadosValidados[key];
                });
            }
            
            if(buscarListagem === false){
                let filtros = {};
                if (dadosValidados) {
                    Object.keys(dadosValidados).forEach((key) => {
                        if (dadosValidados[key] !== undefined && dadosValidados[key] !== null) filtros[key] = dadosValidados[key];
                    });
                    solicitarRelatorio({
                        chave: RELATORIOS_EMITIR_FINANCEIRO_CONTASPAGAS,
                        emails,
                        filtros,
                        versao,
                        filtros_template: filtrosTemplateAplicados,
                        corpo_email,
                        enviar_fila: background,
                    });
                }
            }
            else{
                let filtros = [];
                for (const [k, v] of Object.entries(dadosValidados)) {
                    if (v !== null && v !== "") {
                        if ((k === 'faixa_valor_start' && v === 0)) continue
                        if ((k === 'faixa_valor_end' && v === 0)) continue
                        filtros.push(`${FILTROS_VIEWSET[k]}=${v}`);
                    }
                }
                const _url = `${BASE_URL}?${filtros.join("&")}`;
                console.log(_url)
                setUrl(_url);
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                setErrors(errorMessages);
            }
        }
    }

    const colunas = [
        { field: "empresa", header: "Empresa"},
        { field: 'documento', header: "Documento"},
        { field: "fornecedor", header: "Fornecedor", action: (e) => `${e.fornecedor} - ${formatarDocumento(e.identificacao_fornecedor)}`},
        { field: "emissao", header: "Emissão",align: "center",action: (e) => dataToStr(e.emissao, "dd/MM/yyyy")},
        { field: "data_pagamento", header: "Pagamento", align: "center", action: (e) => dataToStr(e.data_pagamento, "dd/MM/yyyy")},
        { field: "forma_pagamento", header: "Forma pgto"},
        { field: "valor", header: "Valor",align: "right",action: (e) => `${formatarCasasDecimais(e.valor,2, {minimumFractionDigits: 2})}`},
    ]



    const limparFormulario = () => {
        formRef.current?.resetForm();
    };

    const limparVisualizacao = () => {
        setBuscarListagem(false)
        setUrl(null);
    };

    const submit = (e, limpar = true) => {
        if (limpar) limparVisualizacao();
        formRef.current?.handleSubmit(e);
    };

    const setErrors = (values) => {
        formRef.current?.setErrors(values);
    };

    const setFieldValue = (field, value, shouldValidate) => {
        formRef.current?.setFieldValue(field, value, shouldValidate);
    };

    const onChangeEmpresa = (e) => {
        empresaSelecionada.current = e?.empresa;
    };

    const handleVisualizar = (e) => {
        setBuscarListagem(true);
        submit(e, false);
    }

    const aplicarFiltrosCsv = (values) => {
        return botaoCsv?.current?.filtrosCSV(values);
    };

    const gerarFiltrosDadosValidados = (dados) => {
        let filtros = {};
        if (dados) {
            Object.keys(dados).forEach((key) => {
                if (dados[key] !== null && dados[key] !== "") filtros[key] = dados[key];
            });
        }
        return filtros;
    };

    const filtersOnClick = async () => {
        const { values = {} } = formRef.current?.getFormikInstance();
        let { background, emails, corpo_email, ...dadosValidados } = values;
        if (dadosValidados.periodo_emissao_start !== null)
            dadosValidados.periodo_emissao_start = dataToStr(dadosValidados.periodo_emissao_start, "yyyy-MM-dd");
        if (dadosValidados.periodo_emissao_end !== null)
            dadosValidados.periodo_emissao_end = dataToStr(dadosValidados.periodo_emissao_end, "yyyy-MM-dd");
        const filtros = gerarFiltrosDadosValidados(dadosValidados);
        return aplicarFiltrosCsv(filtros);
    };

    const BotaoExportar = (props) => {
        return (
            <ExportCSV
                {...props}
                ref={botaoCsv}
                chave_relatorio={RELATORIOS_EMITIR_FINANCEIRO_CONTASPAGAS}
                filtersOnClick={filtersOnClick}
            />
        );
    };

    const carregaUsuarioAtual = useCallback(async () => {
        //showLoading();
        const resposta = await axiosGet("/auth/users/me/");
        //hideLoading();
        console.log(resposta)
        if (resposta.status === 200) setFieldValue("usuario", resposta.data.username);
    }, []);

    useEffect(() => {
        carregaUsuarioAtual();
    }, [carregaUsuarioAtual]);

    return (
        <R.Wrapper titulo={"contas pagas"}>
            <MakoFormGerador
                ref={formRef}
                formikProps={{
                    initialValues: {
                        periodo_emissao_start: null,
                        periodo_emissao_end: null,
                        periodo_vencimento_start: null,
                        periodo_vencimento_end: null,
                        empresa: null,
                        fornec: null,
                        faixa_valor_start: 0,
                        faixa_valor_end: 0,
                        forma_recebimento: null,
                        parcela: null,
                        codigo: "",
                        orderBy: "",
                        ordenacao: "fornecedor",
                    },
                    onSubmit: handleSubmit,
                }}
                camposFormularios={[
                    {
                        label: "Empresa *",
                        inputId: "empresa",
                        inputName: "empresa",
                        component: MakoDropdownEmpresas,
                        componentEspecial: COMPONENTES_ESPECIAIS.EMPRESA,
                        componentProps: {
                            placeholder: "Selecione uma empresa",
                            getOnChange: onChangeEmpresa,
                        },
                        fieldSize: 4,
                    },
                    {
                        label: "Periodo emissão",
                        inputId: "periodo_emissao_start",
                        inputName: "periodo_emissao_start",
                        component: MakoCalendar,
                        componentProps: {
                            valueKey: "valueCalendar",
                        },
                        fieldSize: 2,
                    },
                    {
                        inputId: "periodo_emissao_end",
                        inputName: "periodo_emissao_end",
                        component: MakoCalendar,
                        componentProps: {
                            valueKey: "valueCalendar",
                        },
                        divClassName: "p-mt-4",
                        divStyle: {
                            paddingTop: "5px",
                        },
                        fieldSize: 2,
                    },
                    {
                        label: "Periodo pagamento",
                        inputId: "periodo_pagamento_start",
                        inputName: "periodo_pagamento_start",
                        component: MakoCalendar,
                        componentProps: {
                            valueKey: "valueCalendar",
                        },
                        fieldSize: 2,
                    },
                    {
                        inputId: "periodo_pagamento_end",
                        inputName: "periodo_pagamento_end",
                        component: MakoCalendar,
                        componentProps: {
                            valueKey: "valueCalendar",
                        },
                        divClassName: "p-mt-4",
                        divStyle: {
                            paddingTop: "5px",
                        },
                        fieldSize: 2,
                    },
                    {
                        label: "Forma de pagamento:",
                        inputId: "forma_recebimento",
                        inputName: "forma_recebimento",
                        component: Dropdown,
                        componentProps: {
                            placeholder: "Selecione uma forma de pagamento",
                            filter: true,
                            filterBy: "descricao",
                            optionValue: "codigo",
                            optionLabel: "descricao",
                            url: "/financeiro/formas-recebimentos/"
                        },
                        fieldSize: 3,
                    },
                    {
                        label: "Conta financeira",
                        inputId: "conta_financeira",
                        inputName: "conta_financeira",
                        component: Dropdown,
                        componentProps:{
                            url: "/financeiro/contas-financeiras/",
                            optionValue: "id",
                            optionLabel: "descricao"
                        }
                    },
                    {
                        label: "Ordenação",
                        inputId: "ordenacao",
                        inputName: "ordenacao",
                        component: Dropdown,
                        componentProps: {
                            placeholder: "Selecione sua forma de ordenação",
                            optionValue: "value",
                            optionLabel: "label",
                            options: OPTIONS_ORDENACAO,
                            showClear: false,
                        },
                        fieldSize: 3,
                    }
                ]}
            >
                <R.Buttons.Wrapper>
                    <R.Buttons.Visualizar onClick={handleVisualizar} />
                    <R.Buttons.GerarPdf
                        chave={RELATORIOS_EMITIR_FINANCEIRO_CONTASPAGAS}
                        setFieldValue={setFieldValue}
                        handleSubmit={submit}
                    />
                    <R.Buttons.EnviarEmail handleSubmit={submit} setFieldValue={setFieldValue} />
                    <R.Buttons.Limpar onClick={limparFormulario} />
                </R.Buttons.Wrapper>
            </MakoFormGerador>
            <MakoListagem
                ref={listagemRef}
                colunas={colunas}
                urlPesquisa={url}
                msgTabelaVazia={typeof url !== "string" && "Busca não efetuada"}
                botaoExportar={BotaoExportar}
                configTabela={{
                    paginator: true,
                    lazy: true,
                    exportFilename: FILE_NAME,
                }}>
                
            </MakoListagem>
        </R.Wrapper>
    );
};