import React, { useCallback, useEffect, useState } from "react";

import { Skeleton } from "./skeletons";
import { Avatar } from "primereact";
import useAxiosPrivate from "@/hooks/useAxiosPrivate";

const MakoCardUsuarios = ({ title, actionTemplate }) => {
    const [loading, setLoading] = useState(false);
    const [dados, setDados] = useState([]);
    const { axiosGet } = useAxiosPrivate();

    const fetchData = useCallback(
        async (signal) => {
            setLoading(() => true);
            const response = await axiosGet("/dashboard/usuarios-ativos/?limit=100", { signal });
            setLoading(() => false);
            if (response.status === 200) {
                const usuarios = response.data.results.map((usuario) => {
                    const { emailperfil_set, papeis_vigentes, ...rest } = usuario;
                    const email = emailperfil_set.length > 0 ? emailperfil_set[0].email : "";
                    const papeis = papeis_vigentes.map((papel) => papel.nome).join(" - ");
                    return { ...rest, email, papeis };
                });
                setDados(usuarios);
            }
        },
        [axiosGet]
    );

    useEffect(() => {
        const controller = new AbortController();
        fetchData(controller.signal);
        return () => {
            controller.abort();
        };
    }, [fetchData]);

    return (
        <div className="card">
            <h4>{title}</h4>
            <ul className="widget-person-list">
                {!loading ? (
                    <>
                        {dados.map((user) => (
                            <li key={user.id}>
                                <div className="person-item">
                                    <Avatar
                                        icon="pi pi-user"
                                        image={user.foto}
                                        imageAlt={user.usuario.username ? user.usuario.username : "Icone usuário"}
                                        className="p-mr-2"
                                        shape="circle"
                                        size="large"
                                    />
                                    <div className="person-info">
                                        <div className="person-name">{user.usuario.username}</div>
                                        <div className="person-subtext">{user.papeis}</div>
                                    </div>
                                </div>
                                {/* <div className="person-actions">{actionTemplate(user)}</div> */}
                            </li>
                        ))}
                    </>
                ) : (
                    <Skeleton />
                )}
            </ul>
        </div>
    );
};

export default React.memo(MakoCardUsuarios);
