import React, { useCallback, useRef } from "react";

import { ListagemPendencias } from "../../features";
import { Pendencias } from "../../features/Pendencias";

import { TIPO_CHAVE_PENDENCIAS } from "@/assets/constants/financeiro";

import useCaixaMovimento from "@/hooks/useCaixaMovimento";

const { Buttons } = Pendencias;

export const PendenciasCaixa = () => {
    const { caixaMov } = useCaixaMovimento();
    const listagemRef = useRef(null);

    const atualizarListagem = useCallback(() => {
        listagemRef.current?.buscarDados();
    }, []);

    const actions = (rowData) => {
        const { tipo_chave } = rowData || {};
        let action = <></>;
        switch (tipo_chave) {
            case TIPO_CHAVE_PENDENCIAS.PAGAMENTO:
                action = <Buttons.Pagar />;
                break;
            case TIPO_CHAVE_PENDENCIAS.RECEBIMENTO_CREDIARIO:
                action = <Buttons.ReceberCrediario />;
                break;
            case TIPO_CHAVE_PENDENCIAS.VENDA_CARTAO:
                action = <Buttons.ReceberCartao />;
                break;
            case TIPO_CHAVE_PENDENCIAS.VENDA_ENTRADA:
                action = <Buttons.ReceberEntrada pendencia={rowData} onCloseModal={atualizarListagem} />;
                break;
            case TIPO_CHAVE_PENDENCIAS.VENDA_BOLETO:
                action = <Buttons.EmitirBoleto />;
                break;
            case TIPO_CHAVE_PENDENCIAS.VENDA_CREDIARIO:
                action = <Buttons.ConfirmarCrediario pendencia={rowData} onConfirm={atualizarListagem} />;
                break;
            default:
                break;
        }
        return <div className="actions actions-pendencias">{action}</div>;
    };

    return (
        <>
            <ListagemPendencias listagemRef={listagemRef} caixaMovimento={caixaMov} actions={actions} />
        </>
    );
};
