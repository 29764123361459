import React, { useState, useRef } from "react";
import { Button } from "primereact/button";
import { Redirect } from "react-router-dom";
import { ConfirmDialog } from "primereact/confirmdialog";
import { Toast } from "primereact/toast";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import { axiosDelete } from "@/services/http";

import permissoes from "@/assets/constants/permissoes";
import MakoListagem from "@/components/MakoListagem";
import useLoading from "@/hooks/useLoading";

const url = "/plano-operacional/fases-projetos/";

export const OperacionalFasesProjetosPage = () => {
    const [redirecionar, setRedirecionar] = useState(null);
    const [deleteDialog, setDeleteDialog] = useState(false);
    const [fasesProjetos, setFasesProjetos] = useState(null);
    const { showLoading, hideLoading } = useLoading();
    const toastRef = useRef(null);
    const listagemRef = useRef(null);

    const confirmarDelete = (fasesProjetos) => {
        setFasesProjetos(fasesProjetos);
        setDeleteDialog(true);
    };

    const esconderDeleteDialog = () => {
        setDeleteDialog(false);
    };

    const deleteParametro = async () => {
        showLoading();
        const resposta = await axiosDelete(`${url}${fasesProjetos.id}`);
        hideLoading();

        if (resposta.status === 200 || resposta.status === 204) {
            toastRef.current.show({
                severity: "success",
                summary: "Sucesso",
                detail: "Fases de projetos deletado com sucesso!",
                life: 3000,
            });

            listagemRef.current?.buscarDados();
        } else {
            toastRef.current.show({
                severity: "error",
                summary: "Erro",
                detail: "Desculpe, não conseguimos processar a sua requisição.",
                life: 3000,
            });
            hideLoading();
        }
        setDeleteDialog(false);
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <MakoControleAcesso
                    permissao={[permissoes.OPERACIONAL_PLANOOPERACIONAL_FASESPROJETOS_EDITAR]}
                    componente={Button}
                    icon="pi pi-pencil"
                    className="p-button-rounded p-button-warning p-mr-2 p-mb-1"
                    tooltip="Alterar cadastro de fases de projetos"
                    tooltipOptions={{ position: "left" }}
                    onClick={() =>
                        setRedirecionar({
                            pathname: "/operacional/plano-operacional/fases-projetos/form",
                            state: { fasesProjetos: rowData },
                        })
                    }
                />
                <MakoControleAcesso
                    permissao={[permissoes.OPERACIONAL_PLANOOPERACIONAL_FASESPROJETOS_EXCLUIR]}
                    componente={Button}
                    icon="pi pi-trash"
                    className="p-button-rounded p-button-danger p-mr-2 p-mb-1"
                    onClick={() => confirmarDelete(rowData)}
                    tooltip="Deletar fases de projetos"
                    tooltipOptions={{ position: "left" }}
                />
            </div>
        );
    };

    const cabecalhoTabela = (
        <>
            <MakoControleAcesso
                permissao={[permissoes.OPERACIONAL_PLANOOPERACIONAL_FASESPROJETOS_INCLUIR]}
                componente={Button}
                label="Novo"
                icon="pi pi-plus"
                className="p-button-success p-mr-2"
                onClick={() => setRedirecionar("/operacional/plano-operacional/fases-projetos/form")}
            />
        </>
    );

    const colunas = [
        { field: "id", header: "Código", style: { minWidth: "100px" } },
        { field: "projeto.nome", header: "Projeto", style: { minWidth: "300px" } },
        { field: "fase", header: "Fase", style: { minWidth: "100px" } },
        { field: "descricao", header: "Descrição", style: { minWidth: "400px" } },
        { field: "data_inicio", header: "Data inicio", style: { minWidth: "130px" } },
        { field: "data_fim", header: "Data fim", style: { minWidth: "130px" } },
        {
            field: "action",
            header: "Ações",
            action: (e) => actionBodyTemplate(e),
            style: { minWidth: "130px" },
            frozen: true,
            alignFrozen: "right",
        },
    ];

    return redirecionar ? (
        <Redirect to={redirecionar} />
    ) : (
        <div className="p-grid">
            <Toast ref={toastRef} />
            <div className="p-col-12">
                <div className="card">
                    <MakoListagem
                        ref={listagemRef}
                        titulo="Fases de Projetos"
                        colunas={colunas}
                        painelEsquerdo={cabecalhoTabela}
                        urlPesquisa={url}
                        responsiva
                        configTabela={{
                            paginator: true,
                            lazy: true,
                            frozenWidth: "0.5vw",
                            flexGrow: 1,
                            flexBasis: "12rem",
                            scrollable: true,
                        }}
                    />
                    <ConfirmDialog
                        visible={deleteDialog}
                        onHide={esconderDeleteDialog}
                        header="Confirmação"
                        message={
                            fasesProjetos && (
                                <span>
                                    {"Deseja realmente excluir a fase de projetos "}
                                    <b>{fasesProjetos.nome}</b>?
                                </span>
                            )
                        }
                        icon="pi pi-info-circle p-mr-3"
                        accept={deleteParametro}
                        acceptLabel="Sim"
                        acceptClassName="p-button-danger"
                        reject={esconderDeleteDialog}
                        rejectLabel="Não"
                    />
                </div>
            </div>
        </div>
    );
};
