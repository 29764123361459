import { useCallback } from "react";

import useHttp from "@/hooks/useHttp";
import useToast from "@/hooks/useToast";

const BASE_URL = "/financeiro/caixas/{caixa}/pendencias/{pendencia}/atribuir";

const useAtribuirPendenciaCaixa = (caixa_movimento_id) => {
    const { showError } = useToast();
    const { httpPut } = useHttp();

    if (!caixa_movimento_id) throw new Error("Informe o id do caixa");

    const handleAtribuir = useCallback(
        async ({ pendencia_id, tipo_chave }) => {
            const url = BASE_URL.replace("{caixa}", caixa_movimento_id).replace("{pendencia}", pendencia_id);
            let pendencia = null;
            const handlers = {
                200: ({ data }) => (pendencia = data?.pendencia),
                400: ({ data }) =>
                    showError({
                        summary: "Alerta!",
                        detail: data?.msg || "Erro ao vincular pendência ao caixa",
                        life: 3000,
                    }),
            };
            await httpPut({ url, body: { tipo_chave } }, handlers);
            return pendencia;
        },
        [caixa_movimento_id, httpPut, showError]
    );

    return handleAtribuir;
};

export default useAtribuirPendenciaCaixa;
