import React, { useCallback, useEffect, useState } from "react";
import { useFormik } from "formik";
import classNames from "classnames";
import * as Yup from "yup";
import { InputTextarea } from "primereact/inputtextarea";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { MakoAutoComplete } from "@/components/MakoAutoComplete";
import { MakoCalendarTime } from "@/components/MakoCalendarTime";
import { axiosGet } from "@/services/http";
import useRequisicao from "@/hooks/useRequisicao";
import useLoading from "@/hooks/useLoading";
import useToast from "@/hooks/useToast";

export const FormDadosBasicos = ({ ableButtonStep }) => {
    const [categorias, setCategorias] = useState([]);
    const [centros, setCentros] = useState([]);
    const [centroResultado, setCentroResultado] = useState([]);
    const [setores, setSetores] = useState([]);

    const { showLoading, hideLoading } = useLoading();
    const { requisicao, updateRequisicao } = useRequisicao();
    const { showError } = useToast();

    const { setValues, ...formik } = useFormik({
        initialValues: {
            solicitante: null,
            data_hora: null,
            grupo_categoria: null,
            centro_estocagem: null,
            centro_resultados: null,
            setor: null,
            observacao: "",
        },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                solicitante: Yup.object()
                    .shape({
                        id: Yup.number().required("O campo 'solicitante' é obrigatório"),
                    })
                    .typeError("Informe um 'solicitante' válido"),
                data_hora: Yup.date().required().typeError("Informe uma 'data/hora' válido"),
                grupo_categoria: Yup.number().required().typeError("O campo 'grupo categoria' é obrigatório"),
                centro_estocagem: Yup.number().required().typeError("O campo 'centro estocagem' é obrigatório"),
                centro_resultados: Yup.number().typeError("Informe um 'centro de resultado' válido"),
                setor: Yup.number().typeError("Informe um 'setor' válido"),
                observacao: Yup.string().max(100, "Informe no máximo 100 caracteres."),
            });

            await formSchema.validate(values, {
                abortEarly: false,
            });

            updateRequisicao({ ...requisicao, ...values });
            ableButtonStep(true);
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};

                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });

                formik.setErrors(errorMessages);
            }
            ableButtonStep(false);
        }
    }

    const fetchCategoriasGrupos = useCallback(async () => {
        showLoading();

        const response = await axiosGet(`/produtos/categorias-grupos/`);

        if (response.status === 200) {
            setCategorias(response.data.results);
        } else {
            showError({
                summary: "Erro :(",
                detail: "Desculpe, não conseguimos listas as categorias.",
                life: 3000,
            });
        }

        hideLoading();
    }, [showLoading, hideLoading]);

    const fetchCentrosEstocagem = useCallback(async () => {
        showLoading();

        const response = await axiosGet(`/produtos/centros-estocagem/`);

        if (response.status === 200) {
            setCentros(response.data.results);
        } else {
            showError({
                summary: "Erro :(",
                detail: "Desculpe, não conseguimos listas os centros de estocagem.",
                life: 3000,
            });
        }

        hideLoading();
    }, [showLoading, hideLoading]);

    const fetchSetores = useCallback(async () => {
        showLoading();

        const response = await axiosGet(`/plano-operacional/setores/`);

        if (response.status === 200) {
            setSetores(response.data.results);
        } else {
            showError({
                summary: "Erro :(",
                detail: "Desculpe, não conseguimos listas os setores.",
                life: 3000,
            });
        }

        hideLoading();
    }, [showLoading, hideLoading]);

    const fetchCentroResultado = useCallback(async () => {
        showLoading();

        const response = await axiosGet(`/plano-operacional/centros-resultados/`);

        if (response.status === 200) {
            setCentroResultado(response.data.results);
        } else {
            showError({
                summary: "Erro :(",
                detail: "Desculpe, não conseguimos listas os centros de resultados.",
                life: 3000,
            });
        }

        hideLoading();
    }, [showLoading, hideLoading]);

    const handleReset = useCallback(() => {
        formik.resetForm();
        ableButtonStep(false);

        if (requisicao) {
            updateRequisicao({
                ...requisicao,
                solicitante: null,
                data_hora: null,
                grupo_categoria: null,
                centro_estocagem: null,
                centro_resultados: null,
                setor: null,
                observacao: "",
            });
        }
    }, [requisicao]);

    useEffect(() => {
        fetchCategoriasGrupos();
        fetchCentrosEstocagem();
        fetchSetores();
        fetchCentroResultado();
    }, [fetchCategoriasGrupos, fetchCentrosEstocagem, fetchSetores, fetchCentroResultado]);

    useEffect(() => {
        if (requisicao) {
            setValues({
                solicitante: requisicao.solicitante,
                data_hora: requisicao.data_hora,
                grupo_categoria: requisicao.grupo_categoria,
                centro_estocagem: requisicao.centro_estocagem,
                centro_resultados: requisicao.centro_resultados,
                setor: requisicao.setor,
                observacao: requisicao.observacao,
            });
        }
    }, [requisicao]);

    return (
        <div className="">
            <form onSubmit={formik.handleSubmit}>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-4">
                        <label htmlFor="solicitante">Solicitante *</label>
                        <MakoAutoComplete
                            id="solicitante"
                            name="solicitante"
                            placeholder="Busque pelo nome... (min 3 caracteres)"
                            minCaracteresBusca={3}
                            value={formik.values.solicitante}
                            onChange={formik.handleChange}
                            field="nome"
                            urlSearch="/pessoas/perfis?query={id,nome,vinculoperfilempresa_set}&nome__contains="
                            className={classNames({ "p-invalid": formik.errors.solicitante })}
                        />
                        {formik.errors.solicitante && <small className="p-error">{formik.errors.solicitante}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="data_hora">Data/Hora *</label>
                        <MakoCalendarTime
                            id="data_hora"
                            name="data_hora"
                            value={formik.values.data_hora}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.data_hora })}
                        />
                        {formik.errors.data_hora && <small className="p-error">{formik.errors.data_hora}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="grupo_categoria">Grupo categoria *</label>
                        <Dropdown
                            id="grupo_categoria"
                            name="grupo_categoria"
                            options={categorias}
                            optionValue="id"
                            optionLabel="descricao"
                            filter
                            filterBy="descricao"
                            placeholder="Selecione um grupo categoria..."
                            value={formik.values.grupo_categoria}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.grupo_categoria })}
                        />
                        {formik.errors.grupo_categoria && (
                            <small className="p-error">{formik.errors.grupo_categoria}</small>
                        )}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="centro_estocagem">Centro de estocagem *</label>
                        <Dropdown
                            id="centro_estocagem"
                            name="centro_estocagem"
                            options={centros}
                            optionValue="id"
                            optionLabel="nome"
                            filter
                            filterBy="nome"
                            placeholder="Selecione um centro de estocagem..."
                            value={formik.values.centro_estocagem}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.centro_estocagem })}
                        />
                        {formik.errors.centro_estocagem && (
                            <small className="p-error">{formik.errors.centro_estocagem}</small>
                        )}
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-6">
                        <label htmlFor="centro_resultados">Centro de resultado *</label>
                        <Dropdown
                            id="centro_resultados"
                            name="centro_resultados"
                            options={centroResultado}
                            optionValue="id"
                            optionLabel="nome"
                            filter
                            filterBy="nome"
                            placeholder="Selecione um centro de resultado..."
                            value={formik.values.centro_resultados}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.centro_resultados })}
                        />
                        {formik.errors.centro_resultados && (
                            <small className="p-error">{formik.errors.centro_resultados}</small>
                        )}
                    </div>
                    <div className="p-field p-col-12 p-md-6">
                        <label htmlFor="setor">Setor: *</label>
                        <Dropdown
                            id="setor"
                            name="setor"
                            options={setores}
                            optionValue="id"
                            optionLabel="nome"
                            filter
                            filterBy="nome"
                            placeholder="Selecione um setor..."
                            value={formik.values.setor}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.setor })}
                        />
                        {formik.errors.setor && <small className="p-error">{formik.errors.setor}</small>}
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-12">
                        <label htmlFor="observacao">Observação</label>
                        <InputTextarea
                            id="observacao"
                            name="observacao"
                            value={formik.values.observacao}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.observacao })}
                            autoResize
                            rows={3}
                            maxLength={100}
                        />
                        {formik.errors.observacao && <small className="p-error">{formik.errors.observacao}</small>}
                    </div>
                </div>
                <p>
                    <b>* Campos obrigatórios</b>
                </p>
                <div className="p-grid">
                    <div className="p-col-12 p-md-6">
                        <Button type="submit" icon="pi pi-check" label="Gravar" className="p-mr-2 p-mb-2" />
                        <Button
                            type="reset"
                            icon="pi pi-trash"
                            label="Limpar"
                            className="p-button-warning p-mr-2 p-mb-2"
                            onClick={handleReset}
                        />
                    </div>
                </div>
            </form>
        </div>
    );
};
