import React, { useCallback, useEffect, useMemo } from "react";
import { useHistory } from "react-router-dom";

import { DevolucaoFornecedorFormDadosBasicos } from "./formDadosBasicos";
import { DevolucaoFornecedorFormItens } from "./formItens";
import { DevolucaoFornecedorFormFinanceiro } from "./formFinanceiro";
import { PageBase } from "@/components/PageBase";
import { MakoMultiTabs } from "@/components/MakoMultiTabs";
import useDevolucaoFornecedor from "@/hooks/useDevolucaoFornecedor";
import { DocFiscalForm } from "./formDocFiscal";
import { BlockUI } from "primereact/blockui";
import { SITUACAO_VENDA_DEVOLUCAO } from "@/assets/constants/constants";

export const TabDevolucaoFornecedor = ({ devolucaoId }) => {
    const history = useHistory();
    const { possuiDevolucao, handlePreencherTroca, editavel, dadosBasicos } = useDevolucaoFornecedor();

    const TABS = [
        {
            header: "Dados básicos",
            icon: "pi pi-fw pi-align-left",
            component: (
                <BlockUI blocked={!editavel}>
                    <DevolucaoFornecedorFormDadosBasicos />
                </BlockUI>
            ),
        },
        {
            header: "Itens",
            icon: "fas pi-fw fa-cubes",
            component: <DevolucaoFornecedorFormItens />,
            disabled: !possuiDevolucao,
        },
        {
            header: "Financeiro",
            icon: "pi pi-fw pi-money-bill",
            component: <DevolucaoFornecedorFormFinanceiro />,
            disabled: !possuiDevolucao,
        },
        {
            header: "Doc. fiscal",
            icon: "pi pi-fw pi-book",
            disabled: !possuiDevolucao,
            component: <DocFiscalForm />,
        },
    ];

    const preencherDevolucao = useCallback(() => {
        if (devolucaoId) handlePreencherTroca(devolucaoId);
    }, [handlePreencherTroca, devolucaoId]);

    const title = useMemo(() => {
        const BASE_TITLE = "Lançamento de devolução ao fornecedor";
        if (!dadosBasicos?.id) return BASE_TITLE;
        const { id, status, fornecedor } = dadosBasicos || {};
        const { label } = SITUACAO_VENDA_DEVOLUCAO.find(({ value }) => value === status) || {};
        return `${BASE_TITLE}: (${id}) - ${fornecedor?.nome} - ${label}`;
    }, [dadosBasicos]);

    useEffect(() => {
        preencherDevolucao();
    }, [preencherDevolucao]);

    return (
        <PageBase>
            <MakoMultiTabs
                tabs={TABS}
                title={title}
                propsButtonProx={{ disabled: !possuiDevolucao }}
                ocultarButtonFinalizar
                propsButtonCancelar={{ onClick: () => history.push("/compras/entrada/devolucao-fornecedor") }}
            />
        </PageBase>
    );
};
