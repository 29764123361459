import React, { useCallback, useEffect, useRef, useState } from "react";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { axiosGet, axiosPost } from "@/services/http";
import { Toast } from "primereact/toast";
import useLoading from "@/hooks/useLoading";

export const ImprimirFichaCrediarioForm = (props) => {
    const [ficha, setFicha] = useState(null);
    const [listaRelatorios, setListaRelatorios] = useState([]);
    const { showLoading, hideLoading } = useLoading();
    const toastRef = useRef(null);

    async function handleImprimir() {
        try {
            // /relatorios/gerar/
            // usar o hook useRelatorio, ele ja faz todas as tratativas
            showLoading();
            const resposta = await axiosPost("", {});
            hideLoading();

            if (resposta.status === 201) {
                toastRef.current.show({
                    severity: "success",
                    summary: "Sucesso",
                    detail: "Ficha gerada com sucesso!",
                    life: 3000,
                });
                props.esconderDialog(false);
            }
        } catch (error) {
            toastRef.current.show({
                severity: "error",
                summary: "Erro",
                detail: "Falha ao gerar ficha de crediário, por favor tente novamente.",
                life: 1500,
            });
        }
    }

    const listarRelatorios = useCallback(async () => {
        showLoading();
        const resposta = await axiosGet(`/relatorios/relatorios?tipo=2`);
        hideLoading();

        if (resposta.status === 200 || resposta.status === 201) {
            setListaRelatorios(resposta.data.results);
        }
    }, [showLoading, hideLoading]);

    useEffect(() => {
        listarRelatorios();
    }, [listarRelatorios]);

    return (
        <div className="p-grid">
            <Toast ref={toastRef} />
            <div className="p-col-12">
                <div className="p-fluid p-formgrid p-grid ">
                    <div className="p-field p-col-12 p-md--12">
                        <label htmlFor="ficha">Selecione uma ficha: *</label>
                        <Dropdown
                            id="ficha"
                            name="ficha"
                            options={listaRelatorios}
                            optionValue="id"
                            optionLabel="descricao"
                            filter
                            filterBy="descricao"
                            placeholder="Selecione..."
                            value={ficha}
                            onChange={(e) => setFicha(e.target.value)}
                        />
                    </div>
                </div>
                <div className="p-grid p-justify-center p-mt-3">
                    <Button
                        type="button"
                        icon="pi pi-print"
                        label="Imprimir"
                        className="p-button-info p-mr-2"
                        disabled={!ficha}
                        onClick={() => handleImprimir()}
                    />
                    <Button
                        type="button"
                        icon="pi pi-times"
                        label="Fechar"
                        className="p-button-danger p-button-outlined"
                        onClick={() => props.esconderDialog(false)}
                    />
                </div>
            </div>
        </div>
    );
};
