import React, { useState, useRef } from "react";
import { Button } from "primereact/button";
import { Redirect } from "react-router-dom";
import { ConfirmDialog } from "primereact/confirmdialog";
import { Toast } from "primereact/toast";
import { axiosDelete } from "@/services/http";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";

import permissoes from "@/assets/constants/permissoes";
import MakoListagem from "@/components/MakoListagem";
import useLoading from "@/hooks/useLoading";
import { Tag } from "primereact/tag";
import { Dropdown } from "primereact/dropdown";
import { CodigoFiltroTemplate, DropdownFiltroTemplate, TextoFiltroTemplate } from "@/components/MakoFiltrosCabecalho";
import {  TIPO_ESCOPO_FAIXA_COMISSAO_CHOICE } from "@/assets/constants/constants";

export const FaixaComissaoPage = () => {
    const [redirecionar, setRedirecionar] = useState(null);
    const [deleteDialog, setDeleteDialog] = useState(false);
    const [faixa, setFaixa] = useState(null);
    const { showLoading, hideLoading } = useLoading();
    const toastRef = useRef(null);
    const listagemRef = useRef(null);

    const confirmarDelete = (faixa) => {
        setFaixa(faixa);
        setDeleteDialog(true);
    };

    const esconderDeleteDialog = () => {
        setDeleteDialog(false);
    };

    const deleteRegra = async () => {
        showLoading();
        const resposta = await axiosDelete(`/vendas/faixa-comissao/${faixa.id}`);
        hideLoading();

        if (resposta.status === 204) {
            toastRef.current.show({
                severity: "success",
                summary: "Sucesso",
                detail: "Faixa de comissão deletada com sucesso!",
                life: 3000,
            });

            listagemRef.current?.buscarDados();
        } else {
            toastRef.current.show({
                severity: "error",
                summary: "Erro",
                detail: "Desculpe, não conseguimos processar a sua requisição.",
                life: 3000,
            });
            hideLoading();
        }
        setDeleteDialog(false);
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <MakoControleAcesso
                    permissao={[permissoes.VENDAS_CADASTRO_FAIXACOMISSAO_EDITAR]}
                    componente={Button}
                    icon="pi pi-pencil"
                    className="p-button-rounded p-button-warning p-mr-2 p-mb-1"
                    tooltip="Alterar faixa de comissão"
                    onClick={() =>
                        setRedirecionar({
                            pathname: "/vendas/cadastros/faixa-comissao/form",
                            state: { faixa: rowData },
                        })
                    }
                />
                <MakoControleAcesso
                    permissao={[permissoes.VENDAS_CADASTRO_FAIXACOMISSAO_EXCLUIR]}
                    componente={Button}
                    icon="pi pi-trash"
                    tooltip="Deletar faixa de comissão"
                    className="p-button-rounded p-button-danger p-mr-2 p-mb-1"
                    onClick={() => confirmarDelete(rowData)}
                />
            </div>
        );
    };

    const cabecalhoTabela = (
        <>
            <MakoControleAcesso
                permissao={[permissoes.VENDAS_CADASTRO_FAIXACOMISSAO_INCLUIR]}
                componente={Button}
                label="Novo"
                icon="pi pi-plus"
                className="p-button-success p-mr-2"
                onClick={() => setRedirecionar("/vendas/cadastros/faixa-comissao/form")}
            />
        </>
    );

    const TIPO_CALCULO = [
        { value: "P", label: "Percentual" },
        { value: "V", label: "Valor fixo" },
    ];

    const tipoBodyTemplate = (rowData) => {
        if (rowData.tipo_calculo === "P") {
            return <Tag severity="info" value="Percentual" />;
        }

        return <Tag severity="info" value="Valor fixo" />;
    };

    const tipoFiltroTemplate = (options) => {
        return (
            <Dropdown
                placeholder="Selecione"
                showClear
                value={options.value}
                options={TIPO_CALCULO}
                onChange={(e) => options.filterCallback(e.value)}
            />
        );
    };

    const escopoFiltroTemplate = (options) => {
        return (
            <DropdownFiltroTemplate
                dropdownProps={{
                    optionValue: "value",
                    optionLabel: "label",
                    options: TIPO_ESCOPO_FAIXA_COMISSAO_CHOICE
                }}
                options={options}
            />
        );
    };

    const colunas = [
        { field: "id", header: "#ID", filter: true , filterElement: CodigoFiltroTemplate},
        { field: "codigo_faixa", header: "Código", filter: true , filterElement: CodigoFiltroTemplate},
        { field: "regra_apuracao.descricao", header: "Regra Apuração",filter: true, filterElement: TextoFiltroTemplate, filterField: 'regra_apuracao__descricao'},
        { field: "escopo.descricao", header: "Escopo" ,filter:true, filterElement: escopoFiltroTemplate, filterField: 'escopo'},
        {
            field: "tipo_calculo",
            header: "Tipo cálculo",
            filter: true,
            filterElement: tipoFiltroTemplate,
            action: (e) => tipoBodyTemplate(e),
        },
        {
            field: "action",
            header: "Ações",
            action: (e) => actionBodyTemplate(e),
            style: { width: "15%" },
        },
    ];

    return redirecionar ? (
        <Redirect to={redirecionar} />
    ) : (
        <div className="p-grid">
            <Toast ref={toastRef} />
            <div className="p-col-12">
                <div className="card">
                    <MakoListagem
                        ref={listagemRef}
                        titulo="Faixas de Comissão"
                        colunas={colunas}
                        painelEsquerdo={cabecalhoTabela}
                        urlPesquisa={"/vendas/faixa-comissao/"}
                        responsiva
                        filtros={{
                            id: { value: "", matchMode: "equals" },
                            codigo_faixa: { value: "", matchMode: "equals" },
                            tipo_calculo: { value: "", matchMode: "equals" },
                            regra_apuracao__descricao: {
                                operator: 'and',
                                constraints: [{ value: null, matchMode: "unaccent_icontains" }],
                            },
                            escopo: {value: null, matchMode: "equals"}
                        }}
                        configTabela={{
                            paginator: true,
                            lazy: true,
                            filterDisplay: "menu",
                        }}
                    />
                    <ConfirmDialog
                        visible={deleteDialog}
                        onHide={esconderDeleteDialog}
                        header="Confirmação"
                        message={
                            faixa && (
                                <span>
                                    {"Deseja realmente excluir a faixa de comissão "}
                                    <b>{faixa.descricao}</b>?
                                </span>
                            )
                        }
                        icon="pi pi-info-circle p-mr-3"
                        accept={deleteRegra}
                        acceptLabel="Sim"
                        acceptClassName="p-button-danger"
                        reject={esconderDeleteDialog}
                        rejectLabel="Não"
                    />
                </div>
            </div>
        </div>
    );
};
