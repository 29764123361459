import React, { createContext, useCallback, useState } from "react";
import { axiosGet, axiosPatch, axiosPost } from "@/services/http";
import useLoading from "@/hooks/useLoading";
import { formatarCasasDecimais } from "@/assets/util/util";

const TrocaContext = createContext({});

export const TrocaProvider = ({ children }) => {
    const [submit, setSubmit] = useState(false);
    const [dadosBasicos, setDadosBasicos] = useState(null);
    const { showLoading, hideLoading } = useLoading();

    const handleDadosTroca = useCallback(
        async (values) => {
            if (!values.id) {
                const { status, data } = await axiosPost("/vendas/troca/", {
                    ...values,
                    venda: values.venda.id,
                    cliente: values.cliente.id,
                });
                setDadosBasicos(data);
                return { status, data };
            } else {
                let diffProd = {};
                Object.entries(values).forEach(([k, v]) => {
                    if (v !== dadosBasicos[k]) {
                        diffProd[k] = v;
                    }
                });
                if (Object.keys(diffProd).length > 0) {
                    const { status, data } = await axiosPatch(`/vendas/troca/${values.id}/`, diffProd);
                    if (status === 200) setDadosBasicos(data);
                    return data;
                }
                return { status: 200, data: values };
            }
        },
        [dadosBasicos, setDadosBasicos]
    );

    const handleItemTrocaDevolucao = useCallback(
        async (values) => {
            if (!values.id) {
                const { sku, ...rest } = values;
                const { status, data } = await axiosPost("/vendas/itens-troca-devolucao/", {
                    ...rest,
                    item_venda: sku.id,
                    sku: sku.sku.id,
                    sku_movimenta_estoque: sku.sku_movimenta_estoque.id,
                    unidade_medida: sku.unidade_venda.id,
                    troca: dadosBasicos.id,
                    encargos: formatarCasasDecimais(rest.encargos),
                    subtotal: formatarCasasDecimais(rest.subtotal),
                });
                return { status, data };
            } else {
                const { status, data } = await axiosPatch(`/vendas/itens-troca-devolucao/${values.id}/`, values);
                return { status, data };
            }
        },
        [dadosBasicos?.id]
    );

    const handleItemSaida = useCallback(
        async (values) => {
            if (!values.id) {
                const { sku, ...rest } = values;
                const { status, data } = await axiosPost("/vendas/itens-troca-saida/", {
                    ...rest,
                    sku: sku.id,
                    troca: dadosBasicos.id,
                    encargos: formatarCasasDecimais(rest.encargos),
                    subtotal: formatarCasasDecimais(rest.subtotal),
                    desconto: formatarCasasDecimais(rest.desconto),
                });
                return { status, data };
            } else {
                const { status, data } = await axiosPatch(`/vendas/itens-troca-saida/${values.id}/`, values);
                return { status, data };
            }
        },
        [dadosBasicos?.id]
    );

    const handlePreencherTroca = useCallback(
        async (idTroca) => {
            showLoading();
            const resposta = await axiosGet(`/vendas/troca/${idTroca}/`);
            hideLoading();

            if (resposta.status === 200) {
                setDadosBasicos(resposta.data);
                setSubmit(true);
            }
        },
        [showLoading, hideLoading]
    );

    return (
        <TrocaContext.Provider
            value={{
                submit,
                dadosBasicos,
                setSubmit,
                handleDadosTroca,
                handleItemTrocaDevolucao,
                handleItemSaida,
                handlePreencherTroca,
            }}
        >
            {children}
        </TrocaContext.Provider>
    );
};

export default TrocaContext;
