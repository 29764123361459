import React, { useCallback, useEffect, useImperativeHandle, useRef, useState, forwardRef } from "react";
import { Dialog } from "primereact/dialog";
import { DataView } from "primereact/dataview";
import { Tag } from "primereact/tag";
import { Button } from "primereact/button";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";

import { ModalNotificacoes } from "./ModalNotificacoes";
import { BotaoAprovarNotificacao } from "./BotaoAprovarNotificacao";
import { BotaoEscalarNotificacao } from "./BotaoEscalarNotificacao";
import { BotaoReprovarNotificacao } from "./BotaoReprovarNotificacao";
import { BlockUI } from "@/components/BlockUI";
import { STATUS_NOTIFICACAO_CHOICE, TIPO_NOTIFICACAO_CHOICE } from "@/assets/constants/notificacao";
import { dataToStr } from "@/assets/util/datas";
import { axiosDelete, axiosGet } from "@/services/http";
import useToast from "@/hooks/useToast";
import { MakoCalendar } from "@/components/MakoCalendar";
import { classNames } from "primereact/utils";

const Modal = (props, ref) => {
    const [visible, setVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState(null);
    const [notificacoes, setNotificacoes] = useState([]);
    const modalNotificacoesRef = useRef(null);
    const { showError } = useToast();

    const abrirModal = () => {
        setVisible(true);
    };

    const fecharModal = () => {
        setVisible(false);
    };

    useImperativeHandle(ref, () => ({ abrirModal }));

    const listarNotificacoes = useCallback(async () => {
        setLoading(true);
        const { status, data } = await axiosGet("/pessoas/notificacoes-perfil/");
        setLoading(false);
        if (status === 200) {
            setNotificacoes(data.results);
        } else {
            showError({
                summary: "Erro :(",
                detail: "Desculpe, não conseguimos processar sua requisição.",
                life: 3000,
            });
        }
    }, [showError]);

    useEffect(() => {
        listarNotificacoes();
    }, [listarNotificacoes]);

    const tagTemplate = (item) => {
        const style = item._severity === "secondary" ? { background: "rgb(96, 125, 139)" } : null;
        if (item)
            return (
                <Tag
                    rounded
                    style={style}
                    severity={item._severity}
                    value={item.label.toUpperCase()}
                    className={classNames("p-px-2", item?.className)}
                />
            );
        return <Tag rounded style={style} value="N/A" className="p-mb-1 p-px-2" />;
    };

    const confirmarExclusao = () => {
        confirmDialog({
            header: "Confirmação",
            message: (
                <p>
                    Confirma a <strong style={{ color: "#D32F2F" }}>EXCLUSÃO</strong> das notificações até a data de{" "}
                    <strong>{dataToStr(data, "dd/MM/yyyy")}</strong>?<br />
                    <b>OBS:</b> Essa ação é irreversível.
                </p>
            ),
            icon: "pi pi-info-circle",
            acceptClassName: "p-button-danger",
            acceptLabel: "Confirmar",
            rejectLabel: "Fechar",
            accept: excluirNotificacoes,
        });
    };

    const excluirNotificacoes = async () => {
        const { status } = await axiosDelete(`/pessoas/notificacoes/?data=${dataToStr(data, "yyyy-MM-dd")}`);
        if (status === 200) {
            setData(null);
            await listarNotificacoes();
        } else {
            showError({
                summary: "Erro :(",
                detail: "Desculpe, não conseguimos excluir as notificações.",
                life: 3000,
            });
        }
    };

    const itemTemplate = (item) => {
        const tipoChoice = TIPO_NOTIFICACAO_CHOICE.find((e) => e.value === item.tipo);
        const status = STATUS_NOTIFICACAO_CHOICE.find((e) => e.value === item.status);
        const statusHtml = tagTemplate(status);
        const nova = !item.lida ? tagTemplate({ label: "Nova", _severity: "success", className: "p-py-0" }) : null;
        return (
            <div className="mako-notificacoes">
                <div className="mako-notificacoes-content">
                    <Tag
                        className="content-tag p-mr-2"
                        icon="pi pi-tag product-category-icon"
                        value={tipoChoice.label}
                    />
                    {nova}
                    <h4 className="content-descricao">{item.descricao}</h4>
                    <p className="content-info">{`${dataToStr(
                        item.datahora_criacao,
                        "dd/MM/yyyy hh:mm"
                    )} - Gerada por ${item.emitente.usuario.username}`}</p>
                    {statusHtml}
                </div>
                <div className="mako-notificacoes-actions">
                    <Button
                        icon="pi pi-eye"
                        label="Detalhes"
                        severity="help"
                        onClick={() => modalNotificacoesRef.current?.abrirModal(item)}
                    />
                    <BotaoEscalarNotificacao notificacao={item} />
                    <BotaoReprovarNotificacao notificacao={item} />
                    <BotaoAprovarNotificacao notificacao={item} />
                </div>
            </div>
        );
    };

    return (
        <Dialog
            header="Notificações"
            visible={visible}
            onHide={fecharModal}
            style={{ width: "60vw" }}
            breakpoints={{ "960px": "90vw" }}
        >
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12 p-md-3">
                    <label>Excluir notificações</label>
                    <div className="p-inputgroup mako-notificicao-input-date">
                        <MakoCalendar maxDate={new Date()} valueCalendar={data} onChange={(e) => setData(e.value)} />
                        <Button icon="pi pi-check" disabled={!data} severity="danger" onClick={confirmarExclusao} />
                    </div>
                </div>
            </div>
            <BlockUI loading={loading}>
                <div className="p-grid list-demo">
                    <DataView value={notificacoes} itemTemplate={itemTemplate} emptyMessage="Sem notificações" />
                </div>
            </BlockUI>
            <ModalNotificacoes ref={modalNotificacoesRef} />
            <ConfirmDialog />
        </Dialog>
    );
};

export const ModalListaNotificacoes = forwardRef(Modal);
