import React, { useRef } from "react";

import { Button } from "primereact/button";
import { useHistory } from "react-router-dom";
import { Toast } from "primereact/toast";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";

import permissoes from "@/assets/constants/permissoes";
import MakoListagem from "@/components/MakoListagem";
import { BotaoDelete } from "@/components/BotaoDelete";

export const OperacoesFiscaisServicoPage = () => {
    const history = useHistory();
    const toastRef = useRef(null);
    const listagemRef = useRef(null);

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <MakoControleAcesso
                    permissao={[permissoes.FISCAL_CADASTRO_OPERACAOFISCALSERVICO_EDITAR]}
                    componente={Button}
                    icon="pi pi-pencil"
                    className="p-button-rounded p-button-warning p-mr-2 p-mb-1"
                    tooltip="Alterar cadastro de operação fiscal"
                    tooltipOptions={{ position: "left" }}
                    onClick={() =>
                        history.push({
                            pathname: "/fiscal/cadastros/operacoes-fiscais-servicos/form",
                            opFiscal: rowData,
                        })
                    }
                />
                <MakoControleAcesso
                    permissao={[permissoes.FISCAL_CADASTRO_OPERACAOFISCALSERVICO_CLONAR]}
                    componente={Button}
                    icon="pi pi-clone"
                    className="p-button-rounded p-button-help p-mr-2 p-mb-1"
                    tooltip="Clonar operação fiscal"
                    tooltipOptions={{ position: "left" }}
                    disabled
                    // onClick={() => mostrarClonarDialog(rowData)}
                />
                <MakoControleAcesso
                    permissao={[permissoes.FISCAL_CADASTRO_OPERACAOFISCALSERVICO_EXCLUIR]}
                    componente={BotaoDelete}
                    url="/servicos/operacoes-fiscais-servicos/"
                    objetoId={rowData.id}
                    exigeConfirmacao
                    msgConfirmacao={
                        <span>
                            Deseja realmente excluir a operação de serviço <b>{rowData.descricao}</b>?
                        </span>
                    }
                    classNames="p-mb-1"
                    tooltip="Deletar operação"
                    tooltipOptions={{ position: "left" }}
                    msgToastErroExclusao="A operação fiscal de serviço não pode ser excluída."
                    onDelete={() => listagemRef.current?.buscarDados()}
                />
            </div>
        );
    };

    const cabecalhoTabela = (
        <>
            <MakoControleAcesso
                permissao={[permissoes.FISCAL_CADASTRO_OPERACAOFISCALSERVICO_INCLUIR]}
                componente={Button}
                label="Novo"
                icon="pi pi-plus"
                className="p-button-success p-mr-2"
                onClick={() => history.push("/fiscal/cadastros/operacoes-fiscais-servicos/form")}
            />
        </>
    );

    const colunas = [
        {
            field: "id",
            header: "Código",
            style: { minWidth: "50px" },
        },
        { field: "descricao", header: "Descrição", style: { minWidth: "350px" } },
        {
            field: "vigencia_inicial",
            header: "Início",
            style: { minWidth: "120px" },
        },
        {
            field: "vigencia_final",
            header: "Fim",
            style: { minWidth: "120px" },
        },
        {
            field: "action",
            header: "Ações",
            action: (e) => actionBodyTemplate(e),
            style: { minWidth: "150px" },
        },
    ];

    return (
        <div className="p-grid">
            <Toast ref={toastRef} />
            <div className="p-col-12">
                <div className="card">
                    <MakoListagem
                        ref={listagemRef}
                        titulo="Operações Fiscais de Serviços"
                        colunas={colunas}
                        painelEsquerdo={cabecalhoTabela}
                        urlPesquisa={`/servicos/operacoes-fiscais-servicos/`}
                        responsiva
                        filtarPorEmpresa
                        naoBuscarSemEmpresa
                        configTabela={{
                            paginator: true,
                            lazy: true,
                            scrollable: true,
                        }}
                    />
                </div>
            </div>
        </div>
    );
};
