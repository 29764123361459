import React, { useCallback } from "react";
import { Dropdown } from "primereact/dropdown";
import { axiosPatch } from "@/services/http";
import useFormatCNPJCPF from "@/hooks/useFomatCNPJCPF";
import useAuth from "@/hooks/useAuth";
import useToast from "@/hooks/useToast";
import useAxiosPrivate from "@/hooks/useAxiosPrivate";
import { useQuery, QueryClient, QueryClientProvider } from "react-query";
import useEmpresa from "@/hooks/useEmpresa";

const queryClient = new QueryClient();

function Componente() {
    const [formatarDocumento] = useFormatCNPJCPF();
    const { user, reloadPermissions } = useAuth();
    const { showError } = useToast();
    const { axiosGet } = useAxiosPrivate();
    const { empresaSelecionadaId, alterarEmpresaSelecionada } = useEmpresa();

    const alterarEmpresaPadrao = useCallback(
        async (empresaPadraoId) => {
            if (user?.usuario_empresapadrao && empresaPadraoId) {
                const body = {
                    empresa_padrao: empresaPadraoId,
                };
                const { id } = user.usuario_empresapadrao;
                const { status } = await axiosPatch(`/pessoas/usuarios-empresa-padrao/${id}/`, body);
                if (status !== 200) {
                    showError({
                        summary: "Erro :(",
                        detail: "Desculpe, não foi possível alterar a empresa principal.",
                        life: 3000,
                    });
                }
            }
        },
        [showError, user]
    );

    const { isLoading, data } = useQuery({
        queryKey: ["something"],
        queryFn: async () => {
            const { status, data } = await axiosGet("/pessoas/grupos-perfis?limit=100");
            if (status === 200) {
                let empresas = [];
                await data.results.forEach(async ({ perfil }) => {
                    const { status, data } = await axiosGet(
                        `/pessoas/permissoes-perfis-vigentes?empresa=${perfil.id}&perfil=${user?.id}`
                    );
                    if (status === 200 && data?.length > 0)
                        empresas.push({
                            ...perfil,
                            label: perfil.nome_curto ? perfil.nome_curto : perfil.nome,
                        });
                });
                return empresas;
            }
        },
        isStale: true,
        staleTime: Infinity,
        cacheTime: Infinity,
    });

    const empresaOpcaoTemplate = (option) => {
        return <span>{`${option.label} - ${formatarDocumento(option.identificacao)}`}</span>;
    };

    const empresaSelecionadaTemplate = (option, props) => {
        if (option) return empresaOpcaoTemplate(option);
        return <span>{props.placeholder}</span>;
    };

    const onChangeEmpresa = async (value) => {
        if (value) {
            const empresaFull = data?.find((el) => el.id === value);
            alterarEmpresaSelecionada({ id: value, empresa: empresaFull });
            await reloadPermissions(value);
            await alterarEmpresaPadrao(value);
        } else alterarEmpresaSelecionada({ id: null, empresa: null });
    };

    return (
        <Dropdown
            value={empresaSelecionadaId}
            options={!isLoading ? data : []}
            optionValue="id"
            filter
            filterBy="nome,identificacao"
            showClear
            placeholder={isLoading ? "Carregando..." : "Selecione uma empresa"}
            disabled={isLoading}
            emptyMessage="Nenhum registro disponível"
            emptyFilterMessage="Nenhum registro encontrado"
            valueTemplate={empresaSelecionadaTemplate}
            itemTemplate={empresaOpcaoTemplate}
            onChange={(e) => onChangeEmpresa(e.value)}
        />
    );
}

export const MakoUsuarioEmpresas = () => {
    return (
        <QueryClientProvider client={queryClient} contextSharing>
            <Componente />
        </QueryClientProvider>
    );
};
