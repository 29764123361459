import React, { useState, useEffect, useCallback, useRef, useMemo, memo } from "react";

import { differenceInDays } from "date-fns";

import { ConfirmDialog } from "primereact/confirmdialog";
import { Divider } from "primereact/divider";
import { Panel } from "primereact/panel";

import { MakoButton as Button } from "@/components/MakoButton";
import { MakoActionsButtons } from "@/components/MakoActionsButtons";
import MakoListagem from "@/components/MakoListagem";

import { ModalParcela } from "./modals";
import { Sessoes } from "./sessoes";
import { Buttons } from "./buttons";

import {
    FINANCEIRO_RECEBIMENTO_ACRESCIMO_CARENCIA,
    FINANCEIRO_RECEBIMENTO_ACRESCIMO_PERCENTUAL,
    FINANCEIRO_RECEBIMENTO_ACRESCIMO_DESCONTO_PERCENTUAL_MAX,
    FINANCEIRO_RECEBIMENTO_ANTECIPACAO_PERCENTUAL,
    FINANCEIRO_RECEBIMENTO_ANTECIPACAO_DIAS,
    FINANCEIRO_RECEBIMENTO_DESCONTO_PERCENTUAL,
    FINANCEIRO_RECEBIMENTO_MULTA_PERCENTUAL,
    FINANCEIRO_RECEBIMENTO_MULTA_DESCONTO_PERCENTUAL_MAX,
    FINANCEIRO_MOVIMENTACAO_CAIXA,
} from "@/assets/constants/parametros";
import { formatarCasasDecimais } from "@/assets/util/util";
import { parseData, dataToStr } from "@/assets/util/datas";
import { axiosPost } from "@/services/http";

import useCaixaMovimento from "@/hooks/useCaixaMovimento";
import useLoadingLocal from "@/hooks/useLoadingLocal";
import useClearRefs from "@/hooks/useClearRefs";
import useEmpresa from "@/hooks/useEmpresa";
import useParam from "@/hooks/useParam";
import useToast from "@/hooks/useToast";

const { Recibo: ButtonRecibo } = Buttons;

const MemoButtonRecibo = memo(ButtonRecibo);

const numeroParcelaBodyTemplate = (rowData) => {
    if (rowData.numero_parcela === 0) return <span>ENTRADA</span>;
    return <span>{`${rowData.numero_parcela}/${rowData.quantidade_parcelas}`}</span>;
};

export const Parcelas = ({ recebimentos = [], successCallback = () => {}, cancelCallback = () => {} }) => {
    const [templateRateioSelecionado, setTemplateRateioSelecionado] = useState(null);
    const [permitirRecebimentos, setPermitirRecebimentos] = useState(true);
    const [permitirRecibo, setPermitirRecibo] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [parcelas, setParcelas] = useState([]);
    const [confirm, setConfirm] = useState(false);

    const { showSuccess, showWarning, showError } = useToast();
    const [loading, showLoading, hideLoading] = useLoadingLocal();
    const { caixaMov: caixa } = useCaixaMovimento();
    const { empresaSelecionadaId } = useEmpresa();
    const { getParam } = useParam();

    const sacadoFormRef = useRef();
    const rateioFormRef = useRef();
    const modalRef = useRef(null);

    const cachedSacadoDados = useRef({ data: null, competencia: null });
    const cachedRateio = useRef(null);

    useClearRefs(sacadoFormRef, rateioFormRef, modalRef, cachedSacadoDados, cachedRateio);

    async function registrarRecebimentos() {
        setSubmitted(true);
        const competencia = sacadoFormRef.current?.valueCompetencia || cachedSacadoDados.current.competencia,
            dataRecebimento = sacadoFormRef.current?.valueDataRecebimento || cachedSacadoDados.current.data,
            setDataRecebimento = sacadoFormRef.current?.setDataRecebimento;

        if (typeof dataRecebimento === "string") {
            showWarning({
                summary: "Aviso",
                detail: "A data de recebimento não é válida.",
                life: 3000,
            });
            setDataRecebimento(null);
        } else if (dataRecebimento) {
            const recebimentos = parcelas.map((parcela) => {
                const formas_recebimento = parcela.forma_recebimento.map((fr) => ({
                    forma_recebimento: fr.id,
                    valor: formatarCasasDecimais(fr.valor),
                    conta_financeira: fr.conta_financeira,
                }));

                const resultadoRateio = rateioFormRef.current?.resultadoRateio || cachedRateio.current;

                let _recebimento = {
                    id: parcela.id,
                    caixa: caixa ? caixa.id : null,
                    documento: parcela.documento,
                    vencimento: parcela.vencimento,
                    data_recebimento: dataToStr(dataRecebimento, "yyyy-MM-dd"),
                    conta_financeira: parcela.conta_financeira,
                    template_rateio:
                        resultadoRateio?.length > 0 ? templateRateioSelecionado : parcela.template_rateio.id,
                    quantidade_parcelas: parcela.quantidade_parcelas,
                    numero_parcela: parcela.numero_parcela,
                    valor: formatarCasasDecimais(parcela.valor),
                    em_aberto: formatarCasasDecimais(parcela.valor),
                    multa: formatarCasasDecimais(parcela.multa),
                    juros: formatarCasasDecimais(parcela.juros),
                    descontos: formatarCasasDecimais(parcela.descontos),
                    formas_recebimento,
                    recebido: formatarCasasDecimais(parcela.recebido),
                };

                if (competencia) _recebimento.competencia = competencia;
                return _recebimento;
            });

            showLoading();
            const { status, data } = await axiosPost("/financeiro/registrar-recebimentos/", { recebimentos });
            hideLoading();

            if (status === 200) {
                showSuccess({
                    summary: "Sucesso",
                    detail: "Recebimentos registrados com sucesso!",
                    life: 1500,
                });
                setSubmitted(false);
                setPermitirRecibo(true);
                successCallback();
            } else if (status === 400 && !data.err) {
                showWarning({
                    summary: "Aviso",
                    detail: data.msg,
                    life: 3000,
                });
            } else {
                showError({
                    summary: "Erro :(",
                    detail: "Desculpe, não foi possível registrar os recebimentos!",
                    life: 3000,
                });
            }
        }
    }

    const calcularRecebimentos = useCallback(() => {
        if (recebimentos) {
            const _recebimentos = recebimentos.map((recebimento) => {
                const { empresa, valor, dias_atraso } = recebimento;
                const percentJuros =
                    parseFloat(getParam(FINANCEIRO_RECEBIMENTO_ACRESCIMO_PERCENTUAL, empresa.id)?.valor) || 0;
                const carenciaJuros =
                    parseInt(getParam(FINANCEIRO_RECEBIMENTO_ACRESCIMO_CARENCIA, empresa.id)?.valor) || 0;
                const carenciaAntecipacao =
                    parseInt(getParam(FINANCEIRO_RECEBIMENTO_ANTECIPACAO_DIAS, empresa.id)?.valor) || 0;
                const percentDescontos =
                    parseFloat(getParam(FINANCEIRO_RECEBIMENTO_DESCONTO_PERCENTUAL, empresa.id)?.valor) || 0;
                const percentAntecipacao =
                    parseFloat(getParam(FINANCEIRO_RECEBIMENTO_ANTECIPACAO_PERCENTUAL, empresa.id)?.valor) || 0;
                const percentMulta =
                    parseFloat(getParam(FINANCEIRO_RECEBIMENTO_MULTA_PERCENTUAL, empresa.id)?.valor) || 0;
                const percentDescJuros =
                    parseFloat(getParam(FINANCEIRO_RECEBIMENTO_ACRESCIMO_DESCONTO_PERCENTUAL_MAX, empresa.id)?.valor) ||
                    0;
                const percentDescMulta =
                    parseFloat(getParam(FINANCEIRO_RECEBIMENTO_MULTA_DESCONTO_PERCENTUAL_MAX, empresa.id)?.valor) || 0;
                const saldo = parseFloat(valor);
                const descontos =
                    dias_atraso < 0 && carenciaAntecipacao > dias_atraso ? (saldo * percentAntecipacao) / 100 : 0;
                const recebido = formatarCasasDecimais(saldo + recebimento.multa + recebimento.juros - descontos);

                const formaValida =
                    ![0, 9].includes(recebimento.forma_recebimento?.tipo?.id) && !!recebimento.conta_financeira;

                return {
                    ...recebimento,
                    forma_recebimento: formaValida
                        ? [
                              {
                                  ...recebimento.forma_recebimento,
                                  valor: recebido,
                                  conta_financeira: recebimento.conta_financeira,
                              },
                          ]
                        : [],
                    percent_juros: percentJuros,
                    percent_juros_desc_max: percentDescJuros,
                    carencia_juros: carenciaJuros,
                    carencia_antecipacao: carenciaAntecipacao,
                    percent_antecipacao: percentAntecipacao,
                    percent_descontos: percentDescontos,
                    percent_multa: percentMulta,
                    percent_multa_desc_max: percentDescMulta,
                    dias: dias_atraso,
                    valor: saldo,
                    descontos,
                    recebido,
                };
            });
            setParcelas(_recebimentos);
            setTemplateRateioSelecionado(_recebimentos[0].template_rateio.id);
        }
    }, [recebimentos, getParam]);

    const habilitarRecebimentos = useCallback(() => {
        if (!empresaSelecionadaId) {
            setPermitirRecebimentos(false);
            showWarning({
                summary: "Aviso!",
                detail: "Você não possui uma empresa selecionada.",
                life: 3000,
            });
            return null;
        }
        const param = getParam(FINANCEIRO_MOVIMENTACAO_CAIXA, empresaSelecionadaId);
        if (param && param.valor && caixa && caixa.bloqueado) {
            setPermitirRecebimentos(false);
            showWarning({
                summary: "Oops!",
                detail: "No momento você não pode efetuar recebimentos pois seu caixa está bloqueado.",
                life: 5000,
            });
        }
    }, [caixa, empresaSelecionadaId, getParam, showWarning]);

    const editarRecebimento = (novoRecebimento) => {
        const index = parcelas?.findIndex((p) => p.id === novoRecebimento.id);
        let _parcelas = [...parcelas];
        _parcelas[index] = novoRecebimento;
        if (permitirRegistrar) setConfirm(true);
        setParcelas(_parcelas);
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button
                    icon="pi pi-dollar"
                    className="p-button-rounded p-button-success p-mr-2"
                    onClick={() => modalRef.current?.show(rowData)}
                    disabled={permitirRecibo}
                />
                <MemoButtonRecibo recebimentoId={rowData?.id} disabled={!rowData?._edited || !permitirRecibo} />
            </div>
        );
    };

    const colunas = [
        { field: "documento", header: "Documento" },
        {
            field: "numero_parcela",
            header: "Parcela",
            style: { width: "8%" },
            action: (e) => numeroParcelaBodyTemplate(e),
        },
        { field: "data_emissao", header: "Emissão", style: { width: "9%" }, dateFormat: "dd/MM/yyyy" },
        { field: "vencimento", header: "Vencimento", style: { width: "9%" }, dateFormat: "dd/MM/yyyy" },
        { field: "dias", header: "Dias", style: { width: "5%" } },
        { field: "valor", header: "Valor", money: true },
        { field: "descontos", header: "Descontos", money: true },
        { field: "multa", header: "Multa", money: true },
        { field: "juros", header: "Juros", money: true },
        { field: "recebido", header: "Recebido", money: true },
        {
            field: "actions",
            header: "Ações",
            style: { width: "10%" },
            action: (e) => actionBodyTemplate(e),
        },
    ];

    const rowClass = (rowData) => {
        const data = sacadoFormRef.current?.valueDataRecebimento;
        return {
            "p-text-bold": rowData?._edited,
            "table-recebimentos-overdue":
                !rowData?._edited && differenceInDays(data, parseData(rowData.vencimento)) > 0,
            "table-recebimentos-pending":
                !rowData?._edited && differenceInDays(data, parseData(rowData.vencimento)) < 0,
        };
    };

    const onCollapseSacado = () => {
        cachedSacadoDados.current = {
            data: sacadoFormRef.current?.valueDataRecebimento,
            competencia: sacadoFormRef.current?.competencia,
        };
    };

    const onCollapseRateio = () => {
        cachedRateio.current = rateioFormRef.current?.resultadoRateio;
    };

    const permitirRegistrar = useMemo(() => {
        return parcelas?.length === 1;
    }, [parcelas]);

    const existeFormaRecebimento = useMemo(() => {
        return parcelas?.some((item) => item.forma_recebimento.some((f) => f?.id));
    }, [parcelas]);

    useEffect(() => {
        calcularRecebimentos();
    }, [calcularRecebimentos]);

    useEffect(() => {
        habilitarRecebimentos();
    }, [habilitarRecebimentos]);

    return (
        <>
            <ConfirmDialog
                visible={confirm}
                header="Confirmação"
                message="Deseja ja registrar o recebimento?"
                icon="pi pi-exclamation-triangle"
                acceptLabel="Sim"
                rejectClassName="p-button-danger p-button-text"
                acceptClassName="p-button-success p-button"
                rejectLabel="Cancelar"
                accept={registrarRecebimentos}
                onHide={() => setConfirm(false)}
            />
            <ModalParcela ref={modalRef} onConfirmar={(e) => editarRecebimento(e)} />
            <Panel header="Sacado" toggleable onCollapse={onCollapseSacado}>
                <Sessoes.Sacado
                    ref={sacadoFormRef}
                    devedor={recebimentos[0]?.devedor}
                    competencia={parcelas.at(0)?.competencia}
                    submitted={submitted}
                />
            </Panel>
            <Panel header="Resultado do rateio" toggleable className="p-mt-3" onCollapse={onCollapseRateio}>
                <Sessoes.Rateio ref={rateioFormRef} parcelas={parcelas} />
            </Panel>
            <MakoListagem
                titulo="Parcelas a receber"
                colunas={colunas}
                dadosLocal={parcelas}
                configTabela={{
                    paginator: true,
                    rowClassName: rowClass,
                }}
            />
            <Divider align="center">
                <b>Totais</b>
            </Divider>
            <Sessoes.Totais parcelas={parcelas} />
            <MakoActionsButtons>
                <Button
                    type="button"
                    loading={loading}
                    label="Registrar recebimentos"
                    icon="pi pi-dollar"
                    disabled={(permitirRecebimentos && !existeFormaRecebimento) || permitirRecibo}
                    onClick={registrarRecebimentos}
                />
                <Button
                    loading={loading}
                    label="Cancelar"
                    onClick={cancelCallback}
                    icon="pi pi-times"
                    className="p-button-danger"
                />
            </MakoActionsButtons>
        </>
    );
};
