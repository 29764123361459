import React, { useEffect, useRef } from "react";
import classNames from "classnames";
import { useHistory } from "react-router-dom";
import { InputText } from "primereact/inputtext";
import { RadioButton } from "primereact/radiobutton";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { Tooltip } from "primereact/tooltip";
import { useFormik } from "formik";
import * as Yup from "yup";

import { MakoCalendar } from "@/components/MakoCalendar";
import { axiosPost, axiosPut } from "@/services/http";
import { dataToStr, parseData } from "@/assets/util/datas";
import useLoading from "@/hooks/useLoading";

export const VigenciaPrecoForm = (props) => {
    const toastRef = useRef(null);
    const history = useHistory();
    const { showLoading, hideLoading } = useLoading();

    const { setValues, ...formik } = useFormik({
        initialValues: {
            descricao: "",
            nivel: 1,
            data_inicio: new Date(),
            data_fim: null,
        },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                descricao: Yup.string().required("O campo 'descrição' é obrigatório."),
                nivel: Yup.number().required("Escolha uma opção de nível."),
                data_inicio: Yup.date()
                    .typeError("Informe uma data válida.")
                    .required("O campo 'início da vigência' é obrigatório."),
                data_fim: Yup.date()
                    .nullable()
                    .min(formik.values.data_inicio, "O vigência final não pode ser menor que a inicial."),
            });

            await formSchema.validate(values, {
                abortEarly: false,
            });

            const vigencia = {
                ...values,
                data_inicio: dataToStr(values.data_inicio, "yyyy-MM-dd"),
                data_fim: values.data_fim ? dataToStr(values.data_fim, "yyyy-MM-dd") : null,
            };

            if (!values.id) {
                showLoading();
                const resposta = await axiosPost("/produtos/precos-vigencia/", vigencia);
                hideLoading();

                if (resposta.status === 201) {
                    toastRef.current.show({
                        severity: "success",
                        summary: "Sucesso!",
                        detail: "Vigência de preço cadastrada com sucesso.",
                        life: 1500,
                    });

                    setTimeout(() => {
                        history.push("/vendas/cadastros/vigencia-preco");
                    }, 1500);
                } else {
                    toastRef.current.show({
                        severity: "error",
                        summary: "Erro!",
                        detail: "Desculpe, não conseguimos processar sua requisição.",
                        life: 3000,
                    });
                }
            } else {
                showLoading();
                const resposta = await axiosPut(`/produtos/precos-vigencia/${vigencia.id}/`, vigencia);
                hideLoading();

                if (resposta.status === 200) {
                    toastRef.current.show({
                        severity: "success",
                        summary: "Sucesso!",
                        detail: "Vigência de preço alterada com sucesso.",
                        life: 1500,
                    });

                    setTimeout(() => {
                        history.push("/vendas/cadastros/vigencia-preco");
                    }, 1500);
                } else {
                    toastRef.current.show({
                        severity: "error",
                        summary: "Erro!",
                        detail: "Desculpe, não conseguimos processar sua requisição.",
                        life: 3000,
                    });
                }
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};

                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });

                formik.setErrors(errorMessages);
            }
        }
    }

    useEffect(() => {
        if (props.location.state) {
            setValues({
                ...props.location.state,
                data_inicio: parseData(props.location.state.data_inicio),
                data_fim: parseData(props.location.state.data_fim),
            });
        }
    }, [props.location.state, setValues]);

    return (
        <div className="p-grid">
            <div className="p-col-12">
                <div className="card">
                    <h3>{!formik.values.id ? "Nova vigência de preço" : "Manutenção de vigência de preço"}</h3>
                    <form onSubmit={formik.handleSubmit}>
                        <Toast ref={toastRef} />
                        <div className="p-fluid p-formgrid p-grid">
                            <div className="p-field p-col-12">
                                <label htmlFor="descricao">Descrição *</label>
                                <InputText
                                    id="descricao"
                                    name="descricao"
                                    value={formik.values.descricao}
                                    onChange={formik.handleChange}
                                    className={classNames({ "p-invalid": formik.errors.descricao })}
                                />
                                {formik.errors.descricao && (
                                    <small className="p-error">{formik.errors.descricao}</small>
                                )}
                            </div>
                        </div>
                        <div className="p-fluid p-formgrid p-grid">
                            <div className="p-field p-col-12 p-md-4">
                                <Tooltip target=".custom-tooltip-lbl">
                                    <span>Tooltip de orientação de nivel de preço</span>
                                </Tooltip>

                                <label className="custom-tooltip-lbl">Prioridade da vigência *</label>
                                <div className="p-formgroup-inline">
                                    <div className="p-field-radiobutton">
                                        <RadioButton
                                            inputId="nivel-um"
                                            name="nivel"
                                            value={1}
                                            onChange={formik.handleChange}
                                            checked={formik.values.nivel === 1}
                                        />
                                        <label htmlFor="nivel-um">Baixa</label>
                                    </div>
                                    <div className="p-field-radiobutton">
                                        <RadioButton
                                            inputId="nivel-dois"
                                            name="nivel"
                                            value={2}
                                            onChange={formik.handleChange}
                                            checked={formik.values.nivel === 2}
                                        />
                                        <label htmlFor="nivel-dois">Média</label>
                                    </div>
                                    <div className="p-field-radiobutton">
                                        <RadioButton
                                            inputId="nivel-tres"
                                            name="nivel"
                                            value={3}
                                            onChange={formik.handleChange}
                                            checked={formik.values.nivel === 3}
                                        />
                                        <label htmlFor="nivel-tres">Alta</label>
                                    </div>
                                </div>
                                {formik.errors.nivel && <small className="p-error">{formik.errors.nivel}</small>}
                            </div>
                            <div className="p-field p-col-12 p-md-4">
                                <label htmlFor="data-inicio">Início da vigência *</label>
                                <MakoCalendar
                                    id="data-inicio"
                                    name="data_inicio"
                                    value={formik.values.data_inicio}
                                    onChange={formik.handleChange}
                                    className={classNames({ "p-invalid": formik.errors.data_inicio })}
                                />
                                {formik.errors.data_inicio && (
                                    <small className="p-error">{formik.errors.data_inicio}</small>
                                )}
                            </div>
                            <div className="p-field p-col-12 p-md-4">
                                <label htmlFor="data-fim">Final da vigência</label>
                                <MakoCalendar
                                    id="data-fim"
                                    name="data_fim"
                                    value={formik.values.data_fim}
                                    onChange={formik.handleChange}
                                    className={classNames({ "p-invalid": formik.errors.data_fim })}
                                />
                                {formik.errors.data_fim && <small className="p-error">{formik.errors.data_fim}</small>}
                            </div>
                        </div>
                        <p>* Campos obrigatórios</p>
                        <div className="p-grid">
                            <div className="p-col-12 p-md-6">
                                <Button type="submit" icon="pi pi-check" label="Gravar" className="p-mr-2 p-mb-2" />
                                <Button
                                    type="button"
                                    icon="pi pi-angle-double-left"
                                    label="Voltar"
                                    className="p-button-danger p-mr-2 p-mb-2"
                                    onClick={() => history.push("/vendas/cadastros/vigencia-preco")}
                                />
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};
