import React, { useState, useEffect, useCallback } from "react";

import { compareAsc, parse } from "date-fns";
import { useFormik } from "formik";
import classNames from "classnames";
import * as Yup from "yup";
import axios from "axios";

import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";

import { MakoInputMoeda } from "@/components/MakoInputMoeda";
import { MakoCalendar } from "@/components/MakoCalendar";

import useContratoServico from "@/hooks/useContratoServico";
import useLoading from "@/hooks/useLoading";
import useToast from "@/hooks/useToast";

import { axiosGet, axiosPatch } from "@/services/http";
import { parseData } from "@/assets/util/datas";
import { dataToStr } from "@/assets/util/datas";

export const ModalEditarParcela = ({ parcela, recebimento = true, onFinish, onClose }) => {
    const [parcelas, setParcelas] = useState([]);

    const { hideLoading, showLoading } = useLoading();
    const { showSuccess, showError } = useToast();
    const { contrato } = useContratoServico();

    const formik = useFormik({
        initialValues: parcela,
        onSubmit: handleSubmit,
    });

    const validarVencimento = (data, idIndex) => {
        const parcelasOrdenadas = parcelas.sort((a, b) => compareAsc(parseData(a.vencimento), parseData(b.vencimento)));
        const rowIndex = parcelasOrdenadas?.findIndex(({ id }) => id === idIndex);
        if (rowIndex === 0) {
            const prox = parcelas[rowIndex + 1].vencimento;
            const proxVencimento = prox instanceof Date ? prox : parseData(prox);
            return compareAsc(proxVencimento, data) > 0;
        } else if (rowIndex === parcelas.length - 1) {
            const ant = parcelas[rowIndex - 1].vencimento;
            const antVencimento = ant instanceof Date ? ant : parseData(ant);
            return compareAsc(data, antVencimento) > 0;
        } else {
            const prox = parcelas[rowIndex + 1].vencimento;
            const proxVencimento = prox instanceof Date ? prox : parseData(prox);
            const ant = parcelas[rowIndex - 1].vencimento;
            const antVencimento = ant instanceof Date ? ant : parseData(ant);
            return compareAsc(data, antVencimento) > 0 && compareAsc(proxVencimento, data) > 0;
        }
    };

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                vencimento: Yup.date()
                    .required("O campo 'vencimento' é obrigatório.")
                    .test({
                        test: (e) => validarVencimento(e, values.id),
                        message: "A data de vencimento não é válida para esse recebimento.",
                    })
                    .typeError("Informe uma data válida."),
                valor: Yup.number()
                    .required("O campo 'valor' é obrigatório.")
                    .min(0.01, "Esse campo não pode ser ZERO."),
                historico_original: Yup.string().required("O campo 'histórico' é obrigatório."),
            });
            const dadosValidos = formSchema.validateSync(values, { abortEarly: false });
            showLoading();
            const { status } = await axiosPatch(
                `/financeiro/${recebimento ? "recebimentos" : "pagamentos"}/${dadosValidos.id}/`,
                {
                    ...dadosValidos,
                    vencimento: dataToStr(dadosValidos.vencimento, "yyyy-MM-dd"),
                }
            );
            hideLoading();
            if (status === 200) {
                showSuccess({
                    summary: "Sucesso",
                    detail: `${recebimento ? "Rebimento" : "Pagamento"} alterado com sucesso!`,
                    life: 1500,
                });
                onFinish();
            } else {
                showError({
                    summary: "Erro :(",
                    detail: "Desculpe, não foi possível processar a sua requisição.",
                    life: 3000,
                });
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                const errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            }
        }
    }

    const listarRecebimentos = useCallback(
        async (cancelToken) => {
            showLoading();
            const { status, data } = await axiosGet(
                `/financeiro/${
                    recebimento ? "recebimentos" : "pagamentos"
                }/?cancelado=False&faturamento_contrato__contrato_id=${contrato.id}&${
                    recebimento
                        ? `agrupador=${parcela.agrupador}`
                        : `documento=${parcela.documento}&credor=${parcela.credor}`
                }`,
                { cancelToken }
            );
            hideLoading();
            if (status === 200) setParcelas(data.results);
        },
        [showLoading, hideLoading, parcela, recebimento, contrato]
    );

    useEffect(() => {
        const { token, ...cancelToken } = axios.CancelToken.source();
        listarRecebimentos(token);
        return function clear() {
            cancelToken.cancel();
        };
    }, [listarRecebimentos]);

    return (
        <form onSubmit={formik.handleSubmit}>
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12 p-md-3">
                    <label htmlFor="vencimento">Vencimento</label>
                    <MakoCalendar
                        id="vencimento"
                        name="vencimento"
                        valueCalendar={formik.values.vencimento}
                        onChange={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.vencimento })}
                    />
                    {formik.errors.vencimento && <small className="p-error">{formik.errors.vencimento}</small>}
                </div>
                <div className="p-field p-col-12 p-md-2">
                    <label htmlFor="valor">Valor</label>
                    <MakoInputMoeda
                        id="valor"
                        name="valor"
                        min={0}
                        valueMoeda={formik.values.valor}
                        onChangeMoeda={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.valor })}
                    />
                    {formik.errors.valor && <small className="p-error">{formik.errors.valor}</small>}
                </div>
                <div className="p-field p-col-12 p-md-7">
                    <label htmlFor="historico-original">Histórico</label>
                    <InputText
                        id="historico-original"
                        name="historico_original"
                        value={formik.values.historico_original}
                        onChange={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.historico_original })}
                    />
                    {formik.errors.historico_original && (
                        <small className="p-error">{formik.errors.historico_original}</small>
                    )}
                </div>
            </div>
            <div className="p-grid p-col-12 p-md-6">
                <Button icon="pi pi-save" label="Gravar" type="submit" className="p-button-info p-mr-2" />
                <Button label="Cancelar" type="button" className="p-button-danger" onClick={onClose} />
            </div>
        </form>
    );
};
