import React, { useCallback, useState } from "react";

import { MakoButtonAtivar } from "@/components/MakoButtonAtivar";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";

import { axiosPatch } from "@/services/http";
import permissoes from "@/assets/constants/permissoes";

import useToast from "@/hooks/useToast";

const BASE_DEVOLUCAO = { id: 0, ativo: true };
const URL_BACKEND = "/compras/tipos-devolucoes-fornecedor";

export const Status = ({ tipoDevolucao = BASE_DEVOLUCAO, onSuccessCallback = () => {} }) => {
    const [loading, setLoading] = useState(false);

    const { showSuccess, showError } = useToast();

    const handleStatus = useCallback(async () => {
        setLoading(true);
        const { id, ativo } = tipoDevolucao;
        const { status } = await axiosPatch(`${URL_BACKEND}/${id}/`, { ativo: !ativo });
        setLoading(false);
        if (status === 200) {
            showSuccess({
                summary: "Sucesso!",
                detail: `${!ativo ? "Ativado" : "Desativado"} com sucesso!`,
                life: 3000,
            });
            onSuccessCallback();
        } else showError();
    }, [onSuccessCallback, showError, showSuccess, tipoDevolucao]);

    return (
        <MakoControleAcesso
            componente={MakoButtonAtivar}
            permissao={[permissoes.COMPRAS_CADASTRO_TIPODEVOLUCAO_ALTERARDESATIVAR]}
            ativo={tipoDevolucao.ativo}
            loading={loading}
            onClick={handleStatus}
        />
    );
};
