import React, { useCallback, useEffect, useMemo } from "react";

import { useHistory, useLocation } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { InputTextarea } from "primereact/inputtextarea";
import { InputText } from "primereact/inputtext";
import { Checkbox } from "primereact/checkbox";
import { Button } from "primereact/button";
import { MakoInputPercent } from "@/components/MakoInputs/MakoInputPercent";
import { MakoInputMoeda } from "@/components/MakoInputMoeda";
import { PageBase } from "@/components/PageBase";
import { Dropdown } from "@/components/Dropdown";
import { Label } from "@/components/Label";
import { TIPOS_CHOICE_SERVICOS_SERVICO } from "@/assets/constants/constants";
import { gerarStringAleatoria } from "@/assets/util/util";
import useLoading from "@/hooks/useLoading";
import useEmpresa from "@/hooks/useEmpresa";
import useToast from "@/hooks/useToast";
import classNames from "classnames";
import useHttp from "@/hooks/useHttp";

export const ServicosForm = () => {
    const { showLoading, hideLoading } = useLoading();
    const { showSuccess, showError } = useToast();
    const { empresaSelecionadaId } = useEmpresa();
    const { state } = useLocation();
    const history = useHistory();
    const { httpPost, httpPut, httpGet } = useHttp();

    const codigoOriginal = state?.servico ? state?.servico.codigo : null;

    const { setValues, setFieldValue, resetForm, ...formik } = useFormik({
        initialValues: {
            empresa: empresaSelecionadaId,
            codigo: "",
            descricao: "",
            custo: 0,
            valor_venda: 0,
            tipo: null,
            terceirizado: false,
            ativo: true,
            comissao_interveniente: 0,
            requer_apontamento: false,
            template: true,
            custo_hora: false,
            detalhes: "",
        },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                codigo: Yup.string()
                    .max(10, "Máximo de caracteres atingido: 10")
                    .test("Validar código unico", "O código informado ja existe", (val) => {
                        return verificarExistenciaCodigo(val);
                    })
                    .required("O campo 'codigo' é obrigatório")
                    .typeError("Informe um 'código' válido"),
                descricao: Yup.string()
                    .max(100, "Máximo de caracteres atingido: 100")
                    .required("O campo 'descrição' é obrigatório")
                    .typeError("Informe um 'descrição' válido"),
                custo: Yup.number().required("O campo 'custo' é obrigatório.").typeError("Informe um 'custo' válido"),
                valor_venda: Yup.number()
                    .required("O campo 'valor venda' é obrigatório.")
                    .typeError("Informe um 'valor venda' válido"),
                tipo: Yup.string()
                    .max(1, "Máximo de caracteres atingido: 1")
                    .required("O campo 'tipo' é obrigatório")
                    .typeError("Informe um 'tipo' válido"),
                detalhes: Yup.string()
                    .max(255, "Máximo de caracteres atingido: 255")
                    .required("O campo 'detalhes' é obrigatório")
                    .typeError("Informe um 'detalhes' válido"),
                comissao_interveniente: Yup.number()
                    .required("O campo 'valor de comissão' é obrigatório.")
                    .typeError("Informe um 'valor de comissão' válido"),
                template_origem: Yup.number().when("template", {
                    is: (val) => !val,
                    then: Yup.number()
                        .required("O campo 'template de origem' é obrigtório")
                        .typeError("Informe uma 'data final' válida"),
                    otherwise: Yup.number().nullable(),
                }),
            });

            await formSchema.validate(values, { abortEarly: false });

            if (!values.id) {
                const handlers = {
                    201: () => {
                        showSuccess({
                            summary: "Sucesso",
                            detail: "Serviço cadastrado com sucesso!",
                            life: 1500,
                        });
                        history.push("/servicos/servicos/servico");
                    },
                };

                showLoading();
                await httpPost(
                    {
                        url: "/servicos/servicos/",
                        body: values,
                    },
                    handlers
                );
                hideLoading();
            } else {
                const handlers = {
                    200: () => {
                        showSuccess({
                            summary: "Sucesso",
                            detail: "Serviço alterado com sucesso!",
                            life: 1500,
                        });
                        history.push("/servicos/servicos/servico");
                    },
                };

                showLoading();
                await httpPut(
                    {
                        url: `/servicos/servicos/${values.id}/`,
                        body: values,
                    },
                    handlers
                );
                hideLoading();
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            } else showError();
        }
    }

    function gerarCodigo() {
        setFieldValue("codigo", gerarStringAleatoria(6));
    }

    const fetchTemplates = useCallback(async (results) => {
        return results.map((item) => ({
            ...item,
            label: `${item.codigo} | ${item.descricao}`,
        }));
    }, []);

    const verificarExistenciaCodigo = useCallback(
        async (codigo) => {
            if (codigoOriginal && codigoOriginal === codigo) return true;
            let existe = false;
            const handlers = {
                200: ({ data }) => {
                    existe = !data.results.length > 0;
                },
            };
            await httpGet(
                {
                    url: `/servicos/servicos/?empresa=${empresaSelecionadaId}&ativo=true&template=true&codigo=${codigo}&limit=1`,
                },
                handlers
            );
            return existe;
        },
        [empresaSelecionadaId, codigoOriginal]
    );

    const onChangeTercerizado = (e) => {
        const { checked } = e.target;
        if (!checked) setFieldValue("comissao_interveniente", 0);
        formik.handleChange(e);
    };

    const tipoValido = useMemo(() => {
        if (formik.values.tipo === "R") {
            setFieldValue("comissao_interveniente", 0);
            setFieldValue("terceirizado", false);
            return false;
        }
        return true;
    }, [formik.values.tipo, setFieldValue]);

    useEffect(() => {
        if (state && state.servico) {
            setValues({ ...state.servico, empresa: state.servico.empresa.id });
        }
    }, [state, setValues]);

    return (
        <PageBase>
            <h5>{formik.values.id ? "Editar Serviço" : "Cadastrar Serviço"}</h5>
            <form onSubmit={formik.handleSubmit}>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="codigo">Código *</label>
                        <div className="p-inputgroup">
                            <InputText
                                id="codigo"
                                name="codigo"
                                onChange={formik.handleChange}
                                value={formik.values.codigo}
                                autoComplete="off"
                                maxLength={12}
                                className={classNames({ "p-invalid": formik.errors.codigo })}
                            />
                            <Button
                                type="button"
                                icon="pi pi-refresh"
                                className="p-button-info"
                                tooltip="Gerar código aleatório"
                                onClick={gerarCodigo}
                            />
                        </div>
                        {formik.errors.codigo && <small className="p-error">{formik.errors.codigo}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-7">
                        <label htmlFor="descricao">Descrição *</label>
                        <InputText
                            id="descricao"
                            name="descricao"
                            onChange={formik.handleChange}
                            value={formik.values.descricao}
                            autoComplete="off"
                            maxLength={100}
                            className={classNames({ "p-invalid": formik.errors.descricao })}
                        />
                        {formik.errors.descricao && <small className="p-error">{formik.errors.descricao}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="tipo">Tipo *</label>
                        <Dropdown
                            id="tipo"
                            name="tipo"
                            options={TIPOS_CHOICE_SERVICOS_SERVICO}
                            optionValue="value"
                            optionLabel="label"
                            onChange={formik.handleChange}
                            showClear={false}
                            value={formik.values.tipo}
                            className={classNames({ "p-invalid": formik.errors.tipo })}
                        />
                        {formik.errors.tipo && <small className="p-error">{formik.errors.tipo}</small>}
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="custo">Custo *</label>
                        <MakoInputMoeda
                            id="custo"
                            name="custo"
                            onChangeMoeda={formik.handleChange}
                            valueMoeda={formik.values.custo}
                            className={classNames({ "p-invalid": formik.errors.custo })}
                        />
                        {formik.errors.custo && <small className="p-error">{formik.errors.custo}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="valor_venda">Preço</label>
                        <MakoInputMoeda
                            id="valor_venda"
                            name="valor_venda"
                            onChangeMoeda={formik.handleChange}
                            valueMoeda={formik.values.valor_venda}
                            className={classNames({ "p-invalid": formik.errors.valor_venda })}
                        />
                        {formik.errors.valor_venda && <small className="p-error">{formik.errors.valor_venda}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="comissao_interveniente">Comissão interveniente</label>
                        <MakoInputPercent
                            id="comissao_interveniente"
                            name="comissao_interveniente"
                            onValueChange={formik.handleChange}
                            value={formik.values.comissao_interveniente}
                            disabled={!formik.values.terceirizado || !tipoValido}
                            className={classNames({ "p-invalid": formik.errors.comissao_interveniente })}
                        />
                        {formik.errors.comissao_interveniente && (
                            <small className="p-error">{formik.errors.comissao_interveniente}</small>
                        )}
                    </div>
                    <div className="p-field-checkbox p-col-12 p-md-2 p-mt-5">
                        <Checkbox
                            id="terceirizado"
                            name="terceirizado"
                            checked={formik.values.terceirizado}
                            disabled={!tipoValido}
                            onChange={onChangeTercerizado}
                        />
                        <label htmlFor="terceirizado">Terceirizado?</label>
                    </div>
                    <div className="p-field-checkbox p-col-12 p-md-2 p-mt-5">
                        <Checkbox
                            id="custo_hora"
                            name="custo_hora"
                            checked={formik.values.custo_hora}
                            onChange={formik.handleChange}
                        />
                        <label htmlFor="custo_hora">Custo por hora?</label>
                    </div>
                    <div className="p-field-checkbox p-col-12 p-md-2 p-mt-5">
                        <Checkbox
                            id="template"
                            name="template"
                            checked={formik.values.template}
                            onChange={formik.handleChange}
                            disabled
                        />
                        <label htmlFor="template">Modelo?</label>
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-9">
                        <Label obrigatorio={!formik.values.template} label="Serviço de origem" />
                        <Dropdown
                            id="template_origem"
                            name="template_origem"
                            disabled={formik.values.template}
                            url={`/servicos/servicos/?empresa=${empresaSelecionadaId}&ativo=true&template=true`}
                            buscar={empresaSelecionadaId}
                            aposBuscar={fetchTemplates}
                            optionLabel="label"
                            onChange={formik.handleChange}
                            value={formik.values.template_origem}
                            className={classNames({ "p-invalid": formik.errors.template_origem })}
                        />
                        {formik.errors.template_origem && (
                            <small className="p-error">{formik.errors.template_origem}</small>
                        )}
                    </div>
                    <div className="p-field-checkbox p-col-12 p-md-2 p-mt-5">
                        <Checkbox
                            id="requer_apontamento"
                            name="requer_apontamento"
                            checked={formik.values.requer_apontamento}
                            onChange={formik.handleChange}
                        />
                        <label htmlFor="requer_apontamento">Requer apontamento?</label>
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-12">
                        <label htmlFor="detalhes">Detalhes do serviço *</label>
                        <InputTextarea
                            id="detalhes"
                            name="detalhes"
                            value={formik.values.detalhes}
                            onChange={formik.handleChange}
                            rows={2}
                            autoResize
                            maxLength={4096}
                            className={classNames({ "p-invalid": formik.errors.detalhes })}
                        />
                        {formik.errors.detalhes && <small className="p-error">{formik.errors.detalhes}</small>}
                    </div>
                </div>
                <div className="p-grid p-col-12 p-md-6">
                    <Button icon="pi pi-save" label="Gravar" type="submit" className="p-button-info p-mr-2" />
                    {!!!formik.values.id && (
                        <Button
                            icon="pi pi-trash"
                            label="Limpar"
                            type="button"
                            className="p-button-warning p-mr-2"
                            onClick={resetForm}
                        />
                    )}
                    <Button
                        label="Cancelar"
                        type="button"
                        className="p-button-danger"
                        onClick={() => history.push("/servicos/servicos/servico")}
                    />
                </div>
            </form>
        </PageBase>
    );
};
