import React from "react";
import classNames from "classnames";
import { useHistory } from "react-router-dom";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import { useFormik } from "formik";
import { PageBase } from "@/components/PageBase";
import { axiosPost, axiosPut } from "@/services/http";
import { SelectButton } from "primereact/selectbutton";
import { Dropdown } from "@/components/Dropdown";
import { TIPO_SKU_ESTOCADO } from "@/assets/constants/estoque";
import { TIPO_CENTRO_ESTOCAGEM_CHOICE } from "@/assets/constants/constants";

import useLoading from "@/hooks/useLoading";
import useToast from "@/hooks/useToast";
import * as Yup from "yup";
import useEmpresa from "@/hooks/useEmpresa";

export const TipoTransferenciaForm = (props) => {
    const history = useHistory();
    const { empresaSelecionadaId } = useEmpresa();
    const { showLoading, hideLoading } = useLoading();
    const { showSuccess, showError } = useToast();
    const options = [
        { value: false, label: "Não" },
        { value: true, label: "Sim" },
    ];

    const { setValues, setFieldValue, ...formik } = useFormik({
        initialValues: props.location.state
            ? {
                  ...props.location.state,
                  tipo_sku_origem: props.location.state.tipo_sku_origem.id,
                  tipo_sku_destino: props.location.state.tipo_sku_destino.id,
                  tipo_ce_origem: props.location.state.tipo_ce_origem.id,
                  tipo_ce_destino: props.location.state.tipo_ce_destino.id,
              }
            : {
                  empresa: empresaSelecionadaId,
                  descricao: "",
                  ativo: true,
                  tipo_sku_origem: "M",
                  tipo_sku_destino: "M",
                  mesmo_estoque: null,
                  mesmo_centro_estocagem: null,
                  tipo_ce_origem: "N",
                  tipo_ce_destino: "N",
                  mesmo_cnpj_cpf: null,
                  mesma_raiz_cnpj: null,
                  para_matriz: null,
                  para_filial: null,
              },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                descricao: Yup.string().required("O campo é obrigatório."),
                tipo_sku_origem: Yup.string().required("O campo é obrigatório."),
                tipo_sku_destino: Yup.string().required("O campo é obrigatório."),
                tipo_ce_origem: Yup.string().required("O campo é obrigatório."),
                tipo_ce_destino: Yup.string().required("O campo é obrigatório."),
            });

            await formSchema.validate(values, {
                abortEarly: false,
            });

            if (!values.id) {
                showLoading();
                const resposta = await axiosPost("/transferencias/tipo-transferencia/", values);
                hideLoading();

                if (resposta.status === 201) {
                    showSuccess({
                        summary: "Sucesso",
                        detail: "Novo tipo de transferência cadastrado!",
                        life: 1500,
                    });
                    setTimeout(() => {
                        voltarParaListagem();
                    }, 2000);
                } else {
                    showError({
                        summary: "Erro",
                        detail: "Desculpe, não conseguimos processar a sua requisição.",
                        life: 3000,
                    });
                }
            } else {
                showLoading();
                const resposta = await axiosPut(`/transferencias/tipo-transferencia/${values.id}/`, values);
                hideLoading();

                if (resposta.status === 200) {
                    showSuccess({
                        summary: "Sucesso",
                        detail: "Tipo de transferência alterado com sucesso!",
                        life: 3000,
                    });

                    setTimeout(() => {
                        voltarParaListagem();
                    }, 2000);
                } else {
                    showError({
                        summary: "Erro",
                        detail: "Desculpe, não conseguimos processar a sua requisição.",
                        life: 3000,
                    });
                }
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            }
        }
    }

    const selecionaCentro = (valor) => {
        setFieldValue("mesmo_centro_estocagem", valor);
        if (valor) setFieldValue("mesmo_estoque", valor);
    };

    const selecionaMatriz = (valor) => {
        setFieldValue("para_matriz", valor);
        if (valor) setFieldValue("para_filial", false);
    };

    const selecionaFilial = (valor) => {
        setFieldValue("para_filial", valor);
        if (valor) setFieldValue("para_matriz", false);
    };

    const resetarIndicadores = () => {
        setFieldValue("mesmo_estoque", null);
        setFieldValue("mesmo_centro_estocagem", null);
        setFieldValue("mesmo_cnpj_cpf", null);
        setFieldValue("mesma_raiz_cnpj", null);
        setFieldValue("para_matriz", null);
        setFieldValue("para_filial", null);
        setFieldValue("tipo_sku_origem", "M");
        setFieldValue("tipo_sku_destino", "M");
        setFieldValue("tipo_ce_origem", "N");
        setFieldValue("tipo_ce_destino", "N");
    };

    const voltarParaListagem = () => {
        history.push("/estoque/cadastros/tipo-transferencia");
    };

    return (
        <PageBase>
            <h3>{!formik.values.id ? "Novo Tipo de Transferência" : "Manutenção de Tipo de Transferência"}</h3>
            <form onSubmit={formik.handleSubmit}>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-10 p-md-10">
                        <label htmlFor="descricao">Descrição *</label>
                        <InputText
                            id="descricao"
                            name="descricao"
                            value={formik.values.descricao}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.descricao })}
                        />
                        {formik.errors.descricao && <small className="p-error">{formik.errors.descricao}</small>}
                    </div>
                    <div className="p-field-checkbox p-col-2 p-md-2 p-mt-5">
                        <Checkbox
                            id="ativo"
                            name="ativo"
                            checked={formik.values.ativo}
                            onChange={formik.handleChange}
                        />
                        <label htmlFor="ativo">Ativo</label>
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="tipo_sku_origem">Tipo do produto de origem *</label>
                        <Dropdown
                            id="tipo_sku_origem"
                            name="tipo_sku_origem"
                            placeholder="Selecione..."
                            options={TIPO_SKU_ESTOCADO}
                            optionValue="value"
                            optionLabel="label"
                            value={formik.values.tipo_sku_origem}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.tipo_sku_origem })}
                        />
                        {formik.errors.tipo_sku_origem && (
                            <small className="p-error">{formik.errors.tipo_sku_origem}</small>
                        )}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="tipo_sku_destino">Tipo do produto de destino *</label>
                        <Dropdown
                            id="tipo_sku_destino"
                            name="tipo_sku_destino"
                            placeholder="Selecione..."
                            options={TIPO_SKU_ESTOCADO}
                            optionValue="value"
                            optionLabel="label"
                            value={formik.values.tipo_sku_destino}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.tipo_sku_destino })}
                        />
                        {formik.errors.tipo_sku_destino && (
                            <small className="p-error">{formik.errors.tipo_sku_destino}</small>
                        )}
                    </div>
                    <div className="p-field p-col-6 p-md-3">
                        <label htmlFor="mesmo_estoque">Mesmo estoque?</label>
                        <SelectButton
                            value={formik.values.mesmo_estoque}
                            options={options}
                            disabled={!!formik.values.mesmo_centro_estocagem}
                            onChange={(e) => setFieldValue("mesmo_estoque", e.value)}
                        />
                    </div>
                    <div className="p-field p-col-6 p-md-3">
                        <label htmlFor="mesmo_centro_estocagem">Mesmo centro de estocagem?</label>
                        <SelectButton
                            value={formik.values.mesmo_centro_estocagem}
                            options={options}
                            onChange={(e) => selecionaCentro(e.value)}
                        />
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-6">
                        <label htmlFor="tipo_ce_origem">Tipo de centro de estocagem de origem *</label>
                        <Dropdown
                            id="tipo_ce_origem"
                            name="tipo_ce_origem"
                            placeholder="Selecione..."
                            options={TIPO_CENTRO_ESTOCAGEM_CHOICE}
                            optionValue="value"
                            optionLabel="label"
                            value={formik.values.tipo_ce_origem}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.tipo_ce_origem })}
                        />
                        {formik.errors.tipo_ce_origem && (
                            <small className="p-error">{formik.errors.tipo_ce_origem}</small>
                        )}
                    </div>
                    <div className="p-field p-col-12 p-md-6">
                        <label htmlFor="tipo_ce_destino">Tipo do centro de estocagem de destino *</label>
                        <Dropdown
                            id="tipo_ce_destino"
                            name="tipo_ce_destino"
                            placeholder="Selecione..."
                            options={TIPO_CENTRO_ESTOCAGEM_CHOICE}
                            optionValue="value"
                            optionLabel="label"
                            value={formik.values.tipo_ce_destino}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.tipo_ce_destino })}
                        />
                        {formik.errors.tipo_ce_destino && (
                            <small className="p-error">{formik.errors.tipo_ce_destino}</small>
                        )}
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-6 p-md-3">
                        <label htmlFor="mesmo_cnpj_cpf">Mesmo Cnpj/CPF?</label>
                        <SelectButton
                            value={formik.values.mesmo_cnpj_cpf}
                            options={options}
                            onChange={(e) => setFieldValue("mesmo_cnpj_cpf", e.value)}
                        />
                    </div>
                    <div className="p-field p-col-6 p-md-3">
                        <label htmlFor="mesma_raiz_cnpj">Mesma raiz de Cnpj?</label>
                        <SelectButton
                            value={formik.values.mesma_raiz_cnpj}
                            options={options}
                            onChange={(e) => setFieldValue("mesma_raiz_cnpj", e.value)}
                        />
                    </div>
                    <div className="p-field p-col-6 p-md-3">
                        <label htmlFor="para_matriz">Para matriz?</label>
                        <SelectButton
                            value={formik.values.para_matriz}
                            disabled={formik.values.para_filial}
                            options={options}
                            onChange={(e) => selecionaMatriz(e.value)}
                        />
                    </div>
                    <div className="p-field p-col-6 p-md-3">
                        <label htmlFor="para_filial">Para filial?</label>
                        <SelectButton
                            value={formik.values.para_filial}
                            disabled={formik.values.para_matriz}
                            options={options}
                            onChange={(e) => selecionaFilial(e.value)}
                        />
                    </div>
                </div>
                <p>
                    <b>* Campo obrigatório</b>
                </p>
                <div className="p-grid p-col-12 p-md-6">
                    <Button icon="pi pi-save" label="Gravar" type="submit" className="p-button-info p-mr-2" />
                    <Button
                        icon="pi pi-undo"
                        label="Resetar indicadores"
                        type="button"
                        className="p-button-warning p-mr-2"
                        onClick={resetarIndicadores}
                    />
                    <Button
                        icon="pi pi-angle-double-left"
                        label="Voltar"
                        type="button"
                        className="p-button-danger p-mr-2"
                        onClick={voltarParaListagem}
                    />
                </div>
            </form>
        </PageBase>
    );
};
