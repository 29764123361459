import React, { useCallback, useEffect } from "react";
import classNames from "classnames";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { useFormik } from "formik";
import * as Yup from "yup";

import { MakoCalendar } from "@/components/MakoCalendar";
import { MakoInputMoeda } from "@/components/MakoInputMoeda";
import { MakoInputCliente } from "@/components/MakoInputs/MakoInputCliente";
import { MakoInputNumVenda } from "@/components/MakoInputs/MakoInputNumVenda";
import { MakoDropdownCompetenciaEstoque } from "@/components/MakoInputs/MakoDropdownCompetenciaEstoque";
import { FormikAutoSave } from "@/components/FormikAutoSave";
import { CamposObrigatorios } from "@/components/CamposObrigatorios";
import { SITUACAO_DEVOLUCAO } from "@/assets/constants/vendas";
import { axiosGet } from "@/services/http";
import useDevolucao from "@/hooks/useDevolucao";
import useToast from "@/hooks/useToast";
import { BlockUI } from "@/components/BlockUI";

export const DadosBasicosDevolucaoForm = () => {
    const { dadosBasicos, handleDadosBasicos } = useDevolucao();
    const { showError } = useToast();

    const { setFieldValue, setValues, ...formik } = useFormik({
        initialValues: {
            id: 0,
            empresa: null,
            cliente: null,
            venda: null,
            competencia_estoque: null,
            data: new Date(),
            valor_total: 0,
            valor_voucher: 0,
            cancelada: false,
            status: "P",
        },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values, formikHelpers) {
        try {
            const formSchema = Yup.object().shape({
                cliente: Yup.object()
                    .shape({
                        id: Yup.string().required("O campo 'cliente' é obrigatório."),
                    })
                    .typeError("Você precisa buscar e selecionar um cliente"),
                venda: Yup.object()
                    .shape({
                        id: Yup.string().required("O campo 'nº da venda' é obrigatório."),
                    })
                    .typeError("Você precisa buscar e selecionar uma venda"),
                data: Yup.date().required("O campo 'data da devolução' é obrigatório."),
            });
            formSchema.validateSync(values, { abortEarly: false });
            const { status, data } = await handleDadosBasicos(values);
            if (status === 200 || status === 201) {
                formikHelpers.resetForm({ values: data });
            } else if (status === 500) {
                showError({
                    summary: "Erro :(",
                    detail: "Desculpe, não foi possível processar sua requisição.",
                    life: 3000,
                });
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            }
        }
    }

    function traduzirSituacaoDevolucao(value) {
        const situacao = SITUACAO_DEVOLUCAO.find((s) => s.value === `${value}`.toUpperCase());
        if (!situacao) return "N/A";
        return situacao.label;
    }

    function onChangeNumVenda(event) {
        const { value } = event;
        setFieldValue("venda", value);
        if (value instanceof Object) {
            setFieldValue("cliente", value.cliente);
        }
    }

    const buscarUltimaVendaPorCliente = useCallback(async () => {
        if (formik.values.cliente instanceof Object && !formik.values.venda) {
            const clienteId = formik.values.cliente.id;
            const url = `/vendas/vendas?cliente__id=${clienteId}&situacao=F&sort=-id&query={id,cliente,data,valor_total_ap}`;
            const { status, data } = await axiosGet(url);
            if (status === 200) {
                const [ultVenda] = data.results;
                setFieldValue("venda", ultVenda);
            } else {
                showError({
                    summary: "Erro :(",
                    detail: "Desculpe, não foi possível buscar as últimas vendas do cliente.",
                    life: 3000,
                });
            }
        }
    }, [formik.values.cliente, formik.values.venda, setFieldValue, showError]);

    useEffect(() => {
        buscarUltimaVendaPorCliente();
    }, [buscarUltimaVendaPorCliente]);

    useEffect(() => {
        if (dadosBasicos) setValues(dadosBasicos);
    }, [dadosBasicos, setValues]);

    return (
        <>
            <BlockUI
                blocked={dadosBasicos?.status === "F"}
                template={<b style={{ fontSize: "3rem" }}>A devolução foi processada.</b>}
            >
                <form onSubmit={formik.handleSubmit}>
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-12 p-md-2">
                            <label htmlFor="id">Nº da devolução</label>
                            <InputText id="id" name="id" disabled value={formik.values.id || "Nova"} />
                        </div>
                        <div className="p-field p-col-12 p-md-2">
                            <label htmlFor="data">Data da devolução *</label>
                            <MakoCalendar
                                id="data"
                                name="data"
                                disabled={!!formik.values.id}
                                valueCalendar={formik.values.data}
                                onChange={formik.handleChange}
                            />
                            {formik.errors.data && <small className="p-error">{formik.errors.data}</small>}
                        </div>
                        <div className="p-field p-col-12 p-md-2">
                            <label htmlFor="venda">Nº da venda *</label>
                            <MakoInputNumVenda
                                id="venda"
                                name="venda"
                                disabled={!!formik.values.id}
                                value={formik.values.venda}
                                onChange={onChangeNumVenda}
                                className={classNames({ "p-invalid": formik.errors.venda })}
                            />
                            {formik.errors.venda && <small className="p-error">{formik.errors.venda}</small>}
                        </div>
                        <div className="p-field p-col-12 p-md-6">
                            <label htmlFor="cliente">Cliente *</label>
                            <MakoInputCliente
                                id="cliente"
                                name="cliente"
                                disabled={!!formik.values.id}
                                value={formik.values.cliente}
                                onChange={formik.handleChange}
                                className={classNames({ "p-invalid": formik.errors.cliente })}
                            />
                            {formik.errors.cliente && <small className="p-error">{formik.errors.cliente}</small>}
                        </div>
                    </div>
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-12 p-md-3">
                            <label htmlFor="competencia-estoque">Competência de estoque *</label>
                            <MakoDropdownCompetenciaEstoque
                                id="competencia-estoque"
                                name="competencia_estoque"
                                disabled={!!formik.values.id}
                                value={formik.values.competencia_estoque}
                                onChange={formik.handleChange}
                                className={classNames({ "p-invalid": formik.errors.competencia_estoque })}
                            />
                            {formik.errors.competencia_estoque && (
                                <small className="p-error">{formik.errors.competencia_estoque}</small>
                            )}
                        </div>
                        <div className="p-field p-col-12 p-md-2">
                            <label htmlFor="valor-devolucao">Valor total devolução</label>
                            <MakoInputMoeda
                                id="valor-devolucao"
                                name="valor_devolucao"
                                disabled
                                valueMoeda={formik.values.valor_total}
                            />
                        </div>
                        <div className="p-field p-col-12 p-md-2">
                            <label htmlFor="valor-devolvido">Valor devolvido</label>
                            <MakoInputMoeda id="valor-devolvido" name="valor_devolvido" disabled valueMoeda={0} />
                        </div>
                        <div className="p-field p-col-12 p-md-3">
                            <label htmlFor="usuario-inclusao">Incluída por</label>
                            <InputText id="usuario-inclusao" name="usuario_inclusao" disabled />
                        </div>
                        <div className="p-field p-col-12 p-md-2">
                            <label htmlFor="status-devolucao">Situação da devolução</label>
                            <InputText
                                id="status-devolucao"
                                name="status_devolucao"
                                disabled
                                value={traduzirSituacaoDevolucao(formik.values.status)}
                            />
                        </div>
                    </div>
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-12 p-md-2">
                            <label htmlFor="n-voucher">Nº do vale-troca</label>
                            <InputText id="n-voucher" name="n_voucher" disabled />
                        </div>
                        <div className="p-field p-col-12 p-md-2">
                            <label htmlFor="valor-voucher">Valor do vale-troca</label>
                            <MakoInputMoeda id="valor-voucher" name="valor_voucher" disabled valueMoeda={0} />
                        </div>
                        <div className="p-field p-col-12 p-md-2">
                            <label htmlFor="emissao_voucher">Emissão do vale-troca</label>
                            <MakoCalendar id="emissao_voucher" name="emissaovoucher" disabled />
                        </div>
                        <div className="p-field p-col-12 p-md-2">
                            <label htmlFor="venc-voucher">Validade do vale-troca</label>
                            <MakoCalendar id="venc-voucher" name="vencvoucher" disabled />
                        </div>
                        <div className="p-field p-col-12 p-md-2">
                            <label htmlFor="status-voucher">Situação do vale-troca</label>
                            <InputText id="status-voucher" name="status_voucher" disabled />
                        </div>
                    </div>
                    <CamposObrigatorios />
                    {formik.values.id === 0 && (
                        <div className="p-grid">
                            <div className="p-col-12 p-md-6">
                                <Button type="submit" icon="pi pi-save" label="Incluir dados básicos" />
                            </div>
                        </div>
                    )}
                    <FormikAutoSave intervalo={500} autosave={formik.values.id > 0} formik={formik} />
                </form>
            </BlockUI>
        </>
    );
};
