import React, { useState, useRef } from "react";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import permissoes from "@/assets/constants/permissoes";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import MakoListagem from "@/components/MakoListagem";
import { Tag } from "primereact/tag";
import useToast from "@/hooks/useToast";
import { BotaoDelete } from "@/components/BotaoDelete";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { PageBase } from "@/components/PageBase";

const url = "/fiscal/ncm/";

const opcoesPesquisa = [
    { id: "todos", label: "Todos", value: "" },
    { id: "ativos", label: "Ativos", value: "true" },
    { id: "inativos", label: "Inativos", value: "false" },
];

export const FiscalNcmPage = () => {
    const [filtro, setFiltro] = useState("");
    const { showSuccess } = useToast();
    const listagemRef = useRef(null);
    const history = useHistory();

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions ">
                <MakoControleAcesso
                    permissao={[permissoes.FISCAL_FISCAL_NCM_EDITAR]}
                    componente={Button}
                    icon="pi pi-pencil"
                    className="p-button-rounded p-button-warning p-mr-2 p-mb-1"
                    tooltip="Alterar Cadastro de NCM"
                    tooltipOptions={{ position: "left" }}
                    onClick={() =>
                        history.push({
                            pathname: "/fiscal/cadastros/ncm/form",
                            state: { ncm: rowData },
                        })
                    }
                />
                <MakoControleAcesso
                    permissao={[permissoes.FISCAL_FISCAL_NCM_EXCLUIR]}
                    componente={BotaoDelete}
                    url={url}
                    objetoId={rowData.id}
                    classNames="p-mb-1"
                    exigeConfirmacao
                    msgConfirmacao={
                        <span>
                            Deseja realmente excluir o NCM <b>{rowData.descricao}</b>?
                        </span>
                    }
                    msgToastErroExclusao="O NCM não pode ser excluído."
                    tooltip="Deletar NCM"
                    tooltipOptions={{ position: "left" }}
                    onDelete={() => {
                        listagemRef.current?.buscarDados();
                        showSuccess({
                            summary: "Sucesso",
                            detail: "Ncm deletado com sucesso!",
                            life: 3000,
                        });
                    }}
                />
            </div>
        );
    };

    const filtroStatus = (status) => {
        listagemRef.current.filter(status.value, "ativo", "contains");
        setFiltro(status.value);
    };

    const cabecalhoTabela = (
        <>
            <MakoControleAcesso
                permissao={[permissoes.FISCAL_FISCAL_NCM_INCLUIR]}
                componente={Button}
                label="Novo"
                icon="pi pi-plus"
                className="p-button-success p-mr-2"
                onClick={() => history.push("/fiscal/cadastros/ncm/form")}
            />
            <Dropdown
                id="opcoesPesquisa"
                name="opcoesPesquisa"
                style={{ width: "120px" }}
                value={filtro}
                options={opcoesPesquisa}
                optionLabel="label"
                placeholder={!filtro ? ("" ? filtro : "Todos") : filtro ? "Ativo" : false ? filtro : "Inativo"}
                onChange={filtroStatus}
            />
        </>
    );

    const STATUS_NCM = [
        { value: true, label: "Ativo" },
        { value: false, label: "Inativo" },
    ];

    const statusFiltroTemplate = (options) => {
        return (
            <Dropdown
                placeholder="Selecione"
                showClear
                value={options.value}
                options={STATUS_NCM}
                onChange={(e) => options.filterCallback(e.value)}
            />
        );
    };

    const statusBodyTemplate = (rowData) => {
        if (rowData.ativo) {
            return <Tag severity="success" value="ATIVO" />;
        }

        return <Tag severity="danger" value="DESATIVADO" />;
    };

    const colunas = [
        {
            field: "id",
            header: "#ID",
            style: { width: "10%" },
            filter: true,
        },
        {
            field: "codigo",
            header: "Código",
            style: { width: "12%" },
            filter: true,
        },
        {
            field: "descricao",
            header: "Descrição",
            filter: true,
        },
        {
            field: "vigencia_inicial",
            header: "Vigência Inicial",
            style: { width: "15%" },
            dateFormat: "dd/MM/yyyy",
            filter: true,
        },
        {
            header: "Situação",
            field: "ativo",
            style: { width: "10%" },
            filter: true,
            filterElement: statusFiltroTemplate,
            action: (e) => statusBodyTemplate(e),
        },
        {
            field: "action",
            header: "Ações",
            action: (e) => actionBodyTemplate(e),
            style: { width: "10%" },
        },
    ];

    return (
        <PageBase>
            <MakoListagem
                ref={listagemRef}
                titulo="NCM's"
                colunas={colunas}
                painelEsquerdo={cabecalhoTabela}
                urlPesquisa={url}
                responsiva
                filtros={{
                    id: {
                        operator: "and",
                        constraints: [{ value: "", matchMode: "icontains" }],
                    },
                    codigo: {
                        operator: "and",
                        constraints: [{ value: "", matchMode: "icontains" }],
                    },
                    descricao: {
                        operator: "and",
                        constraints: [{ value: "", matchMode: "icontains" }],
                    },
                    vigencia_inicial: {
                        operator: "and",
                        constraints: [{ value: "", matchMode: "icontains" }],
                    },
                    ativo: { value: true, matchMode: "equals" },
                }}
                configTabela={{
                    paginator: true,
                    lazy: true,
                    filterDisplay: "menu",
                }}
            />
        </PageBase>
    );
};
