import React, { useState, useRef, useEffect, useCallback } from "react";
import { Button } from "primereact/button";
import { ConfirmDialog } from "primereact/confirmdialog";
import { Toast } from "primereact/toast";
import { Menu } from "primereact/menu";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import permissoes from "@/assets/constants/permissoes";
import MakoListagem from "@/components/MakoListagem";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { CancelaNotaServicoFormModal } from "./modals/formCancelaModal";
import { CartaCorrecaoNFSFormModal } from "./modals/formCartaCorrecaoModal";
import { axiosGet, axiosPatch, axiosPost } from "@/services/http";
import axios from "axios";
import useLoading from "@/hooks/useLoading";
import useEmpresa from "@/hooks/useEmpresa";
import { PageBase } from "@/components/PageBase";

export const ListaNotaServicoPage = () => {
    const [corpoNota, setCorpoNota] = useState(null);
    const [notaServico, setNotaServico] = useState(null);
    const [confirmaCancelar, setConfirmaCancelar] = useState(false);
    const [notaSelecionada, setNotaSelecionada] = useState(null);
    const [cancelaDialog, setCancelaDialog] = useState(false);
    const [correcaoDialog, setCorrecaoDialog] = useState(false);
    const history = useHistory();
    const { showLoading, hideLoading } = useLoading();
    const { empresaSelecionada } = useEmpresa();
    const menu = useRef(null);
    const toastRef = useRef(null);
    const listagemRef = useRef(null);

    const botoesEmissao = [
        {
            label: "Operações NF",
            items: [
                {
                    label: "Pré-visualizar",
                    icon: "pi pi-file",
                    command: () => {
                        enviarNota("pre-visualizar");
                    },
                },
                {
                    label: "Transmitir",
                    icon: "pi pi-arrow-up",
                    command: () => {
                        enviarNota("emitir");
                    },
                },
                {
                    label: "Carta de correção",
                    icon: "pi pi-book",
                    command: () => {
                        setCorrecaoDialog(true);
                    },
                },
                {
                    label: "Cancelar",
                    icon: "pi pi-times",
                    command: () => {
                        setConfirmaCancelar(true);
                    },
                },
            ],
        },
    ];

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <MakoControleAcesso
                    permissao={[permissoes.FISCAL_NOTASERVICO_EDITAR]}
                    componente={Button}
                    icon="pi pi-pencil"
                    className="p-button-rounded p-button-warning p-mr-2 p-mb-1"
                    tooltip="Alterar cadastro de nota de serviço"
                    tooltipOptions={{ position: "left" }}
                    onClick={() =>
                        history.push({
                            pathname: "/fiscal/nota-servico/incluir-nota",
                            notaServico: rowData.id,
                            opServico: rowData.operacao_servico,
                        })
                    }
                />
                <MakoControleAcesso
                    permissao={[permissoes.FISCAL_NOTASERVICO_EMITIR]}
                    model={botoesEmissao}
                    componente={Button}
                    className="p-button-rounded p-button-info p-mr-2 p-mb-1"
                    tooltip="Operações NF"
                    tooltipOptions={{ position: "left" }}
                    disabled={notaSelecionada && rowData.numero === notaSelecionada.numero ? false : true}
                    icon="pi pi-book"
                    onClick={(event) => {
                        menu.current.toggle(event);
                        setNotaServico(rowData);
                    }}
                />
                <MakoControleAcesso
                    permissao={[permissoes.FISCAL_NOTASERVICO_CONSULTAR]}
                    componente={Button}
                    icon="pi pi-download"
                    className="p-button-rounded p-button-success p-mr-2 p-mb-1"
                    //onClick={() => downloadNota(rowData, "xml")}
                    tooltip="Download XML"
                    tooltipOptions={{ position: "left" }}
                    disabled={
                        rowData.protocolo
                            ? notaSelecionada && rowData.rps_numero === notaSelecionada.rps_numero
                                ? false
                                : true
                            : true
                    }
                />
                <MakoControleAcesso
                    permissao={[permissoes.FISCAL_NOTASERVICO_CONSULTAR]}
                    componente={Button}
                    icon="pi pi-file-pdf"
                    className="p-button-rounded p-button-danger p-mr-2 p-mb-1"
                    //onClick={() => downloadNota(rowData, "pdf")}
                    tooltip={`Imprimir NFSe`}
                    tooltipOptions={{ position: "left" }}
                    disabled={
                        rowData.protocolo
                            ? notaSelecionada && rowData.numero === notaSelecionada.numero
                                ? false
                                : true
                            : true
                    }
                />
            </div>
        );
    };

    const montaNota = useCallback(async () => {
        if (notaServico) {
            const resposta = await axiosPost(`/servicos/montar-nota-servico-invoicy/`, notaServico);
            if (resposta.status === 200) setCorpoNota(resposta.data.nota_servico);
            else
                toastRef.current.show({
                    severity: "warn",
                    summary: "Informações incompletas",
                    detail: "Verifique as informações no formulário da nota e tente novamente.",
                    life: 3000,
                });
        }
    }, [notaServico]);

    useEffect(() => {
        montaNota();
    }, [notaServico, montaNota]);

    async function enviarNota(operacao) {
        try {
            const apiServico = await axiosGet(`/configuracoes/api-servicos?empresa=${empresaSelecionada.id}`);
            if (apiServico.data?.results?.length === 0) {
                toastRef.current.show({
                    severity: "warn",
                    summary: "API não encontrada!",
                    detail: "Não existe API de Serviço cadastrada.",
                    life: 5000,
                });
            } else {
                const resposta = await axios.post(
                    `${process.env.REACT_APP_INTEGRACAO_FISCAL}/documents/${apiServico.data.results[0].chave_acesso}/${apiServico.data.results[0].cnpj_integrado}/nfse/${operacao}`,
                    corpoNota
                );
                if (resposta.data?.length > 0)
                    if (resposta.data[0].Codigo === 100) {
                        toastRef.current.show({
                            severity:
                                resposta.data[0].Documentos[0].Situacao.SitDescricao === "Autorizado o uso da NF-e" ||
                                operacao === "pre-visualizar"
                                    ? "success"
                                    : "warn",
                            summary:
                                resposta.data[0].Documentos[0].Situacao.SitDescricao === "Autorizado o uso da NF-e" ||
                                operacao === "pre-visualizar"
                                    ? "Sucesso!"
                                    : "Aviso",
                            detail: `${resposta.data[0].Descricao}!
                         \n
                         Código: ${resposta.data[0].Codigo}
                         \n
                         ${
                             operacao === "emitir"
                                 ? `${
                                       resposta.data[0].Documentos
                                           ? resposta.data[0].Documentos[0].Situacao.SitDescricao
                                           : ""
                                   }`
                                 : ""
                         }`,
                            sticky: true,
                        });

                        if (
                            resposta.data[0].Documentos[0].Situacao.SitDescricao === "Autorizado o uso da NF-e" ||
                            operacao === "pre-visualizar"
                        ) {
                            if (resposta.data[0].Documentos[0].DocPDFBase64) {
                                var link = document.createElement("a");
                                link.innerHTML = "Download PDF file";
                                link.download = "file.pdf";
                                link.href =
                                    "data:application/octet-stream;base64," +
                                    resposta.data[0].Documentos[0].DocPDFBase64;
                                document.body.appendChild(link);
                                window.open(document.body.appendChild(link));
                            }

                            showLoading();
                            const json = await axiosPatch(`/servicos/notas-fiscais-servicos/${notaServico.id}/`, {
                                chave_nf: resposta.data[0].Documentos[0].DocChaAcesso,
                                protocolo: resposta.data[0].Documentos[0].DocProtocolo,
                            });
                            hideLoading();
                            if (json.status !== 200) {
                                toastRef.current.show({
                                    severity: "error",
                                    summary: "Erro",
                                    detail: "Falha ao persistir chave e protocolo da nota! Por favor, verifique com a assistência.",
                                    life: 6000,
                                });
                            } else listagemRef.current?.buscarDados();
                        }
                    } else {
                        toastRef.current.show({
                            severity: "warn",
                            summary: "Mensagem",
                            detail: `${resposta.data[0].Descricao} \n
                    Código: ${resposta.data[0].Codigo}`,
                            life: 5000,
                        });
                    }
                else if (resposta.data?.Descricao)
                    toastRef.current.show({
                        severity: "warn",
                        summary: "Mensagem",
                        detail: `${resposta.data.Descricao} \n
                    Código: ${resposta.data.Codigo}`,
                        life: 5000,
                    });
                else
                    toastRef.current.show({
                        severity: "error",
                        summary: "Erro",
                        detail: "Não foi possível realizar essa ação, verifique o layout e tente novamente.",
                        life: 5000,
                    });
            }
        } catch (error) {
            toastRef.current.show({
                severity: "error",
                summary: "Erro",
                detail: `Falha ao comunicar com o agente fiscal.`,
                life: 3000,
            });
        }
    }

    const verificaProtocolo = (protocolo) => {
        if (protocolo) return protocolo;
        else return "N/A";
    };

    const calculaTotal = (servicos) => {
        let total = 0;
        servicos.forEach((servico) => {
            total += parseFloat(servico.valor_total);
        });
        return total;
    };

    const colunas = [
        {
            field: "rps_numero",
            header: "Número",
            filter: true,
            style: { width: "80px" },
        },
        {
            field: "rps_serie",
            header: "Série",
            filter: true,
            style: { width: "50px" },
        },
        {
            field: "tomador.nome",
            header: "Tomador",
            style: { minWidth: "300px" },
            filter: true,
        },
        {
            field: "protocolo",
            header: "Protocolo",
            action: (e) => verificaProtocolo(e.protocolo),
            filter: true,
            style: { minWidth: "200px" },
        },
        {
            field: "valor_total",
            header: "Valor Total",
            action: (e) => calculaTotal(e.serviconotafiscalservico_set),
            filter: true,
            align: "right",
            style: { width: "130px" },
        },
        {
            field: "action",
            header: "Ações",
            action: (e) => actionBodyTemplate(e),
            frozen: true,
            alignFrozen: "right",
            style: { minWidth: "200px" },
        },
    ];

    const esconderCancelDialog = () => {
        setConfirmaCancelar(false);
    };

    return (
        <PageBase>
            <Toast ref={toastRef} />
            <Menu model={botoesEmissao} popup ref={menu} id="popup_menu" />
            <MakoListagem
                ref={listagemRef}
                titulo="Notas Fiscais"
                colunas={colunas}
                urlPesquisa={`/servicos/notas-fiscais-servicos/`}
                mostrarGlobalSearch={false}
                responsiva
                configTabela={{
                    paginator: true,
                    lazy: true,
                    filterDisplay: "menu",
                    selection: notaSelecionada,
                    onSelectionChange: (e) => setNotaSelecionada(e.value),
                    selectionMode: "single",
                    scrollable: true,
                    frozenWidth: "0.5vw",
                    resizableColumns: true,
                    columnResizeMode: "expand",
                }}
            />
            <CancelaNotaServicoFormModal cancelaDialog={cancelaDialog} setCancelaDialog={setCancelaDialog} />
            <CartaCorrecaoNFSFormModal correcaoDialog={correcaoDialog} setCorrecaoDialog={setCorrecaoDialog} />
            <ConfirmDialog
                visible={confirmaCancelar}
                onHide={esconderCancelDialog}
                header="Confirmação de Cancelamento"
                message={
                    notaServico && (
                        <span>
                            {"Deseja realmente cancelar a nota de serviço número "}
                            <b>{notaServico.rps_numero}</b>?
                        </span>
                    )
                }
                icon="pi pi-info-circle p-mr-3"
                accept={() => setCancelaDialog(true)}
                acceptLabel="Sim"
                acceptClassName="p-button-danger"
                reject={esconderCancelDialog}
                rejectLabel="Não"
            />
        </PageBase>
    );
};
