import React, { useRef, useState } from "react";
import { useFormik } from "formik";
import { PageBase } from "@/components/PageBase";
import useNotaFiscal from "@/hooks/useNotaFiscal";
import classNames from "classnames";
import * as Yup from "yup";
import MakoListagem from "@/components/MakoListagem";
import useToast from "@/hooks/useToast";
import { Button } from "primereact/button";
import { parseNumberToMoneyHTML } from "@/assets/util/util";
import { MakoCalendar } from "@/components/MakoCalendar";
import { MakoInputMoeda } from "@/components/MakoInputMoeda";
import { InputText } from "primereact/inputtext";
import { Dialog } from "primereact/dialog";
import { dataToStr } from "@/assets/util/datas";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import { BotaoDelete } from "@/components/BotaoDelete";
import permissoes from "@/assets/constants/permissoes";

export const DuplicataCobrancaForm = () => {
    const [dialog, setDialog] = useState(false);
    const { dadosBasicos, handleCobranca } = useNotaFiscal();
    const listagemRef = useRef(null);
    const { showError } = useToast();

    const url = "/fiscal/cobrancas-nota-fiscal/";

    const { setFieldValue, setValues, resetForm, ...formik } = useFormik({
        initialValues: {
            numero_duplicata: 1,
            data_vencimento: new Date(),
            valor_duplicata: dadosBasicos.fatura_liquida,
        },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                data_vencimento: Yup.date().required("O campo é obrigatório!"),
                valor_duplicata: Yup.number().required("O campo é obrigatório!"),
            });

            await formSchema.validate(values, {
                abortEarly: false,
            });

            const { status } = await handleCobranca(url, {
                ...values,
                data_vencimento: dataToStr(values.data_vencimento, "yyyy-MM-dd"),
            });

            if (status === 201) {
                listagemRef.current?.buscarDados();
                setDialog(false);
                resetForm();
            } else
                showError({
                    summary: "Erro",
                    detail: "Desculpe, não foi possível salvar as informações da cobrança.",
                    life: 1500,
                });
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            }
        }
    }

    const painelEsquerdo = (
        <>
            <Button
                label="Adicionar"
                icon="pi pi-plus"
                type="button"
                disabled={dadosBasicos?.indicador_pagamento === 1}
                className="p-button-success p-button-outlined"
                onClick={(e) => setDialog(true)}
            />
        </>
    );

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <MakoControleAcesso
                    permissao={[permissoes.FISCAL_NOTASAIDA_EDITAR]}
                    componente={BotaoDelete}
                    url={url}
                    objetoId={rowData.id}
                    classNames="p-mb-1"
                    exigeConfirmacao
                    msgConfirmacao={<span>Deseja realmente deletar a duplicada?</span>}
                    msgToastErroExclusao="A duplicata não pode ser deletada."
                    onDelete={() => listagemRef.current?.buscarDados()}
                />
            </div>
        );
    };

    const colunas = [
        { field: "numero_duplicata", header: "Número" },
        { field: "valor_duplicata", header: "Valor", action: (e) => parseNumberToMoneyHTML(e.valor_duplicata) },
        { field: "data_vencimento", header: "Vencimento" },
        {
            field: "action",
            header: "Ações",
            action: (e) => actionBodyTemplate(e),
            style: { minWidth: "30px" },
        },
    ];

    const esconderDialog = () => {
        resetForm();
        setDialog(false);
    };
    return (
        <PageBase>
            <MakoListagem
                ref={listagemRef}
                colunas={colunas}
                titulo="Duplicatas"
                painelEsquerdo={painelEsquerdo}
                urlPesquisa={`/fiscal/cobrancas-nota-fiscal?nota_fiscal=${dadosBasicos.id}`}
                configTabela={{
                    scrollable: true,
                    scrollHeight: "400px",
                }}
            />
            <Dialog
                header="Duplicata da fatura"
                visible={dialog}
                breakpoints={{ "960px": "80vw" }}
                style={{ width: "50vw" }}
                onHide={() => esconderDialog()}
            >
                <form>
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-12 p-md-4">
                            <label htmlFor="numero_duplicata">Número *</label>
                            <InputText
                                id="numero_duplicata"
                                name="numero_duplicata"
                                onChange={formik.handleChange}
                                value={formik.values.numero_duplicata}
                                disabled
                                className={classNames({ "p-invalid": formik.errors.numero_duplicata })}
                            />
                            {formik.errors.numero_duplicata && (
                                <small className="p-error">{formik.errors.numero_duplicata}</small>
                            )}
                        </div>
                        <div className="p-field p-col-12 p-md-4">
                            <label htmlFor="valor_duplicata">Valor *</label>
                            <MakoInputMoeda
                                id="valor_duplicata"
                                name="valor_duplicata"
                                value={formik.values.valor_duplicata}
                                onValueChange={formik.handleChange}
                                className={classNames({ "p-invalid": formik.errors.valor_duplicata })}
                            />
                            {formik.errors.valor_duplicata && (
                                <small className="p-error">{formik.errors.valor_duplicata}</small>
                            )}
                        </div>
                        <div className="p-field p-col-12 p-md-4">
                            <label htmlFor="data_vencimento">Data vencimento *</label>
                            <MakoCalendar
                                id="data_vencimento"
                                name="data_vencimento"
                                valueCalendar={formik.values.data_vencimento}
                                onChange={formik.handleChange}
                                className={classNames({
                                    "p-invalid": formik.errors.data_vencimento,
                                })}
                            />
                            {formik.errors.data_vencimento && (
                                <small className="p-error">{formik.errors.data_vencimento}</small>
                            )}
                        </div>
                    </div>
                    <p>
                        <b>* Campos obrigatórios.</b>
                    </p>
                    <div className="p-grid p-mt-2 p-ml-1">
                        <Button
                            label="Inserir"
                            icon="pi pi-plus"
                            type="button"
                            className="p-button-success p-mr-2"
                            onClick={() => handleSubmit(formik.values)}
                        />
                        <Button
                            label="Fechar"
                            icon="pi pi-times"
                            type="reset"
                            className="p-button-danger p-mr-2"
                            onClick={() => esconderDialog()}
                        />
                    </div>
                </form>
            </Dialog>
        </PageBase>
    );
};
