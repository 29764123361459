import React, { createContext, useCallback, useMemo, useState } from "react";

import { dataToStr } from "@/assets/util/datas";
import useHttp from "@/hooks/useHttp";

const DevolucaoFornecedorContext = createContext({});

export const DevolucaoFornecedorProvider = ({ children }) => {
    const [dadosBasicos, setDadosBasicos] = useState({});
    const { httpPost, httpPatch, httpGet, httpDelete } = useHttp();

    const handleDevolucaoFornecedor = async (values) => {
        const devolucao = {
            ...values,
            data_devolucao: dataToStr(values.data_devolucao, "yyyy-MM-dd"),
        };

        if (!values.id) {
            let status = 400,
                json = {};

            const handlers = {
                201: ({ data }) => {
                    status = 201;
                    json = data;
                    setDadosBasicos(data);
                },
            };

            await httpPost(
                {
                    url: "/compras/devolucao-fornecedor/",
                    body: {
                        ...devolucao,
                        fornecedor: devolucao.fornecedor?.id,
                        motorista: devolucao.motorista?.id || null,
                    },
                },
                handlers
            );

            return { status, data: json };
        } else {
            let diffDevolucao = {};
            Object.entries(devolucao).forEach(([k, v]) => {
                if (v !== dadosBasicos[k]) diffDevolucao[k] = v;
            });
            if (Object.keys(diffDevolucao).length) {
                let status = 400,
                    json = {};

                const handlers = {
                    200: ({ data }) => {
                        status = 200;
                        const { transportador } = data;
                        const _transportador = transportador?.id ? transportador : values?.transportador;
                        json = { ...data, tipo: data.tipo.id, transportador: _transportador };
                        setDadosBasicos({ ...data, tipo: data.tipo.id, transportador: _transportador });
                    },
                };

                await httpPatch(
                    {
                        url: `/compras/devolucao-fornecedor/${values.id}/`,
                        body: {
                            ...diffDevolucao,
                            transportador: diffDevolucao?.transportador?.id || diffDevolucao?.transportador,
                            motorista: diffDevolucao?.motorista?.id || null,
                        },
                    },
                    handlers
                );

                return { status, data: json };
            }
        }
        return { status: 204, data: values };
    };

    const handlePreencherTroca = useCallback(
        async (idDev) => {
            const handlers = {
                200: ({ data }) => {
                    const { tipo, ...rest } = data;
                    setDadosBasicos({ ...rest, tipo: tipo.id });
                },
            };

            await httpGet(
                {
                    url: `/compras/devolucao-fornecedor/${idDev}/`,
                },
                handlers
            );
        },
        [httpGet]
    );

    const handleItens = useCallback(
        async (values) => {
            if (!values.id) {
                let status = 400,
                    json = {};

                const handlers = {
                    201: ({ data }) => {
                        status = 201;
                        json = data;
                        handlePreencherTroca(dadosBasicos.id);
                    },
                };

                await httpPost(
                    {
                        url: "/compras/item-devolucao-fornecedor/",
                        body: {
                            ...values,
                            sku: values.sku.id,
                            descricao_item_fornecedor: values.sku.descricao_reduzida,
                            devolucao_fornecedor: dadosBasicos.id,
                            numero_volumes_por_item: 1,
                        },
                    },
                    handlers
                );
                return { status, data: json };
            } else {
                let status = 400,
                    json = {};

                const handlers = {
                    200: ({ data }) => {
                        status = 200;
                        json = data;
                        handlePreencherTroca(dadosBasicos.id);
                    },
                };

                await httpPatch(
                    {
                        url: `/compras/item-devolucao-fornecedor/${values.id}/`,
                        body: {
                            ...values,
                            sku: values.sku.id,
                        },
                    },
                    handlers
                );
                return { status, data: json };
            }
        },
        [dadosBasicos?.id, handlePreencherTroca, httpPost, httpPatch]
    );

    const handleParcelas = useCallback(
        async (values, remover = false) => {
            if (!values.id) {
                let status = 400,
                    json = {};

                const handlers = {
                    201: ({ data }) => {
                        status = 201;
                        json = data;
                    },
                };

                await httpPost(
                    {
                        url: "/compras/parcela-devolucao-fornecedor/",
                        body: {
                            ...values,
                            devolucao_fornecedor: dadosBasicos.id,
                            credor: values?.credor?.id || values?.credor,
                        },
                    },
                    handlers
                );

                return { status, data: json };
            } else {
                if (remover) {
                    let status = 400,
                        json = {};

                    const handlers = {
                        204: ({ data = {} }) => {
                            status = 204;
                            json = data;
                        },
                        400: ({ err }) => (json = err),
                    };

                    await httpDelete(
                        {
                            url: `/compras/parcela-devolucao-fornecedor/${values.id}`,
                        },
                        handlers
                    );

                    return { status, data: json };
                }
                let status = 400,
                    json = {};

                const handlers = {
                    200: ({ data = {} }) => {
                        status = 200;
                        json = data;
                    },
                    400: ({ err }) => (json = err),
                };

                await httpPatch(
                    {
                        url: `/compras/parcela-devolucao-fornecedor/${values.id}/`,
                        body: values,
                    },
                    handlers
                );

                return { status, data: json };
            }
        },
        [dadosBasicos?.id, httpDelete, httpPatch, httpPost]
    );

    const editavel = useMemo(() => {
        if (!dadosBasicos) return true;
        const { status } = dadosBasicos || {};
        return status !== "F";
    }, [dadosBasicos]);

    const possuiDevolucao = useMemo(() => {
        return Object.keys(dadosBasicos).length > 0;
    }, [dadosBasicos]);

    return (
        <DevolucaoFornecedorContext.Provider
            value={{
                editavel,
                dadosBasicos,
                possuiDevolucao,
                handleDevolucaoFornecedor,
                handlePreencherTroca,
                handleItens,
                handleParcelas,
            }}
        >
            {children}
        </DevolucaoFornecedorContext.Provider>
    );
};

export default DevolucaoFornecedorContext;
