import React, { forwardRef, useImperativeHandle, useState } from "react";
import classNames from "classnames";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { useFormik } from "formik";
import * as Yup from "yup";
import { totalizadorFiltrosAplicados } from "@/assets/util/util";
import { MakoCalendar } from "@/components/MakoCalendar";
import { dataToStr } from "@/assets/util/datas";

const INITIAL_VALUES = {
    numero: "",
    destinatario__nome__unaccent__icontains: "",
    chave_nf__icontains: "",
    protocolo: "",
    serie: "",
    modelo: "",
    datahora_emissao__gte: null,
    datahora_emissao__lte: null,
    valor_total_nf: "",
};

const Modal = ({ onFilter, baseUrl, filtros, setFiltro, removerFiltro }, ref) => {
    const [visible, setVisible] = useState(false);
    const formik = useFormik({
        initialValues: filtros ? { ...INITIAL_VALUES, ...filtros } : INITIAL_VALUES,
        onSubmit: handleSubmit,
        enableReinitialize: true,
    });

    async function handleSubmit(values) {
        try {
            if (typeof onFilter === "function") {
                let filtros = {};
                let params = [];
                Object.keys(values).map((key) => {
                    if (values[key] && values[key] !== "") {
                        if (key === "datahora_emissao__gte") {
                            filtros[key] = `${dataToStr(values[key], "yyyy-MM-dd")} 00:00:00`;
                            return params.push(`${key}=${dataToStr(values[key], "yyyy-MM-dd")} 00:00:00`);
                        }
                        if (key === "datahora_emissao__lte") {
                            filtros[key] = `${dataToStr(values[key], "yyyy-MM-dd")} 23:59:59`;
                            return params.push(`${key}=${dataToStr(values[key], "yyyy-MM-dd")} 00:00:00`);
                        }
                        filtros[key] = values[key];
                        return params.push(`${key}=${values[key]}`);
                    }
                });

                if (params.length > 0) {
                    onFilter(baseUrl + `&${params.join("&")}`, totalizadorFiltrosAplicados(INITIAL_VALUES, values));
                    setFiltro(filtros);
                }
            }
            setVisible(false);
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};

                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });

                formik.setErrors(errorMessages);
            }
        }
    }

    const abrirModal = () => {
        setVisible(true);
    };

    useImperativeHandle(ref, () => ({ abrirModal, limparFiltros }));

    const onCancel = () => {
        setVisible(false);
    };

    const limparFiltros = () => {
        formik.resetForm();
    };

    const limparForm = () => {
        removerFiltro();
        limparFiltros();
    };

    return (
        <Dialog
            header="Filtro avançado de notas fiscais"
            visible={visible}
            onHide={() => setVisible(false)}
            style={{ width: "60vw" }}
        >
            <form>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-6">
                        <label htmlFor="nome-curto">Nome destinatário</label>
                        <InputText
                            id="nome-curto"
                            name="destinatario__nome__unaccent__icontains"
                            value={formik.values.destinatario__nome__unaccent__icontains}
                            onChange={formik.handleChange}
                        />
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="numero">Número</label>
                        <InputText
                            id="numero"
                            name="numero"
                            value={formik.values.numero}
                            onChange={formik.handleChange}
                        />
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="serie">Série</label>
                        <InputText id="serie" name="serie" value={formik.values.serie} onChange={formik.handleChange} />
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-6">
                        <label htmlFor="chave_nf__icontains">Chave NF</label>
                        <InputText
                            id="chave_nf__icontains"
                            name="chave_nf__icontains"
                            value={formik.values.chave_nf__icontains}
                            onChange={formik.handleChange}
                            className={classNames({
                                "p-invalid": formik.errors.chave_nf__icontains,
                            })}
                        />
                        {formik.errors.chave_nf__icontains && (
                            <small className="p-error">{formik.errors.chave_nf__icontains}</small>
                        )}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="protocolo">Protocolo</label>
                        <InputText
                            id="protocolo"
                            name="protocolo"
                            value={formik.values.protocolo}
                            onChange={formik.handleChange}
                            className={classNames({
                                "p-invalid": formik.errors.protocolo,
                            })}
                        />
                        {formik.errors.protocolo && <small className="p-error">{formik.errors.protocolo}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="modelo">Modelo</label>
                        <Dropdown
                            id="modelo"
                            name="modelo"
                            options={[
                                { id: "55", value: "NF-e" },
                                { id: "65", value: "NFC-e" },
                            ]}
                            placeholder="Selecione"
                            optionValue="id"
                            optionLabel="value"
                            value={formik.values.modelo}
                            onChange={formik.handleChange}
                        />
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-6 p-md-3">
                        <label htmlFor="datahora_emissao__gte">Data emissão (início)</label>
                        <MakoCalendar
                            id="datahora_emissao__gte"
                            name="datahora_emissao__gte"
                            valueCalendar={formik.values.datahora_emissao__gte}
                            onChange={formik.handleChange}
                        />
                    </div>
                    <div className="p-field p-col-6 p-md-3">
                        <label htmlFor="datahora_emissao__lte">Data emissão (fim)</label>
                        <MakoCalendar
                            id="datahora_emissao__lte"
                            name="datahora_emissao__lte"
                            valueCalendar={formik.values.datahora_emissao__lte}
                            onChange={formik.handleChange}
                        />
                    </div>
                </div>
                <div className="p-grid p-justify-end p-mt-4">
                    <Button
                        type="submit"
                        label="Filtrar"
                        icon="pi pi-filter"
                        className="p-mr-2"
                        onClick={formik.handleSubmit}
                    />
                    <Button
                        type="reset"
                        icon="pi pi-trash"
                        label="Limpar"
                        onClick={limparForm}
                        className="p-button-warning p-mr-2"
                    />
                    <Button
                        type="reset"
                        label="Cancelar"
                        onClick={() => onCancel()}
                        className="p-button-danger p-mr-3"
                    />
                </div>
            </form>
        </Dialog>
    );
};

export const ModalFiltroAvancadoNf = forwardRef(Modal);
