import { SEVERITIES } from "./primereact";

export const SITUACAO_VENDA = [
    { value: "P", label: "Pendente", severity: SEVERITIES.warning },
    { value: "C", label: "Cancelada", severity: SEVERITIES.danger },
    { value: "F", label: "Finalizada", severity: SEVERITIES.success },
];

export const SITUACAO_DEVOLUCAO = [
    { value: "P", label: "Pendente", severity: SEVERITIES.warning },
    { value: "V", label: "Validada", severity: SEVERITIES.info },
    { value: "C", label: "Cancelada", severity: SEVERITIES.danger },
    { value: "F", label: "Finalizada", severity: SEVERITIES.success },
];

export const TIPOS_ENTREGA_PRODUTO = [
    { value: "E", label: "Entrega pela loja" },
    { value: "R", label: "Retirada pelo cliente" },
];

export const TIPO_ASSISTENCIA_TECNICA = [
    { value: "R", label: "Reposição de peça" },
    { value: "T", label: "Troca da mercadoria" },
    { value: "E", label: "Reparo especializado" },
    { value: "A", label: "Reparo autorizado" },
];

export const MOTIVO_ASSISTENCIA_TECNICA = [
    { value: "DF", label: "Defeito de fabricação" },
    { value: "DU", label: "Desgaste pelo uso" },
    { value: "DA", label: "Dano físico" },
];

export const SITUACAO_ASSISTENCIA_TECNICA = [
    { value: "A", label: "Aberta" },
    { value: "F", label: "Finalizada" },
    { value: "C", label: "Cancelada" },
];

export const TIPO_INDICADOR_DESTINATARIO_OCORRENCIA = [
    { value: 1, label: "Pessoa de origem" },
    { value: 2, label: "Pessoa de destino" },
];
