import React, { forwardRef, memo, useEffect, useRef, useState } from "react";
import { Dropdown } from "components/Dropdown";
import { InputNumber } from "primereact/inputnumber";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { useFormik } from "formik";
import { InputText } from "primereact/inputtext";
import { MakoCalendar } from "@/components/MakoCalendar";
import { dataToStr } from "@/assets/util/datas";
import { FINANCEIRO_OBRIGAORCAMENTO, FINANCEIRO_PAGAMENTO_EXIGEPREVISAO } from "@/assets/constants/parametros";
import usePagamento from "@/hooks/usePagamento";
import useParam from "@/hooks/useParam";
import classNames from "classnames";
import * as Yup from "yup";
import { MakoAutoComplete } from "@/components/MakoAutoComplete";
import useFormatCNPJCPF from "@/hooks/useFomatCNPJCPF";
import { FormikAutoSave } from "@/components/FormikAutoSave";
import useEmpresa from "@/hooks/useEmpresa";
import { MakoDropdownHistoricoPadrao } from "@/components/MakoInputs";

export const FinanceiroPagamentosForm = () => {
    const [formatarDocumento] = useFormatCNPJCPF();
    const [exigePrevisao, setExigePrevisao] = useState(0);
    const [obrigaOrcamento, setObrigaOrcamento] = useState(0);
    const { empresaSelecionadaId } = useEmpresa();
    const { handleSubmitPagamento, handleEditarPagamento, pagamento, pagamentos, submit } = usePagamento();
    const { getParam } = useParam();
    const toastRef = useRef(null);

    const { setFieldValue, setValues, ...formik } = useFormik({
        initialValues: {
            status: "novo",
            entrada: null,
            empresa: null,
            credor: null,
            competencia: null,
            historico_padrao: null,
            origem_fracionamento: "",
            data_emissao: new Date(),
            data_lancamento: new Date(),
            documento: "",
            forma_pagamento: null,
            plano_orcamentario: null,
            valor_total: null,
            conta_financeira: null,
            template_rateio: null,
            hasParcelas: true,
            isPrevisao: false,
            valor_parcela: 0,
            caixa: null,
        },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                historico_padrao: Yup.object()
                    .required("O campo 'histórico' é obrigatório.")
                    .typeError("Selecione um histórico."),
                credor: Yup.object()
                    .required("O campo 'credor' é obrigatório.")
                    .typeError("O campo 'credor' é obrigatório."),
                valor_total: Yup.number()
                    .required("O campo 'valor' é obrigatório.")
                    .typeError("O campo 'valor' é obrigatório."),
                documento: Yup.string().required("O campo 'documento' é obrigatório."),
                data_lancamento: Yup.date()
                    .required("O campo 'data de lançamento' é obrigatório.")
                    .min(Yup.ref("data_emissao"), "A data de lançamento não pode ser anterior à data de emissão")
                    .max(new Date(), "A data de lançamento não pode ser após a data atual."),
                data_emissao: Yup.date().required("O campo 'data da emissão' é obrigatório."),
                plano_orcamentario:
                    obrigaOrcamento && obrigaOrcamento.valor === "1" && exigePrevisao && exigePrevisao.valor === "0"
                        ? Yup.date().required("O campo 'plano orçamentário' é obrigatório.")
                        : null,
                conta_financeira: Yup.number().nullable().notRequired().default(null),
                hasParcelas: Yup.bool().default(false),
                vencimento: Yup.date().when("hasParcelas", {
                    is: true,
                    then: Yup.date(),
                    otherwise: Yup.date()
                        .required("O campo 'vencimento' é obrigatório.")
                        .typeError("O campo 'vencimento' é obrigatório")
                        .min(Yup.ref("data_emissao"), "A data de vencimento não pode ser anterior a data de emissão"),
                }),
                valor_parcela: Yup.number().when("hasParcelas", {
                    is: true,
                    then: Yup.number().nullable(),
                    otherwise: Yup.number()
                        .required("O campo 'valor da parcela' é obrigatório.")
                        .typeError("O campo 'valor da parcela' é obrigatório."),
                }),
                isPrevisao: Yup.boolean().default(false),
                caixa: Yup.number().nullable().notRequired().default(null),
            });
            await formSchema.validate(values, {
                abortEarly: false,
            });

            if (!pagamento?.id) {
                await handleSubmitPagamento({
                    ...values,
                    empresa: empresaSelecionadaId,
                    data_emissao: dataToStr(values.data_emissao, "yyyy-MM-dd"),
                    data_lancamento: dataToStr(values.data_lancamento, "yyyy-MM-dd"),
                    competencia: values.competencia?.id,
                });
            } else {
                const index = pagamentos?.findIndex((e) => e.id === pagamento.id);
                await handleEditarPagamento(values, index);
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            } else {
                toastRef.current.show({
                    severity: "error",
                    summary: "Erro",
                    detail: "Desculpe, não conseguimos processar a sua requisição.",
                    life: 1500,
                });
            }
        }
    }

    const autoCompleteCredorTemplate = (item) => {
        return <div>{`${item.nome} - ${formatarDocumento(item.identificacao)}`}</div>;
    };

    const handleCompetencia = (e) => {
        setFieldValue("competencia", e?.value);
    };

    const handleDataEmissao = (e) => {
        setFieldValue("data_emissao", e.value);
    };

    useEffect(() => {
        if (exigePrevisao?.valor === "1") setFieldValue("previsao", true);
        else setFieldValue("previsao", false);
    }, [exigePrevisao, setFieldValue]);

    useEffect(() => {
        setExigePrevisao(getParam(FINANCEIRO_PAGAMENTO_EXIGEPREVISAO));
        setObrigaOrcamento(getParam(FINANCEIRO_OBRIGAORCAMENTO));
    }, [getParam]);

    useEffect(() => {
        if (pagamento) setValues(pagamento);
    }, [pagamento, setValues]);

    return (
        <div className="p-grid">
            <div className="p-col-12">
                <div className="card">
                    <Toast ref={toastRef} />
                    <form onSubmit={formik.handleSubmit}>
                        <div className="p-fluid p-formgrid p-grid">
                            <div className="p-field p-col-12 p-md-6">
                                <label htmlFor="credor">Credor *</label>
                                <MakoAutoComplete
                                    id="credor"
                                    name="credor"
                                    value={formik.values.credor}
                                    onChange={formik.handleChange}
                                    minCaracteresBusca={4}
                                    itemTemplate={autoCompleteCredorTemplate}
                                    field="nome"
                                    disabled={formik.values.id}
                                    urlSearch={`/pessoas/perfis?query={id,nome,identificacao}&nome__unaccent__icontains=`}
                                    placeholder="Comece a digitar o nome do credor para buscar... (min 4 caracteres)"
                                    className={classNames({ "p-invalid": formik.errors.credor })}
                                />
                                {formik.errors.credor && <small className="p-error">{formik.errors.credor}</small>}
                            </div>
                            <div className="p-field p-col-12 p-md-3">
                                <label htmlFor="entrada">N.º Entrada</label>
                                <InputText
                                    id="entrada"
                                    name="entrada"
                                    value={formik.values.entrada}
                                    onChange={formik.handleChange}
                                    disabled
                                />
                            </div>
                            <div className="p-field p-col-12 p-md-3">
                                <label htmlFor="conta_financeira">Conta financeira</label>
                                <Dropdown
                                    id="conta_financeira"
                                    name="conta_financeira"
                                    url={`/financeiro/contas-financeiras/?query={id,descricao}&perfil=${empresaSelecionadaId}`}
                                    placeholder="Selecione uma conta financeira"
                                    emptyMessage="Não existem contas cadastradas."
                                    showClear
                                    optionValue="id"
                                    optionLabel="descricao"
                                    value={formik.values.conta_financeira}
                                    onChange={formik.handleChange}
                                />
                            </div>
                        </div>
                        <div className="p-fluid p-formgrid p-grid">
                            <div className="p-field p-col-12 p-md-3">
                                <label htmlFor="forma_pagamento">Forma de pagamento</label>
                                <Dropdown
                                    id="forma_pagamento"
                                    name="forma_pagamento"
                                    url="/financeiro/formas-recebimentos/?query={id,descricao}&ativo=true&limit=50"
                                    optionValue="id"
                                    optionLabel="descricao"
                                    value={formik.values.forma_pagamento}
                                    onChange={formik.handleChange}
                                />
                            </div>
                            <div className="p-field p-col-12 p-md-3">
                                <label htmlFor="documento">Documento *</label>
                                <InputText
                                    id="documento"
                                    name="documento"
                                    value={formik.values.documento}
                                    onChange={formik.handleChange}
                                    disabled={formik.values.id}
                                    className={classNames({ "p-invalid": formik.errors.documento })}
                                    autoComplete="off"
                                />
                                {formik.errors.documento && (
                                    <small className="p-error">{formik.errors.documento}</small>
                                )}
                            </div>
                            <div className="p-field p-col-12 p-md-6">
                                <label htmlFor="historico">Histórico *</label>
                                <MakoDropdownHistoricoPadrao
                                    filtros={{ tipo: "P" }}
                                    id="historico"
                                    name="historico_padrao"
                                    disabled={!!formik.values.id}
                                    value={formik.values.historico_padrao}
                                    handleChangeForm={formik.handleChange}
                                    setFieldValueForm={setFieldValue}
                                    inputTextProps={{
                                        value: formik.values.historico_original,
                                    }}
                                    className={classNames({ "p-invalid": formik.errors.historico_padrao })}
                                />
                                {formik.errors.historico_padrao && (
                                    <small className="p-error">{formik.errors.historico_padrao}</small>
                                )}
                            </div>
                        </div>
                        <div className="p-fluid p-formgrid p-grid">
                            <div className="p-field p-col-6 p-md-3">
                                <label htmlFor="valor_total">Valor bruto *</label>
                                <InputNumber
                                    id="valor_total"
                                    name="valor_total"
                                    mode="currency"
                                    currency="BRL"
                                    locale="pt-BR"
                                    disabled={formik.values.id}
                                    min={0}
                                    value={formik.values.valor_total}
                                    onValueChange={formik.handleChange}
                                    className={classNames({ "p-invalid": formik.errors.valor_total })}
                                />
                                {formik.errors.valor_total && (
                                    <small className="p-error">{formik.errors.valor_total}</small>
                                )}
                            </div>
                            <div className="p-field p-col-12 p-md-3">
                                <label htmlFor="competencia">Competência</label>
                                <Dropdown
                                    id="competencia"
                                    name="competencia"
                                    placeholder="Selecione uma competencia"
                                    url={"/financeiro/competencias?limit=1000"}
                                    filter
                                    filterBy="grupo_competencia"
                                    showClear
                                    optionLabel="grupo_competencia"
                                    emptyMessage="Não existem registros cadastrados"
                                    value={formik.values.competencia}
                                    onChange={handleCompetencia}
                                    className={classNames({ "p-invalid": formik.errors.competencia })}
                                />
                                {formik.errors.competencia && (
                                    <small className="p-error">{formik.errors.competencia}</small>
                                )}
                            </div>
                            <div className="p-field p-col-6 p-md-3">
                                <label htmlFor="data_lancamento">Data de lançamento *</label>
                                <MakoCalendar
                                    id="data_lancamento"
                                    name="data_lancamento"
                                    disabled={formik.values.id}
                                    valueCalendar={formik.values.data_lancamento}
                                    onChange={formik.handleChange}
                                    className={classNames({
                                        "p-invalid": formik.errors.data_lancamento,
                                    })}
                                />
                                {formik.errors.data_lancamento && (
                                    <small className="p-error">{formik.errors.data_lancamento}</small>
                                )}
                            </div>
                            <div className="p-field p-col-6 p-md-3">
                                <label htmlFor="data_emissao">Data de emissão *</label>
                                <MakoCalendar
                                    id="data_emissao"
                                    name="data_emissao"
                                    disabled={formik.values.id}
                                    minDate={formik.values.competencia?.data_inicio}
                                    maxDate={formik.values.competencia?.data_fim}
                                    valueCalendar={formik.values.data_emissao}
                                    onChange={handleDataEmissao}
                                    className={classNames({
                                        "p-invalid": formik.errors.data_emissao,
                                    })}
                                />
                                {formik.errors.data_emissao && (
                                    <small className="p-error">{formik.errors.data_emissao}</small>
                                )}
                            </div>
                        </div>
                        <div className="p-fluid p-formgrid p-grid">
                            <div className="p-field p-col-12 p-md-3">
                                <label htmlFor="template_rateio">Template de rateio *</label>
                                <Dropdown
                                    id="template_rateio"
                                    name="template_rateio"
                                    url={`/financeiro/templates-rateios/?query={id,descricao}`}
                                    disabled={formik.values.id}
                                    optionValue="id"
                                    optionLabel="descricao"
                                    emptyMessage="Não existem registros cadastrados"
                                    value={formik.values.template_rateio}
                                    onChange={formik.handleChange}
                                    className={classNames({ "p-invalid": formik.errors.template_rateio })}
                                />
                                {formik.errors.template_rateio && (
                                    <small className="p-error">{formik.errors.template_rateio}</small>
                                )}
                            </div>
                            <div className="p-field p-col-12 p-md-4">
                                <label htmlFor="plano_orcamentario">{`Plano Orçamentário ${
                                    obrigaOrcamento?.valor === "1" ? "*" : ""
                                }`}</label>
                                <Dropdown
                                    id="plano_orcamentario"
                                    name="plano_orcamentario"
                                    placeholder="Selecione um plano"
                                    url={"/financeiro/planos-orcamentarios?limit=1000"}
                                    filter
                                    filterBy="descricao"
                                    optionValue="id"
                                    optionLabel="descricao"
                                    emptyMessage="Não existem registros cadastrados"
                                    value={formik.values.plano_orcamentario}
                                    onChange={formik.handleChange}
                                    className={classNames({ "p-invalid": formik.errors.plano_orcamentario })}
                                />
                                {formik.errors.plano_orcamentario && (
                                    <small className="p-error">{formik.errors.plano_orcamentario}</small>
                                )}
                            </div>
                            <div className="p-field p-col-12 p-md-2">
                                <label htmlFor="origem_fracionamento">Origem fracionamento</label>
                                <InputText
                                    id="origem_fracionamento"
                                    name="origem_fracionamento"
                                    value={formik.values.origem_fracionamento}
                                    disabled
                                />
                            </div>
                            <div className="p-field p-col-12 p-md-3">
                                <label htmlFor="caixa">Caixa</label>
                                <Dropdown
                                    id="caixa"
                                    name="caixa"
                                    disabled={!!formik.values.id}
                                    url={`/financeiro/caixas/`}
                                    optionValue="id"
                                    optionLabel="nome"
                                    value={formik.values.caixa}
                                    onChange={formik.handleChange}
                                    className={classNames({ "p-invalid": formik.errors.caixa })}
                                />
                                {formik.errors.caixa && <small className="p-error">{formik.errors.caixa}</small>}
                            </div>
                            {/* <div className="p-field-checkbox p-col-12 p-md-2 p-mt-3">
                                <label htmlFor="hasParcelas">Há Parcelas?</label>
                                <MakoInputCheckBox
                                    id="hasParcelas"
                                    name="hasParcelas"
                                    checked={hasParcelas}
                                    onChange={handleHasParcelasCadastro}
                                />
                            </div> */}
                            {/* {
                                !hasParcelas
                                ?
                                    <>
                                        <div className="p-field p-col-12 p-md-3">
                                            <label htmlFor="vencimento">Data de vencimento *</label>
                                            <MakoCalendar
                                                id="vencimento"
                                                name="vencimento"
                                                disabled={formik.values.id}
                                                valueCalendar={formik.values.vencimento}
                                                onChange={formik.handleChange}
                                                className={classNames({
                                                    "p-invalid": formik.errors.vencimento,
                                                })}
                                            />
                                            {formik.errors.vencimento && (
                                                <small className="p-error">{formik.errors.vencimento}</small>
                                            )}
                                        </div>
                                        <div className="p-field p-col-12 p-md-3">
                                            <label htmlFor="valor_parela">Valor *</label>
                                            <InputNumber
                                                id="valor_parcela"
                                                name="valor_parcela"
                                                mode="currency"
                                                currency="BRL"
                                                locale="pt-BR"
                                                disabled={formik.values.id}
                                                min={0}
                                                value={formik.values.valor_parcela !== null ? formik.values.valor_parcela : formik.values.valor}
                                                onValueChange={formik.handleChange}
                                                className={classNames({ "p-invalid": formik.errors.valor_parcela })}
                                            />
                                            {formik.errors.valor_parcela && (
                                                <small className="p-error">{formik.errors.valor_parcela}</small>
                                            )}
                                        </div>
                                        <div className="p-field p-col p-md-3">
                                            <label htmlFor="previsao">É previsão?</label>
                                            <Dropdown
                                                options={OPTIONS_ISPREVISAO}
                                                optionLabel="label"
                                                optionValue="value"
                                                id="isPrevisao"
                                                name="isPrevisao"
                                                placeholder="Selecione uma opção"
                                                onChange={formik.handleChange}
                                                value={formik.values.isPrevisao}
                                            />
                                        </div>
                                    </>
                                :
                                <></>
                            } */}
                        </div>
                        <br />
                        <p>
                            <b>* Campos obrigatórios.</b>
                        </p>
                        {!submit ? (
                            <div className="p-grid p-col-12 p-md-12">
                                <Button
                                    icon="pi pi-save"
                                    label="Incluir dados básicos"
                                    type="submit"
                                    className="p-button-info"
                                />
                            </div>
                        ) : (
                            <FormikAutoSave intervalo={500} autosave={pagamento?.id} formik={formik} />
                        )}
                    </form>
                </div>
            </div>
        </div>
    );
};

export default memo(forwardRef(FinanceiroPagamentosForm));
