import React, { useCallback, useEffect, useImperativeHandle, useMemo, useState, forwardRef } from "react";
import classNames from "classnames";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { InputMask } from "primereact/inputmask";
import { Checkbox } from "primereact/checkbox";
import { Button } from "primereact/button";
import { TabView, TabPanel } from "primereact/tabview";
import { DataScroller } from "primereact/datascroller";
import { useFormik } from "formik";
import * as Yup from "yup";

import { MakoCalendar } from "@/components/MakoCalendar";
import { Dropdown } from "@/components/Dropdown";
import { BotaoDelete } from "@/components/BotaoDelete";
import { dataToStr } from "@/assets/util/datas";
import { PESSOAS_ENDERECOS_TIPOS_IDENTIFICACAO } from "@/assets/constants/parametros";
import { axiosGet, axiosPost, axiosPut, axiosPatch } from "@/services/http";
import useParam from "@/hooks/useParam";
import useToast from "@/hooks/useToast";
import useLoading from "@/hooks/useLoading";
import useAuth from "@/hooks/useAuth";

const Modal = ({ onSave }, ref) => {
    const [visible, setVisible] = useState(false);
    const [activeIndex, setActiveIndex] = useState(0);
    const [enderecos, setEnderecos] = useState([]);
    const [paises, setPaises] = useState([]);
    const [loadingCEP, setLoadingCEP] = useState(false);
    const [perfilId, setPerfilId] = useState(null);
    const { getParam } = useParam();
    const { showSuccess, showError } = useToast();
    const { user } = useAuth();
    const { showLoading, hideLoading } = useLoading();

    const { setValues, resetForm, ...formik } = useFormik({
        initialValues: {
            identificacao: "",
            pais: "BR",
            cep: "",
            logradouro: "",
            numero: "",
            complemento: "",
            bairro: "",
            estado: null,
            cidade: null,
            ponto_referencia: "",
            principal: false,
            data_alteracao: new Date(),
            ativo: true,
            tag_endereco: null,
        },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                identificacao: Yup.string().required("O campo 'identificação do endereço' é obrigatório."),
                cep: Yup.string().required("O campo 'cep' é obrigatório."),
                logradouro: Yup.string().required("O campo 'logradouro' é obrigatório."),
                numero: Yup.string().required("O campo 'numero' é obrigatório."),
                bairro: Yup.string().required("O campo 'bairro' é obrigatório."),
                pais: Yup.string().required("O campo 'país' é obrigatório.").typeError("Selecione um país."),
                estado: Yup.number().required("O campo 'estado' é obrigatório."),
                cidade: Yup.number().required("O campo 'municipio' é obrigatório."),
            });
            await formSchema.validate(values, { abortEarly: false });
            const endereco = {
                ...values,
                perfil: perfilId,
                pais: paises.find((p) => p.sigla === values.pais)?.id,
                data_alteracao: dataToStr(values.data_alteracao, "yyyy-MM-dd HH:mm:ss"),
                principal: enderecos.length === 0 ? true : values.principal,
            };
            if (!values.id) {
                showLoading();
                const { status } = await axiosPost("/pessoas/enderecos-perfis/", endereco);
                hideLoading();
                if (status === 201) {
                    showSuccess({
                        summary: "Sucesso",
                        detail: "Endereço cadastrado com sucesso!",
                        life: 1500,
                    });
                    setTimeout(() => {
                        finalizar();
                    }, 2000);
                } else {
                    showError({
                        summary: "Erro :(",
                        detail: "Desculpe, não conseguimos processar sua requisição.",
                        life: 3000,
                    });
                }
            } else {
                showLoading();
                const { status } = await axiosPut(`/pessoas/enderecos-perfis/${values.id}/`, endereco);
                hideLoading();
                if (status === 200) {
                    showSuccess({
                        summary: "Sucesso",
                        detail: "Endereço alterado com sucesso!",
                        life: 1500,
                    });
                    setTimeout(() => {
                        finalizar();
                    }, 2000);
                } else {
                    showError({
                        summary: "Erro :(",
                        detail: "Desculpe, não conseguimos processar sua requisição.",
                        life: 3000,
                    });
                }
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            }
        }
    }

    const finalizar = useCallback(() => {
        resetForm();
        setVisible(false);
        if (typeof onSave === "function") onSave();
    }, [onSave, resetForm]);

    const alterarEndereco = useCallback(
        async (id, data) => {
            showLoading();
            const { status } = await axiosPatch(`/pessoas/enderecos-perfis/${id}/`, data);
            hideLoading();
            if (status === 200) {
                showSuccess({
                    summary: "Sucesso",
                    detail: "Endereço alterado com sucesso!",
                    life: 1500,
                });
                setTimeout(() => {
                    finalizar();
                }, 2000);
            } else {
                showError({
                    summary: "Erro :(",
                    detail: "Desculpe, não conseguimos processar sua requisição.",
                    life: 3000,
                });
            }
        },
        [showLoading, hideLoading, showSuccess, showError, finalizar]
    );

    const abrirModal = (perfilId) => {
        if (perfilId) setPerfilId(perfilId);
        setVisible(true);
    };

    useImperativeHandle(ref, () => ({ abrirModal }));

    const buscarEnderecos = useCallback(async () => {
        if (perfilId) {
            const { status, data } = await axiosGet(`/pessoas/enderecos-perfis/?perfil__id=${perfilId}&limit=20`);
            if (status === 200) {
                setEnderecos(data.results);
            }
        }
    }, [perfilId]);

    useEffect(() => {
        buscarEnderecos();
    }, [buscarEnderecos]);

    const tiposEnderecos = useMemo(() => {
        const param = getParam(PESSOAS_ENDERECOS_TIPOS_IDENTIFICACAO);
        if (!param) return null;
        return param.valor.split("|").map((tipo) => ({ id: tipo, label: tipo }));
    }, [getParam]);

    const consultarCEP = async (value) => {
        if (formik.values.pais === "BR" && value.length > 0) {
            setLoadingCEP(true);
            const json = await axiosGet(`/pessoas/consultar-cep/${value}/`);
            setLoadingCEP(false);
            if (json.status === 200) {
                const { logradouro, bairro, municipio, estado } = json.data;
                formik.setFieldValue("estado", estado.id);
                formik.setFieldValue("cidade", municipio.id);
                formik.setFieldValue("logradouro", logradouro);
                formik.setFieldValue("bairro", bairro);
            }
        }
    };

    const itemTemplate = useCallback(
        (data) => {
            return (
                <div className="product-list-item">
                    <div className="product-list-detail">
                        <span className={`product-badge status-${data.principal ? "instock" : "lowstock"}`}>
                            {data.principal ? "Principal" : "Alternativo"}
                        </span>
                        <div className="product-name">{`${data.logradouro}, ${data.numero} - ${data.bairro}`}</div>
                        <div className="product-description">{`${data.cidade.nome} / ${data.cidade.estado.uf} - ${data.cep} - ${data.cidade.estado.pais.nome}`}</div>
                        <i className="pi pi-tag product-category-icon" />
                        <span className="product-category">{data.identificacao}</span>
                        {data.tag_endereco?.tag ? (
                            <span className="product-category">
                                {" "}
                                | <i className="pi pi-tags product-category-icon" />
                                {data.tag_endereco?.tag}
                            </span>
                        ) : null}
                    </div>
                    <div className="product-list-action">
                        <div className="p-text-right">
                            <Button
                                icon="pi pi-pencil"
                                className="p-button-rounded p-button-warning p-mr-2"
                                tooltip="Editar endereço"
                                tooltipOptions={{ position: "bottom" }}
                                onClick={() => {
                                    setValues({
                                        ...data,
                                        cidade: data.cidade.id,
                                        estado: data.cidade.estado.id,
                                        pais: data.cidade.estado.pais.sigla,
                                        tag_endereco: data.tag_endereco?.id,
                                    });
                                    setActiveIndex(0);
                                }}
                            />
                            <Button
                                icon="pi pi-check"
                                className="p-button-rounded p-button-success p-mr-2"
                                disabled={!!!data.id}
                                tooltip="Checar endereço"
                                tooltipOptions={{ position: "bottom" }}
                                onClick={() =>
                                    alterarEndereco(data.id, {
                                        data_alteracao: dataToStr(new Date(), "yyyy-MM-dd HH:mm:ss"),
                                        usuario_alteracao: user.id,
                                        ativo: true,
                                    })
                                }
                            />
                            <Button
                                icon="pi pi-star-fill"
                                className="p-button-rounded p-button-help"
                                tooltip="Definir como prinicipal"
                                tooltipOptions={{ position: "bottom" }}
                                onClick={() => alterarEndereco(data.id, { principal: true })}
                            />
                        </div>
                        <div className="p-text-right">
                            <Button
                                icon="pi pi-ban"
                                className={`p-button-rounded p-button-${data.ativo ? "secondary" : "info"} p-mr-2`}
                                tooltip={data.ativo ? "Desativar endereço" : "Ativar endereço"}
                                tooltipOptions={{ position: "bottom" }}
                                onClick={() => alterarEndereco(data.id, { ativo: !data.ativo })}
                            />
                            <BotaoDelete
                                url="/pessoas/enderecos-perfis/"
                                objetoId={data.id}
                                exigeConfirmacao
                                msgConfirmacao={<span>Deseja realmente excluir esse endereço?</span>}
                                msgToastErroExclusao="O endereço não pode ser excluído."
                                onDelete={onSave}
                                tooltip="Excluir endereço"
                                tooltipOptions={{ position: "bottom" }}
                            />
                        </div>
                    </div>
                </div>
            );
        },
        [setValues, alterarEndereco, user, onSave]
    );

    return (
        <Dialog
            header="Endereços"
            visible={visible}
            onHide={() => setVisible(false)}
            breakpoints={{ "960px": "75vw" }}
            style={{ width: "80vw", display: "block" }}
            blockScroll
        >
            <TabView activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
                <TabPanel header="Formulário" leftIcon="pi pi-fw pi-align-left">
                    <form onSubmit={formik.handleSubmit}>
                        <div className="p-fluid p-formgrid p-grid">
                            <div className="p-field p-col-12 p-md-6">
                                <label htmlFor="identificacao">Identificação do endereço *</label>
                                {tiposEnderecos ? (
                                    <Dropdown
                                        id="identificacao"
                                        name="identificacao"
                                        options={tiposEnderecos}
                                        optionValue="id"
                                        optionLabel="label"
                                        value={formik.values.identificacao}
                                        onChange={formik.handleChange}
                                        placeholder="Selecione..."
                                        className={classNames({ "p-invalid": formik.errors.identificacao })}
                                    />
                                ) : (
                                    <InputText
                                        id="identificacao"
                                        name="identificacao"
                                        value={formik.values.identificacao}
                                        onChange={formik.handleChange}
                                        placeholder="Sugestão: Residencial | Profissional"
                                        className={classNames({ "p-invalid": formik.errors.identificacao })}
                                    />
                                )}
                                {formik.errors.identificacao && (
                                    <small className="p-error">{formik.errors.identificacao}</small>
                                )}
                            </div>
                            <div className="p-field p-col-12 p-md-6">
                                <label htmlFor="pais">País *</label>
                                <Dropdown
                                    id="pais"
                                    name="pais"
                                    url="/pessoas/paises?limit=300"
                                    setObjects={setPaises}
                                    optionLabel="nome"
                                    optionValue="sigla"
                                    filter
                                    filterBy="nome"
                                    value={formik.values.pais}
                                    onChange={formik.handleChange}
                                    className={classNames({ "p-invalid": formik.errors.pais })}
                                />
                                {formik.errors.pais && <small className="p-error">{formik.errors.pais}</small>}
                            </div>
                        </div>
                        <div className="p-fluid p-formgrid p-grid">
                            <div className="p-field p-col-12 p-md-3">
                                <label htmlFor="cep">CEP *</label>
                                <span className="p-input-icon-right">
                                    {loadingCEP && <i className="pi pi-spin pi-spinner" />}
                                    {formik.values.pais === "BR" ? (
                                        <InputMask
                                            id="cep"
                                            name="cep"
                                            disabled={!!!formik.values.pais}
                                            value={formik.values.cep}
                                            onChange={formik.handleChange}
                                            onComplete={(e) => consultarCEP(e.value)}
                                            mask="99.999-999"
                                            className={classNames({ "p-invalid": formik.errors.cep })}
                                        />
                                    ) : (
                                        <InputText
                                            id="cep"
                                            name="cep"
                                            disabled={!!!formik.values.pais}
                                            value={formik.values.cep}
                                            onChange={formik.handleChange}
                                            className={classNames({ "p-invalid": formik.errors.cep })}
                                        />
                                    )}
                                    {formik.errors.cep && <small className="p-error">{formik.errors.cep}</small>}
                                </span>
                            </div>
                            <div className="p-field p-col-12 p-md-3">
                                <label htmlFor="estado">Estado / Província *</label>
                                <Dropdown
                                    id="estado"
                                    name="estado"
                                    // options={estados}
                                    url={`/pessoas/estados?query={id,nome,uf,codigo_uf}&pais__sigla=${formik.values.pais}&limit=100`}
                                    buscar={!!formik.values.pais}
                                    // disabled={loadingEstados}
                                    // placeholder={!loadingEstados ? "Selecione" : "Buscando..."}
                                    optionLabel="nome"
                                    optionValue="id"
                                    filter
                                    showClear
                                    filterBy="nome"
                                    value={formik.values.estado}
                                    onChange={formik.handleChange}
                                    className={classNames({
                                        "p-invalid": formik.errors.estado,
                                    })}
                                />
                                {formik.errors.estado && <small className="p-error">{formik.errors.estado}</small>}
                            </div>
                            <div className="p-field p-col-12 p-md-6">
                                <label htmlFor="cidade">Município *</label>
                                <Dropdown
                                    id="cidade"
                                    name="cidade"
                                    url={`/pessoas/cidades/?estado=${formik.values.estado}&query={id,nome}&limit=1000&ordering=nome`}
                                    buscar={!!formik.values.estado}
                                    optionLabel="nome"
                                    optionValue="id"
                                    filter
                                    showClear
                                    filterBy="nome"
                                    value={formik.values.cidade}
                                    onChange={formik.handleChange}
                                    className={classNames({ "p-invalid": formik.errors.cidade })}
                                />
                                {formik.errors.cidade && <small className="p-error">{formik.errors.cidade}</small>}
                            </div>
                        </div>
                        <div className="p-fluid p-formgrid p-grid">
                            <div className="p-field p-col-12 p-md-6">
                                <label htmlFor="logradouro">Logradouro *</label>
                                <InputText
                                    id="logradouro"
                                    name="logradouro"
                                    value={formik.values.logradouro}
                                    onChange={formik.handleChange}
                                    className={classNames({ "p-invalid": formik.errors.logradouro })}
                                />
                                {formik.errors.logradouro && (
                                    <small className="p-error">{formik.errors.logradouro}</small>
                                )}
                            </div>
                            <div className="p-field p-col-12 p-md-2">
                                <label htmlFor="numero">Numero *</label>
                                <InputText
                                    id="numero"
                                    name="numero"
                                    value={formik.values.numero}
                                    onChange={formik.handleChange}
                                    className={classNames({ "p-invalid": formik.errors.numero })}
                                />
                                {formik.errors.numero && <small className="p-error">{formik.errors.numero}</small>}
                            </div>
                            <div className="p-field p-col-12 p-md-2">
                                <label htmlFor="complemento">Complemento</label>
                                <InputText
                                    id="complemento"
                                    name="complemento"
                                    value={formik.values.complemento}
                                    onChange={formik.handleChange}
                                />
                            </div>
                            <div className="p-field p-col-12 p-md-2">
                                <label htmlFor="bairro">Bairro *</label>
                                <InputText
                                    id="bairro"
                                    name="bairro"
                                    value={formik.values.bairro}
                                    onChange={formik.handleChange}
                                    className={classNames({ "p-invalid": formik.errors.bairro })}
                                />
                                {formik.errors.bairro && <small className="p-error">{formik.errors.bairro}</small>}
                            </div>
                        </div>
                        <div className="p-fluid p-formgrid p-grid">
                            <div className="p-field p-col-12 p-md-6">
                                <label htmlFor="ref">Ponto de referência</label>
                                <InputText
                                    id="ref"
                                    name="ponto_referencia"
                                    value={formik.values.ponto_referencia}
                                    onChange={formik.handleChange}
                                />
                            </div>
                            <div className="p-field p-col-12 p-md-3">
                                <label htmlFor="tag_endereco">Tag</label>
                                <Dropdown
                                    id="tag_endereco"
                                    name="tag_endereco"
                                    url="/pessoas/tags-enderecos-perfis?limit=300"
                                    optionLabel="tag"
                                    optionValue="id"
                                    filter
                                    filterBy="nome"
                                    showClear
                                    value={formik.values.tag_endereco}
                                    onChange={formik.handleChange}
                                />
                            </div>
                            <div className="p-field p-col-12 p-md-3">
                                <label htmlFor="data-atualizacao">Última atualização</label>
                                <MakoCalendar
                                    id="data-atualizacao"
                                    name="data_alteracao"
                                    disabled
                                    showIcon={false}
                                    valueCalendar={formik.values.data_alteracao}
                                />
                            </div>
                        </div>
                        {enderecos.length > 0 && (
                            <div className="p-fluid p-formgrid p-grid p-mb-2">
                                <div className="p-field-checkbox p-col-12 p-md-3 p-mt-2">
                                    <Checkbox
                                        id="principal"
                                        name="principal"
                                        checked={formik.values.principal}
                                        onChange={formik.handleChange}
                                    />
                                    <label htmlFor="principal">Endereço principal?</label>
                                </div>
                            </div>
                        )}
                        <p>
                            <b>* Campos obrigatórios</b>
                        </p>
                        <div className="p-grid">
                            <div className="p-col-12 p-md-6">
                                <Button type="submit" icon="pi pi-save" label="Gravar" className="p-mr-2" />
                                <Button
                                    type="reset"
                                    icon="pi pi-times"
                                    label="Cancelar"
                                    className="p-button-danger p-mr-2"
                                    onClick={finalizar}
                                />
                            </div>
                        </div>
                    </form>
                </TabPanel>
                <TabPanel header="Endereços" leftIcon="pi pi-fw pi-map-marker">
                    <div className="list-demo">
                        <DataScroller
                            value={enderecos}
                            itemTemplate={itemTemplate}
                            rows={3}
                            inline
                            scrollHeight="300px"
                            header="Endereços cadastrados"
                            emptyMessage="Nenhum endereço encontrado"
                        />
                    </div>
                </TabPanel>
            </TabView>
        </Dialog>
    );
};

export const ModalEnderecos = forwardRef(Modal);
