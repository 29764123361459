import React, { useCallback, useEffect, useRef, useState } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import MakoListagem from "@/components/MakoListagem";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import { axiosGet, axiosPost } from "@/services/http";
import permissoes from "@/assets/constants/permissoes";
import useVenda from "@/hooks/useVenda";
import { Dropdown } from "@/components/Dropdown";
import useToast from "@/hooks/useToast";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { OpcoesFaturamento } from "@/components/MakoOpcoesFaturamento";
import useEmpresa from "@/hooks/useEmpresa";

export const DocFiscalForm = () => {
    const [exibirItens, setExibirItens] = useState(false);
    const [itensSelecionados, setItensSelecionados] = useState([]);
    const [listaItens, setListaItens] = useState([]);
    const [listaOperacoes, setListaOperacoes] = useState([]);
    const [opcaoFaturamento, setOpcaoFaturamento] = useState(null);
    const modeloNfRef = useRef(null);
    const opcoesRef = useRef(null);
    const { dadosBasicos, parcelasVenda } = useVenda();
    const { empresaSelecionada, empresaSelecionadaId } = useEmpresa();
    const { showSuccess, showWarning, showError } = useToast();
    const history = useHistory();
    const listagemRef = useRef();

    async function enviarNota() {
        try {
            if ((await empresaSelecionada?.enderecoperfil_set?.length) > 0) {
                const { cidade } = await empresaSelecionada?.enderecoperfil_set[0];
                const body = {
                    modelo: modeloNfRef.current,
                    venda: dadosBasicos.id,
                    operacao_fiscal: itensSelecionados[0].operacao_fiscal,
                    natureza_operacao: null,
                    transferencia: null,
                    evento_id: null,
                    tipo_operacao: 1,
                    app_label: "vendas",
                    model: "venda",
                    dados_basicos: {
                        codigo_uf: cidade.estado?.codigo_uf,
                        emitente: dadosBasicos.empresa.id,
                        destinatario: dadosBasicos.cliente.id || null,
                        requer_identificacao: dadosBasicos.cliente?.id ? true : false,
                        indicador_pagamento:
                            parcelasVenda?.lenght > 0 ? (parcelasVenda[0].quantidade_parcelas === 1 ? 1 : 2) : 1,
                        municipio_ocorrencia: cidade.id,
                        transportador: null,
                        indicador_final: 1,
                        formato_impressao: 0,
                        nota_referencia: [],
                        info_adicionais: "",
                    },
                    itens: itensSelecionados.map((item) => {
                        return {
                            ...item,
                            unidade: item.unidade_venda.sigla,
                            sku: item.sku.id,
                            valor_total: item.valor_unitario * item.quantidade_nf,
                            valor_desconto: item.valor_desconto_unitario,
                            valor_frete: 0,
                            valor_seguro: 0,
                            quantidade: item.quantidade_nf,
                            outras_despesas: 0,
                            municipio_issqn: cidade.id,
                            municipio_incidencia: cidade.id,
                            app_label: "vendas",
                            model: "itemfiscalitemvenda",
                        };
                    }),
                    parcelas: parcelasVenda,
                };

                const resposta = await axiosPost("/fiscal/inserir-nota-fiscal/", body);

                if (resposta.status === 200) {
                    listagemRef.current?.buscarDados();
                    showSuccess({
                        summary: "Sucesso!",
                        detail: "Nota fiscal incluida com sucesso!",
                        life: 3000,
                    });
                    setExibirItens(false);
                } else if (resposta.status === 400) {
                    showWarning({
                        summary: "Falha ao inserir",
                        detail: `Não foi possível inserir a nota fiscal, verifique as informações da operação fiscal e tente novamente (${resposta.data.erro}.)`,
                        life: 5000,
                    });
                } else {
                    showError({
                        summary: "Erro",
                        detail: "Desculpe, não foi possível inserir a nota fiscal.",
                        life: 3000,
                    });
                }
            }
        } catch (error) {
            showWarning({
                summary: "Cadastro Incompleto!",
                detail: "A empresa atual não possui todas as informações registradas, verifique o cadastro e tente novamente.",
                life: 5000,
            });
        }
    }

    const sugerirOperacoesNF = useCallback(async () => {
        const resposta = await axiosPost(`/fiscal/sugerir-operacoes-fiscais/${empresaSelecionadaId}/`, {
            itens: listaItens,
            devolucao: false,
        });

        if (resposta.status === 200) {
            let novaLista = listaItens;
            await resposta.data.operacoes?.forEach((op, index) => {
                novaLista[index]["operacao_fiscal"] = op;
            });
            setItensSelecionados(novaLista);
            setListaItens(novaLista);
        }
    }, [listaItens, empresaSelecionadaId]);

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <MakoControleAcesso
                    permissao={[permissoes.VENDAS_VENDA_DOCFISCALVENDA_CONSULTAR]}
                    componente={Button}
                    icon="pi pi-pencil"
                    className="p-button-rounded p-button-warning p-mr-2 p-mb-1"
                    tooltip="Editar"
                    tooltipOptions={{ position: "left" }}
                    disabled={rowData.status === "C" || rowData.status === "T"}
                    onClick={() =>
                        history.push({
                            pathname: "/fiscal/nota-saida/emissao-nota",
                            state: { notaFiscal: rowData },
                        })
                    }
                />
            </div>
        );
    };

    const verificaEmissao = (chave) => {
        if (chave) return chave;
        else return "NÃO TRANSMITIDO";
    };

    const verificaProtocolo = (protocolo) => {
        if (protocolo) return protocolo;
        else return "-";
    };

    const colunasNota = [
        {
            field: "numero",
            header: "Número",
            filter: true,
            style: { minWidth: "50px" },
        },
        {
            field: "serie",
            header: "Série",
            filter: true,
            style: { minWidth: "50px" },
        },
        {
            field: "modelo",
            header: "Modelo",
            filter: true,
            style: { minWidth: "50px" },
        },
        {
            field: "chave_nf",
            header: "Chave",
            action: (e) => verificaEmissao(e.chave_nf),
            filter: true,
            align: "left",
            style: { minWidth: "400px" },
        },
        {
            field: "protocolo",
            header: "Protocolo",
            action: (e) => verificaProtocolo(e.protocolo),
            filter: true,
            style: { minWidth: "200px" },
        },
        {
            field: "valor_total_nf",
            header: "Total Nf",
            filter: true,
            align: "right",
            style: { minWidth: "120px" },
        },
        {
            field: "action",
            header: "Ações",
            action: (e) => actionBodyTemplate(e),
            style: { width: "15%" },
        },
    ];

    const editarQuantidade = (options, quantidade, aux) => {
        options.editorCallback(quantidade);
        setListaItens(
            listaItens.map((item) => {
                if (item.aux_quantidade === aux) item.quantidade_nf = quantidade;
                return item;
            })
        );
    };

    const textEditor = (options) => {
        return (
            <InputText
                type="text"
                value={options.value}
                onChange={(e) => editarQuantidade(options, e.target.value, options.rowData.aux_quantidade)}
            />
        );
    };

    const editarOperacao = (options, operacao, aux) => {
        options.editorCallback(operacao);
        setListaItens(
            listaItens.map((item) => {
                if (item.id === aux) item.operacao_fiscal = operacao;
                return item;
            })
        );
    };

    const operacaoEditor = (options) => {
        return (
            <Dropdown
                id="operacao_fiscal"
                name="operacao_fiscal"
                options={listaOperacoes}
                optionValue="id"
                optionLabel="descricao"
                style={{ width: "100%" }}
                value={options.value}
                onChange={(e) => editarOperacao(options, e.target.value, options.rowData.id)}
            />
        );
    };

    const colunasItens = [
        { selectionMode: "multiple", headerStyle: { width: "3em" } },
        { field: "sku.codigo", header: "Código", style: { minWidth: "80px" } },
        { field: "sku.descricao_reduzida", header: "Item", style: { minWidth: "250px" } },
        { field: "sku.item.ncm.codigo", header: "NCM" },
        {
            field: "quantidade",
            header: "Quant",
            style: { minWidth: "100px" },
            decimal: true,
        },
        {
            field: "quantidade_nf",
            header: "Qtd. NF",
            decimal: true,
            style: { minWidth: "100px" },
            editor: (options) => textEditor(options),
        },
        {
            field: "operacao_fiscal",
            header: "Operação Fiscal",
            decimal: true,
            style: { minWidth: "300px" },
            editor: (options) => operacaoEditor(options),
            action: ({ operacao_fiscal }) => (
                <Dropdown
                    id="operacao_fiscal"
                    name="operacao_fiscal"
                    options={listaOperacoes}
                    optionValue="id"
                    optionLabel="descricao"
                    style={{ width: "100%" }}
                    value={operacao_fiscal}
                />
            ),
        },
    ];

    const listarItensVenda = useCallback(async () => {
        const resposta = await axiosGet(`/vendas/itens-vendas/?venda=${dadosBasicos?.id}`);
        if (resposta.status === 200)
            setListaItens(
                resposta.data.results.map((item, index) => ({
                    ...item,
                    aux_quantidade: index + 1,
                    quantidade_nf: item.quantidade,
                }))
            );
    }, [dadosBasicos]);

    const carregaOpcaoFaturamento = useCallback(async () => {
        const resposta = await axiosGet(`/fiscal/opcoes-faturamento?limit=1&empresa=${empresaSelecionadaId}`);
        if (resposta.status === 200)
            if (resposta.data.results?.length > 0) {
                setOpcaoFaturamento(resposta.data.results[0]);
            }
    }, [empresaSelecionadaId]);

    useEffect(() => {
        carregaOpcaoFaturamento();
    }, [carregaOpcaoFaturamento]);

    const listarOperacoes = useCallback(async () => {
        if (!empresaSelecionadaId) return;

        const params = {
            empresa: empresaSelecionadaId,
            tipo_movimento: "S",
            limit: 1000,
            ativo: true,
        };

        const { status, data } = await axiosGet("/fiscal/operacoes-fiscais/", { params });

        if (status === 200) {
            const { enderecoperfil_set = [] } = empresaSelecionada;
            const enderecoEmpresa = enderecoperfil_set?.find(({ principal }) => !!principal);
            const enderecoCliente = dadosBasicos.cliente?.enderecoperfil_set?.find(({ principal }) => !!principal);

            if (enderecoEmpresa?.lenght > 0 && enderecoCliente?.lenght > 0) {
                setListaOperacoes(
                    data.results
                        .filter(({ esfera }) =>
                            esfera === "D"
                                ? enderecoEmpresa[0].cidade.estado.id === enderecoCliente[0].cidade.estado.id
                                : enderecoEmpresa[0].cidade.estado.id !== enderecoCliente[0].cidade.estado.id
                        )
                        .map(({ operacao }) => operacao)
                );
            } else {
                setListaOperacoes(data.results);
            }
        }
    }, [empresaSelecionadaId, empresaSelecionada, dadosBasicos.cliente.enderecoperfil_set]);

    useEffect(() => {
        listarOperacoes();
        listarItensVenda();
    }, [listarItensVenda, listarOperacoes]);

    const aposSalvarPadrao = () => {
        carregaOpcaoFaturamento();
        showSuccess({
            summary: "Sucesso!",
            detail: "Padrão de opções de faturamento alterado com sucesso.",
            life: 3000,
        });
    };

    const aposSalvarContexto = useCallback(
        async (opcao) => {
            setOpcaoFaturamento(opcao);
            showSuccess({
                summary: "Sucesso!",
                detail: "Opções de faturamento salvas com sucesso.",
                life: 3000,
            });
        },
        [showSuccess]
    );

    const rowClassNF = (rowData) => {
        return {
            "mako-table-status-inativo": rowData.status === "C",
            "table-recebimentos-effective": rowData.status === "T",
        };
    };

    return (
        <>
            <MakoControleAcesso
                permissao={[permissoes.VENDAS_VENDA_DOCFISCALVENDA_INCLUIR]}
                componente={Button}
                type="button"
                label="Gerar Documento Fiscal"
                disabled={!opcaoFaturamento || !dadosBasicos}
                onClick={() => {
                    setExibirItens(true);
                    modeloNfRef.current =
                        opcaoFaturamento?.modelo_documento === "QQ"
                            ? dadosBasicos?.cliente?.tipo_pessoa === "PF"
                                ? "65"
                                : "55"
                            : opcaoFaturamento?.modelo_documento === "NFC"
                            ? "65"
                            : "55";
                }}
                className="p-mr-2"
            />
            <MakoControleAcesso
                permissao={[permissoes.FISCAL_FISCAL_OPCOESFATURAMENTO_EDITAR]}
                componente={Button}
                type="button"
                label="Opções de Faturamento"
                className="p-mr-2 p-button-help"
                onClick={async () => {
                    await opcoesRef.current?.abrirModal(opcaoFaturamento);
                }}
            />
            <MakoListagem
                ref={listagemRef}
                titulo="Documentos fiscais da venda"
                urlPesquisa={`/fiscal/notas-fiscais?venda=${dadosBasicos ? dadosBasicos.id : null}`}
                colunas={colunasNota}
                configTabela={{
                    lazy: true,
                    paginator: true,
                    rowClassName: rowClassNF,
                }}
            />
            <Dialog
                header={"Selecionando itens para emissão do documento fiscal"}
                visible={exibirItens}
                breakpoints={{ "960px": "75vw" }}
                style={{ width: "70vw", display: "block" }}
                onHide={() => setExibirItens(false)}
            >
                <MakoListagem
                    titulo="Itens do Orçamento"
                    colunas={colunasItens}
                    dadosLocal={listaItens}
                    configTabela={{
                        editMode: "cell",
                        selectionMode: "multiple",
                        selection: itensSelecionados,
                        onSelectionChange: (e) => setItensSelecionados(e.value),
                    }}
                />
                <Button
                    type="button"
                    icon="pi pi-plus"
                    label="Gerar nota"
                    className="p-mt-4"
                    disabled={!itensSelecionados}
                    onClick={() => enviarNota()}
                />
                <Button
                    type="button"
                    icon="pi pi-question"
                    label="Sugerir operações"
                    className="p-mt-4 p-ml-2 p-button-help"
                    onClick={() => sugerirOperacoesNF()}
                />
            </Dialog>
            <OpcoesFaturamento venda ref={opcoesRef} onSuccess={aposSalvarPadrao} onContext={aposSalvarContexto} />
        </>
    );
};
