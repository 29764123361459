import React, { useImperativeHandle, useState, forwardRef, useCallback } from "react";

import { classNames } from "primereact/utils";
import { useFormik } from "formik";
import * as Yup from "yup";

import { InputText } from "primereact/inputtext";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";

import {
    MakoDropdownCompetenciaFinanceira,
    MakoDropdownHistoricoPadrao,
    MakoInputPerfil,
} from "@/components/MakoInputs";
import { MakoActionsButtons } from "@/components/MakoActionsButtons";
import { MakoInputMoeda } from "@/components/MakoInputMoeda";
import { MakoCalendar } from "@/components/MakoCalendar";
import { Dropdown } from "@/components/Dropdown";
import { Label } from "@/components/Label";

import { dataToStr, parseData } from "@/assets/util/datas";

import useDevolucaoFornecedor from "@/hooks/useDevolucaoFornecedor";
import useGetNumeroParcela from "../hooks/useGetNumeroParcela";
import useToast from "@/hooks/useToast";
import useHttp from "@/hooks/useHttp";

const INITIAL_VALUES = {
    valor_credito: null,
    valor_abatido: 0,
    numero_parcela: 1,
    forma_recebimento: null,
    data_vencimento: null,
    competencia: null,
    plano_orcamentario: null,
    historico_padrao: null,
    template_rateio: null,
    credor: null,
    frete: false,
};

const Modal = ({ aposSalvar = () => {} }, ref) => {
    const [visible, setVisible] = useState(false);
    const { dadosBasicos, handleParcelas } = useDevolucaoFornecedor();
    const { showError, showSuccess } = useToast();
    const { httpGet } = useHttp();

    const fetchNumero = useGetNumeroParcela(dadosBasicos?.id);

    const { setFieldValue, setValues, resetForm, ...formik } = useFormik({
        initialValues: {
            ...INITIAL_VALUES,
            valor_credito: dadosBasicos?.valor_total,
        },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                historico_padrao: Yup.object()
                    .required("O campo 'histórico' é obrigatório.")
                    .typeError("Selecione um histórico."),
                template_rateio: Yup.number()
                    .required("O campo 'regra de rateio' é obrigatório.")
                    .typeError("O campo 'regra de rateio' é obrigatório."),
                valor_credito: Yup.number()
                    .required("O campo 'valor' é obrigatório.")
                    .typeError("O campo 'valor' é obrigatório."),
                forma_recebimento: Yup.number()
                    .required("O campo 'forma de pagamento' é obrigatório.")
                    .typeError("O campo 'forma de pagamento' é obrigatório."),
                data_vencimento: Yup.date()
                    .required("O campo 'data de vencimento' é obrigatório.")
                    .typeError("O campo 'data de vencimento' é obrigatório."),
                competencia: Yup.number()
                    .required("O campo 'competência' é obrigatório.")
                    .typeError("Informe uma 'competência' válida."),
            });

            await formSchema.validate(values, {
                abortEarly: false,
            });

            const { status } = await handleParcelas({
                ...values,
                historico_padrao: values.historico_padrao.id,
                data_vencimento: dataToStr(values.data_vencimento, "yyyy-MM-dd"),
            });

            if (status !== 200 && status !== 201) showError();
            else {
                showSuccess({
                    summary: "Sucesso!",
                    detail: "O item foi cadastrado com sucesso!",
                    life: 3000,
                });
                aposSalvar();
                resetForm();
                fecharModal();
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            } else showError();
        }
    }

    const buscarPerfil = useCallback(
        async (id) => {
            let perfil = {};
            const handlers = {
                200: ({ data }) => {
                    perfil = data;
                },
            };

            await httpGet(
                {
                    url: `/pessoas/perfis/${id}/?query={id,nome,identificacao}`,
                },
                handlers
            );

            return perfil;
        },
        [httpGet]
    );

    const abrirModal = useCallback(
        async (frete = false) => {
            const numero = await fetchNumero();
            setFieldValue("numero_parcela", numero);

            if (frete) {
                const { valor_frete, pagante_frete, fornecedor } = dadosBasicos || {};
                let { empresa } = dadosBasicos;

                if (pagante_frete === 0) empresa = await buscarPerfil(empresa);
                const credor = {
                    0: empresa,
                    1: fornecedor,
                    2: null,
                };
                setFieldValue("credor", credor?.[pagante_frete] || null);
                setFieldValue("frete", true);
                setFieldValue("valor_credito", valor_frete);
            } else {
                setFieldValue("credor", null);
                setFieldValue("frete", false);
            }
            setVisible(true);
        },
        [dadosBasicos, setFieldValue, fetchNumero, buscarPerfil]
    );

    const abrirModalEdicao = useCallback(
        (data) => {
            resetForm({ values: { ...INITIAL_VALUES, ...data } });
            setVisible(true);
        },
        [resetForm]
    );

    const fecharModal = () => {
        setVisible(false);
    };

    useImperativeHandle(ref, () => ({ abrirModal, abrirModalEdicao }), [abrirModal, abrirModalEdicao]);

    return (
        <Dialog
            visible={visible}
            header="Incluindo parcela a receber de devolução"
            style={{ width: "60vw" }}
            breakpoints={{ "960px": "75vw" }}
            onHide={fecharModal}
        >
            <form onSubmit={formik.handleSubmit}>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-2">
                        <Label htmlFor="numero_parcela" label="Parcela" />
                        <InputText
                            id="numero_parcela"
                            name="numero_parcela"
                            value={formik.values.numero_parcela}
                            disabled
                        />
                    </div>
                    <div className="p-field p-col-12 p-md-4">
                        <Label htmlFor="competencia" label="Competência" obrigatorio />
                        <MakoDropdownCompetenciaFinanceira
                            id="competencia"
                            name="competencia"
                            habilitarInativas
                            value={formik.values.competencia}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.competencia })}
                        />
                        {formik.errors.competencia && <small className="p-error">{formik.errors.competencia}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-4">
                        <Label htmlFor="forma_recebimento" label="Forma de pagamento" obrigatorio />
                        <Dropdown
                            id="forma_recebimento"
                            name="forma_recebimento"
                            url="/financeiro/formas-recebimentos/?query={id,descricao}&ativo=true&limit=50"
                            optionValue="id"
                            optionLabel="descricao"
                            value={formik.values.forma_recebimento}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.forma_recebimento })}
                        />
                        {formik.errors.forma_recebimento && (
                            <small className="p-error">{formik.errors.forma_recebimento}</small>
                        )}
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <Label htmlFor="valor_credito" label="Valor da parcela" obrigatorio />
                        <MakoInputMoeda
                            id="valor_credito"
                            name="valor_credito"
                            valueMoeda={formik.values.valor_credito}
                            onChangeMoeda={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.valor_credito })}
                        />
                        {formik.errors.valor_credito && (
                            <small className="p-error">{formik.errors.valor_credito}</small>
                        )}
                    </div>
                    <div className="p-field p-col-12 p-md-6">
                        <Label htmlFor="historico" label="Histórico" obrigatorio />
                        <MakoDropdownHistoricoPadrao
                            filtros={{ tipo: "P" }}
                            id="historico"
                            name="historico_padrao"
                            value={formik.values.historico_padrao}
                            handleChangeForm={formik.handleChange}
                            setFieldValueForm={setFieldValue}
                            inputTextProps={{
                                value: formik.values.historico_original,
                            }}
                            className={classNames({ "p-invalid": formik.errors.historico_padrao })}
                        />
                        {formik.errors.historico_padrao && (
                            <small className="p-error">{formik.errors.historico_padrao}</small>
                        )}
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <Label htmlFor="data_vencimento" label="Data vencimento" obrigatorio />
                        <MakoCalendar
                            id="data_vencimento"
                            name="data_vencimento"
                            disabled={formik.values.id}
                            valueCalendar={formik.values.data_vencimento}
                            minDate={parseData(dadosBasicos?.data_devolucao)}
                            onChange={formik.handleChange}
                            className={classNames({
                                "p-invalid": formik.errors.data_vencimento,
                            })}
                        />
                        {formik.errors.data_vencimento && (
                            <small className="p-error">{formik.errors.data_vencimento}</small>
                        )}
                    </div>
                    <div className="p-field p-col-12 p-md-4">
                        <Label htmlFor="plano_orcamentario" label="Plano Orçamentário" obrigatorio />
                        <Dropdown
                            id="plano_orcamentario"
                            name="plano_orcamentario"
                            placeholder="Selecione um plano"
                            url={"/financeiro/planos-orcamentarios?limit=1000"}
                            filter
                            filterBy="descricao"
                            optionValue="id"
                            optionLabel="descricao"
                            emptyMessage="Não existem registros cadastrados"
                            value={formik.values.plano_orcamentario}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.plano_orcamentario })}
                        />
                        {formik.errors.plano_orcamentario && (
                            <small className="p-error">{formik.errors.plano_orcamentario}</small>
                        )}
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    {formik.values.frete && (
                        <div className="p-field p-col-12 p-md-6">
                            <Label htmlFor="credor" label="Credor" obrigatorio />
                            <MakoInputPerfil
                                id="credor"
                                name="credor"
                                value={formik.values.credor}
                                onChange={formik.handleChange}
                                disabled={dadosBasicos?.pagante_frete !== 2}
                                className={classNames({ "p-invalid": formik.errors.credor })}
                            />
                            {formik.errors.credor && <small className="p-error">{formik.errors.credor}</small>}
                        </div>
                    )}
                    <div
                        className={classNames("p-field", {
                            "p-col-12": !formik.values.frete,
                            "p-col-6": formik.values.frete,
                        })}
                    >
                        <Label htmlFor="template_rateio" label="Regra de rateio" obrigatorio />
                        <Dropdown
                            id="template_rateio"
                            name="template_rateio"
                            url={`/financeiro/templates-rateios/?query={id,descricao}`}
                            disabled={formik.values.id}
                            optionValue="id"
                            optionLabel="descricao"
                            filter
                            filterBy="descricao"
                            emptyMessage="Não existem registros cadastrados"
                            value={formik.values.template_rateio}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.template_rateio })}
                        />
                        {formik.errors.template_rateio && (
                            <small className="p-error">{formik.errors.template_rateio}</small>
                        )}
                    </div>
                </div>
                <MakoActionsButtons className="p-justify-end">
                    <Button icon="pi pi-save" type="submit" label="Confirmar" />
                </MakoActionsButtons>
            </form>
        </Dialog>
    );
};

export const ModalAdicionarParcela = forwardRef(Modal);
