import React, { useEffect, useMemo, useState } from "react";

import classNames from "classnames";

import { useFormik } from "formik";
import * as Yup from "yup";

import { InputNumber } from "primereact/inputnumber";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";

import { MakoDropdownCompetenciaFinanceira } from "@/components/MakoInputs/MakoDropdownCompetenciaFinanceira";
import { MakoCalendar } from "@/components/MakoCalendar";
import { Dropdown } from "@/components/Dropdown";

import { axiosPatch, axiosPost } from "@/services/http";
import { dataToStr, parseData } from "@/assets/util/datas";
import { parseMoeda } from "@/assets/util/util";

import useContratoServico from "@/hooks/useContratoServico";
import useEmpresa from "@/hooks/useEmpresa";
import useLoading from "@/hooks/useLoading";
import useToast from "@/hooks/useToast";
import { TIPO_FORMA_PAGAMENTO_CHOICE } from "@/assets/constants/constants";

export const ModalGerarParcelas = ({ onFinish, onClose, url, pagamento = false }) => {
    const [editavel, setEditavel] = useState(false);

    const { showLoading, hideLoading } = useLoading();
    const { showError, showSuccess } = useToast();
    const { empresaSelecionadaId } = useEmpresa();
    const { contrato } = useContratoServico();

    const initialValues = {
        empresa: empresaSelecionadaId,
        faturamento: null,
        parcelas: 1,
        primeira_parcela: contrato?.primeiro_vencimento,
        rateio: contrato?.template_rateio,
        historico: null,
        historico_original: null,
        competencia: null,
    };

    const { setValues, setFieldValue, ...formik } = useFormik({
        initialValues: !pagamento
            ? initialValues
            : {
                  ...initialValues,
                  forma_pagamento: null,
              },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                faturamento: Yup.number()
                    .required("O campo 'faturamento' é obrigatório.")
                    .typeError("Informe um 'faturamento' válido"),
                parcelas: Yup.number()
                    .required("O campo 'parcelas' é obrigatório.")
                    .min(1, "Informe ao menos uma 'parcela'.")
                    .typeError("Informe uma 'parcela' válido"),
                primeira_parcela: Yup.date()
                    .required("O campo 'primeira parcela' é obrigatório.")
                    .typeError("Informe uma 'primeira parcela' válida"),
                rateio: Yup.number()
                    .required("O campo 'rateio' é obrigatório.")
                    .typeError("Informe um 'rateio' válido"),
                historico: Yup.object()
                    .shape({
                        id: Yup.number().required("O campo 'historico' é obrigatório."),
                    })
                    .required("O campo 'faturamento' é obrigatório.")
                    .typeError("Informe um 'faturamento' válido"),
                historico_original: Yup.string()
                    .max(140, "Máximo de caracteres informado: 140.")
                    .required("O campo 'historico original' é obrigatório.")
                    .typeError("Informe um 'historico original' válido"),
                competencia: Yup.number()
                    .required("O campo 'competencia' é obrigatório.")
                    .typeError("Informe um 'competencia' válido"),
                forma_pagamento: Yup.number().when({
                    is: () => pagamento,
                    then: Yup.number()
                        .required("O campo 'forma pagamento' é obrigatório.")
                        .typeError("Informe um 'forma de pagamento' válida."),
                    otherwise: Yup.number().nullable(),
                }),
            });
            await formSchema.validate(values, { abortEarly: false });
            showLoading();
            const { status, data } = await axiosPost(url, {
                ...values,
                historico: values.historico.id,
                primeira_parcela: dataToStr(values.primeira_parcela, "yyyy-MM-dd"),
            });
            hideLoading();
            if (status === 201) {
                onFinish();
                showSuccess({
                    summary: "Sucesso!",
                    detail: `${!pagamento ? "Recebimentos" : "Pagamentos"} gerados com sucesso.`,
                    life: 3000,
                });
            } else {
                showError({
                    summary: "Erro :(",
                    detail: status === 400 ? data.msg : "Desculpe, não conseguimos processar a sua requisição.",
                    life: 3000,
                });
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            }
        }
    }

    const formatarFaturamento = useMemo(() => {
        return (data) => {
            return data.map((f) => ({
                ...f,
                label: `${f.id} | Valor: ${parseMoeda(f.valor_total, true)}`,
            }));
        };
    }, []);

    const aposBuscarCompetencia = useMemo(() => {
        return (data) => {
            if (data.length === 1) setFieldValue("competencia", data[0].id);
            return data;
        };
    }, []);

    const onChangeHistorico = (e) => {
        setFieldValue("historico_original", e.value instanceof Object ? e.value.descricao : "");
        formik.handleChange(e);
    };

    return (
        <>
            <form onSubmit={formik.handleSubmit}>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-4">
                        <label htmlFor="faturamento">Faturamento *</label>
                        <Dropdown
                            id="faturamento"
                            name="faturamento"
                            url={`/servicos/faturamento-contrato/?contrato=${contrato.id}&recebimento__isnull=true&pagamento__isnull=true`}
                            optionValue="id"
                            optionLabel="label"
                            aposBuscar={formatarFaturamento}
                            emptyMessage="Não possui faturamento elegível"
                            value={formik.values.faturamento}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.faturamento })}
                        />
                        {formik.errors.faturamento && <small className="p-error">{formik.errors.faturamento}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="parcelas">Quant. parcelas *</label>
                        <InputNumber
                            id="parcelas"
                            name="parcelas"
                            value={formik.values.parcelas}
                            min={0}
                            max={120}
                            disabled={contrato?.intervalo_recebimento === 7}
                            onValueChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.parcelas })}
                        />
                        {formik.errors.parcelas && <small className="p-error">{formik.errors.parcelas}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="primero_pacela">1° vencimento *</label>
                        <MakoCalendar
                            id="primeira_parcela"
                            name="primeira_parcela"
                            minDate={contrato?.primeiro_vencimento}
                            valueCalendar={formik.values.primeira_parcela}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.primeira_parcela })}
                        />
                        {formik.errors.primeira_parcela && (
                            <small className="p-error">{formik.errors.primeira_parcela}</small>
                        )}
                    </div>
                    <div className="p-field p-col-12 p-md-4">
                        <label>Regra de rateio *</label>
                        <Dropdown
                            id="rateio"
                            name="rateio"
                            url={`/financeiro/templates-rateios/?query={id,descricao}`}
                            optionValue="id"
                            optionLabel="descricao"
                            value={formik.values.rateio}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.rateio })}
                        />
                        {formik.errors.rateio && <small className="p-error">{formik.errors.rateio}</small>}
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-4">
                        <label htmlFor="competencia">Competência *</label>
                        <MakoDropdownCompetenciaFinanceira
                            id="competencia"
                            name="competencia"
                            value={formik.values.competencia}
                            onChange={formik.handleChange}
                            aposBuscar={aposBuscarCompetencia}
                            className={classNames({ "p-invalid": formik.errors.competencia })}
                        />
                        {formik.errors.competencia && <small className="p-error">{formik.errors.competencia}</small>}
                    </div>
                    {pagamento && (
                        <div className="p-field p-col-12 p-md-4">
                            <label htmlFor="forma_pagamento">Forma pagamento *</label>
                            <Dropdown
                                id="forma_pagamento"
                                name="forma_pagamento"
                                options={TIPO_FORMA_PAGAMENTO_CHOICE}
                                optionValue="id"
                                optionLabel="label"
                                value={formik.values.forma_pagamento}
                                onChange={formik.handleChange}
                                className={classNames({ "p-invalid": formik.errors.forma_pagamento })}
                            />
                            {formik.errors.forma_pagamento && (
                                <small className="p-error">{formik.errors.forma_pagamento}</small>
                            )}
                        </div>
                    )}
                    <div className="p-field p-col-12 p-md-4">
                        <label htmlFor="historico">Historico padrão*</label>
                        <div className="p-inputgroup">
                            <Dropdown
                                id="historico"
                                name="historico"
                                url={`/financeiro/historico-padrao/?ativo=True&tipo=R`}
                                optionLabel="descricao"
                                value={formik.values.historico}
                                onChange={onChangeHistorico}
                                className={classNames({ "p-invalid": formik.errors.historico })}
                                disabled={editavel}
                            />
                            <Button
                                type="button"
                                icon="pi pi-pencil"
                                className="p-button-warning"
                                onClick={() => setEditavel((prev) => !prev)}
                            />
                        </div>
                        {formik.errors.historico && <small className="p-error">{formik.errors.historico}</small>}
                    </div>
                    {editavel && (
                        <div className="p-field p-col-12 p-md-4">
                            <label htmlFor="historico_original">Historico novo *</label>
                            <InputText
                                id="historico_original"
                                name="historico_original"
                                onChange={formik.handleChange}
                                value={formik.values.historico_original}
                                autoComplete="off"
                                maxLength={140}
                                className={classNames({ "p-invalid": formik.errors.historico_original })}
                            />
                            {formik.errors.historico_original && (
                                <small className="p-error">{formik.errors.historico_original}</small>
                            )}
                        </div>
                    )}
                </div>
                <div className="p-grid p-col-12 p-md-6">
                    <Button icon="pi pi-save" label="Gravar" type="submit" className="p-button-info p-mr-2" />
                    <Button
                        icon="pi pi-trash"
                        label="Limpar"
                        type="button"
                        className="p-button-warning p-mr-2"
                        onClick={formik.resetForm}
                    />
                    <Button label="Cancelar" type="button" className="p-button-danger" onClick={onClose} />
                </div>
            </form>
        </>
    );
};
