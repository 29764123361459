import React, { useState, useRef, useEffect, useCallback } from "react";
import { Button } from "primereact/button";
import { Redirect } from "react-router-dom";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
import { axiosGet, axiosPatch } from "@/services/http";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";

import MakoListagem from "@/components/MakoListagem";
import permissoes from "@/assets/constants/permissoes";
import useLoading from "@/hooks/useLoading";
import { BotaoDelete } from "@/components/BotaoDelete";
import { MakoDropdownEmpresas } from "@/components/MakoDropdownEmpresas";
import { CodigoFiltroTemplate, DateFiltroTemplate, TextoFiltroTemplate } from "@/components/MakoFiltrosCabecalho";

const url = "/financeiro/versoes-planos-contas-financeiras/";

export const FinVersaoPlanoContasPage = () => {
    const [vincularEmpresa, setVincularEmpresa] = useState(false);
    const [redirecionar, setRedirecionar] = useState(null);
    const [versaoContas, setVersaoContas] = useState(null);
    const [listEmpresaVinculada, setListEmpresaVinculada] = useState([]);
    const [empresaVinculada, setEmpresaVinculada] = useState(null);
    const [exibirEmpresasVinc, setExibirEmpresasVinc] = useState(false);
    const { showLoading, hideLoading } = useLoading();
    const toastRef = useRef(null);
    const listagemRef = useRef(null);

    async function handleVincular() {
        try {
            if (empresaVinculada !== null) {
                const body = {
                    descricao: versaoContas.descricao,
                    empresas: { add: [empresaVinculada] },
                    inicio_vigencia: versaoContas.inicio_vigencia,
                    final_vigencia: versaoContas.final_vigencia,
                    ativo: versaoContas.ativo,
                };

                showLoading();
                const resposta = await axiosPatch(`${url}${versaoContas.id}/`, body);
                hideLoading();

                if (resposta.status === 201 || resposta.status === 200) {
                    toastRef.current.show({
                        severity: "success",
                        summary: "Sucesso",
                        detail: "Versão de plano de contas cadastrada com sucesso!",
                        life: 1500,
                    });
                    listagemRef.current?.buscarDados();
                    setEmpresaVinculada(null);
                } else {
                    toastRef.current.show({
                        severity: "error",
                        summary: "Erro",
                        detail: "Desculpe, não conseguimos processar a sua requisição.",
                        life: 1500,
                    });
                }
            } else {
                toastRef.current.show({
                    severity: "warn",
                    summary: "Ops",
                    detail: "Selecione uma empresa para realizar o vinculo.",
                    life: 1500,
                });
            }
        } catch (error) {
            toastRef.current.show({
                severity: "error",
                summary: "Erro :(",
                detail: "Desculpe, não conseguimos processar a sua requisição.",
                life: 1500,
            });
            hideLoading();
        }
    }

    const esconderVincularEmpresa = () => {
        setVincularEmpresa(false);
        setEmpresaVinculada(null);
    };

    const exibirVincularEmpresa = (rowData) => {
        setVincularEmpresa(true);
        setVersaoContas(rowData);
    };

    const renderFooter = () => {
        return (
            <div>
                <Button
                    label="Cancelar"
                    icon="pi pi-times"
                    onClick={() => esconderVincularEmpresa()}
                    className="p-button-text"
                />
                <Button label="Confirmar" icon="pi pi-check" className="p-button" onClick={() => handleVincular()} />
            </div>
        );
    };

    const opcoesVersaoPlano = (rowData) => {
        return (
            <div className="actions">
                <MakoControleAcesso
                    permissao={[permissoes.FINANCEIRO_FINANCEIRO_VERSAOPLANOCONTAS_EDITAR]}
                    componente={Button}
                    icon="pi pi-pencil"
                    className="p-button-rounded p-button-warning p-mr-2 p-mb-1"
                    tooltip="Alterar Cadastro de versão do plano de contas"
                    tooltipOptions={{ position: "left" }}
                    onClick={() =>
                        setRedirecionar({
                            pathname: "/financeiro/versao-plano-contas/form",
                            state: { versaoContas: rowData },
                        })
                    }
                />

                <MakoControleAcesso
                    permissao={[permissoes.FINANCEIRO_FINANCEIRO_VERSAOPLANOCONTAS_EXCLUIR]}
                    componente={Button}
                    icon="pi pi-sitemap"
                    className="p-button-rounded p-button-info p-mr-2 p-mb-1"
                    onClick={() => exibirVincularEmpresa(rowData)}
                    tooltip="Vincular plano de contas a empresas"
                    tooltipOptions={{ position: "left" }}
                />
                <MakoControleAcesso
                    permissao={[permissoes.FINANCEIRO_FINANCEIRO_VERSAOPLANOCONTAS_EXCLUIR]}
                    componente={BotaoDelete}
                    url={url}
                    objetoId={rowData.id}
                    exigeConfirmacao
                    msgConfirmacao={
                        <span>
                            Deseja realmente excluir a versão <b>{rowData.nome}</b>?
                        </span>
                    }
                    classNames="p-mb-1"
                    tooltip="Deletar versão do plano de contas"
                    tooltipOptions={{ position: "left" }}
                    msgToastErroExclusao="A versão não pode ser excluída."
                    onDelete={() => listagemRef.current?.buscarDados()}
                />
            </div>
        );
    };

    async function desvincularEmpresa(empresa) {
        const body = {
            descricao: versaoContas.descricao,
            empresas: { remove: [empresa.id] },
            inicio_vigencia: versaoContas.inicio_vigencia,
            final_vigencia: versaoContas.final_vigencia,
            ativo: versaoContas.ativo,
        };

        showLoading();
        const resposta = await axiosPatch(`${url}${versaoContas.id}/`, body);
        hideLoading();

        if (resposta.status === 200 || resposta.status === 204) {
            toastRef.current.show({
                severity: "success",
                summary: "Sucesso",
                detail: "Empresa desvinculada com sucesso!",
                life: 3000,
            });
            setExibirEmpresasVinc(false);
            listagemRef.current?.buscarDados();
        } else {
            toastRef.current.show({
                severity: "error",
                summary: "Erro",
                detail: "Desculpe, não conseguimos processar a sua requisição.",
                life: 3000,
            });
            hideLoading();
        }
    }

    const opcoesEmpresasVinculadas = (rowData) => {
        return (
            <div className="actions">
                <MakoControleAcesso
                    permissao={[permissoes.FINANCEIRO_FINANCEIRO_VERSAOPLANOCONTAS_EXCLUIR]}
                    componente={Button}
                    icon="pi pi-times"
                    className="p-button-rounded p-button-danger p-mr-2 p-mb-1"
                    onClick={() => desvincularEmpresa(rowData)}
                    tooltip="Desvincular empresa da versao do plano de contas"
                    tooltipOptions={{ position: "left" }}
                />
            </div>
        );
    };

    const cabecalhoTabela = (
        <>
            <MakoControleAcesso
                permissao={[permissoes.FINANCEIRO_FINANCEIRO_VERSAOPLANOCONTAS_INCLUIR]}
                componente={Button}
                label="Novo"
                icon="pi pi-plus"
                className="p-button-success p-mr-2"
                onClick={() => setRedirecionar("/financeiro/versao-plano-contas/form")}
            />
        </>
    );

    const exibirListaEmpresasVinc = (row) => {
        setVersaoContas(row);
        setListEmpresaVinculada(row.empresas);
        setExibirEmpresasVinc(true);
    };

    const esconderEmpresasVinc = () => {
        listagemRef.current?.buscarDados();
        setExibirEmpresasVinc(false);
    };

    const qtdEmpresasTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button
                    label={rowData.empresas.length === 0 ? "0" : rowData.empresas.length}
                    onClick={() => exibirListaEmpresasVinc(rowData)}
                    className="p-button-outlined p-button-primary"
                />
            </div>
        );
    };

    const colunas = [
        { field: "id", header: "Código", style: { width: "10%" }, filter: true, filterElement: CodigoFiltroTemplate },
        {
            field: "descricao",
            header: "Descrição",
            style: { width: "30%" },
            filter: true,
            filterElement: TextoFiltroTemplate,
        },
        {
            field: "empresas",
            header: "Empresas",
            action: (e) => qtdEmpresasTemplate(e),
            filter: true,
            filterElement: CodigoFiltroTemplate,
        },
        {
            field: "inicio_vigencia",
            dateFormat: "dd/MM/yyyy",
            header: "Vigência Inicial",
            filter: true,
            filterElement: DateFiltroTemplate,
        },
        {
            field: "final_vigencia",
            header: "Vigência Final",
            dateFormat: "dd/MM/yyyy",
            filter: true,
            filterElement: DateFiltroTemplate,
        },
        {
            field: "action",
            header: "Ações",
            action: (e) => opcoesVersaoPlano(e),
            style: { width: "15%" },
        },
    ];

    const colunasEmpresa = [
        { field: "nome", header: "Nome da empresa", style: { width: "60%", paddingLeft: "1%" } },
        {
            field: "action",
            header: "Remover",
            action: (e) => opcoesEmpresasVinculadas(e),
            style: { width: "30%" },
        },
    ];

    return redirecionar ? (
        <Redirect to={redirecionar} />
    ) : (
        <div className="p-grid">
            <Toast ref={toastRef} />
            <div className="p-col-12">
                <div className="card">
                    <MakoListagem
                        ref={listagemRef}
                        titulo="Versões do Plano de Contas Financeiras"
                        colunas={colunas}
                        painelEsquerdo={cabecalhoTabela}
                        urlPesquisa={url}
                        responsiva
                        configTabela={{
                            paginator: true,
                            lazy: true,
                        }}
                        filtros={{
                            id: { value: null, matchMode: "equals" },
                            descricao: {
                                operator: "and",
                                constraints: [{ value: "", matchMode: "unaccent_icontains" }],
                            },
                            empresas: { value: null, matchMode: "equals" },
                            inicio_vigencia: { value: null, matchMode: "equals" },
                            final_vigencia: { value: null, matchMode: "equals" },
                        }}
                    />
                </div>
            </div>

            <Dialog
                header="Vincular Empresas"
                visible={vincularEmpresa}
                breakpoints={{ "960px": "75vw" }}
                style={{ width: "30vw", display: "block" }}
                footer={renderFooter()}
                onHide={() => esconderVincularEmpresa()}
            >
                <div>
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-12 p-md-12" style={{ width: "100%", paddingLeft: "4%" }}>
                            <label htmlFor="empresa_vinculada">Empresa *</label>
                            <MakoDropdownEmpresas
                                id="empresa_vinculada"
                                name="empresa_vinculada"
                                value={empresaVinculada}
                                onChange={(e) => setEmpresaVinculada(e.id)}
                            />
                        </div>
                    </div>
                </div>
            </Dialog>
            <Dialog
                header="Empresas vinculadas"
                visible={exibirEmpresasVinc}
                breakpoints={{ "960px": "75vw" }}
                style={{ width: "40vw", display: "block" }}
                onHide={() => esconderEmpresasVinc()}
            >
                <MakoListagem
                    ref={listagemRef}
                    colunas={colunasEmpresa}
                    dadosLocal={listEmpresaVinculada}
                    responsiva
                    configTabela={{ paginator: false }}
                />
            </Dialog>
        </div>
    );
};
