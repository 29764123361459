import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import classNames from "classnames";
import { add, parseISO, isEqual } from "date-fns";
import { Button } from "primereact/button";
import { useFormik } from "formik";
import * as Yup from "yup";

import { ModalEditarParcela } from "./ModalEditarParcela";
import { ModalSimuladorParcelas } from "./ModalSimuladorParcelas";
import { MakoListagem } from "@/components/MakoListagem";
import { MakoInputMoeda } from "@/components/MakoInputMoeda";
import { MakoCalendar } from "@/components/MakoCalendar";
import { Dropdown } from "@/components/Dropdown";
import { CamposObrigatorios } from "@/components/CamposObrigatorios";
import { parseNumber } from "@/assets/helpers/number";
import { formatarCasasDecimais, parseMoeda } from "@/assets/util/util";
import {
    VENDAS_ENCARGOS_TAXA_MAXIMA,
    VENDAS_VENCIMENTO_LIMITE_SEGUNDAPARCELA,
    VENDA_FINANCEIRO_MAXIMO_DIAS_AVISTA,
} from "@/assets/constants/parametros";
import { dataToStr, parseData } from "@/assets/util/datas";
import { axiosGet, axiosPost } from "@/services/http";
import useVenda from "@/hooks/useVenda";
import useToast from "@/hooks/useToast";
import useParam from "@/hooks/useParam";
import useEmpresa from "@/hooks/useEmpresa";

export const FinanceiroVendaForm = () => {
    const [loading, setLoading] = useState(false);
    const [planoRecebimento, setPlanoRecebimento] = useState(null);
    const [opcoesParcelas, setOpcoesParcelas] = useState([]);
    const [vouchers, setVouchers] = useState([]);
    const [vendaAVista, setVendaAVista] = useState(false);
    const [taxaAplicada, setTaxaAplicada] = useState(0);
    const [realizaCalculo, setRealizaCalculo] = useState(true);
    const [formasRecebimento, setFormasRecebimento] = useState([]);
    const [parcelasVenda, setParcelasVenda] = useState([]);
    const modalSimuladorParcelasRef = useRef(null);
    const modalEditarParcelaRef = useRef(null);
    const {
        dadosBasicos,
        vendedor,
        vendaFinalizada,
        handleParcelasVenda,
        setParcelasVenda: setParcelasVendaCtx,
        atualizarValoresVenda,
        fixarDiaVencimentoParcela,
    } = useVenda();
    const { showSuccess, showWarning, showError } = useToast();
    const { getParam } = useParam();
    const { empresaSelecionadaId } = useEmpresa();

    const { setFieldValue, ...formik } = useFormik({
        initialValues: {
            valor_frete: parseNumber(dadosBasicos?.valor_frete),
            valor_frete_restante: 0,
            valor_total_itens: parseNumber(dadosBasicos?.valor_total_av),
            voucher: null,
            valor_voucher: 0,
            valor_total_sem_voucher: 0,
            valor_entrada_sem_frete: 0,
            valor_entrada: parseNumber(dadosBasicos?.entrada),
            valor_entrada_com_voucher: 0,
            valor_encargos: 0,
            data_venc_entrada: null,
            forma_recebimento_entrada: null,
            valor_para_parcelar: 0,
            quantidade_parcelas: null,
            data_vencimento: null,
            forma_recebimento: null,
            valor_total_parcelas: 0,
        },
        onSubmit: handleSubmit,
    });

    const validarMinimoEntrada = (valorEntrada, valorTotal) => {
        const { percentual_minimo_entrada } = planoRecebimento;
        return valorEntrada >= valorTotal * (parseNumber(percentual_minimo_entrada) / 100);
    };

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                forma_recebimento: Yup.object()
                    .nullable()
                    .when("valor_para_parcelar", {
                        is: (val) => val > 0,
                        then: Yup.object()
                            .required("Escolha uma forma de pagamento.")
                            .typeError("Escolha uma forma de pagamento."),
                    }),
                valor_entrada: Yup.number().test(
                    "Validation min entrada",
                    `Esse plano exige ${planoRecebimento.percentual_minimo_entrada} % de entrada.`,
                    (value) => validarMinimoEntrada(value, values.valor_total_itens)
                ),
                forma_recebimento_entrada: Yup.object()
                    .nullable()
                    .when("valor_entrada", {
                        is: (val) => val > 0,
                        then: Yup.object()
                            .required("Escolha uma forma de pagamento para a entrada.")
                            .typeError("Escolha uma forma de pagamento para a entrada."),
                    }),
                data_venc_entrada: Yup.date()
                    .nullable()
                    .when("valor_entrada", {
                        is: (val) => val > 0,
                        then: Yup.date()
                            .required("O campo 'vencimento da entrada' é obrigatório.")
                            .typeError("Informe uma data válida."),
                    })
                    .when("valor_voucher", {
                        is: (val) => val > 0,
                        then: Yup.date()
                            .required("O campo 'vencimento da entrada' é obrigatório.")
                            .typeError("Informe uma data válida."),
                    }),
                quantidade_parcelas: Yup.number().when("valor_para_parcelar", {
                    is: (val) => val > 0,
                    then: Yup.number()
                        .typeError("Selecione a quantidade de parcelas.")
                        .required("O campo 'quantidade de parcelas' é obrigatório."),
                    otherwise: Yup.number().nullable(),
                }),
                data_vencimento: Yup.date()
                    .required("O campo 'vencimento 1ª parcela' é obrigatório")
                    .typeError("Informe uma data válida.")
                    .notRequired()
                    .when("quantidade_parcelas", {
                        is: (val) => val === 1 && opcoesParcelas.length === 1 && opcoesParcelas[0].value === 1,
                        then: Yup.date().nullable().notRequired(),
                    })
                    .when("data_venc_entrada", {
                        is: (val) => val !== null && values.valor_para_parcelar,
                        then: Yup.date()
                            .min(
                                values.data_venc_entrada || new Date(),
                                "A data de vencimento da parcela não pode ser inferior a data de vencimento da entrada"
                            )
                            .nullable()
                            .notRequired(),
                    }),
            });
            await formSchema.validate(values, {
                abortEarly: false,
            });
            if (
                values.valor_para_parcelar &&
                values.data_venc_entrada &&
                isEqual(values.data_vencimento, values.data_venc_entrada)
            ) {
                showWarning({
                    summary: "Aviso!",
                    detail: "A data de início das parcelas e da entrada são iguais. Verifique!",
                    life: 5000,
                });
            } else {
                const { data_vencimento: data_vencimento_inicial, valor_total_parcelas, voucher, ...rest } = values;
                const maxDescontoPermitido =
                    taxaAplicada - (taxaAplicada * parseNumber(vendedor.max_desconto_encargos)) / 100;
                const parcela = {
                    ...rest,
                    data_vencimento_inicial,
                    plano_recebimento: planoRecebimento,
                    valor_financiado: valor_total_parcelas - rest.valor_entrada_com_voucher,
                    taxa: taxaAplicada,
                    entrada: {
                        valor: rest.valor_entrada_com_voucher,
                        data_vencimento: rest.data_venc_entrada,
                        forma_recebimento: rest.forma_recebimento_entrada,
                        valor_voucher: rest.valor_voucher,
                        voucher,
                    },
                    requer_aprovacao_desconto: taxaAplicada < maxDescontoPermitido,
                };
                const parcelasGeradas = handleParcelasVenda(parcela);
                setParcelasVenda(parcelasGeradas);
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            }
        }
    }

    const listarFormasRecebimento = useCallback(async () => {
        if (dadosBasicos) {
            const { status, data } = await axiosGet(
                `/financeiro/planos-recebimentos/${dadosBasicos.plano_recebimento}/`
            );
            if (status === 200) {
                const { formas_recebimento, ...rest } = data;
                let _parcelas = [];
                for (let parcela = rest.minimo_parcelas; parcela <= rest.maximo_parcelas; parcela++) {
                    _parcelas.push({ value: parcela, label: parcela === 1 ? "1 parcela" : `${parcela} parcelas` });
                }
                const taxa_alvo = parseNumber(rest.taxa_alvo);
                const taxa_minima = parseNumber(rest.taxa_minima);
                setOpcoesParcelas(_parcelas);
                setPlanoRecebimento({ ...rest, taxa_alvo, taxa_minima });
                setTaxaAplicada(taxa_alvo);
                setFormasRecebimento(formas_recebimento);
            } else {
                showError({
                    summary: "Erro!",
                    detail: "Desculpe, não conseguimos carregar os planos de recebimentos.",
                    life: 3000,
                });
            }
        }
    }, [showError, dadosBasicos]);

    useEffect(() => {
        listarFormasRecebimento();
    }, [listarFormasRecebimento]);

    const projetarData1Parcela = useCallback(() => {
        if (planoRecebimento && dadosBasicos) {
            const [intervalo] = planoRecebimento.intervalo_parcelas.split(",", 1);
            const { data } = dadosBasicos;
            const dataVenc1Parcela =
                data instanceof Date ? add(data, { days: intervalo }) : add(parseISO(data), { days: intervalo });
            setFieldValue("data_vencimento", dataVenc1Parcela);
        }
    }, [dadosBasicos, planoRecebimento, setFieldValue]);

    useEffect(() => {
        projetarData1Parcela();
    }, [projetarData1Parcela]);

    const editarParcela = (e) => {
        const { rowIndex, fixa_dia_vencimento, repetir_demais_parcelas, ...parcelaEditada } = e;
        const parcelaComValoresIguais = !!formasRecebimento.find((el) => el.id === parcelaEditada.forma_recebimento)
            ?.parcelas_valores_iguais;
        setTaxaAplicada(parcelaEditada.taxa);
        const parcelas = parcelasVenda.map((parcela, index) => {
            parcela._editada = true;
            parcela.taxa = parcelaEditada.taxa;
            if (rowIndex === index) parcela.data_vencimento = parcelaEditada.data_vencimento;
            if (parcelaComValoresIguais && index >= rowIndex) parcela.valor_parcela = parcelaEditada.valor_parcela;
            if (repetir_demais_parcelas && index >= rowIndex) {
                parcela.forma_recebimento = parcelaEditada.forma_recebimento;
                parcela.valor_parcela = parcelaEditada.valor_parcela;
                if (fixa_dia_vencimento) {
                    const dataVencimento = parseData(parcelaEditada.data_vencimento);
                    const diaVencimento = dataVencimento.getDate();
                    parcela.data_vencimento = fixarDiaVencimentoParcela(parcela.data_vencimento, diaVencimento);
                }
            }
            return parcela;
        });
        setParcelasVenda(parcelas);
    };

    const parcelaTemplate = (rowData) => {
        const { numero_parcela, valor_entrada, quantidade_parcelas } = rowData;
        if (numero_parcela === 0) return <span>ENTRADA</span>;
        const qtdParcelasFinanciadas = valor_entrada > 0 ? quantidade_parcelas - 1 : quantidade_parcelas;
        return <span>{`${numero_parcela}/${qtdParcelasFinanciadas}`}</span>;
    };

    const actionBodyTemplate = (rowData, { rowIndex }) => {
        return (
            <div className="actions">
                <Button
                    icon="pi pi-pencil"
                    className="p-button-rounded p-button-warning p-mr-2"
                    disabled={rowData.numero_parcela === 0 || vendaFinalizada}
                    onClick={() =>
                        modalEditarParcelaRef.current?.abrirModal({
                            ...rowData,
                            valor_frete: formik.values.valor_frete_restante,
                            valor_entrada: formik.values.valor_entrada_sem_frete,
                            valor_para_parcelar: formik.values.valor_para_parcelar,
                            rowIndex,
                        })
                    }
                />
            </div>
        );
    };

    const colunas = [
        { field: "data_vencimento", header: "Vencimento", dateFormat: "dd/MM/yyyy" },
        { field: "parcelas", header: "Parcela", action: (e) => parcelaTemplate(e) },
        { field: "forma_recebimento.descricao", header: "Forma de recebimento", style: { width: "50%" } },
        { field: "valor_parcela", header: "Valor", money: true },
        {
            field: "actions",
            header: "Ações",
            style: { width: "8%" },
            action: actionBodyTemplate,
        },
    ];

    const rowClass = (rowData) => {
        return {
            "table-recebimentos-overdue": rowData._editada,
        };
    };

    async function handleGravarParcelas() {
        const parcelasFormatadas = parcelasVenda.map((parcela) => ({
            ...parcela,
            venda: dadosBasicos?.id,
            forma_recebimento: parcela.forma_recebimento.id,
            data_vencimento: dataToStr(parcela.data_vencimento, "yyyy-MM-dd"),
            valor_parcela: formatarCasasDecimais(parcela.valor_parcela),
            valor_frete: formik.values.valor_frete,
        }));
        const { status } = await axiosPost("/vendas/parcelas-vendas/", parcelasFormatadas);
        if (status === 201) {
            showSuccess({
                summary: "Sucesso!",
                detail: "Parcelas lançadas com sucesso.",
                life: 1500,
            });
            setParcelasVenda((old) =>
                old.map((p) => {
                    delete p._editada;
                    return p;
                })
            );
            await atualizarValoresVenda();
        } else {
            showError({
                summary: "Erro :(",
                detail: "Desculpe, não foi possível lançar as parcelas.",
                life: 3000,
            });
        }
    }

    const calcularValorParaParcelar = useCallback(() => {
        const valorEntradaComVoucher = formik.values.valor_entrada + formik.values.valor_voucher;
        const valorEntradaSemFrete = Math.max(0, valorEntradaComVoucher - formik.values.valor_frete);
        const valorFreteRestante = Math.max(0, formik.values.valor_frete - valorEntradaComVoucher);
        const totalSemVoucher = formik.values.valor_frete + formik.values.valor_total_itens;
        const valorParaParcelar = formik.values.valor_total_itens - valorEntradaSemFrete;
        setFieldValue("valor_frete_restante", valorFreteRestante);
        setFieldValue("valor_entrada_com_voucher", valorEntradaComVoucher);
        setFieldValue("valor_entrada_sem_frete", valorEntradaSemFrete);
        setFieldValue("valor_total_sem_voucher", totalSemVoucher);
        setFieldValue("valor_para_parcelar", valorParaParcelar >= 0 ? valorParaParcelar : 0);
        setVendaAVista(valorParaParcelar <= 0);
    }, [
        formik.values.valor_frete,
        formik.values.valor_total_itens,
        formik.values.valor_voucher,
        formik.values.valor_entrada,
        setFieldValue,
    ]);

    useEffect(() => {
        calcularValorParaParcelar();
    }, [calcularValorParaParcelar]);

    const aplicarParcelasSimuladas = useCallback(
        (simulacao) => {
            const { quantidade_parcelas, total_parcelado, taxa, total_encargos, entrada } = simulacao;
            setRealizaCalculo(false);
            const valorEntradaSemVoucher = Math.max(0, entrada - formik.values.valor_voucher);
            setFieldValue("valor_entrada", valorEntradaSemVoucher);
            setFieldValue("quantidade_parcelas", quantidade_parcelas);
            setFieldValue("valor_total_parcelas", total_parcelado + entrada);
            setFieldValue("valor_encargos", total_encargos);
            setTaxaAplicada(taxa);
        },
        [setFieldValue, formik.values.valor_voucher]
    );

    const calcularEncargos = useCallback(async () => {
        if (
            !realizaCalculo ||
            !formik.values.quantidade_parcelas ||
            typeof taxaAplicada !== "number" ||
            !formik.values.valor_para_parcelar
        )
            return;
        const body = {
            taxa: taxaAplicada,
            quantidade_parcelas: formik.values.quantidade_parcelas,
            entrada: formik.values.valor_entrada_sem_frete,
        };
        const { status, data } = await axiosPost(`/vendas/vendas/${dadosBasicos?.id}/simular-parcelas/`, body);
        if (status === 200) {
            aplicarParcelasSimuladas({ ...data, quantidade_parcelas: body.quantidade_parcelas, taxa: body.taxa });
        } else if (status < 500) {
            showWarning({
                summary: "Aviso.",
                detail: data?.msg || "Não foi possível simular os encargos da venda.",
                life: 3000,
            });
        } else {
            showError({
                summary: "Erro :(",
                detail: "Não foi possível simular os encargos da venda.",
                life: 3000,
            });
        }
    }, [
        realizaCalculo,
        dadosBasicos,
        formik.values.quantidade_parcelas,
        formik.values.valor_para_parcelar,
        taxaAplicada,
        formik.values.valor_entrada_sem_frete,
        showWarning,
        showError,
        aplicarParcelasSimuladas,
    ]);

    useEffect(() => {
        calcularEncargos();
    }, [calcularEncargos]);

    const carregarParcelas = useCallback(async () => {
        setLoading(true);
        const { status, data } = await axiosGet(`/vendas/parcelas-vendas/?venda=${dadosBasicos?.id}&limit=100`);
        setLoading(false);
        if (status === 200 && data.results.length > 0) {
            const taxaMedia = parseNumber(dadosBasicos?.taxa_media_parcelas);
            setParcelasVenda(data.results.map((p) => ({ ...p, taxa: taxaMedia })));
            setParcelasVendaCtx(data.results);
            setTaxaAplicada(taxaMedia);
        } else if (status !== 200) {
            showError({
                summary: "Erro :(",
                detail: "Não foi possível carregar as parcelas da entrada.",
                life: 3000,
            });
        }
    }, [dadosBasicos, showError, setParcelasVendaCtx]);

    useEffect(() => {
        carregarParcelas();
    }, [carregarParcelas]);

    const taxaMaxEncargos = useMemo(() => {
        const param = getParam(VENDAS_ENCARGOS_TAXA_MAXIMA, empresaSelecionadaId);
        if (param) return parseNumber(param.valor);
        return planoRecebimento?.taxa_alvo || 100;
    }, [getParam, planoRecebimento, empresaSelecionadaId]);

    const limiteMax2Parcela = useMemo(() => {
        const param = getParam(VENDAS_VENCIMENTO_LIMITE_SEGUNDAPARCELA);
        if (param) return parseNumber(param.valor);
        return 31;
    }, [getParam]);

    const limiteDiasVendaAVista = useMemo(() => {
        const param = getParam(VENDA_FINANCEIRO_MAXIMO_DIAS_AVISTA);
        if (param) return parseNumber(param.valor);
        return 15;
    }, [getParam]);

    const onChangeValorEntrada = (value) => {
        if (value > formik.values.valor_total_sem_voucher) {
            formik.setFieldError("valor_entrada", "Esse campo não pode ser maior que 'total sem o voucher'.");
            return;
        }
        setFieldValue("valor_entrada", value);
        setRealizaCalculo(true);
    };

    const voucherItemTemplate = (option) => {
        return <span>{`${option.codigo} - ${parseMoeda(option.saldo)}`}</span>;
    };

    const voucherSelecionadoTemplate = (option, props) => {
        if (option) return voucherItemTemplate(option);
        return <span>{props.placeholder}</span>;
    };

    const possuiEncargos = !!planoRecebimento?.taxa_alvo;

    const totalParcelas = parcelasVenda.reduce((total, parcela) => total + parseNumber(parcela.valor_parcela), 0);

    return (
        <>
            <form onSubmit={formik.handleSubmit}>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="valor-frete">Valor do frete</label>
                        <MakoInputMoeda
                            id="valor-frete"
                            name="valor_frete"
                            valueMoeda={formik.values.valor_frete}
                            onChangeMoeda={formik.handleChange}
                        />
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="total-venda">Total dos itens</label>
                        <MakoInputMoeda
                            id="total-venda"
                            name="valor_total_itens"
                            disabled
                            valueMoeda={formik.values.valor_total_itens}
                        />
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="voucher">Voucher</label>
                        <Dropdown
                            id="voucher"
                            name="voucher"
                            url={`/vendas/voucher/?cliente=${dadosBasicos?.cliente?.id}&situacao=A`}
                            setObjects={setVouchers}
                            optionValue="id"
                            optionLabel="codigo"
                            itemTemplate={voucherItemTemplate}
                            valueTemplate={voucherSelecionadoTemplate}
                            value={formik.values.voucher}
                            onChange={(e) => {
                                if (e.value) {
                                    const voucher = vouchers.find((v) => v.id === e.value);
                                    let valorVoucher = voucher.saldo;
                                    if (valorVoucher > formik.values.valor_total_sem_voucher)
                                        valorVoucher = formik.values.valor_total_sem_voucher;
                                    setFieldValue("valor_voucher", valorVoucher);
                                } else {
                                    setFieldValue("valor_voucher", 0);
                                }
                                formik.handleChange(e);
                            }}
                            className={classNames({ "p-invalid": formik.errors.voucher })}
                        />
                        {formik.errors.voucher && <small className="p-error">{formik.errors.voucher}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="total-sem-voucher">Total sem o voucher</label>
                        <MakoInputMoeda
                            id="total-sem-voucher"
                            name="valor_total_sem_voucher"
                            disabled
                            valueMoeda={formik.values.valor_total_sem_voucher}
                        />
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="valor-entrada">Valor recebido / entrada</label>
                        <MakoInputMoeda
                            id="valor-entrada"
                            name="valor_entrada"
                            min={0}
                            max={formik.values.valor_total_sem_voucher}
                            valueMoeda={formik.values.valor_entrada}
                            onChangeMoeda={(e) => onChangeValorEntrada(e.value)}
                            className={classNames({ "p-invalid": formik.errors.valor_entrada })}
                        />
                        {formik.errors.valor_entrada && (
                            <small className="p-error">{formik.errors.valor_entrada}</small>
                        )}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="venc-entrada">Vencimento da entrada</label>
                        <MakoCalendar
                            id="venc-entrada"
                            name="data_venc_entrada"
                            minDate={dadosBasicos ? dadosBasicos.data : new Date()}
                            maxDate={
                                dadosBasicos
                                    ? add(parseData(dadosBasicos.data), { days: limiteDiasVendaAVista })
                                    : add(new Date(), { days: limiteDiasVendaAVista })
                            }
                            disabled={formik.values.valor_entrada === 0 && formik.values.valor_voucher === 0}
                            valueCalendar={formik.values.data_venc_entrada}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.data_venc_entrada })}
                        />
                        {formik.errors.data_venc_entrada && (
                            <small className="p-error">{formik.errors.data_venc_entrada}</small>
                        )}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="forma-pgto-entrada">Forma de receb. da entrada</label>
                        <Dropdown
                            id="forma-pgto-entrada"
                            name="forma_recebimento_entrada"
                            disabled={formik.values.valor_entrada === 0 && formik.values.valor_voucher === 0}
                            url="/financeiro/formas-recebimentos/?utilizar_entrada=true"
                            optionLabel="descricao"
                            value={formik.values.forma_recebimento_entrada}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.forma_recebimento_entrada })}
                        />
                        {formik.errors.forma_recebimento_entrada && (
                            <small className="p-error">{formik.errors.forma_recebimento_entrada}</small>
                        )}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="valor-para-parcelar">Valor para parcelar (s/ frete)</label>
                        <MakoInputMoeda
                            id="valor-para-parcelar"
                            name="valor_para_parcelar"
                            disabled
                            valueMoeda={formik.values.valor_para_parcelar}
                        />
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="qtd-parcelas">Quantidade de parcelas *</label>
                        <Dropdown
                            id="qtd-parcelas"
                            name="quantidade_parcelas"
                            options={opcoesParcelas}
                            value={formik.values.quantidade_parcelas}
                            disabled={vendaAVista}
                            onChange={(e) => {
                                formik.handleChange(e);
                                setRealizaCalculo(true);
                            }}
                            className={classNames({ "p-invalid": formik.errors.quantidade_parcelas })}
                        />
                        {formik.errors.quantidade_parcelas && (
                            <small className="p-error">{formik.errors.quantidade_parcelas}</small>
                        )}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="venc-inicial">Vencimento 1ª parcela *</label>
                        <MakoCalendar
                            id="venc-inicial"
                            name="data_vencimento"
                            disabled={vendaAVista}
                            minDate={dadosBasicos ? dadosBasicos.data : new Date()}
                            maxDate={
                                dadosBasicos
                                    ? add(parseData(dadosBasicos.data), { days: limiteMax2Parcela })
                                    : add(new Date(), { days: limiteMax2Parcela })
                            }
                            valueCalendar={formik.values.data_vencimento}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.data_vencimento })}
                        />
                        {formik.errors.data_vencimento && (
                            <small className="p-error">{formik.errors.data_vencimento}</small>
                        )}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="forma-pgto">Forma de recebimento *</label>
                        <Dropdown
                            id="forma-pgto"
                            name="forma_recebimento"
                            options={formasRecebimento}
                            optionLabel="descricao"
                            disabled={vendaAVista}
                            value={formik.values.forma_recebimento}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.forma_recebimento })}
                        />
                        {formik.errors.forma_recebimento && (
                            <small className="p-error">{formik.errors.forma_recebimento}</small>
                        )}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="valor-total-parcelas">Valor total parcelas (c/ frete)</label>
                        <MakoInputMoeda
                            id="valor-total-parcelas"
                            name="valor_total_parcelas"
                            disabled
                            valueMoeda={formik.values.valor_total_parcelas}
                        />
                    </div>
                </div>
                {planoRecebimento && (
                    <h5 className="p-m-0">
                        {`Intervalo de parcelas do plano: ${planoRecebimento.intervalo_parcelas.split(",").join(", ")}`}
                    </h5>
                )}
                <CamposObrigatorios />
                <div className="p-grid">
                    <div className="p-col-12 p-md-6">
                        <Button type="submit" icon="pi pi-plus" label="Gerar parcelas" className="p-mr-2" />
                        <Button
                            type="button"
                            icon="fas fa-glasses"
                            label="Simular parcelas"
                            disabled={vendaAVista || !possuiEncargos}
                            severity="help"
                            onClick={() =>
                                modalSimuladorParcelasRef.current?.abrirModal(
                                    formik.values.valor_para_parcelar,
                                    formik.values.valor_entrada_com_voucher,
                                    formik.values.valor_frete
                                )
                            }
                        />
                    </div>
                </div>
            </form>
            <MakoListagem
                colunas={colunas}
                dadosLocal={parcelasVenda}
                configTabela={{ rowClassName: rowClass, paginator: true, loading }}
            />
            {parcelasVenda.filter((p) => p._editada).length > 0 && (
                <p className="p-error p-mt-2">É necessário realizar uma nova gravação das parcelas.</p>
            )}
            <div className="p-grid p-justify-between p-mt-3">
                <div className="p-ml-3">
                    <Button
                        icon="pi pi-save"
                        type="button"
                        label="Gravar parcelas"
                        disabled={vendaFinalizada}
                        onClick={handleGravarParcelas}
                        className="p-button-success"
                    />
                </div>
                <div className="p-text-right p-mr-3">
                    <h6 className="p-m-0">{`Total das parcelas: ${parseMoeda(totalParcelas)}`}</h6>
                    <h6 className="p-m-0">{`Taxa: ${formatarCasasDecimais(taxaAplicada, 3)} % - Encargos: ${parseMoeda(
                        totalParcelas - formik.values.valor_total_itens - formik.values.valor_frete
                    )}`}</h6>
                </div>
            </div>
            <ModalSimuladorParcelas
                ref={modalSimuladorParcelasRef}
                opcoesParcelas={opcoesParcelas}
                taxas={{
                    min: planoRecebimento?.taxa_minima,
                    alvo: planoRecebimento?.taxa_alvo,
                    max: taxaMaxEncargos,
                }}
                onConfirmar={(e) => {
                    aplicarParcelasSimuladas(e);
                    formik.handleSubmit();
                }}
            />
            <ModalEditarParcela
                ref={modalEditarParcelaRef}
                formasRecebimento={formasRecebimento}
                planoRecebimento={planoRecebimento}
                taxaMax={taxaMaxEncargos}
                taxaMin={planoRecebimento?.taxa_minima}
                dataMinVencimento={formik.values.data_venc_entrada || dadosBasicos?.data}
                parcelas={parcelasVenda}
                onSuccess={editarParcela}
            />
        </>
    );
};
