import React, { useCallback, useEffect, useState } from "react";
import classNames from "classnames";
import { useHistory, useLocation } from "react-router-dom";
import { MultiSelect } from "primereact/multiselect";
import { Checkbox } from "primereact/checkbox";
import { useFormik } from "formik";
import * as Yup from "yup";
import { InputText } from "primereact/inputtext";

import { PageBase } from "@/components/PageBase";
import { Label } from "@/components/Label";
import { Dropdown } from "@/components/Dropdown";
import { MakoButton as Button } from "@/components/MakoButton";
import { CamposObrigatorios } from "@/components/CamposObrigatorios";
import { RELATORIO_IMPRESSORATERMICA_PROPRIETARIO_TOKEN } from "@/assets/constants/parametros";
import { axiosGet, axiosPatch, axiosPost } from "@/services/http";
import useLoadingLocal from "@/hooks/useLoadingLocal";
import useToast from "@/hooks/useToast";
import useEmpresa from "@/hooks/useEmpresa";
import useParam from "@/hooks/useParam";

const BASE_URL = "/relatorios/impressora-termica/";
const BASE_URL_VOLTAR = "/configuracoes/dispositivos/impressoras-termicas";
const INITIAL_VALUES = {
    nome: "",
    empresas: null,
    id_externo: null,
    ativa: true,
};

export const ImpressoraTermicaForm = () => {
    const [empresas, setEmpresas] = useState([]);
    const [tokenProprietario, setTokenProprietario] = useState(null);
    const { state } = useLocation();
    const history = useHistory();
    const [loading, showLoading, hideLoading] = useLoadingLocal();
    const { showSuccess, showError } = useToast();
    const { empresaSelecionadaId } = useEmpresa();
    const { getParam } = useParam();

    const { setFieldValue, ...formik } = useFormik({
        initialValues: state ? { ...state, empresas: state.empresas.map((e) => e.id) } : INITIAL_VALUES,
        onSubmit: handleSubmit,
    });

    const listarEmpresasVinculadas = useCallback(async () => {
        const { status, data } = await axiosGet("/pessoas/grupos-perfis/");
        if (status === 200)
            if (data.results.length > 0)
                setEmpresas(
                    await data.results.map((vinculo) => {
                        const { perfil } = vinculo;
                        return { ...perfil, label: `${perfil.nome} - ${perfil.identificacao}` };
                    })
                );
    }, []);

    const obterTokenProprietario = useCallback(() => {
        const param = getParam(RELATORIO_IMPRESSORATERMICA_PROPRIETARIO_TOKEN, empresaSelecionadaId);
        if (param) setTokenProprietario(param.valor);
    }, [getParam, empresaSelecionadaId]);

    useEffect(() => {
        obterTokenProprietario();
    }, [obterTokenProprietario]);

    useEffect(() => {
        listarEmpresasVinculadas();
    }, [listarEmpresasVinculadas]);

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                nome: Yup.string().required("O campo 'nome' é obrigatório."),
                id_externo: Yup.string()
                    .required("O campo 'impressora externa' é obrigatório.")
                    .typeError("Você precisa selecionar uma impressora externa para vincular."),
                empresas: Yup.array()
                    .min(1, "Selecione ao menos uma empresa.")
                    .typeError("Selecione uma opção válida."),
            });
            await formSchema.validate(values, { abortEarly: false });
            const body = {
                ...values,
                empresas: { add: values.empresas },
            };
            if (!values.id) {
                showLoading();
                const { status } = await axiosPost(BASE_URL, body);
                hideLoading();
                if (status === 201) {
                    showSuccess({
                        summary: "Sucesso",
                        detail: "Impressora térmica cadastrada com sucesso.",
                        life: 1500,
                    });
                    setTimeout(() => {
                        history.push(BASE_URL_VOLTAR);
                    }, 1500);
                } else {
                    showError({
                        summary: "Erro :(",
                        detail: "Desculpe, não foi possível processar a requisição.",
                        life: 3000,
                    });
                }
            } else {
                showLoading();
                const { status } = await axiosPatch(`${BASE_URL}${values.id}/`, body);
                hideLoading();
                if (status === 200) {
                    showSuccess({
                        summary: "Sucesso",
                        detail: "Impressora térmica alterada com sucesso!",
                        life: 1500,
                    });
                    setTimeout(() => {
                        history.push(BASE_URL_VOLTAR);
                    }, 1500);
                } else {
                    showError({
                        summary: "Erro :(",
                        detail: "Desculpe, não foi possível processar a requisição.",
                        life: 3000,
                    });
                }
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            }
        }
    }

    return (
        <PageBase>
            <h5>{!formik.values.id ? "Nova impressora térmica" : "Manutenção de impressora térmica"}</h5>
            <form onSubmit={formik.handleSubmit}>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-6">
                        <Label htmlFor="nome" label="Nome" obrigatorio />
                        <InputText
                            id="nome"
                            name="nome"
                            value={formik.values.nome}
                            onChange={formik.handleChange}
                            autoComplete="off"
                            className={classNames({ "p-invalid": formik.errors.nome })}
                        />
                        {formik.errors.nome && <small className="p-error">{formik.errors.nome}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-6">
                        <Label htmlFor="empresas" label="Empresas" obrigatorio />
                        <MultiSelect
                            id="empresas"
                            name="empresas"
                            options={empresas}
                            optionValue="id"
                            optionLabel="label"
                            placeholder="Selecione uma ou mais empresa(s)"
                            filterBy="nome,identificacao"
                            filter
                            value={formik.values.empresas}
                            onChange={formik.handleChange}
                            className="multiselect-custom"
                        />
                        {formik.errors.empresas && <small className="p-error">{formik.errors.empresas}</small>}
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-6">
                        <Label htmlFor="impressora-externa" label="Impressora remota" obrigatorio />
                        <Dropdown
                            id="impressora-externa"
                            name="id_externo"
                            buscar={!!tokenProprietario}
                            url={`/relatorios/impressora-termica/impressoras-externas/?proprietario=${tokenProprietario}`}
                            optionValue="id"
                            optionLabel="nome"
                            value={formik.values.id_externo}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.id_externo })}
                        />
                        {formik.errors.id_externo && <small className="p-error">{formik.errors.id_externo}</small>}
                    </div>
                    <div className="p-field-checkbox p-col-12 p-md-2 p-mt-5">
                        <Checkbox
                            inputId="ativa"
                            name="ativa"
                            checked={formik.values.ativa}
                            onChange={formik.handleChange}
                        />
                        <Label htmlFor="ativa" label="Ativa?" />
                    </div>
                </div>
                <CamposObrigatorios />
                <div className="p-grid p-col-12 p-md-6">
                    <Button label="Gravar" type="submit" className="p-button-info p-mr-2" loading={loading} />
                    <Button
                        label="Cancelar"
                        type="reset"
                        to={BASE_URL_VOLTAR}
                        className="p-button-danger p-mr-2"
                        loading={loading}
                    />
                </div>
            </form>
        </PageBase>
    );
};
