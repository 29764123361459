import React, { useRef } from "react";
import classNames from "classnames";
import { InputText } from "primereact/inputtext";
import { Checkbox } from "primereact/checkbox";
import { Button } from "primereact/button";
import { useFormik } from "formik";
import * as Yup from "yup";

import MakoListagem from "@/components/MakoListagem";
import { MakoInputTransportador } from "@/components/MakoInputs/MakoInputTransportador";
import { MakoInputMoeda } from "@/components/MakoInputMoeda";
import { BotaoDelete } from "@/components/BotaoDelete";
import { CamposObrigatorios } from "@/components/CamposObrigatorios";
import { axiosPost, axiosPut } from "@/services/http";
import useFormatCNPJCPF from "@/hooks/useFomatCNPJCPF";
import useOrdemCompra from "@/hooks/useOrdemCompra";
import useToast from "@/hooks/useToast";

export const TransportadoresOrdemCompraForm = () => {
    const listagemRef = useRef(null);
    const [formatarDocumento] = useFormatCNPJCPF();
    const { dadosBasicos, permiteAlterar, atualizarValoresOC } = useOrdemCompra();
    const { showError } = useToast();

    const formik = useFormik({
        initialValues: {
            transportador: null,
            trajeto: "",
            cif: false,
            valor_frete: 0,
        },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                transportador: Yup.object().typeError("Você precisa selecionar um transportador."),
                trajeto: Yup.string().max(60, "O campo 'trajeto' não pode ser maior que 60 caracteres."),
            });
            await formSchema.validate(values, { abortEarly: false });
            const vinculo = {
                ...values,
                ordem_compra: dadosBasicos?.id,
                transportador: values.transportador.id,
            };
            if (!values.id) {
                const { status } = await axiosPost("/compras/vinculo-transportador-ordem-compra/", vinculo);
                if (status === 201) {
                    formik.resetForm();
                    listagemRef.current?.buscarDados();
                    await atualizarValoresOC();
                } else {
                    showError({
                        summary: "Erro :(",
                        detail: "Desculpe, não foi possível adicionar o transportador na ordem de compra.",
                        life: 3000,
                    });
                }
            } else {
                const { status } = await axiosPut(`/compras/vinculo-transportador-ordem-compra/${values.id}/`, vinculo);
                if (status === 200) {
                    formik.resetForm();
                    listagemRef.current?.buscarDados();
                    await atualizarValoresOC();
                } else {
                    showError({
                        summary: "Erro :(",
                        detail: "Desculpe, não foi possível alterar o vínculo de transportador na ordem de compra.",
                        life: 3000,
                    });
                }
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            }
        }
    }

    const formatarCNPJBodyTemplate = (rowData) => {
        return formatarDocumento(rowData.transportador.identificacao);
    };

    const cifFobBodyTemplate = (rowData) => {
        if (rowData.cif) return <span>CIF</span>;
        return <span>FOB</span>;
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button
                    icon="pi pi-pencil"
                    disabled={!permiteAlterar}
                    className="p-button-rounded p-button-warning p-mr-2 p-mb-1"
                    onClick={() => formik.setValues(rowData)}
                />
                <BotaoDelete
                    url={`/compras/vinculo-transportador-ordem-compra/`}
                    objetoId={rowData.id}
                    disabled={!permiteAlterar}
                    exigeConfirmacao
                    msgConfirmacao={
                        <span>
                            Deseja mesmo excluir o transportador <b>{rowData.transportador.nome}</b>?
                        </span>
                    }
                    msgToastErroExclusao="O transportador não pode ser excluído."
                    classNames="p-mr-2 p-mb-1"
                    onDelete={async () => {
                        listagemRef.current?.buscarDados();
                        await atualizarValoresOC();
                    }}
                />
            </div>
        );
    };

    const colunasTransportadores = [
        { field: "transportador.nome", header: "Nome" },
        {
            field: "transportador.identificacao",
            header: "CNPJ",
            style: { width: "15%" },
            action: (e) => formatarCNPJBodyTemplate(e),
        },
        { field: "trajeto", header: "Trajeto" },
        { field: "valor_frete", header: "Valor frete", money: true },
        { field: "cif", header: "CIF / FOB", action: (e) => cifFobBodyTemplate(e) },
        {
            field: "action",
            header: "Ações",
            action: (e) => actionBodyTemplate(e),
            style: { width: "10%" },
        },
    ];

    return (
        <>
            <form onSubmit={formik.handleSubmit}>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-6">
                        <label htmlFor="transportador">Transportador *</label>
                        <MakoInputTransportador
                            id="transportador"
                            name="transportador"
                            value={formik.values.transportador}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.transportador })}
                        />
                        {formik.errors.transportador && (
                            <small className="p-error">{formik.errors.transportador}</small>
                        )}
                    </div>
                    <div className="p-field p-col-12 p-md-6">
                        <label htmlFor="trajeto">Trajeto</label>
                        <InputText
                            id="trajeto"
                            name="trajeto"
                            value={formik.values.trajeto}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.trajeto })}
                        />
                        {formik.errors.trajeto && <small className="p-error">{formik.errors.trajeto}</small>}
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="valor-frete-previsto">Valor do frete previsto</label>
                        <MakoInputMoeda
                            id="valor-frete-previsto"
                            name="valor_frete_previsto"
                            disabled
                            valueMoeda={dadosBasicos?.valor_frete_previsto || 0}
                        />
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="valor-frete">Valor do frete</label>
                        <MakoInputMoeda
                            id="valor-frete"
                            name="valor_frete"
                            valueMoeda={formik.values.valor_frete}
                            onChangeMoeda={formik.handleChange}
                        />
                    </div>
                    <div className="p-field-checkbox p-col-12 p-md-3 p-mt-5">
                        <Checkbox id="cif" name="cif" checked={formik.values.cif} onChange={formik.handleChange} />
                        <label htmlFor="cif">Frete pago pelo emitente?</label>
                    </div>
                </div>
                <CamposObrigatorios />
                <div className="p-grid">
                    <div className="p-col-12 p-md-6">
                        <Button
                            type="submit"
                            icon="pi pi-plus"
                            disabled={!permiteAlterar}
                            label="Adicionar"
                            className="p-mr-2 p-mb-2"
                        />
                        <Button
                            label="Limpar"
                            type="reset"
                            disabled={!permiteAlterar}
                            icon="pi pi-trash"
                            className="p-button-warning p-mr-2 p-mb-2"
                            onClick={() => formik.resetForm()}
                        />
                    </div>
                </div>
            </form>
            <MakoListagem
                ref={listagemRef}
                urlPesquisa={`/compras/vinculo-transportador-ordem-compra/?ordem_compra=${dadosBasicos?.id}`}
                colunas={colunasTransportadores}
                configTabela={{ lazy: true, paginator: true }}
            />
        </>
    );
};
