import React, { useCallback, useEffect, useRef, useState } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { InputNumber } from "primereact/inputnumber";
import { Dropdown } from "primereact/dropdown";
import { useFormik } from "formik";
import { axiosGet, axiosPatch, axiosPost } from "@/services/http";
import { useHistory } from "react-router-dom";

import * as Yup from "yup";
import classNames from "classnames";
import useLoading from "@/hooks/useLoading";
import { TIPO_CALCULO_FAIXA_APURACAO_CHOICE, TIPO_ESCOPO_FAIXA_COMISSAO_CHOICE } from "@/assets/constants/constants";

const urlvoltar = "/vendas/cadastros/faixa-comissao";

export const FaixaComissaoForm = (props) => {
    const [listaRegras, setListaRegras] = useState([]);
    const { showLoading, hideLoading } = useLoading();

    const history = useHistory();
    const toastRef = useRef(null);

    const faixaVazia = {
        codigo: null,
        regra_apuracao: null,
        escopo: null,
        tipo_calculo: null,
        minimo: null,
        maximo: null,
        percentual: null,
        valor_fixo: null,
    };

    const formik = useFormik({
        initialValues: props.location.state
            ? {
                  ...props.location.state.faixa,
                  escopo: props.location.state.faixa.escopo?.id,
                  tipo_calculo: props.location.state.faixa.tipo_calculo?.id,
                  regra_apuracao: props.location.state.faixa.regra_apuracao?.id,
              }
            : faixaVazia,
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                codigo_faixa: Yup.string().max(3).required("O campo 'código' é obrigatório."),
                tipo_calculo: Yup.string().required("O campo 'tipo calculo' é obrigatório ").typeError("Informe um 'tipo calculo' válido"),
                escopo: Yup.string().required("O campo 'escopo' é obrigatório ").typeError("Informe um 'escopo' válido"),
                minimo: Yup.number().nullable(),
                maximo: Yup.number().when("minimo", {
                    is: (val) => !!val,
                    then: Yup.number()
                        .nullable()
                        .min(values.minimo, "O campo 'maximo' deve ter um valor máior que o minimo")
                        .typeError("informe um 'maximo' válido"),
                    otherwise: Yup.number().nullable(),
                })
            });

            await formSchema.validate(values, {
                abortEarly: false,
            });
            if (values.tipo_calculo === "P") values.valor_fixo = null;
            else values.percentual = null;
            if (!values.id) {
                showLoading();
                const resposta = await axiosPost(`/vendas/faixa-comissao/`, values);
                hideLoading();
                if (resposta.status === 201) {
                    toastRef.current.show({
                        severity: "success",
                        summary: "Sucesso",
                        detail: "Faixa de comissão cadastrada com sucesso!",
                        life: 1500,
                    });

                    setTimeout(() => {
                        formik.resetForm();
                        history.push(urlvoltar);
                    }, 2000);
                } else {
                    toastRef.current.show({
                        severity: "error",
                        summary: "Erro",
                        detail: "Desculpe, não conseguimos processar a sua requisição.",
                        life: 1500,
                    });
                }
            } else {
                showLoading();
                const resposta = await axiosPatch(`/vendas/faixa-comissao/${values.id}/`, values);
                hideLoading();

                if (resposta.status === 200) {
                    toastRef.current.show({
                        severity: "success",
                        summary: "Sucesso",
                        detail: "Faixa de comissão alterada com sucesso!",
                        life: 1500,
                    });

                    setTimeout(() => {
                        formik.resetForm();
                        history.push(urlvoltar);
                    }, 2000);
                } else {
                    toastRef.current.show({
                        severity: "error",
                        summary: "Erro",
                        detail: "Desculpe, não conseguimos processar a sua requisição.",
                        life: 1500,
                    });
                }
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};

                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });

                formik.setErrors(errorMessages);
                hideLoading();
            } else {
                toastRef.current.show({
                    severity: "error",
                    summary: "Erro",
                    detail: "Desculpe, não conseguimos processar a sua requisição.",
                    life: 1500,
                });
                hideLoading();
            }
        }
    }

    const listarRegras = useCallback(async () => {
        showLoading();
        const resposta = await axiosGet("/vendas/regra-apuracao-comissao-venda?limit=1000");
        hideLoading();

        if (resposta.status === 200) setListaRegras(resposta.data.results);
    }, [showLoading, hideLoading]);

    const cancelar = () => {
        formik.resetForm();
        history.push(urlvoltar);
    };

    useEffect(() => {
        listarRegras();
    }, [listarRegras]);

    return (
        <div className="p-grid">
            <div className="p-col-12">
                <div className="card">
                    <Toast ref={toastRef} />
                    <h3>{!formik.values.id ? "Nova faixa de comissão" : "Manutenção de faixa de comissão"}</h3>
                    <form onSubmit={formik.handleSubmit}>
                        <div className="p-fluid p-formgrid p-grid">
                            <div className="p-field p-col-12 p-md-3">
                                <label htmlFor="codigo_faixa">Código *</label>
                                <InputText
                                    id="codigo_faixa"
                                    name="codigo_faixa"
                                    value={formik.values.codigo_faixa}
                                    onChange={formik.handleChange}
                                    className={classNames({ "p-invalid": formik.errors.codigo_faixa })}
                                />
                                {formik.errors.codigo_faixa && (
                                    <small className="p-error">{formik.errors.codigo_faixa}</small>
                                )}
                            </div>
                            <div className="p-field p-col-12 p-md-3">
                                <label htmlFor="regra_apuracao">Regra de Apuração</label>
                                <Dropdown
                                    id="regra_apuracao"
                                    placeholder="Selecione um tipo de cálculo"
                                    name="regra_apuracao"
                                    onChange={formik.handleChange}
                                    value={formik.values.regra_apuracao}
                                    filter
                                    filterBy="descricao"
                                    optionLabel="descricao"
                                    optionValue="id"
                                    options={listaRegras}
                                />
                                {formik.errors.regra_apuracao && (
                                    <small className="p-error">{formik.errors.regra_apuracao}</small>
                                )}
                            </div>
                            <div className="p-field p-col-12 p-md-3">
                                <label htmlFor="tipo_calculo">Tipo de cálculo *</label>
                                <Dropdown
                                    id="tipo_calculo"
                                    placeholder="Selecione um tipo de cálculo"
                                    name="tipo_calculo"
                                    onChange={formik.handleChange}
                                    value={formik.values.tipo_calculo}
                                    optionLabel="label"
                                    optionValue="value"
                                    options={TIPO_CALCULO_FAIXA_APURACAO_CHOICE}
                                />
                                {formik.errors.tipo_calculo && (
                                    <small className="p-error">{formik.errors.tipo_calculo}</small>
                                )}
                            </div>
                            <div className="p-field p-col-12 p-md-3">
                                <label htmlFor="escopo">Escopo *</label>
                                <Dropdown
                                    id="escopo"
                                    placeholder="Selecione um escopo"
                                    name="escopo"
                                    onChange={formik.handleChange}
                                    value={formik.values.escopo}
                                    optionLabel="label"
                                    optionValue="value"
                                    options={TIPO_ESCOPO_FAIXA_COMISSAO_CHOICE}
                                />
                                {formik.errors.escopo && <small className="p-error">{formik.errors.escopo}</small>}
                            </div>
                        </div>
                        <div className="p-fluid p-formgrid p-grid">
                            <div className="p-field p-col-12 p-md-3">
                                <label htmlFor="minimo">Mínimo</label>
                                <InputNumber
                                    id="minimo"
                                    name="minimo"
                                    mode="decimal"
                                    min={0}
                                    minFractionDigits={2}
                                    maxFractionDigits={2}
                                    value={formik.values.minimo}
                                    onValueChange={formik.handleChange}
                                    className={classNames({ "p-invalid": formik.errors.minimo })}
                                />
                                {formik.errors.minimo && <small className="p-error">{formik.errors.minimo}</small>}
                            </div>
                            <div className="p-field p-col-12 p-md-3">
                                <label htmlFor="maximo">Máximo</label>
                                <InputNumber
                                    id="maximo"
                                    name="maximo"
                                    mode="decimal"
                                    min={0}
                                    minFractionDigits={2}
                                    maxFractionDigits={2}
                                    value={formik.values.maximo}
                                    onValueChange={formik.handleChange}
                                    className={classNames({ "p-invalid": formik.errors.maximo })}
                                />
                                {formik.errors.maximo && <small className="p-error">{formik.errors.maximo}</small>}
                            </div>
                            {formik.values.tipo_calculo === "P" ? (
                                <div className="p-field p-col-12 p-md-3">
                                    <label htmlFor="percentual">% Percentual</label>
                                    <InputNumber
                                        id="percentual"
                                        name="percentual"
                                        mode="decimal"
                                        prefix="%"
                                        min={0}
                                        max={100}
                                        minFractionDigits={2}
                                        maxFractionDigits={5}
                                        value={formik.values.percentual}
                                        onValueChange={formik.handleChange}
                                        className={classNames({ "p-invalid": formik.errors.percentual })}
                                        autoComplete="off"
                                        autoFocus
                                    />
                                    {formik.errors.percentual && (
                                        <small className="p-error">{formik.errors.percentual}</small>
                                    )}
                                </div>
                            ) : (
                                <div className="p-field p-col-12 p-md-3">
                                    <label htmlFor="valor_fixo">Valor fixo</label>
                                    <InputNumber
                                        id="valor_fixo"
                                        name="valor_fixo"
                                        mode="decimal"
                                        min={0}
                                        minFractionDigits={2}
                                        maxFractionDigits={2}
                                        value={formik.values.valor_fixo}
                                        onValueChange={formik.handleChange}
                                        className={classNames({ "p-invalid": formik.errors.valor_fixo })}
                                    />
                                    {formik.errors.valor_fixo && (
                                        <small className="p-error">{formik.errors.valor_fixo}</small>
                                    )}
                                </div>
                            )}
                        </div>
                        <p>
                            <b>* Campos obrigatórios.</b>
                        </p>
                        <div className="p-grid p-col-12 p-md-3">
                            <Button label="Gravar" type="submit" className="p-button-info p-mr-2 p-mb-6" />
                            <Button
                                label="Cancelar"
                                type="reset"
                                className="p-button-danger p-mr-2 p-mb-6"
                                onClick={cancelar}
                            />
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};
