import React, { useMemo } from "react";

import MakoListagem from "@/components/MakoListagem";

import { formatDocumento } from "@/assets/util/util";

const BASE_URL = "/financeiro/caixa-fracionamento-recebimento-rateio/";

const BASE_COLUMS = [
    {
        field: "recebimento.venda",
        header: "Venda",
        style: { width: "8%" },
        align: "center",
    },
    {
        field: "recebimento.devedor.nome",
        header: "Cliente",
        action: ({ recebimento: { devedor } }) => `${devedor?.nome} - ${formatDocumento(devedor?.identificacao)}`,
    },
    {
        field: "recebimento.numero_parcela",
        header: "Parcela",
        align: "center",
        style: { width: "6%" },
        action: ({ recebimento: { numero_parcela, quantidade_parcelas } }) =>
            `${numero_parcela}/${quantidade_parcelas}`,
    },
    {
        field: "recebimento.vencimento",
        header: "Vencimento",
        style: { width: "9%" },
        dateFormat: "dd/MM/yyyy",
        align: "center",
    },
    {
        field: "recebimento.valor",
        header: "Valor original",
        money: "true",
        style: { width: "8%" },
    },
    {
        field: "valor_recebido",
        header: "Val. atualizado",
        money: "true",
        style: { width: "8%" },
    },
];

const BASE_COLUMN_ACTIONS = {
    field: "actions",
    header: "Ações",
    style: { width: "12%" },
};

export const Listagem = ({ listagemRef, actions }) => {
    const colunas = useMemo(() => {
        if (!actions) return BASE_COLUMS;
        const actionColum = {
            ...BASE_COLUMN_ACTIONS,
            action: (e) => actions(e),
        };
        return [...BASE_COLUMS, actionColum];
    }, [actions]);

    return (
        <div className="p-mt-3">
            <MakoListagem
                ref={listagemRef}
                colunas={colunas}
                urlPesquisa={BASE_URL}
                configTabela={{
                    paginator: true,
                    lazy: true,
                }}
            />
        </div>
    );
};
