import React, { useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { InputNumber } from "primereact/inputnumber";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Menu } from "primereact/menu";
import { Tag } from "primereact/tag";

import { FiltroAvancadoEntrada } from "./filtroAvancado";
import { ModalConsultaNotasCnpj } from "./form/ModalNotasCnpj";
import { PageBase } from "@/components/PageBase";
import { MakoDropdownCategoriasHierarquicas } from "@/components/MakoDropdownCategoriasHierarquicas";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import { Dropdown } from "@/components/Dropdown";
import { DateFiltroTemplate, FornecedorFiltroTemplate } from "@/components/MakoFiltrosCabecalho";
import MakoListagem from "@/components/MakoListagem";
import { MakoErrosPersonalizados } from "@/components/MakoErrosPersonalizados";
import { STATUS_ENTRADA } from "@/assets/constants/compras";
import permissoes from "@/assets/constants/permissoes";
import { axiosPost, axiosPut } from "@/services/http";
import useLoading from "@/hooks/useLoading";
import useToast from "@/hooks/useToast";
import useClearRefs from "@/hooks/useClearRefs";

const BASE_URL = "/compras/entradas/?status_in=P,V,F";

export const EntradaProdutosPage = () => {
    const [filtroAvancado, setFiltroAvancado] = useState(false);
    const [totalizadorFiltros, setTotalizadorFiltros] = useState(0);
    const [entrada, setEntrada] = useState(null);
    const [url, setUrl] = useState(BASE_URL);
    const listagemRef = useRef(null);
    const menuRef = useRef(null);
    const modalErrosRef = useRef(null);
    const modalConsultaCnpj = useRef(null);
    const history = useHistory();
    const { showSuccess, showWarning, showError } = useToast();
    const { showLoading, hideLoading } = useLoading();

    useClearRefs(listagemRef, menuRef, modalErrosRef, modalConsultaCnpj);

    const validarEntrada = async () => {
        showLoading();
        const { status, data } = await axiosPut(`/compras/validar-entrada/${entrada.id}/`);
        hideLoading();
        if (status === 200) {
            listagemRef.current?.buscarDados();
        } else if (status !== 500) {
            if (data.erros) {
                modalErrosRef.current?.abrirModal(data.erros);
            } else {
                showWarning({
                    summary: "Aviso",
                    detail: data.msg,
                    life: 3000,
                });
            }
        } else {
            showError({
                summary: "Erro :(",
                detail: "Desculpe, não foi possível validar a entrada.",
                life: 3000,
            });
        }
    };

    const cancelarEntrada = async () => {
        showLoading();
        const { status, data } = await axiosPut(`/compras/entradas/${entrada.id}/cancelar/`);
        hideLoading();
        if (status === 200) {
            listagemRef.current?.buscarDados();
        } else if (status !== 500) {
            showWarning({
                summary: "Aviso",
                detail: data.msg,
                life: 3000,
            });
        } else {
            showError({
                summary: "Erro :(",
                detail: "Desculpe, não foi possível cancelar a entrada.",
                life: 3000,
            });
        }
    };

    const desfazerEntrada = async () => {
        showLoading();
        const { status, data } = await axiosPut(`/compras/entradas/${entrada.id}/desfazer/`);
        hideLoading();
        if (status === 200) {
            listagemRef.current?.buscarDados();
        } else if (status !== 500) {
            showWarning({
                summary: "Aviso",
                detail: data.msg,
                life: 3000,
            });
        } else {
            showError({
                summary: "Erro :(",
                detail: "Desculpe, não foi possível desfazer a entrada.",
                life: 3000,
            });
        }
    };

    const calcularCustosEntrada = async () => {
        showLoading();
        const { status, data } = await axiosPost(`/compras/calcular-custos-entrada/${entrada.id}/`);
        hideLoading();
        if (status === 200) {
            showSuccess({
                summary: "Sucesso!",
                detail: "Custo calculado com sucesso.",
                life: 1500,
            });
            setTimeout(() => listagemRef.current?.buscarDados(), 2000);
        } else if (status !== 500) {
            showWarning({
                summary: "Atenção!",
                detail: data?.msg || "Não foi possível calcular o custo.",
                life: 5000,
            });
        } else {
            showError({
                summary: "Erro :(",
                detail: "Desculpe, não foi possível calcular o custo.",
                life: 3000,
            });
        }
    };

    const painelEsquerdoTabela = (
        <>
            <Button
                label="Nova"
                icon="pi pi-plus"
                className="p-button-success p-mr-2"
                onClick={() => history.push("/compras/entrada/entrada-produtos/form")}
            />
            <Button
                label="Procurar NFe online"
                icon="fas fa-file-code"
                className="p-button-info p-mr-2"
                onClick={() => modalConsultaCnpj.current?.abrirModal()}
            />
            <Button
                label="Filtro avançado"
                icon="pi pi-filter"
                className="p-button-help p-mr-2"
                badge={totalizadorFiltros > 0 ? totalizadorFiltros : null}
                onClick={() => setFiltroAvancado(true)}
            />
            <Button
                label="Limpar filtros"
                icon="pi pi-trash"
                className="p-button-warning p-mr-2"
                onClick={() => {
                    setUrl(BASE_URL);
                    setTotalizadorFiltros(0);
                }}
            />
        </>
    );

    const statusBodyTemplate = (rowData) => {
        const statusEntrada = STATUS_ENTRADA.find((e) => e.value === rowData.status);
        if (!statusEntrada) return <span>N/A</span>;
        return <Tag value={statusEntrada.label.toUpperCase()} severity={statusEntrada.severity} />;
    };

    const confirmarValidacao = () => {
        confirmDialog({
            header: "Confirmação",
            message: (
                <p>
                    Confirma a <strong style={{ color: "#2196f3" }}>VALIDAÇÃO</strong> da entrada{" "}
                    <strong style={{ color: "#2196f3" }}>{entrada?.id}</strong>?
                </p>
            ),
            icon: "pi pi-info-circle",
            acceptLabel: "Confirmar",
            rejectLabel: "Cancelar",
            accept: validarEntrada,
        });
    };

    const confirmarFinalizacao = () => {
        confirmDialog({
            header: "Confirmação",
            message: (
                <p>
                    Confirma o cálculo do custo da entrada <strong>{entrada?.id}</strong>?
                </p>
            ),
            icon: "pi pi-info-circle",
            acceptLabel: "Confirmar",
            rejectLabel: "Cancelar",
            accept: calcularCustosEntrada,
        });
    };

    const confirmarCancelamento = () => {
        confirmDialog({
            header: "Confirmação",
            message: (
                <p>
                    Confirma o <strong style={{ color: "#D32F2F" }}>CANCELAMENTO</strong> da entrada{" "}
                    <strong style={{ color: "#D32F2F" }}>{entrada?.id}</strong>?
                </p>
            ),
            icon: "pi pi-info-circle",
            acceptClassName: "p-button-danger",
            acceptLabel: "Confirmar",
            rejectLabel: "Fechar",
            accept: cancelarEntrada,
        });
    };

    const confirmarDesfazimento = () => {
        confirmDialog({
            header: "Confirmação",
            message: (
                <p>
                    Confirma o <strong style={{ color: "#D32F2F" }}>DESFAZIMENTO</strong> da entrada{" "}
                    <strong style={{ color: "#D32F2F" }}>{entrada?.id}</strong>?
                </p>
            ),
            icon: "pi pi-info-circle",
            acceptClassName: "p-button-danger",
            acceptLabel: "Confirmar",
            rejectLabel: "Fechar",
            accept: desfazerEntrada,
        });
    };

    const itensMenu = [
        {
            label: "Validar",
            icon: "pi pi-check",
            disabled: entrada?.status !== "P" && !entrada?.pre_validada,
            command: () => confirmarValidacao(),
        },
        {
            label: "Finalizar",
            icon: "pi pi-calculator",
            disabled: entrada?.status !== "V",
            command: () => confirmarFinalizacao(),
        },
        {
            label: "Cancelar",
            icon: "pi pi-times",
            disabled: entrada?.status === "C",
            command: () => confirmarCancelamento(),
        },
        {
            label: "Desfazer",
            icon: "pi pi-step-backward",
            disabled: entrada?.status !== "F",
            command: () => confirmarDesfazimento(),
        },
    ];

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <MakoControleAcesso
                    permissao={[permissoes.PRODUTO_ENTRADA_ENTRADA_EDITAR]}
                    componente={Button}
                    icon="pi pi-pencil"
                    className="p-button-rounded p-button-warning p-mr-2 p-mb-1"
                    onClick={() =>
                        history.push({
                            pathname: "/compras/entrada/entrada-produtos/form",
                            state: {
                                entradaId: rowData.id,
                            },
                        })
                    }
                />
                <MakoControleAcesso
                    permissao={[permissoes.PRODUTO_ENTRADA_ENTRADA_EDITAR]}
                    componente={Button}
                    icon="pi pi-cog"
                    className="p-button-rounded p-button-info p-mb-1"
                    onClick={(e) => {
                        setEntrada(rowData);
                        menuRef.current?.toggle(e);
                    }}
                />
            </div>
        );
    };

    const codigoFiltroTemplate = (options) => {
        return <InputNumber value={options.value} onValueChange={(e) => options.filterCallback(e.value)} />;
    };

    const categoriaFiltroTemplate = (options) => {
        return (
            <MakoDropdownCategoriasHierarquicas
                categoriaTituloSelecionavel
                value={options.value}
                onChange={(e) => options.filterCallback(e.value)}
            />
        );
    };

    const statusFiltroTemplate = (options) => {
        return (
            <Dropdown
                placeholder="Selecione"
                showClear
                value={options.value}
                options={STATUS_ENTRADA}
                onChange={(e) => options.filterCallback(e.value)}
            />
        );
    };

    const colunas = [
        {
            field: "id",
            header: "Nº Entrada",
            filter: true,
            filterElement: codigoFiltroTemplate,
            style: { width: "9%" },
        },
        {
            field: "datahora_entrada",
            header: "Data",
            dateFormat: "dd/MM/yyyy",
            filter: true,
            filterElement: DateFiltroTemplate,
            filterField: "datahora_entrada__date",
            style: { width: "10%" },
        },
        {
            field: "fornecedor.nome",
            header: "Fornecedor",
            filter: true,
            filterElement: FornecedorFiltroTemplate,
            filterField: "fornecedor",
        },
        {
            field: "categoria_titulo",
            header: "Categoria",
            action: ({ categoria_titulo }) => categoria_titulo?.nome,
            filter: true,
            filterElement: categoriaFiltroTemplate,
        },
        {
            field: "status",
            header: "Situação",
            style: { width: "10%" },
            filter: true,
            filterElement: statusFiltroTemplate,
            action: (e) => statusBodyTemplate(e),
        },
        { field: "action", header: "Ações", style: { width: "10%" }, action: (e) => actionBodyTemplate(e) },
    ];

    const fecharDialog = () => {
        setFiltroAvancado(() => false);
    };

    const onConfirmFiltro = (newUrl, contador) => {
        setUrl(newUrl);
        setTotalizadorFiltros(contador);
        fecharDialog();
    };

    const tipoErroBodyTemplate = (rowData) => {
        if (rowData.tipo === "error") return <Tag severity="danger" value="ERRO" />;
        return <Tag severity="warning" value="AVISO" />;
    };

    const colunasErros = [
        { field: "sku", header: "Cód produto", style: { width: "15%" } },
        { field: "erro", header: "Mensagem" },
        { field: "tipo", header: "Erro / Aviso", style: { width: "12%" }, action: (e) => tipoErroBodyTemplate(e) },
    ];

    return (
        <PageBase>
            <MakoListagem
                ref={listagemRef}
                titulo="Entrada de produtos"
                painelEsquerdo={painelEsquerdoTabela}
                colunas={colunas}
                urlPesquisa={url}
                filtarPorEmpresa
                naoBuscarSemEmpresa
                configTabela={{
                    lazy: true,
                    paginator: true,
                }}
                filtros={{
                    id: { value: "", matchMode: "equals" },
                    datahora_entrada__date: { value: null, matchMode: "equals" },
                    status: { value: null, matchMode: "equals" },
                    fornecedor: { value: "", matchMode: "equals" },
                    categoria_titulo: { value: null, matchMode: "equals" },
                }}
            />
            <ConfirmDialog />
            <Menu model={itensMenu} popup ref={menuRef} />
            <MakoErrosPersonalizados ref={modalErrosRef} titulo="Inconsistências encontradas" colunas={colunasErros} />
            <Dialog
                header="Filtro avançado"
                visible={filtroAvancado}
                breakpoints={{ "960px": "75vw" }}
                style={{ width: "75vw", display: "block" }}
                onHide={() => fecharDialog()}
            >
                <FiltroAvancadoEntrada onConfirm={onConfirmFiltro} onCancel={fecharDialog} baseUrl={BASE_URL} />
            </Dialog>
            <ModalConsultaNotasCnpj ref={modalConsultaCnpj} />
        </PageBase>
    );
};
