import React, { useState, useCallback, useEffect, memo, forwardRef, useImperativeHandle } from "react";
import { TreeSelect } from "primereact/treeselect";
import { Button } from "primereact/button";

import { MAKO_ICONS } from "@/assets/constants/constants_styles";
import { axiosGet } from "@/services/http";
import useToast from "@/hooks/useToast";

const Componente = (
    {
        grupoId,
        categoriaPai,
        buscarTodas,
        categoriaTituloSelecionavel,
        showClear,
        apenasTitulo,
        getCategoriaCompleta,
        ...props
    },
    ref
) => {
    const [categorias, setCategorias] = useState([]);
    const { showError } = useToast();

    const percorreListaSelecionavel = useCallback(
        (lista) => {
            lista.forEach((l) => {
                l.label = l.data.nome;
                if (l.data.tipo === "T" && !categoriaTituloSelecionavel) {
                    l.selectable = false;
                } else {
                    l.selectable = true;
                }

                if (l.children) {
                    percorreListaSelecionavel(l.children);
                }
            });

            return lista;
        },
        [categoriaTituloSelecionavel]
    );

    const localizaCategoria = useCallback((lista, key) => {
        let categoria = null;
        for (let x = 0; x < lista.length; x++) {
            if (lista[x].key === parseInt(key)) {
                return lista[x].data;
            }

            if (lista[x].children) {
                categoria = localizaCategoria(lista[x].children, key);
            }

            if (categoria) {
                break;
            }
        }

        return categoria;
    }, []);

    const listarCategoriasHierarquicas = useCallback(async () => {
        let url = "/produtos/categorias-menu?";
        if (buscarTodas) {
            url = "/produtos/categorias-menu?grupo_id=False";
        } else if (grupoId) {
            url = `/produtos/categorias-menu?grupo_id=${grupoId}`;
        } else if (categoriaPai) {
            url = `/produtos/categorias-menu?categoria_pai=${categoriaPai}`;
        }
        if (apenasTitulo) {
            if (url[url.length - 1] === "/") {
                url = `${url}?&tipo=T`;
            } else {
                url = `${url}&tipo=T`;
            }
        }
        const { status, data } = await axiosGet(url);
        if (status === 200) {
            setCategorias(percorreListaSelecionavel(data));
        } else {
            showError({
                summary: "Erro :(",
                detail: "Desculpe, não conseguimos listar suas categorias.",
                life: 3000,
            });
        }
    }, [grupoId, categoriaPai, buscarTodas, apenasTitulo, percorreListaSelecionavel, showError]);

    useEffect(() => {
        listarCategoriasHierarquicas();
    }, [listarCategoriasHierarquicas]);

    const limparSelecao = (e) => {
        if (props?.onChange) {
            if (props?.name && props?.id) {
                props.onChange(
                    {
                        ...e,
                        target: {
                            name: props?.name,
                            id: props?.id,
                        },
                    },
                    null
                );
            } else {
                props.onChange(e, null);
            }
        }
        if (getCategoriaCompleta) {
            getCategoriaCompleta(null);
        }
    };

    const onChangeCategoria = (e) => {
        if (props.onChange) {
            props.onChange(e);
        }

        if (typeof getCategoriaCompleta === "function") {
            const { selectionMode } = props;
            if (selectionMode !== "checkbox") {
                getCategoriaCompleta(localizaCategoria(categorias, e.value));
            } else {
                let _categorias = [];
                Object.keys(e.value).forEach((key) => {
                    if (e.value[key].checked) _categorias.push(localizaCategoria(categorias, key));
                });
                getCategoriaCompleta(_categorias);
            }
        }
    };

    const getCategoria = useCallback(
        (id) => {
            return localizaCategoria(categorias, parseInt(id));
        },
        [categorias, localizaCategoria]
    );

    useImperativeHandle(ref, () => ({ getCategoria }), [getCategoria]);

    return (
        <>
            <div className="p-inputgroup">
                <TreeSelect
                    placeholder={props?.placeholder || "Selecione uma categoria"}
                    filter
                    filterBy="data.nome"
                    emptyMessage="Nenhuma categoria encontrada."
                    options={categorias}
                    {...props}
                    onChange={onChangeCategoria}
                />
                {showClear && (
                    <Button
                        type="button"
                        icon={MAKO_ICONS.LIMPAR_INPUT}
                        onClick={(e) => limparSelecao(e)}
                        className="p-button-secondary"
                    />
                )}
            </div>
        </>
    );
};

export const MakoDropdownCategoriasHierarquicas = memo(forwardRef(Componente));
