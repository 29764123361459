import React, { useRef } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { useFormik } from "formik";
import { MakoCalendar } from "@/components/MakoCalendar";
import { useHistory } from "react-router-dom";
import { axiosPatch, axiosPost } from "@/services/http";
import { dataToStr } from "@/assets/util/datas";

import useLoading from "@/hooks/useLoading";
import classNames from "classnames";
import * as Yup from "yup";

const url = "/financeiro/competencias/";
const urlvoltar = "/financeiro/cadastros/competencia";

export const FinanceiroCompetenciaForm = (props) => {
    const { showLoading, hideLoading } = useLoading();
    const toastRef = useRef(null);
    const history = useHistory();
    const competenciaVazia = {
        codigo: "",
        grupo_competencia: "",
        data_inicio: null,
        data_fim: null,
    };

    const formik = useFormik({
        initialValues: props.location.state ? props.location.state.competencia : competenciaVazia,
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                codigo: Yup.string().required("O campo 'código' é obrigatório.").max(20),
                grupo_competencia: Yup.string().required("O campo 'grupo de competẽncia' é obrigatório.").max(30),
                data_inicio: Yup.date().required("O campo 'data inicial' é obrigatório."),
                data_fim: Yup.date()
                    .required("O campo 'data final' é obrigatório.")
                    .min(values.data_inicio, "A data final não pode ser maior que a data inicial."),
            });

            await formSchema.validate(values, {
                abortEarly: false,
            });

            let data_inicio = dataToStr(values.data_inicio, "yyyy-MM-dd");
            values.data_inicio = data_inicio;

            let data_fim = dataToStr(values.data_fim, "yyyy-MM-dd");
            values.data_fim = data_fim;

            if (!values.id) {
                showLoading();
                const resposta = await axiosPost(`${url}`, values);
                hideLoading();

                if (resposta.status === 201) {
                    toastRef.current.show({
                        severity: "success",
                        summary: "Sucesso",
                        detail: "Competência cadastrada com sucesso!",
                        life: 1500,
                    });

                    setTimeout(() => {
                        formik.resetForm();
                        history.push(urlvoltar);
                    }, 2000);
                } else {
                    toastRef.current.show({
                        severity: "error",
                        summary: "Erro :(",
                        detail: "Desculpe, não conseguimos processar a sua requisição.",
                        life: 1500,
                    });
                }
            } else {
                showLoading();
                const resposta = await axiosPatch(`${url}${values.id}/`, values);
                hideLoading();

                if (resposta.status === 200) {
                    toastRef.current.show({
                        severity: "success",
                        summary: "Sucesso",
                        detail: "Competência alterada com sucesso!",
                        life: 1500,
                    });

                    setTimeout(() => {
                        formik.resetForm();
                        history.push(urlvoltar);
                    }, 2000);
                } else {
                    toastRef.current.show({
                        severity: "error",
                        summary: "Erro :(",
                        detail: "Desculpe, não conseguimos processar a sua requisição.",
                        life: 1500,
                    });
                }
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};

                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });

                formik.setErrors(errorMessages);
                hideLoading();
            } else {
                toastRef.current.show({
                    severity: "error",
                    summary: "Erro :(",
                    detail: "Desculpe, não conseguimos processar a sua requisição.",
                    life: 1500,
                });
                hideLoading();
            }
        }
    }

    const cancelar = () => {
        formik.resetForm();
        history.push(urlvoltar);
    };

    return (
        <div className="p-grid">
            <div className="p-col-12">
                <div className="card">
                    <Toast ref={toastRef} />
                    <h3>{!formik.values.id ? "Nova competência" : "Manutenção de competência"}</h3>
                    <form onSubmit={formik.handleSubmit}>
                        <div className="p-fluid p-formgrid p-grid">
                            <div className="p-field p-col-12 p-md-2">
                                <label htmlFor="codigo">Código *</label>
                                <InputText
                                    id="codigo"
                                    name="codigo"
                                    value={formik.values.codigo}
                                    onChange={formik.handleChange}
                                    className={classNames({ "p-invalid": formik.errors.codigo })}
                                    autoComplete="off"
                                    autoFocus
                                />
                                {formik.errors.codigo && <small className="p-error">{formik.errors.codigo}</small>}
                            </div>
                            <div className="p-field p-col-12 p-md-5">
                                <label htmlFor="grupo_competencia">Grupo de competência *</label>
                                <InputText
                                    id="grupo_competencia"
                                    name="grupo_competencia"
                                    value={formik.values.grupo_competencia}
                                    onChange={formik.handleChange}
                                    className={classNames({ "p-invalid": formik.errors.grupo_competencia })}
                                    autoComplete="off"
                                    autoFocus
                                />
                                {formik.errors.grupo_competencia && (
                                    <small className="p-error">{formik.errors.grupo_competencia}</small>
                                )}
                            </div>
                            <div className="p-field p-col-12 p-md-2">
                                <label htmlFor="data_inicio">Data inicial *</label>
                                <MakoCalendar
                                    id="data_inicio"
                                    name="data_inicio"
                                    valueCalendar={formik.values.data_inicio}
                                    onChange={formik.handleChange}
                                    className={classNames({
                                        "p-invalid": formik.errors.data_inicio,
                                    })}
                                />
                                {formik.errors.data_inicio && (
                                    <small className="p-error">{formik.errors.data_inicio}</small>
                                )}
                            </div>
                            <div className="p-field p-col-12 p-md-2">
                                <label htmlFor="data_fim">Data final *</label>
                                <MakoCalendar
                                    id="data_fim"
                                    name="data_fim"
                                    valueCalendar={formik.values.data_fim}
                                    onChange={formik.handleChange}
                                    className={classNames({
                                        "p-invalid": formik.errors.data_fim,
                                    })}
                                />
                                {formik.errors.data_fim && <small className="p-error">{formik.errors.data_fim}</small>}
                            </div>
                        </div>

                        <p>
                            <b>* Campos obrigatórios.</b>
                        </p>

                        <div className="p-grid p-col-12 p-md-6">
                            <Button label="Gravar" type="submit" className="p-button-info p-mr-2 p-mb-6" />
                            <Button
                                label="Cancelar"
                                type="reset"
                                className="p-button-danger p-mr-2 p-mb-6"
                                onClick={cancelar}
                            />
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};
