import React from "react";

import * as Yup from "yup";
import { useFormik } from "formik";

import { classNames } from "primereact/utils";
import { InputText } from "primereact/inputtext";

import { MakoButton as Button } from "@/components/MakoButton";
import { MakoInputMoeda } from "@/components/MakoInputMoeda";
import { MakoQrCodePix } from "@/components/MakoQrCodePix";
import { Dropdown } from "@/components/Dropdown";
import { Label } from "@/components/Label";

import { DropdownChavesPix } from "./dropdown-chaves-pix";

import useCaixaMovimento from "@/hooks/useCaixaMovimento";
import useLoadingLocal from "@/hooks/useLoadingLocal";
import useHttp from "@/hooks/useHttp";

import { TIPO_TRANSACOES_PENDENCIAS } from "@/assets/constants/constants";
import { dataToStr } from "@/assets/util/datas";

const BASE_URL = "/financeiro/caixas/{caixa}/pendencias/{pendencia}/processar";

const INITIAL_VALUES = {
    conta_financeira: null,
    tipo: 0,
    natureza: "E",
    valor_pix: 0,
    valor_taxa: 0,
    valor_calculado: 0,
    juros: 0,
    multa: 0,
    descontos: 0,
    datahora_requisicao: new Date(),
    datahora_transacao: null,
    comprovante: null,
    chave: null,
};

export const ReceberPix = ({ pendencia, successCallback = () => {} }) => {
    const [loading, showLoading, hideLoading] = useLoadingLocal();
    const { caixaMov } = useCaixaMovimento();
    const { httpPost } = useHttp();

    const { setValues, setFieldValue, ...formik } = useFormik({
        initialValues: {
            ...INITIAL_VALUES,
            valor_pix: pendencia?.valor_receber,
            valor_calculado: pendencia?.valor_receber,
            forma_recebimento: pendencia?.forma_recebimento,
            id: pendencia?.id,
            tipo_chave: pendencia?.tipo_chave,
        },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values, formikHelpers) {
        try {
            const formSchema = Yup.object().shape({
                conta_financeira: Yup.number().nullable().required("O campo 'conta financeira' é obrigatório"),
                tipo: Yup.number().oneOf(
                    TIPO_TRANSACOES_PENDENCIAS.map((v) => v.value),
                    "Informe um 'tipo' válido"
                ),
                valor_pix: Yup.number().min(0.01, "Informe um 'valor transação' maior que 0"),
                chave: Yup.object().nullable().typeError("Informe uma 'chave pix' válida"),
                comprovante: Yup.string().nullable().typeError("Informe um 'comprovante' válido"),
            });

            await formSchema.validate(values, { abortEarly: false });

            const { id, tipo, ...body } = values;

            let success = false;
            let dataCallback = null;

            const handlers = {
                200: ({ data }) => {
                    success = true;
                    dataCallback = data;
                    formikHelpers.resetForm({ values: INITIAL_VALUES });
                },
            };

            const url = BASE_URL.replace("{caixa}", caixaMov?.id).replace("{pendencia}", id);
            showLoading();
            await httpPost(
                {
                    url,
                    body: {
                        ...body,
                        datahora_transacao: dataToStr(new Date(), "yyyy-MM-dd HH:mm"),
                        datahora_requisicao: dataToStr(body.datahora_requisicao, "yyyy-MM-dd HH:mm"),
                        chave: body.chave?.conteudo || body.chave?.chave,
                    },
                },
                handlers
            );
            hideLoading();
            if (success) successCallback(dataCallback);
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formikHelpers.setErrors(errorMessages);
            }
        }
    }

    return (
        <form onSubmit={formik.handleSubmit}>
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12 p-md-4">
                    <Label htmlFor="conta_financeira" label="Conta financeira" obrigatorio />
                    <Dropdown
                        id="conta_financeira"
                        name="conta_financeira"
                        url="/financeiro/contas-financeiras?limit=200"
                        optionValue="id"
                        optionLabel="descricao"
                        showClear={false}
                        value={formik.values.conta_financeira}
                        onChange={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.conta_financeira })}
                    />
                    {formik.errors.conta_financeira && (
                        <small className="p-error">{formik.errors.conta_financeira}</small>
                    )}
                </div>
                <div className="p-field p-col-12 p-md-4">
                    <Label htmlFor="tipo" label="Tipo" obrigatorio />
                    <Dropdown
                        id="tipo"
                        name="tipo"
                        options={TIPO_TRANSACOES_PENDENCIAS}
                        optionValue="value"
                        optionLabel="label"
                        showClear={false}
                        value={formik.values.tipo}
                        className={classNames({ "p-invalid": formik.errors.tipo })}
                    />
                    {formik.errors.tipo && <small className="p-error">{formik.errors.tipo}</small>}
                </div>
                <div className="p-field p-col-12 p-md-4">
                    <Label htmlFor="valor_pix" label="Valor" obrigatorio />
                    <MakoInputMoeda
                        id="valor_pix"
                        name="valor_pix"
                        valueMoeda={formik.values.valor_pix}
                        onChangeMoeda={formik.handleChange}
                        disabled
                    />
                </div>
            </div>
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12 p-md-8">
                    <Label htmlFor="chave" label="Chave Pix" />
                    <DropdownChavesPix
                        id="chave"
                        name="chave"
                        showClear={false}
                        contaFinanceiraId={formik.values.conta_financeira}
                        onChange={formik.handleChange}
                        value={formik.values.chave}
                        className={classNames({ "p-invalid": formik.errors.chave })}
                    />
                    {formik.errors.chave && <small className="p-error">{formik.errors.chave}</small>}
                </div>
                <div className="p-field p-col-12 p-md-4">
                    <Label htmlFor="comprovante" label="Comprovante" />
                    <InputText
                        id="comprovante"
                        name="comprovante"
                        showClear={false}
                        maxLength={30}
                        value={formik.values.comprovante}
                        onInput={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.comprovante })}
                    />
                    {formik.errors.comprovante && <small className="p-error">{formik.errors.comprovante}</small>}
                </div>
            </div>
            <div className="pendencia-pix">
                {!!formik.values.chave?.id && (
                    <div className="pendencia-pix-qrcode">
                        <MakoQrCodePix chaveId={formik.values.chave?.id} valor={parseFloat(formik.values.valor_pix)} />
                    </div>
                )}
                <Button loading={loading} label="Confirmar" type="submit" className="p-button p-button-success" />
            </div>
        </form>
    );
};
